import React from "react";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BookOpen } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { openUrlInNewTab } from "../../../common/utils/browser.utils";

const useStyles = makeStyles()(theme => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  menuItem: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 10,
  },
  dropdownButton: {
    height: 24,
    fontSize: 12,
    boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.31), 0px 1px 1px 0px rgba(0, 0, 0, 0.08)",
    color: theme.palette.secondary.dark,
    marginLeft: 16,
  },
}));

interface Props {
  onSelectSchema: (schema: string) => void;
  disabled: boolean;
}

export default function CustomAttributesSchemaMenus({ onSelectSchema, disabled }: Props) {
  const { classes } = useStyles();

  const [howToUseAnchorEl, setHowToUseAnchorEl] = React.useState<null | HTMLElement>(null);
  const [tryExamplesAnchorEl, setTryExamplesAnchorEl] = React.useState<null | HTMLElement>(null);
  return (
    <>
      <DropdownButton
        id="how-to-use"
        label="How to use"
        icon={BookOpen}
        onClick={e => setHowToUseAnchorEl(e.currentTarget)}
        disabled={false}
      />
      <Menu
        id="how-to-use-menu"
        anchorEl={howToUseAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(howToUseAnchorEl)}
        classes={{ list: classes.root }}
        onClose={() => setHowToUseAnchorEl(null)}
      >
        <MenuItem
          id="define-properties-in-json-menu-item"
          dense
          classes={{ root: classes.menuItem }}
          onClick={() => {
            openUrlInNewTab(
              "https://json-schema.org/learn/getting-started-step-by-step#properties"
            );
            setHowToUseAnchorEl(null);
          }}
        >
          Define properties in JSON
        </MenuItem>
      </Menu>

      <DropdownButton
        id="try-examples-button"
        label="Try Examples"
        onClick={e => setTryExamplesAnchorEl(e.currentTarget)}
        disabled={disabled}
      />
      <Menu
        id="try-examples-menu"
        anchorEl={tryExamplesAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(tryExamplesAnchorEl)}
        classes={{ list: classes.root }}
        onClose={() => setTryExamplesAnchorEl(null)}
      >
        <MenuItem
          id="company-address-and-contact-attributes-menu-item"
          dense
          classes={{ root: classes.menuItem }}
          onClick={() => {
            onSelectSchema(sampleSchemas["companyAddressAndContactAttributes"]);
            setTryExamplesAnchorEl(null);
          }}
        >
          Company address and contact attributes
        </MenuItem>
      </Menu>
    </>
  );
}

interface DropdownButtonProps {
  id: string;
  label: string;
  icon?: any;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
}

const DropdownButton = ({ id, label, icon: Icon, onClick, disabled }: DropdownButtonProps) => {
  const { classes } = useStyles();

  return (
    <Button
      id={id}
      variant="text"
      size="small"
      className={classes.dropdownButton}
      disableRipple
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && <Icon size={14} style={{ marginRight: 8 }} />}
      {label}
      <ArrowDropDown />
    </Button>
  );
};

const sampleSchemas = {
  companyAddressAndContactAttributes: `{
  "properties": {
    "address": {
      "description": "Company address",
      "type": "string",
      "minLength": 1
    },
    "phone": {
      "description": "Company phone",
      "type": "string",
      "minLength": 1
    },
    "email": {
      "description": "Company email",
      "type": "string"
    },
    "description": {
      "type": "string"
    }
  },
  "description": "Company information",
  "type": "object",
  "required": [
    "address",
    "phone",
    "email"
  ]
}
  `,
};
