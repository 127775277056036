import React, { useState } from "react";

import DashboardCustomizeOutlined from "@mui/icons-material/DashboardCustomizeOutlined";
import Grid from "@mui/material/Grid";

import {
  OrganizationResponse,
  ServerBindingResponse,
  ServerResponse,
  WorkspaceResponse,
  WorkspaceResponseTypeEnum,
} from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import EmptyState from "../../../../common/components/EmptyState";
import { getFromLocalStorage } from "../../../../common/utils/localStorage.utils";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import { useListThemes } from "../../../services/adminThemesQuery";
import {
  useInfiniteScrollForItemsWithQuery,
  UseItemsReturnType,
} from "../../common/EnhancedTableAsync/useItemsWithQuery";
import useWorkspacesSeqOrCursor from "../../common/EnhancedTableAsync/useWorkspacesSeqOrCursor";
import PageContent from "../../common/PageContent";
import EditWorkspaceDialog from "../EditWorkspaceDialog";
import { WorkspaceTab } from "../workspace-directory.common";
import WorkspaceCard from "./WorkspaceCard";
import WorkspaceCardMenu from "./WorkspaceCardMenu";
import WorkspacesTable from "./WorkspacesTable";
import WorkspacesToolbar from "./WorkspacesToolbar";
import { GRID_MODE_KEY, GridMode } from "./utils";

interface Props {
  tab: WorkspaceTab;
  setTab: (tab: WorkspaceTab) => void;
  serversBindings: ServerBindingResponse[];
  cardProgress: string;
  connectProgress: string;
  deleteProgress: string;
  onDelete: (id: string, name: string, type: WorkspaceResponseTypeEnum | undefined) => void;
  onSelectServer: (id: string) => void;
  onConnectToExisting: (aid: string, rid: string) => void;
  onCreateDeveloperPortal: ({ aid }: { aid: string }) => void;
  onUnbind: ({ aid, rid }: { aid: string | undefined; rid: string }) => void;
  onBindToTheme: (server: WorkspaceResponse | OrganizationResponse) => void;
  onUnbindTheme: (server: WorkspaceResponse | OrganizationResponse) => void;
  handleBookmarkServer: (server: ServerResponse) => void;
  onWorkspaceCreate?: () => void;
  data: UseItemsReturnType<WorkspaceResponse>;
}

function WorkspacesController({
  tab,
  setTab,
  serversBindings,
  cardProgress,
  connectProgress,
  deleteProgress,
  onDelete,
  onSelectServer,
  onConnectToExisting,
  onCreateDeveloperPortal,
  onUnbind,
  onBindToTheme,
  onUnbindTheme,
  handleBookmarkServer,
  onWorkspaceCreate,
  data,
}: Props) {
  const [workspaceMenu, setWorkspaceMenu] = useState<{
    anchorEl: HTMLElement;
    server: WorkspaceResponse;
  }>();
  const [gridMode, setGridMode] = useState<GridMode>(
    (getFromLocalStorage(GRID_MODE_KEY) as GridMode) || "full-card"
  );
  const [editWorkspace, setEditWorkspace] = useState<string>();
  const tenantId = getTenantId();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const themesQuery = useListThemes(tenantId, {
    enabled: !!checkTenantPermissionsQuery.data?.read_themes,
  });
  const themes = themesQuery.data?.themes ?? [];

  const {
    totalData: totalWorkspaces,
    areFiltersApplied,
    searchText,
    filters,
    onLastPage,
    onResetPage,
    onFiltersUpdate,
  } = data;

  const isEmpty = !totalWorkspaces.length && !areFiltersApplied && !searchText;

  useInfiniteScrollForItemsWithQuery({ onLastPage, enabled: gridMode !== "table" });

  return (
    <PageContent progress={data.firstPageLoading}>
      {!isEmpty ? (
        <>
          <WorkspacesToolbar
            searchText={searchText}
            onUpdate={
              tab === WorkspaceTab.WORKSPACES_SEQ ||
              tab === WorkspaceTab.DEVELOPER_PORTALS_SEQ ||
              tab === WorkspaceTab.ADMINISTRATIVE_SEQ
                ? text => onFiltersUpdate(text, filters)
                : undefined
            }
            onCreate={
              checkTenantPermissionsQuery.data?.create_workspace ? onWorkspaceCreate : undefined
            }
            {...(tab !== WorkspaceTab.ADMINISTRATIVE_SEQ
              ? {
                  gridMode,
                  setGridMode(gridMode) {
                    setGridMode(gridMode);
                    onResetPage();
                  },
                }
              : {})}
          />

          {totalWorkspaces.length > 0 ? (
            (gridMode === "table" && tab !== WorkspaceTab.ADMINISTRATIVE_SEQ && (
              <WorkspacesTable
                data={data}
                onSelectServer={onSelectServer}
                themes={themes}
                themesProgress={themesQuery.isFetching}
                connectProgress={connectProgress}
                serversBindings={serversBindings}
                cardProgress={cardProgress}
                deleteProgress={deleteProgress}
                onMenuOpen={setWorkspaceMenu}
              />
            )) || (
              <Grid container spacing={3}>
                {totalWorkspaces.map(server => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={3} key={server.id}>
                      <WorkspaceCard
                        server={server as any}
                        progress={cardProgress === server.id}
                        connectProgress={server.id === connectProgress}
                        deleteProgress={server.id === deleteProgress}
                        serversBindings={serversBindings}
                        onSelectServer={onSelectServer}
                        tab={tab}
                        isFullCard={gridMode === "full-card"}
                        themes={themes}
                        themesProgress={themesQuery.isFetching}
                        onMenuOpen={setWorkspaceMenu as any}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )
          ) : (
            <div style={{ color: "gray" }}>No workspaces found</div>
          )}
        </>
      ) : (
        <EmptyState
          title="Workspaces"
          description="You haven't created any workspace yet"
          icon={DashboardCustomizeOutlined}
          buttonContent="+ Create New"
          onSubmit={
            checkTenantPermissionsQuery.data?.create_workspace ? onWorkspaceCreate : undefined
          }
          style={{ marginTop: 80 }}
        />
      )}

      {workspaceMenu && (
        <WorkspaceCardMenu
          setTab={setTab}
          workspace={workspaceMenu.server}
          serversBindings={serversBindings}
          onDelete={onDelete}
          onBindTheme={onBindToTheme}
          onUnbindTheme={onUnbindTheme}
          onConnectToExisting={onConnectToExisting}
          onCreateDeveloperPortal={onCreateDeveloperPortal}
          onUnbind={onUnbind}
          onSelectServer={onSelectServer}
          onBookmark={handleBookmarkServer}
          handleClose={() => setWorkspaceMenu(undefined)}
          handleEdit={setEditWorkspace}
          mainMenuAnchorEl={workspaceMenu.anchorEl}
        />
      )}

      {editWorkspace && (
        <EditWorkspaceDialog onClose={() => setEditWorkspace(undefined)} serverID={editWorkspace} />
      )}
    </PageContent>
  );
}

export function WorkspacesControllerWithCursor(props: Omit<Props, "data">) {
  const data = useWorkspacesSeqOrCursor({ forceMode: "cursor" });

  return <WorkspacesController {...props} data={data} />;
}

export function WorkspacesControllerWithSeq(
  props: Omit<Props, "data"> & { workspaceType?: string }
) {
  const { workspaceType, ...otherProps } = props;

  const data = useWorkspacesSeqOrCursor({ forceMode: "seq", workspaceType });

  return <WorkspacesController {...otherProps} data={data} />;
}
