import React, { CSSProperties } from "react";

import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { Info } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ width: string | undefined; noIconMargin: boolean }>()(
  (theme, { width, noIconMargin }) => ({
    tooltip: {
      wordBreak: "break-word",
      backgroundColor: theme.palette.secondary.dark,
      padding: 12,
      width: width || 256,
    },
    arrow: {
      color: theme.palette.secondary.dark,
    },
    title: {
      marginBottom: 4,
    },
    icon: {
      width: 16,
      height: 16,
      color: theme.palette.secondary.light,
      ...(noIconMargin ? {} : { marginLeft: 6 }),
    },
  })
);

interface Props extends Omit<TooltipProps, "children"> {
  id?: string;
  icon?: any;
  style?: CSSProperties;
  width?: string;
  noIconMargin?: boolean;
}

function IconTooltip({ id, icon: Icon, style = {}, width, noIconMargin = false, ...props }: Props) {
  const { classes } = useStyles({ width, noIconMargin });

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      style={style}
      placement="top"
      {...props}
    >
      {Icon ? (
        <Icon className={classes.icon} data-testid={`${id}-tooltip-icon`} />
      ) : (
        <Info className={classes.icon} data-testid={`${id}-tooltip-icon`} />
      )}
    </Tooltip>
  );
}

export default IconTooltip;
