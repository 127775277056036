import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import { useQueryClient } from "@tanstack/react-query";

import {
  BaseUserWithData,
  SetPasswordStateRequestStateEnum,
  UserCredentialStateEnum,
  UserCredentialTypeEnum,
  UserWithData,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../../common/api/paths";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../../common/components/notifications/notificationService";
import identityUsersApi from "../../../../../../../services/adminIdentityUsersApi";
import { getUserQueryKey } from "../../../../../../../services/adminIdentityUsersQuery";

interface Props {
  user: UserWithData | BaseUserWithData;
  handleClose: () => void;
}

export default function IdentityPoolUserSidePanelForceChangePassword({ user, handleClose }: Props) {
  const [progress, setProgress] = useState(false);
  const queryClient = useQueryClient();

  const password = user.credentials?.filter(c => c.type === UserCredentialTypeEnum.Password)?.at(0);

  const handleForceChangePassword = () => {
    setProgress(true);
    identityUsersApi
      .setPasswordState({
        ipID: user?.user_pool_id!,
        userID: user?.id!,
        state: { state: SetPasswordStateRequestStateEnum.MustBeChanged },
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getUserQueryKey(getTenantId(), user?.id!) })
      )
      .then(() => notifySuccess("Force change password requested"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to force change password"))
      .finally(() => {
        setProgress(false);
        handleClose();
      });
  };

  return password &&
    password.state !== UserCredentialStateEnum.MustBeChanged &&
    (password.payload as any)?.hashed_password?.config?.method ? (
    <>
      <MenuItem
        id="force-change-password-button"
        onClick={() => {
          handleForceChangePassword();
        }}
        disabled={progress}
      >
        Force change password
      </MenuItem>
    </>
  ) : null;
}
