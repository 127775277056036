import React, { useCallback, useState } from "react";

import { TreeView } from "@mui/x-tree-view/TreeView";

import { OrganizationResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import OrganizationsHierarchyNode from "./OrganizationsHierarchyNode";

interface Props {
  toolbar?: React.JSX.Element;
  emptyState: React.JSX.Element;
  selected?: string;
  onSelectServer: (id: string) => void;
  onMenuOpen?: (props: {
    anchorEl: HTMLElement;
    server: OrganizationResponse;
    rootServer?: OrganizationResponse | WorkspaceResponse;
  }) => void;
  rootOrganization?: Pick<OrganizationResponse, "id" | "number_of_child_organizations">;
  additionalRootWorkspaces?: WorkspaceResponse[];
  showRootCard?: boolean;
  hideHierarchyBelowId?: { id: string; reason: string };
  allowSelectRoot?: boolean;
  greyOutNodeWithId?: { id: string; reason: string };
}

const defaultRootOrganization = { id: "" };

export default function OrganizationsHierarchy({
  toolbar,
  emptyState,
  selected,
  onSelectServer,
  onMenuOpen,
  rootOrganization,
  additionalRootWorkspaces,
  showRootCard,
  hideHierarchyBelowId,
  allowSelectRoot,
  greyOutNodeWithId,
}: Props) {
  const organization = rootOrganization || defaultRootOrganization;

  const [expanded, setExpanded] = useState([organization.id ?? ""]);
  const [loaded, setLoaded] = useState([organization.id ?? ""]);

  const onExpand = useCallback(
    (organizationId: string, opened: boolean) => {
      setExpanded(expanded => [
        organization.id ?? "",
        ...expanded.filter(id => id !== organizationId),
        ...(opened ? [organizationId] : []),
      ]);
    },
    [setExpanded, organization.id]
  );

  const onLoad = useCallback(
    (organizationId: string, opened: boolean) => {
      setLoaded(loaded => [
        organization.id ?? "",
        ...loaded.filter(id => id !== organizationId),
        ...(opened ? [organizationId] : []),
      ]);
    },
    [setLoaded, organization.id]
  );

  return (
    <div>
      <TreeView
        aria-label="customized"
        sx={{ overflowX: "hidden" }}
        disableSelection
        disabledItemsFocusable
        expanded={loaded}
      >
        <OrganizationsHierarchyNode
          toolbar={toolbar}
          emptyState={emptyState}
          expanded={expanded}
          onExpand={onExpand}
          organization={organization}
          selected={selected}
          onSelectServer={onSelectServer}
          isRoot
          onMenuOpen={onMenuOpen}
          onLoad={onLoad}
          additionalRootWorkspaces={additionalRootWorkspaces}
          showRootCard={showRootCard}
          hideHierarchyBelowId={hideHierarchyBelowId}
          allowSelectRoot={allowSelectRoot}
          greyOutNodeWithId={greyOutNodeWithId}
        />
      </TreeView>
    </div>
  );
}
