import React from "react";

import RouterLink from "../../../../common/components/RouterLink";
import { useWorkspace } from "../../common/useWorkspace";
import DocLink from "./DocLink";

export default function QuickstartArticleOrchestrated() {
  const [workspace] = useWorkspace();

  return (
    <>
      <p>
        SecureAuth facilitates the creation of orchestrated user flows by allowing seamless
        integration with third-party identity verification providers and data aggregation from
        multiple API sources. This integration supports personalized user experiences and the
        collection of additional user data post-authentication, enhancing identity verification and
        user interaction.
      </p>

      <p>
        <b>Key Capabilities:</b>
      </p>
      <ul>
        <li>
          <b>Third-party Application Integration</b>: Connect third-party applications to enrich the
          user's authentication context and enable post-authentication data gathering or processes.
        </li>
        <li>
          <b>Customizable Post-Authentication Actions</b>: Redirect users to third-party
          applications after authentication to collect more data or permissions that can be added to
          the user's session.
        </li>
        <li>
          <b>Adaptive Success and Abort Flows</b>: Handle scenarios where additional user data or
          permissions are collected successfully or the process fails, allowing for seamless
          continuation or appropriate error handling.
        </li>
      </ul>

      <p>
        <b>Setup Overview:</b>
      </p>

      <ol>
        <li>
          <b>Register Third-party Applications:</b>
          <ul>
            <li>
              Click <b>Show all</b> in the left navigation to see advanced views.
            </li>
            <li>
              Navigate to{" "}
              <RouterLink to={`/${workspace}/custom-applications`} target="_blank" withIcon>
                <b>Extensions &gt; Extension Apps</b>
              </RouterLink>{" "}
              in the admin dashboard.
            </li>
            <li>
              Click <b>+ Custom Application</b> and provide details such as the type, name, and
              application URL.
            </li>
            <li>Complete the setup to generate Client ID and Client Secret.</li>
          </ul>
        </li>
        <li>
          <b>Associate the Application with an IDP:</b>
          <ul>
            <li>
              Go to{" "}
              <RouterLink to={`/${workspace}/authentication/identities`} target="_blank" withIcon>
                <b>Authentication &gt; Providers</b>
              </RouterLink>{" "}
              , select the IDP of choice, and configure the Post Authentication application.
            </li>
            <li>
              This association ensures users are redirected post-login for additional processes.
            </li>
          </ul>
        </li>
        <li>
          <b>Post-Authn Success and Abort Flows:</b>
          <ul>
            <li>
              For success flows, the third-party application gathers extra user data and sends it to
              SecureAuth, enriching the authentication context.
            </li>
            <li>
              In case of failure, SecureAuth redirects the user, providing options for error
              handling or displaying error pages as needed.{" "}
            </li>
          </ul>
        </li>
      </ol>
      <br />
      <p>
        For detailed information, refer to{" "}
        <DocLink href="https://docs.secureauth.com/cloudentity/en/integrating-third-party-applications-for-user-authentication.html#add-post-authn-success-flow">
          Integrating Third-party Applications for User Authentication
        </DocLink>
        .
      </p>
    </>
  );
}
