import React, { useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useQueryClient } from "@tanstack/react-query";
import { JSONSchema7 } from "json-schema";
import isEqual from "lodash/isEqual";
import isMatch from "lodash/isMatch";
import omit from "lodash/omit";

import { OrganizationResponse } from "@cloudentity/acp-admin";

import WorkspaceDetailsInfoPanel from "../../../admin/components/common/WorkspaceDetailsInfoPanel";
import Domains from "../../../admin/components/settings/Domains";
import SchemaForm from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/SchemaForm";
import {
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../../../admin/components/workspaceDirectory/identityPools/schemas/schemas.utils";
import adminOrganizationsApi from "../../../admin/services/adminOrganizationsApi";
import {
  getOrganizationQueryKey,
  listOrganizationsQueryKey,
} from "../../../admin/services/adminOrganizationsQuery";
import { useCheckWorkspacePermissions } from "../../../admin/services/adminPermissionsQuery";
import RouteLeavingGuardSimple from "../../../common/components/RouteLeavingGuardSimple";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import TextField from "../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";

interface Props {
  organization: OrganizationResponse | undefined;
  isPreview?: boolean;
}

export default function OrganizationDetails({ organization, isPreview }: Props) {
  const queryClient = useQueryClient();
  const workspaceId = organization?.id ?? "";

  const [updateProgress, setUpdateProgress] = useState(false);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspaceId);

  // form
  const [payload, setPayload] = useState({});

  const schemaWithMappedTitles = mapFieldNameToTitle(organization?.metadata?.schema ?? {});
  const uiSchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(schemaWithMappedTitles),
  };

  const validateMetadata = validator.validateFormData(
    payload,
    (schemaWithMappedTitles as JSONSchema7) || {}
  );
  // end

  const data = useMemo(() => {
    setPayload(organization?.metadata?.payload || {});
    return {
      ...organization,
      domains: (organization?.domains || []) as (string | { value: string })[],
    };
  }, [organization]);

  const form = useForm({
    id: "workspace-details",
    initialValues: data,
    progress: updateProgress,
  });

  const handleUpdate = formData => {
    const submitData = {
      ...data,
      ...formData,
      metadata: { ...organization?.metadata, payload },
      domains: (formData.domains || []).map(d => (typeof d === "string" ? d : d.value)),
    };

    if (validateMetadata.errors.length > 0) {
      return;
    }

    if (checkWorkspacePermissionsQuery.data?.update_organization) {
      setUpdateProgress(true);
      return adminOrganizationsApi
        .updateOrganization({
          wid: workspaceId,
          org: submitData as any,
        })
        .then(() => {
          notifySuccess("Organization settings saved successfully");
        })
        .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
        .then(() =>
          queryClient.invalidateQueries({ queryKey: getOrganizationQueryKey(workspaceId) })
        )
        .catch(notifyErrorOrDefaultTo("Error occurred when trying to update organization settings"))
        .finally(() => setUpdateProgress(false));
    } else if (checkWorkspacePermissionsQuery.data?.update_organization_metadata) {
      setUpdateProgress(true);
      return adminOrganizationsApi
        .updateOrganizationMetadata({
          wid: workspaceId,
          organizationMetadata: { payload },
        })
        .then(() => {
          notifySuccess("Organization settings saved successfully");
        })
        .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
        .then(() =>
          queryClient.invalidateQueries({ queryKey: getOrganizationQueryKey(workspaceId) })
        )
        .catch(notifyErrorOrDefaultTo("Error occurred when trying to update organization settings"))
        .finally(() => setUpdateProgress(false));
    }
  };

  const payloadHasChanges = !isEqual(organization?.metadata?.payload || {}, payload);

  const domains = form.watch("domains");
  const allValues = form.watch();
  const formFactoryHasChanges = !isMatch(data, omit(allValues, ["domains"]));
  const domainsHasChanges =
    (domains || []).length !== (organization?.domains || []).length ||
    !(domains || []).every(
      (_, index) =>
        (domains || []).map(d => (typeof d === "string" ? d : d.value)).at(index) ===
        (organization?.domains || []).at(index)
    );

  const formHasChanges = payloadHasChanges || formFactoryHasChanges || domainsHasChanges;

  return (
    <Form form={form} noFormTag>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <WorkspaceDetailsInfoPanel workspace={organization} isOrganization />

          <Paper style={{ padding: 32 }}>
            <TextFieldRequired
              name="name"
              label="Name"
              disabled={!checkWorkspacePermissionsQuery.data?.update_organization}
              tooltip={
                !checkWorkspacePermissionsQuery.data?.update_organization
                  ? "No permission to edit this field"
                  : undefined
              }
            />

            <TextField
              name="description"
              label="Description"
              multiline
              maxRows={3}
              minRows={3}
              disabled={!checkWorkspacePermissionsQuery.data?.update_organization}
              tooltip={
                !checkWorkspacePermissionsQuery.data?.update_organization
                  ? "No permission to edit this field"
                  : undefined
              }
            />

            <Domains disabled={!checkWorkspacePermissionsQuery.data?.update_organization} />

            <SchemaForm
              formData={payload}
              setFormData={setPayload}
              schema={schemaWithMappedTitles}
              UISchema={uiSchema}
              submitAttempt={true}
              extraErrors={{}}
              resetExtraErrors={() => {}}
              disabled={!checkWorkspacePermissionsQuery.data?.update_organization_metadata}
            />

            {(checkWorkspacePermissionsQuery.data?.update_organization ||
              checkWorkspacePermissionsQuery.data?.update_organization_metadata) && (
              <FormFooter
                disabled={validateMetadata.errors.length > 0 || !formHasChanges}
                onSubmit={handleUpdate}
                style={{ marginTop: 22 }}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
      {!isPreview && <RouteLeavingGuardSimple when={formHasChanges} />}
    </Form>
  );
}
