import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { Copy, Link2 } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { OrganizationResponse, ServerResponse } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import Logo from "../../../common/components/Logo";
import { notifyErrorOrDefaultTo } from "../../../common/components/notifications/notificationService";
import { copyToClipboard } from "../../../common/services/clipboard";
import { CustomStylingType } from "../../../common/utils/hooks/useStyling";
import {
  getOrganizationQueryKey,
  listOrganizationsQueryKey,
} from "../../services/adminOrganizationsQuery";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import adminServersApi from "../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  getWorkspaceQueryKey,
  listWorkspacesQueryKey,
} from "../../services/adminServersQuery";
import WorkspaceAvatar from "../nav/WorkspaceAvatar";
import Tooltip from "./Tooltip";
import TooltipWithAction from "./TooltipWithAction";

const useStyles = makeStyles()(theme => ({
  container: {
    marginBottom: 32,
    display: "flex",
    alignItems: "center",
  },
  logo: {
    verticalAlign: "middle",
    height: 32,
    width: "auto",
    maxWidth: 100,
    objectFit: "contain",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
  },
  leftContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
    backgroundColor: "white",
    width: 100,
    height: 100,
    marginRight: 24,
  },
  id: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.light,
    marginTop: 8,
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  logoName: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  name: {
    marginTop: 16,
    width: "100%",
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "monospace !important",
  },
  iconButton: {
    marginLeft: 8,
  },
}));

interface Props {
  workspace: ServerResponse | OrganizationResponse | undefined;
  isOrganization: boolean;
  styling?: CustomStylingType;
}

export default function WorkspaceDetailsInfoPanel({ workspace, isOrganization, styling }: Props) {
  const { classes } = useStyles();
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(false);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace?.id);

  const handleColorUpdate = (color: string) => {
    if (!color || !workspace?.id) return;

    setProgress(true);
    adminServersApi
      .updateAuthorizationServer({ wid: workspace.id, server: { ...workspace, color } as any })
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getWorkspaceQueryKey(workspace.id ?? "") })
      )
      .then(() => {
        if (isOrganization) {
          queryClient.invalidateQueries({ queryKey: getOrganizationQueryKey(workspace.id ?? "") });
          queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() });
        }
      })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), workspace.id),
        })
      )
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update workspace settings"))
      .finally(() => setProgress(false));
  };

  return (
    <div className={classes.container}>
      <div>
        <div className={classes.leftContainer}>
          {styling?.logo.url ? (
            <Logo
              classes={{ logo: classes.logo }}
              logoUrl={styling.logo.url}
              style={styling.logo.size ? { height: styling.logo.size } : {}}
            />
          ) : (
            <WorkspaceAvatar
              server={workspace}
              size="large"
              withColorPicker={!!checkWorkspacePermissionsQuery.data?.update_workspace}
              onColorSelected={handleColorUpdate}
              progress={progress}
              isOrganization={isOrganization}
            />
          )}
        </div>
      </div>
      <div className={classes.rightContainer}>
        <Typography variant="h4">{workspace?.name}</Typography>

        <div className={classes.id}>
          <div>
            id: <span id="workspace-id-info">{workspace?.id}</span>
          </div>
          <Tooltip title="Copy workspace ID">
            <IconButton
              size="small"
              className={classes.iconButton}
              onClick={() => copyToClipboard(workspace?.id, "Workspace ID")}
              id="copy-workspace-id-button"
            >
              <Copy size={16} />
            </IconButton>
          </Tooltip>
          {isOrganization && (
            <TooltipWithAction
              title={
                <>
                  <b>Organization admin panel link</b>
                  <p>
                    Share this delegated admin panel link with organization administrators who need
                    access.
                  </p>
                </>
              }
              actionLabel="Copy link"
              onAction={() => copyToClipboard(`${workspace?.issuer_url}/b2b`, "Link")}
              actionButtonId="copy-link-tooltip-button"
            >
              <IconButton
                size="small"
                className={classes.iconButton}
                onClick={() => copyToClipboard(`${workspace?.issuer_url}/b2b`, "Link")}
                id="copy-link-button"
              >
                <Link2 size={16} />
              </IconButton>
            </TooltipWithAction>
          )}
        </div>
      </div>
    </div>
  );
}
