import React from "react";

import DashboardCustomizeOutlined from "@mui/icons-material/DashboardCustomizeOutlined";

import EmptyState from "../../../../common/components/EmptyState";
import { useGetEnvironment } from "../../../services/adminEnvironmentQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";
import BruteForceProtectionTable from "./BruteForceProtectionTable";

export default function BruteForceProtection() {
  const getEnvironmentQuery = useGetEnvironment();
  const envBruteForceLimits = getEnvironmentQuery.data?.brute_force_limits;
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  return (
    <>
      <PageHeader title="Brute-force Protection" />
      <PageContent>
        {envBruteForceLimits?.enabled && (
          <BruteForceProtectionTable
            readonly={!checkTenantPermissionsQuery.data?.manage_brute_force_protection}
          />
        )}

        {!envBruteForceLimits?.enabled && (
          <EmptyState
            title="Brute-force Protection not enabled"
            description="To enable Brute-force Protection for your tenant please contact administrator."
            icon={DashboardCustomizeOutlined}
            style={{ marginTop: 80 }}
          />
        )}
      </PageContent>
    </>
  );
}
