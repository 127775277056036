import React from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  root: {
    marginBottom: 32,
    paddingBottom: 8,
    borderBottom: "1px solid #ECECEC",
  },
  title: {
    fontWeight: 500,
    fontSize: 18,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subtitle: {
    color: theme.palette.secondary.light,
    padding: "4px 0",
  },
  noBorder: {
    borderBottom: "none",
    paddingBottom: 0,
  },
}));

interface Props {
  title: React.ReactNode;
  id?: string;
  subtitle?: React.ReactNode;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  noBorder?: boolean;
  small?: boolean;
}

export default function FormSection({
  title,
  id,
  subtitle,
  style = {},
  children,
  noBorder = false,
  small = false,
}: Props) {
  const { cx, classes } = useStyles();

  return (
    <div className={classes.root} style={style}>
      <div className={cx(classes.titleContainer, noBorder && classes.noBorder)}>
        <Typography
          id={id}
          variant="h3"
          display="block"
          className={classes.title}
          style={small ? { fontSize: 12 } : {}}
        >
          {title}
        </Typography>
        {children}
      </div>
      {subtitle && (
        <Typography display="block" variant="caption" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </div>
  );
}
