import React, { ReactNode } from "react";

import Paper from "@mui/material/Paper";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  paperRoot: {
    width: "100%",
    margin: "0 auto",
    position: "relative",
    padding: 24,
    marginBottom: 16,
  },
}));

interface Props {
  children: ReactNode;
}

export default function HeaderTile({ children }: Props) {
  const { classes } = useStyles();

  return (
    <Paper className={classes.paperRoot}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>{children}</div>
    </Paper>
  );
}
