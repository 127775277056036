import React from "react";
import { useParams } from "react-router";

import Editor, { OnChange } from "@monaco-editor/react";
import Close from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  editorToolbar: {
    height: 49,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 8,
    paddingRight: 8,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  errorContainer: {
    width: "100%",
    height: 220,
    position: "absolute",
    background: "#F2F3F4",
    bottom: 0,
    right: 0,
    left: 0,
  },
  errorHeader: {
    background: "#E9EBEC",
    height: 36,
    padding: "8px 0px 8px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  error: {
    padding: "16px 24px",
    color: theme.palette.secondary.dark,
  },
  errorConsole: {
    color: theme.palette.secondary.light,
  },
  path: {
    color: theme.palette.secondary.light,
    fontWeight: 600,
  },
}));

interface Props {
  value: string | undefined;
  onValueChange: OnChange;
  error: string;
  readOnly?: boolean;
  onResetError: () => void;
  selectedPath: string;
  onSaveClick: () => void;
  disableSave: boolean;
  saveProgress: boolean;
}

export default function ThemeTemplateEditor({
  value,
  onValueChange,
  error,
  readOnly,
  onResetError,
  selectedPath,
  onSaveClick,
  disableSave,
  saveProgress,
}: Props) {
  const { classes } = useStyles();
  const { id: themeID = "" } = useParams<{ id: string }>();

  return (
    <>
      {selectedPath && (
        <div className={classes.editorToolbar}>
          <Typography className={classes.path}>
            {selectedPath.split("/").slice(-1).pop()}
          </Typography>
          {themeID !== "default" && !readOnly && (
            <div style={{ textAlign: "right" }}>
              <LoadingButton
                id="save-template-button"
                onClick={onSaveClick}
                disabled={disableSave}
                loading={saveProgress}
                variant="contained"
              >
                Save
              </LoadingButton>
            </div>
          )}
        </div>
      )}
      {!selectedPath && (
        <div style={{ width: "100%", textAlign: "center", marginTop: 128 }}>
          <Typography style={{ color: "rgba(0, 0, 0, 0.26)" }}>Select file</Typography>
        </div>
      )}
      {selectedPath && (
        <Editor
          defaultLanguage="html"
          options={{
            readOnly: saveProgress || readOnly || themeID === "default",
            minimap: {
              enabled: false,
            },
            scrollBeyondLastLine: false,
          }}
          height="calc(100vh - 282px)"
          value={value}
          onChange={onValueChange}
        />
      )}
      {error && (
        <div className={classes.errorContainer}>
          <div className={classes.errorHeader}>
            <Typography className={classes.errorConsole}>Console</Typography>
            <IconButton onClick={onResetError}>
              <Close />
            </IconButton>
          </div>
          <div className={classes.error}>{error}</div>
        </div>
      )}
    </>
  );
}
