import React from "react";

import CheckboxField from "../../../common/utils/forms/CheckboxField";

export default function SSOIDPSettings() {
  return (
    <CheckboxField
      name="sso_settings.disabled"
      label="Disable SSO"
      helperText="If selected, Single Sign-On sessions will not be initiated for this identity provider"
    />
  );
}
