import React, { CSSProperties } from "react";

import SwitchMUI, { SwitchProps as SwitchMUIProps } from "@mui/material/Switch";

import Progress from "./Progress";

interface SwitchProps extends SwitchMUIProps {
  progress?: boolean;
  containerStyle?: CSSProperties;
}

function Switch({ progress, containerStyle, ...props }: SwitchProps) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...containerStyle,
      }}
    >
      {progress ? (
        <Progress size={20} style={{ top: 0, right: 0, width: 0, height: 0, left: "100%" }} />
      ) : undefined}
      <SwitchMUI color="primary" {...props} />
    </div>
  );
}

export default Switch;
