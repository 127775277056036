import { useQuery } from "@tanstack/react-query";

import { ScriptDebugs, ScriptExecutionPoints } from "@cloudentity/acp-admin";

import adminScriptsApi from "./adminScriptsApi";
import { useQueryWithWorkspacePermissionCheck, withQueryError } from "./queryUtils";

const GET_SCRIPTS_QUERY = "GET_SCRIPTS_QUERY";
const GET_EXECUTION_POINTS_QUERY = "GET_EXECUTION_POINTS_QUERY";
const GET_SCRIPT_DEBUGS_QUERY = "GET_SCRIPT_DEBUGS_QUERY";

export const getScriptsQueryKey = (tid: string, wid: string) => [GET_SCRIPTS_QUERY, tid, wid];
export const getExecutionPointsQueryKey = (tid: string, wid: string) => [
  GET_EXECUTION_POINTS_QUERY,
  tid,
  wid,
];
export const getScriptDebugsQueryKey = (tid: string, wid: string) => [
  GET_SCRIPT_DEBUGS_QUERY,
  tid,
  wid,
];

export const useGetScripts = (tid: string, wid: string, options?: any) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "read_scripts",
    getScriptsQueryKey(tid, wid),
    withQueryError(async () => {
      const data = await adminScriptsApi.listScripts({ wid });
      return data.data;
    }, "Error occurred while trying to fetch scripts"),
    options
  );

export const useListScriptExecutionPoints = (tid: string, wid: string, options?: any) =>
  useQuery<ScriptExecutionPoints>({
    queryKey: getExecutionPointsQueryKey(tid, wid),
    queryFn: withQueryError<ScriptExecutionPoints>(async () => {
      const data = await adminScriptsApi.listScriptExecutionPoints({ wid });
      return data.data;
    }, "Error occurred while trying to fetch execution points"),
    ...options,
  });

export const useListScriptDebugs = (tid: string, wid: string, options?: any) =>
  useQuery<ScriptDebugs>({
    queryKey: getScriptDebugsQueryKey(tid, wid),
    queryFn: withQueryError<ScriptDebugs>(async () => {
      const data = await adminScriptsApi.listScriptDebugs({ wid });
      return data.data;
    }, "Error occurred while trying to fetch script debugs"),
    ...options,
  });
