import React, { useEffect, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import some from "lodash/some";
import { Icon } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { TenantPermissionsResponse, WorkspacePermissionsResponse } from "@cloudentity/acp-admin";

import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import { useWorkspace } from "../common/useWorkspace";

interface Props {
  id: string;
  label: React.ReactNode;
  isActive: boolean;
  isDrawerOpen: boolean;
  isOpen: boolean;
  disableActiveEffect?: boolean;
  disableActiveShadowEffect?: boolean;
  icon?: Icon;
  img?: { normal: any; active: any };
  hasTenantPermission?: keyof TenantPermissionsResponse;
  hasOneOfWorkspacePermissions?: (keyof WorkspacePermissionsResponse)[];
  children: React.ReactNode;
  onClick?: (sectionID: string) => void;
}

export default function NavSection({
  id,
  label,
  isActive,
  isDrawerOpen,
  isOpen,
  disableActiveEffect,
  disableActiveShadowEffect,
  icon: Icon,
  img,
  hasTenantPermission,
  hasOneOfWorkspacePermissions,
  children,
  onClick,
}: Props) {
  const { cx, classes } = useStyles();

  const [transitionState, setTransitionState] = useState<"" | "entered">("");

  useEffect(() => {
    if (isActive && isDrawerOpen) {
      onClick && onClick(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [workspace] = useWorkspace();
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  if (
    hasOneOfWorkspacePermissions &&
    !some(
      hasOneOfWorkspacePermissions,
      permission => checkWorkspacePermissionsQuery.data?.[permission]
    )
  ) {
    return null;
  }
  if (hasTenantPermission && !checkTenantPermissionsQuery.data?.[hasTenantPermission]) {
    return null;
  }

  return (
    <>
      <ListItem
        button
        onClick={() => {
          if (isActive && isDrawerOpen && isOpen) {
            return;
          }
          onClick && onClick(id);
        }}
        id={`${id}-nav-section`}
        data-nav-section-id={id}
        classes={{ root: classes.listItemRoot }}
        className={
          isActive && !disableActiveEffect
            ? cx(classes.active, !disableActiveShadowEffect && classes.activeWithShadow)
            : ""
        }
        {...(transitionState === "entered" ? { "data-entered": true } : {})}
      >
        {(Icon || img) && (
          <ListItemIcon classes={{ root: isActive ? classes.activeIcon : undefined }}>
            {Icon && <Icon />}
            {img && (
              <img
                src={isActive ? img.active : img.normal}
                alt="item icon"
                style={{ height: 24, width: 24 }}
              />
            )}
          </ListItemIcon>
        )}
        <ListItemText
          style={{ whiteSpace: "nowrap" }}
          primary={
            <Typography variant="body2" component="div">
              {label}
            </Typography>
          }
          classes={{ root: classes.listItemTextRoot }}
          disableTypography
        />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={isOpen}
        timeout="auto"
        onExit={() => setTransitionState("")}
        onEntered={() => setTransitionState("entered")}
      >
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
}

const useStyles = makeStyles()(theme => ({
  active: {
    color: theme.palette.primary.main,
  },
  activeWithShadow: {
    background: "linear-gradient(270deg, #F2F5FF 0%, #FFFFFF 100%)",
    boxShadow: `-4px 0px 0px 0px ${theme.palette.primary.main} inset`,
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
  listItemRoot: {
    paddingTop: 12,
    paddingBottom: 12,
    overflow: "hidden",
  },
  listItemTextRoot: {
    fontSize: 14,
  },
}));
