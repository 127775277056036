import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { FilePlus, UserPlus } from "react-feather";

import { BaseUserWithData } from "@cloudentity/acp-identity";

import adminB2BUsersApi from "../../../admin/services/adminB2BUsersApi";
import { useCheckPoolPermissions } from "../../../admin/services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../admin/services/adminIdentityUsersApi";
import { getB2BUserQueryKey } from "../../../admin/services/adminIdentityUsersQuery";
import { queryClient } from "../../../admin/services/queryClient";
import { getTenantId } from "../../../common/api/paths";
import Button from "../../../common/components/Button";
import B2BUserCreate from "./B2BUserCreate";
import ImportUsersDialog from "./ImportUsersDialog";

interface Props {
  workspaceId: string;
  identityPoolID: string;
  onCreated?: (user: BaseUserWithData) => void;
}

export default function B2BUsersCreateNew({ workspaceId, identityPoolID, onCreated }: Props) {
  const [params] = useSearchParams();
  const [addUser, setAddUser] = useState(!!params.get("invite"));
  const [importUsers, setImportUsers] = useState(false);

  const checkPoolPermissionsQuery = useCheckPoolPermissions(identityPoolID);

  if (!checkPoolPermissionsQuery.data?.b2b_manage_users) {
    return null;
  }

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="outlined"
          onClick={() => {
            setImportUsers(true);
          }}
          style={{ marginRight: 24 }}
          disabled
        >
          <FilePlus size={14} style={{ marginRight: 8 }} />
          IMPORT USERS
        </Button>

        <Button
          onClick={() => setAddUser(true)}
          id="table-toolbar-action-button"
          variant="contained"
        >
          <UserPlus size={14} style={{ marginRight: 8 }} />
          CREATE USER
        </Button>
      </div>

      {addUser && (
        <B2BUserCreate
          workspaceId={workspaceId}
          identityPoolID={identityPoolID}
          onCreated={(user, addAnotherUser) => {
            onCreated && onCreated(user);
            setAddUser(addAnotherUser);
          }}
          onClose={() => {
            setAddUser(false);
          }}
        />
      )}

      {importUsers && (
        <ImportUsersDialog
          workspaceId={workspaceId}
          identityPoolId={identityPoolID}
          allowMetadata={false}
          allowBusinessMetadata={false}
          disableWhenMetadataHasRequiredProperties
          createUserFn={async ({ newUser, metadata, business_metadata }) => {
            let createdUser: BaseUserWithData;
            return adminB2BUsersApi
              .createB2BUser({ ipID: identityPoolID, newUser })
              .then(res => {
                createdUser = res.data;
                queryClient.setQueryData(getB2BUserQueryKey(getTenantId(), res.data.id), res.data);
                return res;
              })
              .then(() => {
                if (checkPoolPermissionsQuery.data?.b2b_manage_admin_metadata) {
                  return identityUsersApi.setUserMetadata({
                    ipID: createdUser?.user_pool_id,
                    userID: createdUser?.id!,
                    metadataType: "admin",
                    userMetadata: { metadata: metadata },
                  });
                }
                return Promise.resolve({});
              })
              .then(() => {
                if (checkPoolPermissionsQuery.data?.b2b_manage_business_metadata) {
                  return identityUsersApi.setUserMetadata({
                    ipID: createdUser?.user_pool_id,
                    userID: createdUser?.id!,
                    metadataType: "business",
                    userMetadata: { metadata: business_metadata },
                  });
                }
                return Promise.resolve({});
              })
              .then(() => createdUser);
          }}
          onClose={() => setImportUsers(false)}
        />
      )}
    </>
  );
}
