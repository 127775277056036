import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Controller, RegisterOptions } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../components/FormInputLabel";
import { usePrevious } from "../hooks/usePrevious";
import { useFormContext } from "./Form";
import { validators } from "./validation";

export const useStyles = makeStyles()(theme => ({
  container: {
    marginBottom: 32,
    display: "flex",
    alignItems: "center",
  },
  inputsContainer: {
    display: "flex",
  },
  input: {
    width: 53,
    height: 53,
    marginRight: 8,
    backgroundColor: theme.custom.greys.fieldBackground,
    "&:last-of-type": {
      marginRight: 0,
    },
    "& input": {
      textAlign: "center",

      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&[type=number]": {
        "-moz-appearance": "textfield",
      },
    },
  },
}));

interface SingleInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean;
}

export function SingleInput({ focus, autoFocus, ...props }: SingleInputProps) {
  const { classes } = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && prevFocus !== undefined && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <TextField inputRef={inputRef} inputProps={props} type="number" className={classes.input} />
  );
}

export interface OTPFieldProps {
  length: number;
  autoFocus?: boolean;
  disabled?: boolean;
  helperText?: string;
  name: string;
  label: string;
  rules?: Pick<RegisterOptions, "required" | "validate">;
}

export default function OTPField({
  length,
  autoFocus,
  disabled,
  helperText,
  name,
  label,
  rules,
}: OTPFieldProps) {
  const { classes } = useStyles();
  const { form, disabled: formDisabled, id } = useFormContext();
  const inputId = `${id}-${name}`;

  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(""));

  const handleOtpChange = useCallback((otp: string[]) => {
    const otpValue = otp.join("");
    form.setValue(name, otpValue, { shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRightValue = useCallback((str: string) => {
    let changedValue = str;

    if (!changedValue) {
      return changedValue;
    }

    return Number(changedValue) >= 0 ? changedValue : "";
  }, []);

  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            e.preventDefault();
          }

          break;
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput)
        .split("");

      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues]
  );

  return (
    <Controller
      name={name}
      control={form.control}
      rules={{
        ...rules,
        validate: {
          minLength: validators.minLength({ label: "Code", min: length }),
          ...rules?.validate,
        },
      }}
      render={({ fieldState }) => (
        <FormControl
          variant="outlined"
          fullWidth
          error={!!fieldState.error}
          className={classes.container}
        >
          <div>
            <FormInputLabel id={`${inputId}-label`} label={label} forId={`${inputId}-input-0`} />
            <div className={classes.inputsContainer}>
              {Array(length)
                .fill("")
                .map((_, index) => (
                  <SingleInput
                    key={`single-input-${index}`}
                    focus={activeInput === index}
                    value={otpValues && otpValues[index]}
                    autoFocus={autoFocus}
                    onFocus={handleOnFocus(index)}
                    onChange={handleOnChange}
                    onKeyDown={handleOnKeyDown}
                    onBlur={onBlur}
                    onPaste={handleOnPaste}
                    disabled={disabled || formDisabled}
                    id={`${inputId}-input-${index}`}
                  />
                ))}
            </div>
            <FormHelperText id={`${inputId}-helper-text`}>
              {fieldState.error?.message || helperText}
            </FormHelperText>
          </div>
        </FormControl>
      )}
    />
  );
}
