import { Claims } from "@cloudentity/acp-admin";

import adminClaimsApi from "./adminClaimsApi";
import { useQueryWithWorkspacePermissionCheck, withQueryError } from "./queryUtils";

const GET_CLAIMS_QUERY = "GET_CLAIMS_QUERY";

export const getClaimsQueryKey = (tid: string, wid: string) => [GET_CLAIMS_QUERY, tid, wid];

export const useGetClaims = (tid: string, wid: string) =>
  useQueryWithWorkspacePermissionCheck<Claims>(
    wid,
    "read_claims",
    getClaimsQueryKey(tid, wid),
    withQueryError<Claims>(async () => {
      const data = await adminClaimsApi.getClaims({ wid });
      return data.data;
    }, "Error occurred while trying to fetch claims")
  );
