import { useParams } from "react-router";

import { usePrevious } from "../../../common/utils/hooks/usePrevious";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { getRecentWorkspaces } from "../workspaces/WorkspaceSelector";

export const useWorkspace = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const workspace = workspaceId || getRecentWorkspaces().at(0) || "";

  useCheckWorkspacePermissions(workspace);

  const previousWorkspace = usePrevious(workspace);

  return [workspace, previousWorkspace] as const;
};
