export const defaultBusinessMetadataSchemaId = "default_business_metadata";
export const payloadSchemaLabel = "User Attributes Schema";
export const metadataSchemaLabel = "Admin Managed User Attributes Schema";
export const businessMetadataSchemaLabel = "Business Admin Managed User Attributes Schema";

export const payloadSchemaLabelTooltip =
  "This schema allows both users and administrators to modify attributes, offering flexibility and control over user-defined settings. Users can personalize their experience, while administrators retain the ability to adjust attributes as needed, ensuring alignment with organizational goals and standards.";
export const metadataSchemaLabelTooltip =
  "This schema enables administrators to oversee and control user attributes within the system. Administrators possess the authority to define, edit, and manage user attributes, ensuring consistency and adherence to organizational standards. By leveraging this schema, administrators can streamline user management processes, maintain data integrity, and effectively uphold security protocols.";
export const businessMetadataSchemaLabelTooltip =
  "This schema empowers business administrators in B2B scenarios to customize user attributes within the system. Specifically tailored for business-to-business applications, it grants administrators the ability to delegate the management of attributes outlined within this schema to other business administrators. This delegation facilitates efficient control over user profiles.";
