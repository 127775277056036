import React, { CSSProperties, useEffect, useRef, useState } from "react";

import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = (isError: boolean) =>
  makeStyles()(theme => ({
    root: {
      ".MuiInputBase-root": {
        backgroundColor: theme.custom.greys.fieldBackground,
      },
      ".MuiOutlinedInput-notchedOutline": {
        boxShadow: theme.custom.shadows.formInner,
      },
    },
    input: {
      textAlign: "left",
    },
    inputBase: {
      marginLeft: 4,
      "&::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
    },
    iconRoot: {
      fontSize: "1rem",
      "&:hover": {
        cursor: "pointer",
      },
    },
    arrowsContainer: {
      display: "flex",
      flexDirection: "column",
      marginRight: 16,
      position: "absolute",
      right: 2,
      top: 12,
    },
    outerRoot: {
      "& label": {
        color: "rgba(0, 0, 0, 0.54) !important",
      },
      "& fieldset": {
        borderColor: isError
          ? `${theme.palette.error.main} !important`
          : "rgba(0, 0, 0, 0.23) !important",
        borderWidth: isError ? "2px !important" : "1px !important",
      },
    },
    outerRootWithFocus: {
      "& label": {
        color: theme.palette.primary.main,
      },
      "& fieldset": {
        borderColor: `${theme.palette.primary.main} !important`,
        borderWidth: 2,
      },
    },
    measureInputWidth: {
      position: "absolute",
      top: -1000,
      left: -1000,
      height: "auto",
      width: "auto",
      whiteSpace: "nowrap",
    },
  }));

// A Duration represents the elapsed time between two instants
// as an int64 nanosecond count. The representation limits the
// largest representable duration to approximately 290 years.
const MAX_HOURS = 2540400;

const handleClickInput = ref => event => {
  event.persist();
  event.stopPropagation();
  event.preventDefault();
  const value = ref.current.value;
  ref.current.focus();
  if (value) {
    ref.current.setSelectionRange(0, value.length);
  }
};

export const ttlValueToModel = duration => {
  const pattern = /(\d+(?:\.\d+)?)(h|ms|m|s|µ|n)/g;
  let match;
  let hours = 0,
    minutes = 0,
    seconds = 0,
    milliseconds = 0;
  let explicitMilliseconds = false;

  while ((match = pattern.exec(duration))) {
    const value = parseFloat(match[1]);
    switch (match[2]) {
      case "h":
        hours = value;
        break;
      case "m":
        minutes = value;
        break;
      case "s":
        seconds = Math.floor(value);
        if (!explicitMilliseconds) {
          milliseconds += parseFloat((value % 1).toFixed(3)) * 1000;
        }
        break;
      case "ms":
        milliseconds += value;
        explicitMilliseconds = true;
        break;
      case "µ":
        milliseconds += value / 1000;
        break;
      case "n":
        milliseconds += value / 1000000;
        break;
    }
  }

  // Convert everything to integers
  hours = Math.floor(hours);
  minutes = Math.floor(minutes);
  seconds = Math.floor(seconds);
  milliseconds = Math.floor(milliseconds);

  return { hours, min: minutes, sec: seconds, milliseconds };
};

export const setImplicitTTLValues = value => {
  const model = ttlValueToModel(value);
  return `${model.hours}h${model.min}m${model.sec}s`;
};

const RANGES = {
  hours: {
    min: 0,
    max: MAX_HOURS,
  },
  min: {
    min: 0,
    max: 59,
  },
  sec: {
    min: 0,
    max: 59,
  },
  milliseconds: {
    min: 0,
    max: 999,
  },
};

interface Props {
  value: string;
  onChange: (value: string) => void;
  id: string;
  isError?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  withMilliseconds?: boolean;
}

export default function TextFieldTTLEditor({
  value,
  onChange,
  id,
  isError = false,
  style = {},
  disabled,
  withMilliseconds,
}: Props) {
  const { cx, classes } = useStyles(isError)();
  const hoursRef = useRef<HTMLInputElement | null>(null);
  const minRef = useRef<HTMLInputElement | null>(null);
  const secRef = useRef<HTMLInputElement | null>(null);
  const millisecondsRef = useRef<HTMLInputElement | null>(null);

  const [model, setModel] = useState(ttlValueToModel(value));

  useEffect(() => {
    setModel(ttlValueToModel(value));
  }, [value]);

  const [activeField, setActiveField] = useState("hours");
  const [activeRef, setActiveRef] = useState(hoursRef);
  const [hasFocus, setFocus] = useState(false);

  const updateModelAndChange = newModel => {
    setModel(newModel);
    onChange(
      `${newModel.hours}h${newModel.min}m${newModel.sec}s${
        withMilliseconds ? `${newModel.milliseconds}ms` : ""
      }`
    );
  };

  const handleChange = (field, min, max) => event => {
    event.persist();
    const value = event.target.value;
    const re = /^[0-9]+$/;
    if ((value === "" || re.test(value)) && value >= min && value <= max) {
      const newModel = { ...model, [field]: (parseInt(value) || 0).toString() };
      updateModelAndChange(newModel);
    }
  };

  const handleBlur = field => e => {
    if (!model[field]) {
      const newModel = { ...model, [field]: 0 };
      updateModelAndChange(newModel);
    }
  };

  const handleFocus = (field, ref) => () => {
    setActiveField(field);
    setActiveRef(ref);
    setFocus(true);
  };

  const handleIncrement = e => {
    e.persist();
    setFocus(true);
    e.stopPropagation();
    e.preventDefault();
    setTimeout(() => {
      activeRef?.current?.focus();
      activeRef?.current?.setSelectionRange(0, activeRef.current.value.length);
    }, 10);
    if (RANGES[activeField].max > model[activeField]) {
      const newModel = { ...model, [activeField]: (parseInt(model[activeField]) + 1).toString() };
      updateModelAndChange(newModel);
    }
  };

  const handleDecrement = e => {
    e.persist();
    setFocus(true);
    e.stopPropagation();
    e.preventDefault();
    setTimeout(() => {
      activeRef?.current?.focus();
      activeRef?.current?.setSelectionRange(0, activeRef?.current?.value.length);
    }, 10);
    if (RANGES[activeField].min < model[activeField]) {
      const newModel = { ...model, [activeField]: (parseInt(model[activeField]) - 1).toString() };
      updateModelAndChange(newModel);
    }
  };

  const handleClickOuterInput = () => {
    setActiveRef(hoursRef);
    hoursRef?.current?.focus();
    hoursRef?.current?.setSelectionRange(0, hoursRef?.current?.value.length);
  };

  const handleKeydown = e => {
    if (e.keyCode === 38) {
      handleIncrement(e);
    }
    if (e.keyCode === 40) {
      handleDecrement(e);
    }
  };

  const outerInputShouldFocus = () => {
    return hasFocus;
  };

  const calcInputWidth: any = (text, font = "16px Roboto") => {
    // re-use canvas object for better performance
    const canvas =
      calcInputWidth.canvas || (calcInputWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width + 4;
  };

  return (
    <ClickAwayListener onClickAway={() => setFocus(false)}>
      <div style={{ position: "relative", width: withMilliseconds ? 260 : 200, ...style }}>
        <TextField
          variant="outlined"
          value={" "}
          onClick={handleClickOuterInput}
          onFocus={handleClickOuterInput}
          classes={{
            root: cx(
              classes.root,
              outerInputShouldFocus() ? classes.outerRootWithFocus : classes.outerRoot
            ),
          }}
          fullWidth
          disabled={disabled}
        />
        <div
          style={{ position: "absolute", top: 12, left: 16, display: "flex", alignItems: "center" }}
        >
          <Input
            id={`${id}-hours`}
            onClick={handleClickInput(hoursRef)}
            onChange={handleChange("hours", RANGES.hours.min, RANGES.hours.max)}
            onBlur={handleBlur("hours")}
            onFocus={handleFocus("hours", hoursRef)}
            onKeyDown={handleKeydown}
            className={classes.inputBase}
            classes={{ input: classes.input }}
            value={model.hours}
            inputProps={{ "aria-label": "hours", "data-testid": `${id}-hours` }}
            inputRef={hoursRef}
            style={{ width: calcInputWidth(model.hours) }}
            disabled={disabled}
          />
          <Typography onClick={handleClickInput(hoursRef)}>h</Typography>
          <Typography style={{ marginLeft: 6 }}>:</Typography>
          <Input
            id={`${id}-min`}
            onClick={handleClickInput(minRef)}
            onChange={handleChange("min", RANGES.min.min, RANGES.min.max)}
            onBlur={handleBlur("min")}
            onFocus={handleFocus("min", minRef)}
            onKeyDown={handleKeydown}
            className={classes.inputBase}
            classes={{ input: classes.input }}
            value={model.min}
            inputProps={{ "aria-label": "minutes", "data-testid": `${id}-min` }}
            inputRef={minRef}
            style={{ width: calcInputWidth(model.min) }}
            disabled={disabled}
          />
          <Typography onClick={handleClickInput(minRef)}>m</Typography>
          <Typography style={{ marginLeft: 6 }}>:</Typography>
          <Input
            id={`${id}-sec`}
            onClick={handleClickInput(secRef)}
            onChange={handleChange("sec", RANGES.sec.min, RANGES.sec.max)}
            onBlur={handleBlur("sec")}
            onFocus={handleFocus("sec", secRef)}
            onKeyDown={e => {
              if (e.keyCode === 9) {
                // Tab
                e.preventDefault();
                if (withMilliseconds) {
                  millisecondsRef?.current?.focus();
                } else {
                  hoursRef?.current?.focus();
                }
              }
              handleKeydown(e);
            }}
            className={classes.inputBase}
            classes={{ input: classes.input }}
            value={model.sec}
            inputProps={{ "aria-label": "seconds", "data-testid": `${id}-sec` }}
            inputRef={secRef}
            style={{ width: calcInputWidth(model.sec) }}
            disabled={disabled}
          />
          <Typography onClick={handleClickInput(secRef)}>s</Typography>
          {withMilliseconds && (
            <>
              <Typography style={{ marginLeft: 6 }}>:</Typography>
              <Input
                id={`${id}-milliseconds`}
                onClick={handleClickInput(millisecondsRef)}
                onChange={handleChange(
                  "milliseconds",
                  RANGES.milliseconds.min,
                  RANGES.milliseconds.max
                )}
                onBlur={handleBlur("milliseconds")}
                onFocus={handleFocus("milliseconds", millisecondsRef)}
                onKeyDown={e => {
                  if (e.keyCode === 9) {
                    // Tab
                    e.preventDefault();
                    hoursRef?.current?.focus();
                  }
                  handleKeydown(e);
                }}
                className={classes.inputBase}
                classes={{ input: classes.input }}
                value={model.milliseconds}
                inputProps={{
                  "aria-label": "millisecondsonds",
                  "data-testid": `${id}-milliseconds`,
                }}
                inputRef={millisecondsRef}
                style={{ width: calcInputWidth(model.milliseconds) }}
                disabled={disabled}
              />
              <Typography onClick={handleClickInput(millisecondsRef)}>ms</Typography>
            </>
          )}
        </div>
        <div className={classes.arrowsContainer}>
          <ArrowUpIcon
            classes={{ root: classes.iconRoot }}
            onClick={e => {
              if (!disabled) {
                handleIncrement(e);
              }
            }}
            id={`${id}-arrow-up`}
            style={disabled ? { color: "gray", userSelect: "none", cursor: "default" } : {}}
          />
          <ArrowDownIcon
            classes={{ root: classes.iconRoot }}
            onClick={e => {
              if (!disabled) {
                handleDecrement(e);
              }
            }}
            id={`${id}-arrow-down`}
            style={disabled ? { color: "gray", userSelect: "none", cursor: "default" } : {}}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
}
