import React, { useState } from "react";

import { Token } from "@cloudentity/acp-permissions";

import Dialog from "../../../../common/components/Dialog";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";

interface Props {
  onSubmit: (data: any) => Promise<void>;
  onClose: () => void;
  data?: Token;
}

function PermissionTokenCreateUpdateDialog({ onSubmit, onClose, data }: Props) {
  const [progress, setProgress] = useState(false);

  const id = data ? "permission-token-update" : "permission-token-create";
  const text = data ? "Update" : "Create";

  const form = useForm({ id, progress, initialValues: data ?? undefined });

  return (
    <Dialog id={`${id}-dialog`} title={`${text} Token`} onClose={onClose}>
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          autoFocus
          rules={{
            validate: {
              onlyAlphanumeric: validators.onlyAlphanumericWithWhitespaces({ label: "Name" }),
            },
          }}
        />

        <TextField name="description" label="Description" />

        <FormFooter
          onCancel={onClose}
          onSubmit={data => {
            setProgress(true);
            onSubmit(data).finally(() => setProgress(false));
          }}
          submitText={text}
        />
      </Form>
    </Dialog>
  );
}

export default PermissionTokenCreateUpdateDialog;
