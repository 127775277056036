import { Template, TemplatePaths } from "@cloudentity/acp-admin";

import adminTemplatesApi from "./adminTemplatesApi";
import { useQueryWithTenantPermissionCheck, withQueryError } from "./queryUtils";

const LIST_DEFAULT_TEMPLATES_QUERY = "LIST_DEFAULT_TEMPLATES_QUERY";
const GET_DEFAULT_TEMPLATE_QUERY = "GET_DEFAULT_TEMPLATE_QUERY";
const GET_TEMPLATE_QUERY = "GET_TEMPLATE_QUERY";

export const listDefaultTemplatesQueryKey = (tid: string) => [LIST_DEFAULT_TEMPLATES_QUERY, tid];
export const getDefaultTemplateQueryKey = (tid: string, fsPath: string) => [
  GET_DEFAULT_TEMPLATE_QUERY,
  tid,
  fsPath,
];
export const getTemplateQueryKey = (tid: string, themeID: string, fsPath: string) => [
  GET_TEMPLATE_QUERY,
  tid,
  themeID,
  fsPath,
];

export const useListDefaultTemplates = (tid: string, options?: any) =>
  useQueryWithTenantPermissionCheck(
    "read_system_templates",
    listDefaultTemplatesQueryKey(tid),
    withQueryError<TemplatePaths>(async () => {
      const data = await adminTemplatesApi.listDefaultTemplates();
      return data.data;
    }, "Error occurred while trying to fetch default templates"),
    options
  );

export const useGetDefaultTemplate = (tid: string, fsPath: string, options?: any) =>
  useQueryWithTenantPermissionCheck(
    "read_system_templates",
    getDefaultTemplateQueryKey(tid, fsPath),
    withQueryError<Template>(async () => {
      const data = await adminTemplatesApi.getDefaultTemplate({ fsPath: fsPath });
      return data.data;
    }, "Error occurred while trying to fetch default template"),
    options
  );

export const useGetTemplate = (tid: string, themeID: string, fsPath: string, options?: any) =>
  useQueryWithTenantPermissionCheck(
    "read_system_templates",
    getTemplateQueryKey(tid, themeID, fsPath),
    async () => {
      try {
        const data = await adminTemplatesApi.getTemplate({ themeID, fsPath });
        return data.data;
      } catch (e) {}
      //
    },
    options
  );
