import React, { useState } from "react";
import { useNavigate } from "react-router";

import { CustomApp } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import SetExtensionCard from "../../../common/components/extensions/SetExtensionCard";
import { useListServerCustomApps } from "../../services/adminCustomAppsQuery";
import { IdpUiModelType } from "./identities.utils";

interface Props {
  idp: IdpUiModelType;
  updateProgress: boolean;
  onUpdate: (provider: IdpUiModelType) => void;
  editable: boolean;
  hideButtonLink?: boolean;
}

export default function ConnectCustomApplicationExtension({
  idp,
  updateProgress,
  onUpdate,
  editable,
  hideButtonLink,
}: Props) {
  const tenantId = getTenantId();

  const [isExtensionInputFocused, setIsExtensionInputFocused] = useState(false);
  const navigate = useNavigate();

  const customAppsQuery = useListServerCustomApps(tenantId, idp.authorization_server_id!);

  const handleChangeExtension = (value: CustomApp | null) => {
    setIsExtensionInputFocused(false);
    onUpdate({
      ...idp,
      post_authn_app_id: value ? value.id : undefined,
    });
  };

  const progress = customAppsQuery.isFetching || updateProgress;

  return (
    <SetExtensionCard
      id="connect-custom-application-extension"
      label="Post Authentication application"
      options={customAppsQuery.data?.custom_apps ?? []}
      value={
        (customAppsQuery.data?.custom_apps || []).find(ca => ca.id === idp.post_authn_app_id) ||
        null
      }
      placeholder="Select custom application"
      disabled={progress || !editable}
      isInputFocused={isExtensionInputFocused}
      setIsInputFocused={setIsExtensionInputFocused}
      progress={progress}
      handleChange={handleChangeExtension}
      onClick={() => {
        navigate(`/${idp.authorization_server_id}/custom-applications`);
      }}
      buttonLabel="Manage Custom Apps"
      hideButtonLink={hideButtonLink}
    />
  );
}
