import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { Server } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import adminServersApi from "../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  useGetAuthorizationServer,
} from "../../services/adminServersQuery";
import { useWorkspace } from "../common/useWorkspace";
import AuthNContextAttributesList from "./AuthNContextAttributesList";
import AuthNContextTableToolbar from "./AuthNContextTableToolbar";

export default function AuthNContextView() {
  const [progress, setProgress] = useState(false);
  const [workspace] = useWorkspace();

  const [searchText, setSearchText] = useState("");

  const queryClient = useQueryClient();
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);
  const attributes = serverQuery?.data?.authentication_context_settings?.attributes ?? [];

  const handleRemove = (name: string) => {
    const server = {
      ...serverQuery?.data,
      authentication_context_settings: {
        ...serverQuery?.data?.authentication_context_settings,
        attributes: attributes.filter(attribute => attribute.name !== name),
      },
    };

    return updateServer(server as Server);
  };

  const updateServer = (server: Server) => {
    setProgress(true);
    return adminServersApi
      .updateAuthorizationServer({ wid: workspace, server })
      .then(res => {
        queryClient.setQueryData(
          getAuthorizationServerQueryKey(getTenantId(), workspace),
          res.data
        );
        return res;
      })
      .catch(handleErrorWithNotify("Error occurred while trying to update authorization server"))
      .finally(() => setProgress(false));
  };

  const filteredAttributes = attributes.filter(
    attribute =>
      !searchText ||
      (attribute.name || "").toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );
  return (
    <>
      <AuthNContextTableToolbar
        search={searchText}
        onUpdateSearch={search => setSearchText(search)}
      />

      <AuthNContextAttributesList
        searchText={searchText}
        attributes={filteredAttributes}
        progress={progress || serverQuery.isLoading}
        onRemove={handleRemove}
      />
    </>
  );
}
