import React, { useMemo, useState } from "react";

import Menu from "@mui/material/Menu";
import { useQueries } from "@tanstack/react-query";

import { GrantWorkspaceRoleRequestRoleEnum } from "@cloudentity/acp-admin";

import emptyImg from "../../../assets/images/emptyStates/workspace-administrators.svg";
import { getTenantId } from "../../../common/api/paths";
import EmptyState from "../../../common/components/EmptyState";
import InfoBox from "../../../common/components/InfoBox";
import Progress from "../../../common/components/Progress";
import RouterLink from "../../../common/components/RouterLink";
import { useListIDPs } from "../../services/adminIDPsQuery";
import identityPoolsApi from "../../services/adminIdentityPoolsApi";
import { getPoolQueryKey } from "../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../services/adminIdentityUsersApi";
import { getUserQueryKey } from "../../services/adminIdentityUsersQuery";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { useListWorkspaceRoles } from "../../services/adminRolesQuery";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import { useWorkspace } from "../common/useWorkspace";
import ManageUserActionsResendActivationEmail from "../workspaceDirectory/administrator/ManageUserActionsResendActivationEmail";
import { isAnyWorkspaceOrPoolRoleAllowed } from "../workspaceDirectory/administrator/WorkspaceRoleSelectField";
import { getUserRolesBySubjects } from "../workspaceDirectory/identityPools/identityPool/users/list/utils";
import { isOpenbanking } from "../workspaceDirectory/server-profiles";
import ChangeAdminRole from "./ChangeAdminRole";
import ChangeAdminRoleDialog from "./ChangeAdminRoleDialog";
import InviteWorkspaceAdministrator from "./InviteWorkspaceAdministrator";
import RevokeWorkspaceRole from "./RevokeWorkspaceRole";
import WorkspaceAdministratorsTable, {
  UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles,
} from "./WorkspaceAdministratorsTable";
import WorkspaceAdministratorsToolbar from "./WorkspaceAdministratorsToolbar";

export default function WorkspaceAdministrators() {
  const [workspace] = useWorkspace();
  const [menu, setMenu] = useState<{
    anchorEl: HTMLElement | null;
    open: boolean;
    data?: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles;
  } | null>();
  const [changeRoleDialogOpen, setChangeRoleDialogOpen] = useState<{
    data: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles;
  } | null>();
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({});

  const listWorkspaceRolesQuery = useListWorkspaceRoles(workspace);
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);
  const idpsQuery = useListIDPs(getTenantId(), "admin");
  const poolIDsConnectedToAdminWorkspace = useMemo(
    () =>
      (idpsQuery.data?.idps || [])
        .filter(idp => !!idp.identity_pool_id)
        .map(idp => idp.identity_pool_id),
    [idpsQuery.data]
  );
  const isOpenbankingWorkspace = isOpenbanking(serverQuery.data?.profile);

  const subjectsFromAnyIdentityPoolConnectedToAdminWorkspace = useMemo(
    () =>
      (listWorkspaceRolesQuery.data?.subjects || []).filter(sub =>
        poolIDsConnectedToAdminWorkspace.includes(sub.identity_pool_id)
      ),
    [poolIDsConnectedToAdminWorkspace, listWorkspaceRolesQuery.data]
  );

  const userQueries = useQueries({
    queries: subjectsFromAnyIdentityPoolConnectedToAdminWorkspace.map(subject => {
      return {
        queryKey: getUserQueryKey(getTenantId(), subject.identity_pool_user_id),
        queryFn: async () => {
          const data = await identityUsersApi.getUser({
            ipID: subject.identity_pool_id!,
            userID: subject.identity_pool_user_id!,
          });

          return data.data;
        },
      };
    }),
  });

  const poolQueries = useQueries({
    queries: subjectsFromAnyIdentityPoolConnectedToAdminWorkspace.map(subject => {
      return {
        queryKey: getPoolQueryKey(getTenantId(), subject.identity_pool_id!),
        queryFn: async () => {
          const data = await identityPoolsApi.getPool({
            ipID: subject.identity_pool_id!,
          });

          return data.data;
        },
      };
    }),
  });

  const usersAndPoolsAndRoles = useMemo(
    () =>
      userQueries
        .map(q => q.data)
        .map(u => {
          const subject = (listWorkspaceRolesQuery.data?.subjects || []).find(
            s => s.identity_pool_user_id === u?.id
          );

          let roles: GrantWorkspaceRoleRequestRoleEnum[] = [];
          if (subject) {
            roles = getUserRolesBySubjects(u, [], [subject], []).workspace;
          }

          return {
            user: u,
            identity_pool: poolQueries.map(q => q.data).find(p => p?.id === u?.user_pool_id),
            subject,
            roles,
          };
        }),
    [userQueries, listWorkspaceRolesQuery.data?.subjects, poolQueries]
  );

  const progress =
    userQueries.some(q => q.isLoading) ||
    poolQueries.some(q => q.isLoading) ||
    idpsQuery.isLoading ||
    listWorkspaceRolesQuery.isLoading ||
    serverQuery.isLoading ||
    checkWorkspacePermissionsQuery.isLoading;

  const usersAndPoolsAndRolesFiltered = useMemo(
    () =>
      usersAndPoolsAndRoles.filter(up => {
        const s = JSON.stringify(up);
        return !searchText
          ? true
          : s.toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim());
      }),
    [searchText, usersAndPoolsAndRoles]
  );

  return (
    <>
      {(progress || !idpsQuery.isFetched || !listWorkspaceRolesQuery.isFetched) && <Progress />}
      {!progress && idpsQuery.isFetched && listWorkspaceRolesQuery.isFetched && (
        <>
          {!isOpenbankingWorkspace && (
            <InfoBox
              id="settings-administrators"
              title="Workspace administrators and organization administrators"
            >
              Every organization is a workspace thus organization administration can be delegated to
              tenant admin users in addition to organization users.{" "}
              <b>This view shows only workspace administrators.</b> To manage organization
              administrators go to <RouterLink to={`/${workspace}/pools`}>Users</RouterLink> view.
            </InfoBox>
          )}
          {subjectsFromAnyIdentityPoolConnectedToAdminWorkspace.length > 0 && (
            <>
              <WorkspaceAdministratorsToolbar
                search={searchText}
                filters={filters}
                usersAndPoolsAndRoles={usersAndPoolsAndRoles}
                onUpdateFilters={f => setFilters(f)}
                onUpdateSearch={s => setSearchText(s)}
              />
              <WorkspaceAdministratorsTable
                usersAndPoolsAndRoles={usersAndPoolsAndRolesFiltered}
                progress={progress}
                hasManagePermission={isAnyWorkspaceOrPoolRoleAllowed({
                  manage_admin_role: checkWorkspacePermissionsQuery.data?.manage_admin_role,
                  manage_auditor_role: checkWorkspacePermissionsQuery.data?.manage_auditor_role,
                })}
                onRowClick={userAndPoolAndRoles =>
                  setChangeRoleDialogOpen({ data: userAndPoolAndRoles })
                }
                onMenuOpen={(anchorEl, userAndPoolAndRoles) =>
                  setMenu({ anchorEl, data: userAndPoolAndRoles, open: true })
                }
              />
              {menu && (
                <Menu
                  anchorEl={menu.anchorEl}
                  open={Boolean(menu.open)}
                  onClose={() => setMenu({ ...menu, open: false })}
                  keepMounted={!!menu.anchorEl}
                  onClickCapture={() => setMenu({ ...menu, open: false })}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <ChangeAdminRole user={menu.data?.user} workspace={workspace} />
                  <RevokeWorkspaceRole user={menu.data?.user} workspace={workspace} />
                  <ManageUserActionsResendActivationEmail
                    user={menu.data?.user}
                    workspaceId={workspace}
                    onClick={() => {}}
                  />
                </Menu>
              )}
              {changeRoleDialogOpen && (
                <ChangeAdminRoleDialog
                  user={changeRoleDialogOpen.data.user}
                  workspace={workspace}
                  onSuccess={() => setChangeRoleDialogOpen(null)}
                  onCancel={() => setChangeRoleDialogOpen(null)}
                />
              )}
            </>
          )}

          {subjectsFromAnyIdentityPoolConnectedToAdminWorkspace.length === 0 && (
            <EmptyState
              style={{ marginTop: 100 }}
              imgSize={12}
              img={emptyImg}
              title="Invite Administrator"
              description="This workspace has no administrators assigned yet"
              actionButton={<InviteWorkspaceAdministrator usersAndPoolsAndRoles={[]} />}
            />
          )}
        </>
      )}
    </>
  );
}
