import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Navigate, Route, Routes } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";

import { getAdminPortalUrl, getTenantId } from "../../common/api/paths";
import { useSilentAuthentication } from "../../common/auth/useSilentAuthentication";
import { useFeature } from "../../common/utils/hooks/useFeature";
import { GlobalToursProvider } from "../GlobalTours/GlobalTours";
import { useGetEnvironment, useGetWorkspaceEnvironment } from "../services/adminEnvironmentQuery";
import { useCheckTenantPermissions, useGetTenant } from "../services/adminTenantsQuery";
import { useReactQuerySubscription } from "../services/useReactQuerySubscription";
import AdminPanel from "./AdminPanel";
import PageContainer from "./common/PageContainer";
import GetStarted from "./getStarted/GetStarted";
import SelectEcosystem from "./welcome/workspaces/SelectEcosystem";
import SetupBranding from "./welcome/workspaces/SetupBranding";
import WelcomeWorkspaces from "./welcome/workspaces/WelcomeWorkspaces";
import WorkspaceDirectory from "./workspaceDirectory/WorkspaceDirectory";
import { getRecentWorkspaces } from "./workspaces/WorkspaceSelector";

const InitializeDemo = lazy(() => import("./InitializeDemo"));
const WelcomeWorkspaceCreateContainer = lazy(
  () => import("./welcome/WelcomeWorkspaceCreateContainer")
);

interface Props {
  tenantId: string;
  isLoading: boolean;
}

function AuthenticatedAdminAppBase({ tenantId, isLoading }: Props) {
  useSilentAuthentication();

  const navigate = useNavigate();
  const [initializeDemo, setInitializeDemo] = useState(false);

  const withPermissions = useFeature("with_permissions");
  const isNewOnboardingEnabled = useFeature("new_onboarding");
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const getTenantQuery = useGetTenant(getTenantId());

  const shouldRedirectToB2BPortal =
    checkTenantPermissionsQuery.data?.create_organization &&
    !checkTenantPermissionsQuery.data.create_workspace;

  useReactQuerySubscription();

  const onFinish = useCallback(aid => {
    navigate(`/${aid}/overview`);
    setInitializeDemo(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdminWorkspaceEnvironmentQuery = useGetWorkspaceEnvironment("admin");
  const getEnvironmentQuery = useGetEnvironment();

  useEffect(() => {
    if (getEnvironmentQuery.data?.display_workspace_wizard) {
      if (getEnvironmentQuery.data?.initialize_demo_workspace) {
        setInitializeDemo(true);
      } else {
        navigate("/welcome");
      }
    }
  }, [getEnvironmentQuery.data, navigate]);

  const issuerUrl = getEnvironmentQuery.data?.admin_issuer_url;

  if (checkTenantPermissionsQuery.isSuccess && shouldRedirectToB2BPortal && issuerUrl) {
    window.location.href = getAdminPortalUrl(issuerUrl);
    return null;
  }

  const shouldVisitGetStarted = !!getTenantQuery.data?.metadata?.should_visit_get_started;
  const getStartedUrl = isNewOnboardingEnabled ? "/demo/get-started-v2/welcome" : "/get-started";
  const recentWorkspaces = getRecentWorkspaces();
  const initialPath =
    (shouldVisitGetStarted && getStartedUrl) ||
    (recentWorkspaces.length > 0 && `/${recentWorkspaces[0]}/overview`) ||
    "/workspace-directory";

  return (
    <>
      <CssBaseline />
      <PageContainer
        progress={
          isLoading ||
          getEnvironmentQuery.isLoading ||
          getAdminWorkspaceEnvironmentQuery.isLoading ||
          checkTenantPermissionsQuery.isLoading ||
          getTenantQuery.isLoading
        }
      >
        {initializeDemo && <InitializeDemo onFinish={onFinish} />}
        {!initializeDemo && (
          <Suspense fallback={<PageContainer progress />}>
            <GlobalToursProvider>
              <Routes>
                <Route path="/" element={<Navigate to={initialPath} replace />} />
                <Route path="/welcome" element={<WelcomeWorkspaceCreateContainer />} />
                <Route path="/welcome-workspaces" element={<WelcomeWorkspaces />} />
                <Route path="/select-ecosystem" element={<SelectEcosystem />} />
                <Route path="/setup-branding" element={<SetupBranding />} />
                <Route path="/get-started" element={<GetStarted />} />

                <Route
                  path="/workspace-directory"
                  element={<WorkspaceDirectory path="/workspace-directory" />}
                />
                <Route
                  path="/organization-directory"
                  element={<Navigate to="/organization-directory/organizations" replace />}
                />
                <Route
                  path="/organization-directory/:tab"
                  element={<WorkspaceDirectory path="/organization-directory/:tab" />}
                />
                <Route path="/mfa-settings" element={<WorkspaceDirectory path="/mfa-settings" />} />
                <Route
                  path="/message-providers/:tab"
                  element={<WorkspaceDirectory path="/message-providers/:tab" />}
                />
                <Route
                  path="/brute-force-protection/:type"
                  element={<WorkspaceDirectory path="/brute-force-protection/:type" />}
                />
                <Route
                  path="/brute-force-protection"
                  element={<WorkspaceDirectory path="/brute-force-protection" />}
                />
                <Route
                  path="/appearance/branding"
                  element={<WorkspaceDirectory path="/appearance/branding" />}
                />
                <Route
                  path="/appearance/themes"
                  element={<WorkspaceDirectory path="/appearance/themes" />}
                />
                <Route
                  path="/appearance/themes/editor/:id"
                  element={<WorkspaceDirectory path="/appearance/themes/editor/:id" />}
                />
                <Route path="/appearance" element={<WorkspaceDirectory path="/appearance" />} />
                <Route
                  path="/identity-pools/pools/:id/users/:userID"
                  element={<WorkspaceDirectory path="/identity-pools/pools/:id/users/:userID" />}
                />
                <Route
                  path="/identity-pools/pools/:id/:tab"
                  element={<WorkspaceDirectory path="/identity-pools/pools/:id/:tab" />}
                />
                <Route
                  path="/identity-pools/schemas/:id/:tab"
                  element={<WorkspaceDirectory path="/identity-pools/schemas/:id/:tab" />}
                />
                <Route
                  path="/identity-pools/:tab"
                  element={<WorkspaceDirectory path="/identity-pools/:tab" />}
                />
                <Route
                  path="/administrators/users"
                  element={<WorkspaceDirectory path="/administrators/users" />}
                />
                <Route
                  path="/administrators/sign-in"
                  element={<WorkspaceDirectory path="/administrators/sign-in" />}
                />
                <Route
                  path="/administrators/early-access-features"
                  element={<WorkspaceDirectory path="/administrators/early-access-features" />}
                />

                {withPermissions && (
                  <>
                    <Route
                      path="/permissions"
                      element={<WorkspaceDirectory path="/permissions" />}
                    />
                    <Route
                      path="/permissions/:id/:tab"
                      element={<WorkspaceDirectory path="/permissions/:id/:tab" />}
                    />
                  </>
                )}

                <Route path="/:workspaceId/*" element={<AdminPanel />} />
                <Route
                  path="*"
                  element={
                    getEnvironmentQuery.isSuccess && getAdminWorkspaceEnvironmentQuery.isSuccess ? (
                      <Navigate to="/workspace-directory" replace />
                    ) : null
                  }
                />
              </Routes>
            </GlobalToursProvider>
          </Suspense>
        )}
      </PageContainer>
    </>
  );
}

export default AuthenticatedAdminAppBase;
