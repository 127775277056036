import React from "react";

import Fab from "../../../../common/components/Fab";

interface Props {
  actionButtonLabel: string;
  onClick: () => void;
}

export default function ToolbarAction({ actionButtonLabel, onClick }: Props) {
  return (
    <Fab onClick={onClick} id="table-toolbar-action-button" style={{ marginLeft: 32 }}>
      {(actionButtonLabel || "").toLocaleUpperCase()}
    </Fab>
  );
}
