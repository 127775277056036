import {
  SystemsApi,
  SystemsApiCreateSystemRequest,
  SystemsApiDeleteSystemRequest,
  SystemsApiGetSystemRequest,
  SystemsApiListSystemsRequest,
  SystemsApiUpdateSystemRequest,
} from "@cloudentity/acp-permissions";

import { permissionsApiClazz } from "./admin-services.common";

const api: () => SystemsApi = permissionsApiClazz(SystemsApi);

const permissionsSystemsApi = {
  listSystems: (req: SystemsApiListSystemsRequest) => api().listSystems(req),
  createSystem: (req: SystemsApiCreateSystemRequest) => api().createSystem(req),
  getSystem: (req: SystemsApiGetSystemRequest) => api().getSystem(req),
  updateSystem: (req: SystemsApiUpdateSystemRequest) => api().updateSystem(req),
  deleteSystem: (req: SystemsApiDeleteSystemRequest) => api().deleteSystem(req),
};

export default permissionsSystemsApi;
