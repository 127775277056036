import { Oauth2ApiFactory } from "@cloudentity/acp-oauth2";

import { getConfiguration } from "../../common/api/configuration";
import { authPathPrefix } from "../../common/api/paths";
import { axiosInstanceWithoutLogoutRedirect } from "../adminAxiosInstance";

const apiUserInfo = () =>
  Oauth2ApiFactory(
    getConfiguration(authPathPrefix),
    authPathPrefix,
    axiosInstanceWithoutLogoutRedirect
  );

const oauth2Api = {
  userinfo: () => apiUserInfo().userinfo(),
};

export default oauth2Api;
