import React, { useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { RevokeWorkspaceRoleRequestTypeEnum } from "@cloudentity/acp-admin";
import { BaseUserWithData, UserWithData } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import TextField from "../../../common/utils/forms/TextField";
import { useListIDPs } from "../../services/adminIDPsQuery";
import { convertUserWithDataToExtendedUser } from "../../services/adminIdentityUsersQuery";
import adminRolesApi from "../../services/adminRolesApi";
import {
  listUserRoles,
  listWorkspaceRoles,
  useListWorkspaceRoles,
} from "../../services/adminRolesQuery";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import { BUILD_IN_ADMIN_POOL_ID } from "../workspaceDirectory/administrator/AdministratorManagement";
import WorkspaceRoleSelectField from "../workspaceDirectory/administrator/WorkspaceRoleSelectField";
import {
  getUserRolesBySubjects,
  grantWorkspaceRoles,
} from "../workspaceDirectory/identityPools/identityPool/users/list/utils";
import IdentityPoolPayloadSchemaForm from "./IdentityPoolPayloadSchemaForm";
import InviteAdminPoolSelect from "./InviteAdminPoolSelect";
import InviteAdministratorEmailField from "./InviteAdministratorEmailField";
import { subjectToRevokeRoles } from "./manageAccess.utils";
import { serverTypeToRoleOptions } from "./workspaceRoleOptions";

interface Props {
  user?: UserWithData | BaseUserWithData;
  workspace: string;
  onSuccess: () => void;
  onCancel: () => void;
}

export default function ChangeAdminRoleDialog({ user, workspace, onSuccess, onCancel }: Props) {
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();
  const listWorkspaceRolesQuery = useListWorkspaceRoles(workspace);
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);
  const idpsQuery = useListIDPs(getTenantId(), "admin");

  const subject = (listWorkspaceRolesQuery.data?.subjects || []).find(
    s => s.identity_pool_user_id === user?.id
  );

  const initialData = useMemo(
    () => ({
      identity_pool_id: user?.user_pool_id,
      user: convertUserWithDataToExtendedUser(user! as UserWithData),
      roles: getUserRolesBySubjects(user, [], subject ? [subject] : [], []).workspace.at(0) || "",
    }),
    [user, subject]
  );

  const form = useForm({
    id: "manage-access-add-identity-pool-user",
    progress,
    initialValues: initialData,
  });

  const handleChangeRole = data => {
    setProgress(true);

    const roles = subjectToRevokeRoles(subject);

    Promise.all(
      roles.map(role =>
        adminRolesApi.revokeWorkspaceRole({
          wid: workspace,
          request: {
            ...subject,
            type: RevokeWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
            role,
          },
        })
      )
    )
      .then(() => grantWorkspaceRoles(queryClient, workspace, user!, [data.roles]))
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspaceRoles(workspace) }))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listUserRoles(user?.user_pool_id, user?.id!) })
      )
      .then(() => notifySuccess("Administrator role changed successfully"))
      .then(() => onSuccess())
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to change workspace role"))
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <Dialog
      onClose={onCancel}
      id="change-workspace-administrator-dialog"
      fullWidth
      maxWidth="sm"
      title="Change administrator role"
    >
      <Form form={form}>
        <div>
          <InviteAdminPoolSelect idps={idpsQuery.data?.idps || []} disabled />

          <InviteAdministratorEmailField
            poolId={user?.user_pool_id!}
            usersAndPoolsAndRoles={[{ user, subject, identity_pool: undefined, roles: undefined }]}
            disabled
          />

          {user?.user_pool_id === BUILD_IN_ADMIN_POOL_ID && (
            <>
              <TextField
                name="user.payload.given_name"
                label="First name"
                disabled
                optional={false}
              />
              <TextField
                name="user.payload.family_name"
                label="Last name"
                disabled
                optional={false}
              />
            </>
          )}
          {user?.user_pool_id !== BUILD_IN_ADMIN_POOL_ID && (
            <div style={{ marginBottom: 22 }}>
              <IdentityPoolPayloadSchemaForm
                poolId={user?.user_pool_id!}
                payload={user?.payload}
                disabled
              />
            </div>
          )}

          <WorkspaceRoleSelectField
            wid={workspace}
            poolId={initialData.identity_pool_id!}
            workspaceSubjects={listWorkspaceRolesQuery.data?.subjects}
            multiple={false}
            label="Role"
            roleOptions={serverTypeToRoleOptions(serverQuery.data?.type)}
            isInviteWorkspaceAdministrator
          />

          <FormFooter onCancel={onCancel} onSubmit={handleChangeRole} />
        </div>
      </Form>
    </Dialog>
  );
}
