import React from "react";

import Typography from "@mui/material/Typography";
import { Plus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import ReadOnlyInputLabel from "./ReadOnlyInputLabel";

const useStyles = makeStyles()(theme => ({
  container: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    height: 56,
    width: "100%",
    borderRadius: 4,
    background: theme.palette.primary.light,
    marginTop: 12,
    "&:hover": {
      opacity: 0.9,
      cursor: "pointer",
    },
  },
  disabled: {
    opacity: 0.5,
    "&:hover": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
}));

export default function ReadOnlyInputButtonWithLabel({ id, label, content, disabled, onClick }) {
  const { cx, classes } = useStyles();

  return (
    <div style={{ marginBottom: 4 }}>
      <ReadOnlyInputLabel id={id} label={label} />
      <div
        className={cx(classes.container, disabled && classes.disabled)}
        onClick={() => !disabled && onClick()}
        id={`readonly-input-button-${id}`}
      >
        <Typography style={{ fontWeight: 500, fontSize: 14 }}>{content}</Typography>
        <Plus style={{ width: 16, height: 16 }} />
      </div>
    </div>
  );
}
