import React, { useCallback } from "react";

import { makeStyles } from "tss-react/mui";

import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import FiltersToolbar, { Filters } from "../common/EnhancedTableAsync/FiltersToolbar";
import { useWorkspace } from "../common/useWorkspace";
import { isAnyWorkspaceOrPoolRoleAllowed } from "../workspaceDirectory/administrator/WorkspaceRoleSelectField";
import InviteWorkspaceAdministrator from "./InviteWorkspaceAdministrator";
import { UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles } from "./WorkspaceAdministratorsTable";

const useStyles = makeStyles()(() => ({
  toolbar: {
    paddingBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
}));

const filtersSetup = [];

interface Props {
  filters: Filters;
  search: string;
  usersAndPoolsAndRoles: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles[];
  onUpdateFilters: (filters: Filters) => void;
  onUpdateSearch: (search: string) => void;
}

export default function WorkspaceAdministratorsToolbar({
  filters,
  search,
  usersAndPoolsAndRoles,
  onUpdateFilters,
  onUpdateSearch,
}: Props) {
  const { classes } = useStyles();

  const [workspace] = useWorkspace();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  const handleUpdateFilters = useCallback(
    (search, filters) => {
      onUpdateSearch(search);
      onUpdateFilters(filters);
    },
    [onUpdateSearch, onUpdateFilters]
  );

  return (
    <div className={classes.toolbar}>
      <div>
        <FiltersToolbar
          filters={filters}
          filtersSetup={filtersSetup}
          searchText={search}
          searchPlaceholder="Search"
          onUpdate={handleUpdateFilters}
        />
      </div>

      {isAnyWorkspaceOrPoolRoleAllowed(checkWorkspacePermissionsQuery.data) && (
        <div>
          <InviteWorkspaceAdministrator usersAndPoolsAndRoles={usersAndPoolsAndRoles} />
        </div>
      )}
    </div>
  );
}
