import React from "react";

import { Claim } from "@cloudentity/acp-admin";

import Dialog from "../../../../common/components/Dialog";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";

export interface OAuthVerifiedClaimsEditProps {
  model: Claim & { metadata_key?: string };
  existingClaims: string[];
  onEdit: (claim: Claim) => void;
  onCancel: () => void;
  progress: boolean;
}

export default function OAuthVerifiedClaimsEdit({
  model,
  existingClaims,
  onEdit,
  onCancel,
  progress,
}: OAuthVerifiedClaimsEditProps) {
  const form = useForm({ id: "edit-claim", initialValues: model, progress });

  const handleEdit = data => {
    const claim = {
      ...model,
      name: data.name,
      description: data.description,
    };
    onEdit(claim);
  };

  return (
    <Dialog onClose={onCancel} id="edit-claim-dialog" title="Edit verified claim">
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          rules={{
            validate: {
              notUniq: validators.notUniq({
                label: "Name",
                options: existingClaims.filter(v => v !== model.name),
              }),
            },
          }}
          inputProps={{ id: "claim-name-input" }}
        />
        <TextFieldRequired name="description" label="Description" />

        <FormFooter onCancel={onCancel} onSubmit={handleEdit} submitText="Update" />
      </Form>
    </Dialog>
  );
}
