import React from "react";

import { Category, Group } from "./PagesList";

interface Props {
  paths: string[];
  unfolded: string[];
  selectedPath: string;
  previewPath: string;
  hasUnsavedChanges: boolean;
  onEdit: (path: string) => void;
  onCategoryClick: (path: string[]) => void;
  onGroupClick: (path: string) => void;
}

export default function MessagesList({
  paths = [],
  unfolded,
  selectedPath = "",
  previewPath = "",
  hasUnsavedChanges,
  onEdit,
  onCategoryClick,
  onGroupClick,
}: Props) {
  return (
    <>
      <Category
        unfolded={unfolded}
        setUnfolded={onCategoryClick}
        name="Emails"
        path={["pages/emails/"]}
        id="pages/emails/"
      >
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Activate Account With Code"
          id="pages/emails/activate_account_with_code/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Activate Account With Link"
          id="pages/emails/activate_account_with_link/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Address Verification With Link"
          id="pages/emails/address_verification_with_link/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Identifier Taken"
          id="pages/emails/identifier_used/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Verification Code"
          id="pages/emails/otp/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Reset Credentials With Code"
          id="pages/emails/reset_credentials_with_code/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Reset Credentials With Link"
          id="pages/emails/reset_credentials_with_link/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
      </Category>
      <Category
        unfolded={unfolded}
        setUnfolded={onCategoryClick}
        name="SMS messages"
        path={["pages/texts/"]}
        id="pages/texts/"
      >
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Activate Account With Code"
          id="pages/texts/activate_account_with_code/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Activate Account With Link"
          id="pages/texts/activate_account_with_link/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Address Verification With Link"
          id="pages/texts/address_verification_with_link/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Identifier Taken"
          id="pages/texts/identifier_used/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Verification Code"
          id="pages/texts/otp/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Reset Credentials With Code"
          id="pages/texts/reset_credentials_with_code/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Reset Credentials With Link"
          id="pages/texts/reset_credentials_with_link/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
      </Category>
    </>
  );
}
