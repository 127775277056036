import React, { useEffect, useRef, useState } from "react";

import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { ChevronLeft, ChevronRight } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { Styling } from "@cloudentity/acp-admin";

import { acpBasePath, previewBasePath } from "../../../common/api/paths";
import Progress from "../../../common/components/Progress";
import { getNewTheme, logoSizeMapper } from "../../../common/utils/hooks/useStyling";
import AuthenticatedDeveloperAppBase from "../../../developer/components/AuthenticatedDeveloperAppBase";
import AuthenticatedUserAppBase from "../../../user/components/AuthenticatedUserAppBase";
import ThemeTemplatePreview from "../workspaceDirectory/appearance/ThemeTemplatePreview";

const useStyles = makeStyles()(theme => ({
  container: {
    height: "100%",
    minHeight: 990,
    border: "none",
    boxShadow: theme.custom.shadows?.surfaceResting,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: "20px 14px 26px 32px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    color: theme.palette.secondary.dark,
    flex: 1,
  },
  navigation: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "flex-end",
  },
  viewName: {
    color: theme.palette.secondary.light,
    fontSize: 16,
    width: 20,
    textAlign: "center",
    flex: 1,
    position: "relative",
    right: 8,
  },
  stepSpan: {
    width: 20,
    textAlign: "center",
    color: theme.palette.secondary.light,
  },
  slash: {
    color: theme.palette.secondary.light,
  },
  previewContainer: {
    position: "relative",
    flex: 1,
  },
  preview: {
    border: "none",
    pointerEvents: "none",
  },
  menuItem: {
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
    },
  },
  portalPreview: {
    height: "100%",
    "&>div": {
      height: "100%",
    },
  },
}));

interface Props {
  themeId?: string;
  currentData: Styling;
  initialData: Styling;
  mode: "workspace" | "tenant";
  visibleSteps?: string[];
}

function getSteps() {
  return ["Login", "Consent", "MFA", "Error", "User portal", "Developer portal"];
}

export const getBasicStyle = (currentData: Styling, initialData: Styling, mode: string) => {
  return `
    :root {
      --mdc-theme-primary: ${encodeURI(currentData.colors?.primary ?? "")};
      --mdc-theme-secondary: ${encodeURI(currentData.colors?.primary ?? "")};
      --mdc-theme-background: ${encodeURI(currentData.colors?.background ?? "")};
      --header: ${encodeURI(currentData.colors?.header ?? "")};
    }

    ${
      currentData.font_url &&
      `
        @font-face {
            font-family: CustomTenantFont;
            src: url(${encodeURI(currentData.font_url)});
          }

          *:not(i) {
            font-family: CustomTenantFont !important;
          }
        `
    }


        .aut-banner-logo > img {
          content: url(${
            currentData.logo_url
              ? encodeURI(currentData.logo_url)
              : initialData.logo_url && mode === "workspace"
              ? encodeURI(initialData?.logo_url)
              : acpBasePath + "/static/images/sa-logo.svg"
          });
        }

     ${
       (currentData.logo_url || initialData?.logo_url) && currentData.logo_size
         ? `
         .aut-banner-logo > img {
           height: ${logoSizeMapper[currentData.logo_size] || 32}px;
         }
       `
         : ""
     }
  `;
};

export default function Preview({ themeId, currentData, initialData, mode, visibleSteps }: Props) {
  const { classes } = useStyles();
  const theme = useTheme();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = visibleSteps || getSteps();

  function handleNext() {
    if (activeStep === steps.length - 1) {
      setActiveStep(0);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }

  function handleBack() {
    if (activeStep === 0) {
      setActiveStep(steps.length - 1);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  }

  function reloadIframeStyle(styles) {
    setIsLoading(true);
    if (iframeRef && iframeRef.current) {
      let content =
        iframeRef.current?.contentWindow?.document || iframeRef.current?.contentDocument;
      if (content && content.head) {
        const styleTag = content.querySelector("style");
        if (!styleTag) {
          const style = content.createElement("style");
          style.textContent = styles;
          content.head.appendChild(style);
        } else {
          styleTag.textContent = styles;
        }
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    reloadIframeStyle(getBasicStyle(currentData, initialData, mode));
  }, [currentData, initialData, mode]);

  return (
    <Paper className={classes.container} data-testid="basic-branding-preview">
      {(visibleSteps === undefined || (visibleSteps || []).length > 1) && (
        <div className={classes.header}>
          <Typography variant="h4" className={classes.title}>
            Preview
          </Typography>
          <span className={classes.viewName}>{steps[activeStep]}</span>
          <div className={classes.navigation}>
            <IconButton onClick={handleBack} size="large" aria-label="Back">
              <ChevronLeft />
            </IconButton>
            <Typography
              variant="h4"
              data-testid="basic-branding-preview-navigation-current-number"
              className={classes.stepSpan}
            >
              {activeStep + 1}
            </Typography>
            <Typography variant="h4" className={classes.slash}>
              /
            </Typography>
            <Typography
              variant="h4"
              className={classes.stepSpan}
              data-testid="basic-branding-preview-navigation-all-number"
            >
              {steps.length}
            </Typography>
            <IconButton onClick={handleNext} size="large" aria-label="Next">
              <ChevronRight />
            </IconButton>
          </div>
        </div>
      )}
      <div className={classes.previewContainer}>
        {isLoading ? (
          <Progress style={{ top: "50%" }} size={60} />
        ) : (
          <>
            {activeStep === 0 && (
              <ThemeTemplatePreview
                themeId={themeId}
                previewPath="pages/authorization/login/index.tmpl"
                selectedPath="pages/authorization/login/index.tmpl"
                iframeRef={iframeRef}
                onLoad={() => reloadIframeStyle(getBasicStyle(currentData, initialData, mode))}
              />
            )}
            {activeStep === 1 && (
              <iframe
                data-testid="basic-branding-preview-consent"
                src={`${previewBasePath}/scope-grant/preview`}
                width="100%"
                height="100%"
                title="preview"
                className={classes.preview}
                ref={iframeRef}
                onLoad={() => reloadIframeStyle(getBasicStyle(currentData, initialData, mode))}
              />
            )}
            {activeStep === 2 && (
              <iframe
                data-testid="basic-branding-preview-consent"
                src={`${previewBasePath}/mfa/preview`}
                width="100%"
                height="100%"
                title="preview"
                className={classes.preview}
                ref={iframeRef}
                onLoad={() => reloadIframeStyle(getBasicStyle(currentData, initialData, mode))}
              />
            )}
            {activeStep === 3 && (
              <iframe
                data-testid="basic-branding-preview-consent"
                src={`${previewBasePath}/error/preview`}
                width="100%"
                height="100%"
                title="preview"
                className={classes.preview}
                ref={iframeRef}
                onLoad={() => reloadIframeStyle(getBasicStyle(currentData, initialData, mode))}
              />
            )}
            {activeStep === 4 && (
              <div
                className={classes.portalPreview}
                data-testid="basic-branding-preview-user-portal"
              >
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={getNewTheme(theme, currentData)}>
                    <AuthenticatedUserAppBase
                      appName="User Portal"
                      preview
                      logo={{
                        url: currentData.logo_url,
                        size: currentData.logo_size && logoSizeMapper[currentData.logo_size],
                      }}
                      isLoading={false}
                    />
                  </ThemeProvider>
                </StyledEngineProvider>
              </div>
            )}
            {activeStep === 5 && (
              <div
                className={classes.portalPreview}
                data-testid="basic-branding-preview-developer-portal"
              >
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={getNewTheme(theme, currentData)}>
                    <AuthenticatedDeveloperAppBase
                      preview
                      logo={{
                        url: currentData.logo_url ?? "",
                        size: currentData.logo_size && logoSizeMapper[currentData.logo_size],
                      }}
                    />
                  </ThemeProvider>
                </StyledEngineProvider>
              </div>
            )}
          </>
        )}
      </div>
    </Paper>
  );
}
