import React, { useEffect, useMemo, useState } from "react";

import get from "lodash/get";

import { SupportedJSONSchema, UserWithDataStatusEnum } from "@cloudentity/acp-identity";

import { useFeature } from "../../../../common/utils/hooks/useFeature";
import {
  getFromLocalStorage,
  setInLocalStorage,
} from "../../../../common/utils/localStorage.utils";
import { useGetPool } from "../../../services/adminIdentityPoolsQuery";
import { useGetWorkspaceSchema } from "../../../services/adminIdentitySchemasQuery";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import { useListIdentityPoolRoles, useListWorkspaceRoles } from "../../../services/adminRolesQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import IdentityPoolUserAddressesCell from "../identityPools/identityPool/users/list/IdentityPoolUserAddressesCell";
import IdentityPoolUserIdentifierCell from "../identityPools/identityPool/users/list/IdentityPoolUserIdentifierCell";
import StatusChip from "../identityPools/identityPool/users/list/StatusChip";
import {
  getColumnsStorageKey,
  getFormattedDate,
  getUserRole,
  getUserRolesDisplayNames,
  tenantRoleToDisplayRole,
} from "../identityPools/identityPool/users/list/utils";
import {
  fieldToTitle,
  getAllPaths,
  getRequiredPaths,
} from "../identityPools/schemas/schemas.utils";
import { useTenantRoles } from "./useTenantRoles";

interface Props {
  workspaceID?: string;
  identityPoolID: string;
  mapColumnsConfigurationFn?: (column: any) => any; // TODO
  mapDefaultColumnsIdsFn?: (column: any) => any; // TODO
}

export const useAdministratorUsersColumns = ({
  identityPoolID,
  workspaceID,
  mapColumnsConfigurationFn,
  mapDefaultColumnsIdsFn,
}: Props) => {
  const isWithRolesEnabled = useFeature("with_roles");

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const poolQuery = useGetPool(identityPoolID, { enabled: !!identityPoolID });
  const payloadSchemaQuery = useGetWorkspaceSchema(workspaceID, poolQuery.data?.payload_schema_id, {
    enabled: poolQuery.isSuccess,
  });
  const metadataSchemaQuery = useGetWorkspaceSchema(
    workspaceID,
    poolQuery.data?.metadata_schema_id,
    {
      enabled: poolQuery.isSuccess,
    }
  );

  const { query: listTenantRolesQuery, isEnabled: isRoleColumnVisible } = useTenantRoles({
    identityPoolId: identityPoolID,
  });
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspaceID, {
    enabled: !!workspaceID,
  });
  const listWorkspaceRolesQuery = useListWorkspaceRoles(workspaceID!, {
    enabled:
      !!checkWorkspacePermissionsQuery.data?.read_roles && !!workspaceID && isWithRolesEnabled,
  });
  const listIdentityPoolRolesQuery = useListIdentityPoolRoles(identityPoolID!, {
    enabled:
      !!checkWorkspacePermissionsQuery.data?.read_roles && !!identityPoolID && isWithRolesEnabled,
  });

  const allPayloadMetadataColumns = useMemo(
    () =>
      getPayloadMetadataColumns(
        payloadSchemaQuery.data?.schema,
        metadataSchemaQuery.data?.schema,
        getAllPaths
      ),
    [payloadSchemaQuery.data, metadataSchemaQuery.data]
  );

  const requiredPayloadMetadataColumns = useMemo(
    () =>
      getPayloadMetadataColumns(
        payloadSchemaQuery.data?.schema,
        metadataSchemaQuery.data?.schema,
        getRequiredPaths
      ),
    [payloadSchemaQuery.data, metadataSchemaQuery.data]
  );

  const columnsConfiguration = useMemo(
    () =>
      [
        ...allPayloadMetadataColumns,
        ...[
          {
            id: "role",
            header: "Tenant Role",
            accessorFn: row => getUserRole(row, listTenantRolesQuery.data?.subjects || []),
            cell: cell => {
              const role = getUserRole(
                cell.row.original,
                listTenantRolesQuery.data?.subjects || []
              );
              return tenantRoleToDisplayRole(role);
            },
          },
          {
            id: "roles",
            header: "Workspace Roles",
            accessorFn: row => getUserRole(row, listTenantRolesQuery.data?.subjects || []),
            cell: cell => {
              return getUserRolesDisplayNames(
                cell.row.original,
                listTenantRolesQuery.data?.subjects || [],
                listWorkspaceRolesQuery.data?.subjects || [],
                listIdentityPoolRolesQuery.data?.subjects || []
              );
            },
          },
          {
            id: "identifiers",
            path: "identifiers",
            header: "Identifiers",
            accessorFn: row => row.identifiers,
            cell: cell => <IdentityPoolUserIdentifierCell user={cell.row.original} />,
            enableSorting: true,
          },
          {
            id: "addresses",
            path: "addresses",
            header: "Addresses",
            accessorFn: row => row.addresses,
            cell: cell => <IdentityPoolUserAddressesCell user={cell.row.original} />,
          },
          {
            id: "created_at",
            path: "created_at",
            header: "Created At",
            enableSorting: true,
            accessorFn: row => row.created_at,
            cell: cell => {
              const created_at = cell.row.original.created_at;
              return getFormattedDate(created_at ?? "");
            },
          },
          {
            id: "status",
            path: "status",
            header: "Status",
            enableSorting: true,
            accessorFn: row => row.status,
            cell: cell => {
              const status = cell.row.original.status;
              return (
                <StatusChip
                  status={status}
                  label={status === UserWithDataStatusEnum.New ? "Invite Sent" : undefined}
                />
              );
            },
          },
          {
            id: "status_updated_at",
            path: "status_updated_at",
            header: "Last Updated",
            enableSorting: true,
            accessorFn: row => row.status_updated_at,
            cell: cell => {
              const status_updated_at = cell.row.original.status_updated_at;
              return getFormattedDate(status_updated_at ?? "");
            },
          },
        ],
      ]
        .filter(col => (isRoleColumnVisible ? true : col.id !== "role"))
        .filter(col =>
          !!checkWorkspacePermissionsQuery.data?.read_roles ? true : col.id !== "roles"
        )
        .map(mapColumnsConfigurationFn ?? (v => v))
        .filter(Boolean),
    [
      allPayloadMetadataColumns,
      mapColumnsConfigurationFn,
      listTenantRolesQuery.data?.subjects,
      listWorkspaceRolesQuery.data?.subjects,
      listIdentityPoolRolesQuery.data?.subjects,
      isRoleColumnVisible,
      checkWorkspacePermissionsQuery.data?.read_roles,
    ]
  );

  const defaultDynamicColumnsIds: string[] = useMemo(
    () => [
      ...(requiredPayloadMetadataColumns?.slice(0, 2).map(v => v.id) ?? []),
      "identifiers",
      "addresses",
      "role",
      "roles",
      "status",
      "status_updated_at",
    ],
    [requiredPayloadMetadataColumns]
  );

  const defaultColumnsIds = useMemo(
    () =>
      defaultDynamicColumnsIds
        .filter(colId => (isRoleColumnVisible ? true : colId !== "role"))
        .filter(colId =>
          !!checkWorkspacePermissionsQuery.data?.read_roles ? true : colId !== "roles"
        )
        .map(mapDefaultColumnsIdsFn ?? (v => v))
        .map(colId => (columnsConfiguration.map(col => col.id).includes(colId) ? colId : false))
        .filter(Boolean),
    [
      defaultDynamicColumnsIds,
      mapDefaultColumnsIdsFn,
      isRoleColumnVisible,
      columnsConfiguration,
      checkWorkspacePermissionsQuery.data,
    ]
  );

  const [currentColumnsIds, setCurrentColumnsIds] = useState<string[]>([]);

  useEffect(() => {
    setCurrentColumnsIds(
      getColumnsFromStorage(
        identityPoolID,
        columnsConfiguration
          .map(({ id }) => id)
          .filter(colId => (isRoleColumnVisible ? true : colId !== "role"))
          .filter(colId =>
            !!checkWorkspacePermissionsQuery.data?.read_roles ? true : colId !== "roles"
          )
          .map(mapDefaultColumnsIdsFn ?? (v => v))
          .map(colId => (columnsConfiguration.map(col => col.id).includes(colId) ? colId : false))
          .filter(Boolean)
      ) || defaultColumnsIds
    );
  }, [
    mapDefaultColumnsIdsFn,
    identityPoolID,
    defaultColumnsIds,
    columnsConfiguration,
    isRoleColumnVisible,
    checkWorkspacePermissionsQuery.data,
  ]);

  const onSetCurrentColumnsIds = (currentColumnsIds: string[]) => {
    setInLocalStorage(getColumnsStorageKey(identityPoolID), JSON.stringify(currentColumnsIds));
    setCurrentColumnsIds(currentColumnsIds);
  };

  const isResolved =
    poolQuery.isSuccess &&
    (!!checkTenantPermissionsQuery.data?.list_identity_pools
      ? payloadSchemaQuery.isSuccess && metadataSchemaQuery.isSuccess
      : true);

  return {
    isResolved,
    columnsConfiguration,
    currentColumnsIds,
    onSetCurrentColumnsIds,
    defaultColumnsIds,
  };
};

export function getColumnsFromStorage(poolId: string, columnsConfiguration: string[]) {
  try {
    const currentColumnsIdsFromStore = JSON.parse(
      getFromLocalStorage(getColumnsStorageKey(poolId)) ?? ""
    );

    return currentColumnsIdsFromStore.filter(column => columnsConfiguration.includes(column));
  } catch {
    return null;
  }
}

const valueToString = (path, data) => {
  const v = get(data, path.split("."), "");
  if (Array.isArray(v)) {
    return JSON.stringify(v, null, 2);
  }
  if (typeof v === "object" && v !== null) {
    return JSON.stringify(v, null, 2);
  }
  if (typeof v === "boolean" && v !== null) {
    return String(v);
  }
  return v;
};

const getPayloadMetadataColumns = (
  payloadSchema: SupportedJSONSchema | undefined,
  metadataSchema: SupportedJSONSchema | undefined,
  pathsGetter: (schema: SupportedJSONSchema) => any[]
) => [
  ...pathsGetter(payloadSchema || {}).map(f => ({
    id: `payload.${f}`,
    path: `payload.${f}`,
    header: fieldToTitle(f),
    accessorFn: row => valueToString(f, row.payload) ?? null,
    cell: cell => valueToString(f, cell.row.original.payload) ?? null,
  })),
  ...pathsGetter(metadataSchema || {}).map(f => ({
    id: `metadata.${f}`,
    path: `metadata.${f}`,
    header: fieldToTitle(f),
    accessorFn: row => valueToString(f, row.metadata) ?? null,
    cell: cell => valueToString(f, cell.row.original.metadata) ?? null,
  })),
];
