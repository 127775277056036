import React, { CSSProperties, ReactNode } from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import IconTooltip from "./IconTooltip";

const useStyles = makeStyles()(theme => ({
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 12,
    color: theme.palette.secondary.dark,
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export interface FormInputLabelProps {
  id: string;
  forId?: string;
  label: string | ReactNode;
  caption?: string;
  icon?: any;
  optional?: boolean;
  tooltip?: string | ReactNode;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  tooltipPlacement?: any;
}

export default function FormInputLabel({
  id,
  forId,
  label,
  icon: Icon,
  caption,
  optional,
  tooltip,
  style = {},
  innerStyle = {},
  tooltipPlacement,
}: FormInputLabelProps) {
  const { classes } = useStyles();

  return (
    <label id={id} htmlFor={forId} className={classes.label} style={style}>
      <div style={tooltip ? { display: "flex", alignItems: "center", ...innerStyle } : innerStyle}>
        {label}
        {Icon && (
          <Icon
            style={{ color: "#4CAF50", width: 16, height: 16, marginLeft: 8, position: "absolute" }}
          />
        )}
        {tooltip && (
          <IconTooltip
            id={`${id}-tooltip`}
            title={
              <div>
                <Typography variant="h5" style={{ marginBottom: 4 }}>
                  {label}
                </Typography>
                <div>{tooltip}</div>
              </div>
            }
            style={{ marginLeft: 10 }}
            placement={tooltipPlacement || "top"}
          />
        )}
      </div>
      {caption && <Typography variant="caption">{caption}</Typography>}
      {!caption && optional && <Typography variant="caption"> Optional</Typography>}
    </label>
  );
}
