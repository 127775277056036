import React from "react";

import { ServerResponse } from "@cloudentity/acp-admin";

import { IdentityTabs } from "../../../common/utils/viewTabs";
import AlertEnabledIdpDiscovery from "./AlertEnabledIdpDiscovery";
import IdentitiesDetailsAttributes from "./IdentitiesDetailsAttributes";
import IdentitiesDetailsConfiguration from "./IdentitiesDetailsConfiguration";
import IdentitiesDetailsExtensions from "./IdentitiesDetailsExtensions";
import IdentitiesDetailsMappings from "./IdentitiesDetailsMappings";
import IdentitiesDetailsUsers from "./IdentitiesDetailsUsers";
import { IdentityUserView, IdpUiModelType } from "./identities.utils";
import IdentitiesDetailsProvisioning from "./identitiesProvisioning/IdentitiesDetailsProvisioning";

interface Props {
  tab: IdentityTabs;
  enabledDiscoveryAlert: boolean;
  setEnabledDiscoveryAlert: (show: boolean) => void;
  idp: IdpUiModelType;
  errors: any;
  setErrors: (errors: any) => void;
  updateProgress: boolean;
  handleUpdate: (provider: IdpUiModelType) => Promise<void>;
  handleDelete: (provider: IdpUiModelType) => void;
  authorizationServerData: ServerResponse | undefined;
  usersTabView: IdentityUserView;
  setUsersTabView: (view: IdentityUserView) => void;
}

export default function IdentitiesDetailsContent({
  tab,
  enabledDiscoveryAlert,
  setEnabledDiscoveryAlert,
  idp,
  errors,
  setErrors,
  updateProgress,
  handleUpdate,
  handleDelete,
  authorizationServerData,
  usersTabView,
  setUsersTabView,
}: Props) {
  return (
    <>
      {tab === IdentityTabs.CONFIGURATION && (
        <div style={{ position: "relative" }}>
          <AlertEnabledIdpDiscovery
            show={enabledDiscoveryAlert}
            setEnabledDiscoveryAlert={setEnabledDiscoveryAlert}
          />
          <IdentitiesDetailsConfiguration
            idp={idp}
            server={authorizationServerData}
            errors={errors}
            updateProgress={updateProgress}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            onResetErrors={err => setErrors(err)}
          />
        </div>
      )}
      {tab === IdentityTabs.ATTRIBUTES && (
        <IdentitiesDetailsAttributes
          method={idp}
          onUpdate={handleUpdate}
          progress={updateProgress}
        />
      )}
      {tab === IdentityTabs.USERS && idp.method === "static" && (
        <IdentitiesDetailsUsers
          idp={idp}
          users={idp.credentials?.users ?? []}
          view={usersTabView}
          onViewChange={(view: IdentityUserView) => setUsersTabView(view)}
        />
      )}
      {tab === IdentityTabs.MAPPINGS && (
        <IdentitiesDetailsMappings
          method={idp}
          server={authorizationServerData}
          onUpdate={handleUpdate}
          updateProgress={updateProgress}
        />
      )}
      {tab === IdentityTabs.EXTENSIONS && (
        <IdentitiesDetailsExtensions
          method={idp}
          onUpdate={handleUpdate}
          updateProgress={updateProgress}
        />
      )}
      {tab === IdentityTabs.PROVISIONING &&
        !["organization", "identity_pool"].includes(idp.method!) && (
          <IdentitiesDetailsProvisioning idp={idp} />
        )}
    </>
  );
}
