import { useQuery } from "@tanstack/react-query";

import oauth2Api from "./oauth2Api";
import { withQueryError } from "./queryUtils";

const GET_USER_INFO = "GET_USER_INFO";

export const getUserInfoQueryKey = () => [GET_USER_INFO];

export const useGetUserInfo = () =>
  useQuery({
    queryKey: getUserInfoQueryKey(),
    queryFn: withQueryError(async () => {
      const data = await oauth2Api.userinfo();
      return data.data;
    }, "Error occurred while trying to fetch user info"),
  });
