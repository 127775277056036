import React from "react";

import DashboardCustomizeOutlined from "@mui/icons-material/DashboardCustomizeOutlined";
import Grid from "@mui/material/Grid";

import { getTenantId } from "../../../../../common/api/paths";
import EmptyState from "../../../../../common/components/EmptyState";
import { useListSchemas } from "../../../../services/adminIdentitySchemasQuery";
import SchemasGridItem from "./SchemasGridItem";

export default function IdentitySchemasGrid() {
  const schemasQuery = useListSchemas(getTenantId());

  return (
    <>
      {(schemasQuery.data?.schemas || []).length > 0 && (
        <Grid container spacing={3}>
          {schemasQuery.data?.schemas
            ?.sort(
              (a, b) => +(b.system || false) - +(a.system || false) || a.name.localeCompare(b.name)
            )
            .map(schema => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={schema.id}>
                <SchemasGridItem schema={schema} />
              </Grid>
            ))}
        </Grid>
      )}
      {schemasQuery.data?.schemas?.length === 0 && (
        <EmptyState
          title="Identity Pools"
          description="You haven't created any identity schemas yet"
          buttonContent="+ Create pool"
          onSubmit={() => {}}
          icon={DashboardCustomizeOutlined}
          style={{ marginTop: 80 }}
        />
      )}
    </>
  );
}
