import React from "react";

import { useCheckTenantPermissions } from "../admin/services/adminTenantsQuery";
import EmptyState from "../common/components/EmptyState";
import OrganizationEmptyStateIcon from "../common/components/icons/OrganizationEmptyStateIcon";

export default function B2BOrganizationsEmptyState({ onCreate }) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  return (
    <EmptyState
      title="No Organization Created Yet"
      description="Create organization to delegate administration to your partners and customers, and empower them to self-manage."
      icon={OrganizationEmptyStateIcon}
      buttonContent="+ Create organization"
      onSubmit={
        checkTenantPermissionsQuery.data?.create_organization ? () => onCreate(true) : undefined
      }
      style={{ marginTop: 80 }}
    />
  );
}
