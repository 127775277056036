import React, { useCallback, useState } from "react";

import Typography from "@mui/material/Typography";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

import { ExtendedUser, UserWithData } from "@cloudentity/acp-identity";

import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import { useFormContext } from "../../../common/utils/forms/Form";
import { validators } from "../../../common/utils/forms/validation";
import { useListUsers } from "../../services/adminIdentityUsersQuery";
import SquareChip from "../common/SquareChip";
import { escapePercentageSignInValue } from "../workspaceDirectory/identityPools/identityPool/users/list/UsersFilter";
import { UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles } from "./WorkspaceAdministratorsTable";

interface Props {
  poolId: string;
  usersAndPoolsAndRoles: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles[];
  disabled?: boolean;
}

export default function InviteAdministratorEmailField({
  poolId,
  usersAndPoolsAndRoles,
  disabled,
}: Props) {
  const { form } = useFormContext();
  const selectedUser = form.watch("user");

  const isNewUser = !!selectedUser && typeof selectedUser === "string";

  const [listUsersQueryString, setListUsersQueryString] = useState<undefined | string>(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setListUsersQueryStringDebounced = useCallback(
    debounce((selectedUser: string | UserWithData) => {
      const s = decodeURIComponent(
        escapePercentageSignInValue(
          JSON.stringify({ user_key: typeof selectedUser === "string" ? selectedUser : "" })
        )
      );
      setListUsersQueryString(s);
    }, 1000),
    []
  );

  const listUsersQuery = useListUsers<ExtendedUser>({
    ipID: poolId,
    query: listUsersQueryString,
  });

  const isAlreadyAssigned = (o: ExtendedUser | undefined) => {
    const user = usersAndPoolsAndRoles.find(u => u.user?.id === o?.id)?.user;
    if (user) {
      return isEqual(
        (user.identifiers || []).map(i => i.identifier),
        o?.identifiers
      );
    }

    return false;
  };

  return (
    <>
      <AutocompleteField<ExtendedUser>
        name="user"
        label="Email"
        options={(listUsersQuery.data || []).sort((a, b) => (isAlreadyAssigned(a) ? 1 : -1))}
        getOptionLabel={(v: ExtendedUser | string) => {
          return typeof v === "string" ? v : (v.identifiers || []).join(", ");
        }}
        endAdornment={
          typeof selectedUser === "string" && selectedUser.trim().length > 0 && isNewUser ? (
            <SquareChip label="New User" />
          ) : null
        }
        renderOption={({ key, ...props }, option) => (
          <li
            key={key}
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
            {...props}
          >
            <Typography>
              <strong>{option.payload?.given_name + " " + option.payload?.family_name}</strong>
            </Typography>
            <Typography variant="caption" style={{ marginLeft: 8 }}>
              {(option.identifiers || []).join(", ")}
            </Typography>
            <div style={{ flex: 1 }} />
            {isAlreadyAssigned(option) && <SquareChip label="Assigned" />}
          </li>
        )}
        ListboxProps={{
          sx: { maxHeight: 206, overflow: "auto" },
        }}
        onInputChange={(_, v) => {
          form.clearErrors("user");
          if (v) {
            setListUsersQueryStringDebounced(v);
          } else {
            setListUsersQueryString(undefined);
          }
        }}
        onBlur={() => {}}
        blurOnSelect
        filterSelectedOptions={false}
        includeInputInList
        rules={{
          validate: {
            required: v => {
              return (
                (!!v && typeof v === "object") ||
                (typeof v === "string" && v.trim().length > 0) ||
                "Email is required"
              );
            },
            isEmail: v => {
              if (typeof v === "string") {
                return validators.validEmail({ label: "Email" })(v);
              }

              return true;
            },
          },
        }}
        getOptionDisabled={isAlreadyAssigned}
        freeSolo
        autoSelect
        autoComplete
        autoHighlight={false}
        optional={false}
        disableClearable
        disabled={disabled}
      />
    </>
  );
}
