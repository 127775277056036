import React from "react";

import WorkspaceDirectoryStepCard from "./WorkspaceDirectoryStepCard";

export const workspaceWizardCards = [
  {
    id: "defineWorkspaceType",
    title: "Define workspace type",
  },
  {
    id: "addWorkspaceDetails",
    title: "Add workspace details",
  },
  {
    id: "connectUserPool",
    title: "Connect user pool",
  },
  {
    id: "createDeveloperPortal",
    title: "Create developer portal",
  },
  {
    id: "connectDeveloperPool",
    title: "Connect developer pool",
  },
];

interface Props {
  stepName: string;
  stepIndex: number;
  type: string;
}

function WorkspaceDirectoryCreateSidePanel({ stepName, stepIndex, type }: Props) {
  const filteredCards =
    type === "partners"
      ? workspaceWizardCards.filter(v => v.id !== "connectUserPool")
      : workspaceWizardCards;

  return (
    <div
      style={{
        width: 360,
        minWidth: 360,
        height: "100%",
        background: "#fff",
        padding: 12,
        borderRight: "1px solid #ECECEC",
      }}
    >
      {filteredCards.map(({ id, title }, i) => (
        <WorkspaceDirectoryStepCard
          step={i + 1}
          id={id}
          title={title}
          selected={stepName === id}
          visited={stepIndex < i + 1}
          key={id}
        />
      ))}
    </div>
  );
}

export default WorkspaceDirectoryCreateSidePanel;
