import Ajv, { Options, ErrorObject } from 'ajv';
import { FormatsPluginOptions } from 'ajv-formats';

/** The type describing how to customize the AJV6 validator
 */
export interface CustomValidatorOptionsType {
  /** The list of additional meta schemas that the validator can access */
  additionalMetaSchemas?: ReadonlyArray<object>;
  /** The set of additional custom formats that the validator will support */
  customFormats?: {
    [k: string]: string | RegExp | ((data: string) => boolean);
  };
  /** The set of config overrides that will be passed to the AJV validator constructor on top of the defaults */
  ajvOptionsOverrides?: Options;
  /** The `ajv-format` options to use when adding formats to `ajv`; pass `false` to disable it */
  ajvFormatOptions?: FormatsPluginOptions | false;
  /** The AJV class to construct */
  AjvClass?: typeof Ajv;
}

/** The type describing a function that takes a list of Ajv `ErrorObject`s and localizes them
 */
export type Localizer = (errors?: null | ErrorObject[]) => void;

/** This is a simplification of the `ValidateFunction` type provided by the AJV validator down to its minimal form so
 * that the code still works properly with precompiled validator functions generated by the AJV standalone code
 * compilation but does not rely on any of the internal AJV types which causes Typescript issue for library consumers
 */
export interface CompiledValidateFunction {
  /** The errors produced by the precompiled validator */
  errors?: null | ErrorObject[];
  /** This is simplified version of a `ValidateFunction` type definition which describes the interface that our
   * precompiled validator will call.
   */
  (this: Ajv | any, data: any): boolean;
}

/** The definition of precompiled validator functions
 */
export type ValidatorFunctions = { [key: string]: CompiledValidateFunction };
