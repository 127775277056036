import React from "react";
import { useNavigate, useParams } from "react-router";

import Tab from "@mui/material/Tab";

import PageContainer from "../../common/PageContainer";
import PageHeader from "../../common/PageHeader";
import MessageProvidersEmails from "./MessageProvidersEmails";
import MessageProvidersPhone from "./MessageProvidersPhone";

export default function MessageProviders() {
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();

  const tabs = [
    <Tab label="Emails" value="emails" key="emails" />,
    <Tab label="Phone" value="phone" key="phone" />,
  ];
  const onChangeTab = (tab: string) => {
    navigate(`/message-providers/${tab}`);
  };

  return (
    <PageContainer>
      <PageHeader
        title="Message Providers"
        tabs={tabs}
        currentTab={tab}
        onChangeTab={onChangeTab}
      />

      {tab === "emails" && <MessageProvidersEmails />}
      {tab === "phone" && <MessageProvidersPhone />}
    </PageContainer>
  );
}
