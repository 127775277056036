import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { getTenantId } from "../../../../common/api/paths";
import Progress from "../../../../common/components/Progress";
import permissionsSystemsApi from "../../../services/adminPermissionsSystemsApi";
import {
  listPermissionSystemsQueryKey,
  useListPermissionSystems,
} from "../../../services/adminPermissionsSystemsQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";
import PermissionCreate from "./PermissionCreate";
import PermissionsGrid from "./PermissionsGrid";

function PermissionsController() {
  const [createDialog, setCreateDialog] = useState(false);

  const queryClient = useQueryClient();
  const tenantId = getTenantId();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const systemsQuery = useListPermissionSystems(tenantId);
  const systems = systemsQuery.data?.systems ?? [];
  const systemNames = systems.map(system => system.name);

  const progress = systemsQuery.isLoading || systemsQuery.isFetching;

  const handleCreate = (data: any) => {
    const name = data.name.trim();
    return permissionsSystemsApi
      .createSystem({
        system: { name, description: data.description.trim(), prefix: data.prefix },
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listPermissionSystemsQueryKey(tenantId) })
      )
      .then(() => setCreateDialog(false));
  };

  return (
    <>
      <PageHeader
        title="Permission Systems"
        buttonText="+ Create system"
        onButtonClick={
          systems.length && checkTenantPermissionsQuery.data?.manage_permission_systems
            ? () => setCreateDialog(true)
            : undefined
        }
      />
      <PageContent>
        {progress ? <Progress /> : <PermissionsGrid systems={systems} onCreate={handleCreate} />}
      </PageContent>

      {createDialog && (
        <PermissionCreate
          onClose={() => setCreateDialog(false)}
          onCreate={handleCreate}
          systemNames={systemNames}
        />
      )}
    </>
  );
}

export default PermissionsController;
