import { useState } from "react";

import { UserWithData } from "@cloudentity/acp-identity";

export type AdministratorManagementStoreContextType = {
  createdUsers: UserWithData[];
  setCreatedUsers: (users: UserWithData[]) => void;
};

export const useAdministratorManagementStore = (): AdministratorManagementStoreContextType => {
  const [store, setStore] = useState<{ createdUsers: UserWithData[] }>({
    createdUsers: [],
  });

  const setCreatedUsers = (createdUsers: UserWithData[]) => {
    setStore(store => ({
      ...store,
      createdUsers,
    }));
  };

  return {
    createdUsers: store.createdUsers,
    setCreatedUsers,
  };
};
