import { Configuration } from "@cloudentity/acp-admin";

import { cloudentityAuth } from "../auth/actions/actions";
import { acpBaseURI } from "./paths";

export const getConfiguration = (basePath?: string) =>
  new Configuration({
    accessToken: cloudentityAuth.getAccessToken(),
    basePath: basePath || acpBaseURI,
  });
