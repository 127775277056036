import React, { ReactNode, useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { OutlinedTextFieldProps } from "@mui/material/TextField";
import { Copy, ExternalLink } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { copyToClipboard } from "../services/clipboard";

const useStyles = makeStyles<{ fontColor?: string }>()((theme, { fontColor }) => ({
  root: {
    ".MuiInputBase-root": {
      backgroundColor: theme.custom.greys.fieldBackground,
    },
    ".MuiOutlinedInput-notchedOutline": {
      boxShadow: theme.custom.shadows.formInner,
    },
  },
  iconColor: {
    color: theme.palette.secondary.light,
  },
  disabled: {
    color: fontColor || "rgba(0, 0, 0, 0.38)",
    cursor: "default",
  },
}));

interface Props extends Omit<OutlinedTextFieldProps, "variant"> {
  label: string;
  id: string;
  name: string;
  value: string | number;
  disabled?: boolean;
  toggleVisibility?: boolean;
  defaultVisibility?: boolean;
  withProgress?: boolean;
  withLink?: boolean;
  withCopy?: boolean;
  withStartAdornment?: ReactNode | null;
  withEndAdornment?: ReactNode | null;
  withEndAdornmentOpacity?: boolean;
  fontColor?: string;
}

const AdornmentTextField = ({
  label,
  id,
  name,
  value,
  disabled,
  toggleVisibility,
  defaultVisibility = true,
  withProgress,
  withLink,
  withCopy,
  withStartAdornment = null,
  withEndAdornment = null,
  withEndAdornmentOpacity = true,
  fontColor,
  ...props
}: Props) => {
  const { classes } = useStyles({ fontColor });

  const [visibility, setVisibility] = useState(defaultVisibility);
  const inputProps = {
    ...(props.inputProps || {}),
    ...(props.inputProps?.autoComplete === "off" ? { "data-lpignore": true } : {}), // to prevent LastPass auto-fill
  };

  return (
    <TextField
      {...props}
      FormHelperTextProps={{ id: `${id}-helper-text`, role: "contentinfo" }}
      name={name}
      value={value}
      variant="outlined"
      inputProps={{ ...(inputProps || {}), "aria-label": name, id }}
      type={props.type || (visibility ? "text" : "password")}
      className={classes.root}
      placeholder={withProgress ? "Loading..." : props.placeholder ?? ""}
      InputProps={{
        readOnly: disabled,
        classes: { input: disabled ? classes.disabled : undefined },
        startAdornment: (
          <>
            {!withProgress && (
              <InputAdornment position="start">
                {withStartAdornment && withStartAdornment}
              </InputAdornment>
            )}
          </>
        ),
        endAdornment: (
          <>
            {withProgress && <CircularProgress size={24} />}
            {!withProgress && (
              <InputAdornment position="end">
                {withEndAdornment && withEndAdornment}
                {withCopy && (
                  <IconButton
                    aria-label="copy to clipboard"
                    tabIndex={-1}
                    onClick={() => copyToClipboard(value || props.defaultValue, label)}
                    id={`${id}-copy-button`}
                  >
                    <Copy className={classes.iconColor} />
                  </IconButton>
                )}
                {toggleVisibility && (
                  <IconButton
                    aria-label="toggle password visibility"
                    tabIndex={-1}
                    onClick={() => setVisibility(!visibility)}
                    id={`${id}-toggle-password-button`}
                  >
                    {visibility ? (
                      <Visibility className={classes.iconColor} />
                    ) : (
                      <VisibilityOff className={classes.iconColor} />
                    )}
                  </IconButton>
                )}
                {withLink && (
                  <IconButton
                    aria-label="link"
                    tabIndex={-1}
                    onClick={() => {
                      if (typeof value === "string") {
                        window.open(value, "_blank");
                      }
                    }}
                    id={`${id}-link-button`}
                    disabled={!value}
                  >
                    <ExternalLink className={classes.iconColor} />
                  </IconButton>
                )}
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};

export default AdornmentTextField;
