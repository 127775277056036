import React from "react";

import Chip, { ChipProps } from "@mui/material/Chip";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ light: boolean }>()((theme, { light }) => ({
  root: {
    borderRadius: "4px !important",
    ...(light
      ? {
          color: `${theme.palette.primary.main} !important`,
          background: `${theme.palette.primary.light} !important`,
        }
      : {}),
  },
}));

interface Props {
  light?: boolean;
}

export default function SquareChip({ light = false, ...props }: Props & ChipProps) {
  const { classes } = useStyles({ light });

  return <Chip size="small" classes={{ root: classes.root }} {...props} />;
}
