import React, {
  CSSProperties,
  ElementType,
  useEffect,
  useState,
  ReactElement,
  ReactNode,
  useMemo,
} from "react";

import IconButton from "@mui/material/IconButton";
import { PopoverPosition } from "@mui/material/Popover";
import Popper, { PopperProps } from "@mui/material/Popper";
import { X } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { StepActionsProps, StepContainer } from "./StepComponents";
import SvgMask from "./SvgMask";
import { scrollTo, getScrollParent } from "./utils";

export interface BasicPopoverStepProps {
  targets: (HTMLElement | null)[] | (() => (HTMLElement | null)[]);
  scrollToElement?: boolean;
  placement?: PopperProps["placement"];
  position?: Partial<PopoverPosition>;
  ProgressComponent?: ReactElement | false;
  ActionsComponent?: ElementType<StepActionsProps> | false;
  cardStyles?: CSSProperties;
  onMaskClick?: () => void;
  onClose?: () => void;
  children: ReactNode;
}

const useStyles = makeStyles<void, "arrow">()((theme, _, classes) => ({
  popper: {
    boxShadow: theme.shadows[4],
    zIndex: theme.zIndex.drawer + 1,
    minWidth: "256px",
    maxWidth: "512px",
    [`&[data-popper-placement*="bottom"] .${classes.arrow}`]: {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent white transparent`,
      },
    },
    [`&[data-popper-placement*="top"] .${classes.arrow}`]: {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `white transparent transparent transparent`,
      },
    },
    [`&[data-popper-placement*="right"] .${classes.arrow}`]: {
      left: 0,
      top: 1,
      marginLeft: "-0.8em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent white transparent transparent`,
      },
    },
    [`&[data-popper-placement*="left"] .${classes.arrow}`]: {
      right: 0,
      top: 1,
      marginRight: "-0.8em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent white`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    zIndex: 1,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  footer: {
    display: "flex",
    alignItems: "space-between",
    justifyContent: "flex-end",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    color: theme.custom.greys.textDisabled,
  },
}));

export const EmptyComponent = ({ children }: { children: ReactNode }) => <>{children}</>;

export const BasicPopoverStep = ({
  targets,
  scrollToElement,
  placement,
  ActionsComponent,
  ProgressComponent,
  onMaskClick,
  cardStyles,
  children,
  onClose,
}: BasicPopoverStepProps) => {
  const [arrowRef, setArrowRef] = useState<any>(null);
  const { classes } = useStyles();

  const anchors = useMemo(
    () => (typeof targets == "function" ? targets() : targets).filter((v): v is HTMLElement => !!v),
    [targets]
  );

  if (!anchors.length) return null;

  return (
    <>
      {!scrollToElement ? (
        <SvgMask elements={anchors} onClick={onMaskClick} />
      ) : (
        <ScrollTo elements={anchors}>
          <SvgMask elements={anchors} onClick={onMaskClick} />
        </ScrollTo>
      )}
      <Popper
        className={classes.popper}
        open
        placement={placement || "right"}
        anchorEl={anchors[0]}
        modifiers={[
          {
            name: "flip",
            enabled: true,
          },
          {
            name: "arrow",
            enabled: !anchors[0],
            options: {
              element: arrowRef,
            },
          },
          {
            name: "offset",
            enabled: true,
            options: {
              offset: [0, 15],
            },
          },
        ]}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <StepContainer cardStyles={{ ...cardStyles, position: "relative" }}>
          <>
            {onClose && (
              <IconButton onClick={onClose} className={classes.closeButton} size="small">
                <X size="18" />
              </IconButton>
            )}
            {children}
            {ActionsComponent}
            {ProgressComponent}
          </>
        </StepContainer>
      </Popper>
    </>
  );
};

const ScrollTo = (props: { elements: HTMLElement[]; children: ReactNode }) => {
  const [ready, setReady] = useState(props.elements.map(() => false));

  useEffect(() => {
    props.elements.forEach((element, index) => {
      const scrollContainer = getScrollParent(element, true, false);
      const scrollContainerTop =
        "getBoundingClientRect" in scrollContainer
          ? scrollContainer.getBoundingClientRect().top
          : 0;

      const elementTop = element.getBoundingClientRect().top;
      const scrollTop = "scrollTop" in scrollContainer ? scrollContainer.scrollTop : 0;
      const top = elementTop - scrollContainerTop;
      const newScrollPosition = Math.max(0, scrollTop + top - 100);

      scrollTo(newScrollPosition, scrollContainer, 200).then(() => {
        setReady(ready => [...ready.slice(0, index), true, ...ready.slice(index + 1)]);
      });
    });
  }, [props.elements]);

  return ready.every(v => v) ? <> {props.children} </> : null;
};
