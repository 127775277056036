import React, { useCallback, useState } from "react";

import debounce from "lodash/debounce";

import SearchInput from "../../common/SearchInput";

export const escapePercentageSignInValue = value => value.replaceAll("%", "%25");

interface Props {
  onQuery: (query: any) => void;
}

export default function AdministratorUsersFilter({ onQuery }: Props) {
  const [value, setValue] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = useCallback(
    debounce((searchText: string) => {
      onQuery(
        searchText
          ? decodeURIComponent(
              escapePercentageSignInValue(
                JSON.stringify({ user_key: escapePercentageSignInValue(searchText) })
              )
            )
          : undefined
      );
    }, 1000),
    [onQuery]
  );

  const handleChangeSearchText = (newSearchText: string) => {
    setValue(newSearchText);
    handleUpdate(newSearchText);
  };

  const handleClearSearchText = () => {
    setValue("");
    handleUpdate("");
  };

  return (
    <SearchInput
      value={value}
      placeholder="Search"
      onSearch={handleChangeSearchText}
      onClear={handleClearSearchText}
    />
  );
}
