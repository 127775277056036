import React from "react";

import { Mail, Smartphone, User } from "react-feather";

import { UpdateUserStatusEnum, UserIdentifierTypeEnum } from "@cloudentity/acp-identity";

export type UpdateDataType = {
  metadata: { [key: string]: object };
  metadata_schema_id: string;
  payload: { [key: string]: object };
  payload_schema_id: string;
  status: UpdateUserStatusEnum;
};

export const iconIdentifierMapper: {
  [key in UserIdentifierTypeEnum]: { icon: any };
} = {
  [UserIdentifierTypeEnum.Mobile]: {
    icon: <Smartphone size={18} />,
  },
  [UserIdentifierTypeEnum.Email]: {
    icon: <Mail size={18} />,
  },
  [UserIdentifierTypeEnum.External]: {
    icon: <User size={18} />,
  },
  [UserIdentifierTypeEnum.Uid]: {
    icon: <User size={18} />,
  },
  [UserIdentifierTypeEnum.Federated]: {
    icon: <User size={18} />,
  },
};

export const getDateTime = (dateTime: string | undefined | null) => {
  if (!dateTime) return null;
  try {
    const date = new Date(dateTime);
    return date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      })
      .toUpperCase();
  } catch {
    return null;
  }
};

export const checkIfOpenAccordionFits = (
  availableHeight: number,
  accordionDetailsId: string
): { fits: boolean; heightLeft: number } => {
  const identifiersContentBoundingRect = document
    .querySelector(`#${accordionDetailsId}`)
    ?.getBoundingClientRect();

  if (!identifiersContentBoundingRect) {
    return { fits: false, heightLeft: availableHeight };
  }

  const heightLeft = Math.max(0, availableHeight - identifiersContentBoundingRect.height);
  if (heightLeft > 0) {
    return { fits: true, heightLeft };
  }
  return { fits: false, heightLeft: availableHeight };
};
