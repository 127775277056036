import React from "react";
import { useNavigate, useParams } from "react-router";

import Tab from "@mui/material/Tab";

import { ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import PageHeader from "../common/PageHeader";
import { useWorkspace } from "../common/useWorkspace";
import WorkspaceAdministrators from "../manageAccess/WorkspaceAdministrators";
import OAuthMetadataView from "../oauthServer/OAuthMetadataView";
import AttributeMapping from "./AttributeMapping";
import SettingsGeneral from "./SettingsGeneral";

export default function Settings() {
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();
  const [workspace] = useWorkspace();

  const isWithRolesEnabled = useFeature("with_roles");

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);

  const tabs = [
    <Tab id="general-tab" label="General" value="general" key="general" />,
    <Tab
      id="custom-attributes-tab"
      label="Custom Attributes"
      value="custom-attributes"
      key="custom-attributes"
    />,
    ...(isWithRolesEnabled &&
    !!checkTenantPermissionsQuery.data?.read_roles &&
    workspace !== "admin"
      ? [
          <Tab
            id="administrators-tab"
            label="Administrators"
            value="administrators"
            key="administrators"
          />,
        ]
      : []),
    <Tab
      id="attribute-mapping-tab"
      label="Attribute Mapping"
      value="attribute-mapping"
      key="attribute-mapping"
    />,
  ];
  const onChangeTab = (tab: string) => {
    navigate(`/${workspace}/settings/${tab}`);
  };

  return (
    <PageContainer>
      <PageHeader
        title={
          serverQuery.data?.type === ServerResponseTypeEnum.Organization
            ? "Organization Settings"
            : "Workspace Settings"
        }
        tabs={tabs}
        currentTab={tab}
        onChangeTab={onChangeTab}
        maxWidth={false}
      />
      <PageContent fullWidth={tab !== "general"}>
        {tab === "general" && <SettingsGeneral />}
        {tab === "custom-attributes" && <OAuthMetadataView workspace={workspace} />}
        {tab === "attribute-mapping" && <AttributeMapping />}
        {tab === "administrators" && workspace !== "admin" && <WorkspaceAdministrators />}
      </PageContent>
    </PageContainer>
  );
}
