import React, { useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { ChevronRight } from "react-feather";

import { ServerResponse, Theme } from "@cloudentity/acp-admin";

import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import BindToThemeMenu from "./BindToThemeMenu";

export type WorkspaceMenuType = {
  anchorEl: HTMLElement;
  theme: Theme;
  server: ServerResponse;
};

interface Props {
  menu: WorkspaceMenuType;
  handleClose: () => void;
  onEditHTML: (theme: Theme | undefined) => void;
}

export default function ThemesWorkspaceMenu({ menu, handleClose, onEditHTML }: Props) {
  const [bindToThemeMenuAnchorEl, setBindToThemeMenuAnchorEl] = useState<null | HTMLElement>(null);
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  return (
    <>
      <Menu
        id="row-main-menu"
        anchorEl={menu.anchorEl}
        keepMounted
        open={Boolean(menu)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          id={`${menu.theme.id}-open-theme`}
          onClick={() => {
            onEditHTML(menu.theme);
            handleClose();
          }}
          style={{ minWidth: 200 }}
        >
          Open
        </MenuItem>

        {checkTenantPermissionsQuery.data?.manage_themes && (
          <MenuItem
            id={`${menu.server.id}-bind-to-theme`}
            onClick={e => {
              setBindToThemeMenuAnchorEl(e.currentTarget);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography>Bind to Theme</Typography>
            <ChevronRight size="16" style={{ marginLeft: 8 }} />
          </MenuItem>
        )}
      </Menu>

      {bindToThemeMenuAnchorEl && (
        <BindToThemeMenu
          theme={menu.theme}
          server={menu.server}
          anchorEl={bindToThemeMenuAnchorEl}
          onClose={() => {
            handleClose();
            setBindToThemeMenuAnchorEl(null);
          }}
        />
      )}
    </>
  );
}
