import React, { CSSProperties, ReactNode, useCallback, useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { CheckSquare, Lock, User } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { SelfUserWithDataV2 } from "@cloudentity/acp-identityself";
import { UserSession } from "@cloudentity/acp-public";

import identitySelfApi from "../../services/identitySelfApi";
import { theme } from "../../theme/theme";
import DialogFullScreen from "../DialogFullScreen";
import { notifyErrorOrDefaultTo } from "../notifications/notificationService";
import SelfServiceAccessManagement from "./SelfServiceAccessManagement";
import SelfServiceProfile from "./SelfServiceProfile";
import SelfServiceSecurity from "./SelfServiceSecurity";
import {
  CardsEnum,
  ConfigType,
  parseClients,
  ParsedClient,
  TabsEnum,
  useCommonStyles,
} from "./utils";

const useStyles = makeStyles()(() => ({
  tab: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    minHeight: 40,
    borderRadius: 36,
    margin: "0 8px 4px",
    fontWeight: 400,
    "& svg": {
      marginBottom: "0 !important",
      marginRight: 12,
    },
  },
  selected: {
    background: "rgba(40,152,255,0.09)",
  },
}));

interface Props {
  config: ConfigType;
  onClose?: () => void;
  wrapperStyle?: CSSProperties;
}

function Wrapper({ config, children, onClose }: Props & { children: ReactNode }) {
  return config.withDialog ? (
    <DialogFullScreen
      id="self-service-dialog"
      onCancel={onClose ?? (() => {})}
      title="User Profile"
      contentStyle={{ maxWidth: "100%", padding: 0, height: "100%" }}
      titleStyle={{ backgroundColor: theme.custom.greys.background }}
    >
      {children}
    </DialogFullScreen>
  ) : (
    <>{children}</>
  );
}

const tabsForSelfMeResponse = [TabsEnum.profile];
const cardsForSelfMeResponse = [
  CardsEnum.profile,
  CardsEnum["sign-in-methods"],
  CardsEnum["sign-in-identifiers"],
];

export default function SelfServiceController({ config, onClose, wrapperStyle }: Props) {
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();

  const [cards, setCards] = useState(Object.values(CardsEnum));
  const [tabs, setTabs] = useState(Object.values(TabsEnum));
  const [tab, setTab] = useState(tabs[0]);
  const [progress, setProgress] = useState(true);
  const [userData, setUserData] = useState<SelfUserWithDataV2 | null>(null);
  const [clientsData, setClientsData] = useState<ParsedClient[]>([]);
  const [sessionData, setSessionData] = useState<UserSession[]>([]);
  const [mfaSessionData, setMfaSessionData] = useState<UserSession[]>([]);

  const fetchUser = useCallback((withSetter?: boolean) => {
    return identitySelfApi
      .getUserProfile()
      .then(res => {
        if (withSetter) {
          setUserData(res.data);
        }
        return res;
      })
      .catch(err => {
        if (err.response?.status === 403) {
          setTabs(tabs => tabs.filter(tab => !tabsForSelfMeResponse.includes(tab)));
          setCards(cards => cards.filter(tab => !cardsForSelfMeResponse.includes(tab)));
          setTab(TabsEnum.security);
        } else {
          notifyErrorOrDefaultTo("Error occurred while trying to fetch clients")(err);
        }
        return null;
      });
  }, []);

  const fetchClients = useCallback((withSetter?: boolean) => {
    return identitySelfApi
      .getClients()
      .then(res => {
        if (withSetter) {
          const parsedClients = parseClients(res.data);
          setClientsData(parsedClients);
        }
        return res;
      })
      .catch(err => {
        notifyErrorOrDefaultTo("Error occurred while trying to fetch clients")(err);
        return null;
      });
  }, []);

  const fetchSessions = useCallback((withSetter?: boolean) => {
    return identitySelfApi
      .getSessions()
      .then(res => {
        if (withSetter) {
          setSessionData(res.data.sessions ?? []);
        }
        return res;
      })
      .catch(err => {
        notifyErrorOrDefaultTo("Error occurred while trying to fetch sessions")(err);
        return null;
      });
  }, []);

  const fetchMfaSessions = useCallback((withSetter?: boolean) => {
    return identitySelfApi
      .listUserMFASessions()
      .then(res => {
        if (withSetter) {
          setMfaSessionData(res.data.sessions ?? []);
        }
        return res;
      })
      .catch(err => {
        if (
          err.response?.status !== 403 &&
          err.response?.data?.error !== "user not associated with any identity pool"
        ) {
          notifyErrorOrDefaultTo("Error occurred while trying to fetch MFA sessions")(err);
        }
        return null;
      });
  }, []);

  useEffect(() => {
    setProgress(true);
    Promise.all([fetchUser(), fetchClients(), fetchSessions(), fetchMfaSessions()])
      .then(([resUser, resClients, resSessions, resMfaSessions]) => {
        if (resUser) {
          setUserData(resUser.data);
        }
        if (resClients) {
          const parsedClients = parseClients(resClients.data);
          setClientsData(parsedClients);
        }
        if (resSessions) {
          setSessionData(resSessions.data.sessions ?? []);
        }
        if (resMfaSessions) {
          setMfaSessionData(resMfaSessions.data.sessions ?? []);
        }
      })
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to fetch user profile"))
      .finally(() => setProgress(false));
  }, [fetchClients, fetchSessions, fetchUser, fetchMfaSessions]);

  return (
    <Wrapper config={config} onClose={onClose}>
      {progress ? (
        <div className={commonClasses.progress}>
          <CircularProgress />
        </div>
      ) : (
        <div className={commonClasses.wrapper} style={wrapperStyle}>
          <Tabs
            orientation="vertical"
            value={tab}
            onChange={(_, newTab) => setTab(newTab)}
            sx={{ borderRight: 1, borderColor: "divider", marginBottom: 0, borderBottom: "none" }}
            style={{ width: 248, paddingTop: 10 }}
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            {tabs.includes(TabsEnum.profile) && (
              <Tab
                id="profile"
                label="Profile"
                value={TabsEnum.profile}
                icon={<User size={18} />}
                className={classes.tab}
                classes={{ selected: classes.selected }}
              />
            )}
            {tabs.includes(TabsEnum.security) && (
              <Tab
                id="security"
                label="Security"
                value={TabsEnum.security}
                icon={<Lock size={18} />}
                className={classes.tab}
                classes={{ selected: classes.selected }}
              />
            )}
            {tabs.includes(TabsEnum.privacy) && (
              <Tab
                id="privacy"
                label="Privacy"
                value={TabsEnum.privacy}
                icon={<CheckSquare size={18} />}
                className={classes.tab}
                classes={{ selected: classes.selected }}
              />
            )}
          </Tabs>

          {tab === TabsEnum.profile && (
            <SelfServiceProfile userData={userData} fetchUser={fetchUser} />
          )}
          {tab === TabsEnum.security && (
            <SelfServiceSecurity
              userData={userData}
              fetchUser={fetchUser}
              sessionData={sessionData}
              mfaSessionData={mfaSessionData}
              fetchSessions={fetchSessions}
              fetchMfaSessions={fetchMfaSessions}
              cards={cards}
            />
          )}
          {tab === TabsEnum.privacy && (
            <SelfServiceAccessManagement clientsData={clientsData} fetchClients={fetchClients} />
          )}
        </div>
      )}
    </Wrapper>
  );
}
