import {
  ClaimsApi,
  ClaimsApiCreateClaimRequest,
  ClaimsApiDeleteClaimRequest,
  ClaimsApiListClaimsRequest,
  ClaimsApiUpdateClaimRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => ClaimsApi = adminApiClazz(ClaimsApi);

const adminClaimsApi = {
  getClaims: (req: ClaimsApiListClaimsRequest) => api().listClaims(req),
  createClaim: (req: ClaimsApiCreateClaimRequest) => api().createClaim(req),
  updateClaim: (req: ClaimsApiUpdateClaimRequest) => api().updateClaim(req),
  removeClaim: (req: ClaimsApiDeleteClaimRequest) => api().deleteClaim(req),
};

export default adminClaimsApi;
