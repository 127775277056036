import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";

import { BaseUserWithData, UserWithData } from "@cloudentity/acp-identity";

import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../../common/components/notifications/notificationService";
import {
  useCheckPoolPermissions,
  useGetPool,
} from "../../../../../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../../../../../services/adminIdentityUsersApi";
import IdentityPoolThemeSelectorDialog from "../../../IdentityPoolThemeSelectorDialog";
import { useIdentityPoolThemeSelector } from "../../../useIdentityPoolThemeSelector";
import IdentityPoolUserSelectAddressDialog from "../IdentityPoolUserSelectAddressDialog";

interface Props {
  user: UserWithData | BaseUserWithData;
  workspaceId?: string;
  address?: string;
  handleClose: () => void;
}

const IdentityPoolUserSidePanelResetPassword = ({
  user,
  workspaceId,
  address,
  handleClose,
}: Props) => {
  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  const addresses = user.verifiable_addresses ?? [];
  const getPoolQuery = useGetPool(user?.user_pool_id || "");
  const {
    mustSelectThemeServerId,
    themeWorkspaceId,
    isDialogOpen: isThemeSelectorDialogOpen,
    openDialog: openThemeSelectorDialog,
    closeDialog: closeThemeSelectorDialog,
  } = useIdentityPoolThemeSelector({
    pool: getPoolQuery.data,
  });

  const handleResetPassword = (address: string, serverId: string | undefined) => {
    setProgress(true);
    identityUsersApi
      .requestResetPassword({
        ipID: user?.user_pool_id!,
        userID: user?.id!,
        requestResetPassword: { address },
        serverId: serverId || workspaceId,
      })
      .then(() => notifySuccess("Reset password requested"))
      .then(() => setDialog(false))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to reset password"))
      .finally(() => {
        setProgress(false);
        handleClose();
      });
  };

  const checkPoolPermissionsQuery = useCheckPoolPermissions(user?.user_pool_id!, {
    enabled: !!user?.user_pool_id,
  });

  if (!checkPoolPermissionsQuery.data?.manage_user_passwords) {
    return null;
  }

  return addresses.length > 0 ? (
    <>
      <MenuItem
        id="reset-password-button"
        onClick={() => {
          if (mustSelectThemeServerId) {
            openThemeSelectorDialog();
            return;
          }

          if (address) {
            handleResetPassword(address, themeWorkspaceId);
            return;
          }

          if (addresses.length > 1) {
            setDialog(true);
            return;
          }

          if (addresses?.length === 1) {
            handleResetPassword(addresses[0].address, themeWorkspaceId);
          }
        }}
        disabled={progress}
      >
        Initiate reset password
      </MenuItem>

      {dialog && (
        <IdentityPoolUserSelectAddressDialog
          id="reset-password-dialog"
          poolId={user?.user_pool_id}
          title="Select user address to request reset password"
          submitText="Reset password"
          addresses={addresses}
          onSubmit={(address, serverId) =>
            handleResetPassword(address, serverId || themeWorkspaceId)
          }
          progress={progress}
          onCancel={() => setDialog(false)}
        />
      )}

      {isThemeSelectorDialogOpen && getPoolQuery.data && (
        <IdentityPoolThemeSelectorDialog
          pool={getPoolQuery.data}
          onCancel={() => closeThemeSelectorDialog()}
          onSubmit={serverId => {
            handleResetPassword(address || addresses[0].address, serverId);
            closeThemeSelectorDialog();
          }}
        />
      )}
    </>
  ) : null;
};

export default IdentityPoolUserSidePanelResetPassword;
