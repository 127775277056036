import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { makeStyles } from "tss-react/mui";

import { cloudentityAuth } from "../auth/actions/actions";
import { ErrorView } from "./ErrorBoundary";
import Logo from "./Logo";
import Progress from "./Progress";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
  },
  logo: {
    verticalAlign: "middle",
    height: 32,
    width: "auto",
    maxWidth: 350,
    objectFit: "contain",
  },
  chip: {
    marginLeft: 10,
    backgroundColor: "#fff",
    position: "absolute",
    left: 0,
  },
  appBar: {
    backgroundColor: theme.custom.headerBackground,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    justifyContent: "center",
    alignItems: "center",
    height: 66,
  },
  content: {
    backgroundColor: "#F7FAFF",
    height: "100vh",
  },
}));

interface Props {
  appName: string;
  silent?: boolean;
}

const Callback = ({ appName, silent }: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  let [exchangeCompleted, setExchangeCompleted] = useState(false);
  let [error, setError] = useState<{ error: string; errorDescription: string } | null>(null);

  useEffect(() => {
    cloudentityAuth
      .getAuth(silent && { silent: true })
      .then(() => {
        setExchangeCompleted(true);
      })
      .catch(error => {
        if (error && error.error_description) {
          setError({
            error: (error.error_key as string) || "Error",
            errorDescription: decodeURIComponent(error.error_description),
          });
        } else {
          setError({
            error: (error.error_key as string) || "Error",
            errorDescription: "Something went wrong",
          });

          console.error(error);
        }
      });
  }, [silent]);

  if (error) {
    return (
      <>
        <AppBar position="fixed" className={classes.appBar}>
          <Chip label={appName} className={classes.chip} />
          <Logo classes={{ logo: classes.logo }} />
        </AppBar>

        <div className={classes.content}>
          <ErrorView
            title={error.error}
            details={error.errorDescription}
            action={
              <p>
                <Button variant="text" style={{ fontWeight: 300 }} onClick={() => navigate("/")}>
                  Retry
                </Button>
              </p>
            }
            style={{ height: "unset", paddingTop: 186 }}
          />
        </div>
      </>
    );
  }

  if (exchangeCompleted && !silent) {
    return <Navigate to="/" replace />;
  }

  return <Progress isGlobalLoader />;
};

export default Callback;
