import React, { useState } from "react";

import { makeStyles } from "tss-react/mui";

import { BaseExtendedUser, BaseExtendedUserStatusEnum } from "@cloudentity/acp-identity";

import PageContent from "../../../admin/components/common/PageContent";
import AdministratorUsersFilter from "../../../admin/components/workspaceDirectory/administrator/AdministratorUsersFilter";
import { convertBaseUserWithDataToBaseExtendedUser } from "../../../admin/services/adminIdentityUsersQuery";
import AdvancedTable from "../../../common/components/table/AdvancedTable";
import { AdvancedTableData } from "../../../common/components/table/types";
import B2BUserEdit from "./B2BUserEdit";
import B2BUsersCreateNew from "./B2BUsersCreateNew";
import B2BUsersRowMenu from "./B2BUsersRowMenu";
import { useB2BUsersColumns } from "./useB2BUsersColumns";

export const useStyles = makeStyles()(theme => ({
  toolBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 32,
  },
  toolBarRight: {
    display: "flex",
    alignItems: "center",
  },
  editButton: {
    marginRight: 16,
    border: `1px solid ${theme.custom.greys.border}`,
    padding: 14,
  },
}));

interface Props {
  workspaceId: string;
  identityPoolID: string;
  usersWithPagination: AdvancedTableData<BaseExtendedUser>;
}

export default function B2BUsersTable({ workspaceId, identityPoolID, usersWithPagination }: Props) {
  const { classes } = useStyles();

  const [editUser, setEditUser] = useState<{
    userID: string;
  } | null>(null);

  const [moreMenu, setMoreMenu] = useState<{
    anchorEl: HTMLElement;
    user: BaseExtendedUser;
  }>();

  const {
    columnsConfiguration,
    defaultColumnsIds,
    currentColumnsIds,
    onSetCurrentColumnsIds,
    isResolved,
  } = useB2BUsersColumns({
    identityPoolID,
    workspaceID: workspaceId,
  });

  return (
    <PageContent progress={!isResolved}>
      <div className={classes.toolBar}>
        <AdministratorUsersFilter onQuery={usersWithPagination.onSetQuery} />

        <div className={classes.toolBarRight}>
          <B2BUsersCreateNew
            workspaceId={workspaceId}
            identityPoolID={identityPoolID}
            onCreated={user => {
              usersWithPagination.addCreatedUser(convertBaseUserWithDataToBaseExtendedUser(user));
            }}
          />
        </div>
      </div>

      <AdvancedTable<BaseExtendedUser>
        tableId="administrator-management-users"
        columns={columnsConfiguration}
        activeColumns={currentColumnsIds}
        initialActiveColumns={defaultColumnsIds}
        setActiveColumns={columns => {
          onSetCurrentColumnsIds(columns);
        }}
        onRowClick={(e, rowId) => {
          e.preventDefault();
          setEditUser({ userID: rowId! });
        }}
        onMoreIconClick={(e, user) => {
          setMoreMenu({ anchorEl: e.currentTarget, user });
        }}
        disabledRow={row => row.status === BaseExtendedUserStatusEnum.Deleted}
        data={usersWithPagination}
      />

      {editUser && (
        <B2BUserEdit
          poolId={identityPoolID}
          userId={editUser.userID}
          workspaceId={workspaceId}
          onClose={() => {
            setEditUser(null);
          }}
        />
      )}

      {moreMenu && (
        <B2BUsersRowMenu
          anchorEl={moreMenu?.anchorEl}
          onClose={() => setMoreMenu(undefined)}
          identityPoolId={moreMenu?.user?.user_pool_id}
          userId={moreMenu?.user?.id!}
          workspaceId={workspaceId}
          onEdit={() => setEditUser({ userID: moreMenu?.user?.id! })}
        />
      )}
    </PageContent>
  );
}
