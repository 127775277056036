import React from "react";

import Paper from "@mui/material/Paper";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ disabled?: boolean }>()((theme, { disabled }) => ({
  root: {
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(0, 0, 0, 0.31)",
    borderRadius: 4,
  },
  selectable: {
    "&:hover": {
      cursor: "pointer",
      boxShadow: "0px 1px 16px -4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.31)",
    },
  },
  selected: {
    boxShadow: `0 0 0 2px ${disabled ? theme.custom.greys.border : theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  noBorder: {
    boxShadow: "none",
  },
  disabled: {
    backgroundColor: theme.custom.greys.backgroundDisabled,
    color: theme.custom.greys.textDisabled,
  },
}));

interface Props {
  id?: string;
  selected?: boolean;
  selectable?: boolean;
  noBorder?: boolean;
  className?: any;
  style?: any;
  onSelect?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  children: any;
}

export default function SelectablePaper({
  id,
  selected,
  selectable,
  className,
  style,
  children,
  onSelect,
  noBorder,
  disabled,
  ...props
}: Props) {
  const { cx, classes } = useStyles({ disabled });

  return (
    <Paper
      id={id}
      onClick={e => selectable && onSelect && onSelect(e)}
      elevation={0}
      variant="elevation"
      classes={selectable && !selected ? { root: classes.selectable } : {}}
      className={cx([
        classes.root,
        selected && classes.selected,
        noBorder && classes.noBorder,
        disabled && classes.disabled,
        className,
      ])}
      aria-checked={selected}
      role="radio"
      style={style}
      {...props}
    >
      {children}
    </Paper>
  );
}
