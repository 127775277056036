import React from "react";

import MoreHoriz from "@mui/icons-material/MoreHoriz";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { Layout } from "react-feather";
import { makeStyles } from "tss-react/mui";

import {
  OrganizationResponse,
  ServerResponseTypeEnum,
  Tenant,
  WorkspaceResponse,
} from "@cloudentity/acp-admin";

import OverflowTooltip from "../../../../common/components/OverflowTooltip";
import SelectablePaper from "../../../../common/components/SelectablePaper";
import Tooltip from "../../common/Tooltip";
import WorkspaceAvatar from "../../nav/WorkspaceAvatar";
import { getServerTitle } from "../server-profiles";
import { useWorkspaceCardStyles } from "../workspaces/utils";

const useStyles = makeStyles<{ isGrayedOut?: boolean }>()((_, { isGrayedOut }) => ({
  grayedOut: isGrayedOut
    ? {
        backgroundColor: "#F7FAFE",
        background:
          "repeating-linear-gradient( -45deg, #D8D8D9, #D8D8D9 2.5px, #F7FAFE 2.5px, #F7FAFE 12.5px )",
        opacity: 0.6,
      }
    : {},
}));

export interface OrganizationCardProps<
  T extends Pick<OrganizationResponse, "id" | "name" | "template">
> {
  server: T;
  tenant?: Tenant;
  progress?: boolean;
  connectProgress?: boolean;
  deleteProgress?: boolean;
  selected?: string;
  onSelectServer: (aid: string) => void;
  onMenuOpen?: (props: {
    anchorEl: HTMLElement;
    server: OrganizationResponse;
    rootServer?: OrganizationResponse | WorkspaceResponse;
  }) => void;
  isGrayedOut?: boolean;
  greyOutReason?: string;
  withTemplateOrDefaultTemplate?: boolean;
}

export default function OrganizationCard<
  T extends Pick<OrganizationResponse, "id" | "name" | "template">
>({
  server,
  tenant,
  progress,
  connectProgress,
  deleteProgress,
  selected,
  onSelectServer,
  onMenuOpen,
  isGrayedOut = false,
  greyOutReason,
  withTemplateOrDefaultTemplate,
}: OrganizationCardProps<T>) {
  const { cx, classes } = useWorkspaceCardStyles();
  const { classes: componentClasses } = useStyles({ isGrayedOut });

  const { id = "", name = "" } = server;

  const hasTemplateOrDefaultTemplate =
    withTemplateOrDefaultTemplate &&
    (server.template || tenant?.settings?.default_template_id === server.id);

  return (
    <div className={classes.root}>
      {deleteProgress && (
        <CircularProgress style={{ position: "absolute", top: 32, left: "calc(50% - 20px)" }} />
      )}
      {!deleteProgress && (
        <Tooltip title={greyOutReason || (isGrayedOut ? getServerTitle(server) : undefined)}>
          <span>
            <SelectablePaper
              id={`${id}-paper`}
              className={cx(classes.card, componentClasses.grayedOut)}
              selectable={!isGrayedOut}
              selected={server?.id === selected}
            >
              <div className={classes.cardTop}>
                <div
                  className={classes.cardTopLeft}
                  onClick={e => {
                    if (isGrayedOut) return;
                    e.preventDefault();
                    e.stopPropagation();
                    onSelectServer(id);
                  }}
                  style={{ alignItems: "center" }}
                >
                  <WorkspaceAvatar
                    server={{
                      ...server,
                      ...(isGrayedOut ? {} : { type: ServerResponseTypeEnum.Organization }),
                    }}
                  />
                  <OverflowTooltip
                    id="organization-card-name-tooltip"
                    value={name}
                    typographyStyle={{ fontWeight: 600, marginLeft: 8, marginRight: 22 }}
                    customTooltipContent={
                      <>
                        <div>
                          <b>{name}</b>
                        </div>
                        <div>
                          <b>ID:</b> {id}
                        </div>
                      </>
                    }
                  />
                </div>
                {!isGrayedOut && onMenuOpen && (
                  <div className={classes.cardActions}>
                    <IconButton
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        onMenuOpen({ anchorEl: e.currentTarget as HTMLElement, server });
                      }}
                      id={`${id}-menu-button`}
                      data-testid={`${id}-menu-button`}
                      aria-label={`${name} menu`}
                      className={classes.iconButton}
                    >
                      {!progress ? <MoreHoriz /> : <CircularProgress size="21px" />}
                    </IconButton>
                  </div>
                )}
              </div>

              {hasTemplateOrDefaultTemplate && (
                <div className={classes.cardBottom}>
                  {server.template && tenant?.settings?.default_template_id !== server.id && (
                    <div className={cx(classes.cardBottomItem)}>
                      <div className={classes.cardBottomItemIcon}>
                        <Layout size="14" />
                      </div>
                      <Typography variant="textXSM" className={classes.cardBottomItemText}>
                        {connectProgress ? (
                          <Skeleton variant="text" style={{ width: 100 }} />
                        ) : (
                          "Template"
                        )}
                      </Typography>
                    </div>
                  )}
                  {tenant?.settings?.default_template_id === server.id && (
                    <div className={cx(classes.cardBottomItem)}>
                      <div className={classes.cardBottomItemIcon}>
                        <Layout size="14" />
                      </div>
                      <Typography variant="textXSM" className={classes.cardBottomItemText}>
                        {connectProgress ? (
                          <Skeleton variant="text" style={{ width: 100 }} />
                        ) : (
                          "Default Template"
                        )}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </SelectablePaper>
          </span>
        </Tooltip>
      )}
    </div>
  );
}
