import React from "react";
import { useNavigate } from "react-router";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import { System } from "@cloudentity/acp-permissions";

import OverflowTooltip from "../../../../common/components/OverflowTooltip";
import SelectablePaper from "../../../../common/components/SelectablePaper";

interface Props {
  system: System;
}

function PermissionsGridItem({ system }: Props) {
  const navigate = useNavigate();

  return (
    <SelectablePaper
      id={`${system.id}`}
      selectable
      onSelect={() => navigate(`/permissions/${system.id}/quickstart`)}
      style={{ minHeight: 155 }}
    >
      <div style={{ display: "flex", padding: "16px 12px", maxWidth: "100%", width: "100%" }}>
        <Avatar
          style={{
            marginRight: 8,
            background: "#5DB082",
          }}
        >
          {system.name[0]?.toUpperCase()}
        </Avatar>
        <div style={{ maxWidth: "calc(100% - 112px)" }}>
          <OverflowTooltip id="workspace-card-name-tooltip" value={system.name} />
          <Typography variant="caption" style={{ color: "#9EA1B7" }}>
            {system.description || "No description"}
          </Typography>
        </div>
      </div>
    </SelectablePaper>
  );
}

export default PermissionsGridItem;
