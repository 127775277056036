{
  "additionalProperties": false,
  "type": "object",
  "properties": {
    "additionalProperties": {
      "type": "boolean",
      "x-nullable": false
    },
    "allOf": {
      "items": {
        "$ref": "#"
      },
      "type": "array",
      "x-nullable": false,
      "minItems": 1
    },
    "anyOf": {
      "items": {
        "$ref": "#"
      },
      "type": "array",
      "x-nullable": false,
      "minItems": 1
    },
    "const": {
      "type": "string",
      "x-nullable": false,
      "minLength": 1
    },
    "contains": {
      "$ref": "#",
      "minProperties": 1
    },
    "dependentRequired": {
      "additionalProperties": {
        "items": {
          "type": "string"
        },
        "type": "array"
      },
      "type": "object",
      "x-nullable": false
    },
    "dependentSchemas": {
      "additionalProperties": {
        "$ref": "#"
      },
      "type": "object",
      "x-nullable": false,
      "minProperties": 1
    },
    "title": {
      "type": "string",
      "x-nullable": false,
      "minLength": 1
    },
    "description": {
      "type": "string",
      "x-nullable": false,
      "minLength": 1
    },
    "else": {
      "$ref": "#"
    },
    "enum": {
      "items": {
        "type": "string"
      },
      "type": "array",
      "x-nullable": false,
      "minItems": 1
    },
    "exclusiveMaximum": {
      "format": "int64",
      "type": "integer",
      "x-nullable": false
    },
    "exclusiveMinimum": {
      "format": "int64",
      "type": "integer",
      "x-nullable": false
    },
    "if": {
      "$ref": "#"
    },
    "items": {
      "$ref": "#"
    },
    "maxContains": {
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "maxItems": {
      "description": "arrays",
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "maxLength": {
      "description": "strings",
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "maxProperties": {
      "description": "objects",
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "maximum": {
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "minContains": {
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "minItems": {
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "minLength": {
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "minProperties": {
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "minimum": {
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "multipleOf": {
      "description": "numeric",
      "format": "int64",
      "type": "integer",
      "minimum": 1,
      "x-nullable": false
    },
    "not": {
      "$ref": "#"
    },
    "oneOf": {
      "items": {
        "$ref": "#"
      },
      "type": "array",
      "x-nullable": false,
      "minItems": 1
    },
    "pattern": {
      "type": "string",
      "x-nullable": false,
      "minLength": 1
    },
    "patternProperties": {
      "additionalProperties": {
        "$ref": "#"
      },
      "type": "object",
      "x-nullable": false,
      "minProperties": 1
    },
    "properties": {
      "additionalProperties": {
        "$ref": "#"
      },
      "type": "object",
      "x-nullable": false,
      "minProperties": 1
    },
    "propertyNames": {
      "$ref": "#"
    },
    "required": {
      "items": {
        "type": "string"
      },
      "type": "array",
      "x-nullable": false,
      "minItems": 1
    },
    "then": {
      "$ref": "#"
    },
    "type": {
      "description": "one of: \"null\", \"boolean\", \"integer\", \"string\", \"number\", \"array\", \"object\"",
      "type": "string",
      "enum": ["null", "boolean", "integer", "string", "number", "array", "object"],
      "x-nullable": false
    },
    "uniqueItems": {
      "type": "boolean",
      "x-nullable": false
    },
    "hidden": {
      "type": "boolean",
      "x-nullable": false
    }
  }
}
