import React from "react";
import { Route, Routes } from "react-router-dom";

import CustomApplicationDetails from "./CustomApplicationDetails";
import CustomApplications from "./CustomApplications";

export default function CustomApplicationsRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CustomApplications />} />
      <Route path="/:customAppID" element={<CustomApplicationDetails />} />
    </Routes>
  );
}
