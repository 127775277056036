import React, { useState } from "react";

import DashboardCustomizeOutlined from "@mui/icons-material/DashboardCustomizeOutlined";
import Grid from "@mui/material/Grid";

import { System } from "@cloudentity/acp-permissions";

import EmptyState from "../../../../common/components/EmptyState";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PermissionCreate from "./PermissionCreate";
import PermissionsGridItem from "./PermissionsGridItem";

interface Props {
  systems: System[];
  onCreate: (data: any) => Promise<void>;
}

function PermissionsGrid({ systems, onCreate }: Props) {
  const [createDialog, setCreateDialog] = useState(false);
  const systemNames = systems.map(system => system.name);

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  return (
    <>
      {systems.length > 0 && (
        <Grid container spacing={3}>
          {systems
            .sort((a, b) => ("" + a.name).localeCompare("" + b.name))
            .map(system => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={system.id}>
                <PermissionsGridItem system={system} />
              </Grid>
            ))}
        </Grid>
      )}
      {systems.length === 0 && (
        <EmptyState
          title="Permission Systems"
          description="You haven't created any permission systems yet"
          buttonContent="+ Create system"
          onSubmit={
            checkTenantPermissionsQuery.data?.manage_permission_systems
              ? () => setCreateDialog(true)
              : undefined
          }
          icon={DashboardCustomizeOutlined}
          style={{ marginTop: 80 }}
        />
      )}
      {createDialog && (
        <PermissionCreate
          onClose={() => setCreateDialog(false)}
          onCreate={onCreate}
          systemNames={systemNames}
        />
      )}
    </>
  );
}

export default PermissionsGrid;
