import React, { Dispatch, SetStateAction } from "react";

import DoneAll from "@mui/icons-material/DoneAll";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Icon, Trash, X } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "white",
    paddingLeft: 16,
  },
  button: {
    position: "relative",
    color: "white",
    margin: "0 4px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  right: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    display: "flex",
    marginRight: 8,
    position: "relative",
  },
  crossedIcon: {
    "&::before": {
      position: "absolute",
      content: '""',
      background: "white",
      width: 1,
      height: "100%",
      transform: "rotate(-45deg)",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: "auto",
    },
  },
  iconCrossedOut: {
    position: "absolute",
    left: 14,
    top: 6,
    transform: "rotate(135deg)",
  },
}));

interface Props {
  selectedRows: number[];
  setSelectedRows: Dispatch<SetStateAction<number[]>>;
  allRowsNumber: number;
  onDelete: () => void;
  onCancel: () => void;
  customActions: {
    icon: Icon;
    label: string;
    onClick: () => void;
    isIconCrossedOut?: boolean;
  }[];
}

export default function BulkActionsToolbar({
  selectedRows,
  setSelectedRows,
  allRowsNumber,
  onDelete,
  onCancel,
  customActions,
}: Props) {
  const { cx, classes } = useStyles();

  const handleSelectAll = () => {
    setSelectedRows(Array.from({ length: allRowsNumber }, (_, i) => i));
  };

  const handleDeselectAll = () => {
    setSelectedRows([]);
  };

  return (
    <div className={classes.container}>
      <div>
        {selectedRows.length > 0
          ? `${selectedRows.length} item(s) selected`
          : "Update multiple mappings"}
      </div>
      <div className={classes.right}>
        <div>
          |
          {customActions.map(({ icon: Icon, label, onClick, isIconCrossedOut }) => (
            <Button
              key={label}
              className={classes.button}
              onClick={onClick}
              style={{ position: "relative" }}
            >
              <div className={cx(classes.icon, isIconCrossedOut && classes.crossedIcon)}>
                <Icon size={14} />
              </div>
              {label}
            </Button>
          ))}
          |
        </div>
        <div>
          <Button className={classes.button} onClick={onDelete}>
            <Trash size={14} className={classes.icon} />
            Delete
          </Button>
          {selectedRows.length === allRowsNumber ? (
            <Button className={classes.button} onClick={handleDeselectAll}>
              <div className={cx(classes.icon, classes.crossedIcon)}>
                <DoneAll style={{ fontSize: 18 }} />
              </div>
              Deselect all
            </Button>
          ) : (
            <Button className={classes.button} onClick={handleSelectAll}>
              <DoneAll style={{ fontSize: 18 }} className={classes.icon} />
              Select all
            </Button>
          )}
          |
        </div>
        <IconButton className={classes.button} onClick={onCancel}>
          <X size={16} />
        </IconButton>
      </div>
    </div>
  );
}
