import React, { CSSProperties } from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ disabled?: boolean }>()((theme, { disabled }) => ({
  container: {
    backgroundColor: "#F7FAFF",
    border: "solid 1px #E9EBFA",
    padding: 8,
    width: "100%",
    display: "flex",
    alignItems: "center",
    cursor: disabled ? "initial" : "pointer",
    borderRadius: 4,
  },
  card: {
    flex: 1,
    padding: 16,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: theme.palette.secondary.light,
    fontSize: 12,
  },
  description: {
    color: theme.palette.secondary.light,
  },
  activeColor: {
    color: theme.palette.primary.main,
  },
  active: {
    backgroundColor: "white",
    boxShadow: theme.custom.shadows.surfaceResting,
    color: theme.palette.primary.main,
  },
  iconContainer: {
    backgroundColor: "#F0F7FF",
    borderRadius: 32,
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
  },
}));

interface Props<T> {
  value: T;
  disabled?: boolean;
  onChange: (value: T) => void;
  cards: {
    title: string;
    description?: string;
    value: T;
    img?: string;
    imgComponent?: any;
  }[];
  id: string;
  cardStyle?: CSSProperties;
}

function CardRadioGroup<T extends string>({
  value,
  disabled,
  onChange,
  cards,
  id,
  cardStyle,
}: Props<T>) {
  const { cx, classes } = useStyles({ disabled });

  return (
    <div className={classes.container} id={`card-radio-group-${id}`}>
      {cards.map(({ title, value: cardValue, img, imgComponent: ImgComponent, description }) => {
        const isActive = value === cardValue;
        return (
          <div
            className={cx(classes.card, isActive && classes.active)}
            onClick={() => !isActive && !disabled && onChange(cardValue)}
            role="button"
            key={cardValue}
            id={`card-radio-${id}-${cardValue}`}
            style={cardStyle}
          >
            <div className={classes.iconContainer}>
              {img && <img src={img} alt="icon" />}
              {ImgComponent && <ImgComponent active={isActive} />}
            </div>
            <div>
              <Typography
                variant="h5"
                className={cx(classes.title, isActive && classes.activeColor)}
              >
                {title}
              </Typography>
              {description && (
                <Typography
                  variant="textXSM"
                  className={cx(classes.description, isActive && classes.activeColor)}
                >
                  {description}
                </Typography>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CardRadioGroup;
