import React from "react";
import { useLocation } from "react-router";

import { makeStyles } from "tss-react/mui";

import { TOP_BAR_HEIGHT } from "../../../common/components/nav/utils";
import { shouldRenderMobileLikeNav } from "../AdminPanel";
import { drawerCollapsedWidth, drawerWidth } from "./NavDrawer";

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: TOP_BAR_HEIGHT,

    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
    },

    [theme.breakpoints.down("lg")]: {
      marginLeft: drawerCollapsedWidth,
    },
  },
  containerForceMobile: {
    marginLeft: drawerCollapsedWidth,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

interface Props {
  nav: () => React.ReactElement;
  children: React.ReactElement;
  disabled?: boolean;
}

export default function NavAndContentContainer({ nav: Nav, children, disabled }: Props) {
  const { cx, classes } = useStyles();
  const { pathname } = useLocation();
  const forceMobile = shouldRenderMobileLikeNav(pathname);

  return (
    <div className={cx(classes.container, forceMobile && classes.containerForceMobile)}>
      <div className={cx(disabled && classes.disabled)}>
        <Nav />
      </div>
      {children}
    </div>
  );
}
