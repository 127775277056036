export const palette = {
  primary: {
    light: "#e5f1fc",
    main: "#0075E2",
  },
  secondary: {
    light: "#626576",
    main: "#434656",
    dark: "#213D55",
  },
  error: {
    light: "#FFF8F7",
    main: "#BD271E",
  },
  warning: {
    main: "#ff9800",
  },
  success: {
    main: "#4caf50",
  },
  background: {
    default: "#F6F7F8",
  },
};
