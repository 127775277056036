import React from "react";

import { User } from "react-feather";

import { useGetUserInfo } from "../../../admin/services/oauth2Query";
import OverflowTooltip from "../OverflowTooltip";
import { useTopBarStyles } from "./utils";

interface Props {
  stripped?: boolean;
}

export default function ProfileSettings({ stripped }: Props) {
  const { cx, classes } = useTopBarStyles({ stripped });
  const userinfoQuery = useGetUserInfo();

  const name =
    userinfoQuery.data?.given_name && userinfoQuery.data?.family_name
      ? userinfoQuery.data?.given_name + " " + userinfoQuery.data?.family_name
      : "";

  if (!name && !userinfoQuery.data?.email) {
    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 8,
        padding: "8px 24px",
      }}
    >
      <div
        className={classes.avatar}
        style={{
          border: `solid 2px rgba(0, 0, 0, 0.87)`,
          minWidth: 32,
        }}
      >
        {userinfoQuery.data?.email ? userinfoQuery.data?.email.slice(0, 2) : <User size={20} />}
      </div>
      <div className={classes.flexContainer}>
        <OverflowTooltip
          value={name}
          typographyProps={{ className: cx(classes.textEllipsis, classes.name) }}
        />
        <OverflowTooltip
          value={userinfoQuery.data?.email}
          typographyProps={{ className: cx(classes.textEllipsis) }}
          typographyStyle={{ paddingTop: 0, maxWidth: "unset", fontSize: 12, lineHeight: "16px" }}
        />
      </div>
    </div>
  );
}
