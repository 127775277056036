import React, { useMemo } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import { useQueryClient } from "@tanstack/react-query";

import { Styling } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import { getStylingQueryKey } from "../../../common/api/stylingQuery";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import { getDefaultStyling } from "../../../common/utils/hooks/useStyling";
import adminTenantsApi from "../../services/adminTenantsApi";
import {
  getTenantQueryKey,
  useCheckTenantPermissions,
  useGetTenant,
} from "../../services/adminTenantsQuery";
import PageContent from "../common/PageContent";
import PageHeader from "../common/PageHeader";
import Appearance from "./Appearance";

export default function TenantBranding() {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const defaultStyling = useMemo(() => getDefaultStyling(theme), [theme]);

  const getTenantQuery = useGetTenant(getTenantId());
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  function updateTenant(styling?: Styling) {
    if (styling) {
      return adminTenantsApi
        .updateTenant({ tenant: { ...getTenantQuery.data, styling } })
        .then(res => queryClient.setQueryData(getTenantQueryKey(getTenantQuery.data?.id), res.data))
        .then(() => queryClient.invalidateQueries({ queryKey: getStylingQueryKey() }))
        .catch(handleErrorWithNotify("Error occurred while trying to update tenant"));
    }

    return Promise.resolve();
  }

  return (
    <>
      {getTenantQuery.isLoading || checkTenantPermissionsQuery.isLoading ? (
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%", zIndex: 1000 }}
        />
      ) : (
        <>
          <PageHeader title="Branding" />
          <PageContent>
            <Appearance
              styling={getTenantQuery.data?.styling || defaultStyling}
              onUpdate={updateTenant}
              mode="tenant"
              hasManagePermission={
                !!checkTenantPermissionsQuery.data?.update_tenant &&
                !!checkTenantPermissionsQuery.data?.manage_themes
              }
            />
          </PageContent>
        </>
      )}
    </>
  );
}
