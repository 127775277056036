import { BruteForceLimits } from "@cloudentity/acp-admin";

import adminBruteForceLimitsApi from "./adminBruteForceApi";
import { useQueryWithTenantPermissionCheck, withQueryError } from "./queryUtils";

const LIST_BRUTE_FORCE_LIMITS = "LIST_BRUTE_FORCE_LIMITS";

export const listBruteForceLimitsQueryKey = tid => [LIST_BRUTE_FORCE_LIMITS, tid];

export const useListBruteForceLimits = (tid, options?) =>
  useQueryWithTenantPermissionCheck(
    "read_brute_force_protection",
    listBruteForceLimitsQueryKey(tid),
    withQueryError<BruteForceLimits>(async () => {
      const data = await adminBruteForceLimitsApi.listBruteForceLimits({});
      return data.data;
    }, "Error occurred while trying to list brute-force limits"),
    options
  );
