import { CustomAppsResponse } from "@cloudentity/acp-admin";

import adminCustomAppsApi from "./adminCustomAppsApi";
import { useQueryWithWorkspacePermissionCheck, withQueryError } from "./queryUtils";

const LIST_SERVER_CUSTOM_APPS_QUERY = "LIST_SERVER_CUSTOM_APPS_QUERY";
const GET_CUSTOM_APP_QUERY = "GET_CUSTOM_APP_QUERY";

export const listServerCustomAppsQueryKey = (tid: string, wid: string) => [
  LIST_SERVER_CUSTOM_APPS_QUERY,
  tid,
  wid,
];

export const getCustomAppQueryKey = (tid: string, wid: string, customAppID: string) => [
  GET_CUSTOM_APP_QUERY,
  tid,
  wid,
  customAppID,
];

export const useListServerCustomApps = (tid: string, wid: string, options?: any) =>
  useQueryWithWorkspacePermissionCheck<CustomAppsResponse>(
    wid,
    "read_custom_apps",
    listServerCustomAppsQueryKey(tid, wid),
    withQueryError<CustomAppsResponse>(async () => {
      const data = await adminCustomAppsApi.listServerCustomApps({ wid });
      return data.data;
    }, "Error occurred while trying to list server customApps"),
    options
  );

export const useGetCustomApp = (tid: string, wid: string, customAppID: string, options?: any) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "read_custom_apps",
    getCustomAppQueryKey(tid, wid, customAppID),
    async () => {
      const data = await adminCustomAppsApi.getCustomApp({ wid, customAppID });
      return data.data;
    },
    options
  );
