import React, { useState } from "react";

import { UserVerifiableAddressTypeEnum } from "@cloudentity/acp-identity";

import Dialog from "../../../../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../../../common/utils/forms/FormFooter";
import SelectField from "../../../../../../../common/utils/forms/SelectField";
import { useGetPool } from "../../../../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import { useGetUser } from "../../../../../../services/adminIdentityUsersQuery";
import { useWorkspace } from "../../../../../common/useWorkspace";
import { usePoolRootUrl } from "../../../utils";
import IdentityPoolThemeSelector from "../../IdentityPoolThemeSelector";
import {
  setPoolWorkspaceId,
  useIdentityPoolThemeSelector,
} from "../../useIdentityPoolThemeSelector";

interface Props {
  poolId: string;
  userId: string;
  onClose: () => void;
}

const data = {
  address: "",
};

export default function SendActivationMessageDialog({ poolId, userId, onClose }: Props) {
  const [progress, setProgress] = useState(false);

  const [workspace] = useWorkspace();
  const { mode } = usePoolRootUrl();

  const poolQuery = useGetPool(poolId);
  const getUserQuery = useGetUser(poolId, userId);

  const form = useForm({
    id: "send-activation-message",
    initialValues: data,
    progress: progress || poolQuery.isLoading || getUserQuery.isLoading,
  });

  const { themeWorkspaceId, mustSelectThemeServerId } = useIdentityPoolThemeSelector({
    pool: poolQuery.data,
  });

  const handleSendMessage = data =>
    identityUsersApi
      .sendActivationMessage({
        ipID: poolId,
        userID: userId,
        sendActivationMessage: { address: data.address },
        serverId: mode === "workspace" ? workspace : data.themeWorkspaceId || themeWorkspaceId,
      })
      .then(() => {
        notifySuccess(
          <span>
            Invite sent to: <strong>{data.address}</strong>
          </span>
        );
        onClose();
      })
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to send invitation message"))
      .finally(() => setProgress(false));

  const handleSubmit = data => {
    setProgress(true);
    if (data.rememberThemeWorkspaceId) {
      setPoolWorkspaceId(poolQuery.data?.id || "", data.themeWorkspaceId || "");
      return handleSendMessage(data);
    }

    return handleSendMessage(data);
  };

  return (
    <Dialog
      onClose={onClose}
      id="send-activation-message-dialog"
      title="Activation message address selection"
    >
      <Form form={form}>
        {getUserQuery.data?.verifiable_addresses && (
          <SelectField
            name="address"
            label="Email"
            options={(getUserQuery.data?.verifiable_addresses || [])
              .filter(a => a.type === UserVerifiableAddressTypeEnum.Email)
              .map(({ address }) => ({
                value: address,
                name: address,
              }))}
            optional={false}
            multiple={false}
            rules={{
              required: "Email is required",
            }}
          />
        )}
        {mustSelectThemeServerId && (
          <IdentityPoolThemeSelector pool={poolQuery.data} showRememberCheckbox />
        )}
        <FormFooter onCancel={onClose} onSubmit={handleSubmit} submitText="Send" />
      </Form>
    </Dialog>
  );
}
