import React from "react";

import RouterLink from "../../../../common/components/RouterLink";
import { useWorkspace } from "../../common/useWorkspace";
import DocLink from "./DocLink";

export default function QuickstartArticleMFA() {
  const [workspace] = useWorkspace();

  return (
    <>
      <p>
        SecureAuth's adaptive MFA enhances security by requiring multiple forms of verification,
        dynamically adjusting to user behavior and risk levels. This ensures robust protection while
        maintaining a seamless user experience. MFA can combine various authentication factors,
        including:
      </p>
      <ul>
        <li>
          <b>Knowledge</b>: Passwords.
        </li>

        <li>
          <b>Possession</b>: Mobile devices and Passkeys.
        </li>

        <li>
          <b>Risk-Based Factor</b>: Evaluates contextual data such as user behavior, location, and
          IP address to assess the risk associated with a login attempt. If a high-risk situation is
          detected, additional authentication methods are triggered, enhancing security by adapting
          dynamically to potential threats.
        </li>
      </ul>
      <p>
        To configure Adaptive MFA, administrators can use the following options for built-in
        Identity Pool users, application access, and application scopes.
      </p>

      <ol type="a">
        <li>
          <p>
            <b>Adaptive MFA for Built-In Identity Pool Users</b>
          </p>
          <p>
            This option allows you to set up a range of MFA methods tailored to your security needs
            for Identity Pool users. Here's how to set it up:
          </p>
          <p>Setup Instructions:</p>

          <ol>
            <li>
              Navigate to <b>Users &gt; Sign-In and Sign-Up</b> in the admin dashboard.
            </li>

            <li>
              In the <b>Second-Factor Authentication Methods</b> section, choose from the following
              options:
            </li>

            <li>
              <b>Verification Code</b>: Sends a one-time code for enhanced verification via Email or
              SMS.
            </li>

            <li>
              <b>Authenticator App</b>: A mobile app that generates Time-based One-Time Passwords
              (TOTP) and can receive push notifications and symbol-based push requests, adding an
              extra layer of security by requiring user approval.
            </li>

            <li>
              <b>Passkey</b>: A secure, passwordless option using FIDO2 devices like YubiKey or
              Touch ID. Users can authenticate with a physical or biometric key, eliminating
              traditional passwords.
            </li>

            <li>
              <b>Reduce 2FA Verification on Same Device</b>: Allows users to bypass 2FA on the same
              device for a defined period, enhancing convenience. Set to “0 seconds” to disable this
              feature entirely.
            </li>

            <li>
              <b>Level of Assurance (LOA) Threshold</b>: Users will be prompted for a second factor
              if the real-time LOA falls below the required threshold. This setting enables adaptive
              security based on risk factors, triggering additional verification only when
              necessary.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Adaptive MFA for Application Access</b>
          </p>
          <p>
            In this setup, MFA is applied when users attempt to access specific applications. After
            entering their primary credentials (username and password), users are prompted to select
            an additional verification method based on the data they have enabled for MFA with the
            identity provider. If the process succeeds, the user can continue with the access
            request.
          </p>
          <p>Setup Instructions:</p>

          <ol>
            <li>
              Go to{" "}
              <b>
                <RouterLink to={`/${workspace}/applications`} target="_blank" withIcon>
                  <b>Applications</b>
                </RouterLink>{" "}
                &gt; [Your Application] &gt; Access Controls
              </b>{" "}
              in the admin dashboard.
            </li>

            <li>
              Select the <b>User Policy</b> option and assign the <b>MFA User Policy</b>.
            </li>

            <li>
              <b>Save</b> the changes.
            </li>
          </ol>
        </li>
        <li>
          <p>
            <b>Adaptive MFA for Application Scopes</b>
          </p>
          <p>
            For even finer control, MFA can be configured to protect specific scopes requested by a
            client application. When enabled, MFA is required to grant access to these scopes,
            adding an additional layer of verification for sensitive operations.
          </p>
          <p>
            <b>Setup Instructions:</b>
          </p>
          <ol>
            <li>
              Click <b>Show all</b> in the left navigation to see advanced views.
            </li>
            <li>
              Navigate to <b>Applications &gt;</b>{" "}
              <b>
                <RouterLink to={`/${workspace}/services`} target="_blank" withIcon>
                  <b>Services</b>
                </RouterLink>{" "}
                &gt; [Your Service] &gt; Scopes
              </b>
              .
            </li>
            <li>Select the scopes that require MFA.</li>
            <li>
              Go to the <b>Governance</b> tab and choose the desired MFA method in the{" "}
              <b>User Policy</b> section.
            </li>
            <li>
              Click <b>Save</b>.
            </li>
          </ol>
        </li>
      </ol>
      <br />
      <p>
        Learn more by visiting{" "}
        <DocLink href="https://docs.secureauth.com/cloudentity/en/multi-factor-authentication.html">
          Multi-Factor Authentication (MFA)
        </DocLink>
        .
      </p>
    </>
  );
}
