import { useQueries } from "@tanstack/react-query";

import adminClientsApi from "../../services/adminClientsApi";
import { getClientQueryKey } from "../../services/adminClientsQuery";
import adminPermissionsApi from "../../services/adminPermissionsApi";
import { useListAuthorizationServersHierarchyByParentId } from "../../services/adminServersQuery";

export const useWorkspaceDefaultClientApp = (workspaceId: string) => {
  const listServersHierarchyQuery = useListAuthorizationServersHierarchyByParentId(workspaceId);

  const isHierarchyFetched =
    !listServersHierarchyQuery.isFetching &&
    !listServersHierarchyQuery.hasNextPage &&
    !listServersHierarchyQuery.isFetchingNextPage &&
    listServersHierarchyQuery.isFetched;

  const listClientsHierarchyQuery = useQueries({
    queries: (listServersHierarchyQuery.data?.pages || []).filter(Boolean).map(p => ({
      queryKey: getClientQueryKey(p?.settings?.default_client_id!),
      queryFn: async () => {
        const permissionsRes = await adminPermissionsApi.checkWorkspacePermissions({
          wid: p?.id!,
        });

        if (!!permissionsRes.data?.read_clients) {
          const data = await adminClientsApi.getClient({
            cid: p?.settings?.default_client_id!,
          });
          return data.data;
        }

        return null;
      },
      enabled: !!p?.settings?.default_client_id && isHierarchyFetched,
      retry: 0,
      refetchOnWindowFocus: false,
    })),
  });

  return {
    isLoading:
      listServersHierarchyQuery.isLoading ||
      listServersHierarchyQuery.hasNextPage ||
      listServersHierarchyQuery.isFetchingNextPage ||
      listClientsHierarchyQuery.some(p => p.isLoading),
    url: listClientsHierarchyQuery
      .map(p => p.data)
      .filter(Boolean)
      .filter(p => !!p?.app_url)
      ?.at(0)?.app_url,
  };
};
