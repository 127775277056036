import React, { CSSProperties } from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  label: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 12,
    marginTop: 32,
    color: theme.palette.secondary.dark,
  },
  content: {
    border: `1px solid ${theme.custom.greys.border}`,
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
  },
}));

interface Props {
  name: string;
  color: string;
  icon: JSX.Element;
  style?: CSSProperties;
}

export default function WorkspacesPreviewInput({ name, icon, style = {} }: Props) {
  const { classes } = useStyles();
  return (
    <div style={style}>
      <div className={classes.label}>Preview</div>
      <div className={classes.content}>
        {icon}
        <Typography style={{ overflow: "hidden", textOverflow: "ellipsis", marginLeft: 14 }}>
          {name}
        </Typography>
      </div>
    </div>
  );
}
