import React, { CSSProperties, ReactNode } from "react";

import Avatar, { AvatarProps } from "@mui/material/Avatar";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  avatar: {
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    width: 32,
    height: 32,
    fontSize: 12,
  },
}));

interface Props extends AvatarProps {
  children: ReactNode;
  style?: CSSProperties;
}

function AppAvatar({ children, style, ...props }: Props) {
  const { classes } = useStyles();

  return (
    <Avatar className={classes.avatar} style={style} {...props}>
      {children}
    </Avatar>
  );
}

export default AppAvatar;
