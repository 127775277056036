import React from "react";

import FabMUI, { FabProps as FabMUIProps } from "@mui/material/Fab";
import { Icon } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  fab: {
    padding: "12px 24px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.9,
      boxShadow: theme.custom.shadows.surfaceHover,
    },
  },
  square: {
    borderRadius: 4,
  },
  icon: {
    marginRight: 8,
  },
}));

interface FabProps extends Omit<FabMUIProps, "children"> {
  id?: string;
  children: FabMUIProps["children"];
  square?: boolean;
  startIcon?: Icon;
}

function Fab({ key, id, children, square = true, className, startIcon: Icon, ...props }: FabProps) {
  const { cx, classes } = useStyles();

  return (
    <FabMUI
      id={id}
      variant="extended"
      color="primary"
      size="large"
      className={cx(classes.fab, square && classes.square, className)}
      {...props}
    >
      {Icon && <Icon size={16} strokeWidth={3} className={classes.icon} />}
      {children}
    </FabMUI>
  );
}

export default Fab;
