import React, { ReactNode } from "react";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Copy } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { copyToClipboard } from "../services/clipboard";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  content: {
    display: "flex",
    alignItems: "center",
  },
  value: {
    fontWeight: 500,
    fontFamily: "monospace",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

interface Props {
  value: string;
  startAdornment?: ReactNode;
}

export default function ValueWithCopy({ value, startAdornment }: Props) {
  const { classes } = useStyles();

  function handleCopyToClipboard(value: string, label: string) {
    copyToClipboard(value, label);
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {startAdornment}
        <Typography variant="textMD" className={classes.value}>
          {value}
        </Typography>
      </div>
      <Tooltip placement="left" title={`Copy ${value} to clipboard`}>
        <IconButton
          size="small"
          style={{ marginLeft: 12 }}
          onClick={() => handleCopyToClipboard(value, "")}
        >
          <Copy size={16} />
        </IconButton>
      </Tooltip>
    </div>
  );
}
