"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Cloudentity API
 * Cloudentity Permissions API
 *
 * The version of the OpenAPI document: latest
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokensApi = exports.TokensApiFactory = exports.TokensApiFp = exports.TokensApiAxiosParamCreator = exports.SystemsApi = exports.SystemsApiFactory = exports.SystemsApiFp = exports.SystemsApiAxiosParamCreator = exports.RelationshipsApi = exports.RelationshipsApiFactory = exports.RelationshipsApiFp = exports.RelationshipsApiAxiosParamCreator = exports.PermissionsApi = exports.PermissionsApiFactory = exports.PermissionsApiFp = exports.PermissionsApiAxiosParamCreator = exports.RelationshipUpdateOperationEnum = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
    * @export
    * @enum {string}
    */
var RelationshipUpdateOperationEnum;
(function (RelationshipUpdateOperationEnum) {
    RelationshipUpdateOperationEnum["Insert"] = "insert";
    RelationshipUpdateOperationEnum["Upsert"] = "upsert";
    RelationshipUpdateOperationEnum["Delete"] = "delete";
})(RelationshipUpdateOperationEnum || (exports.RelationshipUpdateOperationEnum = RelationshipUpdateOperationEnum = {}));
/**
 * PermissionsApi - axios parameter creator
 * @export
 */
var PermissionsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Check permission.
         * @summary Check permission
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {CheckPermissionRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermission: function (psID_1, ifMatch_1, request_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1, request_1], args_1, true), void 0, function (psID, ifMatch, request, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('checkPermission', 'psID', psID);
                            localVarPath = "/system/systems/{psID}/check"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication token required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-API-KEY", configuration)];
                        case 1:
                            // authentication token required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(request, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Lookup resources.
         * @summary Lookup resources
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {LookupResourcesRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupResources: function (psID_1, ifMatch_1, request_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1, request_1], args_1, true), void 0, function (psID, ifMatch, request, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('lookupResources', 'psID', psID);
                            localVarPath = "/system/systems/{psID}/lookup/resources"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication token required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-API-KEY", configuration)];
                        case 1:
                            // authentication token required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(request, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Lookup subjects.
         * @summary Lookup subjects
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {LookupSubjectsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupSubjects: function (psID_1, ifMatch_1, request_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1, request_1], args_1, true), void 0, function (psID, ifMatch, request, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('lookupSubjects', 'psID', psID);
                            localVarPath = "/system/systems/{psID}/lookup/subjects"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication token required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-API-KEY", configuration)];
                        case 1:
                            // authentication token required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(request, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.PermissionsApiAxiosParamCreator = PermissionsApiAxiosParamCreator;
/**
 * PermissionsApi - functional programming interface
 * @export
 */
var PermissionsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PermissionsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Check permission.
         * @summary Check permission
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {CheckPermissionRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermission: function (psID, ifMatch, request, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.checkPermission(psID, ifMatch, request, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Lookup resources.
         * @summary Lookup resources
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {LookupResourcesRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupResources: function (psID, ifMatch, request, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.lookupResources(psID, ifMatch, request, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Lookup subjects.
         * @summary Lookup subjects
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {LookupSubjectsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupSubjects: function (psID, ifMatch, request, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.lookupSubjects(psID, ifMatch, request, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PermissionsApiFp = PermissionsApiFp;
/**
 * PermissionsApi - factory interface
 * @export
 */
var PermissionsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PermissionsApiFp)(configuration);
    return {
        /**
         * Check permission.
         * @summary Check permission
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {CheckPermissionRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermission: function (psID, ifMatch, request, options) {
            return localVarFp.checkPermission(psID, ifMatch, request, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Lookup resources.
         * @summary Lookup resources
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {LookupResourcesRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupResources: function (psID, ifMatch, request, options) {
            return localVarFp.lookupResources(psID, ifMatch, request, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Lookup subjects.
         * @summary Lookup subjects
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {LookupSubjectsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupSubjects: function (psID, ifMatch, request, options) {
            return localVarFp.lookupSubjects(psID, ifMatch, request, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PermissionsApiFactory = PermissionsApiFactory;
/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
var PermissionsApi = /** @class */ (function (_super) {
    __extends(PermissionsApi, _super);
    function PermissionsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Check permission.
     * @summary Check permission
     * @param {PermissionsApiCheckPermissionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    PermissionsApi.prototype.checkPermission = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PermissionsApiFp)(this.configuration).checkPermission(requestParameters.psID, requestParameters.ifMatch, requestParameters.request, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Lookup resources.
     * @summary Lookup resources
     * @param {PermissionsApiLookupResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    PermissionsApi.prototype.lookupResources = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PermissionsApiFp)(this.configuration).lookupResources(requestParameters.psID, requestParameters.ifMatch, requestParameters.request, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Lookup subjects.
     * @summary Lookup subjects
     * @param {PermissionsApiLookupSubjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    PermissionsApi.prototype.lookupSubjects = function (requestParameters, options) {
        var _this = this;
        return (0, exports.PermissionsApiFp)(this.configuration).lookupSubjects(requestParameters.psID, requestParameters.ifMatch, requestParameters.request, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PermissionsApi;
}(base_1.BaseAPI));
exports.PermissionsApi = PermissionsApi;
/**
 * RelationshipsApi - axios parameter creator
 * @export
 */
var RelationshipsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Delete relationships.
         * @summary Delete relationships
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {DeleteRelationshipsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRelationships: function (psID_1, ifMatch_1, request_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1, request_1], args_1, true), void 0, function (psID, ifMatch, request, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('deleteRelationships', 'psID', psID);
                            localVarPath = "/system/systems/{psID}/relationships"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication token required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-API-KEY", configuration)];
                        case 1:
                            // authentication token required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(request, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Read relationships.
         * @summary Read relationships
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ReadRelationshipsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRelationships: function (psID_1, ifMatch_1, request_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1, request_1], args_1, true), void 0, function (psID, ifMatch, request, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('readRelationships', 'psID', psID);
                            localVarPath = "/system/systems/{psID}/relationships"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication token required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-API-KEY", configuration)];
                        case 1:
                            // authentication token required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(request, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Write relationships.
         * @summary Write relationships
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {WriteRelationshipsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeRelationships: function (psID_1, ifMatch_1, request_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1, request_1], args_1, true), void 0, function (psID, ifMatch, request, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('writeRelationships', 'psID', psID);
                            localVarPath = "/system/systems/{psID}/relationships"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication token required
                            return [4 /*yield*/, (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "X-API-KEY", configuration)];
                        case 1:
                            // authentication token required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(request, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.RelationshipsApiAxiosParamCreator = RelationshipsApiAxiosParamCreator;
/**
 * RelationshipsApi - functional programming interface
 * @export
 */
var RelationshipsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.RelationshipsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Delete relationships.
         * @summary Delete relationships
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {DeleteRelationshipsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRelationships: function (psID, ifMatch, request, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteRelationships(psID, ifMatch, request, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Read relationships.
         * @summary Read relationships
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ReadRelationshipsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRelationships: function (psID, ifMatch, request, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.readRelationships(psID, ifMatch, request, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Write relationships.
         * @summary Write relationships
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {WriteRelationshipsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeRelationships: function (psID, ifMatch, request, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.writeRelationships(psID, ifMatch, request, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.RelationshipsApiFp = RelationshipsApiFp;
/**
 * RelationshipsApi - factory interface
 * @export
 */
var RelationshipsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.RelationshipsApiFp)(configuration);
    return {
        /**
         * Delete relationships.
         * @summary Delete relationships
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {DeleteRelationshipsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRelationships: function (psID, ifMatch, request, options) {
            return localVarFp.deleteRelationships(psID, ifMatch, request, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Read relationships.
         * @summary Read relationships
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {ReadRelationshipsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRelationships: function (psID, ifMatch, request, options) {
            return localVarFp.readRelationships(psID, ifMatch, request, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Write relationships.
         * @summary Write relationships
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {WriteRelationshipsRequest} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeRelationships: function (psID, ifMatch, request, options) {
            return localVarFp.writeRelationships(psID, ifMatch, request, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.RelationshipsApiFactory = RelationshipsApiFactory;
/**
 * RelationshipsApi - object-oriented interface
 * @export
 * @class RelationshipsApi
 * @extends {BaseAPI}
 */
var RelationshipsApi = /** @class */ (function (_super) {
    __extends(RelationshipsApi, _super);
    function RelationshipsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Delete relationships.
     * @summary Delete relationships
     * @param {RelationshipsApiDeleteRelationshipsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationshipsApi
     */
    RelationshipsApi.prototype.deleteRelationships = function (requestParameters, options) {
        var _this = this;
        return (0, exports.RelationshipsApiFp)(this.configuration).deleteRelationships(requestParameters.psID, requestParameters.ifMatch, requestParameters.request, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Read relationships.
     * @summary Read relationships
     * @param {RelationshipsApiReadRelationshipsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationshipsApi
     */
    RelationshipsApi.prototype.readRelationships = function (requestParameters, options) {
        var _this = this;
        return (0, exports.RelationshipsApiFp)(this.configuration).readRelationships(requestParameters.psID, requestParameters.ifMatch, requestParameters.request, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Write relationships.
     * @summary Write relationships
     * @param {RelationshipsApiWriteRelationshipsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RelationshipsApi
     */
    RelationshipsApi.prototype.writeRelationships = function (requestParameters, options) {
        var _this = this;
        return (0, exports.RelationshipsApiFp)(this.configuration).writeRelationships(requestParameters.psID, requestParameters.ifMatch, requestParameters.request, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return RelationshipsApi;
}(base_1.BaseAPI));
exports.RelationshipsApi = RelationshipsApi;
/**
 * SystemsApi - axios parameter creator
 * @export
 */
var SystemsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Creates a permission system.
         * @summary Create permission system
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {System} [system]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSystem: function (ifMatch_1, system_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ifMatch_1, system_1], args_1, true), void 0, function (ifMatch, system, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/admin/systems";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication admin required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "admin", [], configuration)];
                        case 1:
                            // authentication admin required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(system, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Deletes permission system.
         * @summary Delete permission system
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSystem: function (psID_1, ifMatch_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1], args_1, true), void 0, function (psID, ifMatch, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('deleteSystem', 'psID', psID);
                            localVarPath = "/admin/systems/{psID}"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication admin required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "admin", [], configuration)];
                        case 1:
                            // authentication admin required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Gets permission system.
         * @summary Get permission system
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystem: function (psID_1, ifMatch_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1], args_1, true), void 0, function (psID, ifMatch, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('getSystem', 'psID', psID);
                            localVarPath = "/admin/systems/{psID}"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication admin required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "admin", [], configuration)];
                        case 1:
                            // authentication admin required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Lists permission systems.
         * @summary List permission systems
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSystems: function (ifMatch_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([ifMatch_1], args_1, true), void 0, function (ifMatch, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localVarPath = "/admin/systems";
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication admin required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "admin", [], configuration)];
                        case 1:
                            // authentication admin required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Updates permission system.
         * @summary Update permission system
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {System} [system]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystem: function (psID_1, ifMatch_1, system_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1, system_1], args_1, true), void 0, function (psID, ifMatch, system, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('updateSystem', 'psID', psID);
                            localVarPath = "/admin/systems/{psID}"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication admin required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "admin", [], configuration)];
                        case 1:
                            // authentication admin required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(system, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.SystemsApiAxiosParamCreator = SystemsApiAxiosParamCreator;
/**
 * SystemsApi - functional programming interface
 * @export
 */
var SystemsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.SystemsApiAxiosParamCreator)(configuration);
    return {
        /**
         * Creates a permission system.
         * @summary Create permission system
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {System} [system]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSystem: function (ifMatch, system, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createSystem(ifMatch, system, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Deletes permission system.
         * @summary Delete permission system
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSystem: function (psID, ifMatch, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteSystem(psID, ifMatch, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Gets permission system.
         * @summary Get permission system
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystem: function (psID, ifMatch, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getSystem(psID, ifMatch, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Lists permission systems.
         * @summary List permission systems
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSystems: function (ifMatch, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listSystems(ifMatch, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Updates permission system.
         * @summary Update permission system
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {System} [system]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystem: function (psID, ifMatch, system, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateSystem(psID, ifMatch, system, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.SystemsApiFp = SystemsApiFp;
/**
 * SystemsApi - factory interface
 * @export
 */
var SystemsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.SystemsApiFp)(configuration);
    return {
        /**
         * Creates a permission system.
         * @summary Create permission system
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {System} [system]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSystem: function (ifMatch, system, options) {
            return localVarFp.createSystem(ifMatch, system, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Deletes permission system.
         * @summary Delete permission system
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSystem: function (psID, ifMatch, options) {
            return localVarFp.deleteSystem(psID, ifMatch, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Gets permission system.
         * @summary Get permission system
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystem: function (psID, ifMatch, options) {
            return localVarFp.getSystem(psID, ifMatch, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Lists permission systems.
         * @summary List permission systems
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSystems: function (ifMatch, options) {
            return localVarFp.listSystems(ifMatch, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Updates permission system.
         * @summary Update permission system
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {System} [system]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystem: function (psID, ifMatch, system, options) {
            return localVarFp.updateSystem(psID, ifMatch, system, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.SystemsApiFactory = SystemsApiFactory;
/**
 * SystemsApi - object-oriented interface
 * @export
 * @class SystemsApi
 * @extends {BaseAPI}
 */
var SystemsApi = /** @class */ (function (_super) {
    __extends(SystemsApi, _super);
    function SystemsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Creates a permission system.
     * @summary Create permission system
     * @param {SystemsApiCreateSystemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemsApi
     */
    SystemsApi.prototype.createSystem = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.SystemsApiFp)(this.configuration).createSystem(requestParameters.ifMatch, requestParameters.system, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Deletes permission system.
     * @summary Delete permission system
     * @param {SystemsApiDeleteSystemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemsApi
     */
    SystemsApi.prototype.deleteSystem = function (requestParameters, options) {
        var _this = this;
        return (0, exports.SystemsApiFp)(this.configuration).deleteSystem(requestParameters.psID, requestParameters.ifMatch, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Gets permission system.
     * @summary Get permission system
     * @param {SystemsApiGetSystemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemsApi
     */
    SystemsApi.prototype.getSystem = function (requestParameters, options) {
        var _this = this;
        return (0, exports.SystemsApiFp)(this.configuration).getSystem(requestParameters.psID, requestParameters.ifMatch, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Lists permission systems.
     * @summary List permission systems
     * @param {SystemsApiListSystemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemsApi
     */
    SystemsApi.prototype.listSystems = function (requestParameters, options) {
        var _this = this;
        if (requestParameters === void 0) { requestParameters = {}; }
        return (0, exports.SystemsApiFp)(this.configuration).listSystems(requestParameters.ifMatch, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Updates permission system.
     * @summary Update permission system
     * @param {SystemsApiUpdateSystemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemsApi
     */
    SystemsApi.prototype.updateSystem = function (requestParameters, options) {
        var _this = this;
        return (0, exports.SystemsApiFp)(this.configuration).updateSystem(requestParameters.psID, requestParameters.ifMatch, requestParameters.system, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SystemsApi;
}(base_1.BaseAPI));
exports.SystemsApi = SystemsApi;
/**
 * TokensApi - axios parameter creator
 * @export
 */
var TokensApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Creates a permission system token.
         * @summary Create permission system token
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: function (psID_1, ifMatch_1, token_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1, token_1], args_1, true), void 0, function (psID, ifMatch, token, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('createToken', 'psID', psID);
                            localVarPath = "/admin/systems/{psID}/tokens"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication admin required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "admin", [], configuration)];
                        case 1:
                            // authentication admin required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(token, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Deletes permission system token.
         * @summary Delete permission system token
         * @param {string} psID
         * @param {string} tokenID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken: function (psID_1, tokenID_1, ifMatch_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, tokenID_1, ifMatch_1], args_1, true), void 0, function (psID, tokenID, ifMatch, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('deleteToken', 'psID', psID);
                            // verify required parameter 'tokenID' is not null or undefined
                            (0, common_1.assertParamExists)('deleteToken', 'tokenID', tokenID);
                            localVarPath = "/admin/systems/{psID}/tokens/{tokenID}"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)))
                                .replace("{".concat("tokenID", "}"), encodeURIComponent(String(tokenID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication admin required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "admin", [], configuration)];
                        case 1:
                            // authentication admin required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Gets permission system token.
         * @summary Get permission system token
         * @param {string} psID
         * @param {string} tokenID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: function (psID_1, tokenID_1, ifMatch_1) {
            var args_1 = [];
            for (var _i = 3; _i < arguments.length; _i++) {
                args_1[_i - 3] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, tokenID_1, ifMatch_1], args_1, true), void 0, function (psID, tokenID, ifMatch, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('getToken', 'psID', psID);
                            // verify required parameter 'tokenID' is not null or undefined
                            (0, common_1.assertParamExists)('getToken', 'tokenID', tokenID);
                            localVarPath = "/admin/systems/{psID}/tokens/{tokenID}"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)))
                                .replace("{".concat("tokenID", "}"), encodeURIComponent(String(tokenID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication admin required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "admin", [], configuration)];
                        case 1:
                            // authentication admin required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Lists permission system tokens.
         * @summary List permission system tokens
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTokens: function (psID_1, ifMatch_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, ifMatch_1], args_1, true), void 0, function (psID, ifMatch, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('listTokens', 'psID', psID);
                            localVarPath = "/admin/systems/{psID}/tokens"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication admin required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "admin", [], configuration)];
                        case 1:
                            // authentication admin required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         * Updates permission system token.
         * @summary Update permission system token
         * @param {string} psID
         * @param {string} tokenID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateToken: function (psID_1, tokenID_1, ifMatch_1, token_1) {
            var args_1 = [];
            for (var _i = 4; _i < arguments.length; _i++) {
                args_1[_i - 4] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([psID_1, tokenID_1, ifMatch_1, token_1], args_1, true), void 0, function (psID, tokenID, ifMatch, token, options) {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                if (options === void 0) { options = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'psID' is not null or undefined
                            (0, common_1.assertParamExists)('updateToken', 'psID', psID);
                            // verify required parameter 'tokenID' is not null or undefined
                            (0, common_1.assertParamExists)('updateToken', 'tokenID', tokenID);
                            localVarPath = "/admin/systems/{psID}/tokens/{tokenID}"
                                .replace("{".concat("psID", "}"), encodeURIComponent(String(psID)))
                                .replace("{".concat("tokenID", "}"), encodeURIComponent(String(tokenID)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication admin required
                            // oauth required
                            return [4 /*yield*/, (0, common_1.setOAuthToObject)(localVarHeaderParameter, "admin", [], configuration)];
                        case 1:
                            // authentication admin required
                            // oauth required
                            _a.sent();
                            if (ifMatch !== undefined && ifMatch !== null) {
                                localVarHeaderParameter['if-match'] = String(ifMatch);
                            }
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(token, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
    };
};
exports.TokensApiAxiosParamCreator = TokensApiAxiosParamCreator;
/**
 * TokensApi - functional programming interface
 * @export
 */
var TokensApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.TokensApiAxiosParamCreator)(configuration);
    return {
        /**
         * Creates a permission system token.
         * @summary Create permission system token
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: function (psID, ifMatch, token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createToken(psID, ifMatch, token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Deletes permission system token.
         * @summary Delete permission system token
         * @param {string} psID
         * @param {string} tokenID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken: function (psID, tokenID, ifMatch, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteToken(psID, tokenID, ifMatch, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Gets permission system token.
         * @summary Get permission system token
         * @param {string} psID
         * @param {string} tokenID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: function (psID, tokenID, ifMatch, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getToken(psID, tokenID, ifMatch, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Lists permission system tokens.
         * @summary List permission system tokens
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTokens: function (psID, ifMatch, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.listTokens(psID, ifMatch, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Updates permission system token.
         * @summary Update permission system token
         * @param {string} psID
         * @param {string} tokenID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateToken: function (psID, tokenID, ifMatch, token, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateToken(psID, tokenID, ifMatch, token, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.TokensApiFp = TokensApiFp;
/**
 * TokensApi - factory interface
 * @export
 */
var TokensApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.TokensApiFp)(configuration);
    return {
        /**
         * Creates a permission system token.
         * @summary Create permission system token
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createToken: function (psID, ifMatch, token, options) {
            return localVarFp.createToken(psID, ifMatch, token, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Deletes permission system token.
         * @summary Delete permission system token
         * @param {string} psID
         * @param {string} tokenID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteToken: function (psID, tokenID, ifMatch, options) {
            return localVarFp.deleteToken(psID, tokenID, ifMatch, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Gets permission system token.
         * @summary Get permission system token
         * @param {string} psID
         * @param {string} tokenID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: function (psID, tokenID, ifMatch, options) {
            return localVarFp.getToken(psID, tokenID, ifMatch, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Lists permission system tokens.
         * @summary List permission system tokens
         * @param {string} psID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTokens: function (psID, ifMatch, options) {
            return localVarFp.listTokens(psID, ifMatch, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Updates permission system token.
         * @summary Update permission system token
         * @param {string} psID
         * @param {string} tokenID
         * @param {string} [ifMatch] A server will only return requested resources if the resource matches one of the listed ETag value
         * @param {Token} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateToken: function (psID, tokenID, ifMatch, token, options) {
            return localVarFp.updateToken(psID, tokenID, ifMatch, token, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.TokensApiFactory = TokensApiFactory;
/**
 * TokensApi - object-oriented interface
 * @export
 * @class TokensApi
 * @extends {BaseAPI}
 */
var TokensApi = /** @class */ (function (_super) {
    __extends(TokensApi, _super);
    function TokensApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Creates a permission system token.
     * @summary Create permission system token
     * @param {TokensApiCreateTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    TokensApi.prototype.createToken = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TokensApiFp)(this.configuration).createToken(requestParameters.psID, requestParameters.ifMatch, requestParameters.token, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Deletes permission system token.
     * @summary Delete permission system token
     * @param {TokensApiDeleteTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    TokensApi.prototype.deleteToken = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TokensApiFp)(this.configuration).deleteToken(requestParameters.psID, requestParameters.tokenID, requestParameters.ifMatch, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Gets permission system token.
     * @summary Get permission system token
     * @param {TokensApiGetTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    TokensApi.prototype.getToken = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TokensApiFp)(this.configuration).getToken(requestParameters.psID, requestParameters.tokenID, requestParameters.ifMatch, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Lists permission system tokens.
     * @summary List permission system tokens
     * @param {TokensApiListTokensRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    TokensApi.prototype.listTokens = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TokensApiFp)(this.configuration).listTokens(requestParameters.psID, requestParameters.ifMatch, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Updates permission system token.
     * @summary Update permission system token
     * @param {TokensApiUpdateTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokensApi
     */
    TokensApi.prototype.updateToken = function (requestParameters, options) {
        var _this = this;
        return (0, exports.TokensApiFp)(this.configuration).updateToken(requestParameters.psID, requestParameters.tokenID, requestParameters.ifMatch, requestParameters.token, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return TokensApi;
}(base_1.BaseAPI));
exports.TokensApi = TokensApi;
