import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";

import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Plus } from "react-feather";

import { OrganizationResponse, ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import Progress from "../../../common/components/Progress";
import OrganizationsIcon from "../../../common/components/icons/OrganizationsIcon";
import { getFromLocalStorage } from "../../../common/utils/localStorage.utils";
import {
  useListOrganizations,
  useListUserOrganizations,
} from "../../services/adminOrganizationsQuery";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import WorkspaceAvatar from "../nav/WorkspaceAvatar";
import {
  recentlyUsedOrganizationsKey,
  useWorkspaceSelectorStyles,
  WorkspaceItem,
} from "./WorkspaceSelector";

interface Props {
  organization?: OrganizationResponse;
  onCreateClick: () => void;
}

export default function OrganizationSelector({ organization, onCreateClick }: Props) {
  const { cx, classes } = useWorkspaceSelectorStyles();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations = !!checkTenantPermissionsQuery.data?.list_organizations;

  const organizationsQuery = useListOrganizations({}, { enabled: canListOrganizations });
  const userOrganizationsQuery = useListUserOrganizations({}, { enabled: !canListOrganizations });

  const organizations = canListOrganizations
    ? organizationsQuery.data?.organizations ?? []
    : userOrganizationsQuery.data?.organizations ?? [];

  let recentOrganizations: string[] = [];
  try {
    const recentOrganizationsString = getFromLocalStorage(recentlyUsedOrganizationsKey);
    recentOrganizations = recentOrganizationsString ? JSON.parse(recentOrganizationsString) : [];
  } catch (e) {
    //
  }

  const organizationsToRender = recentOrganizations
    .map(wid => organizations.find(server => server.id === wid))
    .filter(Boolean);

  const isAllowedToSwitchOrganization =
    organizations.length > 1 || checkTenantPermissionsQuery.data?.create_organization;

  const progress = organizationsQuery.isFetching;

  return (
    <div id="workspace-selector" className={classes.root}>
      <div
        id="workspace-selector-current-item"
        className={cx(classes.select, isAllowedToSwitchOrganization && classes.selectable)}
        onClick={e => isAllowedToSwitchOrganization && setAnchorEl(e.currentTarget)}
      >
        {progress ? (
          <Progress size={20} top="calc(50% - 10px)" style={{ left: "calc(50% - 10px)" }} />
        ) : (
          <>
            <WorkspaceAvatar
              server={{ ...organization, type: ServerResponseTypeEnum.Organization }}
              size="small"
            />
            <div className={classes.currentWorkspace}>
              <Typography variant="textXSM" className={classes.currentWorkspaceCaption}>
                ORGANIZATION
              </Typography>
              <Typography
                variant="body2"
                style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                id="workspace-selector-current-item-name"
              >
                {organization?.name ?? ""}
              </Typography>
            </div>
            <div style={{ flex: 1 }} />
            {isAllowedToSwitchOrganization ? (
              Boolean(anchorEl) ? (
                <ArrowUpIcon />
              ) : (
                <ArrowDownIcon />
              )
            ) : null}
          </>
        )}
      </div>
      <Menu
        id="workspace-selector-select"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        classes={{ list: classes.list, paper: classes.menuPaper }}
      >
        <Typography className={classes.menuRecentlyUsed}>Recent Organizations</Typography>
        {organizationsToRender.map((w, index) =>
          w ? (
            <WorkspaceItem
              key={w.id}
              server={w}
              avatar={
                <WorkspaceAvatar
                  server={{ ...w, type: ServerResponseTypeEnum.Organization }}
                  size="small"
                />
              }
              index={index}
              selected={w.id === id}
              classes={classes}
              cx={cx}
              onClick={() => {
                setAnchorEl(null);
                navigate(`/organizations/${w.id}`);
              }}
            />
          ) : null
        )}

        {isAllowedToSwitchOrganization && <Divider style={{ margin: 0 }} />}

        {isAllowedToSwitchOrganization && (
          <MenuItem
            classes={{ root: classes.menuItem }}
            className={classes.menuItemBottom}
            onClick={() => {
              navigate("/organizations");
            }}
          >
            <div style={{ marginRight: 14, width: 24, height: 24 }}>
              <OrganizationsIcon />
            </div>

            <Typography id="workspace-settings" variant="body2">
              View all organizations
            </Typography>
          </MenuItem>
        )}

        {checkTenantPermissionsQuery.data?.create_organization && (
          <MenuItem
            classes={{ root: classes.menuItem }}
            className={classes.menuItemBottom}
            onClick={() => {
              setAnchorEl(null);
              onCreateClick();
            }}
          >
            <Plus className={classes.avatar} style={{ marginRight: 14, width: 24, height: 24 }} />
            <Typography id="workspace-create-new" variant="body2">
              Create organization
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
