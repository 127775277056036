import React from "react";

import { PoolResponse } from "@cloudentity/acp-identity";

import { useCheckPoolPermissions } from "../../../admin/services/adminIdentityPoolsQuery";
import Menu from "../../../common/components/Menu";

interface Props {
  pool: PoolResponse;
  anchorEl: () => HTMLElement;
  handleEdit: (pool: PoolResponse) => void;
  handleDelete?: (pool: PoolResponse) => void;
  handleClose: () => void;
  onSelect: () => void;
}

export default function PoolMenu({
  pool,
  anchorEl,
  handleEdit,
  handleDelete,
  handleClose,
  onSelect,
}: Props) {
  const { id = "" } = pool;

  const checkPoolPermissionsQuery = useCheckPoolPermissions(id);

  return (
    <>
      <Menu
        id={`${id}-card-main-menu`}
        anchorEl={anchorEl}
        onClose={handleClose}
        items={[
          {
            onClick: onSelect,
            label: "Open",
            id: "open",
          },
          ...(checkPoolPermissionsQuery.data?.update_identity_pool
            ? [
                {
                  onClick: () => {
                    handleEdit(pool);
                    handleClose();
                  },
                  label: "Edit",
                  id: "edit",
                },
              ]
            : []),
          ...(checkPoolPermissionsQuery.data?.delete_identity_pool && handleDelete
            ? [
                {
                  onClick: () => {
                    handleDelete(pool);
                    handleClose();
                  },
                  label: "Delete",
                  id: "delete",
                },
              ]
            : []),
        ]}
      />
    </>
  );
}
