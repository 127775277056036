import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { getTenantId } from "../../../../common/api/paths";
import Breadcrumbs from "../../../../common/components/Breadcrumbs";
import ConfirmationDialog from "../../../../common/components/ConfirmationDialog";
import RouteLeavingGuardSimple from "../../../../common/components/RouteLeavingGuardSimple";
import Tabs from "../../../../common/components/Tabs";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import adminTemplatesApi from "../../../services/adminTemplatesApi";
import {
  getTemplateQueryKey,
  useGetDefaultTemplate,
  useGetTemplate,
  useListDefaultTemplates,
} from "../../../services/adminTemplatesQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import { useGetTheme } from "../../../services/adminThemesQuery";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";
import FileBrowser from "./FileBrowser";
import MessagesList from "./MessagesList";
import PagesList, { pathToUnfoldedPath } from "./PagesList";
import ThemeTemplateEditor from "./ThemeTemplateEditor";
import ThemeTemplatePreview from "./ThemeTemplatePreview";

const useStyles = makeStyles()(theme => ({
  tabsContent: {
    height: "calc(100vh - 282px)",
    overflow: "auto",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  previewToolbar: {
    height: 49,
    display: "flex",
    alignItems: "center",
    paddingLeft: 8,
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  previewToolbarLabel: {
    color: theme.palette.secondary.light,
    fontWeight: 600,
  },
}));

export default function ThemeDetails() {
  const { id: themeId = "" } = useParams<{ id: string }>();

  const { classes } = useStyles();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [tab, setTab] = useState("pages");

  const [selectedPath, setSelectedPath] = useState("");
  const [previewPath, setPreviewPath] = useState("");
  const [templateContent, setTemplateContent] = useState("");
  const [value, setValue] = useState("");
  const [saveProgress, setSaveProgress] = useState(false);
  const [error, setError] = useState("");
  const [unfolded, setUnfolded] = useState<string[]>([]);
  const [unsavedChangesWarning, setUnsavedChangesWarning] = useState<{
    newPath: string;
    callback: (path: string, confirmed?: boolean) => void;
  } | null>(null);
  const queryClient = useQueryClient();
  const themeQuery = useGetTheme(getTenantId(), themeId, { enabled: themeId !== "default" });
  const listDefaultTemplatesQuery = useListDefaultTemplates(getTenantId());
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const template = useGetTemplate(getTenantId(), themeId, selectedPath, {
    enabled: !!selectedPath,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const defaultTemplate = useGetDefaultTemplate(getTenantId(), selectedPath, {
    enabled: !!selectedPath,
  });

  const fileNotModified = !value || !selectedPath || value === templateContent;

  const handleSave = () => {
    setError("");
    setSaveProgress(true);
    adminTemplatesApi
      .upsertTemplate({
        themeID: themeId,
        fsPath: selectedPath,
        template: {
          ...(template.data ? template.data : defaultTemplate.data),
          theme_id: themeId,
          tenant_id: getTenantId(),
          fs_path: selectedPath,
          content: value,
        },
      })
      .then(() => {
        iframeRef?.current?.contentWindow?.location.reload();
        notifySuccess("Theme saved successfully");
        return queryClient.resetQueries({
          queryKey: getTemplateQueryKey(getTenantId(), themeId, selectedPath),
        });
      })
      .catch(err => {
        if (err.response?.data?.error) {
          return setError(err.response?.data?.error);
        }
        return notifyErrorOrDefaultTo("Error occurred while trying to save template");
      })
      .finally(() => setSaveProgress(false));
  };

  useEffect(() => {
    const content = template.data?.content ?? defaultTemplate.data?.content ?? "";
    setValue(content);
    setTemplateContent(content);
  }, [template.data, defaultTemplate.data]);

  const handleComponentItemClick = useCallback(
    (path: string, confirmed?: boolean) => {
      if (fileNotModified || confirmed) {
        setError("");
        setSelectedPath(path);
        setPreviewPath("");
      } else {
        setUnsavedChangesWarning({ newPath: path, callback: handleComponentItemClick });
      }
    },
    [fileNotModified]
  );

  const handleOnEdit = useCallback(
    (path: string, confirmed?: boolean) => {
      if (fileNotModified || confirmed) {
        setSelectedPath(path);
        const fileName = path.split("/").at(-1);
        if (fileName?.startsWith("__") || fileName?.startsWith("index.tmpl") || !previewPath) {
          setPreviewPath(path.replace("pages/", ""));
        }
      } else {
        setUnsavedChangesWarning({ newPath: path, callback: handleOnEdit });
      }
    },
    [fileNotModified, previewPath]
  );

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, tab: any, confirmed?: boolean) => {
      if (fileNotModified || confirmed) {
        setTab(tab);
        setPreviewPath("");
        setSelectedPath("");
        setUnfolded([]);
      } else {
        setUnsavedChangesWarning({
          newPath: "",
          callback: () => {
            setValue(templateContent);
            handleTabChange(_, tab, true);
          },
        });
      }
    },
    [fileNotModified, templateContent]
  );

  const handleCategoryClick = (path: string[], confirmed?: boolean) => {
    if (fileNotModified || confirmed) {
      if ((unfolded.length === 1 && unfolded[0] === path[0]) || unfolded[0] === path[0]) {
        setUnfolded([]);
      } else {
        setUnfolded(path);
      }
      setPreviewPath("");
      setSelectedPath("");
    } else {
      setUnsavedChangesWarning({
        newPath: "",
        callback: () => {
          setValue(templateContent);
          handleCategoryClick(path, true);
        },
      });
    }
  };

  const handleGroupClick = (path: string, confirmed?: boolean) => {
    if (fileNotModified || confirmed) {
      if (unfolded.length === 2 && unfolded[1] === path) {
        const s = path.split("/");
        setUnfolded([s.slice(0, 2).join("/") + "/"]);
        setPreviewPath("");
        setSelectedPath("");
      } else {
        setUnfolded(pathToUnfoldedPath(path));
        setPreviewPath(path.replace("pages/", "") + "index.tmpl");
        setSelectedPath(path + "index.tmpl");
      }
    } else {
      setUnsavedChangesWarning({
        newPath: "",
        callback: () => {
          setValue(templateContent);
          handleGroupClick(path, true);
        },
      });
    }
  };

  useEffect(() => {
    setUnfolded(["pages/authorization/", "pages/authorization/consent/"]);
    setSelectedPath("pages/authorization/consent/index.tmpl");
    setPreviewPath("authorization/consent/index.tmpl");
  }, [listDefaultTemplatesQuery.data?.fs_paths]);

  return (
    <>
      <PageHeader
        title={themeQuery.data?.name || "Default"}
        breadcrumb={<Breadcrumb label="Theme Editor" />}
        maxWidth={false}
      />
      <PageContent>
        <Paper style={{ width: "100%" }}>
          <Grid container style={{ width: "100%" }}>
            <Grid item xs={2} style={{ width: "100%" }}>
              <Tabs
                value={tab}
                style={{ marginBottom: 0, borderRight: "1px solid #e8e8e8" }}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons
              >
                <Tab label="Pages" value="pages" />
                <Tab label="Messages" value="messages" />
                <Tab label="Components" value="components" />
              </Tabs>
              <div className={classes.tabsContent}>
                {tab === "pages" && (
                  <PagesList
                    paths={(listDefaultTemplatesQuery.data?.fs_paths || []).filter(path =>
                      path.startsWith("pages/")
                    )}
                    selectedPath={selectedPath}
                    unfolded={unfolded}
                    previewPath={previewPath}
                    onEdit={handleOnEdit}
                    hasUnsavedChanges={!fileNotModified}
                    onCategoryClick={handleCategoryClick}
                    onGroupClick={handleGroupClick}
                  />
                )}
                {tab === "messages" && (
                  <MessagesList
                    paths={(listDefaultTemplatesQuery.data?.fs_paths || []).filter(path =>
                      path.startsWith("pages/")
                    )}
                    selectedPath={selectedPath}
                    unfolded={unfolded}
                    previewPath={previewPath}
                    onEdit={handleOnEdit}
                    hasUnsavedChanges={!fileNotModified}
                    onCategoryClick={handleCategoryClick}
                    onGroupClick={handleGroupClick}
                  />
                )}
                {tab === "components" && (
                  <FileBrowser
                    selected={selectedPath}
                    hasUnsavedChanges={!fileNotModified}
                    paths={(listDefaultTemplatesQuery.data?.fs_paths || []).filter(
                      path => !path.startsWith("pages/")
                    )}
                    onItemClick={handleComponentItemClick}
                  />
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={selectedPath ? (previewPath ? 5 : 7) : 10}
              style={{ position: "relative", height: "100%" }}
            >
              <ThemeTemplateEditor
                value={value}
                onValueChange={v => {
                  setError("");
                  setValue(v || "");
                }}
                error={error}
                readOnly={!checkTenantPermissionsQuery.data?.manage_themes}
                onResetError={() => setError("")}
                selectedPath={selectedPath}
                onSaveClick={handleSave}
                disableSave={fileNotModified}
                saveProgress={saveProgress}
              />
            </Grid>
            {selectedPath && (
              <Grid item xs={previewPath ? 5 : 3}>
                <>
                  <div className={classes.previewToolbar}>
                    <Typography className={classes.previewToolbarLabel}>Preview</Typography>
                  </div>
                  <ThemeTemplatePreview
                    themeId={themeId}
                    iframeRef={iframeRef}
                    previewPath={previewPath}
                    selectedPath={selectedPath}
                  />
                </>
              </Grid>
            )}
          </Grid>
        </Paper>
      </PageContent>
      <RouteLeavingGuardSimple when={!fileNotModified} />
      {unsavedChangesWarning && (
        <ConfirmationDialog
          title="Unsaved Changes"
          content="You have unsaved changes. Leave without saving?"
          cancelText="Dismiss"
          confirmText="Leave"
          onCancel={() => {
            setUnsavedChangesWarning(null);
          }}
          onConfirm={() => {
            unsavedChangesWarning.callback(unsavedChangesWarning.newPath, true);
            setUnsavedChangesWarning(null);
          }}
        />
      )}
    </>
  );
}

const Breadcrumb = ({ label }: { label: string }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumbs
      items={[
        {
          key: "list",
          label: "Themes",
          onClick: () => navigate(`/appearance/themes`),
        },
        { key: "detail", label },
      ]}
    />
  );
};
