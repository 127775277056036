import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";

import { BaseUserWithData, UserWithData } from "@cloudentity/acp-identity";

import ChangeAdminRoleDialog from "./ChangeAdminRoleDialog";

interface Props {
  user?: UserWithData | BaseUserWithData;
  workspace: string;
}

export default function ChangeAdminRole({ user, workspace }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MenuItem id="change-workspace-role-item" onClick={() => setIsOpen(true)}>
        Change role
      </MenuItem>

      {isOpen && (
        <ChangeAdminRoleDialog
          user={user}
          workspace={workspace}
          onSuccess={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}
        />
      )}
    </>
  );
}
