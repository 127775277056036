import React, { useEffect, useRef, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import {
  AccessorFnColumnDef,
  ColumnOrderState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronLeft, ChevronRight, MoreVertical } from "react-feather";
import { makeStyles } from "tss-react/mui";

import tableIcon from "../../../assets/images/icons/table-icon.svg";
import Select from "../Select";
import AdvancedTableColumnFilter from "./AdvancedTableColumnFilter";
import { AdvancedTableProps, ConditionalProps, Id } from "./types";

const useStyles = makeStyles<void, "resizer" | "defaultColumnTd">()((theme, __, classes) => ({
  paper: {
    overflowX: "auto",
    position: "relative",
  },
  defaultColumnTd: {
    padding: "8px 24px",
    height: 64,
    fontSize: 12,
    whiteSpace: "nowrap",

    "&:not(:first-of-type):not(:last-of-type)": {
      borderLeft: `solid 1px #f5eeee`,
    },
  },
  actionColumnTd: {
    padding: 0,
    backgroundColor: "white",
  },
  table: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    [`& *:hover > .${classes.resizer}`]: {
      opacity: 1,
    },
  },
  defaultColumnTh: {
    position: "relative",
    textAlign: "left",
    borderBottom: `solid 1px #E0E0E0`,
    height: 30,
    color: "#7A7A7A",
    fontWeight: 500,
    fontSize: 12,
    whiteSpace: "nowrap",

    "&:not(:last-of-type):not(:nth-last-of-type(2))": {
      borderRight: `solid 1px #f5eeee`,
    },
  },
  actionColumnTh: {
    padding: 0,
    backgroundColor: "white",
  },
  lastColumnDiv: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-end",
    ":before": {
      content: '""',
      boxShadow: "inset -10px 0 8px -8px rgb(5, 5, 5, 0.06)",
      position: "absolute",
      top: 0,
      bottom: -1,
      left: 0,
      width: 30,
      transform: "translateX(-100%)",
      pointerEvents: "none",
    },
  },
  tr: {
    width: "100%",
    padding: "14px 24px",
    borderBottom: `solid 1px #E0E0E0`,
    position: "relative",
  },
  trClickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#faf9f9",
    },
  },
  thContent: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    "& > span": {
      flex: 1,
      padding: "6px 24px",
    },
  },
  resizer: {
    height: "100%",
    width: 5,
    background: "lightgray",
    cursor: "col-resize",
    userSelect: "none",
    touchAction: "none",
    opacity: 0,
  },
  isResizing: {
    background: theme.palette.primary.main,
    opacity: 1,
  },
  footer: {
    margin: "8px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "sticky",
    left: 0,
  },
  footerButtons: {
    marginLeft: 48,
  },
  empty: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 32,
    paddingBottom: 32,
    borderBottom: "solid 1px #E0E0E0",
    color: "gray",
    height: 72,
  },
  sortIndicator: {
    fontSize: 16,
    position: "absolute",
    bottom: 14,
    marginLeft: 8,
  },
}));

export default function ServerSideAdvancedTable<Type extends Id>({
  tableId,
  initialActiveColumns,
  columns,
  activeColumns,
  setActiveColumns,
  disabledRow,
  onRowClick,
  onMoreIconClick,
  data,
  rowProgress,
}: AdvancedTableProps<Type> & ConditionalProps) {
  const { cx, classes } = useStyles();

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [paperWidth, setPaperWidth] = useState(0);
  const paperWidthDiff = useRef(0);

  const table = useReactTable({
    data: data.data ?? [],
    columns: [
      ...activeColumns
        .map(activeColumn => columns.find(column => column.id === activeColumn))
        .filter((column): column is AccessorFnColumnDef<Type, any> => !!column)
        .map(column => ({
          ...column,
          enableSorting: (data.isClientSide && column.enableSorting) || false,
        })),
      { id: "action-column", enableResizing: false, size: 56 },
    ],
    manualPagination: true,
    state: {
      columnVisibility,
      columnOrder,
      sorting: data.isClientSide ? sorting : undefined,
    },
    columnResizeMode: "onChange",
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const items = table.getRowModel().rows;
  const containerNode = useRef<HTMLDivElement>(null);

  function getHeaderSizes() {
    const allHeaders = table.getHeaderGroups().flatMap(headerGroup => headerGroup.headers);
    const resizableHeaders = allHeaders.filter(header => header.column.getCanResize());

    const headersSizes = allHeaders.reduce(
      (acc, header) => ({ ...acc, [header.id]: header.getSize() }),
      {} as Record<string, number>
    );

    const totalBorderWidth = 2;
    const paperWidthContent = paperWidth - totalBorderWidth;
    const headersTotalSize = table.getTotalSize();

    if (
      (headersTotalSize < paperWidthContent && !!resizableHeaders.length) ||
      paperWidthDiff.current
    ) {
      const lastResizableHeader = resizableHeaders[resizableHeaders.length - 1];

      const resizableHeadersWithoutLastTotalSize = resizableHeaders
        .filter(header => header.id !== lastResizableHeader.id)
        .reduce((acc, header) => acc + headersSizes[header.id], 0);

      const nonResizableHeadersTotalSize = allHeaders
        .filter(header => !header.column.getCanResize())
        .reduce((acc, header) => acc + headersSizes[header.id], 0);

      headersSizes[lastResizableHeader.id] =
        Math.max(
          paperWidthContent - resizableHeadersWithoutLastTotalSize - nonResizableHeadersTotalSize,
          headersSizes[lastResizableHeader.id]
        ) + paperWidthDiff.current;

      paperWidthDiff.current = 0;

      return { headersSizes, needUpdate: true };
    }

    return { headersSizes, needUpdate: false };
  }

  const { headersSizes, needUpdate } = getHeaderSizes();
  const headersTotalWidth = Object.values(headersSizes).reduce((acc, size) => acc + size, 0);

  useEffect(() => {
    if (needUpdate) {
      table.setColumnSizing(sizing => ({ ...sizing, ...headersSizes }));
    }
  }, [table, headersSizes, needUpdate]);

  useEffect(() => {
    setPaperWidth(containerNode.current?.getBoundingClientRect().width ?? 0);

    function onResize() {
      const newPaperWidth = containerNode.current?.getBoundingClientRect().width ?? 0;
      paperWidthDiff.current = Math.min(0, newPaperWidth - paperWidth);
      setPaperWidth(newPaperWidth);
    }

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [paperWidth, table]);

  useEffect(() => {
    data.onSetSort(sorting[0]?.id);
    data.onSetOrder(sorting[0]?.desc ? "desc" : "asc");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  return (
    <div ref={containerNode} style={{ marginBottom: 32 }}>
      <Paper className={classes.paper} style={{ width: paperWidth }}>
        <table id={tableId} className={classes.table} style={{ width: headersTotalWidth }}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className={classes.tr}>
                {headerGroup.headers.map((header, headerIndex, headers) => {
                  const isActionColumn = headerIndex === headers.length - 1;
                  return (
                    <th
                      key={header.id}
                      className={cx(
                        classes.defaultColumnTh,
                        isActionColumn && classes.actionColumnTh
                      )}
                      style={{
                        width: headersSizes[header.id] ?? header.getSize(),
                        ...(isActionColumn && { position: "sticky", right: 0 }),
                      }}
                    >
                      {isActionColumn ? (
                        <div className={classes.lastColumnDiv}>
                          {setActiveColumns && (
                            <IconButton
                              onClick={e => setAnchorEl(e.currentTarget)}
                              id="advanced-table-settings-open-button"
                              aria-label="open table settings button"
                            >
                              <img
                                src={tableIcon}
                                alt="table column filters"
                                width="21"
                                height="21"
                              />
                            </IconButton>
                          )}
                        </div>
                      ) : (
                        <div
                          className={classes.thContent}
                          style={
                            header.column.getCanSort()
                              ? { cursor: "pointer", userSelect: "none" }
                              : {}
                          }
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <span>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getIsSorted() === "asc" && (
                              <span className={classes.sortIndicator}> ↑</span>
                            )}
                            {header.column.getIsSorted() === "desc" && (
                              <span className={classes.sortIndicator}> ↓</span>
                            )}
                          </span>
                          {header.column.getCanResize() && (
                            <div
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              className={cx(
                                classes.resizer,
                                header.column.getIsResizing() && classes.isResizing
                              )}
                            />
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {activeColumns.length === 0 || items.length === 0 || data.isLoading
              ? null
              : items.map(row => {
                  const rowOriginal = row.original;
                  const isRowDisabled = disabledRow && disabledRow(rowOriginal);
                  const isRowHighlighted = !!data.highlighedUsers?.find(
                    v => v.id === rowOriginal.id
                  );
                  const isRowProgress = row.original.id === rowProgress;

                  return (
                    <tr
                      id={`${tableId}-row-${rowOriginal.id}`}
                      key={row.id}
                      className={cx(
                        classes.tr,
                        !isRowDisabled && onRowClick && classes.trClickable
                      )}
                      onClick={e => !isRowDisabled && onRowClick && onRowClick(e, rowOriginal.id)}
                      style={isRowHighlighted ? { background: "#F7FAFF" } : {}}
                    >
                      {row.getVisibleCells().map((cell, cellIndex, cells) => {
                        const isActionColumn = cellIndex === cells.length - 1;
                        return (
                          <td
                            key={cell.id}
                            className={cx(
                              classes.defaultColumnTd,
                              isActionColumn && classes.actionColumnTd
                            )}
                            style={{
                              ...(isActionColumn && { position: "sticky", right: 0, padding: 0 }),
                            }}
                          >
                            {cellIndex === 0 && isRowProgress && (
                              <LinearProgress
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  left: 0,
                                  top: 0,
                                  zIndex: 1,
                                }}
                              />
                            )}
                            {isActionColumn ? (
                              <div className={classes.lastColumnDiv}>
                                {onMoreIconClick && (
                                  <IconButton
                                    disabled={isRowDisabled}
                                    onClick={e => {
                                      e.stopPropagation();
                                      onMoreIconClick(e, rowOriginal);
                                    }}
                                  >
                                    <MoreVertical size={20} />
                                  </IconButton>
                                )}
                              </div>
                            ) : (
                              flexRender(cell.column.columnDef.cell, cell.getContext())
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
          </tbody>
        </table>

        {(activeColumns.length === 0 || items.length === 0 || data.isLoading) && (
          <div className={classes.empty} id="table-row-empty">
            {(data.isLoading && <CircularProgress />) ||
              (items.length === 0 && "No data") ||
              (activeColumns.length === 0 && "No columns selected")}
          </div>
        )}

        <div className={classes.footer}>
          <div>Rows per page:</div>
          <Select
            id="select-input-type"
            size="small"
            value={data.params.limit}
            onChange={e => {
              data.onSetLimit(Number(e.target.value));
            }}
            items={[
              { label: "5", value: 5 },
              { label: "10", value: 10 },
              { label: "25", value: 25 },
              { label: "50", value: 50 },
              { label: "75", value: 75 },
            ]}
            minWidth={50}
            smaller
            style={{ marginLeft: 16 }}
          />

          <div className={classes.footerButtons}>
            <IconButton
              onClick={() => {
                data.onBack();
              }}
              disabled={!data.hasPrevious}
            >
              <ChevronLeft size={20} />
            </IconButton>

            <IconButton
              onClick={() => {
                data.onNext();
              }}
              disabled={!data.hasNext}
            >
              <ChevronRight size={20} />
            </IconButton>
          </div>
        </div>
      </Paper>

      {setActiveColumns && (
        <AdvancedTableColumnFilter
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          initialActiveColumns={initialActiveColumns || []}
          columns={columns}
          activeColumns={activeColumns}
          setActiveColumns={setActiveColumns}
        />
      )}
    </div>
  );
}
