import {
  PermissionsApi,
  PermissionsApiCheckPoolPermissionsRequest,
  PoolsApi,
  PoolsApiCreatePoolRequest,
  PoolsApiCreateWorkspacePoolRequest,
  PoolsApiDeletePoolRequest,
  PoolsApiDeleteWorkspacePoolRequest,
  PoolsApiGetPoolRequest,
  PoolsApiListPoolsRequest,
  PoolsApiListUserWorkspacePoolsRequest,
  PoolsApiListWorkspacePoolsRequest,
  PoolsApiUpdatePoolRequest,
} from "@cloudentity/acp-identity";

import { identityApiClazz } from "./admin-services.common";

const api: () => PoolsApi = identityApiClazz(PoolsApi);
const apiPermissions: () => PermissionsApi = identityApiClazz(PermissionsApi);

const identityPoolsApi = {
  listPools: (req: PoolsApiListPoolsRequest) => api().listPools(req),
  listWorkspacePools: (req: PoolsApiListWorkspacePoolsRequest) => api().listWorkspacePools(req),
  listUserWorkspacePools: (req: PoolsApiListUserWorkspacePoolsRequest) =>
    api().listUserWorkspacePools(req),
  createPool: (req: PoolsApiCreatePoolRequest) => api().createPool(req),
  getPool: (req: PoolsApiGetPoolRequest) => api().getPool(req),
  updatePool: (req: PoolsApiUpdatePoolRequest) => api().updatePool(req),
  deletePool: (req: PoolsApiDeletePoolRequest) => api().deletePool(req),
  createWorkspacePool: (req: PoolsApiCreateWorkspacePoolRequest) => api().createWorkspacePool(req),
  deleteWorkspacePool: (req: PoolsApiDeleteWorkspacePoolRequest) => api().deleteWorkspacePool(req),
  checkPoolPermissions: (req: PermissionsApiCheckPoolPermissionsRequest) =>
    apiPermissions().checkPoolPermissions(req),
};

export default identityPoolsApi;
