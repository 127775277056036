export const activityToRowMap = {
  server: {
    created: {
      name: a => a.payload.primary_name,
      description: a => "Workspace added",
    },
    updated: {
      name: a => a.payload.primary_name,
      description: a => "Workspace updated",
    },
    deleted: {
      name: a => a.payload.primary_name,
      description: a => "Workspace removed",
    },
    linked: {
      name: a => a.payload.primary_name,
      description: a => `Portal connected to ${a.payload.secondary_name}`,
    },
    policies_created: {
      link: (workspaceId, a) => `/${workspaceId}/policies`,
      name: a => `${a.payload.count} Policies`,
      description: a => "created",
    },
    policies_removed: {
      link: (workspaceId, a) => `/${workspaceId}/policies`,
      name: a => `${a.payload.count} Policies`,
      description: a => "removed",
    },
  },
  service: {
    created: {
      link: (workspaceId, a) => `/${workspaceId}/services/${a.payload.subject_id}/overview`,
      name: a => a.payload.primary_name,
      description: a => "Service added",
    },
    updated: {
      link: (workspaceId, a) => `/${workspaceId}/services/${a.payload.subject_id}/overview`,
      name: a => a.payload.primary_name,
      description: a => "Service updated",
    },
    deleted: {
      name: a => a.payload.primary_name,
      description: a => "Service removed",
    },
    apis_created: {
      link: (workspaceId, a) => `/${workspaceId}/apis/authorization`,
      name: a => `${a.payload.count} APIs`,
      description: a =>
        `added to ${a.payload.primary_name ? a.payload.primary_name + " " : " "}service`,
    },
    apis_removed: {
      link: (workspaceId, a) => `/${workspaceId}/apis/authorization`,
      name: a => `${a.payload.count} APIs`,
      description: a =>
        `removed from ${a.payload.primary_name ? a.payload.primary_name + " " : " "}service`,
    },
    scopes_created: {
      link: (workspaceId, a) => `/${workspaceId}/services/${a.payload.subject_id}/scopes`,
      name: a => `${a.payload.count} Scopes`,
      description: a => `added to ${a.payload.primary_name} service`,
    },
  },
  idp: {
    created: {
      link: (workspaceId, a) => `/${workspaceId}/identities`,
      name: a => a.payload.primary_name,
      description: a => "Identity provider added",
    },
    updated: {
      link: (workspaceId, a) => `/${workspaceId}/identities`,
      name: a => a.payload.primary_name,
      description: a => "Identity provider edited",
    },
    deleted: {
      name: a => a.payload.primary_name,
      description: a => "Identity provider deleted",
    },
    enabled: {
      link: (workspaceId, a) => `/${workspaceId}/identities`,
      name: a => a.payload.primary_name,
      description: a => "Identity provider enabled",
    },
    disabled: {
      link: (workspaceId, a) => `/${workspaceId}/identities`,
      name: a => a.payload.primary_name,
      description: a => "Identity provider disabled",
    },
  },
  api: {
    created: {
      link: (workspaceId, a) => `/${workspaceId}/apis/authorization`,
      name: a => a.payload.primary_name,
      description: a => "API added",
    },
    updated: {
      link: (workspaceId, a) => `/${workspaceId}/apis/authorization`,
      name: a => a.payload.primary_name,
      description: a => "API edited",
    },
    deleted: {
      name: a => a.payload.primary_name,
      description: a => "API deleted",
    },
  },
  client: {
    created: {
      link: (workspaceId, a) =>
        `/${workspaceId}/applications/${encodeURIComponent(a.payload.subject_id)}/overview`,
      name: a => a.payload.primary_name,
      description: a => "Application added",
    },
    updated: {
      link: (workspaceId, a) =>
        `/${workspaceId}/applications/${encodeURIComponent(a.payload.subject_id)}/overview`,
      name: a => a.payload.primary_name,
      description: a => "Application edited",
    },
    deleted: {
      name: a => a.payload.primary_name,
      description: a => "Application deleted",
    },
  },
  policy: {
    created: {
      link: (workspaceId, a) => `/${workspaceId}/policies/${a.payload.subject_id}`,
      name: a => a.payload.primary_name,
      description: a => "Policy added",
    },
    updated: {
      link: (workspaceId, a) => `/${workspaceId}/policies/${a.payload.subject_id}`,
      name: a => a.payload.primary_name,
      description: a => "Policy edited",
    },
    deleted: {
      name: a => a.payload.primary_name,
      description: a => "Policy deleted",
    },
  },
  gateway: {
    created: {
      link: (workspaceId, a) => `/${workspaceId}/gateways/${a.payload.subject_id}/settings`,
      name: a => a.payload.primary_name,
      description: a => "Gateway added",
    },
    updated: {
      link: (workspaceId, a) => `/${workspaceId}/gateways/${a.payload.subject_id}/settings`,
      name: a => a.payload.primary_name,
      description: a => "Gateway edited",
    },
    deleted: {
      name: a => a.payload.primary_name,
      description: a => "Gateway deleted",
    },
    connected: {
      link: (workspaceId, a) => `/${workspaceId}/gateways/${a.payload.subject_id}/apis`,
      name: a => a.payload.primary_name,
      description: a => "Gateway connected",
    },
    api_groups_created: {
      link: (workspaceId, a) => `/${workspaceId}/gateways/${a.payload.subject_id}/apis`,
      name: a => `${a.payload.count} API groups`,
      description: a => "created",
    },
    api_groups_updated: {
      link: (workspaceId, a) => `/${workspaceId}/gateways/${a.payload.subject_id}/apis`,
      name: a => `${a.payload.count} API groups`,
      description: a => "updated",
    },
    api_groups_removed: {
      link: (workspaceId, a) => `/${workspaceId}/gateways/${a.payload.subject_id}/apis`,
      name: a => `${a.payload.count} API groups`,
      description: a => "removed",
    },
  },
  scope: {
    created: {
      name: a => a.payload.primary_name,
      description: a => "Scope added",
    },
    updated: {
      name: a => a.payload.primary_name,
      description: a => "Scope edited",
    },
    deleted: {
      name: a => a.payload.primary_name,
      description: a => "Scope deleted",
    },
  },
};
