import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";

import {
  BaseUserWithData,
  UserVerifiableAddressTypeEnum,
  UserWithData,
  UserWithDataStatusEnum,
} from "@cloudentity/acp-identity";

import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { useCheckPoolPermissions, useGetPool } from "../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../services/adminIdentityUsersApi";
import IdentityPoolThemeSelectorDialog from "../identityPools/identityPool/IdentityPoolThemeSelectorDialog";
import { useIdentityPoolThemeSelector } from "../identityPools/identityPool/useIdentityPoolThemeSelector";
import SendActivationMessageDialog from "../identityPools/identityPool/users/list/SendActivationMessageDialog";

interface Props {
  user: UserWithData | BaseUserWithData | undefined;
  workspaceId?: string;
  onClick: () => void;
}

export default function ManageUserActionsResendActivationEmail({
  user,
  workspaceId,
  onClick,
}: Props) {
  const addresses = user?.verifiable_addresses?.filter(
    a => a.type === UserVerifiableAddressTypeEnum.Email
  );

  const [sendActivationMsgDialog, setSendActivationMsgDialog] = useState(false);

  const getPoolQuery = useGetPool(user?.user_pool_id!);
  const { mustSelectThemeServerId, openDialog, closeDialog, isDialogOpen, themeWorkspaceId } =
    useIdentityPoolThemeSelector({ pool: getPoolQuery.data });

  const handleClick = (identifier: string, serverId?: string) => {
    identityUsersApi
      .sendActivationMessage({
        ipID: user?.user_pool_id!,
        userID: user?.id!,
        sendActivationMessage: {
          identifier,
        },
        serverId: serverId,
      })
      .then(() =>
        notifySuccess(
          <span>
            Invite sent to: <strong>{identifier}</strong>
          </span>
        )
      )
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to resend activation email"))
      .finally(() => onClick());
  };

  const checkPoolPermissionsQuery = useCheckPoolPermissions(user?.user_pool_id!, {
    enabled: !!user?.user_pool_id,
  });

  if (!checkPoolPermissionsQuery.data?.send_user_activation) {
    return null;
  }

  return user && user.status === UserWithDataStatusEnum.New && addresses && addresses.length > 0 ? (
    <>
      <MenuItem
        id="resend-activation-email-button"
        onClick={e => {
          e.stopPropagation();

          if (addresses?.length > 1) {
            setSendActivationMsgDialog(true);
          } else if (mustSelectThemeServerId) {
            openDialog();
          } else {
            handleClick(addresses[0].address, themeWorkspaceId || workspaceId);
          }
        }}
      >
        Resend Activation Email
      </MenuItem>

      {isDialogOpen && getPoolQuery.data && (
        <IdentityPoolThemeSelectorDialog
          pool={getPoolQuery.data}
          onCancel={() => closeDialog()}
          onSubmit={serverId => {
            handleClick(addresses[0].address, serverId);
            closeDialog();
          }}
        />
      )}

      {sendActivationMsgDialog && (
        <SendActivationMessageDialog
          poolId={user.user_pool_id}
          userId={user.id ?? ""}
          onClose={() => {
            setSendActivationMsgDialog(false);
            onClick();
          }}
        />
      )}
    </>
  ) : null;
}
