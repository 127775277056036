import { FeaturesApi, FeaturesApiSetBetaFeatureRequest } from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => FeaturesApi = adminApiClazz(FeaturesApi);

const adminFeaturesApi = {
  getBetaFeatures: () => api().getBetaFeatures(),
  setBetaFeature: (req: FeaturesApiSetBetaFeatureRequest) => api().setBetaFeature(req),
};

export default adminFeaturesApi;
