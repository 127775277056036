import React, { useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { Theme } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import { notifySuccess } from "../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";
import adminThemesApi from "../../../services/adminThemesApi";
import { listThemesQueryKey } from "../../../services/adminThemesQuery";
import { handleThemeCreateEditError } from "./ThemeEdit";

interface Props {
  sourceTheme?: Theme;
  onClose: () => void;
  onCreated: (theme: Theme) => void;
}

export default function ThemeCreate({ sourceTheme, onClose, onCreated }: Props) {
  const [progress, setProgress] = useState(false);

  const data = useMemo(
    () => (sourceTheme ? { logo_url: sourceTheme.logo_url } : {}),
    [sourceTheme]
  );

  const form = useForm({
    id: "theme-create",
    progress,
    initialValues: data,
  });
  const queryClient = useQueryClient();

  const handleCreate = data => {
    setProgress(true);
    adminThemesApi
      .createTheme({
        sourceThemeId: sourceTheme ? sourceTheme.id || "" : "",
        theme: {
          name: data.name.trim(),
          logo_url: data.logo_url.trim(),
          tenant_id: getTenantId(),
        },
      })
      .then(res =>
        Promise.all([
          Promise.resolve(res),
          queryClient.invalidateQueries({ queryKey: listThemesQueryKey(getTenantId()) }),
        ])
      )
      .then(([res]) => {
        notifySuccess("Theme successfully created");
        onCreated(res.data);
      })
      .catch(handleThemeCreateEditError("Error occurred when trying to create theme"))
      .finally(() => setProgress(false));
  };

  return (
    <Dialog
      id="theme-create-dialog"
      title={sourceTheme ? `Duplicate Theme ${sourceTheme.name}` : "Create Theme"}
      onClose={onClose}
    >
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          autoFocus
          rules={{
            validate: {
              onlyAlphanumeric: validators.onlyAlphanumericWithWhitespaces({ label: "Name" }),
            },
          }}
        />

        <TextField
          name="logo_url"
          label="Theme Icon URL"
          rules={{
            validate: {
              validURL: validators.validURL({ label: "Logo URL" }),
            },
          }}
          helperText="You can set up a theme icon URI, and it will appear in the themes list view to make it easier to identify theme."
        />

        <FormFooter onCancel={onClose} onSubmit={handleCreate} submitText="Create" />
      </Form>
    </Dialog>
  );
}
