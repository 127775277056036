import { Typography } from "@mui/material/styles/createTypography";

export const typography: Omit<
  Typography,
  | "fontWeightBold"
  | "fontWeightMedium"
  | "fontWeightRegular"
  | "fontWeightLight"
  | "htmlFontSize"
  | "pxToRem"
  | "subtitle1"
  | "subtitle2"
  | "overline"
> = {
  fontFamily: `"Inter", "Helvetica", "Arial", sans-serif`,
  fontSize: 14,
  h1: {
    fontSize: 36,
    lineHeight: "48px",
    letterSpacing: "-1.08px",
    fontWeight: 400,
  },
  h2: {
    fontSize: 28,
    lineHeight: "40px",
    letterSpacing: "-1.12px",
    fontWeight: 400,
  },
  h3: {
    fontSize: 20,
    lineHeight: "32px",
    letterSpacing: "-0.5px",
    fontWeight: 500,
  },
  h4: {
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "-0.32px",
    fontWeight: 600,
  },
  h5: {
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: "-0.08px",
    fontWeight: 600,
  },
  h6: {
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: "-0.08px",
    fontWeight: 700,
    textTransform: "uppercase" as const,
  },
  label: {
    fontSize: 12,
    lineHeight: "24px",
    letterSpacing: "-0.04px",
    fontWeight: 500,
  },
  body1: {
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "-0.08px",
    fontWeight: 400,
  },
  body2: {
    fontSize: 14,
    lineHeight: "24px",
    letterSpacing: "-0.08px",
    fontWeight: 400,
  },
  button: {
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "-0.04px",
    fontWeight: 500,
  },
  caption: {
    fontSize: 12,
    lineHeight: "20px",
    letterSpacing: "-0.04px",
    fontWeight: 400,
  },
  textXSM: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "-0.04px",
    marginBottom: 6,
  },
  textSM: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "12px",
    letterSpacing: "-0.04px",
  },
  textMD: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "-0.04px",
  },
  textLG: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "-0.04px",
  },
};
