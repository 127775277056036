import React, { ReactNode } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ExternalLink, Link, Search } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  container: {
    padding: "32px 24px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  left: {
    flex: 1,
  },
  right: {
    marginLeft: 16,
    width: 250,
    textAlign: "right",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    padding: 16,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.custom.greys.background,
    },
  },
  listBox: {
    padding: 0,
    margin: 0,
    "&>li": {
      padding: 13,
      borderBottom: "1px solid #EFEEEF",
      "&:last-of-type": {
        borderBottom: "none",
      },
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

interface Props<T extends { name?: string }> {
  id?: string;
  inputId?: string;
  label?: ReactNode;
  tooltip?: ReactNode;
  description?: ReactNode;
  options: T[];
  value: T | null;
  disabled: boolean;
  placeholder?: string;
  isInputFocused: boolean;
  setIsInputFocused: (isFocused: boolean) => void;
  progress: boolean;
  handleChange: (value: T | null) => void;
  caption?: ReactNode;
  buttonLabel?: string;
  hideButtonLink?: boolean;
  onClick: () => void;
}

export default function SetExtensionCard<T extends { name?: string }>({
  id,
  inputId,
  label,
  tooltip,
  description,
  options,
  value,
  disabled,
  placeholder = "Select script",
  isInputFocused,
  setIsInputFocused,
  progress,
  handleChange,
  caption = "Select the extensions that you have created",
  buttonLabel = "Manage Scripts",
  hideButtonLink,
  onClick,
}: Props<T>) {
  const { classes } = useStyles();

  return (
    <Paper id={id}>
      <div className={classes.container}>
        <div className={classes.left}>
          <Typography variant="h5" id={`${id}-input-label`} style={{ marginBottom: 12 }}>
            {label}
            {tooltip}
          </Typography>
          {description && (
            <Typography variant="textXSM" component="div" style={{ marginBottom: 24 }}>
              {description}
            </Typography>
          )}
          <Autocomplete
            id={inputId || `${id}-input`}
            options={options}
            value={value}
            renderOption={({ key, ...props }, option) => (
              <li key={key} {...props} className={classes.listItem}>
                <Link size={12} style={{ marginRight: 12 }} />
                <Typography variant="caption">{option.name}</Typography>
              </li>
            )}
            getOptionLabel={option => option.name || ""}
            style={{ width: 300, marginBottom: 4 }}
            disabled={disabled}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder={isInputFocused ? (progress ? "Loading..." : "Search") : placeholder}
                onFocus={() => {
                  setIsInputFocused(true);
                }}
                onBlur={() => {
                  setIsInputFocused(false);
                }}
                InputProps={{
                  ...(params?.InputProps || {}),
                  startAdornment: isInputFocused ? (
                    <InputAdornment position="start">
                      <Search size="16" className={classes.icon} strokeWidth="2.5" />
                    </InputAdornment>
                  ) : null,
                  endAdornment: (
                    <>
                      {progress ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={(_, value) => handleChange(value)}
            ListboxProps={{
              className: classes.listBox,
            }}
          />
          <Typography variant="caption">{caption}</Typography>
        </div>
        {!hideButtonLink && (
          <div className={classes.right}>
            <Button
              color="primary"
              variant="outlined"
              role="link"
              onClick={onClick}
              id="edit-button"
              disabled={progress}
            >
              <ExternalLink size="16" style={{ marginRight: 10 }} />
              {buttonLabel}
            </Button>
          </div>
        )}
      </div>
    </Paper>
  );
}
