import React from "react";

import { getTenantId } from "../../../../common/api/paths";
import { useListIDPs } from "../../../services/adminIDPsQuery";
import PageContainer from "../../common/PageContainer";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";
import IdentitiesListSimple from "../../identities/identities_view_tabbed/identitiesListSimple/IdentitiesListSimple";

export default function AdministratorSettingsV2() {
  const listIDPsQuery = useListIDPs(getTenantId(), "admin");

  return (
    <PageContainer>
      <PageHeader title="Sign-in and SSO"></PageHeader>
      <PageContent fullHeight noPadding style={{ paddingLeft: 0, paddingRight: 0 }}>
        <IdentitiesListSimple
          identities={listIDPsQuery.data?.idps ?? []}
          mode="simple"
          workspace="admin"
          setMode={undefined}
          tenantSignInAndSSO
          idpsLoading={listIDPsQuery.isLoading}
        />
      </PageContent>
    </PageContainer>
  );
}
