import React, { ReactNode } from "react";

// eslint-disable-next-line no-restricted-imports
import { SvgIconComponent } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { Icon } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  readOnlyInputLabel: {
    fontWeight: "bold",
    fontSize: 12,
    color: theme.palette.secondary.light,
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
  },
}));

interface Props {
  id: string;
  label: string | ReactNode;
  icon?: Icon | SvgIconComponent;
}

export default function ReadOnlyInputLabel({ id, label, icon: Icon }: Props) {
  const { classes } = useStyles();

  return (
    <Typography id={`read-only-input-label-${id}`} className={classes.readOnlyInputLabel}>
      {Icon && <Icon style={{ marginRight: 8, width: 16, height: 16 }} />}
      {label}
    </Typography>
  );
}
