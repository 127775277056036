import { useMemo } from "react";

import { SupportedJSONSchema } from "@cloudentity/acp-identity";

import { useGetPool } from "../../services/adminIdentityPoolsQuery";
import { useGetSchema } from "../../services/adminIdentitySchemasQuery";
import {
  extractOnlyRequiredProperties,
  fieldToTitle,
  getPathsAndTypes,
} from "../workspaceDirectory/identityPools/schemas/schemas.utils";

const addressProperties = [
  {
    name: "addresses.email_verified",
    description: "Email verified",
    type: "string",
  },
  {
    name: "addresses.email_unverified",
    description: "Email unverified",
    type: "string",
  },
  {
    name: "addresses.email_oidc_discovery",
    description: "Email OIDC discovery",
    type: "string",
  },
  {
    name: "addresses.phone_verified",
    description: "Phone verified",
    type: "string",
  },
  {
    name: "addresses.phone_unverified",
    description: "Phone unverified",
    type: "string",
  },
  {
    name: "addresses.phone_oidc_discovery",
    description: "Phone OIDC discovery",
    type: "string",
  },
];

const identifierProperties = [
  {
    name: "identifiers.email",
    description: "Email",
    type: "string",
  },
  {
    name: "identifiers.mobile",
    description: "Phone",
    type: "string",
  },
];

const useSchemaToProperties = (schema: SupportedJSONSchema | undefined, namePrefix: string) =>
  useMemo(() => {
    if (!schema) {
      return [];
    }

    return getPathsAndTypes(schema).map(v => ({
      name: `${namePrefix}.${v.path}`,
      description: fieldToTitle(v.path),
      type: v.type === "boolean" ? "bool" : v.type,
    }));
  }, [schema, namePrefix]);

export const usePoolSchemasProperties = ({ poolId }) => {
  const getPoolQuery = useGetPool(poolId ?? "", { enabled: !!poolId });

  const payloadSchemaQuery = useGetSchema(getPoolQuery.data?.payload_schema_id, {
    enabled: !!getPoolQuery.data?.payload_schema_id,
  });
  const metadataSchemaQuery = useGetSchema(getPoolQuery.data?.metadata_schema_id, {
    enabled: !!getPoolQuery.data?.metadata_schema_id,
  });
  const businessMetadataSchemaQuery = useGetSchema(getPoolQuery.data?.business_metadata_schema_id, {
    enabled: !!getPoolQuery.data?.business_metadata_schema_id,
  });

  const payloadProperties = useSchemaToProperties(payloadSchemaQuery.data?.schema, "user.payload");
  const metadataProperties = useSchemaToProperties(
    metadataSchemaQuery.data?.schema,
    "user.metadata"
  );
  const businessMetadataProperties = useSchemaToProperties(
    businessMetadataSchemaQuery.data?.schema,
    "user.business_metadata"
  );

  const properties = useMemo(
    () => [...payloadProperties, ...metadataProperties, ...businessMetadataProperties],
    [payloadProperties, metadataProperties, businessMetadataProperties]
  );

  return {
    properties,
    isLoading:
      getPoolQuery.isLoading ||
      payloadSchemaQuery.isLoading ||
      metadataSchemaQuery.isLoading ||
      businessMetadataSchemaQuery.isLoading,
  };
};

export const useProvisioningMappingProperties = ({ poolId }) => {
  const getPoolQuery = useGetPool(poolId ?? "", { enabled: !!poolId });

  const payloadSchemaQuery = useGetSchema(getPoolQuery.data?.payload_schema_id, {
    enabled: !!getPoolQuery.data?.payload_schema_id,
  });
  const metadataSchemaQuery = useGetSchema(getPoolQuery.data?.metadata_schema_id, {
    enabled: !!getPoolQuery.data?.metadata_schema_id,
  });
  const businessMetadataSchemaQuery = useGetSchema(getPoolQuery.data?.business_metadata_schema_id, {
    enabled: !!getPoolQuery.data?.business_metadata_schema_id,
  });

  const payloadProperties = useSchemaToProperties(payloadSchemaQuery.data?.schema, "payload");
  const metadataProperties = useSchemaToProperties(metadataSchemaQuery.data?.schema, "metadata");
  const businessMetadataProperties = useSchemaToProperties(
    businessMetadataSchemaQuery.data?.schema,
    "business_metadata"
  );

  const payloadRequiredSchema = useMemo(
    () => extractOnlyRequiredProperties(payloadSchemaQuery.data?.schema),
    [payloadSchemaQuery.data?.schema]
  );
  const payloadRequiredProperties = useSchemaToProperties(payloadRequiredSchema, "payload");

  const metadataRequiredSchema = useMemo(
    () => extractOnlyRequiredProperties(metadataSchemaQuery.data?.schema),
    [metadataSchemaQuery.data?.schema]
  );
  const metadataRequiredProperties = useSchemaToProperties(metadataRequiredSchema, "metadata");

  const businessMetadataRequiredSchema = useMemo(
    () => extractOnlyRequiredProperties(businessMetadataSchemaQuery.data?.schema),
    [businessMetadataSchemaQuery.data?.schema]
  );
  const businessMetadataRequiredProperties = useSchemaToProperties(
    businessMetadataRequiredSchema,
    "business_metadata"
  );

  const properties = useMemo(
    () => [
      ...payloadProperties,
      ...metadataProperties,
      ...businessMetadataProperties,
      ...addressProperties,
      ...identifierProperties,
    ],
    [payloadProperties, metadataProperties, businessMetadataProperties]
  );

  const requiredProperties = useMemo(() => {
    return [
      ...payloadRequiredProperties,
      ...metadataRequiredProperties,
      ...businessMetadataRequiredProperties,
    ];
  }, [payloadRequiredProperties, metadataRequiredProperties, businessMetadataRequiredProperties]);

  return {
    properties,
    requiredProperties,
    isLoading:
      getPoolQuery.isLoading ||
      payloadSchemaQuery.isLoading ||
      metadataSchemaQuery.isLoading ||
      businessMetadataSchemaQuery.isLoading,
  };
};
