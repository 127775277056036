import { Theme, Themes } from "@cloudentity/acp-admin";

import adminThemesApi from "./adminThemesApi";
import { useQueryWithTenantPermissionCheck, withQueryError } from "./queryUtils";

const LIST_THEMES_QUERY = "LIST_THEMES_QUERY";
const GET_THEME_QUERY = "GET_THEME_QUERY";

export const listThemesQueryKey = (tid: string) => [LIST_THEMES_QUERY, tid];
export const getThemeQueryKey = (tid: string, themeID: string) => [GET_THEME_QUERY, tid, themeID];

export const useListThemes = (tid: string, options?: any) =>
  useQueryWithTenantPermissionCheck(
    "read_themes",
    listThemesQueryKey(tid),
    withQueryError<Themes>(async () => {
      const data = await adminThemesApi.listThemes();
      return data.data;
    }, "Error occurred while trying to fetch themes"),
    options
  );

export const useGetTheme = (tid: string, themeID: string, options?: any) =>
  useQueryWithTenantPermissionCheck(
    "read_themes",
    getThemeQueryKey(tid, themeID),
    withQueryError<Theme>(async () => {
      const data = await adminThemesApi.getTheme({ themeID });
      return data.data;
    }, "Error occurred while trying to fetch theme"),
    options
  );
