import {
  CustomAppsApi,
  CustomAppsApiCreateCustomAppRequest,
  CustomAppsApiDeleteCustomAppRequest,
  CustomAppsApiGetCustomAppRequest,
  CustomAppsApiListServerCustomAppsRequest,
  CustomAppsApiUpdateCustomAppRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => CustomAppsApi = adminApiClazz(CustomAppsApi);

const adminCustomAppsApi = {
  listServerCustomApps: (req: CustomAppsApiListServerCustomAppsRequest) =>
    api().listServerCustomApps(req),
  getCustomApp: (req: CustomAppsApiGetCustomAppRequest) => api().getCustomApp(req),
  createCustomApp: (req: CustomAppsApiCreateCustomAppRequest) => api().createCustomApp(req),
  updateCustomApp: (req: CustomAppsApiUpdateCustomAppRequest) => api().updateCustomApp(req),
  deleteCustomApp: (req: CustomAppsApiDeleteCustomAppRequest) => api().deleteCustomApp(req),
};

export default adminCustomAppsApi;
