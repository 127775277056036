import { LicenseApi } from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => LicenseApi = adminApiClazz(LicenseApi);

const adminLicenseApi = {
  getLicense: () => api().getAdminLicense(),
};

export default adminLicenseApi;
