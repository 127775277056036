import React, { useState } from "react";

import RemoveIcon from "@mui/icons-material/DeleteOutline";
import InputIcon from "@mui/icons-material/Input";
import WarningIcon from "@mui/icons-material/ReportProblem";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/sortBy";
import { makeStyles } from "tss-react/mui";

import { AuthenticationContextAttribute } from "@cloudentity/acp-admin";

import Switch from "../../../common/components/Switch";
import { getHoverRowStyles } from "../../../common/styles/tableStyles";
import { IdpType } from "../../services/adminIDPsApi";
import { mapDataTypeToDisplayName } from "../authnContext/attrbutesDataTypeOptions";
import { SAML_NAME_ID_ID, SAML_NAME_ID_LABEL } from "./IdentitiesDetailsAttributesAdd";
import IdentitiesDetailsMappingsTableRowStaticSource from "./IdentitiesDetailsMappingsTableRowStaticSource";
import { splitAttrName } from "./identities.utils";
import { RowMapping, StaticMappingTypes } from "./identitiesMappings.utils";

const useStyles = makeStyles()((_, __, classes) => ({
  ...getHoverRowStyles(classes),
  inputIcon: {
    verticalAlign: "middle",
    color: "#000",
    opacity: 0.38,
  },
  option: {},
}));

type SourceType = "access_token" | "id_token" | "user_data" | "root" | "saml_attributes" | "jit";

export const sourceMapping = {
  access_token: "Access token",
  id_token: "ID token",
  user_data: "User info",
  root: "Custom",
  jit: "JIT",
};

export function getSourceMapping(
  source: string,
  idpType: IdpType | undefined,
  variableName: string
) {
  if (source === "user_data") {
    if (idpType === "azure" || idpType === "azureb2c") {
      return "Azure Graph";
    } else if (idpType === "github" || idpType === "github_embedded") {
      return "Github Authenticated User Data";
    } else if (idpType === "saml") {
      return "SAML assertion attribute";
    }
  }

  if (source === "saml_attributes") {
    if (idpType === "saml_v2") {
      return "SAML assertion attribute";
    }
  }

  if (idpType === "saml_v2" && source === "root" && variableName === SAML_NAME_ID_ID) {
    return SAML_NAME_ID_LABEL;
  }

  return sourceMapping[source];
}

const hasEqualTypes = (row: RowMapping) =>
  row.static ||
  row.editValue.source?.type === row.editValue.target?.type ||
  !row.editValue.target ||
  !row.editValue.target.name;

const isSaved = (row: RowMapping) =>
  isEqual(row.value, row.editValue as any) && !!row.editValue.source && !!row.editValue.target;

const sourceToDisplayName = (row: RowMapping, idpType: IdpType | undefined) => {
  if (row.static) {
    return "Static";
  }

  if (idpType === "saml_v2" && row.editValue.source?.name === SAML_NAME_ID_ID) {
    return SAML_NAME_ID_LABEL;
  }

  const name = row.editValue.source?.name ?? "";

  const [source] = splitAttrName(name);
  const sourceName = (source && getSourceMapping(source as SourceType, idpType, name)) || source;

  return name ? sourceName : "";
};

interface Props {
  row: RowMapping;
  index: number;
  sourceOptions: AuthenticationContextAttribute[];
  targetOptions: AuthenticationContextAttribute[];
  onChange: (
    index: number,
    value: {
      source?: AuthenticationContextAttribute | StaticMappingTypes | null;
      target?: AuthenticationContextAttribute | null;
    }
  ) => void;
  onRemove: (index: number) => void;
  idpType: IdpType | undefined;
  editable: boolean;
}

export default function IdentitiesDetailsMappingsTableRow({
  row,
  index,
  sourceOptions,
  targetOptions,
  onChange,
  onRemove,
  idpType,
  editable,
}: Props) {
  const { classes } = useStyles();

  const [showAllTargets, setShowAllTargets] = useState(row.static || false);

  const sourceOpts = sourceOptions.map(option => ({
    ...option,
    name: splitAttrName(option.name ?? "")[1],
    source: splitAttrName(option.name ?? "")[0],
  }));

  const sortedSourceOptions = sortBy(sourceOpts, ["source", "name", "type"]);

  const sortedTargetOptions = sortBy(targetOptions, ["type", "name"]);

  return (
    <TableRow className={classes.rowWithHoverIcons}>
      <TableCell id={`identities-mapping-source-type-${index}`} align="left" width="10%">
        {sourceToDisplayName(row, idpType)}
      </TableCell>
      <TableCell width="38%">
        {row.static ? (
          <IdentitiesDetailsMappingsTableRowStaticSource
            row={row}
            onChange={value => {
              onChange(index, { source: value });
            }}
          />
        ) : (
          <Autocomplete<(typeof sourceOpts)[0]>
            id={`identities-mapping-source-value-${index}`}
            autoHighlight
            options={sortedSourceOptions}
            value={row.editValue.source as any}
            groupBy={o => getSourceMapping(o.source as SourceType, idpType, o.name)}
            onChange={(_, value) => {
              if (value) {
                const source = {
                  name: value.source !== "root" ? `${value.source}.${value.name}` : value.name,
                  type: value.type,
                  description: value.description,
                };
                onChange(index, { source, target: null });
              } else {
                onChange(index, { source: null, target: null });
              }
            }}
            getOptionLabel={option => option.description ?? ""}
            isOptionEqualToValue={(option, value) => option.description === value.description}
            disabled={!editable}
            style={{ flex: 1 }}
            renderInput={params => (
              <TextField {...params} label="Source name" variant="outlined" fullWidth />
            )}
            renderOption={({ key, ...props }, option) => (
              <li
                key={key}
                {...props}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography>{option.description}</Typography>
                <Typography variant="caption">{option.type}</Typography>
              </li>
            )}
          />
        )}
        {!row.static && row.editValue.source?.type === "any" && (
          <FormHelperText>
            <div style={{ display: "flex", alignItems: "center" }}>
              <WarningIcon htmlColor="#ff9800" fontSize="small" style={{ marginRight: 6 }} />
              This attribute doesn't have a type. You should remove it and add a typed attribute
              instead.
            </div>
          </FormHelperText>
        )}
      </TableCell>
      {isSaved(row) && (
        <TableCell width="4%" align="center">
          {hasEqualTypes(row) && (
            <InputIcon
              classes={{ root: classes.inputIcon }}
              style={{ color: "#00875a", opacity: 1 }}
            />
          )}
          {!hasEqualTypes(row) && (
            <ShuffleIcon
              classes={{ root: classes.inputIcon }}
              style={{ color: "#00875a", opacity: 1 }}
            />
          )}
        </TableCell>
      )}
      {!isSaved(row) && (
        <TableCell width="4%" align="center">
          {hasEqualTypes(row) && <InputIcon classes={{ root: classes.inputIcon }} />}
          {!hasEqualTypes(row) && <ShuffleIcon classes={{ root: classes.inputIcon }} />}
        </TableCell>
      )}
      <TableCell width="38%">
        <Autocomplete
          id={`identities-mapping-target-value-${index}`}
          autoHighlight
          options={[
            { name: "row-dummy-for-switch", description: "dummy", type: "dummyType" },
            ...(showAllTargets
              ? sortedTargetOptions
              : sortedTargetOptions.filter(
                  o => !row.static && o.type === (row.editValue.source?.type ?? ""),
                  row
                )),
            { ...row.editValue.target },
          ]}
          value={row.editValue.target}
          filterSelectedOptions
          disabled={(!row.static && !row.editValue.source) || !editable}
          onChange={(_, value) => onChange(index, { target: value })}
          getOptionLabel={option => option.description ?? ""}
          isOptionEqualToValue={(option, value) => option.description === value.description}
          style={{
            flex: 1,
            marginBottom: row.static && row.editValue.target?.type === "string_array" ? 10 : 0,
          }}
          onClose={() => setShowAllTargets(false)}
          renderInput={params => (
            <TextField {...params} label="Target name" variant="outlined" fullWidth />
          )}
          renderOption={({ key, ...props }, option) =>
            option.type === "dummyType" ? (
              <li
                key={key}
                {...props}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowAllTargets(!showAllTargets);
                }}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography variant="subtitle2" style={{ marginRight: 8 }}>
                  Show all attribute types
                </Typography>
                <Switch
                  checked={showAllTargets}
                  onChange={() => setShowAllTargets(!showAllTargets)}
                />
              </li>
            ) : (
              <li
                key={key}
                {...props}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography>{option.description}</Typography>
                <Typography variant="caption">{mapDataTypeToDisplayName(option.type)}</Typography>
              </li>
            )
          }
        />
        {row.editValue.target?.type === "any" && (
          <FormHelperText style={{ display: "flex", alignItems: "center" }}>
            <WarningIcon htmlColor="#ff9800" fontSize="small" style={{ marginRight: 6 }} />
            This attribute doesn't have a type. You should remove it and add a typed attribute
            instead.
          </FormHelperText>
        )}
      </TableCell>
      <TableCell align="right" width="10%">
        {editable && (
          <IconButton
            aria-label="delete"
            onClick={e => {
              e.stopPropagation();
              onRemove(index);
            }}
            className={classes.hoverIcon}
            id={`identities-mappings-delete-button-${index}`}
            size="large"
          >
            <RemoveIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}
