import React, { useContext, useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";

import { GlobalStoreContext } from "../admin/GlobalStore/GlobalStore";
import PageContainer from "../admin/components/common/PageContainer";
import PageContent from "../admin/components/common/PageContent";
import PageHeader from "../admin/components/common/PageHeader";
import WorkspaceAdministratorsTable, {
  UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles,
} from "../admin/components/manageAccess/WorkspaceAdministratorsTable";
import { BUILD_IN_ADMIN_POOL_ID } from "../admin/components/workspaceDirectory/administrator/AdministratorManagement";
import { getUserRolesBySubjects } from "../admin/components/workspaceDirectory/identityPools/identityPool/users/list/utils";
import adminB2BUsersApi from "../admin/services/adminB2BUsersApi";
import { getB2BUserQueryKey } from "../admin/services/adminIdentityUsersQuery";
import { useListTenantRoles } from "../admin/services/adminRolesQuery";
import emptyImg from "../assets/images/emptyStates/empty-org-admins.svg";
import { getTenantId } from "../common/api/paths";
import EmptyState from "../common/components/EmptyState";
import IconTooltip from "../common/components/IconTooltip";
import AddB2BAdministratorDialog from "./AddB2BAdministratorDialog";
import B2BOrganizationsDirectoryAdministratorsToolbar from "./B2BOrganizationsDirectoryAdministratorsToolbar";
import EditB2BAdministratorDialog from "./EditB2BAdministratorDialog";
import OrganizationsDirectoryAdministratorsRowMenu from "./OrganizationsDirectoryAdministratorsRowMenu";

export default function B2BAdministrators() {
  const globalStoreContext = useContext(GlobalStoreContext);

  const [menu, setMenu] = useState<{
    anchorEl: HTMLElement;
    open: boolean;
    data: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles;
  } | null>(null);
  const [userEditDialog, setUserEditDialog] = useState<{
    data: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles;
  } | null>(null);
  const [searchText, setSearchText] = useState("");

  const listTenantRolesQuery = useListTenantRoles();

  const subjectsFromAnyIdentityPoolConnectedToAdminWorkspace = useMemo(
    () =>
      (listTenantRolesQuery.data?.subjects || []).filter(
        sub => sub.identity_pool_id === BUILD_IN_ADMIN_POOL_ID && sub.roles?.business_admin
      ),
    [listTenantRolesQuery.data]
  );

  const userQueries = useQueries({
    queries: subjectsFromAnyIdentityPoolConnectedToAdminWorkspace.map(subject => {
      return {
        queryKey: getB2BUserQueryKey(getTenantId(), subject.identity_pool_user_id),
        queryFn: async () => {
          const data = await adminB2BUsersApi.getB2BUser({
            ipID: subject.identity_pool_id!,
            userID: subject.identity_pool_user_id!,
          });

          return data.data;
        },
      };
    }),
  });

  const usersAndPoolsAndRoles = useMemo(
    () =>
      userQueries
        .map(q => q.data)
        .map(u => {
          const subjects = listTenantRolesQuery.data?.subjects || [];
          const subject = subjects.find(s => s.identity_pool_user_id === u?.id);

          return {
            id: u?.id,
            user: u,
            identity_pool: [],
            subject,
            roles: getUserRolesBySubjects(u, subjects).tenant,
          };
        }),
    [userQueries, listTenantRolesQuery.data?.subjects]
  );

  const usersAndPoolsAndRolesFiltered = useMemo(
    () =>
      globalStoreContext.deletedUsers.filterDeletedUsersFromData(
        usersAndPoolsAndRoles.filter(up => {
          const s = JSON.stringify(up);
          return !searchText
            ? true
            : s.toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim());
        })
      ) ?? [],
    [globalStoreContext, searchText, usersAndPoolsAndRoles]
  );

  const progress = userQueries.some(q => q.isLoading) || listTenantRolesQuery.isLoading;

  return (
    <PageContainer>
      <PageHeader
        title={
          <>
            Business Administrators{" "}
            <IconTooltip
              title="Business administrators can onboard and manage all organizations."
              placement="bottom"
            />
          </>
        }
      />
      <PageContent progress={progress || !listTenantRolesQuery.isFetched}>
        {!usersAndPoolsAndRolesFiltered.length ? (
          <EmptyState
            title="Add Business Administrator"
            description="Invite a business administrator who will manage all organizations and delegate organization administration to partners and customers"
            actionButton={<AddB2BAdministratorDialog />}
            img={emptyImg}
            imgSize={12}
            style={{ marginTop: 80 }}
          />
        ) : (
          <>
            <B2BOrganizationsDirectoryAdministratorsToolbar
              search={searchText}
              onUpdateSearch={text => setSearchText(text)}
            />
            <WorkspaceAdministratorsTable
              isB2BAdministrators
              usersAndPoolsAndRoles={usersAndPoolsAndRolesFiltered as any[]}
              progress={false}
              hasManagePermission
              onRowClick={userAndPoolAndRoles => setUserEditDialog({ data: userAndPoolAndRoles })}
              onMenuOpen={(anchorEl, userAndPoolAndRoles) =>
                setMenu({ anchorEl, data: userAndPoolAndRoles, open: true })
              }
            />
          </>
        )}

        {menu && (
          <OrganizationsDirectoryAdministratorsRowMenu
            menu={menu}
            onClose={() => setMenu({ ...menu, open: false })}
          />
        )}

        {userEditDialog && (
          <EditB2BAdministratorDialog
            user={userEditDialog.data.user}
            onCancel={() => setUserEditDialog(null)}
          />
        )}
      </PageContent>
    </PageContainer>
  );
}
