import React from "react";
import { Controller } from "react-hook-form";

import Grid from "@mui/material/Grid";

import FormInputLabel from "../../components/FormInputLabel";
import FormCard from "../../components/applications/FormCard";
import { useFormContext } from "./Form";

export interface FormCardsFieldProps {
  name: string;
  label?: string;
  cards: {
    title: string;
    subtitle: React.ReactElement | string;
    value: string | boolean;
    img?: string;
  }[];
  disabled?: boolean;
  style?: React.CSSProperties;
}

export default function FormCardsField({
  name,
  label,
  cards,
  disabled,
  style,
}: FormCardsFieldProps) {
  const { form, disabled: formDisabled, id } = useFormContext();
  const inputId = `${id}-${name}`;

  return (
    <>
      {label && <FormInputLabel id={`${id}-label`} label={label} />}

      <Controller
        name={name}
        control={form.control}
        render={({ field }) => (
          <Grid
            container
            spacing={2}
            wrap="nowrap"
            style={{ marginBottom: 32, ...style }}
            role="radiogroup"
            id={inputId}
          >
            {cards.map(({ title, subtitle, value, img }) => (
              <Grid item xs={6} key={typeof value === "string" ? value : value ? "true" : "false"}>
                <FormCard
                  id={`${inputId}-${value}`}
                  style={{ height: "100%" }}
                  title={title}
                  subtitle={subtitle}
                  selected={field.value === value}
                  onSelect={() => {
                    field.onChange(value);
                  }}
                  img={img}
                  disabled={disabled || formDisabled}
                />
              </Grid>
            ))}
          </Grid>
        )}
      />
    </>
  );
}
