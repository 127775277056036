import React, { useState } from "react";

import Editor, { loader } from "@monaco-editor/react";
import Button from "@mui/material/Button";
import { Upload } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { useWorkspace } from "../../../admin/components/common/useWorkspace";
import { useGetClientSAMLMetadata } from "../../../admin/services/adminClientsQuery";
import FormSection from "../FormSection";
import Progress from "../Progress";
import SamlUploadDialog from "./SamlUploadDialog";

loader.config({
  paths: {
    vs: "./assets/monaco/min/vs",
  },
});

const useStyles = makeStyles()(() => ({
  container: {
    height: "100%",
  },
  editor: {
    border: "1px solid #D5D7D9",
    borderRadius: 4,
    padding: 1,
    ".margin": {
      backgroundColor: "#F2F3F4 !important",
      ".line-numbers": {
        fontWeight: "bold",
      },
    },
  },
}));

export const formatXml = xml => {
  // https://stackoverflow.com/questions/57039218/doesnt-monaco-editor-support-xml-language-by-default
  try {
    const PADDING = " ".repeat(2);
    const reg = /(>)(<)(\/*)/g;
    let pad = 0;

    xml = xml.replace(reg, "$1\r\n$2$3");

    return xml
      .split("\r\n")
      .map(node => {
        let indent = 0;
        if (node.match(/.+<\/\w[^>]*>$/)) {
          indent = 0;
        } else if (node.match(/^<\/\w/) && pad > 0) {
          pad -= 1;
        } else if (node.match(/^<\w[^>]*[^/]>.*$/)) {
          indent = 1;
        } else {
          indent = 0;
        }

        pad += indent;

        return PADDING.repeat(pad - indent) + node;
      })
      .join("\r\n");
  } catch {
    return xml;
  }
};

interface Props {
  clientId: string | undefined;
}

export default function SamlTab({ clientId }: Props) {
  const { classes } = useStyles();
  const [workspace] = useWorkspace();

  const getClientSamlMetadata = useGetClientSAMLMetadata(clientId ?? "", workspace);
  const [samlUploadDialog, setSamlUploadDialog] = useState(false);

  return (
    <div className={classes.container}>
      {getClientSamlMetadata.isLoading ? (
        <Progress />
      ) : (
        <>
          <FormSection
            id="application-saml-ervice-provider-metadata-header"
            title="SAML Provider Metadata"
          >
            <Button
              id="application-saml-upload-metadata"
              variant="contained"
              onClick={() => setSamlUploadDialog(true)}
              startIcon={<Upload size={14} />}
            >
              Upload
            </Button>
          </FormSection>

          <Editor
            defaultLanguage="html"
            height="calc(100% - 81px)"
            options={{
              minimap: {
                enabled: false,
              },
              scrollBeyondLastLine: false,
              readOnly: true,
            }}
            value={formatXml(getClientSamlMetadata.data?.raw_xml ?? "")}
            wrapperProps={{
              id: "application-saml-metadata-editor",
            }}
            className={classes.editor}
          />
        </>
      )}

      {samlUploadDialog && (
        <SamlUploadDialog
          clientId={clientId}
          onCancel={() => setSamlUploadDialog(false)}
          onTabChange={() => {}}
        />
      )}
    </div>
  );
}
