import React from "react";

import SelfServiceController from "../../common/components/selfServiceSettings/SelfServiceController";

export default function DashboardV2() {
  return (
    <SelfServiceController
      config={{
        withDialog: false,
        showMetadata: true,
      }}
    />
  );
}
