import React, { useEffect, useState } from "react";

import Editor, { loader, useMonaco } from "@monaco-editor/react";
import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";

import Tooltip from "../../../common/Tooltip";
import supportedJsonSchema from "./supported-json-schema.json";

loader.config({
  paths: {
    vs: "./assets/monaco/min/vs",
  },
});

const useStyles = makeStyles()(() => ({
  editorContainer: {
    border: "1px solid #D5D7D9",
    borderRadius: 4,
    padding: 1,
  },
}));

interface Props {
  initialSchema: string;
  readOnly: boolean;
  progress?: boolean;
  onSubmit: (schema: string) => void;
}

export default function IdentitySchemaEditor({
  initialSchema,
  progress,
  readOnly,
  onSubmit,
}: Props) {
  const { classes } = useStyles();

  const [schema, setSchema] = useState(initialSchema);
  const [isSyntaxError, setSyntaxError] = useState(false);

  const monaco = useMonaco();

  const handleValidate = annotations => setSyntaxError(annotations && annotations.length > 0);

  useEffect(() => {
    setSchema(initialSchema);
  }, [initialSchema, progress]);

  useEffect(() => {
    monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: "https://localhost:3000/assets/supported-json-schema.json",
          fileMatch: ["*"],
          schema: supportedJsonSchema,
        },
      ],
      enableSchemaRequest: false,
      allowComments: false,
    });
  }, [monaco]);

  return (
    <>
      <div className={classes.editorContainer}>
        <Editor
          defaultLanguage="json"
          options={{
            minimap: {
              enabled: false,
            },
            scrollBeyondLastLine: false,
            readOnly: readOnly || progress,
          }}
          height="calc(100vh - 460px)"
          defaultValue={initialSchema}
          value={schema}
          onChange={v => setSchema(v ?? "")}
          wrapperProps={{
            id: "identity-schema-editor",
          }}
          onValidate={handleValidate}
        />
      </div>
      {!readOnly && (
        <div style={{ textAlign: "right", width: "100%", marginTop: 32 }}>
          <Tooltip title={isSyntaxError ? "Syntax error" : ""}>
            <span style={{ paddingTop: 4 }}>
              <Button
                id="save-button"
                variant="contained"
                onClick={() => onSubmit(schema)}
                disabled={isSyntaxError}
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </div>
      )}
    </>
  );
}
