import sha256 from "crypto-js/sha256";

import {
  ServerResponse,
  ServerResponseSubjectFormatEnum,
  WorkspaceResponse,
} from "@cloudentity/acp-admin";

const idpPrefixSeparator = ":";

export const getSubjectValue = (
  subject: string,
  idpId: string,
  server?: ServerResponse | WorkspaceResponse
) => {
  if (server?.subject_format === ServerResponseSubjectFormatEnum.Hash) {
    const digest = idpId + idpPrefixSeparator + subject;
    const salt = server.subject_identifier_algorithm_salt;

    return sha256(digest + salt).toString();
  }

  return subject;
};
