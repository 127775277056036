import {
  TokensApi,
  TokensApiCreateTokenRequest,
  TokensApiDeleteTokenRequest,
  TokensApiGetTokenRequest,
  TokensApiListTokensRequest,
  TokensApiUpdateTokenRequest,
} from "@cloudentity/acp-permissions";

import { permissionsApiClazz } from "./admin-services.common";

const api: () => TokensApi = permissionsApiClazz(TokensApi);

const permissionsTokensApi = {
  listTokens: (req: TokensApiListTokensRequest) => api().listTokens(req),
  createToken: (req: TokensApiCreateTokenRequest) => api().createToken(req),
  getToken: (req: TokensApiGetTokenRequest) => api().getToken(req),
  updateToken: (req: TokensApiUpdateTokenRequest) => api().updateToken(req),
  deleteToken: (req: TokensApiDeleteTokenRequest) => api().deleteToken(req),
};

export default permissionsTokensApi;
