import australiaFlag from "../../../assets/images/icons/dashboard/australia-flag.svg";
import australiaMap from "../../../assets/images/icons/dashboard/australia-map.svg";
import brazilFlag from "../../../assets/images/icons/dashboard/brazil-flag.svg";
import brazilMap from "../../../assets/images/icons/dashboard/brazil-map.svg";
import brazilIcon from "../../../assets/images/icons/dashboard/brazil.svg";
import cdrIcon from "../../../assets/images/icons/dashboard/cdr-card-badge.svg";
import fdxIcon from "../../../assets/images/icons/dashboard/fdx-card-badge.svg";
import fdxSquareIcon from "../../../assets/images/icons/dashboard/fdx-square.svg";
import genericIcon from "../../../assets/images/icons/dashboard/generic-card-badge.svg";
import genericSquareIcon from "../../../assets/images/icons/dashboard/generic-square.svg";
import ukFlag from "../../../assets/images/icons/dashboard/uk-flag.svg";
import ukMap from "../../../assets/images/icons/dashboard/uk-map.svg";
import ukIcon from "../../../assets/images/icons/dashboard/uk.svg";
import ExchangeIcon from "../../../common/components/icons/overview/ExchangeIcon";
import { getFromLocalStorage, setInLocalStorage } from "../../../common/utils/localStorage.utils";

function getMondaysBetweenDates(start: Date, end: Date) {
  let result = 0;
  const day = 1; // monday
  const current = new Date(start);
  if (start.getDay() === 1) current.setDate(current.getDate() + 1);
  current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
  while (current < end) {
    result += 1;
    current.setDate(current.getDate() + 7);
  }
  return result;
}

const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export function timeDiff(current: number, previous: number) {
  const sPerMinute = 60;
  const sPerHour = sPerMinute * 60;
  const sPerDay = sPerHour * 24;
  const sPerMonth = sPerDay * 30;

  const prevDate = new Date(previous * 1000);
  const elapsed = current - previous;

  if (elapsed < sPerMinute) {
    return "Just now";
  } else if (elapsed < sPerHour) {
    const rounded = Math.floor(elapsed / sPerMinute);
    return rounded + (rounded <= 1 ? " minute" : " minutes") + " ago";
  } else if (elapsed < sPerDay) {
    const rounded = Math.floor(elapsed / sPerHour);
    return rounded + (rounded <= 1 ? " hour" : " hours") + " ago";
  } else if (elapsed < sPerMonth) {
    const rounded = Math.floor(elapsed / sPerDay);
    const currentDate = new Date(current * 1000);

    const numberOfMondays = getMondaysBetweenDates(prevDate, currentDate);
    if (rounded < 7) return rounded + (rounded <= 1 ? " day" : " days") + " ago";
    else return numberOfMondays + (numberOfMondays === 1 ? " week" : " weeks") + " ago";
  } else {
    return months[prevDate.getMonth()] + " " + prevDate.getDate();
  }
}

export type Trend = {
  trend: "asc" | "desc" | "n/a";
  count: number;
};

export type Trends = {
  tokensMinted: Trend;
  activeUsers: Trend;
};

export type Stats = {
  tokensMinted: number;
  activeUsers: number;
  idps: number;
  services: number;
  clients: number;
};

export const calcInitialTrend = (count: number) => ({
  trend: count > 0 ? "asc" : "n/a",
  count: count || 0,
});

export const calcTrend = (field: string, count: number, trends: Trends) => {
  const trend = trends[field];
  return trend
    ? {
        trend: count === trend.count ? trend.trend : count > trend.count ? "asc" : "desc",
        count: count || 0,
      }
    : {
        trend: "n/a",
        count,
      };
};

export const handleTrends = (data: Stats, workspace: string) => {
  const key = "trends";
  const trends = getFromLocalStorage(key) || "{}";
  const trendsParsed = JSON.parse(trends);
  const workspaceTrends = trendsParsed[workspace];

  if (!workspaceTrends) {
    const t = {
      tokensMinted: calcInitialTrend(data.tokensMinted),
      activeUsers: calcInitialTrend(data.activeUsers),
    };

    setInLocalStorage(key, JSON.stringify({ ...trendsParsed, [workspace]: t }));
    return t;
  }

  const t = {
    tokensMinted: calcTrend("tokensMinted", data.tokensMinted, workspaceTrends),
    activeUsers: calcTrend("activeUsers", data.activeUsers, workspaceTrends),
  };

  setInLocalStorage(key, JSON.stringify({ ...trendsParsed, [workspace]: t }));
  return t;
};

export const workspaceTypeToCard: {
  [type: string]: {
    icon: any;
    flag: any;
    map: string;
    name: string;
    dialogTitle: string;
    infoCardText: string;
    withInfoModal: boolean;
    iconType?: "icon";
  };
} = {
  "openbanking-br": {
    icon: brazilIcon,
    flag: brazilFlag,
    map: brazilMap,
    name: "Open Finance Brazil",
    dialogTitle: "Open Finance Brazil",
    infoCardText: "Compliant",
    withInfoModal: true,
  },
  "openbanking-uk": {
    icon: ukIcon,
    flag: ukFlag,
    map: ukMap,
    name: "Open Banking UK",
    dialogTitle: "Open Banking UK",
    infoCardText: "Compliant",
    withInfoModal: true,
  },
  "openbanking-fdx": {
    icon: fdxIcon,
    flag: fdxSquareIcon,
    map: "",
    name: "Financial Data Exchange",
    dialogTitle: "Financial Data Exchange",
    infoCardText: "Compliant",
    withInfoModal: true,
  },
  "openbanking-cdr": {
    icon: cdrIcon,
    flag: australiaFlag,
    map: australiaMap,
    name: "CDR Australia",
    dialogTitle: "CDR Australia",
    infoCardText: "Compliant",
    withInfoModal: true,
  },
  "openbanking-generic": {
    icon: genericIcon,
    flag: genericSquareIcon,
    map: "",
    name: "Open Banking Generic",
    dialogTitle: "Open Banking Generic",
    infoCardText: "Compliant",
    withInfoModal: false,
  },
  generic: {
    icon: ExchangeIcon,
    flag: ExchangeIcon,
    map: "",
    name: "Status",
    dialogTitle: "",
    infoCardText: "Ready",
    withInfoModal: false,
    iconType: "icon",
  },
};
