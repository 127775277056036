import {
  BruteForceLimitsApi,
  BruteForceLimitsApiDeleteBruteForceLimitRequest,
  BruteForceLimitsApiListBruteForceLimitsRequest,
  BruteForceLimitsApiSetBruteForceLimitRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => BruteForceLimitsApi = adminApiClazz(BruteForceLimitsApi);

const adminBruteForceLimitsApi = {
  listBruteForceLimits: (req: BruteForceLimitsApiListBruteForceLimitsRequest) =>
    api().listBruteForceLimits(req),
  setBruteForceLimit: (req: BruteForceLimitsApiSetBruteForceLimitRequest) =>
    api().setBruteForceLimit(req),
  deleteBruteForceLimit: (req: BruteForceLimitsApiDeleteBruteForceLimitRequest) =>
    api().deleteBruteForceLimit(req),
};

export default adminBruteForceLimitsApi;
