import React from "react";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import cdrIcon from "../../../../assets/images/icons/welcome/cdr.svg";
import fdxIcon from "../../../../assets/images/icons/welcome/fdx.svg";
import opbrIcon from "../../../../assets/images/icons/welcome/flag-br-round.svg";
import opukIcon from "../../../../assets/images/icons/welcome/flag-uk-round.svg";
import saIcon from "../../../../assets/images/logos/sa/sa_rgb_mark_blk.svg";

const iconMapper = {
  "openbanking-br": opbrIcon,
  "openbanking-uk": opukIcon,
  "openbanking-cdr": cdrIcon,
  fdx: fdxIcon,
  default: saIcon,
};

const useStyles = makeStyles<{ disabled: boolean }>()((theme, { disabled }) => ({
  container: {
    marginRight: 14,
    marginTop: 22,
    position: "relative",
  },
  demoLabel: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    margin: 0,
    padding: "0 12px",
    position: "absolute",
    top: -16,
    right: 7,
    fontWeight: 600,
  },
  card: {
    border: `1px solid ${theme.custom.greys.border}`,
    borderRadius: 4,
    fontSize: 12,
    display: "flex",
    cursor: "default",
    backgroundColor: "white",
    padding: 8,
    paddingBottom: 2,
    minWidth: 210,
    ...(disabled ? { opacity: 0.5, pointerEvents: "none" } : {}),
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: 10,
  },
  right: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 600,
  },
  link: {
    ...(disabled ? { pointerEvents: "none" } : {}),
  },
}));

interface Props {
  id: "openbanking-br" | "openbanking-uk" | "openbanking-fdx" | "openbanking-cdr" | "default";
  title: string;
  onClick: () => void;
  type: "openbanking-br" | "openbanking-uk" | "fdx" | "openbanking-cdr" | "default";
  disabled: boolean;
}

const DemoWorkspaceCard = ({ id, title, onClick, type, disabled }: Props) => {
  const { classes } = useStyles({ disabled });
  const icon = iconMapper[type];

  return (
    <div className={classes.container}>
      <Typography className={classes.demoLabel} variant="textXSM">
        DEMO
      </Typography>
      <div className={classes.card} id={id + "-card"}>
        <img src={icon} alt="icon" className={classes.icon} />
        <div className={classes.right}>
          <Typography variant="textMD" className={classes.title}>
            {title}
          </Typography>
          <Link
            id={id + "-launch"}
            onClick={() => {
              if (!disabled) {
                onClick();
              }
            }}
            className={classes.link}
          >
            Launch
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DemoWorkspaceCard;
