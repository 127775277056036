import React from "react";

import { StepActions, StepContent, StepTitle } from "./StepComponents";

export type CardContentType = {
  step: string;
  title: string;
  description: string;
  withSkip?: boolean;
  isFinal?: boolean;
};

interface Props {
  totalCount: number;
  cardContent: CardContentType;
  onBack: () => void;
  onCompleteTour: () => void;
  onCompleteCurrentStep: () => void;
  onMore?: () => void;
  moreLabel?: string;
  isFinal?: boolean;
}

export default function PopoverStepCardContent({
  totalCount,
  cardContent,
  onBack,
  onCompleteTour,
  onCompleteCurrentStep,
  onMore,
  moreLabel,
  isFinal,
}: Props) {
  return (
    <>
      <StepTitle id={cardContent.step}>{cardContent.title}</StepTitle>
      <StepContent>{cardContent.description} </StepContent>
      <StepActions
        onPrev={!cardContent.withSkip ? onBack : undefined}
        onNext={isFinal ? onCompleteTour : onCompleteCurrentStep}
        onMore={onMore}
        moreLabel={moreLabel}
        nextLabel={isFinal ? "Finish" : "Next"}
        progressText={`${cardContent.step} of ${totalCount}`}
      />
    </>
  );
}
