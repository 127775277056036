import React, { useState } from "react";

import { AxiosResponse } from "axios";

import { ListUserSessions, UserSession } from "@cloudentity/acp-public";

import identitySelfApi from "../../services/identitySelfApi";
import Button from "../Button";
import ConfirmationDialog from "../ConfirmationDialog";
import { notifyErrorOrDefaultTo, notifySuccess } from "../notifications/notificationService";

interface Props {
  id: string;
  session: UserSession & { parser: UAParser.IResult };
  fetchSessions: (withSetter?: boolean) => Promise<AxiosResponse<ListUserSessions, any> | null>;
  fetchMfaSessions: (withSetter?: boolean) => Promise<AxiosResponse<ListUserSessions, any> | null>;
}

export default function SelfServiceRevokeMfaSession({
  id,
  session,
  fetchSessions,
  fetchMfaSessions,
}: Props) {
  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  function handleClose() {
    setDialog(false);
  }

  function handleRevokeSession() {
    setProgress(true);
    identitySelfApi
      .deleteMFASession(id)
      .then(handleClose)
      .then(() => notifySuccess("2FA Trust revoked"))
      .then(() => fetchSessions(true))
      .then(() => fetchMfaSessions(true))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to revoke 2FA Trust"))
      .finally(() => setProgress(false));
  }

  return (
    <>
      <Button
        size="small"
        color="error"
        style={{ marginTop: 8, fontSize: 12, background: "none" }}
        onClick={() => setDialog(true)}
      >
        Revoke 2FA Trust
      </Button>

      {dialog && (
        <ConfirmationDialog
          title="Revoke 2FA Trust"
          content={
            <>
              <div style={{ marginBottom: 16 }}>
                You're about to revoke 2FA Trust for {session.parser.browser.name}{" "}
                {session.parser.browser.version} ({session.parser.os.name}{" "}
                {session.parser.os.version})
              </div>
            </>
          }
          onCancel={handleClose}
          onConfirm={handleRevokeSession}
          progress={progress}
        />
      )}
    </>
  );
}
