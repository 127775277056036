import React, { lazy, Suspense, useCallback, useEffect } from "react";
import { Navigate, Routes, useLocation, useNavigate, useParams } from "react-router";
import { Route } from "react-router-dom";

import { ServerResponseProfileEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../common/api/paths";
import { useFeature } from "../../common/utils/hooks/useFeature";
import GetStartedMainUseCaseTour from "../GlobalTours/Tours/GetStartedMainUseCaseTour";
import TopBar from "../components/nav/TopBar";
import { useGetEnvironment, useGetWorkspaceEnvironment } from "../services/adminEnvironmentQuery";
import { useGetWorkspace } from "../services/adminServersQuery";
import AuthenticationSettings from "./authentication/AuthenticationSettings";
import AuthNContext from "./authnContext/AuthNContext";
import PageContainer from "./common/PageContainer";
import { useWorkspace } from "./common/useWorkspace";
import CustomApplicationsRoutes from "./customApplications/CustomApplicationsRoutes";
import GetStarted from "./getStartedV2/GetStarted";
import ManageAccessWorkspace from "./manageAccess/ManageAccessWorkspace";
import AdminNavDrawer from "./nav/AdminNavDrawer";
import DeveloperNavDrawer from "./nav/DeveloperNavDrawer";
import NavAndContentContainer from "./nav/NavAndContentContainer";
import OAuthClaims from "./oauthServer/OAuthClaims";
import QuickstartArticleController from "./quickstart/QuickstartArticleController";
import Settings from "./settings/Settings";
import IdentityPoolForView from "./workspaceDirectory/identityPools/identityPool/IdentityPoolForView";
import IdentityPoolUser from "./workspaceDirectory/identityPools/identityPool/users/user/IdentityPoolUser";
import {
  checkIfDemoWorkspace,
  getHasOpenBankingQuickstartView,
} from "./workspaceDirectory/server-profiles";
import {
  getRecentWorkspaces,
  removeFromRecentWorkspaces,
  setRecentWorkspace,
} from "./workspaces/WorkspaceSelector";

const WorkspaceOrganizations = lazy(() => import("./organizations/WorkspaceOrganizations"));
const WorkspaceAppearance = lazy(() => import("./appearance/WorkspaceAppearance"));
const OverviewController = lazy(() => import("./overview/OverviewController"));
const OpenbankingController = lazy(() => import("./openbanking/OpenbankingController"));
const AuditEventsController = lazy(() => import("./auditEvents/AuditEventsController"));
const AnalyticsController = lazy(() => import("./analytics/AnalyticsController"));
const ApplicationControllerForView = lazy(
  () => import("./applications/ApplicationControllerForView")
);
const WorkspaceIdentityPoolsController = lazy(
  () => import("./workspaceIdentityPools/WorkspaceIdentityPoolsController")
);
const ApplicationsController = lazy(() => import("./applications/ApplicationsController"));
const BuilderController = lazy(() => import("./applicationBuilder/BuilderController"));
const APIsController = lazy(() => import("./apis/APIsController"));
const SystemTenantAPIsController = lazy(() => import("./apis/SystemTenantAPIsController"));
const GatewayControllerForView = lazy(() => import("./gateways/GatewayControllerForView"));
const IdentitiesControllerTabbed = lazy(
  () => import("./identities/identities_view_tabbed/IdentitiesController")
);
const IdentitiesDetailsControllerForView = lazy(
  () => import("./identities/IdentitiesDetailsControllerForView")
);
const Policies = lazy(() => import("./policies/Policies"));
const PoliciesEdit = lazy(() => import("./policies/PoliciesEdit"));
const ServiceControllerForView = lazy(() => import("./services/ServiceControllerForView"));
const ServicesController = lazy(() => import("./services/ServicesController"));
const GatewaysController = lazy(() => import("./gateways/GatewaysController"));
const OAuthAuthorization = lazy(() => import("./oauthServer/OAuthAuthorization"));
const OAuthSettings = lazy(() => import("./oauthServer/OAuthSettings"));
const OAuthTokens = lazy(() => import("./oauthServer/OAuthTokens"));
const WebhooksRoutes = lazy(() => import("./webhooks/WebhooksRoutes"));
const OAuthConsent = lazy(() => import("./oauthServer/OAuthConsent"));
const OAuthSecretsTabController = lazy(() => import("./oauthServer/OAuthSecretsTabController"));
const OAuthAdvanced = lazy(() => import("./oauthServer/OAuthAdvanced"));
const OAuthMetadata = lazy(() => import("./oauthServer/OAuthMetadata"));
const VisualizerController = lazy(() => import("./visualizer/VisualizerController"));
const TenantsManagementController = lazy(
  () => import("./tenantManagement/TenantsManagementController")
);
const TenantManagementController = lazy(
  () => import("./tenantManagement/TenantManagementController")
);
const SystemFeaturesController = lazy(() => import("./systemFeatures/SystemFeaturesController"));
const ExtensionsController = lazy(() => import("./extensions/ExtensionsController"));
const QuickstartController = lazy(() => import("./quickstart/QuickstartController"));

export function isRouteAvailableForWorkspace(
  type: string,
  systemWorkspaceAccess: boolean,
  adminWorkspaceAccess: boolean
) {
  return (
    (type === "system" && systemWorkspaceAccess) ||
    (type === "admin" && adminWorkspaceAccess) ||
    (type !== "system" && type !== "admin")
  );
}

export const isSystemTenantAdminWorkspace = (tenantId: string, workspace: string) =>
  tenantId === "system" && workspace === "admin";

export const shouldRenderMobileLikeNav = pathname => {
  const isVisualizerView = pathname.endsWith("/visualizer");
  const isExtensionsView = pathname.endsWith("/extensions") && !pathname.includes("/identities/");
  return isVisualizerView || isExtensionsView;
};

export default function AdminPanel() {
  const location = useLocation();
  const navigate = useNavigate();

  const { workspaceId: workspaceIdFromUrl } = useParams<{ workspaceId: string }>();
  const [workspace] = useWorkspace();
  const getAdminWorkspaceEnvironmentQuery = useGetWorkspaceEnvironment("admin");
  const getEnvironmentQuery = useGetEnvironment();
  const tenantId = getTenantId();

  const isWithRolesEnabled = useFeature("with_roles");
  const isNewOnboardingEnabled = useFeature("new_onboarding");

  const getWorkspaceQuery = useGetWorkspace(workspace, { enabled: !!workspace });
  const workspaceObj = getWorkspaceQuery.data;

  const withAnalytics = useFeature("with_analytics");

  const hasOpenBankingQuickstartView = getHasOpenBankingQuickstartView(
    workspaceObj?.profile,
    workspaceObj?.id ?? ""
  );

  const isDemoWorkspace = checkIfDemoWorkspace(workspace, workspaceObj?.profile);
  const isNewGettingStartedView =
    isNewOnboardingEnabled && isDemoWorkspace && location.pathname.includes("/get-started-v2/");

  const onPoliciesEditBack = useCallback(
    () => navigate(`/${workspace}/policies`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workspace]
  );

  useEffect(() => {
    if (
      (!hasOpenBankingQuickstartView &&
        !getWorkspaceQuery.isLoading &&
        location.pathname.endsWith(`/${workspace}/openbanking`)) ||
      (!isDemoWorkspace &&
        !getWorkspaceQuery.isLoading &&
        location.pathname.includes("/get-started-v2/")) ||
      (!isDemoWorkspace &&
        !getWorkspaceQuery.isLoading &&
        location.pathname.endsWith("/quickstart") &&
        !location.pathname.includes("/gateways/"))
    ) {
      navigate(`/${workspace}/overview`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasOpenBankingQuickstartView,
    getWorkspaceQuery.isLoading,
    location.pathname,
    workspace,
    workspaceObj,
  ]);

  useEffect(() => {
    const serverType = workspaceObj?.type;
    if (serverType) {
      const recentWorkspaces = getRecentWorkspaces(
        workspaceObj?.profile !== ServerResponseProfileEnum.Consumer ? ["default", "demo"] : []
      );
      setRecentWorkspace(workspace, recentWorkspaces);
    }
  }, [workspace, tenantId, workspaceObj]);

  useEffect(() => {
    if (getWorkspaceQuery.isError) {
      removeFromRecentWorkspaces(workspace);
      navigate("/workspace-directory", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWorkspaceQuery.isError]);

  return (
    <PageContainer
      progress={
        getWorkspaceQuery.isLoading ||
        getAdminWorkspaceEnvironmentQuery.isLoading ||
        getEnvironmentQuery.isLoading
      }
    >
      <TopBar adminPanel />
      <NavAndContentContainer
        nav={getWorkspaceQuery.data?.type === "developer" ? DeveloperNavDrawer : AdminNavDrawer}
        disabled={isNewGettingStartedView}
      >
        <Suspense fallback={<PageContainer progress />}>
          <Routes>
            {tenantId === "system" ||
            isRouteAvailableForWorkspace(
              workspaceIdFromUrl!,
              !!getEnvironmentQuery.data?.system_workspace_access,
              !!getAdminWorkspaceEnvironmentQuery.data?.admin_workspace_access
            ) ? (
              <>
                <Route path="/authentication/identities" element={<IdentitiesControllerTabbed />} />
                <Route
                  path="/authentication/identities/:type/:id/:tab"
                  element={<IdentitiesDetailsControllerForView />}
                />
                <Route path="/authentication/settings/:tab" element={<AuthenticationSettings />} />
                <Route
                  path="/authentication/sessions-and-context/:tab"
                  element={<AuthenticationSettings />}
                />
                <Route path="/identities" element={<IdentitiesControllerTabbed />} />
                <Route
                  path="/identities/:type/:id/:tab"
                  element={<IdentitiesDetailsControllerForView />}
                />
                <Route path="/pools" element={<WorkspaceIdentityPoolsController />} />
                <Route path="/pools/:id/:tab" element={<IdentityPoolForView />} />
                <Route
                  path="/pools/:id/users/:userID"
                  element={<IdentityPoolUser isWorkspacePool />}
                />
                <Route path="/services" element={<ServicesController />} />
                <Route path="/services/:id/:tab" element={<ServiceControllerForView />} />
                <Route path="/gateways" element={<GatewaysController />} />
                <Route path="/gateways/:id/:tab" element={<GatewayControllerForView />} />
                <Route
                  path="/apis/:tab"
                  element={
                    getTenantId() === "system" ? <SystemTenantAPIsController /> : <APIsController />
                  }
                />
                <Route path="/application-builder" element={<BuilderController />} />
                <Route path="/applications" element={<ApplicationsController />} />
                <Route path="/applications/:id/:tab" element={<ApplicationControllerForView />} />

                {isNewOnboardingEnabled && isDemoWorkspace ? (
                  <Route path="/overview" element={<BuilderController />} />
                ) : (
                  <Route path="/overview" element={<OverviewController />} />
                )}
                {withAnalytics && (
                  <Route path="/analytics/:tab" element={<AnalyticsController />} />
                )}
                {hasOpenBankingQuickstartView && (
                  <Route path="/openbanking" element={<OpenbankingController />} />
                )}
                <Route path="/policies" element={<Policies />} />
                <Route
                  path="/policies/:id"
                  element={<PoliciesEdit workspace={workspace} onBack={onPoliciesEditBack} />}
                />
                <Route path="/extensions" element={<ExtensionsController />} />
                <Route path="/secrets" element={<OAuthSecretsTabController />} />
                <Route path="/custom-applications/*" element={<CustomApplicationsRoutes />} />
                <Route path="/webhooks/*" element={<WebhooksRoutes />} />
                {isWithRolesEnabled && (
                  <Route path="/manage-access" element={<ManageAccessWorkspace />} />
                )}
                <Route path="/audit-events" element={<AuditEventsController />} />
                <Route path="/workspaces/oauth/:tab" element={<OAuthSettings />} />
                <Route path="/workspaces/tokens/claims" element={<OAuthClaims />} />
                <Route path="/workspaces/tokens/:tab" element={<OAuthTokens />} />
                <Route path="/workspaces/consent" element={<OAuthConsent />} />
                <Route path="/workspaces/authorization" element={<OAuthAuthorization />} />
                <Route path="/workspaces/authorization/:tab" element={<OAuthAuthorization />} />
                <Route path="/workspaces/authentication-context" element={<AuthNContext />} />
                <Route path="/workspaces/metadata" element={<OAuthMetadata />} />
                <Route path="/workspaces/advanced" element={<OAuthAdvanced />} />
                <Route path="/settings/:tab" element={<Settings />} />
                <Route path="/appearance" element={<WorkspaceAppearance workspace={workspace} />} />
                <Route path="/organizations" element={<WorkspaceOrganizations />} />
                <Route path="/organizations/:tab" element={<WorkspaceOrganizations />} />
                <Route path="/visualizer" element={<VisualizerController />} />
                {isSystemTenantAdminWorkspace(tenantId, workspace) && (
                  <>
                    <Route path="/tenant-management" element={<TenantsManagementController />} />
                    <Route
                      path="/tenant-management/:id/:tab"
                      element={<TenantManagementController />}
                    />
                    <Route path="/features" element={<SystemFeaturesController />} />
                  </>
                )}
                {isNewOnboardingEnabled && isDemoWorkspace && (
                  <>
                    <Route path="/get-started-v2" element={<GetStarted />} />
                    <Route path="/get-started-v2/:step" element={<GetStarted />} />
                    <Route path="/quickstart" element={<QuickstartController />} />
                    <Route path="/quickstart/:id" element={<QuickstartArticleController />} />
                  </>
                )}
              </>
            ) : null}
            <Route
              path="*"
              element={
                getEnvironmentQuery.isSuccess && getAdminWorkspaceEnvironmentQuery.isSuccess ? (
                  <Navigate to="/workspace-directory" replace />
                ) : null
              }
            />
          </Routes>
        </Suspense>
      </NavAndContentContainer>
      {isNewOnboardingEnabled && isDemoWorkspace && <GetStartedMainUseCaseTour />}
      {/* <InfoBanner workspaceProfile={workspaceObj?.profile} /> */}
    </PageContainer>
  );
}
