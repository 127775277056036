import React from "react";

import { ServerResponse } from "@cloudentity/acp-admin";

import { IdpType } from "../../../services/adminIDPsApi";
import { useWorkspace } from "../../common/useWorkspace";
import IdentitiesConfigurationAuth0 from "../IdentitiesConfigurationAuth0";
import IdentitiesConfigurationAzure from "../IdentitiesConfigurationAzure";
import IdentitiesConfigurationAzureB2C from "../IdentitiesConfigurationAzureB2C";
import IdentitiesConfigurationCognito from "../IdentitiesConfigurationCognito";
import IdentitiesConfigurationCustom from "../IdentitiesConfigurationCustom";
import IdentitiesConfigurationExternal from "../IdentitiesConfigurationExternal";
import IdentitiesConfigurationGithub from "../IdentitiesConfigurationGithub";
import IdentitiesConfigurationGoogle from "../IdentitiesConfigurationGoogle";
import IdentitiesConfigurationEnsure from "../IdentitiesConfigurationIntelliTrust";
import IdentitiesConfigurationOIDC from "../IdentitiesConfigurationOIDC";
import IdentitiesConfigurationOkta from "../IdentitiesConfigurationOkta";
import IdentitiesConfigurationSamlV2 from "../IdentitiesConfigurationSamlV2";
import IdentitiesConfigurationStatic from "../IdentitiesConfigurationStatic";
import { IdpUiModelType, ProviderMapperType } from "../identities.utils";
import IdentitiesConfigurationIdentityPool from "../identityPools/IdentitiesConfigurationIdentityPool";
import IdentitiesConfigurationOrganization from "../organization/IdentitiesConfigurationOrganization";

interface Props {
  data: ProviderMapperType;
  server: ServerResponse | undefined;
  onLogoEdit?: (data: IdpUiModelType) => void;
  errors: any;
  onCancel: () => void;
  onSubmit: (type: IdpType, provider: IdpUiModelType) => Promise<any>;
  onResetErrors: (err: any) => void;
  progress?: boolean;
}

export default function IdentitiesCreateConfigurationStep({
  data,
  server,
  onLogoEdit,
  errors,
  onCancel,
  onSubmit,
  onResetErrors,
  progress,
}: Props) {
  const [workspace] = useWorkspace();

  return (
    <>
      {data.method === "azure" && (
        <IdentitiesConfigurationAzure
          provider={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("azure", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "azureb2c" && (
        <IdentitiesConfigurationAzureB2C
          provider={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("azureb2c", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "cognito" && (
        <IdentitiesConfigurationCognito
          provider={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("cognito", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "github" && (
        <IdentitiesConfigurationGithub
          provider={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("github", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "oidc" && (
        <IdentitiesConfigurationOIDC
          provider={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("oidc", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "saml_v2" && (
        <IdentitiesConfigurationSamlV2
          provider={data.body}
          server={server}
          errors={errors}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("saml_v2", data)}
          onCancel={onCancel}
          onResetErrors={onResetErrors}
          updateProgress={progress}
        />
      )}
      {data.method === "intelli_trust" && (
        <IdentitiesConfigurationEnsure
          provider={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("intelli_trust", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "okta" && (
        <IdentitiesConfigurationOkta
          provider={data.body}
          server={server}
          errors={errors}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("okta", data)}
          onCancel={onCancel}
          onResetErrors={onResetErrors}
          updateProgress={progress}
        />
      )}
      {data.method === "auth0" && (
        <IdentitiesConfigurationAuth0
          provider={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("auth0", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "static" && (
        <IdentitiesConfigurationStatic
          idp={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("static", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "cloudentity" && (
        <IdentitiesConfigurationCustom
          idp={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("custom", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "custom" && (
        <IdentitiesConfigurationCustom
          idp={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("custom", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "external" && (
        <IdentitiesConfigurationExternal
          idp={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("external", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "google" && (
        <IdentitiesConfigurationGoogle
          provider={data.body}
          server={server}
          onLogoEdit={onLogoEdit}
          onSubmit={data => onSubmit("google", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
      {data.method === "identity_pool" && (
        <IdentitiesConfigurationIdentityPool
          provider={data.body}
          server={server}
          onSubmit={data => onSubmit("identity_pool", data)}
          onCancel={onCancel}
          updateProgress={progress}
          workspaceId={workspace}
          workspaceIdForNewPool={workspace}
        />
      )}
      {data.method === "organization" && (
        <IdentitiesConfigurationOrganization
          provider={data.body}
          server={server}
          onSubmit={data => onSubmit("organization", data)}
          onCancel={onCancel}
          updateProgress={progress}
        />
      )}
    </>
  );
}
