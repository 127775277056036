import React from "react";

import Grid from "@mui/material/Grid";

import { FederatedAccount } from "@cloudentity/acp-identityself";

import { providers } from "../../../admin/components/identities/identities.utils";
import InfoField from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/InfoField";
import FormSection from "../FormSection";

interface Props {
  federatedAccounts: FederatedAccount[];
}
export default function SelfServiceCredentialsLinkedAccounts({ federatedAccounts }: Props) {
  return federatedAccounts.length > 0 ? (
    <>
      <FormSection title="Linked accounts" />

      {federatedAccounts.map(account => {
        const provider = providers.find(
          provider => provider.method === account.federated_idp_method
        );

        return (
          <div style={{ marginTop: 16 }} key={account.identifier_id}>
            <Grid container spacing={2}>
              {provider && (
                <Grid item lg={1} xs={2} style={{ display: "flex", alignItems: "center" }}>
                  <img src={provider.icon} style={{ width: "100%", maxWidth: 32 }} alt="icon" />
                </Grid>
              )}
              <Grid item lg={6} xs={6}>
                <InfoField
                  id="idp-name"
                  label="Provider name"
                  value={account.federated_idp_name}
                  noMarginTop
                />
              </Grid>
              <Grid item lg={5} xs={4}>
                <InfoField
                  id="idp-type"
                  label="Provider type"
                  value={provider?.name || account.federated_idp_method}
                  noMarginTop
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
    </>
  ) : null;
}
