import React from "react";

import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";

import { useCheckPoolPermissions } from "../../../../services/adminIdentityPoolsQuery";

const useStyles = makeStyles()(theme => ({
  root: {
    maxWidth: "100%",
    position: "relative",
  },
  menu: {
    boxShadow: theme.custom.shadows.menu,
  },
}));

interface Props {
  pool: PoolResponse;
  anchorEl: () => HTMLElement;
  handleClose: () => void;
  handleGoToTab: (id: string, tab: string, section?: string) => void;
  onDelete?: (pool: PoolResponse) => void;
}

function IdentityPoolMenu({ pool, anchorEl, handleClose, handleGoToTab, onDelete }: Props) {
  const { classes } = useStyles();
  const { id } = pool;

  const checkPoolPermissionsQuery = useCheckPoolPermissions(id!);

  return (
    <Menu
      id={`${id}-card-main-menu`}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      classes={{ paper: classes.menu }}
    >
      {checkPoolPermissionsQuery.data?.read_identity_pool_users && (
        <MenuItem
          id={`${id}-manage-users-pool`}
          onClick={() => {
            handleGoToTab(pool.id ?? "", "users");
            handleClose();
          }}
          key="manage-users"
        >
          Manage Users
        </MenuItem>
      )}

      <MenuItem
        id={`${id}-manage-schemas-pool`}
        onClick={() => {
          handleGoToTab(pool.id ?? "", "schemas");
          handleClose();
        }}
        key="manage-schemas"
      >
        Manage Schemas
      </MenuItem>

      {onDelete && (
        <MenuItem
          id={`${id}-delete-pool`}
          onClick={() => {
            onDelete(pool);
            handleClose();
          }}
          key="delete"
        >
          Delete
        </MenuItem>
      )}

      <Divider style={{ margin: "8px 0" }} key="settings-divider" />

      <MenuItem disabled style={{ fontSize: 12, fontWeight: 500 }} key="edit-settings">
        Edit Settings
      </MenuItem>
      <MenuItem
        id={`${id}-manage-sign-in-settings-pool`}
        onClick={() => {
          handleGoToTab(pool.id ?? "", "signin-and-signup", "sign-in");
          handleClose();
        }}
        key="manage-sign-in"
      >
        Sign-in
      </MenuItem>
      <MenuItem
        id={`${id}-manage-sign-up-settings-pool`}
        onClick={() => {
          handleGoToTab(pool.id ?? "", "signin-and-signup", "sign-up");
          handleClose();
        }}
        key="manage-sign-up"
      >
        Sign-up
      </MenuItem>
      <MenuItem
        id={`${id}-manage-password-settings-pool`}
        onClick={() => {
          handleGoToTab(pool.id ?? "", "signin-and-signup", "password");
          handleClose();
        }}
        key="manage-password"
      >
        Password Policy
      </MenuItem>
      <MenuItem
        id={`${id}-manage-password-general-settings-pool`}
        onClick={() => {
          handleGoToTab(pool.id ?? "", "signin-and-signup", "password-settings");
          handleClose();
        }}
        key="manage-password-settings"
      >
        Password Settings
      </MenuItem>
      <MenuItem
        id={`${id}-manage-otp-settings-pool`}
        onClick={() => {
          handleGoToTab(pool.id ?? "", "signin-and-signup", "otp");
          handleClose();
        }}
        key="manage-otp"
      >
        Verification Code
      </MenuItem>
    </Menu>
  );
}

export default IdentityPoolMenu;
