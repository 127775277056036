import { BaseUserWithData, UserWithData } from "@cloudentity/acp-identity";

import { getSubjectValue } from "../../../../common/utils/subject.utils";
import { useGetWorkspace } from "../../../services/adminServersQuery";
import { useGetUserInfo } from "../../../services/oauth2Query";

export const useIsCurrentLoggedInUser = (
  user: UserWithData | BaseUserWithData | undefined,
  workspaceId: string
) => {
  const userInfoQuery = useGetUserInfo();
  const getWorkspaceQuery = useGetWorkspace(workspaceId);

  const userHash = getSubjectValue(
    user?.id || "",
    userInfoQuery.data?.idp || "",
    getWorkspaceQuery.data
  );

  if (!user) {
    return false;
  }

  return userHash === userInfoQuery.data?.sub;
};
