import React, { CSSProperties } from "react";

import Chip, { ChipProps } from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { ExtendedUserStatusEnum, UserWithDataStatusEnum } from "@cloudentity/acp-identity";

const useStyles = makeStyles()(theme => ({
  chip: {
    borderRadius: 4,
    marginRight: 6,
    textTransform: "capitalize",
    minWidth: 66,
  },
  label: {
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
  },
  blue: {
    backgroundColor: "#F0F7FF",
    border: `solid 1px #008EFF`,
    color: "#008EFF",
  },
  green: {
    backgroundColor: "#ECFDF5",
    border: `solid 1px #10B981`,
    color: "#10B981",
  },
  red: {
    backgroundColor: "#FFE3E6",
    border: `solid 1px ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },
  gray: {
    backgroundColor: theme.palette.background.default,
    border: `solid 1px #CCCFE4`,
    color: "#CCCFE4",
  },
  dot: {
    width: 4,
    height: 4,
    marginRight: 8,
    borderWidth: 2,
    borderRadius: 3,
  },
}));

const colorMapper: { [status in StatusEnum]: string } = {
  [ExtendedUserStatusEnum.Active]: "green",
  [ExtendedUserStatusEnum.Deleted]: "red",
  [ExtendedUserStatusEnum.Inactive]: "gray",
  [ExtendedUserStatusEnum.New]: "blue",
};

type StatusEnum = ExtendedUserStatusEnum | UserWithDataStatusEnum | string;

interface Props {
  status?: StatusEnum;
  label?: string;
  size?: ChipProps["size"];
  style?: CSSProperties;
  id?: string;
}

function StatusChip({ status, label, size = "small", style = {}, id }: Props) {
  const { cx, classes } = useStyles();

  if (!status) {
    return null;
  }

  return (
    <Chip
      variant="outlined"
      size={size}
      className={cx(classes.chip, classes[colorMapper[status]])}
      label={
        <Typography variant="textSM" className={classes.label}>
          <div className={cx(classes[colorMapper[status]], classes.dot)} />
          {label || status}
        </Typography>
      }
      style={style}
      id={id}
    />
  );
}

export default StatusChip;
