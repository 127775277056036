import React from "react";

import Typography from "@mui/material/Typography";

interface Props {
  text: string;
  style?: React.CSSProperties;
}

export default function RowEmptyState({ text, style = {} }: Props) {
  return (
    <div
      id="table-row-empty"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        padding: "16px 24px",
        width: "100%",
        ...style,
      }}
    >
      <Typography variant="body2">{text}</Typography>
    </div>
  );
}
