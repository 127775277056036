import React, { ReactNode } from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import Tooltip from "../../../../../common/Tooltip";

const useStyles = makeStyles()(theme => ({
  fieldContainer: {
    marginTop: 24,
    color: theme.palette.secondary.light,
  },
  fieldLabel: {
    textTransform: "uppercase",
    color: "#9EA1B7",
  },
  fieldValue: {
    marginTop: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: 8,
  },
  fieldValueWarning: {
    color: theme.palette.error.main,
  },
}));

interface Props {
  id: string;
  label: string;
  value: string | ReactNode | null | undefined;
  isWarning?: boolean;
  warningText?: string;
  noMarginTop?: boolean;
}

const InfoField = ({ id, label, value, isWarning = false, warningText, noMarginTop }: Props) => {
  const { cx, classes } = useStyles();

  return (
    <div className={classes.fieldContainer} style={noMarginTop ? { marginTop: 0 } : {}}>
      <Typography variant="textXSM" component="label" htmlFor={id} className={classes.fieldLabel}>
        {label}
      </Typography>
      <Typography
        variant="textMD"
        component="div"
        id={id}
        className={cx(classes.fieldValue, isWarning && classes.fieldValueWarning)}
      >
        <Tooltip title={warningText}>
          <span>{value}</span>
        </Tooltip>
      </Typography>
    </div>
  );
};

export default InfoField;
