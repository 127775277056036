import React, { ReactNode, useCallback, useEffect, useState } from "react";

import AccountTree from "@mui/icons-material/AccountTree";
import ViewStream from "@mui/icons-material/ViewStream";
import IconButton from "@mui/material/IconButton";
import debounce from "lodash/debounce";
import { Plus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import Fab from "../../../../common/components/Fab";
import { setInLocalStorage } from "../../../../common/utils/localStorage.utils";
import SearchInput from "../../common/SearchInput";
import { GridMode } from "./utils";

const useStyles = makeStyles()(theme => ({
  toolBar: {
    marginBottom: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 48,
  },
  fabButton: {
    marginLeft: 16,
    textTransform: "uppercase",
  },
  button: {
    marginRight: 8,
  },
  icon: {
    fontSize: 24,
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
}));

export interface OrganizationsToolbarProps {
  createButtonLabel: string;
  searchText: string;
  onUpdate?: (searchText: string) => void;
  onCreate?: () => void;
  searchDisabled?: boolean;
  customButton?: ReactNode;
  gridMode?: GridMode;
  setGridMode?: (mode: GridMode) => void;
  gridModeLocalStorageKey?: string;
}

function OrganizationsToolbar({
  createButtonLabel,
  onUpdate,
  onCreate,
  searchText: upperSearchText,
  searchDisabled = false,
  customButton,
  gridMode,
  setGridMode,
  gridModeLocalStorageKey,
}: OrganizationsToolbarProps) {
  const { cx, classes } = useStyles();
  const [searchText, setSearchText] = useState(upperSearchText);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = useCallback(
    debounce((searchText: string) => {
      if (onUpdate) {
        onUpdate(searchText);
      }
    }, 500),
    [onUpdate]
  );

  const handleChangeSearchText = (newSearchText: string) => {
    setSearchText(newSearchText);
    handleUpdate(newSearchText);
  };

  const handleClearSearchText = () => {
    setSearchText("");
    handleUpdate("");
  };

  const handleGridModeChange = (mode: GridMode) => {
    if (!setGridMode || !gridModeLocalStorageKey) return;
    setGridMode(mode);
    setInLocalStorage(gridModeLocalStorageKey, mode);
  };

  useEffect(() => {
    setSearchText(upperSearchText);
  }, [upperSearchText]);

  return (
    <div>
      <div className={classes.toolBar}>
        <div>
          {onUpdate && (
            <SearchInput
              value={searchText}
              placeholder="Search"
              onSearch={handleChangeSearchText}
              onClear={handleClearSearchText}
              disabled={searchDisabled}
            />
          )}
        </div>

        <div>
          {gridMode && setGridMode && (
            <>
              <IconButton
                className={classes.button}
                onClick={() => handleGridModeChange("cards")}
                aria-label="Cards mode button"
                id="grid-mode-button_cards"
              >
                <ViewStream
                  className={cx(classes.icon, gridMode === "cards" && classes.activeIcon)}
                />
              </IconButton>
              <IconButton
                className={classes.button}
                onClick={() => handleGridModeChange("hierarchy")}
                aria-label="Hierarchy mode button"
                id="grid-mode-button_hierarchy"
              >
                <AccountTree
                  className={cx(classes.icon, gridMode === "hierarchy" && classes.activeIcon)}
                />
              </IconButton>
            </>
          )}

          {onCreate && (
            <Fab
              onClick={onCreate}
              startIcon={Plus}
              id="create-new-workspace-button"
              className={classes.fabButton}
            >
              {createButtonLabel}
            </Fab>
          )}
        </div>

        {customButton}
      </div>
    </div>
  );
}

export default OrganizationsToolbar;
