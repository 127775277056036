import React from "react";

import { RecentActivity, ServerResponse } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import { useGetRecentActivities } from "../../services/adminStatsQuery";
import { useWorkspace } from "../common/useWorkspace";
import RecentActivitiesEmptyState from "./RecentActivitiesEmptyState";
import RecentActivityItem from "./RecentActivityItem";

export const RECENT_ACTIVITY_LIST_ID = "recent-activity-list";

interface Props {
  authorizationServer: ServerResponse | undefined;
}

export default function RecentActivitiesListForDashboard({ authorizationServer }: Props) {
  const [workspace] = useWorkspace();

  const getRecentActivitiesQuery = useGetRecentActivities(getTenantId(), workspace);
  const activities = getRecentActivitiesQuery.data ?? [];
  if (activities.length === 0) {
    return <RecentActivitiesEmptyState />;
  }

  return <RecentActivitiesList activities={activities} authorizationServer={authorizationServer} />;
}

export function RecentActivitiesList({
  authorizationServer,
  activities,
}: Props & { activities: RecentActivity[] }) {
  if (activities.length === 0) {
    return null;
  }

  return (
    <div data-testid={RECENT_ACTIVITY_LIST_ID} id={RECENT_ACTIVITY_LIST_ID}>
      {activities
        .sort((a, b) =>
          new Date(a.date as string).getTime() < new Date(b.date as string).getTime() ? 1 : -1
        )
        .map(v => (
          <RecentActivityItem activity={v} authorizationServer={authorizationServer} key={v.id} />
        ))}
    </div>
  );
}
