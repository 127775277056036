import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import SelectField from "../../../common/utils/forms/SelectField";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../common/utils/forms/validation";
import adminCustomAppsApi from "../../services/adminCustomAppsApi";
import { listServerCustomAppsQueryKey } from "../../services/adminCustomAppsQuery";
import { useWorkspace } from "../common/useWorkspace";
import { customAppTypesOptions } from "./custom-applications.common";

interface Props {
  onCreated: () => void;
  onCancel: () => void;
}

const initialFormData = {
  type: customAppTypesOptions[0].value,
  url: "https://",
};

const AddCustomApplicationDialog = ({ onCreated, onCancel }: Props) => {
  const [workspace] = useWorkspace();
  const [createProgress, setCreateProgress] = useState(false);

  const form = useForm({
    id: "create-custom-application",
    initialValues: initialFormData,
    progress: createProgress,
  });

  const queryClient = useQueryClient();

  const handleSubmit = data => {
    setCreateProgress(true);
    return adminCustomAppsApi
      .createCustomApp({
        wid: workspace,
        customApp: {
          id: "",
          server_id: workspace,
          tenant_id: getTenantId(),
          name: data.name,
          url: data.url,
          type: data.type,
        },
      })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listServerCustomAppsQueryKey(getTenantId(), workspace),
        })
      )
      .then(onCreated)
      .catch(handleErrorWithNotify("Error occurred while trying to create custom application"))
      .finally(() => {
        setCreateProgress(false);
      });
  };

  return (
    <Dialog
      onClose={onCancel}
      id="create-custom-application-dialog"
      title="Create Custom Application"
    >
      <Form form={form}>
        <SelectField label="Type" name="type" options={customAppTypesOptions} optional={false} />

        <TextFieldRequired label="Name" name="name" />

        <TextFieldRequired
          label="Application URL"
          name="url"
          rules={{
            validate: { validHttpsURL: validators.validHttpsURL({ label: "Application URL" }) },
          }}
        />

        <FormFooter onCancel={onCancel} onSubmit={handleSubmit} submitText="Create" />
      </Form>
    </Dialog>
  );
};

export default AddCustomApplicationDialog;
