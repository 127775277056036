import React from "react";

import Grid from "@mui/material/Grid";
import differenceInDays from "date-fns/differenceInDays";

import { BaseUserWithData, UserCredentialTypeEnum, UserWithData } from "@cloudentity/acp-identity";

import { useGetPool } from "../../../../../../../services/adminIdentityPoolsQuery";
import InfoField from "../InfoField";
import { getDateTime } from "../utils";
import PasswordStatus, { countExpiresDate } from "./PasswordStatus";

interface Props {
  user: UserWithData | BaseUserWithData;
  poolId: string;
}

export default function IdentityPoolUserSidePanelPasswordDetails({ user, poolId }: Props) {
  const poolQuery = useGetPool(poolId);
  const expiresAfter = poolQuery.data?.password_settings?.expires_after;

  const password = user.credentials?.filter(c => c.type === UserCredentialTypeEnum.Password)?.at(0);

  const passwordUpdatedAt = password?.updated_at;
  const passwordExpiresAt = password?.expires_at;

  const expiresDate = countExpiresDate(passwordExpiresAt, passwordUpdatedAt, expiresAfter);

  const passwordExpiresSoon =
    expiresDate && expiresDate !== "N/A" && expiresDate !== "Never"
      ? differenceInDays(new Date(expiresDate), new Date()) < 7
      : false;

  return (
    <>
      <PasswordStatus password={password} />

      {passwordUpdatedAt && (
        <Grid item xs={6}>
          <InfoField
            id="field-password-updated"
            label="Last updated"
            value={getDateTime(passwordUpdatedAt)}
            noMarginTop
          />
        </Grid>
      )}

      {passwordExpiresAt && (
        <Grid item xs={6}>
          <InfoField
            id="field-password-expires"
            label="Expires"
            value={expiresDate}
            isWarning={passwordExpiresSoon}
            warningText={passwordExpiresSoon ? "Password expired or expires soon" : undefined}
            noMarginTop
          />
        </Grid>
      )}
    </>
  );
}
