import React, { useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Theme } from "@cloudentity/acp-admin";

import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import BindToWorkspaceDialog from "./BindToWorkspaceDialog";

export type ThemeMenuType = {
  anchorEl: HTMLElement;
  theme: Theme;
};

interface Props {
  themeMenu: ThemeMenuType;
  handleClose: () => void;
  onEditHTML: (theme: Theme) => void;
  onEditTheme: (theme: Theme) => void;
  onDuplicateTheme: (theme: Theme) => void;
  onDeleteClick: () => void;
}

export default function ThemesMenu({
  themeMenu,
  handleClose,
  onEditHTML,
  onEditTheme,
  onDuplicateTheme,
  onDeleteClick,
}: Props) {
  const [bindToWorkspaceDialog, setBindToWorkspaceDialog] = useState(false);

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  return (
    <>
      <Menu
        id="row-main-menu"
        anchorEl={themeMenu.anchorEl}
        keepMounted
        open={Boolean(themeMenu)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {(checkTenantPermissionsQuery.data?.get_tenant || themeMenu.theme.id !== "default") &&
          checkTenantPermissionsQuery.data?.read_themes && (
            <MenuItem
              id={`${themeMenu.theme.id}-open-theme`}
              onClick={() => {
                onEditHTML(themeMenu.theme);
                handleClose();
              }}
            >
              Open
            </MenuItem>
          )}

        {checkTenantPermissionsQuery.data?.manage_themes && (
          <MenuItem
            id={`${themeMenu.theme.id}-theme-bind-to-workspace`}
            onClick={() => setBindToWorkspaceDialog(true)}
            style={{ display: "flex", justifyContent: "space-between", minWidth: 200 }}
          >
            Bind to Workspace
          </MenuItem>
        )}

        {checkTenantPermissionsQuery.data?.manage_themes && themeMenu.theme.id !== "default" && (
          <MenuItem
            id={`${themeMenu.theme.id}-theme-duplicate`}
            onClick={() => onDuplicateTheme(themeMenu.theme)}
          >
            Duplicate Theme
          </MenuItem>
        )}
        {checkTenantPermissionsQuery.data?.manage_themes && themeMenu.theme.id !== "default" && (
          <MenuItem
            id={`${themeMenu.theme.id}-theme-edit-settings`}
            onClick={() => onEditTheme(themeMenu.theme)}
          >
            Edit settings
          </MenuItem>
        )}
        {checkTenantPermissionsQuery.data?.manage_themes && themeMenu.theme.id !== "default" && (
          <MenuItem id={`${themeMenu.theme.id}-theme-delete`} onClick={onDeleteClick}>
            Delete
          </MenuItem>
        )}
      </Menu>
      {bindToWorkspaceDialog && (
        <BindToWorkspaceDialog
          theme={themeMenu.theme}
          onClose={() => {
            setBindToWorkspaceDialog(false);
            handleClose();
          }}
        />
      )}
    </>
  );
}
