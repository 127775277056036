import axios from "axios";

import { WebApiFactory } from "@cloudentity/acp-web";

import { getConfiguration } from "./configuration";
import { acpBaseURI, getTenantId } from "./paths";
import { unauthorizedRedirectAxios } from "./unauthorized-redirect.interceptor";

const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(v => v, unauthorizedRedirectAxios);

const api = () => {
  const basePath = `${acpBaseURI}/${getTenantId()}`;
  return WebApiFactory(getConfiguration(basePath), basePath, axiosInstance);
};

const stylingApi = {
  getStyling: (aid: string) => api().getStyling(aid),
};

export default stylingApi;
