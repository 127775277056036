import React, { useEffect, useMemo, useRef, useState } from "react";

import { useMonaco } from "@monaco-editor/react";
import LoadingButton from "@mui/lab/LoadingButton";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useQueryClient } from "@tanstack/react-query";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { editor } from "monaco-editor";
import { makeStyles } from "tss-react/mui";

import { Claim, OrganizationResponse, Server, WorkspaceResponse } from "@cloudentity/acp-admin";

import { getTenantId, portalMode } from "../../../common/api/paths";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import FormInputLabel from "../../../common/components/FormInputLabel";
import Progress from "../../../common/components/Progress";
import RouteLeavingGuardSimple from "../../../common/components/RouteLeavingGuardSimple";
import {
  notifyError,
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { useGetClaims } from "../../services/adminClaimsQuery";
import {
  getOrganizationQueryKey,
  listOrganizationsQueryKey,
} from "../../services/adminOrganizationsQuery";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import adminServersApi from "../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  listWorkspacesQueryKey,
  useGetAuthorizationServer,
} from "../../services/adminServersQuery";
import SchemaForm, {
  omitPayloadPropertiesWithoutPathInSchema,
} from "../workspaceDirectory/identityPools/identityPool/users/user/SchemaForm";
import {
  getAllPaths,
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../workspaceDirectory/identityPools/schemas/schemas.utils";
import supportedJsonSchema from "../workspaceDirectory/identityPools/schemas/supported-json-schema.json";
import OAuthMetadataEditor from "./OAuthMetadataEditor";

import IMarker = editor.IMarker;

const useStyles = makeStyles()(theme => ({
  paper: {
    height: "calc(100vh - 460px)",
    overflow: "scroll",
    position: "relative",
  },
  emptyContainer: {
    textAlign: "center",
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  emptyContent: {
    marginTop: 16,
    color: theme.palette.secondary.light,
  },
  consoleContainer: {
    position: "sticky",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    backgroundColor: "#fff",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
  consoleContent: {
    padding: "12px 16px 24px 16px",
    backgroundColor: "rgb(255 249 247)",
  },
}));

export interface OAuthMetadataViewProps {
  workspace: string;
  server?: WorkspaceResponse | OrganizationResponse | undefined;
}

export default function OAuthMetadataView({ workspace, server }: OAuthMetadataViewProps) {
  const { classes } = useStyles();

  const [schema, setSchema] = useState("{}");
  const [lastValidSchema, setLastValidSchema] = useState({});
  const [payload, setPayload] = useState({});
  const [claimsWithoutAttributesDialog, setClaimsWithoutAttributesDialog] = useState<{
    claims: Claim[];
  } | null>(null);

  const queryClient = useQueryClient();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace, {
    enabled: !server && !!checkWorkspacePermissionsQuery.data?.get_workspace,
  });
  const claimsQuery = useGetClaims(getTenantId(), workspace);
  const workspaceClaims = (claimsQuery.data?.claims || []).filter(
    claim => claim.source_type === "workspace"
  );

  const updateWorkspaceMetadataPermission =
    !!checkWorkspacePermissionsQuery.data?.update_workspace_metadata;
  const updateWorkspacePermission = !!checkWorkspacePermissionsQuery.data?.update_workspace;
  const updatePermissions = updateWorkspaceMetadataPermission || updateWorkspacePermission;

  const metadata = server?.metadata || serverQuery.data?.metadata;

  useEffect(() => {
    setSchema(JSON.stringify(metadata?.schema, null, 2));
    setLastValidSchema(metadata?.schema ?? {});
    setPayload(metadata?.payload ?? {});
  }, [metadata]);

  const [schemaSyntaxErrors, setSchemaSyntaxError] = useState<IMarker[]>([]);
  const [updateProgress, setUpdateProgress] = useState(false);

  const handleValidateSchema = markers => setSchemaSyntaxError(markers);

  const handleSave = () => {
    setUpdateProgress(true);
    const parsedSchema = JSON.parse(schema);

    if (!updateWorkspacePermission) {
      return adminServersApi
        .updateWorkspaceMetadata({
          wid: workspace,
          workspaceMetadata: {
            payload: omitPayloadPropertiesWithoutPathInSchema(payload, parsedSchema),
          },
        })
        .then(updateWorkspacePromiseChain);
    }

    return adminServersApi
      .updateAuthorizationServer({
        wid: workspace,
        server: {
          ...serverQuery.data,
          metadata: {
            ...serverQuery.data?.metadata,
            payload: isEmpty(parsedSchema)
              ? {}
              : omitPayloadPropertiesWithoutPathInSchema(payload, parsedSchema),
            schema: parsedSchema,
          },
        } as Server,
      })
      .then(updateWorkspacePromiseChain);
  };

  const schemaWithMappedTitles = mapFieldNameToTitle(lastValidSchema);
  const validatePayload = validator.validateFormData(payload, schemaWithMappedTitles);

  const handleSaveClick = () => {
    const parsedSchema = JSON.parse(schema);
    const allPathsInPreviousSchema = getAllPaths(serverQuery.data?.metadata?.schema || {});
    const allPathsInNewSchema = getAllPaths(parsedSchema);
    const removedPaths = allPathsInPreviousSchema.filter(
      path => !allPathsInNewSchema.includes(path)
    );
    const claimsWithoutAttributes = workspaceClaims.filter(claim =>
      removedPaths.includes(claim.source_path?.replace("metadata.", ""))
    );

    if (
      !isEqual(serverQuery.data?.metadata?.schema, parsedSchema) &&
      claimsWithoutAttributes.length > 0
    ) {
      setClaimsWithoutAttributesDialog({ claims: claimsWithoutAttributes });
      return;
    }

    return handleSave();
  };

  const updateWorkspacePromiseChain = () =>
    queryClient
      .invalidateQueries({ queryKey: listWorkspacesQueryKey() })
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), workspace),
        })
      )
      .then(() => queryClient.invalidateQueries({ queryKey: getOrganizationQueryKey(workspace) }))
      .then(() => notifySuccess("Workspace metadata saved successfully"))
      .catch(err => {
        if (
          err?.response?.data?.error === "some fields did not pass the validation" &&
          err?.response?.data?.status_code === 422
        ) {
          const details: { Causes: { InstanceLocation: string; Message: string }[] } = err?.response
            ?.data?.details ?? { Causes: [] };

          const paths = details.Causes.map(c => ({
            path: c.InstanceLocation.split("/").slice(1),
            message: c.Message,
          }));

          notifyError(
            "Metadata is invalid. Please fix the following errors: " +
              paths
                .map(p => (p.path.length > 0 ? `${p.path.join(".")}: ${p.message}` : p.message))
                .join(", ")
          );
          return;
        }

        notifyErrorOrDefaultTo("Error occurred when trying to update workspace metadata")(err);
        return err;
      })
      .finally(() => setUpdateProgress(false));

  const lastParsedSchemaRef = useRef({});
  const parsedSchema = useMemo(() => {
    try {
      const p = JSON.parse(schema);
      lastParsedSchemaRef.current = p;
      return p;
    } catch (e) {
      return lastParsedSchemaRef.current;
    }
  }, [schema, lastParsedSchemaRef]);

  useEffect(() => {
    if (!schemaSyntaxErrors.length) {
      setLastValidSchema(parsedSchema);
    }
  }, [schemaSyntaxErrors, parsedSchema]);

  const monaco = useMonaco();
  useEffect(() => {
    monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: "payload",
          fileMatch: ["payload"],
          schema: parsedSchema,
        },
        {
          uri: "metadata",
          fileMatch: ["metadata"],
          schema: supportedJsonSchema,
        },
      ],
      enableSchemaRequest: false,
      allowComments: false,
    });
  }, [monaco, parsedSchema]);

  const uiSchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(schemaWithMappedTitles),
    "ui:options": { title: "" },
  };

  const emptySchema = !schemaWithMappedTitles || isEmpty(schemaWithMappedTitles);

  const saveButtonDisabled =
    validatePayload.errors.length > 0 ||
    schemaSyntaxErrors.length > 0 ||
    updateProgress ||
    (isEqual(parsedSchema, metadata?.schema) && isEqual(payload, metadata?.payload ?? {}));

  const saveButtonVisible = updatePermissions && (portalMode !== "b2b" || !emptySchema);

  return serverQuery.isLoading ? (
    <Progress isGlobalLoader />
  ) : (
    <>
      <Grid container spacing={2}>
        {portalMode !== "b2b" && (
          <Grid item xs={7}>
            <OAuthMetadataEditor
              id="workspace-metadata-schema"
              label="Custom Attributes Schema"
              value={schema}
              path="metadata"
              onChange={(schema, resetPayload) => {
                if (resetPayload) {
                  setPayload(omitPayloadPropertiesWithoutPathInSchema(payload, schema));
                }
                setSchema(schema);
              }}
              errors={schemaSyntaxErrors}
              onValidate={handleValidateSchema}
              disabled={!updateWorkspacePermission}
            />
          </Grid>
        )}

        <Grid item xs={5}>
          <FormInputLabel
            id="preview"
            label="Form preview and value editor"
            style={{ textTransform: "uppercase" }}
          />
          <Paper className={classes.paper}>
            {emptySchema ? (
              <div className={classes.emptyContainer}>
                <Typography variant="h3">Preview Form</Typography>
                <Typography variant="body2" className={classes.emptyContent}>
                  Define the schema to see the preview
                </Typography>
              </div>
            ) : (
              <div>
                <div style={{ padding: 32 }}>
                  <SchemaForm
                    formData={payload}
                    setFormData={setPayload}
                    schema={schemaWithMappedTitles}
                    UISchema={uiSchema}
                    submitAttempt={true}
                    extraErrors={{}}
                    resetExtraErrors={() => {}}
                    disabled={!updatePermissions}
                  />
                </div>
                {validatePayload.errors?.length > 0 && (
                  <div className={classes.consoleContainer}>
                    <FormInputLabel
                      id="console"
                      label="Console"
                      style={{ textTransform: "uppercase", padding: "4px 16px", marginBottom: 0 }}
                    />
                    <div className={classes.consoleContent}>
                      <FormHelperText
                        style={{ minHeight: 20 }}
                        error={validatePayload.errors?.length > 0}
                      >
                        {validatePayload.errors &&
                          validatePayload.errors.map((e, i) => (
                            <span key={i} style={{ display: "block" }}>
                              <span style={{ marginRight: 8 }}>ERR! </span>
                              {e.stack}
                            </span>
                          ))}
                      </FormHelperText>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Paper>

          <SaveButton
            showButton={saveButtonVisible}
            onSaveClick={() => handleSaveClick()}
            loading={updateProgress}
            disabled={saveButtonDisabled}
          />
        </Grid>
      </Grid>
      {claimsWithoutAttributesDialog && (
        <ConfirmationDialog
          title="No matched attributes"
          content={
            <span>
              Workspace type claims that don't match custom attributes schema:
              <ul>
                {claimsWithoutAttributesDialog.claims.map(claim => (
                  <li>{claim.name}</li>
                ))}
              </ul>
              Do you want to continue with saving current schema?
            </span>
          }
          confirmText="Continue"
          progress={updateProgress}
          onCancel={() => setClaimsWithoutAttributesDialog(null)}
          onConfirm={() => handleSave().then(() => setClaimsWithoutAttributesDialog(null))}
        />
      )}
      <RouteLeavingGuardSimple when={!saveButtonDisabled} />
    </>
  );
}

interface SaveButtonProps {
  showButton: boolean;
  onSaveClick: () => void;
  loading: boolean;
  disabled: boolean;
}

function SaveButton({ showButton, onSaveClick, loading, disabled }: SaveButtonProps) {
  return showButton ? (
    <div style={{ textAlign: "right", marginTop: 24 }}>
      <LoadingButton
        style={{ padding: "12px 24px" }}
        id="save-button"
        variant="contained"
        onClick={onSaveClick}
        loading={loading}
        disabled={disabled}
      >
        Save
      </LoadingButton>
    </div>
  ) : null;
}
