import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";

import Tab from "@mui/material/Tab";

import { getTenantId } from "../../../../common/api/paths";
import { useListSchemas } from "../../../services/adminIdentitySchemasQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";
import IdentityPoolsGrid from "./identityPoolsList/IdentityPoolsGrid";
import IdentitySchemaCreate from "./schemas/IdentitySchemaCreate";
import IdentitySchemasGrid from "./schemas/IdentitySchemasGrid";

export default function IdentityPools() {
  const { tab } = useParams<{ tab: string }>();
  const navigate = useNavigate();

  const [createSchemaDialog, setCreateSchemaDialog] = useState(false);

  const schemasQuery = useListSchemas(getTenantId());
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const progress = schemasQuery.isFetching || checkTenantPermissionsQuery.isFetching;

  const handleTabChange = (tab: string) => {
    navigate(`/identity-pools/${tab}`);
  };

  const schemas = schemasQuery.data?.schemas ?? [];

  const isNonEmptySchemaView =
    tab === "schemas" && !!schemas.length && checkTenantPermissionsQuery.data?.create_identity_pool;

  return (
    <>
      <PageHeader
        title="Identity Pools"
        tabs={[
          <Tab label="Pools" value="pools" key="pools" id="pools-tab" />,
          <Tab label="Schemas" value="schemas" key="schemas" id="schemas-tab" />,
        ]}
        onChangeTab={handleTabChange}
        currentTab={tab}
        buttonText={(isNonEmptySchemaView && "+ Create schema") || ""}
        onButtonClick={(isNonEmptySchemaView && (() => setCreateSchemaDialog(true))) || undefined}
      />
      <PageContent progress={progress}>
        {tab === "pools" && <IdentityPoolsGrid />}
        {tab === "schemas" && <IdentitySchemasGrid />}
      </PageContent>

      {createSchemaDialog && (
        <IdentitySchemaCreate onClose={() => setCreateSchemaDialog(false)} schemas={schemas} />
      )}
    </>
  );
}
