import React, { Dispatch, SetStateAction, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { OrganizationResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import { listWorkspacesQueryKey } from "../../../services/adminServersQuery";
import { UseItemsReturnType } from "../../common/EnhancedTableAsync/useItemsWithQuery";
import PageContent from "../../common/PageContent";
import TenantAddOrganization from "../../organizations/TenantAddOrganization";
import TenantOrganizationsTabCards from "./TenantOrganizationsTabCards";
import TenantOrganizationsTabHierarchy from "./TenantOrganizationsTabHierarchy";
import { GridMode } from "./utils";

interface Props {
  cardProgress: string;
  deleteProgress: string;
  onSelectServer: (id: string) => void;
  allData: UseItemsReturnType<OrganizationResponse>;
  templateData?: UseItemsReturnType<OrganizationResponse>;
  onMenuOpen: (props: {
    anchorEl: HTMLElement;
    server: OrganizationResponse;
    rootServer?: OrganizationResponse | WorkspaceResponse;
  }) => void;
  gridMode: GridMode;
  setGridMode: Dispatch<SetStateAction<GridMode>>;
}

export default function TenantOrganizationsTab({
  cardProgress,
  deleteProgress,
  onSelectServer,
  allData,
  templateData,
  onMenuOpen,
  gridMode,
  setGridMode,
}: Props) {
  const queryClient = useQueryClient();

  const [createOrganizationDialog, setCreateOrganizationDialog] = useState(false);

  return (
    <PageContent progress={allData.firstPageLoading || templateData?.firstPageLoading}>
      {gridMode === "cards" && (
        <TenantOrganizationsTabCards
          cardProgress={cardProgress}
          deleteProgress={deleteProgress}
          onSelectServer={onSelectServer}
          allData={allData}
          templateData={templateData}
          onMenuOpen={onMenuOpen}
          setGridMode={setGridMode}
          onCreate={setCreateOrganizationDialog}
        />
      )}

      {gridMode === "hierarchy" && (
        <TenantOrganizationsTabHierarchy
          onSelectServer={onSelectServer}
          onMenuOpen={onMenuOpen}
          onCreate={setCreateOrganizationDialog}
          setGridMode={setGridMode}
        />
      )}

      {createOrganizationDialog && (
        <TenantAddOrganization
          onCancel={() => {
            setCreateOrganizationDialog(false);
          }}
          onCreated={() => {
            queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() });
          }}
          onSkip={() => setCreateOrganizationDialog(false)}
          onInviteSent={() => setCreateOrganizationDialog(false)}
        />
      )}
    </PageContent>
  );
}
