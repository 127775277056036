import React, { useContext, useEffect } from "react";

import { BasicPopoverStep } from "../../../common/components/guidedTours/BasicPopoverStep";
import PopoverStepCardContent, {
  CardContentType,
} from "../../../common/components/guidedTours/PopoverStepCardContent";
import { GlobalToursContext } from "../GlobalTours";
import { B2B_ORG_MANAGEMENT_TOUR } from "./B2BOrgWelcomeTour";

export const B2B_ORG_VIEW_TOUR = "b2b-portal-org-view-tour";

export enum B2BOrgViewTourSteps {
  OrgDetailsNavCard = "OrgDetailsNavCard",
  SignInNavCard = "SignInNavCard",
  SuborgsNavCard = "SuborgsNavCard",
  UsersNavCard = "UsersNavCard",
  SwitchModeCard = "SwitchModeCard",
}

const cardContent: { [key in B2BOrgViewTourSteps]: CardContentType } = {
  [B2BOrgViewTourSteps.OrgDetailsNavCard]: {
    step: "1",
    title: "Organization Details",
    description:
      "Update the organization's name, provide a description for clarity, and specify associated email domains to ensure proper grouping and management of users.",
    withSkip: true,
  },
  [B2BOrgViewTourSteps.SignInNavCard]: {
    step: "2",
    title: "Sign-in and SSO",
    description:
      "Configure authentication methods for users, including built-in options (e.g., password, passkey) and external providers (e.g., Google, Okta). Preview and customize the login experience in real time.",
  },
  [B2BOrgViewTourSteps.SuborgsNavCard]: {
    step: "3",
    title: "Suborganizations",
    description:
      "Create a hierarchical structure within a parent organization, enabling separate management of users, branding, and authentication settings for child entities. This is ideal for business customers or partners needing their own configurations while inheriting base settings from the parent organization.",
  },
  [B2BOrgViewTourSteps.UsersNavCard]: {
    step: "4",
    title: "User Management",
    description:
      "Manage your organization's users by creating, importing, and assigning roles. Customize user attributes and configure secure sign-in and sign-up workflows for seamless access management.",
  },
  [B2BOrgViewTourSteps.SwitchModeCard]: {
    step: "5",
    title: "Switch mode",
    description:
      "Provides delegated administrative capabilities for business customers or partners. When enabled, it allows designated admins to manage their respective organizations, including users, authentication settings, and suborganizations, within a restricted scope.",
    isFinal: true,
  },
};

export default function B2BOrgViewTour() {
  const tour = `${B2B_ORG_VIEW_TOUR}`;

  const globalToursContext = useContext(GlobalToursContext);
  const currentTour = globalToursContext.getCurrentTour();
  const currentStep = globalToursContext.getCurrentStep();
  const isWelcomeTourFinished = globalToursContext.isFinished(B2B_ORG_MANAGEMENT_TOUR);

  const TourSteps = [
    {
      id: B2BOrgViewTourSteps.OrgDetailsNavCard,
      targets: () => [document.querySelector<HTMLElement>("#settings-side-nav-item")],
    },
    {
      id: B2BOrgViewTourSteps.SignInNavCard,
      targets: () => [document.querySelector<HTMLElement>("#sign-in-side-nav-item")],
    },
    {
      id: B2BOrgViewTourSteps.SuborgsNavCard,
      targets: () => [document.querySelector<HTMLElement>("#suborganizations-side-nav-item")],
    },
    {
      id: B2BOrgViewTourSteps.UsersNavCard,
      targets: () => [document.querySelector<HTMLElement>("#users-side-nav-item")],
    },
    {
      id: B2BOrgViewTourSteps.SwitchModeCard,
      targets: () => [document.querySelector<HTMLElement>("#admin-b2b-button")],
    },
  ];

  const popoverStyle = { width: 460, padding: 24 };
  const totalStepsCount = Object.keys(cardContent).length;
  const currentStepContent: CardContentType | null =
    (currentStep && cardContent[currentStep?.id]) || null;

  function onGoToPreviousStep() {
    if (!currentTour || !currentStep) return;
    globalToursContext.goBack(currentTour, currentStep.id);
  }

  function onCompleteCurrentStep() {
    if (!currentTour || !currentStep) return;
    globalToursContext.complete(currentTour, currentStep.id);
  }

  function onCompleteTour() {
    if (!currentTour) return;
    globalToursContext.completeAndStopAll(currentTour);
  }

  function prepareForSwitchModeStep() {
    const profileButton = document.querySelector<HTMLButtonElement>("#profile-navbar-button");
    if (profileButton) {
      profileButton.click();
    }
    setTimeout(() => {
      onCompleteCurrentStep();
    }, 400);
  }

  function cleanAfterSwitchModeStep() {
    const backdropElement = document.querySelector<any>(".MuiBackdrop-root");
    if (backdropElement) {
      backdropElement.click();
    }
    setTimeout(() => {
      onCompleteCurrentStep();
    }, 100);
  }

  const onCompleteCurrentStepProp =
    (currentStep?.id === B2BOrgViewTourSteps.UsersNavCard && prepareForSwitchModeStep) ||
    (currentStep?.id === B2BOrgViewTourSteps.SwitchModeCard && cleanAfterSwitchModeStep) ||
    onCompleteCurrentStep;

  useEffect(() => {
    if (isWelcomeTourFinished) {
      globalToursContext.register({
        id: B2B_ORG_VIEW_TOUR,
        runOnce: true,
        startOnRegister: true,
        steps: TourSteps,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWelcomeTourFinished]);

  return currentTour === tour && currentStep && currentStepContent ? (
    <>
      <BasicPopoverStep
        targets={currentStep.targets}
        scrollToElement
        placement="right-start"
        cardStyles={popoverStyle}
        onMaskClick={onCompleteCurrentStepProp}
      >
        <PopoverStepCardContent
          totalCount={totalStepsCount}
          cardContent={currentStepContent}
          onBack={onGoToPreviousStep}
          onCompleteTour={onCompleteTour}
          {...(currentStepContent.withSkip
            ? {
                moreLabel: "Skip",
                onMore: () => {
                  onCompleteTour();
                },
              }
            : {})}
          onCompleteCurrentStep={onCompleteCurrentStepProp}
          isFinal={currentStepContent.isFinal}
        />
      </BasicPopoverStep>
    </>
  ) : null;
}
