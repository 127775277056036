import { IdpsApiListIDPsForIdentityPoolRequest, IDPsResponse } from "@cloudentity/acp-admin";
import { IDP } from "@cloudentity/acp-root";

import { getTenantId } from "../../common/api/paths";
import adminIDPsApi, { IdpType } from "./adminIDPsApi";
import {
  useQueryWithTenantPermissionCheck,
  useQueryWithWorkspacePermissionCheck,
  withQueryError,
} from "./queryUtils";

const LIST_IDPS_QUERY = "LIST_IDPS_QUERY";
const LIST_IDPS_FOR_IDENTITY_POOL_QUERY = "LIST_IDPS_FOR_IDENTITY_POOL_QUERY";
const LIST_IDPS_FOR_VISUALIZER_QUERY = "LIST_IDPS_FOR_VISUALIZER_QUERY";
const GET_IDP_QUERY = "GET_IDP_QUERY";

export const listIDPsQueryKey = (tid, wid?) =>
  wid ? [LIST_IDPS_QUERY, tid, wid] : [LIST_IDPS_QUERY, tid];
export const listIDPsForIdentityPoolQueryKey = (tid, ipID?) =>
  ipID ? [LIST_IDPS_FOR_IDENTITY_POOL_QUERY, tid, ipID] : [LIST_IDPS_FOR_IDENTITY_POOL_QUERY, tid];

export const listIDPsForVisualizerQueryKey = (tid, aid) => [
  LIST_IDPS_FOR_VISUALIZER_QUERY,
  tid,
  aid,
];
export const getIDPQueryKey = (iid: string, wid?: string) =>
  wid ? [GET_IDP_QUERY, iid, wid] : [GET_IDP_QUERY, iid];

export const useListIDPs = (tid, wid, options?) =>
  useQueryWithWorkspacePermissionCheck<IDPsResponse>(
    wid,
    "read_idps",
    listIDPsQueryKey(tid, wid),
    withQueryError<IDPsResponse>(async () => {
      const data = await adminIDPsApi.getIDPs({ wid });
      return data.data;
    }, "Error occurred while trying to list IDPs"),
    options
  );

export const useListIDPsForIdentityPool = (req: IdpsApiListIDPsForIdentityPoolRequest, options?) =>
  useQueryWithTenantPermissionCheck<IDPsResponse>(
    "list_identity_pools",
    listIDPsForIdentityPoolQueryKey(getTenantId(), req.ipID),
    withQueryError<IDPsResponse>(async () => {
      const data = await adminIDPsApi.listIDPsForIdentityPool(req);
      return data.data;
    }, "Error occurred while trying to list IDPs for identity pool"),
    options
  );

export const useListIDPsForVisualizer = (tid, wid, options?) =>
  useQueryWithWorkspacePermissionCheck<IDP[]>(
    wid,
    "read_idps",
    listIDPsForVisualizerQueryKey(tid, wid),
    withQueryError<IDP[]>(async () => {
      const { data } = await adminIDPsApi.getIDPs({ wid });
      const baseIdps =
        data.idps?.slice().sort((a, b) => (a.name ?? "").localeCompare(b.name ?? "")) ?? [];
      const fullIdps: IDP[] = await Promise.all(
        baseIdps.map(idp =>
          adminIDPsApi.getIDP({ wid, type: idp.method, iid: idp.id }).then(v => v.data)
        )
      );
      return fullIdps.filter(idp => idp.attributes && idp.attributes.length > 0);
    }, "Error occurred while trying to list full IDPs"),
    options
  );

export const useGetIDP = (wid: string, type: IdpType | undefined, iid: string, options?) =>
  useQueryWithWorkspacePermissionCheck<IDP>(
    wid,
    "read_idps",
    getIDPQueryKey(iid, wid),
    withQueryError<IDP>(async () => {
      const data = await adminIDPsApi.getIDP({ wid, type, iid });
      return data.data;
    }, "Error occurred while trying to get IDP"),
    options
  );
