import React from "react";

import ButtonMUI, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ variant?: "text" | "outlined" | "contained" }>()(
  (theme, { variant }) => ({
    button: {
      "&:hover": {
        ...(variant !== "outlined" && variant !== "text"
          ? { backgroundColor: theme.palette.primary.main }
          : {}),
        opacity: 0.9,
      },
    },
  })
);

export default function LoadingButton({
  children,
  className,
  ref,
  ...buttonProps
}: LoadingButtonProps) {
  const { cx, classes } = useStyles({ variant: buttonProps.variant });

  return (
    <ButtonMUI className={cx(classes.button, className)} ref={ref} {...buttonProps}>
      {children}
    </ButtonMUI>
  );
}
