import React from "react";

import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import PageHeader from "../common/PageHeader";
import WorkspaceAdministrators from "./WorkspaceAdministrators";

export default function ManageAccessWorkspace() {
  return (
    <PageContainer>
      <PageHeader title="Manage Access" />
      <PageContent>
        <WorkspaceAdministrators />
      </PageContent>
    </PageContainer>
  );
}
