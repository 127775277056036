import React, { useState } from "react";
import { useNavigate } from "react-router";

import { useQueryClient } from "@tanstack/react-query";
import { FilePlus, UserPlus } from "react-feather";

import { GrantTenantRoleRequestTypeEnum } from "@cloudentity/acp-admin";
import { NewUserPayloadStatusEnum, PoolResponse } from "@cloudentity/acp-identity";

import ImportUsersDialog from "../../../../../../../b2b/components/Users/ImportUsersDialog";
import { getTenantId } from "../../../../../../../common/api/paths";
import Button from "../../../../../../../common/components/Button";
import { notifyErrorOrDefaultTo } from "../../../../../../../common/components/notifications/notificationService";
import { useFeature } from "../../../../../../../common/utils/hooks/useFeature";
import { trimStringValues } from "../../../../../../../common/utils/object.utils";
import {
  listWorkspacePoolsQueryKey,
  useCheckPoolPermissions,
} from "../../../../../../services/adminIdentityPoolsQuery";
import { useGetSchema } from "../../../../../../services/adminIdentitySchemasQuery";
import identityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listUsersQueryKey,
} from "../../../../../../services/adminIdentityUsersQuery";
import { useCheckWorkspacePermissions } from "../../../../../../services/adminPermissionsQuery";
import adminRolesApi from "../../../../../../services/adminRolesApi";
import { listTenantRoles, listUserRoles } from "../../../../../../services/adminRolesQuery";
import { isAnyWorkspaceOrPoolRoleAllowed } from "../../../../administrator/WorkspaceRoleSelectField";
import { usePoolRootUrl } from "../../../utils";
import {
  setPoolWorkspaceId,
  useIdentityPoolThemeSelector,
} from "../../useIdentityPoolThemeSelector";
import IdentityPoolUserCreate, { CreateData } from "../create/IdentityPoolUserCreate";
import { grantWorkspaceRoles } from "./utils";

const initialData = {
  identifier: "email",
  phone: "",
  mode: "invite",
  role: "",
  roles: [],
  temporaryPassword: true,
};

interface Props {
  workspaceId: string;
  pool: PoolResponse;
  isOrganizationWorkspace: boolean;
  onResetUsers: () => void;
  handleSendActivationMessage: (userId: string, serverId?: string) => void;
}

export default function IdentityPoolUsersAddImportUsers({
  workspaceId,
  pool,
  isOrganizationWorkspace,
  onResetUsers,
  handleSendActivationMessage,
}: Props) {
  const { rootUrl, mode: poolMode } = usePoolRootUrl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const tenantId = getTenantId();

  const [progress, setProgress] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [importUsers, setImportUsers] = useState(false);

  const { themeWorkspaceId } = useIdentityPoolThemeSelector({ pool });

  const checkWorkspacePermissions = useCheckWorkspacePermissions(workspaceId);
  const checkPoolPermissionsQuery = useCheckPoolPermissions(pool?.id!);
  const hasManageUserPermissions = !!checkPoolPermissionsQuery.data?.manage_identity_pool_users;

  const payloadSchemaQuery = useGetSchema(pool?.payload_schema_id, {
    enabled: hasManageUserPermissions,
  });
  const metadataSchemaQuery = useGetSchema(pool?.metadata_schema_id, {
    enabled: hasManageUserPermissions,
  });
  const businessMetadataSchemaQuery = useGetSchema(pool?.business_metadata_schema_id, {
    enabled: hasManageUserPermissions,
  });

  const isWithRolesEnabled = useFeature("with_roles");

  const isWorkspaceOrPoolsRolesVisible =
    poolMode === "workspace" &&
    isWithRolesEnabled &&
    (checkWorkspacePermissions.data?.read_roles || checkPoolPermissionsQuery.data?.read_roles);

  const isRolesVisible =
    !!isAnyWorkspaceOrPoolRoleAllowed(
      checkWorkspacePermissions.data,
      checkPoolPermissionsQuery.data
    ) && !!isWorkspaceOrPoolsRolesVisible;

  const handleCreate = ({
    mode,
    password,
    email,
    phone,
    identifier,
    payload,
    metadata,
    businessMetadata,
    role,
    roles,
    serverId,
    temporaryPassword,
  }: CreateData) => {
    const selectedIdentifier = (identifier === "email" ? email?.trim() : phone) ?? "";
    const status =
      mode === "invite" ? NewUserPayloadStatusEnum.New : NewUserPayloadStatusEnum.Active;
    const verified = mode !== "invite";
    const credentials =
      mode === "invite"
        ? []
        : [
            {
              type: "password" as any,
              password,
              ...(temporaryPassword ? { must_be_changed: true } : {}),
            },
          ];

    setProgress(true);

    return identityUsersApi
      .createUser({
        ipID: pool?.id ?? "",
        newUser: {
          payload: payload && trimStringValues(payload),
          metadata: metadata && trimStringValues(metadata),
          business_metadata: businessMetadata && trimStringValues(businessMetadata),
          status,
          credentials,
          identifiers: [
            {
              identifier: selectedIdentifier,
              type: identifier as any,
            },
          ],
          verifiable_addresses: [
            {
              address: selectedIdentifier,
              status: "active" as any,
              type: identifier as any,
              verified,
            },
          ],
        },
      })
      .then(res => {
        if (role) {
          return adminRolesApi
            .grantTenantRole({
              request: {
                role: role,
                type: GrantTenantRoleRequestTypeEnum.IdentityPoolUser,
                identity_pool_id: res.data.user_pool_id,
                identity_pool_user_id: res.data.id,
                tenant_id: tenantId,
              },
            })
            .then(() => queryClient.invalidateQueries({ queryKey: listTenantRoles() }))
            .then(() =>
              queryClient.invalidateQueries({
                queryKey: listUserRoles(res.data.user_pool_id, res.data.id),
              })
            )
            .catch(notifyErrorOrDefaultTo("Error occurred when trying to grant tenant role"))
            .then(() => {
              if ((roles || []).length > 0 && res.data) {
                return grantWorkspaceRoles(queryClient, workspaceId, res.data, roles || []).then(
                  () => res
                );
              }

              return res;
            });
        }

        if ((roles || []).length > 0 && res.data) {
          return grantWorkspaceRoles(queryClient, workspaceId, res.data, roles || []).then(
            () => res
          );
        }

        return res;
      })
      .then(res => {
        if (mode === "invite" && res.data.id) {
          handleSendActivationMessage(res.data.id, serverId);
        }
        navigate(`${rootUrl}/${pool?.id ?? ""}/users/${res.data.id}`);
      })
      .then(() => {
        setAddUser(false);
        onResetUsers();
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, pool?.id) })
      )
      .then(() => {
        if (poolMode === "workspace") {
          queryClient.invalidateQueries({ queryKey: listWorkspacePoolsQueryKey(workspaceId) });
        }
      })
      .finally(() => setProgress(false));
  };

  return (
    <>
      {poolMode === "workspace" && isOrganizationWorkspace && (
        <Button
          onClick={() => setImportUsers(true)}
          id="empty-state-import-button"
          variant="outlined"
          style={{ marginRight: 24, minHeight: 47 }}
        >
          <FilePlus size={14} style={{ marginRight: 8 }} />
          IMPORT USERS
        </Button>
      )}

      <Button
        onClick={() => setAddUser(true)}
        id="empty-state-add-user-button"
        variant="contained"
        style={{ minHeight: 47 }}
      >
        <UserPlus size={14} style={{ marginRight: 8 }} />
        CREATE USER
      </Button>

      {addUser && (
        <IdentityPoolUserCreate
          pool={pool}
          payloadSchema={payloadSchemaQuery.data}
          metadataSchema={metadataSchemaQuery.data}
          businessMetadataSchema={businessMetadataSchemaQuery.data}
          allowMetadata
          allowBusinessMetadata
          onCreate={data => {
            if (data.rememberThemeWorkspaceId) {
              setPoolWorkspaceId(pool?.id || "", data.themeWorkspaceId || "");
              return handleCreate({ ...data, serverId: data.themeWorkspaceId });
            }

            return handleCreate({ ...data, serverId: data.themeWorkspaceId || themeWorkspaceId });
          }}
          onClose={() => setAddUser(false)}
          progress={progress}
          initialData={initialData}
          isRolesVisible={isRolesVisible}
          workspaceId={workspaceId}
        />
      )}

      {importUsers && pool.id && (
        <ImportUsersDialog
          workspaceId={workspaceId}
          identityPoolId={pool.id}
          allowMetadata
          allowBusinessMetadata
          createUserFn={({ newUser, metadata, business_metadata }) =>
            identityUsersApi
              .createUser({
                ipID: pool.id!,
                newUser: {
                  ...newUser,
                  status: newUser.status as unknown as NewUserPayloadStatusEnum,
                  metadata,
                  business_metadata,
                },
              })
              .then(res => {
                queryClient.setQueryData(getUserQueryKey(tenantId, res.data.id), res.data);
                return res.data;
              })
          }
          onClose={() => setImportUsers(false)}
        />
      )}
    </>
  );
}
