import React from "react";

import Link from "@mui/material/Link";
import { ExternalLink } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  icon: {
    marginLeft: 4,
    position: "relative",
    top: 1,
  },
}));

interface Props {
  href: string;
  children: string;
}

export default function DocLink({ href, children }: Props) {
  const { classes } = useStyles();

  return (
    <Link href={href} target="_blank" rel="noopener noreferrer">
      <b>{children}</b>
      <ExternalLink size={14} className={classes.icon} />
    </Link>
  );
}
