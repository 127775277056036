import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import {
  GrantIdentityPoolRoleRequestRoleEnum,
  ServerResponseTypeEnum,
} from "@cloudentity/acp-admin";
import { UpdateUserStatusEnum } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../common/api/paths";
import DialogFullScreen from "../../../../../../../common/components/DialogFullScreen";
import Tabs from "../../../../../../../common/components/Tabs";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import { useFeature } from "../../../../../../../common/utils/hooks/useFeature";
import {
  useCheckPoolPermissions,
  useGetPool,
} from "../../../../../../services/adminIdentityPoolsQuery";
import { useListSchemas } from "../../../../../../services/adminIdentitySchemasQuery";
import identityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listUsersQueryKey,
  useGetUser,
} from "../../../../../../services/adminIdentityUsersQuery";
import { useCheckWorkspacePermissions } from "../../../../../../services/adminPermissionsQuery";
import {
  useListIdentityPoolRoles,
  useListWorkspaceRoles,
} from "../../../../../../services/adminRolesQuery";
import { useGetAuthorizationServer } from "../../../../../../services/adminServersQuery";
import { useCheckTenantPermissions } from "../../../../../../services/adminTenantsQuery";
import { useWorkspace } from "../../../../../common/useWorkspace";
import { useTenantRoles } from "../../../../administrator/useTenantRoles";
import { getUserRole, getUserRolesBySubjects } from "../list/utils";
import IdentityPoolUserJsonTab from "./IdentityPoolUserJsonTab";
import IdentityPoolUserSchemasTab from "./IdentityPoolUserSchemasTab";
import IdentityPoolUserSidePanel from "./sidePanel/IdentityPoolUserSidePanel";

const useStyles = makeStyles()(() => ({
  paper: {
    borderBottom: "none",
    borderRadius: "4px 4px 0 0",
  },
  gridContainer: {
    maxWidth: 1440,
    height: "100%",
  },
  grid: {
    height: "100%",
  },
}));

type TabType =
  | "user-attributes"
  | "metadata-attributes"
  | "business-metadata-attributes"
  | "roles"
  | "json";

interface Props {
  isWorkspacePool?: boolean;
}

const IdentityPoolUser = ({ isWorkspacePool }: Props) => {
  const { classes } = useStyles();
  const tenantId = getTenantId();
  const navigate = useNavigate();
  const params = useParams<{ id: string; userID: string }>();
  const [workspace] = useWorkspace();

  const id = params.id || "";
  const userID = params.userID || "";
  const workspaceId = isWorkspacePool ? workspace : undefined;

  const [progress, setProgress] = useState(false);
  const [tab, setTab] = useState<TabType>("user-attributes");

  const queryClient = useQueryClient();

  const getUserQuery = useGetUser(id, userID);
  const isWithRolesEnabled = useFeature("with_roles");

  const listSchemasQuery = useListSchemas(tenantId);

  const poolQuery = useGetPool(id);
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const checkPoolPermissionsQuery = useCheckPoolPermissions(id);
  const listWorkspaceRolesQuery = useListWorkspaceRoles(workspace, { enabled: !!isWorkspacePool });
  const listIdentityPoolRolesQuery = useListIdentityPoolRoles(id);
  const getAuthorizationServerQuery = useGetAuthorizationServer(tenantId, workspace, {
    enabled: !!isWorkspacePool,
  });

  const schemas = listSchemasQuery.data?.schemas || [];
  const pool = poolQuery.data;
  const user = getUserQuery.data;

  const noManagePermission = !checkPoolPermissionsQuery.data?.manage_identity_pool_users;

  const isAdminWorkspaceAccessEnabled = useFeature("admin_workspace_access");
  const { isEnabled: isTenantRoleVisible, query: tenantRolesQuery } = useTenantRoles({
    identityPoolId: pool?.id!,
    disabled: !isAdminWorkspaceAccessEnabled,
  });

  const userRolesBySubjects = useMemo(
    () =>
      getUserRolesBySubjects(
        user,
        [],
        listWorkspaceRolesQuery.data?.subjects,
        listIdentityPoolRolesQuery.data?.subjects
      ),
    [user, listWorkspaceRolesQuery.data, listIdentityPoolRolesQuery.data]
  );

  const initialTenantRole =
    getUserQuery.data && tenantRolesQuery.data
      ? getUserRole(getUserQuery.data, tenantRolesQuery.data?.subjects || [])
      : "";

  const initialWorkspaceRoles = useMemo(
    () => [
      ...userRolesBySubjects.workspace.map(r => r),
      ...(userRolesBySubjects.identityPool.includes(
        GrantIdentityPoolRoleRequestRoleEnum.UserManager
      )
        ? ["team_manager"]
        : []),
    ],
    [userRolesBySubjects]
  );

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspaceId);

  const isWorkspaceRolesVisible =
    !!checkWorkspacePermissionsQuery.data?.read_roles && !!isWorkspacePool;

  const areRolesVisible = isWithRolesEnabled && (isTenantRoleVisible || isWorkspaceRolesVisible);

  const isOrganization =
    getAuthorizationServerQuery.data?.type === ServerResponseTypeEnum.Organization;

  const handleStatusUpdate = (status: UpdateUserStatusEnum) => {
    setProgress(true);

    identityUsersApi
      .updateUser({ ipID: id, userID: userID, updateUser: { ...user, status } })
      .then(res => queryClient.setQueryData(getUserQueryKey(tenantId, userID), res.data))
      .then(() => queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, id) }))
      .then(() => notifySuccess("User data successfully updated"))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to update user"))
      .finally(() => setProgress(false));
  };

  const isLoading =
    progress ||
    poolQuery.isLoading ||
    listSchemasQuery.isLoading ||
    checkTenantPermissionsQuery.isLoading ||
    listWorkspaceRolesQuery.isLoading ||
    getUserQuery.isLoading ||
    tenantRolesQuery.isLoading ||
    listIdentityPoolRolesQuery.isLoading ||
    getAuthorizationServerQuery.isLoading;

  return (
    <DialogFullScreen
      id="pool-user-dialog"
      onCancel={() =>
        navigate(
          isWorkspacePool ? `/${workspace}/pools/${id}/users` : `/identity-pools/pools/${id}/users`
        )
      }
      title="User Profile"
      isLoading={isLoading}
    >
      {pool && user && (
        <Grid container spacing={4} className={classes.gridContainer}>
          <Grid item md={7} sm={12} className={classes.grid}>
            <Paper className={classes.paper}>
              <Tabs
                value={tab}
                onChange={(_, v) => {
                  setTab(v);
                }}
                style={{ padding: "16px 24px 0", marginBottom: 0 }}
              >
                <Tab value="user-attributes" label="User Attributes" id="user-attributes-tab" />
                <Tab
                  value="metadata-attributes"
                  label="User Metadata Attributes"
                  id="metadata-attributes-tab"
                />
                <Tab
                  value="business-metadata-attributes"
                  label="User Business Metadata Attributes"
                  id="business-metadata-attributes-tab"
                />
                <Tab value="json" label="JSON" id="json-tab" />
              </Tabs>
            </Paper>

            <div>
              {tab === "user-attributes" && (
                <IdentityPoolUserSchemasTab
                  schemaId="payload_schema_id"
                  dataKey="payload"
                  pool={pool}
                  user={user}
                  schemas={schemas}
                  noManagePermission={noManagePermission}
                  withRoles={
                    areRolesVisible
                      ? {
                          isTenantRoleVisible,
                          isWorkspaceRolesVisible,
                          workspaceSubjects: listWorkspaceRolesQuery.data?.subjects ?? [],
                          tenantRole: initialTenantRole,
                          workspaceRoles: initialWorkspaceRoles,
                          userRolesBySubjects,
                        }
                      : undefined
                  }
                  isOrganization={isOrganization}
                />
              )}

              {tab === "metadata-attributes" && (
                <IdentityPoolUserSchemasTab
                  schemaId="metadata_schema_id"
                  dataKey="metadata"
                  pool={pool}
                  user={user}
                  schemas={schemas}
                  noManagePermission={noManagePermission}
                  isOrganization={isOrganization}
                />
              )}

              {tab === "business-metadata-attributes" && (
                <IdentityPoolUserSchemasTab
                  schemaId="business_metadata_schema_id"
                  dataKey="business_metadata"
                  pool={pool}
                  user={user}
                  schemas={schemas}
                  noManagePermission={noManagePermission}
                  isOrganization={isOrganization}
                />
              )}

              {tab === "json" && (
                <IdentityPoolUserJsonTab
                  user={user}
                  noManagePermission={noManagePermission}
                  poolId={id}
                />
              )}
            </div>
          </Grid>
          <Grid item md={5} sm={12}>
            <IdentityPoolUserSidePanel
              workspaceId={workspaceId}
              user={user}
              progress={progress}
              onStatusChange={
                !!checkPoolPermissionsQuery.data?.manage_identity_pool_users
                  ? handleStatusUpdate
                  : undefined
              }
              poolId={id}
            />
          </Grid>
        </Grid>
      )}
    </DialogFullScreen>
  );
};

export default IdentityPoolUser;
