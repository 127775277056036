import React from "react";

// eslint-disable-next-line no-restricted-imports
import { SvgIconTypeMap } from "@mui/material";
import IconButtonMUI, { IconButtonProps as IconButtonMUIProps } from "@mui/material/IconButton";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { IconProps } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ noBorder: boolean }>()((theme, { noBorder }) => ({
  button: {
    ...(noBorder ? {} : { border: `1px solid ${theme.custom.greys.border}` }),
  },
}));

interface IconButtonProps extends Omit<IconButtonMUIProps, "children"> {
  icon: React.FC<IconProps> | OverridableComponent<SvgIconTypeMap>;
  noBorder?: boolean;
  strokeWidth?: number;
}

function IconButton({
  icon: Icon,
  noBorder = false,
  strokeWidth = 1.5,
  ...props
}: IconButtonProps) {
  const { classes } = useStyles({ noBorder });

  return (
    <IconButtonMUI className={classes.button} {...props}>
      <Icon size={16} strokeWidth={strokeWidth} />
    </IconButtonMUI>
  );
}

export default IconButton;
