import React, { useContext } from "react";

import Editor from "@monaco-editor/react";
import Paper from "@mui/material/Paper";
import { makeStyles } from "tss-react/mui";

import { UserWithData } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../common/api/paths";
import { GlobalStoreContext } from "../../../../../../GlobalStore/GlobalStore";
import adminIdentityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import { listUsersQueryKey } from "../../../../../../services/adminIdentityUsersQuery";
import { queryClient } from "../../../../../../services/queryClient";
import SquareChip from "../../../../../common/SquareChip";
import IdentityPoolUserSchemasTabDeleteButton from "./IdentityPoolUserSchemasTabDeleteButton";

const useStyles = makeStyles()(theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    flex: 1,
    marginBottom: 24,
    position: "relative",
  },
  editorContainer: {
    padding: 1,
    height: "100%",
  },
  editor: {
    ".margin": {
      ".line-numbers": {
        fontWeight: "bold",
      },
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 24px",
  },
  chip: {
    position: "absolute",
    bottom: 8,
    right: 20,
  },
}));

interface Props {
  user: UserWithData;
  poolId: string;
  noManagePermission: boolean;
}

const IdentityPoolUserJsonTab = ({ user, poolId, noManagePermission }: Props) => {
  const { classes } = useStyles();
  const globalStoreContext = useContext(GlobalStoreContext);

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <div className={classes.editorContainer}>
          <Editor
            theme="my-theme2"
            defaultLanguage="json"
            height={600}
            options={{
              minimap: {
                enabled: false,
              },
              scrollBeyondLastLine: false,
              folding: false,
              lineNumbersMinChars: 3,
              automaticLayout: true,
              readOnly: true,
            }}
            value={JSON.stringify(user, null, 2)}
            wrapperProps={{
              id: "identity-pool-user-editor",
            }}
            className={classes.editor}
          />
        </div>
        <SquareChip label="Read-only" className={classes.chip} />
      </Paper>
      <div className={classes.footer}>
        {!noManagePermission && (
          <IdentityPoolUserSchemasTabDeleteButton
            poolId={poolId}
            user={user}
            identifiers={user.identifiers ?? []}
            onDeleteUser={(id, poolId) =>
              adminIdentityUsersApi
                .deleteUser({ ipID: poolId, userID: id })
                .then(() => globalStoreContext.deletedUsers.addDeletedUserId(id))
                .then(() =>
                  queryClient.invalidateQueries({
                    queryKey: listUsersQueryKey(getTenantId(), poolId),
                  })
                )
            }
          />
        )}
      </div>
    </div>
  );
};

export default IdentityPoolUserJsonTab;
