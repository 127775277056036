import React from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";

import { IDPBase } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import { useFormContext } from "../../../common/utils/forms/Form";
import SelectField from "../../../common/utils/forms/SelectField";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import { useListPools } from "../../services/adminIdentityPoolsQuery";

interface Props {
  idps: IDPBase[];
  disabled?: boolean;
}

export default function InviteAdminPoolSelect({ idps, disabled }: Props) {
  const isAdminWorkspaceAccessEnabled = useFeature("admin_workspace_access");

  const identityPoolsQuery = useListPools({ tid: getTenantId(), limit: 100 });

  const { form } = useFormContext();

  const identityPoolOptions = idps.map(idp => ({
    value: idp.identity_pool_id ?? "",
    name:
      (identityPoolsQuery.data?.pools || []).find(pool => pool.id === idp.identity_pool_id)?.name ||
      idp.identity_pool_id ||
      "",
  }));

  return (
    <>
      {isAdminWorkspaceAccessEnabled && idps.length > 1 && (
        <SelectField
          name="identity_pool_id"
          label="Identity Pool"
          options={identityPoolOptions}
          onChange={() => {
            form.setValue("user", null);
          }}
          disabled={!!disabled}
          rules={{
            required: "Identity Pool is required",
          }}
        />
      )}

      {idps.length === 0 && (
        <Alert severity="warning" style={{ marginBottom: 16 }}>
          <AlertTitle>No users to add</AlertTitle>
          <Typography>There are no IDPs connected to admin workspace</Typography>
        </Alert>
      )}
    </>
  );
}
