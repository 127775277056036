import React from "react";

import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Trash } from "react-feather";

import { Claim } from "@cloudentity/acp-admin";

import Switch from "../../../../common/components/Switch";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import EnhancedTable from "../../common/EnhancedTable";
import { useWorkspace } from "../../common/useWorkspace";
import { ClaimType, prepareClaimForUpdate, sourceTypeOptions } from "./ClaimUtils";
import ScopeChip from "./ScopeChip";
import TabHeader from "./TabHeader";

const getHeadCells = (opaqueHeader: string) => [
  { id: "name", label: "Name", align: "left", sortable: true, style: { paddingLeft: 32 } },
  {
    id: "description",
    label: "Description",
    align: "left",
    sortable: true,
    style: { paddingLeft: 32 },
  },
  { id: "source_type", label: "Source type", align: "left" },
  { id: "source_path", label: "Source path", align: "left" },
  { id: "scoped", label: "Scopes", align: "left" },
  { id: "opaque", label: opaqueHeader, align: "center" },
  { id: "actions", label: "", align: "right" },
];

interface OAuthClaimsTabProps {
  claims: Claim[];
  fetching: boolean;
  highlightedItem: Claim | undefined;
  setShowCreateDialog: (value: { type: ClaimType } | undefined) => void;
  setShowEditDialog: (claim: Claim | undefined) => void;
  setRemoveConfirmDialog: (claim: Claim | undefined) => void;
  handleToggleClaimOpaque: (row: any) => void;
  type: ClaimType;
  title: string;
  id: string;
  progressRow: string | null;
  opaqueHeader: string;
  scopesNames: string[];
}

export default function OAuthClaimsTab({
  claims,
  fetching,
  highlightedItem,
  setShowCreateDialog,
  setShowEditDialog,
  setRemoveConfirmDialog,
  handleToggleClaimOpaque,
  type,
  title,
  id,
  progressRow,
  opaqueHeader,
  scopesNames,
}: OAuthClaimsTabProps) {
  const sourceTypeName = type => sourceTypeOptions.find(t => t.value === type)?.name;

  const [workspace] = useWorkspace();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  return (
    <div>
      <TabHeader
        onAdd={() => setShowCreateDialog({ type })}
        length={claims.length}
        subtitle={title}
        loading={fetching}
      />

      <EnhancedTable
        id={id}
        highlightedItem={highlightedItem}
        data={claims}
        headCells={getHeadCells(opaqueHeader)}
        loading={fetching}
        createRow={(row, _, classes, cx, highlighted) => (
          <TableRow
            key={row.id}
            hover={checkWorkspacePermissionsQuery.data?.update_workspace}
            onClick={
              checkWorkspacePermissionsQuery.data?.update_workspace
                ? () => setShowEditDialog(prepareClaimForUpdate(row))
                : undefined
            }
            className={cx(
              checkWorkspacePermissionsQuery.data?.update_workspace
                ? [classes.rowWithHoverIcons, classes.row]
                : []
            )}
            style={{
              height: 57,
              position: "relative",
              ...(highlighted && { backgroundColor: "#F7FAFF" }),
            }}
          >
            <TableCell style={{ paddingLeft: 32 }}>
              {progressRow === row.id && (
                <LinearProgress
                  style={{
                    position: "absolute",
                    width: "100%",
                    left: 0,
                    top: 0,
                  }}
                />
              )}
              {row.name}
            </TableCell>
            <TableCell style={{ paddingLeft: 32 }}>{row.description}</TableCell>
            <TableCell>{sourceTypeName(row.source_type)}</TableCell>
            <TableCell>{row.source_path}</TableCell>
            <TableCell>
              {row.scopes?.map(s => (
                <ScopeChip key={row.id + s} hasWarning={!scopesNames.includes(s)} scope={s} />
              ))}
            </TableCell>
            <TableCell>
              <Switch
                disabled={!!progressRow || !checkWorkspacePermissionsQuery.data?.update_workspace}
                checked={!!row.opaque}
                onClick={e => {
                  e.stopPropagation();
                  handleToggleClaimOpaque(row);
                }}
              />
            </TableCell>
            <TableCell align="right" padding="none" style={{ padding: "0 32px 0 5px" }}>
              {checkWorkspacePermissionsQuery.data?.update_workspace && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    setRemoveConfirmDialog(row);
                  }}
                  aria-label="delete"
                  className={classes.iconButton}
                  size="large"
                >
                  <Trash size="14" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        )}
        emptyStateText="No claims found"
      />
    </div>
  );
}
