import React from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import SelectablePaper from "../SelectablePaper";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    padding: "24px 16px",
    borderRadius: 4,
  },
  title: {
    fontWeight: "bold",
    fontSize: 12,
  },
  subtitle: {
    marginTop: 8,
    fontSize: 12,
    color: theme.palette.secondary.light,
  },
  avatar: {
    background: theme.palette.primary.main,
    width: 32,
    height: 32,
    borderRadius: 4,
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12,
  },
}));

interface Props {
  title: string;
  subtitle?: React.ReactElement | string;
  selected?: boolean;
  avatar?: string;
  img?: string;
  style?: any;
  id?: string;
  selectable?: boolean;
  disabled?: boolean;
  onSelect: () => void;
}

export default function FormCard({
  title,
  subtitle,
  selected,
  avatar,
  img,
  style = {},
  id = "",
  selectable = true,
  disabled,
  onSelect,
}: Props) {
  const { classes } = useStyles();

  return (
    <SelectablePaper
      id={id}
      data-testid={id}
      className={classes.root}
      selectable={selectable && !disabled}
      selected={selected}
      onSelect={onSelect}
      style={style}
      disabled={disabled}
    >
      <div className={classes.container}>
        {avatar && (
          <div className={classes.avatar}>
            <Typography variant="body1">{avatar}</Typography>
          </div>
        )}
        {img && <img src={img} style={{ width: 32, height: 32, marginRight: 12 }} alt={title} />}
        <div>
          <Typography className={classes.title}>{title}</Typography>
          {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
        </div>
      </div>
    </SelectablePaper>
  );
}
