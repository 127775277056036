import React, { useState } from "react";

import { AxiosResponse } from "axios";

import { ListClientsWithAccess } from "@cloudentity/acp-public";

import identitySelfApi from "../../services/identitySelfApi";
import Button from "../Button";
import ConfirmationDialog from "../ConfirmationDialog";
import { notifyErrorOrDefaultTo, notifySuccess } from "../notifications/notificationService";
import { ParsedClient } from "./utils";

interface Props {
  client: ParsedClient;
  fetchClients: (withSetter?: boolean) => Promise<AxiosResponse<ListClientsWithAccess, any> | null>;
}

export default function SelfServiceRevokeAccess({ client, fetchClients }: Props) {
  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  function handleClose() {
    setDialog(false);
  }

  function handleRevokeAccess() {
    setProgress(true);
    identitySelfApi
      .revokeClient({ cid: client.client?.id })
      .then(() => fetchClients(true))
      .then(() => notifySuccess("Access revoked"))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to revoke access"))
      .finally(() => setProgress(false));
  }

  return (
    <>
      <Button
        id="revoke-button"
        color="error"
        onClick={e => {
          e.stopPropagation();
          setDialog(true);
        }}
        style={{ background: "none" }}
        size="small"
      >
        Revoke
      </Button>

      {dialog && (
        <ConfirmationDialog
          title={`Revoke ${client.client?.name}`}
          content={<>Are you sure you want to perform this action?</>}
          onCancel={handleClose}
          onConfirm={handleRevokeAccess}
          progress={progress}
        />
      )}
    </>
  );
}
