import {
  PermissionsApi,
  PermissionsApiCheckTenantPermissionsRequest,
  TenantsApi,
  TenantsApiGetAdminTenantRequest,
  TenantsApiUpdateAdminTenantRequest,
} from "@cloudentity/acp-admin";
import {
  FeaturesApiFactory,
  LimitsApiFactory,
  SecureOptions,
  SecurityApiFactory,
  SetRateLimitRequest,
  SetTenantFeature,
  TenantsApiFactory as TenantsApiFactoryRoot,
  LicensesApiFactory,
  License,
} from "@cloudentity/acp-root";

import { getConfiguration } from "../../common/api/configuration";
import { acpBaseURI } from "../../common/api/paths";
import axiosInstance from "../adminAxiosInstance";
import { adminApiClazz } from "./admin-services.common";

const apiTenants: () => TenantsApi = adminApiClazz(TenantsApi);
const apiRootTenants = () => TenantsApiFactoryRoot(getConfiguration(), acpBaseURI, axiosInstance);
const apiFeatures = () => FeaturesApiFactory(getConfiguration(), acpBaseURI, axiosInstance);
const apiLimits = () => LimitsApiFactory(getConfiguration(), acpBaseURI, axiosInstance);
const apiPermissions: () => PermissionsApi = adminApiClazz(PermissionsApi);
const apiSecurity = () => SecurityApiFactory(getConfiguration(), acpBaseURI, axiosInstance);
const apiLicense = () => LicensesApiFactory(getConfiguration(), acpBaseURI, axiosInstance);

const adminTenantsApi = {
  getTenant: (req: TenantsApiGetAdminTenantRequest) => apiTenants().getAdminTenant(req),
  updateTenant: (req: TenantsApiUpdateAdminTenantRequest) => apiTenants().updateAdminTenant(req),

  getTenantSecurity: (tid: string) => apiSecurity().getTenantSecurity(tid),
  setTenantSecurity: (tid: string, setTenantSecurity?: SecureOptions) =>
    apiSecurity().setTenantSecurity(tid, setTenantSecurity),
  getSecurity: () => apiSecurity().getSecurity(),

  listTenants: () => apiRootTenants().listAdminTenants(),

  getTenantFeatures: (tid: string) => apiFeatures().getTenantFeatures(tid),
  setTenantFeature: (tid: string, feature: SetTenantFeature) =>
    apiFeatures().setTenantFeature(tid, feature),

  getLimits: (tid: string) => apiLimits().listRateLimits(tid),
  deleteLimit: (tid: string, module: string) => apiLimits().deleteRateLimit(tid, module),
  updateLimit: (tid: string, module: string, customRateLimit: SetRateLimitRequest) =>
    apiLimits().setRateLimit(tid, module, customRateLimit),

  checkTenantPermissions: (req: PermissionsApiCheckTenantPermissionsRequest) =>
    apiPermissions().checkTenantPermissions(req),

  getTenantLicense: (tid: string) => apiLicense().getTenantLicense(tid),
  setTenantLicense: (tid: string, license: License) => apiLicense().setTenantLicense(tid, license),
  getConfiguredLicenses: (tid: string) => apiLicense().getConfiguredLicenses(tid),
};

export default adminTenantsApi;
