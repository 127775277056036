import React, { CSSProperties } from "react";

import SelectField from "../../../../common/utils/forms/SelectField";

export const typeSelectOptions = [
  { name: "Email", value: "email" },
  { name: "Phone", value: "mobile" },
];

interface Props {
  id: string;
  name: string;
  disabled?: boolean;
  style?: CSSProperties;
}

export default function TypeSelect({ id, name, disabled, style }: Props) {
  return (
    <SelectField
      id={id}
      name={name}
      options={typeSelectOptions}
      size="small"
      style={{ marginBottom: 0, ...style }}
      rules={{
        required: "Value is required",
      }}
      disabled={disabled}
    />
  );
}
