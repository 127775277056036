import React from "react";

import { makeStyles } from "tss-react/mui";

import { previewCardWidth } from "./utils";

const useStyles = makeStyles()(() => ({
  preview: {
    width: previewCardWidth,
    height: "60vh",
    flex: "none",
    pointerEvents: "none",
    border: "solid 1px lightgray",
    borderRadius: 4,
    boxShadow:
      "0px 327px 92px 0px rgba(0, 0, 0, 0.00), 0px 210px 84px 0px rgba(0, 0, 0, 0.01), 0px 118px 71px 0px rgba(0, 0, 0, 0.05), 0px 52px 52px 0px rgba(0, 0, 0, 0.09), 0px 13px 29px 0px rgba(0, 0, 0, 0.10)",
  },
  previewLabelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  previewLabel: {
    borderRadius: "0 0 8px 8px",
    borderTop: "1px solid #F2F5FF",
    background: "linear-gradient(270deg, #F2F5FF 0%, #FFF 100%)",
    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.10) inset",
    display: "inline-block",
    padding: "2px 12px",
    position: "relative",
    fontSize: 12,
  },
  hidden: {
    visibility: "hidden",
  },
}));

interface Props {
  url: string;
  caption: string;
  innerRef: React.RefObject<HTMLIFrameElement>;
  isVisible: boolean;
  setIsIframeLoaded: (isIframeLoaded: boolean) => void;
}

const IdentitiesListSimplePreviewIframe = React.memo(
  ({ url, caption, innerRef, isVisible, setIsIframeLoaded }: Props) => {
    const { cx, classes } = useStyles();

    return (
      <div className={classes.previewLabelContainer}>
        <iframe
          src={url}
          width="100%"
          height="100%"
          title="preview"
          className={cx(classes.preview, !isVisible && classes.hidden)}
          ref={innerRef}
          onLoad={() => setIsIframeLoaded(true)}
        />
        <div className={cx(classes.previewLabel, !isVisible && classes.hidden)}>{caption}</div>
      </div>
    );
  }
);

export default IdentitiesListSimplePreviewIframe;
