import React, { useCallback } from "react";

import { makeStyles } from "tss-react/mui";

import FiltersToolbar from "../admin/components/common/EnhancedTableAsync/FiltersToolbar";
import AddB2BAdministratorDialog from "./AddB2BAdministratorDialog";

const useStyles = makeStyles()(() => ({
  toolbar: {
    paddingBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
}));

interface Props {
  search: string;
  onUpdateSearch: (search: string) => void;
}

export default function B2BOrganizationsDirectoryAdministratorsToolbar({
  search,
  onUpdateSearch,
}: Props) {
  const { classes } = useStyles();

  const handleUpdateFilters = useCallback(
    (search: string) => {
      onUpdateSearch(search);
    },
    [onUpdateSearch]
  );

  return (
    <div className={classes.toolbar}>
      <div>
        <FiltersToolbar
          filters={{}}
          filtersSetup={[]}
          searchText={search}
          searchPlaceholder="Search"
          onUpdate={handleUpdateFilters}
        />
      </div>

      <AddB2BAdministratorDialog />
    </div>
  );
}
