import { useQuery } from "@tanstack/react-query";

import { IdentityPoolRolesResponse, RolesApiListUserRolesRequest } from "@cloudentity/acp-admin";

import { portalMode } from "../../common/api/paths";
import { useFeature } from "../../common/utils/hooks/useFeature";
import adminRolesApi from "./adminRolesApi";
import {
  useQueryWithTenantPermissionCheck,
  useQueryWithWorkspacePermissionCheck,
  withQueryError,
} from "./queryUtils";

const LIST_TENANT_ROLES = "LIST_TENANT_ROLES";
const LIST_USER_ROLES = "LIST_USER_ROLES";
const LIST_WORKSPACE_ROLES = "LIST_WORKSPACE_ROLES";
const LIST_IDENTITY_POOL_ROLES = "LIST_IDENTITY_POOL_ROLES";

export const listTenantRoles = () => [LIST_TENANT_ROLES];
export const listUserRoles = (ipID, userID) => [LIST_USER_ROLES, ipID, userID];
export const listWorkspaceRoles = wid => [LIST_WORKSPACE_ROLES, wid];
export const listIdentityPoolRoles = ipID => [LIST_IDENTITY_POOL_ROLES, ipID];

export const useListTenantRoles = (options?: any) => {
  const isWithRolesEnabled = useFeature("with_roles");

  return useQueryWithTenantPermissionCheck(
    "read_roles",
    listTenantRoles(),
    withQueryError(async () => {
      const data = await adminRolesApi.listTenantRoles({});
      return data?.data;
    }, "Error occurred while trying to list tenant roles"),
    {
      ...options,
      enabled: (options?.enabled ?? true) && (portalMode === "b2b" || isWithRolesEnabled),
    }
  );
};

export const useListUserRoles = (req: RolesApiListUserRolesRequest, options?: any) => {
  const isWithRolesEnabled = useFeature("with_roles");

  return useQueryWithTenantPermissionCheck(
    "read_roles",
    listUserRoles(req.ipID, req.userID),
    withQueryError(async () => {
      const data = await adminRolesApi.listUserRoles(req);
      return data?.data;
    }, "Error occurred while trying to list user roles"),
    {
      ...options,
      enabled: (options?.enabled ?? true) && isWithRolesEnabled,
    }
  );
};

export const useListWorkspaceRoles = (wid: string, options?: any) => {
  const isWithRolesEnabled = useFeature("with_roles");

  return useQueryWithWorkspacePermissionCheck(
    wid,
    "read_roles",
    listWorkspaceRoles(wid),
    withQueryError(async () => {
      const data = await adminRolesApi.listWorkspaceRoles({ wid });
      return data?.data;
    }, "Error occurred while trying to list workspace roles"),
    {
      ...options,
      enabled: (options?.enabled ?? true) && (portalMode === "b2b" || isWithRolesEnabled),
    }
  );
};

export const useListIdentityPoolRoles = (ipID: string, options?: any) => {
  const isWithRolesEnabled = useFeature("with_roles");

  return useQuery<IdentityPoolRolesResponse>({
    queryKey: listIdentityPoolRoles(ipID),
    queryFn: withQueryError(async () => {
      const data = await adminRolesApi.listIdentityPoolRoles({ ipID });
      return data?.data;
    }, "Error occurred while trying to list identity pool roles"),
    ...{
      ...options,
      enabled: (options?.enabled ?? true) && (portalMode === "b2b" || isWithRolesEnabled),
    },
  });
};
