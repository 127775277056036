import React, { useMemo, useState } from "react";

import Paper from "@mui/material/Paper";

import { TestMFAMethodRequest } from "@cloudentity/acp-admin";

import FormSection from "../../../../common/components/FormSection";
import {
  notifyError,
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import PhoneField from "../../../../common/utils/forms/PhoneField";
import adminMfaMethodsApi from "../../../services/adminMfaMethodsApi";

export default function MessageProvidersPhoneTestMessage() {
  const [isSendingTestMessage, setIsSendingTestMessage] = useState(false);

  const initialDataTestMessage = useMemo(() => ({ phoneNumber: "" }), []);

  const form = useForm({
    id: "mfa-phone-test-message",
    initialValues: initialDataTestMessage,
    progress: isSendingTestMessage,
  });

  const testMFAMethod = (mFAMethodTest: TestMFAMethodRequest) => {
    setIsSendingTestMessage(true);
    adminMfaMethodsApi
      .testMFAMethod({ mfaID: "sms", mFAMethodTest: mFAMethodTest })
      .then(() => notifySuccess("Message sent"))
      .catch(err => {
        if (
          err.response?.data?.status_code === 429 &&
          err.response?.data?.error === "too many requests"
        ) {
          notifyError("Please wait a few seconds before sending a new test message");
          return err;
        }

        return notifyErrorOrDefaultTo("Error occurred while trying to send test message")(err);
      })
      .finally(() => setIsSendingTestMessage(false));
  };

  return (
    <Paper style={{ padding: 32 }}>
      <FormSection id="mfa-phone-send-message-label" title="Send test message" />
      <Form form={form}>
        <PhoneField
          name="phoneNumber"
          label="SMS recipient"
          selector="#mfa-phone-test-message-phone-input"
          style={{ marginBottom: 52 }}
          required
        />

        <FormFooter
          onSubmit={data => testMFAMethod({ address: data.phoneNumber })}
          submitText="Send"
        />
      </Form>
    </Paper>
  );
}
