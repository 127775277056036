import React, { ReactNode } from "react";

import Progress from "@mui/material/CircularProgress";
import MenuMUI, { MenuProps as MenuMUIProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ dark: boolean }>()((theme, { dark }) => ({
  menuPaper: {
    boxShadow: theme.custom.shadows.menu,
    minWidth: 180,
    "& .MuiList-padding": {
      padding: 0,
    },
    ...(dark
      ? {
          backgroundColor: theme.palette.secondary.dark,
          color: "white",
        }
      : {}),
  },
  menuItem: {
    padding: "12px 16px",
    borderBottom: "solid 1px #ececec",
    "&:last-of-type": {
      borderBottom: "none",
    },
    ...(dark
      ? {
          borderBottomColor: "#4c4c4c",
        }
      : {}),
  },
  menuProgress: {
    width: 172,
    height: 232,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface MenuProps extends Omit<MenuMUIProps, "open"> {
  header?: ReactNode;
  onClose?: () => void;
  items?: { onClick: () => void; label: string | ReactNode; id: string; disabled?: boolean }[];
  dark?: boolean;
  loading?: { isLoading: boolean; container?: { width: number; height: number } };
  children?: React.ReactNode;
}

const Menu = ({
  id,
  onClose,
  items = [],
  anchorEl,
  dark = false,
  loading,
  children,
  ...props
}: MenuProps) => {
  const { classes } = useStyles({ dark });
  return (
    <MenuMUI
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={{ paper: classes.menuPaper }}
      {...props}
    >
      {loading?.isLoading ? (
        <MenuItem
          disabled
          className={classes.menuProgress}
          style={
            loading.container
              ? { width: loading.container.width, height: loading.container.height }
              : {}
          }
        >
          <Progress size={32} />
        </MenuItem>
      ) : (
        items.map(item => (
          <MenuItem
            className={classes.menuItem}
            id={`${id}-${item.id}`}
            key={`${id}-${item.id}`}
            onClick={item.onClick}
            disabled={item.disabled}
          >
            {item.label}
          </MenuItem>
        ))
      )}
      {children}
    </MenuMUI>
  );
};

export default Menu;
