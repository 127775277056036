import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";

import { BaseUserWithData, UserWithData } from "@cloudentity/acp-identity";

import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../../common/components/notifications/notificationService";
import identityOTPApi from "../../../../../../../services/adminIdentityOTPApi";
import { useGetPool } from "../../../../../../../services/adminIdentityPoolsQuery";
import IdentityPoolThemeSelectorDialog from "../../../IdentityPoolThemeSelectorDialog";
import { useIdentityPoolThemeSelector } from "../../../useIdentityPoolThemeSelector";

interface Props {
  user: UserWithData | BaseUserWithData;
  workspaceId?: string;
  address: string;
}

const IdentityPoolUserSidePanelVerifyAddress = ({ user, workspaceId, address }: Props) => {
  const [progress, setProgress] = useState(false);

  const getPoolQuery = useGetPool(user?.user_pool_id || "");
  const { mustSelectThemeServerId, openDialog, closeDialog, isDialogOpen, themeWorkspaceId } =
    useIdentityPoolThemeSelector({ pool: getPoolQuery.data });

  const handleVerifyAddress = (serverId?: string) => {
    setProgress(true);
    identityOTPApi
      .requestAddressVerification({
        ipID: user?.user_pool_id!,
        userID: user?.id!,
        requestAddressVerification: { address },
        serverId: serverId || workspaceId,
      })
      .then(() => notifySuccess("Address verification requested"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to request address verification"))
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <>
      <MenuItem
        id="verify-address-button"
        onClick={() => {
          if (mustSelectThemeServerId) {
            openDialog();
            return;
          }
          handleVerifyAddress(themeWorkspaceId);
        }}
        disabled={progress}
      >
        Verify Address
      </MenuItem>
      {isDialogOpen && getPoolQuery.data && (
        <IdentityPoolThemeSelectorDialog
          pool={getPoolQuery.data}
          onCancel={() => closeDialog()}
          onSubmit={serverId => {
            handleVerifyAddress(serverId);
            closeDialog();
          }}
        />
      )}
    </>
  );
};

export default IdentityPoolUserSidePanelVerifyAddress;
