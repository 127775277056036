import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import { useQueryClient } from "@tanstack/react-query";

import { OrganizationResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import EmptyState from "../../../../common/components/EmptyState";
import OrganizationTemplateIcon from "../../../../common/components/icons/OrganizationTemplateIcon";
import { getFromLocalStorage } from "../../../../common/utils/localStorage.utils";
import { listWorkspacesQueryKey } from "../../../services/adminServersQuery";
import { useCheckTenantPermissions, useGetTenant } from "../../../services/adminTenantsQuery";
import {
  useInfiniteScrollForItemsWithQuery,
  UseItemsReturnType,
} from "../../common/EnhancedTableAsync/useItemsWithQuery";
import useOrganizationsSeqOrCursor from "../../common/EnhancedTableAsync/useOrganizationsSeqOrCursor";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";
import TenantAddOrganization from "../../organizations/TenantAddOrganization";
import { OrganizationsDirectoryTab } from "../workspace-directory.common";
import CreateOrganizationTemplateDialog from "./CreateOrganizationTemplateDialog";
import OrganizationCard from "./OrganizationCard";
import OrganizationCardMenu from "./OrganizationCardMenu";
import OrganizationsManagementSettings from "./OrganizationsManagementSettings";
import OrganizationsToolbar from "./OrganizationsToolbar";
import TenantOrganizationsTab from "./TenantOrganizationsTab";
import { GridMode, TENANT_ORGANIZATIONS_GRID_MODE_KEY } from "./utils";

interface Props {
  cardProgress: string;
  deleteProgress: string;
  onSelectServer: (id: string) => void;
  allData: UseItemsReturnType<OrganizationResponse>;
  templateData?: UseItemsReturnType<OrganizationResponse>;
}

function OrganizationsController({
  cardProgress,
  deleteProgress,
  onSelectServer,
  allData,
  templateData,
}: Props) {
  const navigate = useNavigate();
  const [workspaceMenu, setWorkspaceMenu] = useState<{
    anchorEl: HTMLElement | null;
    server: OrganizationResponse;
    rootServer?: OrganizationResponse | WorkspaceResponse | undefined;
  }>();
  const { tab } = useParams<{ tab: OrganizationsDirectoryTab }>();
  const [createOrganizationDialog, setCreateOrganizationDialog] = useState<{
    parentId?: string;
  } | null>(null);
  const [createTemplateDialog, setCreateTemplateDialog] = useState(false);

  const queryClient = useQueryClient();
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations = checkTenantPermissionsQuery.data?.list_organizations;
  const getTenantQuery = useGetTenant(getTenantId());

  const defaultGridMode =
    (canListOrganizations &&
      (getFromLocalStorage(TENANT_ORGANIZATIONS_GRID_MODE_KEY) as GridMode)) ||
    "cards";
  const [gridMode, setGridMode] = useState<GridMode>(defaultGridMode);

  const handleTabChange = useCallback(
    (tab: OrganizationsDirectoryTab) => {
      navigate(`/organization-directory/${tab}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (!Object.values(OrganizationsDirectoryTab).includes(tab as OrganizationsDirectoryTab)) {
      handleTabChange(OrganizationsDirectoryTab.ORGANIZATIONS);
    }
  }, [tab, handleTabChange]);

  return (
    <>
      <PageHeader<OrganizationsDirectoryTab>
        title="Organization Management"
        onChangeTab={handleTabChange}
        currentTab={tab as OrganizationsDirectoryTab}
        tabs={[
          <Tab
            label="Organizations"
            value={OrganizationsDirectoryTab.ORGANIZATIONS}
            key="organizations"
            id="organizations-tab"
          />,
          ...(templateData && canListOrganizations
            ? [
                <Tab
                  label="Templates"
                  value={OrganizationsDirectoryTab.ORGANIZATIONS_TEMPLATES}
                  key="templates"
                  id="templates-tab"
                />,
              ]
            : []),
          <Tab
            label="Settings"
            value={OrganizationsDirectoryTab.ORGANIZATIONS_SETTINGS}
            key="settings"
            id="settings-tab"
          />,
        ]}
      />
      {tab === OrganizationsDirectoryTab.ORGANIZATIONS && (
        <TenantOrganizationsTab
          cardProgress={cardProgress}
          deleteProgress={deleteProgress}
          onSelectServer={onSelectServer}
          allData={allData}
          templateData={templateData}
          onMenuOpen={setWorkspaceMenu}
          gridMode={gridMode}
          setGridMode={setGridMode}
        />
      )}
      {tab === OrganizationsDirectoryTab.ORGANIZATIONS_TEMPLATES && templateData && (
        <PageContent progress={allData.firstPageLoading || templateData?.firstPageLoading}>
          {templateData.totalData.length > 0 ||
          templateData.areFiltersApplied ||
          templateData.searchText ? (
            <>
              <OrganizationsToolbar
                createButtonLabel="Create Template"
                searchText={allData.searchText}
                gridModeLocalStorageKey={TENANT_ORGANIZATIONS_GRID_MODE_KEY}
                onUpdate={
                  canListOrganizations
                    ? text => {
                        allData.onFiltersUpdate(text, allData.filters);
                        templateData?.onFiltersUpdate(text, templateData.filters);
                      }
                    : undefined
                }
                onCreate={
                  checkTenantPermissionsQuery.data?.create_workspace
                    ? () => setCreateTemplateDialog(true)
                    : undefined
                }
              />
              <Grid container spacing={3} style={{ marginBottom: 32 }}>
                {templateData.totalData.map(server => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={3} key={server.id}>
                      <OrganizationCard
                        server={server}
                        tenant={getTenantQuery.data}
                        progress={cardProgress === server.id}
                        deleteProgress={server.id === deleteProgress}
                        onSelectServer={onSelectServer}
                        onMenuOpen={setWorkspaceMenu}
                      />
                    </Grid>
                  );
                })}
                {templateData.nextPageAvailable && (
                  <Grid item xs={12}>
                    <LoadingButton
                      id="load-more-templates-button"
                      variant="outlined"
                      onClick={() => templateData.onLastPage()}
                      loading={templateData.isFetching}
                    >
                      Load more
                    </LoadingButton>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <EmptyState
              style={{ marginTop: 80 }}
              icon={OrganizationTemplateIcon}
              title="No Organization Templates Defined Yet"
              description="Create an organization template to easily onboard organizations with the stored configuration"
              buttonContent="+ Create Template"
              onSubmit={
                checkTenantPermissionsQuery.data?.create_organization
                  ? () => setCreateTemplateDialog(true)
                  : undefined
              }
            />
          )}
        </PageContent>
      )}

      {tab === OrganizationsDirectoryTab.ORGANIZATIONS_SETTINGS && (
        <OrganizationsManagementSettings />
      )}

      {workspaceMenu && (
        <OrganizationCardMenu
          workspace={workspaceMenu.server}
          onSelectServer={onSelectServer}
          handleClose={() => setWorkspaceMenu(p => (p ? { ...p, anchorEl: null } : undefined))}
          mainMenuAnchorEl={workspaceMenu.anchorEl}
          gridMode={gridMode}
          withChangeParentOrganization={
            !!workspaceMenu.server.parent_id &&
            gridMode === "hierarchy" &&
            tab === OrganizationsDirectoryTab.ORGANIZATIONS
          }
          rootServer={workspaceMenu.rootServer}
          withToggleAsTemplate={tab === OrganizationsDirectoryTab.ORGANIZATIONS}
          withEdit
          withCreateSuborganization
          onCreate={parentId => setCreateOrganizationDialog({ parentId })}
        />
      )}

      {createOrganizationDialog && (
        <TenantAddOrganization
          onCancel={() => {
            setCreateOrganizationDialog(null);
          }}
          onCreated={() => {
            queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() });
          }}
          onSkip={() => setCreateOrganizationDialog(null)}
          onInviteSent={() => setCreateOrganizationDialog(null)}
          workspaceParentId={createOrganizationDialog.parentId}
        />
      )}

      {createTemplateDialog && (
        <CreateOrganizationTemplateDialog
          onCancel={() => {
            setCreateTemplateDialog(false);
          }}
          onCreated={() => {
            setCreateTemplateDialog(false);
          }}
        />
      )}
    </>
  );
}

export default function TenantOrganizations(props: Omit<Props, "allData">) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations = checkTenantPermissionsQuery.data?.list_organizations;

  if (canListOrganizations) {
    return <OrganizationsControllerWithSeq {...props} />;
  }
  return <OrganizationsControllerWithCursor {...props} />;
}

function OrganizationsControllerWithSeq(props: Omit<Props, "allData">) {
  const allData = useOrganizationsSeqOrCursor({ forceMode: "seq", template: false });
  const templateData = useOrganizationsSeqOrCursor({ forceMode: "seq", template: true });

  return <OrganizationsController {...props} allData={allData} templateData={templateData} />;
}

function OrganizationsControllerWithCursor(props: Omit<Props, "allData">) {
  const allData = useOrganizationsSeqOrCursor({ forceMode: "cursor" });

  useInfiniteScrollForItemsWithQuery({ onLastPage: allData.onLastPage });

  return <OrganizationsController {...props} allData={allData} />;
}
