// import QuickstartArticleApi from "./QuickstartArticleApi";
import QuickstartArticleBranding from "./QuickstartArticleBranding";
// import QuickstartArticleIntegrateApis from "./QuickstartArticleIntegrateApis";
import QuickstartArticleIntegrateApp from "./QuickstartArticleIntegrateApp";
import QuickstartArticleMFA from "./QuickstartArticleMFA";
import QuickstartArticleOIDC from "./QuickstartArticleOIDC";
import QuickstartArticleOrchestrated from "./QuickstartArticleOrchestrated";
import QuickstartArticleSelfService from "./QuickstartArticleSelfService";
import QuickstartArticleSetUpMethods from "./QuickstartArticleSetUpMethods";
import QuickstartArticleUserOnboarding from "./QuickstartArticleUserOnboarding";

export const articles = [
  {
    id: "set-up-methods",
    title: "Set up authentication methods",
    description:
      "Easily configure and integrate the authentication methods and providers to authenticate end users.",
    component: QuickstartArticleSetUpMethods,
    videoUrl: "https://docs.secureauth.com/resources/videos/setup_authentication_methods.mp4",
  },
  {
    id: "user-onboarding",
    title: "User Onboarding and Management",
    description:
      "Easily configure user attributes, onboard users using multiple mechanisms and enable administrators to manage them easily.",
    component: QuickstartArticleUserOnboarding,
    videoUrl: "https://docs.secureauth.com/resources/videos/user_onboarding_and_management.mp4",
  },
  {
    id: "integrate-app",
    title: "Integrate your business application",
    description:
      "Integrate your business applications using standard protocols such as OAuth, SAML, and OpenID Connect, along with capability for custom API-based integrations.",
    component: QuickstartArticleIntegrateApp,
    videoUrl:
      "https://docs.secureauth.com/resources/videos/all-integrate_your_business_application.mp4",
  },
  {
    id: "oidc-claims-and-saml-assertions",
    title: "OIDC claims and SAML assertions",
    description:
      "SecureAuth platform is an OpenID Identity provider as well as SAML Identity provider. Check the configurations to configure and consume claims/assertions from relying parties",
    component: QuickstartArticleOIDC,
    videoUrl: "https://docs.secureauth.com/resources/videos/OIDC+claims+and+SAML+assertions.mp4",
  },
  {
    id: "adaptive-mfa",
    title: "Adaptive Multi-Factor Authentication (MFA)",
    description:
      "Enhance security with context-based MFA. Our system dynamically adapts to user behavior and risk, offering more robust protection while maintaining a seamless user experience.",
    component: QuickstartArticleMFA,
    videoUrl:
      "https://docs.secureauth.com/resources/videos/Adaptive+Multi-Factor+Authentication+(MFA).mp4",
  },
  {
    id: "orchestrated-user-flows",
    title: "Orchestrated User Flows",
    description:
      "Verify user identities by integrating with external identity verification providers, aggregate data from multiple API sources, and guide users through custom experience flows to gather information for progressive profiling or context collection, enabling personalized and orchestrated workflows for each user.",
    component: QuickstartArticleOrchestrated,
    videoUrl: "https://docs.secureauth.com/resources/videos/Orchestrated+User+Flows.mp4",
  },
  // {
  //   id: "api-authorization-and-security",
  //   title: "API Authorization and Security",
  //   description:
  //     "Secure your APIs with API level access policies and enforced by SecureAuth microperimeter at your API Gateway edge or closer to your actual workloads.",
  //   component: QuickstartArticleApi,
  // },
  // {
  //   id: "integrate-with-apis",
  //   title: "Want to integrate with our APIs?",
  //   description:
  //     "SecureAuth provides all capabilities through APIs, allowing you to integrate them directly into your applications and workflows. If you wish to proceed this way, please follow our guide on how to utilize all our APIs.",
  //   component: QuickstartArticleIntegrateApis,
  // },
  {
    id: "self-service",
    title: "Self-Service User Management",
    description:
      "Empower users with self-service account management. Let them update their profile, reset passwords, and manage consents, reducing admin burden and improving user satisfaction.",
    component: QuickstartArticleSelfService,
    videoUrl:
      "https://docs.secureauth.com/resources/videos/Self-Service+User+Management+Flow+Full.mp4",
  },
  {
    id: "branding",
    title: "Configure branding",
    description:
      "Customize look and feel of your client-facing web pages such as Login Page, Sign up and Password Reset",
    component: QuickstartArticleBranding,
    videoUrl: "https://docs.secureauth.com/resources/videos/configure_branding.mp4",
  },
];
