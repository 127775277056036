import React, { useCallback, useState } from "react";

import MenuItem from "@mui/material/MenuItem";

import {
  BaseUserWithData,
  BaseUserWithDataStatusEnum,
  UserWithData,
  UserWithDataStatusEnum,
} from "@cloudentity/acp-identity";

import ConfirmationDialog from "../../../../common/components/ConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { useCheckPoolPermissions } from "../../../services/adminIdentityPoolsQuery";

interface Props {
  user?: UserWithData | BaseUserWithData;
  onDeleted: () => void;
  onCloseConfirmation: () => void;
  onDeleteUser: (
    id: string,
    poolId: string
  ) => Promise<void | { status: UserWithDataStatusEnum | BaseUserWithDataStatusEnum }>;
}

export default function ManageUserActionsDeleteUser({
  user,
  onDeleteUser,
  onDeleted,
  onCloseConfirmation,
}: Props) {
  const [progress, setProgress] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const handleConfirm = useCallback(() => {
    setProgress(true);
    onDeleteUser(user?.id!, user?.user_pool_id!)
      .then(() => notifySuccess("User successfully deleted"))
      .then(() => onDeleted())
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to delete user"))
      .finally(() => {
        setProgress(false);
        setConfirmation(false);
      });
  }, [user, onDeleted, onDeleteUser]);

  const checkPoolPermissionsQuery = useCheckPoolPermissions(user?.user_pool_id!, {
    enabled: !!user?.user_pool_id,
  });
  if (!checkPoolPermissionsQuery.data?.manage_identity_pool_users) {
    return null;
  }

  return (
    <>
      <MenuItem
        id="delete-user-button"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setConfirmation(true);
        }}
      >
        Delete User
      </MenuItem>
      {confirmation && (
        <ConfirmationDialog
          title="Delete User"
          content={
            <div>
              You're about to delete user with <b>{user?.identifiers?.at(0)?.identifier ?? ""}</b>{" "}
              identifier. This cannot be undone. Delete anyway?
            </div>
          }
          confirmText="Delete"
          progress={progress}
          onCancel={() => {
            setConfirmation(false);
            onCloseConfirmation();
          }}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
}
