import React, { useState } from "react";

import Button from "@mui/material/Button";

import RemoveConfirmationDialog from "../../../common/components/RemoveConfirmationDialog";
import FormFooter from "../../../common/utils/forms/FormFooter";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { IdpUiModelType } from "./identities.utils";
import TrySignInButton from "./identities_view_tabbed/TrySignInButton";

interface Props<T extends IdpUiModelType> {
  customSubmit?: boolean;
  idp: T;
  onSubmit: (data: T) => void;
  onCancel?: () => void;
  onDelete?: (idp: T) => void;
}

function IdentitiesDetailsFooter<T extends IdpUiModelType>({
  customSubmit,
  idp,
  onSubmit,
  onCancel,
  onDelete,
}: Props<T>) {
  const [removeDialog, setRemoveDialog] = useState(false);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(idp.authorization_server_id);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: !customSubmit ? 24 : "unset",
        }}
      >
        {onDelete && checkWorkspacePermissionsQuery.data?.manage_idps ? (
          <Button
            variant="text"
            id="gateway-delete"
            color="error"
            onClick={() => setRemoveDialog(true)}
          >
            Delete Identity
          </Button>
        ) : (
          <div />
        )}

        {!customSubmit && (
          <div style={{ display: "flex", alignItems: "center" }}>
            {!onCancel && (
              <TrySignInButton
                serverId={idp.authorization_server_id ?? ""}
                idpHint={idp.id}
                variant="text"
                size="small"
              />
            )}

            <FormFooter onSubmit={onSubmit} onCancel={onCancel} />
          </div>
        )}
      </div>

      {removeDialog && (
        <RemoveConfirmationDialog
          title="Delete Identity"
          type="identity"
          name={idp.name ?? ""}
          onCancel={() => setRemoveDialog(false)}
          onConfirm={() => onDelete && onDelete(idp)}
        />
      )}
    </>
  );
}

export default IdentitiesDetailsFooter;
