import React, { CSSProperties, useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import { AccessorFnColumnDef } from "@tanstack/react-table";
import { makeStyles } from "tss-react/mui";

import RowEmptyState from "../../../../common/components/RowEmptyState";
import { getHoverRowStyles } from "../../../../common/styles/tableStyles";
import { updateTableLimitInLocalStorage } from "../EnhancedTable";
import FiltersToolbar, { Filters, FiltersSetup } from "./FiltersToolbar";
import ToolbarAction from "./ToolbarAction";

export type HeadCellsType = {
  id: string;
  path?: string;
  label?: string | JSX.Element;
  sortable?: boolean;
  align?: "left" | "right" | "center";
  width?: number | string;
  minWidthCell?: number;
  hidden?: boolean;
  tooltip?: string;
  colSpan?: number;
  type?: "filters";
  style?: CSSProperties;
  columnFilters?: {
    initialActiveColumns: string[];
    columns: AccessorFnColumnDef<any, any>[];
    activeColumns: string[];
    onSetActiveColumns: (activeColumns: string[], reset?: boolean) => void;
  };
}[];

interface EnhancedTableAsyncHeadProps {
  classes: any;
  onRequestSort: (event: React.MouseEvent<EventTarget>, property: string) => void;
  order: "asc" | "desc";
  orderBy: string;
  headCells: HeadCellsType;
}

function EnhancedTableAsyncHead({
  classes,
  order,
  orderBy,
  onRequestSort,
  headCells,
}: EnhancedTableAsyncHeadProps) {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead data-testid="table-head">
      <TableRow>
        {headCells
          .filter(headCell => !headCell.hidden)
          .map(headCell => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              align={headCell.align}
              {...(headCell.minWidthCell ? { className: classes.minWidthCell } : {})}
              width={headCell.width}
              colSpan={headCell.colSpan}
            >
              <Tooltip
                title={headCell.tooltip || ""}
                disableHoverListener={!headCell.tooltip}
                placement="top-start"
              >
                <div>
                  {headCell.sortable && (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span
                          className={classes.visuallyHidden}
                          data-testid={`header-sorting-${headCell.id}`}
                        >
                          {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  )}
                  {!headCell.sortable && headCell.label}
                </div>
              </Tooltip>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles()((theme, _, classes) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  minWidthCell: {
    width: "1%",
    whiteSpace: "nowrap",
  },
  firstLine: {
    fontWeight: 500,
    color: theme.palette.secondary.dark,
  },
  secondLine: {
    color: theme.palette.secondary.light,
  },
  iconButton: {
    border: "1px solid #ECECEC",
  },
  progressContainer: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    top: -1,
    left: -1,
    right: -1,
    bottom: -1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toolbarContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
    width: "100%",
  },
  ...getHoverRowStyles(classes),
}));

export interface EnhancedTableAsyncProps {
  data: any[];
  loading: boolean;
  createRow: (row: any, index?: number, classes?: any, cx?: any) => JSX.Element | string;
  headCells: HeadCellsType;
  sort: string;
  order: "asc" | "desc";
  page: number;
  limit: number;
  nextPageAvailable: boolean;
  onPaginationUpdate: (
    sort: string,
    order: "asc" | "desc",
    page: number,
    limit: number,
    afterDelete?: boolean
  ) => void;
  onFiltersUpdate: (searchPhrase: string, filters: Filters) => void;
  id: string;
  emptyStateText?: string;
  filters: Filters;
  filtersSetup: FiltersSetup;
  searchText: string;
  searchPlaceholder: string;
  actionButtonLabel?: string;
  onToolbarAction?: () => void;
  noToolbar?: boolean;
  customToolbar?: React.ReactNode;
  toolbarStyle?: CSSProperties;
}

export default function EnhancedTableAsync({
  data,
  loading,
  createRow,
  headCells,
  emptyStateText = "No data",
  id,
  sort,
  order,
  page,
  limit,
  nextPageAvailable,
  onPaginationUpdate,
  onFiltersUpdate,
  filters,
  filtersSetup,
  searchText,
  searchPlaceholder,
  actionButtonLabel,
  onToolbarAction,
  noToolbar = false,
  customToolbar,
  toolbarStyle,
}: EnhancedTableAsyncProps) {
  const { cx, classes } = useStyles();

  const handleRequestSort = () => {
    onPaginationUpdate(sort, order === "asc" ? "desc" : "asc", page, limit);
  };

  const handleChangePage = (_, newPage) => {
    onPaginationUpdate(sort, order, newPage, limit);
  };

  const handleChangeRowsPerPage = event => {
    const newLimit = parseInt(event.target.value, 10);
    updateTableLimitInLocalStorage(id, newLimit);
    onPaginationUpdate(sort, order, page, newLimit);
  };

  useEffect(() => {
    if (!data.length && !loading && page > 0) {
      onPaginationUpdate(sort, order, page - 1, limit, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, loading, sort, order, page, limit]);

  return (
    <div>
      {(!noToolbar || customToolbar || onToolbarAction) && (
        <div className={classes.toolbarContainer} style={toolbarStyle}>
          <>
            {!noToolbar && (
              <FiltersToolbar
                filters={filters}
                filtersSetup={filtersSetup}
                searchText={searchText}
                searchPlaceholder={searchPlaceholder}
                onUpdate={onFiltersUpdate}
              />
            )}
            {customToolbar}
          </>
          {onToolbarAction && (
            <div>
              <ToolbarAction
                actionButtonLabel={actionButtonLabel || ""}
                onClick={onToolbarAction}
              />
            </div>
          )}
        </div>
      )}
      <Paper style={{ position: "relative" }}>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table" id={id}>
            <EnhancedTableAsyncHead
              classes={classes}
              order={order}
              orderBy={sort}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {data.map((row, index) => createRow(row, index, classes, cx))}
              {data.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={headCells.reduce((acc, curr) => (acc += curr.colSpan ?? 1), 0)}
                    style={{ padding: 0 }}
                  >
                    <RowEmptyState text={emptyStateText} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 75]}
                  count={-1}
                  labelDisplayedRows={() => ""}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ disabled: !nextPageAvailable }}
                  data-testid="pagination"
                  SelectProps={{ "data-testid": "pagination-select" } as any}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        {loading && (
          <div className={classes.progressContainer}>
            <CircularProgress />
          </div>
        )}
      </Paper>
    </div>
  );
}
