import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { GrantTenantRoleRequestTypeEnum } from "@cloudentity/acp-admin";
import { BaseNewUserPayloadStatusEnum, BaseUserWithData } from "@cloudentity/acp-identity";

import { useWorkspaceDefaultClientApp } from "../../../admin/components/common/useWorkspaceDefaultClientApp";
import { isAnyWorkspaceOrPoolRoleAllowed } from "../../../admin/components/workspaceDirectory/administrator/WorkspaceRoleSelectField";
import IdentityPoolUserCreate, {
  CreateData,
} from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/create/IdentityPoolUserCreate";
import { grantWorkspaceRoles } from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/list/utils";
import adminB2BUsersApi from "../../../admin/services/adminB2BUsersApi";
import {
  useCheckPoolPermissions,
  useGetPool,
} from "../../../admin/services/adminIdentityPoolsQuery";
import { useGetWorkspaceSchema } from "../../../admin/services/adminIdentitySchemasQuery";
import identityUsersApi from "../../../admin/services/adminIdentityUsersApi";
import {
  getB2BUserQueryKey,
  listUsersQueryKey,
} from "../../../admin/services/adminIdentityUsersQuery";
import { useCheckWorkspacePermissions } from "../../../admin/services/adminPermissionsQuery";
import adminRolesApi from "../../../admin/services/adminRolesApi";
import { listTenantRoles, listUserRoles } from "../../../admin/services/adminRolesQuery";
import { getTenantId } from "../../../common/api/paths";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { trimStringValues } from "../../../common/utils/object.utils";

const initialData = {
  identifier: "email",
  email: "",
  phone: "",
  role: "",
  roles: [],
  mode: "invite",
  password: "",
  temporaryPassword: true,
};

interface Props {
  workspaceId: string;
  identityPoolID: string;
  onCreated: (user: BaseUserWithData, addAnotherUser: boolean) => void;
  onClose: () => void;
}

export default function B2BUserCreate({ workspaceId, identityPoolID, onCreated, onClose }: Props) {
  const [progress, setProgress] = useState(false);
  const queryClient = useQueryClient();

  const defaultWorkspaceClientApp = useWorkspaceDefaultClientApp(workspaceId);

  const checkWorkspacePermissions = useCheckWorkspacePermissions(workspaceId);
  const checkPoolPermissions = useCheckPoolPermissions(identityPoolID);

  const poolQuery = useGetPool(identityPoolID);

  const payloadSchemaQuery = useGetWorkspaceSchema(workspaceId, poolQuery.data?.payload_schema_id, {
    enabled: poolQuery.isSuccess,
  });
  const metadataSchemaQuery = useGetWorkspaceSchema(
    workspaceId,
    poolQuery.data?.metadata_schema_id,
    {
      enabled: poolQuery.isSuccess,
    }
  );
  const businessMetadataSchemaQuery = useGetWorkspaceSchema(
    workspaceId,
    poolQuery.data?.business_metadata_schema_id,
    {
      enabled: poolQuery.isSuccess,
    }
  );

  const handleCreate = ({
    mode,
    password,
    email,
    phone,
    identifier,
    payload,
    metadata,
    businessMetadata,
    role,
    roles,
    addAnotherItem,
  }: CreateData) => {
    const selectedIdentifier = (identifier === "email" ? email?.trim() : phone) ?? "";

    const status =
      mode === "invite" ? BaseNewUserPayloadStatusEnum.New : BaseNewUserPayloadStatusEnum.Active;
    const verified = mode !== "invite";
    const credentials =
      mode === "invite"
        ? []
        : [
            {
              type: "password" as any,
              password: password,
            },
          ];

    setProgress(true);

    const req = {
      ipID: identityPoolID,
      newUser: {
        payload: payload && trimStringValues(payload),
        status: status,
        credentials: credentials,
        identifiers: [
          {
            identifier: selectedIdentifier,
            type: identifier as any,
          },
        ],
        verifiable_addresses: [
          {
            address: selectedIdentifier,
            status: "active" as any,
            type: identifier as any,
            verified,
          },
        ],
      },
    };

    let createdUser: BaseUserWithData;
    return adminB2BUsersApi
      .createB2BUser(req)
      .then(res => {
        createdUser = res.data;
        return queryClient.setQueryData(
          getB2BUserQueryKey(getTenantId(), createdUser.id),
          createdUser
        );
      })
      .then(() => {
        if (checkPoolPermissions.data?.b2b_manage_admin_metadata) {
          return identityUsersApi.setUserMetadata({
            userID: createdUser?.id!,
            ipID: createdUser?.user_pool_id,
            metadataType: "admin",
            userMetadata: { metadata: metadata && trimStringValues(metadata) },
          });
        }
        return Promise.resolve({});
      })
      .then(() => {
        if (checkPoolPermissions.data?.b2b_manage_business_metadata) {
          return identityUsersApi.setUserMetadata({
            userID: createdUser?.id!,
            ipID: createdUser?.user_pool_id,
            metadataType: "business",
            userMetadata: { metadata: businessMetadata && trimStringValues(businessMetadata) },
          });
        }
        return Promise.resolve({});
      })
      .then(() => {
        if (mode === "invite") {
          return identityUsersApi
            .sendActivationMessage({
              ipID: identityPoolID,
              userID: createdUser.id!,
              serverId: workspaceId,
              postActivationUrl: defaultWorkspaceClientApp.url,
            })
            .then(() =>
              notifySuccess(
                <span>
                  Invite sent to: <strong>{email}</strong>
                </span>
              )
            )
            .catch(notifyErrorOrDefaultTo("Error occurred when trying to send activation message"));
        } else {
          notifySuccess(
            <span>
              User with identifier <strong>{email}</strong> successfully created
            </span>
          );
          return Promise.resolve();
        }
      })
      .then(() => {
        if (role) {
          return adminRolesApi
            .grantTenantRole({
              request: {
                role: role,
                type: GrantTenantRoleRequestTypeEnum.IdentityPoolUser,
                identity_pool_id: createdUser.user_pool_id,
                identity_pool_user_id: createdUser.id,
                tenant_id: getTenantId(),
              },
            })
            .then(() => queryClient.invalidateQueries({ queryKey: listTenantRoles() }))
            .then(() =>
              queryClient.invalidateQueries({
                queryKey: listUserRoles(createdUser.user_pool_id, createdUser.id),
              })
            )
            .catch(notifyErrorOrDefaultTo("Error occurred when trying to grant tenant role"));
        }
        if ((roles || []).length > 0 && createdUser) {
          return grantWorkspaceRoles(queryClient, workspaceId, createdUser, roles || []);
        }
      })
      .then(() => onCreated(createdUser, !!addAnotherItem))
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listUsersQueryKey(getTenantId(), identityPoolID),
        })
      )
      .finally(() => setProgress(false));
  };

  return (
    <IdentityPoolUserCreate
      pool={poolQuery.data}
      payloadSchema={payloadSchemaQuery.data}
      metadataSchema={metadataSchemaQuery.data}
      businessMetadataSchema={businessMetadataSchemaQuery.data}
      allowMetadata={false}
      allowBusinessMetadata={false}
      onCreate={handleCreate}
      onClose={onClose}
      progress={progress}
      initialData={initialData}
      isAddAnotherItemVisible
      isRolesVisible={isAnyWorkspaceOrPoolRoleAllowed(
        checkWorkspacePermissions.data,
        checkPoolPermissions.data
      )}
      workspaceId={workspaceId}
      disableWhenMetadataHasRequiredProperties
    />
  );
}
