import React, { CSSProperties, ReactNode } from "react";

import MuiAlert, { AlertProps as MuiAlertProps } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Icon, X } from "react-feather";
import { makeStyles } from "tss-react/mui";

const getConfig = (severity: MuiAlertProps["severity"]) => {
  switch (severity) {
    case "info":
      return {
        rootClass: "info",
        iconClass: "infoIcon",
        color: "#008EFF",
      };
    case "warning":
      return {
        rootClass: "info",
        iconClass: "warningIcon",
        color: "#212533",
      };
    // add other variants when used
    default:
      return {
        rootClass: "info",
        iconClass: "infoIcon",
        color: "#008EFF",
      };
  }
};

const useStyles = makeStyles()(theme => ({
  info: {
    backgroundColor: "#F0F7FF",
    border: "1px solid #CFE9FF",
  },
  infoIcon: {
    color: theme.palette.primary.main,
  },
  warningIcon: {
    color: theme.palette.error.main,
  },
  closeButton: {
    position: "absolute",
    top: 4,
    right: 4,
  },
}));

interface Props extends Omit<MuiAlertProps, "children" | "icon"> {
  children: ReactNode;
  severity: MuiAlertProps["severity"];
  icon?: Icon;
  onClose?: () => void;
  noMargin?: boolean;
  iconStyle?: CSSProperties;
}

const Alert = ({
  children,
  severity = "info",
  icon: Icon,
  title,
  onClose,
  noMargin,
  iconStyle = {},
  ...props
}: Props) => {
  const { classes } = useStyles();

  const config = getConfig(severity);
  return (
    <MuiAlert
      icon={
        Icon ? (
          <Icon size={16} className={classes[config.iconClass]} style={iconStyle} />
        ) : undefined
      }
      severity={severity}
      style={{ marginBottom: noMargin ? 0 : 32, position: "relative" }}
      {...props}
    >
      {onClose && (
        <IconButton size="small" onClick={onClose} className={classes.closeButton}>
          <X size={18} />
        </IconButton>
      )}
      {title && <AlertTitle>{title}</AlertTitle>}
      <Typography variant="textMD">{children}</Typography>
    </MuiAlert>
  );
};

export default Alert;
