import { QueryClient } from "@tanstack/react-query";

import { GlobalStoreContextType } from "../GlobalStore/GlobalStore";
import { getPermissionSystemQueryKey } from "./adminPermissionsSystemsQuery";
import { getPoliciesQueryKey, getPolicyExecutionPointsQueryKey } from "./adminPoliciesQuery";
import { getScriptsQueryKey } from "./adminScriptsQuery";
import { listServerBindingsQueryKey, listWorkspacesQueryKey } from "./adminServersQuery";
import { getTenantQueryKey } from "./adminTenantsQuery";

export const onMessage =
  (queryClient: QueryClient, globalStoreContext: GlobalStoreContextType) => (e: MessageEvent) => {
    const invalidate = key => queryClient.invalidateQueries({ queryKey: key });

    try {
      const msg = JSON.parse(e.data);
      if (!msg.topic || !msg.payload) {
        return;
      }

      switch (msg.topic) {
        case "servers": {
          invalidate(listWorkspacesQueryKey());
          break;
        }
        case "tenants": {
          invalidate(getTenantQueryKey(msg.payload));
          break;
        }
        case "policies": {
          invalidate(getPoliciesQueryKey(msg.payload.tenant_id, msg.payload.server_id));
          break;
        }
        case "policy_execution_points": {
          invalidate(
            getPolicyExecutionPointsQueryKey(msg.payload.tenant_id, msg.payload.server_id)
          );
          break;
        }
        case "scripts": {
          invalidate(getScriptsQueryKey(msg.payload.tenant_id, msg.payload.server_id));
          break;
        }
        case "servers_bindings": {
          invalidate(listServerBindingsQueryKey(msg.payload));
          break;
        }
        case "audit_event": {
          if (msg.payload.length) {
            globalStoreContext.auditEvents.setLastId(msg.payload[msg.payload.length - 1].event_id);
          }
          break;
        }
        case "permission_systems": {
          invalidate(getPermissionSystemQueryKey(msg.payload.tenant_id, msg.payload.system_id));
          break;
        }
        default:
          break;
      }
    } catch (e) {
      //
    }
  };
