import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { OrganizationResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import adminServersApi from "../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  listWorkspacesQueryKey,
} from "../../services/adminServersQuery";

interface Props {
  server: WorkspaceResponse | OrganizationResponse;
  onCancel: () => void;
}

export default function UnbindThemeDialog({ server, onCancel }: Props) {
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();

  const handleUnbindTheme = () => {
    setProgress(true);
    adminServersApi
      .unbindServerTheme({ wid: server.id! })
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), server.id),
        })
      )
      .then(() => {
        notifySuccess("Server successfully unbound from theme");
        onCancel();
      })
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to unbind server from theme"))
      .finally(() => setProgress(false));
  };

  return (
    <ConfirmationDialog
      title="Unbind theme"
      content="Do you want to continue?"
      confirmText="Unbind"
      progress={progress}
      onCancel={onCancel}
      onConfirm={handleUnbindTheme}
    />
  );
}
