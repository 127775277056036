import {
  OTPApi,
  OTPApiRequestAddressVerificationRequest,
  OTPApiRequestOTPChallengeRequest,
  OTPApiVerifyOTPRequest,
} from "@cloudentity/acp-identity";

import { identityApiClazz } from "./admin-services.common";

const api: () => OTPApi = identityApiClazz(OTPApi);

const identityOTPApi = {
  requestAddressVerification: (req: OTPApiRequestAddressVerificationRequest) =>
    api().requestAddressVerification(req),
  requestOTPChallenge: (req: OTPApiRequestOTPChallengeRequest) => api().requestOTPChallenge(req),
  verifyOTP: (req: OTPApiVerifyOTPRequest) => api().verifyOTP(req),
};

export default identityOTPApi;
