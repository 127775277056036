import React, { Dispatch, SetStateAction } from "react";

import { UiSchema } from "@rjsf/utils";
import isEmpty from "lodash/isEmpty";

import { Schema } from "@cloudentity/acp-identity";

import SchemaForm from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/SchemaForm";
import {
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../../../admin/components/workspaceDirectory/identityPools/schemas/schemas.utils";

interface Props {
  schemaId: string | undefined;
  schemas: Schema[];
  progress: boolean;
  submitAttempt: boolean;
  extraErrors: any;
  resetExtraErrors: () => void;
  disabled?: boolean;
  metadata: { [key: string]: object };
  setMetadata: Dispatch<SetStateAction<{ [key: string]: object }>>;
}
export default function UserEditBusinessMetadata({
  schemaId,
  schemas,
  progress,
  submitAttempt,
  extraErrors,
  resetExtraErrors,
  disabled,
  metadata,
  setMetadata,
}: Props) {
  const businessMetadataSchema = schemas.find(s => s.id === schemaId) || null;

  const businessMetadataSchemaWithMappedTitles = mapFieldNameToTitle(
    businessMetadataSchema?.schema || {}
  );

  const metadataUISchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(businessMetadataSchemaWithMappedTitles || {}),
  };

  return (
    <div style={{ padding: "0 32px" }}>
      {!isEmpty(businessMetadataSchemaWithMappedTitles) && (
        <SchemaForm
          formData={metadata}
          setFormData={setMetadata}
          schema={businessMetadataSchemaWithMappedTitles}
          UISchema={metadataUISchema}
          submitAttempt={submitAttempt}
          updateProgress={progress}
          extraErrors={extraErrors}
          resetExtraErrors={resetExtraErrors}
          disabled={disabled}
        />
      )}
    </div>
  );
}
