import { useCallback, useEffect, useState } from "react";

import { getFromLocalStorage, setInLocalStorage } from "../localStorage.utils";

export const useLocalStorage = (key: string) => {
  const [v, setV] = useState(getFromLocalStorage(key));

  const setValue = useCallback(
    (value: string) => {
      setInLocalStorage(key, value);
      setV(value);
      window.dispatchEvent(new StorageEvent("storage", { key }));
    },
    [key]
  );

  const handler = useCallback(
    (e: StorageEvent) => {
      if (e.key === key) {
        setV(getFromLocalStorage(e.key));
      }
    },
    [key]
  );

  useEffect(() => {
    const v = getFromLocalStorage(key);
    if (v !== undefined) {
      setValue(v);
    }
  }, [key, setValue]);

  useEffect(() => {
    window.addEventListener("storage", handler, false);

    return () => window.removeEventListener("storage", handler);
  }, [handler]);

  return [v, setValue] as const;
};
