import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";

import {
  useInfiniteScrollForItemsWithQuery,
  UseItemsReturnType,
} from "../../../common/EnhancedTableAsync/useItemsWithQuery";
import IdentityPoolsGridItem from "./IdentityPoolsGridItem";

const useStyles = makeStyles()(theme => ({
  loading: {
    position: "absolute",
    left: "50%",
    bottom: 16,
    transform: "translateX(-50%)",
    backgroundColor: "white",
    zIndex: 2,
    padding: theme.spacing(1),
    textAlign: "center",
    borderRadius: "50%",
    boxShadow: theme.custom.shadows.surfaceHover,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 48,
    width: 48,
  },
}));

interface Props {
  data: UseItemsReturnType<PoolResponse>;
  onMenuOpen?: ({ elementId, pool }: { elementId: string; pool: PoolResponse }) => void;
  onSelect?: (id: string) => void;
}

function IdentityPoolsCards({ data, onMenuOpen, onSelect }: Props) {
  const { classes } = useStyles();

  const { totalData, onLastPage, areFiltersApplied, searchText } = data;

  useInfiniteScrollForItemsWithQuery({ onLastPage });

  const isEmpty = totalData.length === 0 && (areFiltersApplied || searchText);

  return isEmpty ? (
    <div style={{ color: "gray" }}>No pools found</div>
  ) : (
    <Grid container spacing={3}>
      {totalData.map(pool => (
        <Grid item xs={12} sm={12} md={6} lg={4} key={pool!.id + String(Math.random())}>
          <IdentityPoolsGridItem pool={pool} onMenuOpen={onMenuOpen} onSelect={onSelect} />
        </Grid>
      ))}

      {data.isFetching && (
        <div className={classes.loading} id="next-loading" role="presentation">
          <CircularProgress size={24} />
        </div>
      )}
    </Grid>
  );
}

export default IdentityPoolsCards;
