import { PoolResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";

import otpIcon from "../../../../../assets/images/icons/identityPools/otp-icon.svg";
import passwordIcon from "../../../../../assets/images/icons/identityPools/password-icon.svg";
import smartphoneIcon from "../../../../../assets/images/icons/identityPools/smartphone-icon.svg";
import webauthnIcon from "../../../../../assets/images/icons/identityPools/webauthn-icon.svg";
import { providers } from "../../identities.utils";

export const IDPS_LIST_VIEW_MODE_KEY = "idps-list-view-mode";
export type IdpsViewModeType = "simple" | "advanced";
export const previewCardWidth = 480;

const hiddenIdps = ["static", "organization", "cloudentity"];

const imgMapper = providers.reduce(
  (acc, { icon, method }) => ({
    ...acc,
    [method]: (() => {
      const store = {};
      return name => {
        if (!store[name]) {
          const img = document.createElement("img");
          img.src = icon;
          store[name] = img;
        }
        return store[name];
      };
    })(),
  }),
  {}
);

const getImg = (src: string) => {
  const img = document.createElement("img");
  img.setAttribute("style", "width: 24px; max-width: 24px;");
  img.src = src;
  return img;
};

const poolMethodsMapper: {
  [method in PoolResponseAuthenticationMechanismsEnum]: {
    img: HTMLImageElement;
    name: string;
  };
} = {
  [PoolResponseAuthenticationMechanismsEnum.Totp]: {
    img: getImg(smartphoneIcon),
    name: "Authenticator App",
  },
  [PoolResponseAuthenticationMechanismsEnum.Password]: {
    img: getImg(passwordIcon),
    name: "Password",
  },
  [PoolResponseAuthenticationMechanismsEnum.Otp]: {
    img: getImg(otpIcon),
    name: "Verification Code",
  },
  [PoolResponseAuthenticationMechanismsEnum.Webauthn]: {
    img: getImg(webauthnIcon),
    name: "Passkey",
  },
};

function createButton(label: string, img: HTMLImageElement) {
  const button = document.createElement("button");
  button.className = "href-card visible-flex";
  button.style.minHeight = "47px";
  const span = document.createElement("span");
  span.textContent = label;
  const div = document.createElement("div");
  div.appendChild(img);
  div.appendChild(span);
  const i = document.createElement("i");
  i.className = "material-icons";
  i.textContent = "navigate_next";
  button.appendChild(div);
  button.appendChild(i);
  return button;
}

export function createIdpButton(name: string, method: string) {
  if (hiddenIdps.includes(method)) return null;

  const provider = providers.find(provider => provider.method === method);
  if (!provider) return;

  const img = imgMapper[method](name);
  return createButton(name, img);
}

export function createPoolMethodButton(method: PoolResponseAuthenticationMechanismsEnum) {
  const button = document.createElement("button");
  button.className = "href-card visible-flex";
  button.style.minHeight = "47px";

  const methodData = poolMethodsMapper[method];
  return createButton(methodData.name, methodData.img);
}

export function handleIdpDiscoveryEnabled(content: Document, enabled: boolean) {
  const header = content.querySelector("#log-in-header") as HTMLElement;
  const usernamePasswordForm = content.querySelector("#username-password-form") as HTMLElement;

  if (header) {
    header.textContent = enabled ? "Log in to continue" : "Log in with";
  }

  if (usernamePasswordForm) {
    usernamePasswordForm.style.display = enabled ? "block" : "none";
  }
}

export function handleAddSeparator(methodsContainer: HTMLElement, show: boolean) {
  const container = document.createElement("div");
  const div = document.createElement("div");
  div.textContent = "or login with";
  container.className = "spacer-container";

  if (show) {
    container.appendChild(div);
    methodsContainer.appendChild(container);
  }
}

export function handlePoolNoMethods(form: HTMLElement, show: boolean) {
  const noMethodsInfo = form.querySelector("#no-methods-info") as HTMLElement;
  if (noMethodsInfo) {
    form.removeChild(noMethodsInfo);
  }

  if (show) {
    const div = document.createElement("div");
    div.id = "no-methods-info";
    div.textContent = "No methods available to log in";
    form.appendChild(div);
  }
}

function handleUsernameField(content: Document, show: boolean) {
  const header = content.querySelector<HTMLElement>(".log-in-header-container");
  const usernameContainer = content.querySelector<HTMLElement>("#text-field-username-input");
  if (header && usernameContainer?.parentElement) {
    header.style.display = show ? "block" : "none";
    usernameContainer.parentElement.style.display = show ? "block" : "none";
  }
}

function handlePasswordField(content: Document, show: boolean) {
  const password = content.querySelector<HTMLElement>("#password-container");
  if (password) {
    password.style.display = show ? "block" : "none";
  }
}

function handleSubmitButton(content: Document, show: boolean) {
  const submitButton = content.querySelector<HTMLElement>(".submit-button");
  if (submitButton) {
    submitButton.style.display = show ? "block" : "none";
  }
}

export function renderMethod(
  content: Document,
  header: HTMLElement | null,
  elementWithMargin: HTMLElement | null,
  methodsContainer: HTMLElement,
  method: PoolResponseAuthenticationMechanismsEnum | null,
  isPrimary: boolean
) {
  switch (method) {
    case PoolResponseAuthenticationMechanismsEnum.Totp: {
      if (isPrimary) {
        handleUsernameField(content, true);
        handleSubmitButton(content, true);
        if (header) {
          header.textContent = "Log in with authenticator app";
        }
      } else {
        methodsContainer.appendChild(
          createPoolMethodButton(PoolResponseAuthenticationMechanismsEnum.Totp)
        );
      }
      break;
    }
    case PoolResponseAuthenticationMechanismsEnum.Password: {
      if (isPrimary) {
        handleUsernameField(content, true);
        handlePasswordField(content, true);
        handleSubmitButton(content, true);
        if (header) {
          header.textContent = "Log in with password";
        }
      } else {
        methodsContainer.appendChild(
          createPoolMethodButton(PoolResponseAuthenticationMechanismsEnum.Password)
        );
      }
      break;
    }
    case PoolResponseAuthenticationMechanismsEnum.Webauthn: {
      if (isPrimary) {
        handleUsernameField(content, true);
        handleSubmitButton(content, true);
        if (header) {
          header.textContent = "Log in with passkey";
        }
      } else {
        methodsContainer.appendChild(
          createPoolMethodButton(PoolResponseAuthenticationMechanismsEnum.Webauthn)
        );
      }
      break;
    }
    case PoolResponseAuthenticationMechanismsEnum.Otp: {
      if (isPrimary) {
        handleUsernameField(content, true);
        handleSubmitButton(content, true);
        if (header) {
          header.textContent = "Log in with verification code";
        }
      } else {
        methodsContainer.appendChild(
          createPoolMethodButton(PoolResponseAuthenticationMechanismsEnum.Otp)
        );
      }
      break;
    }
    default:
      handleUsernameField(content, false);
      handlePasswordField(content, false);
      handleSubmitButton(content, false);
      if (header) {
        header.textContent = "";
        if (elementWithMargin) {
          elementWithMargin.style.marginTop = "0";
        }
      }
      break;
  }
}
