import React, { CSSProperties, ReactNode } from "react";
import { Controller, RegisterOptions } from "react-hook-form";

import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { makeStyles } from "tss-react/mui";

import FormInputLabel, { FormInputLabelProps } from "../../components/FormInputLabel";
import { useFormContext } from "./Form";

const useStyles = makeStyles()(theme => ({
  root: {
    ".MuiInputBase-root": {
      backgroundColor: theme.custom.greys.fieldBackground,
      minHeight: 48,
    },
    ".MuiOutlinedInput-notchedOutline": {
      boxShadow: theme.custom.shadows.formInner,
    },
  },
  warning: {
    ".MuiOutlinedInput-notchedOutline": {
      border: `solid 1px ${theme.palette.warning.main}`,
    },
  },
}));

export interface AutocompleteFieldProps<T>
  extends Omit<AutocompleteProps<T, boolean, boolean, boolean>, "renderInput"> {
  id?: string;
  name: string;
  label?: string | ReactNode;
  helperText?: string | ReactNode;
  labelIcon?: any;
  labelCaption?: string;
  labelProps?: Omit<FormInputLabelProps, "id" | "label">;
  placeholder?: string;
  optional?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  style?: CSSProperties;
  rules?: Pick<RegisterOptions, "required" | "validate">;
  afterChange?: (value: any) => void;
  warning?: boolean;
  disabled?: boolean;
}

export default function AutocompleteField<T>({
  id,
  name,
  label,
  helperText,
  labelIcon,
  labelCaption,
  labelProps,
  placeholder,
  optional,
  startAdornment,
  endAdornment,
  style,
  rules,
  afterChange,
  warning,
  disabled,
  ...props
}: AutocompleteFieldProps<T>) {
  const { cx, classes } = useStyles();

  const { form, disabled: formDisabled, id: formId } = useFormContext();
  const inputID = `${id || formId}-${name}`;

  const required = rules?.required;
  const isOptional = optional !== undefined ? optional : !required;

  return (
    <Controller
      name={name}
      control={form.control}
      rules={rules}
      defaultValue={props.defaultValue}
      render={({ field: { value, onChange }, fieldState }) => {
        const error = fieldState.error;

        return (
          <FormControl style={{ marginBottom: 32, width: "100%", ...style }} error={!!error}>
            <FormInputLabel
              id={`${inputID}-label`}
              forId={`${inputID}-checkbox`}
              label={label}
              icon={labelIcon}
              caption={labelCaption}
              optional={isOptional}
              style={!label ? { height: 0, margin: 0 } : {}}
              {...labelProps}
            />
            <Autocomplete
              id={`${inputID}-checkbox`}
              value={value || (props.multiple ? [] : props.defaultValue ?? null)}
              onChange={(_, option) => {
                onChange(option);
                if (!props.freeSolo) {
                  form.trigger(name);
                }
                if (afterChange) {
                  afterChange(option);
                }
              }}
              loading={props.loading}
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${inputID}`}
                  className={cx(classes.root, warning && classes.warning)}
                  error={!!error}
                  variant="outlined"
                  placeholder={placeholder}
                  fullWidth
                  disabled={disabled || formDisabled}
                  InputProps={{
                    ...params.InputProps,
                    ...(startAdornment ? { startAdornment } : {}),
                    endAdornment: (
                      <>
                        {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                        {endAdornment}
                      </>
                    ),
                    componentsProps: {
                      root: {
                        id: `${inputID}-inputBase-root`,
                      },
                    },
                  }}
                />
              )}
              filterSelectedOptions
              autoHighlight
              disabled={disabled || formDisabled}
              {...props}
            />
            <FormHelperText id={`${inputID}-helper-text`} style={{ marginTop: 3 }} component="div">
              {error?.message || helperText}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}
