import React from "react";

import { UserVerifiableAddress, UserVerifiableAddressTypeEnum } from "@cloudentity/acp-identity";

import Dialog from "../../../../../../../common/components/Dialog";
import Form, { useForm } from "../../../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../../../common/utils/forms/FormFooter";
import SelectField from "../../../../../../../common/utils/forms/SelectField";
import { useGetPool } from "../../../../../../services/adminIdentityPoolsQuery";
import IdentityPoolThemeSelector from "../../IdentityPoolThemeSelector";
import {
  setPoolWorkspaceId,
  useIdentityPoolThemeSelector,
} from "../../useIdentityPoolThemeSelector";

interface Props {
  id: string;
  poolId: string;
  title: string;
  submitText: string;
  addresses: UserVerifiableAddress[];
  onCancel: () => void;
  onSubmit: (address: string, serverId: string) => void;
  progress: boolean;
}

const data = {
  address: "",
};

export default function IdentityPoolUserSelectAddressDialog({
  id,
  poolId,
  title,
  submitText,
  addresses,
  onSubmit,
  progress,
  onCancel,
}: Props) {
  const getPoolQuery = useGetPool(poolId);
  const { isLoading, mustSelectThemeServerId } = useIdentityPoolThemeSelector({
    pool: getPoolQuery.data,
  });

  const form = useForm({
    id: "reset-password",
    initialValues: data,
    progress: progress || getPoolQuery.isLoading || isLoading,
  });

  return (
    <Dialog onClose={onCancel} id={id} title={title}>
      <Form form={form}>
        {addresses && (
          <>
            <SelectField
              name="address"
              label="Address"
              options={addresses
                .filter(i => i.type === UserVerifiableAddressTypeEnum.Email)
                .map(({ address }) => ({
                  value: address,
                  name: address,
                }))}
              optional={false}
              multiple={false}
              rules={{
                required: "Address is required",
              }}
            />
            {mustSelectThemeServerId && (
              <IdentityPoolThemeSelector pool={getPoolQuery.data} showRememberCheckbox />
            )}
          </>
        )}
        <FormFooter
          onCancel={onCancel}
          onSubmit={({ address, themeWorkspaceId, rememberThemeWorkspaceId }) => {
            if (rememberThemeWorkspaceId) {
              setPoolWorkspaceId(poolId, themeWorkspaceId);
              onSubmit(address, themeWorkspaceId);
            }
            onSubmit(address, themeWorkspaceId);
          }}
          submitText={submitText}
        />
      </Form>
    </Dialog>
  );
}
