import React, { useState } from "react";

import { AxiosResponse } from "axios";

import { SelfUserWithDataV2 } from "@cloudentity/acp-identityself";

import { logout } from "../../auth/actions/actions";
import identitySelfApi from "../../services/identitySelfApi";
import Button from "../Button";
import ConfirmationDialog from "../ConfirmationDialog";
import { notifyErrorOrDefaultTo, notifySuccess } from "../notifications/notificationService";

function bufferDecode(value) {
  return Uint8Array.from(atob(value.replace(/-/g, "+").replace(/_/g, "/")), c => c.charCodeAt(0));
}

function bufferEncode(value: any) {
  return btoa(String.fromCharCode.apply(null, [...new Uint8Array(value)]))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

interface Props {
  fetchUser: (withSetter?: boolean) => Promise<AxiosResponse<SelfUserWithDataV2, any> | null>;
}

export default function SelfServiceSetPasskey({ fetchUser }: Props) {
  const [error, setError] = useState(false);

  function handleSetPasskey() {
    identitySelfApi
      .beginWebAuthnCredentialsGeneration()
      .then(async ({ data }: { data: any }) => {
        if (data.publicKey) {
          try {
            const publicKey = {
              ...data.publicKey,
              challenge: bufferDecode(data.publicKey.challenge),
              user: {
                ...data.publicKey.user,
                id: bufferDecode(data.publicKey.user.id),
              },
            };

            const credential: any = await navigator.credentials.create({ publicKey }); // FIXME

            var attestationObject = credential.response.attestationObject;
            var clientDataJSON = credential.response.clientDataJSON;
            var rawId = credential.rawId;

            const { data: completeData } =
              await identitySelfApi.completeWebAuthnCredentialsGeneration({
                id: credential.id,
                rawId: bufferEncode(rawId),
                type: credential.type,
                response: {
                  attestationObject: bufferEncode(attestationObject),
                  clientDataJSON: bufferEncode(clientDataJSON),
                },
              } as any);

            if (completeData) {
              await identitySelfApi.setWebAuthn({ new_webauthn: completeData as any });
              notifySuccess("Passkey successfully set");
              fetchUser(true);
            }
          } catch (err) {
            notifyErrorOrDefaultTo("Error occurred while trying to set passkey")(err);
          }
        }
      })
      .catch(e => {
        if (
          e.response?.status === 401 &&
          e.response?.headers?.["www-authenticate"]?.includes("insufficient_user_authentication")
        ) {
          setError(true);
          return;
        }

        return notifyErrorOrDefaultTo("Error occurred while trying to initiate passkey generation")(
          e
        );
      });
  }

  return (
    <>
      <Button
        id="set-passkey-button"
        onClick={handleSetPasskey}
        variant="contained"
        size="small"
        style={{ fontSize: 12 }}
      >
        Enable
      </Button>

      {error && (
        <ConfirmationDialog
          onCancel={() => {
            setError(false);
          }}
          id="set-passkey-dialog"
          title="Reauthentication required"
          onConfirm={() => logout("admin", { withPromptLogin: true })}
          confirmText="Reauthenticate"
          content={<>For security reasons, you need to reauthenticate to set a new passkey.</>}
        />
      )}
    </>
  );
}
