import React, { useState } from "react";

import { UserPlus } from "react-feather";

import Fab from "../../../common/components/Fab";
import InviteWorkspaceAdministratorDialog from "./InviteWorkspaceAdministratorDialog";
import { UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles } from "./WorkspaceAdministratorsTable";

interface Props {
  usersAndPoolsAndRoles: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles[];
}

export default function InviteWorkspaceAdministrator({ usersAndPoolsAndRoles }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Fab onClick={() => setIsOpen(true)} id="manage-access-add-identity-pool-user-button">
        <UserPlus size={16} strokeWidth={3} style={{ marginRight: 8 }} />
        Invite Admin
      </Fab>

      {isOpen && (
        <InviteWorkspaceAdministratorDialog
          usersAndPoolsAndRoles={usersAndPoolsAndRoles}
          onCreated={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}
        />
      )}
    </>
  );
}
