import React from "react";
import { useNavigate } from "react-router";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { AlertTriangle, Edit } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { getTenantId } from "../../../../common/api/paths";
import Chip from "../../../../common/components/Chip";
import IconButton from "../../../../common/components/IconButton";
import { useListBruteForceLimits } from "../../../services/adminBruteForceLimitsQuery";
import { useGetEnvironment } from "../../../services/adminEnvironmentQuery";
import AvatarNameId from "../../common/AvatarNameId";
import EnhancedTable from "../../common/EnhancedTable";
import Tooltip from "../../common/Tooltip";
import { blockDurationToHumanString, bruteForceTypeToName } from "./bruteForceProtection.utils";

const useStyles = makeStyles()(() => ({
  row: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const headCells = [
  { label: "Type", id: "type" },
  { label: "Block Duration", id: "block_duration" },
  { label: "Max Attempts", id: "max_attempts" },
  { label: "", id: "actions", align: "right" },
];

const ID = "brute-force-protection-table";

interface Props {
  readonly?: boolean;
}

export default function BruteForceProtectionTable({ readonly }: Props) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const getEnvironmentQuery = useGetEnvironment();
  const envBruteForceLimits = getEnvironmentQuery.data?.brute_force_limits || {};
  const types = Object.keys(envBruteForceLimits).filter(k => k !== "enabled");

  const bruteForceLimitsQuery = useListBruteForceLimits(getTenantId());

  const customLimitsByType = type =>
    bruteForceLimitsQuery.data?.brute_force_limits?.find(l => l.protected_type === type);

  const isDisabled = type =>
    customLimitsByType(type)?.block_duration === "0h0m0s" ||
    envBruteForceLimits[type].block_duration === "0h0m0s" ||
    customLimitsByType(type)?.max_attempts === 0 ||
    envBruteForceLimits[type].max_attempts === 0;

  return (
    <EnhancedTable
      id="idp-attributes-table"
      data={types}
      headCells={headCells}
      createRow={(type, i) => (
        <TableRow
          key={i}
          id={`${ID}-row-${i}`}
          onClick={() => !readonly && navigate(`/brute-force-protection/${type}`)}
          hover={!readonly}
          className={readonly ? undefined : classes.row}
        >
          <TableCell id={`${ID}-type-${i}`}>
            <AvatarNameId name={bruteForceTypeToName(type)} id={type} />
          </TableCell>
          <TableCell id={`${ID}-type-${i}`}>
            {customLimitsByType(type)
              ? blockDurationToHumanString(customLimitsByType(type)?.block_duration || "")
              : blockDurationToHumanString(envBruteForceLimits[type].block_duration)}
          </TableCell>
          <TableCell id={`${ID}-type-${i}`}>
            <Chip
              label={
                customLimitsByType(type)
                  ? customLimitsByType(type)?.max_attempts
                  : envBruteForceLimits[type].max_attempts
              }
            />
          </TableCell>
          <TableCell id={`${ID}-type-${i}`} width="1%" align="right">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
              {isDisabled(type) && (
                <Tooltip title="Brute-force protection for this type is disabled.">
                  <AlertTriangle color="rgb(255, 172, 51)" style={{ marginRight: 16 }} />
                </Tooltip>
              )}
              {!readonly && (
                <IconButton
                  onClick={() => navigate(`/brute-force-protection/${type}`)}
                  icon={Edit}
                  size="small"
                />
              )}
            </div>
          </TableCell>
        </TableRow>
      )}
    />
  );
}
