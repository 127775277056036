import React, { CSSProperties } from "react";
import { Controller } from "react-hook-form";

import Chip from "@mui/material/Chip";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../components/FormInputLabel";
import { useFormContext } from "./Form";

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: 12,
  },
  chip: {
    marginRight: 13,
    fontSize: 14,
    color: theme.palette.secondary.light,
    fontWeight: 500,
  },
  active: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:hover": {
      backgroundColor: "#abd6fd",
    },
  },
  labelContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export interface ChipsGroupFieldProps {
  name: string;
  label: string;
  options: { label: string; value: string }[];
  style?: CSSProperties;
  disabled?: boolean;
  onChange: (value: string) => void;
}

export default function ChipsGroupField({
  name,
  label,
  options,
  style,
  disabled,
  onChange,
}: ChipsGroupFieldProps) {
  const { cx, classes } = useStyles();

  const { form, disabled: formDisabled, id } = useFormContext();

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field }) => (
        <div style={style}>
          <div className={classes.labelContainer}>
            <FormInputLabel
              id={`${id}-${name}-switch-label`}
              label={label}
              style={{ marginBottom: 4 }}
            />
          </div>
          <div className={classes.container}>
            {options.map(({ label, value: optionValue }) => (
              <Chip
                id={`${id}-${name}-${optionValue}`}
                key={optionValue + label}
                label={label}
                onClick={() => {
                  field.onChange(optionValue);
                  onChange(optionValue);
                }}
                disabled={formDisabled || disabled}
                className={cx([classes.chip, field.value === optionValue && classes.active])}
              />
            ))}
          </div>
        </div>
      )}
    />
  );
}
