import React from "react";

import Paper from "@mui/material/Paper";
import { AxiosResponse } from "axios";
import { Mail, Phone, User } from "react-feather";

import {
  ListUserMFASessions,
  SelfUserIdentifierTypeEnum,
  SelfUserWithDataV2,
} from "@cloudentity/acp-identityself";
import { ListUserSessions, UserSession } from "@cloudentity/acp-public";

import FormSection from "../FormSection";
import ValueWithCopy from "../ValueWithCopy";
import SelfServiceCredentials from "./SelfServiceCredentials";
import SelfServiceEmptySidePanel from "./SelfServiceEmptySidePanel";
import SelfServiceSessions from "./SelfServiceSessions";
import SelfServiceTabContent from "./SelfServiceTabContent";
import { CardsEnum, useCommonStyles } from "./utils";

interface Props {
  userData: SelfUserWithDataV2 | null;
  fetchUser: (withSetter?: boolean) => Promise<AxiosResponse<SelfUserWithDataV2, any> | null>;
  sessionData: UserSession[];
  fetchSessions: (withSetter?: boolean) => Promise<AxiosResponse<ListUserSessions, any> | null>;
  mfaSessionData: UserSession[];
  fetchMfaSessions: (
    withSetter?: boolean
  ) => Promise<AxiosResponse<ListUserMFASessions, any> | null>;
  cards: CardsEnum[];
}

export default function SelfServiceSecurity({
  userData,
  fetchUser,
  sessionData,
  fetchSessions,
  mfaSessionData,
  fetchMfaSessions,
  cards,
}: Props) {
  const { classes: commonClasses } = useCommonStyles();

  const identifiers =
    userData?.identifiers?.filter(identifier => identifier.type !== "federated") ?? [];
  const mobileIdentifiers = identifiers.filter(
    identifier => identifier.type === SelfUserIdentifierTypeEnum.Mobile
  );
  const emailIdentifiers = identifiers.filter(
    identifier => identifier.type === SelfUserIdentifierTypeEnum.Email
  );
  const otherIdentifiers = identifiers.filter(
    identifier =>
      identifier.type !== SelfUserIdentifierTypeEnum.Mobile &&
      identifier.type !== SelfUserIdentifierTypeEnum.Email
  );

  const verifiableAddresses = userData?.verifiable_addresses ?? [];

  return (
    <>
      <SelfServiceTabContent
        rightPanel={
          cards.includes(CardsEnum["sign-in-identifiers"]) ? (
            <>
              {!identifiers.length && !verifiableAddresses.length && (
                <SelfServiceEmptySidePanel
                  header="No Authentication Identifiers"
                  text="Reach out to the administrator to add your updated authentication information"
                />
              )}

              {!!identifiers.length && (
                <>
                  <FormSection
                    title="Sign-in identifiers"
                    subtitle="Your identifiers used for authentication"
                  />

                  {otherIdentifiers.map(identifier => (
                    <ValueWithCopy
                      value={identifier.identifier}
                      key={identifier.identifier}
                      startAdornment={<User size={16} className={commonClasses.infoPanelRowIcon} />}
                    />
                  ))}

                  {mobileIdentifiers.map(identifier => (
                    <ValueWithCopy
                      value={identifier.identifier}
                      key={identifier.identifier}
                      startAdornment={
                        <Phone size={16} className={commonClasses.infoPanelRowIcon} />
                      }
                    />
                  ))}

                  {emailIdentifiers.map(identifier => (
                    <ValueWithCopy
                      value={identifier.identifier}
                      key={identifier.identifier}
                      startAdornment={<Mail size={16} className={commonClasses.infoPanelRowIcon} />}
                    />
                  ))}
                </>
              )}
            </>
          ) : undefined
        }
      >
        {cards.includes(CardsEnum["sign-in-methods"]) && (
          <Paper className={commonClasses.paper} style={{ marginBottom: 32 }}>
            <SelfServiceCredentials userData={userData} fetchUser={fetchUser} />
          </Paper>
        )}

        {cards.includes(CardsEnum.devices) && (
          <Paper className={commonClasses.paper}>
            <SelfServiceSessions
              sessionData={sessionData}
              fetchSessions={fetchSessions}
              mfaSessionData={mfaSessionData}
              fetchMfaSessions={fetchMfaSessions}
            />
          </Paper>
        )}
      </SelfServiceTabContent>
    </>
  );
}
