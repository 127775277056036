import React from "react";

import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import Switch from "../../../../common/components/Switch";

const useStyles = makeStyles<{
  noMargin?: boolean;
  hasPrev?: boolean;
  hasNext?: boolean;
  disabled?: boolean;
}>()((theme, { noMargin, hasPrev, hasNext, disabled }) => ({
  switchCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: noMargin ? 0 : 8,
    padding: 16,
    position: "relative",
    ...(disabled ? { backgroundColor: theme.custom.sa.neutrals.n0 } : {}),
    ...(hasPrev ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 } : {}),
    ...(hasNext
      ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: "none" }
      : {}),
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    background: "none",
    border: "solid 1px black",
    color: theme.palette.secondary.light,
    width: 30,
    height: 30,
    marginRight: 8,
  },
  avatarSvg: {
    svg: {
      width: 20,
    },
  },
  idpAvatar: {
    border: "none",
    width: 28,
    height: 28,
    "& > img": {
      width: "100%",
    },
  },
}));

interface Props {
  name: string;
  icon: any;
  id: string;
  noSvgStyle?: boolean;
  idpAvatar?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  noMargin?: boolean;
  hasPrev?: boolean;
  hasNext?: boolean;
}

export default function SignInStepSwitchCard({
  name,
  icon,
  id,
  noSvgStyle,
  idpAvatar,
  checked,
  onChange,
  disabled,
  noMargin,
  hasPrev,
  hasNext,
}: Props) {
  const { cx, classes } = useStyles({ noMargin, hasPrev, hasNext, disabled });

  return (
    <Paper id={`switch-card-${id}`} className={classes.switchCard}>
      <div className={classes.flexContainer}>
        <Avatar
          className={cx(
            classes.avatar,
            !noSvgStyle && classes.avatarSvg,
            idpAvatar && classes.idpAvatar
          )}
        >
          {icon}
        </Avatar>
        <Typography variant="textMD">{name}</Typography>
      </div>

      <div className={classes.flexContainer}>
        <Switch
          checked={checked}
          onChange={(_, checked) => onChange(checked)}
          disabled={disabled}
        />
      </div>
    </Paper>
  );
}
