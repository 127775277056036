import {
  OrganizationsApi,
  OrganizationsApiCreateOrganizationRequest,
  OrganizationsApiDeleteOrganizationRequest,
  OrganizationsApiGetOrganizationRequest,
  OrganizationsApiListOrganizationsRequest,
  OrganizationsApiListUserOrganizationsRequest,
  OrganizationsApiUpdateOrganizationMetadataRequest,
  OrganizationsApiUpdateOrganizationRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => OrganizationsApi = adminApiClazz(OrganizationsApi);

const adminOrganizationsApi = {
  listOrganizations: (req: OrganizationsApiListOrganizationsRequest) =>
    api().listOrganizations(req),
  listUserOrganizations: (req: OrganizationsApiListUserOrganizationsRequest) =>
    api().listUserOrganizations(req),
  createOrganization: (req: OrganizationsApiCreateOrganizationRequest) =>
    api().createOrganization(req),
  getOrganization: (req: OrganizationsApiGetOrganizationRequest) => api().getOrganization(req),
  updateOrganization: (req: OrganizationsApiUpdateOrganizationRequest) =>
    api().updateOrganization(req),
  deleteOrganization: (req: OrganizationsApiDeleteOrganizationRequest) =>
    api().deleteOrganization(req),
  updateOrganizationMetadata: (req: OrganizationsApiUpdateOrganizationMetadataRequest) =>
    api().updateOrganizationMetadata(req),
};

export default adminOrganizationsApi;
