import React, { ReactNode, useRef, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import {
  BaseUserWithData,
  UpdateUserStatusEnum,
  UserWithData,
  UserWithDataStatusEnum,
} from "@cloudentity/acp-identity";

import { useOpenAccordionsWhenFit } from "../../../../../../../../common/utils/hooks/useOpenAccordionsWhenFit";
import StatusChip from "../../list/StatusChip";
import IdentityPoolUserSidePanelAddresses from "./IdentityPoolUserSidePanelAddresses";
import IdentityPoolUserSidePanelIdentifiers from "./IdentityPoolUserSidePanelIdentifiers";
import IdentityPoolUserSidePanelManageButton from "./IdentityPoolUserSidePanelManageButton";
import IdentityPoolUserSidePanelPasswordDetails from "./IdentityPoolUserSidePanelPasswordDetails";
import IdentityPoolUserSidePanelUserDetails from "./IdentityPoolUserSidePanelUserDetails";

const useStyles = makeStyles()(() => ({
  paper: {
    padding: 24,
  },
  actionBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    marginTop: 24,
    marginBottom: 16,
    color: "#646576",
  },
}));

interface Props {
  workspaceId?: string;
  user: UserWithData | BaseUserWithData;
  progress?: boolean;
  hideManageButton?: boolean;
  onStatusChange?: (status: UpdateUserStatusEnum) => void;
  poolId: string;
  onlyInfo?: boolean;
  customManageButton?: ReactNode;
}

const IdentityPoolUserSidePanel = ({
  workspaceId,
  user,
  progress = false,
  onStatusChange,
  hideManageButton,
  poolId,
  onlyInfo = false,
  customManageButton,
}: Props) => {
  const { classes } = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);

  const [identifiersAccordionOpen, setIdentifiersAccordionOpen] = useState(false);
  const [addressesAccordionOpen, setAddressesAccordionOpen] = useState(false);

  useOpenAccordionsWhenFit({
    accordions: [
      {
        id: "accordion-details-pool-user-identifiers-accordion",
        onOpen: () => setIdentifiersAccordionOpen(true),
      },
      {
        id: "accordion-details-pool-user-addresses-accordion",
        onOpen: () => setAddressesAccordionOpen(true),
      },
    ],
    getAvailableHeight: () => {
      const containerBoundingRect = containerRef?.current?.getBoundingClientRect();
      return containerBoundingRect
        ? window.innerHeight - containerBoundingRect.top - containerBoundingRect.height
        : 0;
    },
  });

  return (
    <div ref={containerRef}>
      <Paper className={classes.paper}>
        <div className={classes.actionBar}>
          <StatusChip
            status={user.status}
            label={user.status === UserWithDataStatusEnum.New ? "Invite Sent" : user.status}
            size="medium"
            style={{ height: 36 }}
            id="status-chip"
          />

          {!hideManageButton && onStatusChange && (
            <IdentityPoolUserSidePanelManageButton
              workspaceId={workspaceId}
              user={user}
              progress={progress}
              onStatusChange={onStatusChange}
            />
          )}

          {customManageButton}
        </div>

        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.header}>
              User Record Details
            </Typography>
          </Grid>

          <IdentityPoolUserSidePanelUserDetails user={user} />

          <Grid item xs={12}>
            <Typography variant="h5" className={classes.header}>
              User Password Details
            </Typography>
          </Grid>

          <IdentityPoolUserSidePanelPasswordDetails user={user} poolId={poolId} />
        </Grid>
      </Paper>

      {!onlyInfo && (
        <>
          <IdentityPoolUserSidePanelIdentifiers
            user={user}
            identifiersAccordionOpen={identifiersAccordionOpen}
            setIdentifiersAccordionOpen={setIdentifiersAccordionOpen}
            poolId={poolId}
          />

          <IdentityPoolUserSidePanelAddresses
            user={user}
            workspaceId={workspaceId}
            addressesAccordionOpen={addressesAccordionOpen}
            setAddressesAccordionOpen={setAddressesAccordionOpen}
            poolId={poolId}
          />
        </>
      )}
    </div>
  );
};

export default IdentityPoolUserSidePanel;
