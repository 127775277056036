import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import { notifyErrorOrDefaultTo } from "../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import adminOrganizationsApi from "../../../services/adminOrganizationsApi";
import { listOrganizationsQueryKey } from "../../../services/adminOrganizationsQuery";
import { getExecutionPointsQueryKey } from "../../../services/adminScriptsQuery";
import { listWorkspacesQueryKey } from "../../../services/adminServersQuery";
import { initialColors } from "../WorkspacesColorInput";
import AssignedWorkspaceField from "../administrator/AssignedWorkspaceField";
import { EMPTY_VALUE_PLACEHOLDER_SERVER } from "../server-profiles";

interface Props {
  onCancel: () => void;
  onCreated: () => void;
}

const data = { server: "" };

export default function CreateOrganizationTemplateDialog({ onCancel, onCreated }: Props) {
  const [progress, setProgress] = useState(false);
  const form = useForm({ id: "create-organization-template", initialValues: data, progress });
  const queryClient = useQueryClient();

  const handleCreate = ({ server, name }) => {
    setProgress(true);
    adminOrganizationsApi
      .createOrganization({
        org: {
          name,
          template_id: server?.id ?? undefined,
          template: true,
          color: initialColors[Math.floor(Math.random() * initialColors.length)],
        },
      })
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getExecutionPointsQueryKey(getTenantId(), server?.id),
        })
      )
      .then(() => onCreated())
      .catch(
        notifyErrorOrDefaultTo("Error occurred when trying to create an organization template")
      )
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <Dialog onClose={onCancel} id="create-organization-template-dialog" title="Create Template">
      <Form form={form}>
        <TextFieldRequired name="name" label="Name" autoFocus />

        <AssignedWorkspaceField
          label="Copy existing configuration"
          name="server"
          workspaceType={ServerResponseTypeEnum.Organization}
          optional
          emptyValue={{ id: EMPTY_VALUE_PLACEHOLDER_SERVER, name: "Not selected" }}
        />

        <FormFooter onCancel={onCancel} onSubmit={handleCreate} submitText="Create" />
      </Form>
    </Dialog>
  );
}
