import React, { CSSProperties, useEffect } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  progress: {
    position: "absolute",
    left: "calc(50% - 50px);",
  },
  circle: {
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

const globalProgress = document.querySelector("#globalProgress");
const globalProgressHTML = globalProgress?.innerHTML ?? "";
let timeout;
let interval;
let isVisible = true;

// added temporarily for test check purpose
function startCounting() {
  clearInterval(interval);
  let sec = 0;
  console.log(0);
  interval = setInterval(() => {
    console.log(++sec);
  }, 100);
}

function stopCounting() {
  clearInterval(interval);
}

function showLoader() {
  clearTimeout(timeout);
  if (!isVisible && globalProgress) {
    globalProgress.innerHTML = globalProgressHTML;
    isVisible = true;
  }
}

function hideLoader() {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (globalProgress) {
      globalProgress.innerHTML = "";
      isVisible = false;
      stopCounting();
    }
  });
}

if (window.navigator?.userAgent === "cloudentity-acp-test") {
  startCounting();
}

interface Props {
  isGlobalLoader?: boolean;
  size?: number;
  top?: number | string;
  classes?: any;
  style?: CSSProperties;
}

const Progress = ({ isGlobalLoader, size = 100, top = 40, style = {} }: Props) => {
  const { classes } = useStyles();

  useEffect(() => {
    if (isGlobalLoader && globalProgress) {
      showLoader();
      return () => hideLoader();
    }
  }, [isGlobalLoader]);

  return (
    (!isGlobalLoader && (
      <div className={classes.progress} style={{ top, ...style }}>
        <CircularProgress size={size} className={classes.circle} />
      </div>
    )) ||
    null
  );
};

export default Progress;
