import React, { ReactNode } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { useCommonStyles } from "./utils";

interface Props {
  children: ReactNode;
  rightPanel?: ReactNode;
}

export default function SelfServiceTabContent({ children, rightPanel }: Props) {
  const { classes: commonClasses } = useCommonStyles();

  return (
    <div className={commonClasses.container}>
      <Grid container spacing={3} className={commonClasses.mainGrid}>
        <Grid item lg={7} sm={12}>
          {children}
        </Grid>
        {rightPanel && (
          <Grid item lg={5} sm={12}>
            <Paper className={commonClasses.sidePanel}>{rightPanel}</Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
