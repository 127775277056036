import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import {
  CloudLightning,
  Eye,
  Feather,
  FileText,
  Grid as GridIcon,
  MoreHorizontal,
  Settings,
  Shield,
  Star,
  Tool,
} from "react-feather";
import { makeStyles } from "tss-react/mui";

import { ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import AnalyticsIcon from "../../../common/components/icons/AnalyticsIcon";
import AuthZServerIcon from "../../../common/components/icons/AuthZServerIcon";
import AuthenticationIcon from "../../../common/components/icons/AuthenticationIcon";
import IdentityPoolIcon from "../../../common/components/icons/IdentityPoolIcon";
import LogsIcon from "../../../common/components/icons/LogsIcon";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import { isSystemTenantAdminWorkspace } from "../AdminPanel";
import { useWorkspace } from "../common/useWorkspace";
import WorkspaceSelector from "../workspaces/WorkspaceSelector";
import { NavDrawerContentProps } from "./NavDrawer";
import NavItem from "./NavItem";
import NavSection from "./NavSection";
import NavSectionItem from "./NavSectionItem";
import { useSections } from "./useSections";

const useStyles = makeStyles()(() => ({
  listPadding: {
    padding: 0,
    overflow: "auto",
  },
}));

const initSections = {
  applications: {
    isActive: (pathname, workspace) =>
      pathname.startsWith(`/${workspace}/applications`) ||
      pathname.startsWith(`/${workspace}/services`),
  },
  enforcement: {
    isActive: (pathname, workspace) =>
      pathname.startsWith(`/${workspace}/apis/`) || pathname.startsWith(`/${workspace}/gateways`),
  },
  governance: {
    isActive: (pathname, workspace) =>
      pathname.startsWith(`/${workspace}/visualizer`) ||
      pathname.startsWith(`/${workspace}/policies`),
  },
  extensions: {
    isActive: (pathname, workspace) =>
      pathname.startsWith(`/${workspace}/extensions`) ||
      pathname.startsWith(`/${workspace}/webhooks`) ||
      pathname.startsWith(`/${workspace}/secrets`) ||
      pathname.startsWith(`/${workspace}/custom-applications`),
  },
  "oauth-server": {
    isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/workspaces/`),
  },
  authentication: {
    isActive: (pathname, workspace) =>
      pathname.startsWith(`/${workspace}/authentication/identities`) ||
      pathname.startsWith(`/${workspace}/identities`) ||
      pathname.startsWith(`/${workspace}/authentication/sessions-and-context`),
  },
  settings: {
    isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/settings/`),
  },
};

const advancedIsActive = (pathname, workspace) =>
  pathname.startsWith(`/${workspace}/audit-events`) ||
  pathname.startsWith(`/${workspace}/applications`) ||
  pathname.startsWith(`/${workspace}/services`) ||
  pathname.startsWith(`/${workspace}/pools`) ||
  pathname.startsWith(`/${workspace}/apis/`) ||
  pathname.startsWith(`/${workspace}/gateways`) ||
  pathname.startsWith(`/${workspace}/visualizer`) ||
  pathname.startsWith(`/${workspace}/policies`) ||
  pathname.startsWith(`/${workspace}/extensions`) ||
  pathname.startsWith(`/${workspace}/webhooks`) ||
  pathname.startsWith(`/${workspace}/secrets`) ||
  pathname.startsWith(`/${workspace}/custom-applications`) ||
  pathname.startsWith(`/${workspace}/workspaces`) ||
  pathname.startsWith(`/${workspace}/manage-access`) ||
  pathname.startsWith(`/${workspace}/settings`) ||
  pathname.startsWith(`/${workspace}/appearance`) ||
  pathname.startsWith(`/${workspace}/analytics`);

export default function DeveloperNavDrawerContent({
  isOpen,
  onOpenDrawer,
  onSectionClick,
  onItemClick,
}: NavDrawerContentProps) {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const [workspace] = useWorkspace();
  const isAnalyticsEnabled = useFeature("with_analytics");

  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);

  const isOrganizationWorkspace =
    serverQuery.data?.type === ServerResponseTypeEnum.Organization || false;

  const [isAdvancedConfigurationOpen, setAdvancedConfigurationOpen] = useState(false);
  const { sections, setSections, sectionClick } = useSections(initSections, isOpen, onSectionClick);
  const tenantId = getTenantId();

  useEffect(() => {
    if (!isOpen) {
      setAdvancedConfigurationOpen(false);
    }
  }, [isOpen]);

  const toggleAdvancedConfiguration = useCallback(() => {
    setAdvancedConfigurationOpen(!isAdvancedConfigurationOpen);
    onOpenDrawer();

    if (!isAdvancedConfigurationOpen) {
      setSections(
        Object.keys(sections).reduce((acc, sid) => {
          acc[sid] = {
            ...sections[sid],
            isOpen: initSections[sid].isActive(pathname, workspace),
          };

          return acc;
        }, {})
      );
    }
  }, [isAdvancedConfigurationOpen, onOpenDrawer, sections, pathname, workspace, setSections]);

  useEffect(() => {
    if (advancedIsActive(pathname, workspace) && isOpen) {
      setAdvancedConfigurationOpen(true);
    } else {
      setAdvancedConfigurationOpen(false);
    }
  }, [isOpen, pathname, workspace]);

  return (
    <>
      <WorkspaceSelector onClick={onOpenDrawer} />
      <Divider />
      <List classes={{ padding: classes.listPadding }}>
        <NavItem
          id="overview-link"
          text="Overview"
          to={`/${workspace}/overview`}
          icon={Eye}
          onClick={() => {
            onItemClick();
            setAdvancedConfigurationOpen(false);
            onSectionClick();
          }}
          isActive={
            pathname.startsWith(`/${workspace}/overview`) ||
            pathname.startsWith(`/${workspace}/analytics`)
          }
        />

        <NavSection
          id="authentication"
          label="Authentication"
          isActive={initSections.authentication.isActive(pathname, workspace)}
          isOpen={sections.authentication.isOpen}
          icon={AuthenticationIcon}
          hasOneOfWorkspacePermissions={["read_idps"]}
          onClick={sectionClick}
          isDrawerOpen={isOpen}
        >
          <NavSectionItem
            id="providers-link"
            text="Providers"
            to={`/${workspace}/authentication/identities`}
            isActive={
              pathname.startsWith(`/${workspace}/authentication/identities`) ||
              pathname.startsWith(`/${workspace}/identities`)
            }
            ignoreMatchAndActiveToAllowNavigate
            hasWorkspacePermission="read_idps"
            onClick={onItemClick}
          />
          <NavSectionItem
            id="sessions-and-context-link"
            text="Sessions and Context"
            to={`/${workspace}/authentication/sessions-and-context/authn-context-attributes`}
            isActive={pathname.startsWith(`/${workspace}/authentication/sessions-and-context/`)}
            hasWorkspacePermission="read_idps"
            onClick={onItemClick}
          />
        </NavSection>

        <NavSection
          id="advanced-configuration"
          label={<Typography variant="h6">Advanced Configuration</Typography>}
          isActive={advancedIsActive(pathname, workspace)}
          onClick={toggleAdvancedConfiguration}
          isOpen={isAdvancedConfigurationOpen}
          isDrawerOpen={isOpen}
          icon={!isOpen ? MoreHorizontal : undefined}
          disableActiveShadowEffect
        >
          <NavSection
            id="applications"
            label="Applications"
            isActive={initSections["applications"].isActive(pathname, workspace)}
            isOpen={sections["applications"].isOpen}
            icon={GridIcon}
            hasOneOfWorkspacePermissions={["read_clients", "read_services"]}
            onClick={sectionClick}
            isDrawerOpen={isOpen}
          >
            <NavSectionItem
              id="clients-link"
              text="Clients"
              to={`/${workspace}/applications`}
              hasWorkspacePermission="read_clients"
              onClick={onItemClick}
            />
            <NavSectionItem
              id="services-link"
              text="Services"
              to={`/${workspace}/services`}
              hasWorkspacePermission="read_services"
              onClick={onItemClick}
            />
          </NavSection>

          {isOrganizationWorkspace && (
            <NavItem
              id="users-link"
              text="Users"
              to={`/${workspace}/pools`}
              icon={IdentityPoolIcon}
              hasWorkspacePermission="list_identity_pools"
              onClick={onItemClick}
            />
          )}

          <NavSection
            id="enforcement"
            label="Enforcement"
            isActive={initSections["enforcement"].isActive(pathname, workspace)}
            isOpen={sections["enforcement"].isOpen}
            icon={CloudLightning}
            hasOneOfWorkspacePermissions={["read_gateways", "read_apis"]}
            onClick={sectionClick}
            isDrawerOpen={isOpen}
          >
            <NavSectionItem
              id="gateways-link"
              text="Authorizers"
              to={`/${workspace}/gateways`}
              hasWorkspacePermission="read_gateways"
              onClick={onItemClick}
            />
            <NavSectionItem
              id="authorizers-link"
              text="APIs"
              to={`/${workspace}/apis/authorization`}
              isActive={pathname.startsWith(`/${workspace}/apis/`)}
              hasWorkspacePermission="read_apis"
              onClick={onItemClick}
            />
          </NavSection>
          <NavSection
            id="governance"
            label="Governance"
            isActive={initSections["governance"].isActive(pathname, workspace)}
            isOpen={sections["governance"].isOpen}
            icon={Shield}
            onClick={sectionClick}
            isDrawerOpen={isOpen}
          >
            <NavSectionItem
              id="visualizer-link"
              text="Data Governance"
              to={`/${workspace}/visualizer`}
              onClick={() => onItemClick(true)}
            />
            <NavSectionItem
              id="policies-link"
              text="Policies"
              to={`/${workspace}/policies`}
              hasWorkspacePermission="read_policies"
              onClick={onItemClick}
            />
          </NavSection>
          <NavSection
            id="extensions"
            label="Extensions"
            isActive={initSections["extensions"].isActive(pathname, workspace)}
            isOpen={sections["extensions"].isOpen}
            icon={FileText}
            hasOneOfWorkspacePermissions={[
              "read_scripts",
              "read_custom_apps",
              "read_secrets",
              "read_webhooks",
            ]}
            onClick={sectionClick}
            isDrawerOpen={isOpen}
          >
            <NavSectionItem
              id="scripts-link"
              text="Scripts"
              to={`/${workspace}/extensions`}
              hasWorkspacePermission="read_scripts"
              onClick={() => onItemClick(true)}
            />
            <NavSectionItem
              id="custom-applications-link"
              text="Custom Apps"
              to={`/${workspace}/custom-applications`}
              hasWorkspacePermission="read_custom_apps"
              onClick={onItemClick}
            />
            <NavSectionItem
              id="secrets-link"
              text="Secrets"
              to={`/${workspace}/secrets`}
              hasWorkspacePermission="read_secrets"
              onClick={onItemClick}
            />
            <NavSectionItem
              id="webhooks-link"
              text="Webhooks"
              to={`/${workspace}/webhooks`}
              hasWorkspacePermission="read_webhooks"
              onClick={onItemClick}
            />
          </NavSection>
          <NavSection
            id="oauth-server"
            label="Authorization Server"
            isActive={initSections["oauth-server"].isActive(pathname, workspace)}
            isOpen={sections["oauth-server"].isOpen}
            icon={AuthZServerIcon}
            hasOneOfWorkspacePermissions={["get_workspace"]}
            onClick={sectionClick}
            isDrawerOpen={isOpen}
          >
            <NavSectionItem
              id="oauth-server-oauth"
              text="OAuth"
              to={`/${workspace}/workspaces/oauth/general`}
              isActive={pathname.startsWith(`/${workspace}/workspaces/oauth`)}
              onClick={onItemClick}
            />
            <NavSectionItem
              id="oauth-server-tokens"
              text="Tokens"
              to={`/${workspace}/workspaces/tokens/type`}
              isActive={pathname.startsWith(`/${workspace}/workspaces/tokens`)}
              onClick={onItemClick}
            />
            <NavSectionItem
              id="oauth-server-consent"
              text="Consent"
              to={`/${workspace}/workspaces/consent`}
              isActive={pathname.startsWith(`/${workspace}/workspaces/consent`)}
              onClick={onItemClick}
            />
            <NavSectionItem
              id="oauth-server-authorization"
              text="Access Control"
              to={`/${workspace}/workspaces/authorization`}
              isActive={pathname.startsWith(`/${workspace}/workspaces/authorization`)}
              onClick={onItemClick}
            />
            {serverQuery.data?.type === ServerResponseTypeEnum.Organization && (
              <NavSectionItem
                id="oauth-server-metadata"
                text="Metadata"
                to={`/${workspace}/workspaces/metadata`}
                isActive={pathname.startsWith(`/${workspace}/workspaces/metadata`)}
                onClick={onItemClick}
              />
            )}
            <NavSectionItem
              id="oauth-server-advanced"
              text="Advanced"
              to={`/${workspace}/workspaces/advanced`}
              isActive={pathname.startsWith(`/${workspace}/workspaces/advanced`)}
              onClick={onItemClick}
            />
          </NavSection>

          <NavItem
            id="settings"
            text="Settings"
            to={`/${workspace}/settings/general`}
            icon={Settings}
            hasWorkspacePermission="get_workspace"
            onClick={onItemClick}
            isActive={pathname.startsWith(`/${workspace}/settings/`)}
          />

          <NavItem
            id="appearance"
            text="Appearance"
            to={`/${workspace}/appearance`}
            icon={Feather}
            hasWorkspacePermission="get_workspace"
            onClick={onItemClick}
          />

          {isAnalyticsEnabled && (
            <NavItem
              id="analytics"
              text="Analytics"
              to={`/${workspace}/analytics/oauth2`}
              icon={AnalyticsIcon}
              hasWorkspacePermission="read_analytics"
              onClick={onItemClick}
              isActive={pathname.startsWith(`/${workspace}/analytics`)}
            />
          )}

          <NavItem
            id="logs"
            text="Logs"
            to={`/${workspace}/audit-events`}
            icon={LogsIcon}
            onClick={onItemClick}
            isActive={pathname.startsWith(`/${workspace}/audit-events`)}
          />

          {isSystemTenantAdminWorkspace(tenantId, workspace) && (
            <>
              <NavItem
                id="tenant-management-link"
                text="Tenant management"
                to={`/${workspace}/tenant-management`}
                icon={Tool}
                onClick={onItemClick}
              />
              <NavItem
                id="features-link"
                text="System features"
                to={`/${workspace}/features`}
                icon={Star}
                onClick={onItemClick}
              />
            </>
          )}
        </NavSection>
      </List>
    </>
  );
}
