import React from "react";

import Typography from "@mui/material/Typography";
import { ChevronRight } from "react-feather";
import { makeStyles } from "tss-react/mui";

import SelectablePaper from "../../../common/components/SelectablePaper";
import getStartedCardBackground from "./get-started-card-background.svg";

const useStyles = makeStyles()(theme => ({
  root: {
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    background: `url(${getStartedCardBackground}) no-repeat left center`,
    backgroundColor: "#FFF",
    border: "1px solid transparent",
  },
  content: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 32,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

interface Props {
  label: string;
  icon: React.ReactNode;
  selectable?: boolean;
  onSelect?: () => void;
  selected?: boolean;
  id: string;
  noChevron?: boolean;
}
export default function GetStartedCard({
  label,
  icon,
  onSelect,
  selectable = true,
  selected,
  id,
  noChevron,
}: Props) {
  const { cx, classes } = useStyles();

  return (
    <SelectablePaper
      className={cx(classes.root, selected && classes.selected)}
      onSelect={onSelect}
      selectable={selectable}
      id={id}
    >
      <div className={classes.content}>
        {icon}
        <Typography className={classes.label}>{label}</Typography>
      </div>
      {!noChevron && <ChevronRight className={classes.icon} />}
    </SelectablePaper>
  );
}
