import { QueryClient, UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import {
  ServerResponseProfileEnum,
  WorkspaceResponse,
  WorkspaceResponseTypeEnum,
} from "@cloudentity/acp-admin";
import { PoolResponse } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../common/api/paths";
import {
  handleErrorWithNotify,
  notifyError,
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { setInLocalStorage } from "../../../common/utils/localStorage.utils";
import { listIDPsQueryKey } from "../../services/adminIDPsQuery";
import identityPoolsApi from "../../services/adminIdentityPoolsApi";
import { listOrganizationsQueryKey } from "../../services/adminOrganizationsQuery";
import adminServersApi from "../../services/adminServersApi";
import {
  getWorkspaceQueryKey,
  listServerBindingsQueryKey,
  listWorkspacesQueryKey,
} from "../../services/adminServersQuery";
import { ORGANIZATION_KEY_LOCAL_STORAGE } from "../common/useOrganization";
import { removeFromRecentWorkspaces } from "../workspaces/WorkspaceSelector";

export const workspaceAppTypes = [
  {
    value: "demo",
    title: "Demo Environment",
    subtitle: "Tour around a pre-configured workspace",
    profile: ServerResponseProfileEnum.Default,
  },
  {
    value: "consumer",
    title: "Consumer Applications and Services",
    subtitle: "Build and protect Applications, services and APIS for your consumers",
    profile: ServerResponseProfileEnum.Consumer,
  },
  {
    value: "fintech",
    title: "Fintech and mission-critical applications",
    subtitle: "Protect your most sensitive apps and services",
    profile: ServerResponseProfileEnum.FapiAdvanced,
  },
  {
    value: "openbanking_uk",
    title: "Open Banking UK",
    subtitle: "Open Banking UK compliant workspace",
    profile: ServerResponseProfileEnum.OpenbankingUkFapiAdvanced,
  },
  {
    value: "openbanking_br",
    title: "Open Finance Brazil",
    subtitle: "Open Finance Brazil compliant workspace",
    profile: ServerResponseProfileEnum.OpenbankingBr,
  },
  {
    value: "cdr_australia",
    title: "CDR Australia",
    subtitle: "CDR Australia compliant workspace",
    profile: ServerResponseProfileEnum.CdrAustralia,
  },
  {
    value: "fdx",
    title: "Financial Data Exchange",
    subtitle: "Financial Data Exchange compliant workspace",
    profile: ServerResponseProfileEnum.Fdx,
  },
  {
    value: "openbanking_generic",
    title: "Generic Open Banking",
    subtitle: "Build Generic Open Banking based on FAPI 2.0",
    profile: ServerResponseProfileEnum.Fapi20Security,
  },
  {
    value: "openbanking_ksa",
    title: "Open Banking KSA",
    subtitle: "Open Banking Kingdom Saudi Arabia compliant workspace",
    profile: ServerResponseProfileEnum.OpenbankingKsa,
  },
  {
    value: "connect_id",
    title: "Connect ID",
    subtitle: "Connect ID compliant server",
    profile: ServerResponseProfileEnum.ConnectId,
  },
  {
    value: "partners",
    title: "Partners B2B Integration",
    subtitle: "Enable your partners systems to connect to your APIs",
    profile: ServerResponseProfileEnum.Partners,
  },
  {
    value: "third_party",
    title: "Third Party",
    subtitle: "Allow 3rd party developers to integrate with your APIs and services",
    profile: ServerResponseProfileEnum.ThirdParty,
  },
  {
    value: "workforce",
    title: "Workforce",
    subtitle: "Build and protect applications, services, and APIs used by your workforce",
    profile: ServerResponseProfileEnum.Workforce,
  },
];

export const getWorkspaceAppTypeByValue = value => workspaceAppTypes.find(w => w.value === value);

export enum WorkspaceTab {
  WORKSPACES_SEQ = "workspaces-seq",
  WORKSPACES_CURSOR = "workspaces-cursor",
  DEVELOPER_PORTALS_SEQ = "developer-portals-seq",
  ADMINISTRATIVE_SEQ = "administrative_seq",
}

export enum OrganizationsDirectoryTab {
  ORGANIZATIONS = "organizations",
  ORGANIZATIONS_TEMPLATES = "templates",
  ORGANIZATIONS_SETTINGS = "settings",
}

export const handleDeleteServer = ({
  server,
  workspacePools,
  setProgress,
  handleClose,
  queryClient,
  removeServerMutation,
  currentWorkspace,
  currentOrganization,
}: {
  server: Pick<WorkspaceResponse, "id" | "name" | "type">;
  workspacePools: PoolResponse[];
  setProgress: (id: string) => void;
  handleClose: () => void;
  queryClient: QueryClient;
  removeServerMutation: UseMutationResult<AxiosResponse<void, any>, unknown, string, unknown>;
  currentWorkspace: string;
  currentOrganization: string;
}) => {
  const { type, id = "" } = server;
  const tenantId = getTenantId();

  if (type === WorkspaceResponseTypeEnum.Organization) {
    setProgress(id);

    Promise.all(
      workspacePools.map(pool => identityPoolsApi.deletePool({ ipID: pool.id!, withIdp: true }))
    )
      .then(() => removeServerMutation.mutateAsync(id))
      .then(() => queryClient.invalidateQueries({ queryKey: getWorkspaceQueryKey(id) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() => queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(getTenantId()) }))
      .then(() => {
        if (id === currentOrganization) {
          setInLocalStorage(ORGANIZATION_KEY_LOCAL_STORAGE, "");
        }
      })
      .then(() => notifySuccess("Organization successfully removed"))
      .catch(err => {
        if (
          err.response?.data?.status_code === 400 &&
          err.response?.data?.error === "resource can't be removed"
        ) {
          notifyError("Organization has identity pool assigned. Remove identity pool first.");
          return;
        }

        handleErrorWithNotify("Error occur while trying to remove organization")(err);
      })
      .finally(() => {
        setProgress("");
        handleClose();
      });

    return;
  }

  setProgress(id);
  adminServersApi
    .removeServer({ wid: id })
    .then(() => {
      if (id === currentWorkspace) {
        removeFromRecentWorkspaces(currentWorkspace);
      }
    })
    .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
    .then(() => queryClient.invalidateQueries({ queryKey: listServerBindingsQueryKey(tenantId) }))
    .then(() => queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(tenantId) }))
    .then(() => notifySuccess("Workspace successfully removed"))
    .catch(err => {
      notifyErrorOrDefaultTo("Error occur while trying to remove workspaces")(err);
    })
    .finally(() => {
      setProgress("");
      handleClose();
    });
};
