export const attributesDataTypeOptions = [
  {
    value: "string",
    name: "String",
  },
  {
    value: "string_array",
    name: "List of strings",
  },
  {
    value: "bool",
    name: "Boolean",
  },
  {
    value: "bool_array",
    name: "List of booleans",
  },
  {
    value: "number",
    name: "Number",
  },
  {
    value: "number_array",
    name: "List of numbers",
  },
  {
    value: "object",
    name: "Object",
  },
  {
    value: "object_array",
    name: "List of objects",
  },
];

export const mapDataTypeToDisplayName = value =>
  attributesDataTypeOptions.find(o => o.value === value)?.name ?? value;
