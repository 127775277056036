import React, { useMemo } from "react";

import { getTenantId } from "../../../../common/api/paths";
import AutocompleteField from "../../../../common/utils/forms/AutocompleteField";
import { useGetAuthorizationServer } from "../../../services/adminServersQuery";
import { useWorkspace } from "../../common/useWorkspace";
import { getAllPaths } from "../../workspaceDirectory/identityPools/schemas/schemas.utils";

export default function ClaimWorkspaceSourceType() {
  const [workspace] = useWorkspace();
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);

  const options = useMemo(() => {
    const schema = serverQuery.data?.metadata?.schema || {};

    return getAllPaths(schema) || [];
  }, [serverQuery.data]);

  return (
    <AutocompleteField
      name="metadata_key"
      label="Metadata key"
      options={options}
      defaultValue=""
      freeSolo
      autoHighlight={false}
      autoSelect
    />
  );
}
