import React from "react";

import { Claim } from "@cloudentity/acp-admin";

import Dialog from "../../../../common/components/Dialog";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";

export interface OAuthVerifiedClaimsCreateProps {
  workspace: string;
  existingClaims: (string | undefined)[];
  onCreate: (claims: Claim[]) => void;
  onCancel: () => void;
  progress: boolean;
}

const OAuthVerifiedClaimsCreate = ({
  workspace,
  existingClaims,
  onCreate,
  onCancel,
  progress,
}: OAuthVerifiedClaimsCreateProps) => {
  const form = useForm({ id: "add-claim", progress });

  const handleCreate = data => {
    const claim = {
      authorization_server_id: workspace,
      name: data.name,
      description: data.description,
      source_path: data.name,
      type: "id_token",
      verified: true,
      source_type: "verifiedAuthnCtx",
    };
    onCreate([{ ...claim }]);
  };

  return (
    <Dialog onClose={onCancel} id="add-claim-dialog" title="Add verified claim">
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          rules={{
            validate: {
              notUniq: validators.notUniq({ label: "Name", options: existingClaims }),
            },
          }}
          autoFocus
        />
        <TextFieldRequired name="description" label="Description" />
        <FormFooter onCancel={onCancel} onSubmit={handleCreate} submitText="Add" />
      </Form>
    </Dialog>
  );
};

export default OAuthVerifiedClaimsCreate;
