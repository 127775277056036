import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";

import { useWorkspace } from "../common/useWorkspace";

export const useSections = (initSections, isOpen, onSectionClick) => {
  const { pathname } = useLocation();
  const [workspace] = useWorkspace();

  const [openSectionsMap, setOpenSectionsMap] = useState<{
    [k: string]: { isOpen: boolean };
  }>(initSections);
  const [previousOpenSection, setPreviousOpenSection] = useState("");

  const sectionClick = useCallback(
    sectionID => {
      setPreviousOpenSection(sectionID !== previousOpenSection ? sectionID : "");

      if (!isOpen) {
        onSectionClick();
      }

      setOpenSectionsMap(
        Object.keys(openSectionsMap).reduce((acc, sid) => {
          acc[sid] = {
            isOpen: sid === sectionID ? !openSectionsMap[sid].isOpen : false,
          };

          return acc;
        }, {})
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openSectionsMap, isOpen, previousOpenSection]
  );

  useEffect(() => {
    if (!isOpen) {
      setPreviousOpenSection("");
      setOpenSectionsMap(
        Object.keys(openSectionsMap).reduce((acc, sid) => {
          acc[sid] = {
            isOpen: false,
          };

          return acc;
        }, {})
      );
    } else if (previousOpenSection !== "") {
      setOpenSectionsMap(
        Object.keys(openSectionsMap).reduce((acc, sid) => {
          acc[sid] = {
            isOpen: sid === previousOpenSection,
          };

          return acc;
        }, {})
      );
    } else {
      setOpenSectionsMap(
        Object.keys(openSectionsMap).reduce((acc, sid) => {
          acc[sid] = {
            isOpen: initSections[sid].isActive(pathname, workspace),
          };

          return acc;
        }, {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, pathname, workspace, previousOpenSection]);

  return { sections: openSectionsMap, setSections: setOpenSectionsMap, sectionClick };
};
