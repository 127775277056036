import React, { CSSProperties, ReactNode } from "react";

import MuiTooltip, { TooltipProps as MuiTooltipProps } from "@mui/material/Tooltip";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.dark,
    padding: "6px 12px",
    wordBreak: "keep-all",
    ...(theme.typography.caption as CSSProperties),
  },
}));

interface Props extends Omit<MuiTooltipProps, "children" | "title"> {
  children: React.ReactElement;
  title: ReactNode;
}

function Tooltip({ children, title, ...props }: Props) {
  const { classes } = useStyles();

  return (
    <MuiTooltip
      classes={{
        tooltip: classes.tooltip,
      }}
      title={title}
      placement="top"
      {...props}
    >
      {children}
    </MuiTooltip>
  );
}

export default Tooltip;
