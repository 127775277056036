import React, { useMemo, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import set from "lodash/set";
import { makeStyles } from "tss-react/mui";

import {
  BaseNewUserPayload,
  BaseNewUserPayloadStatusEnum,
  BaseUserWithData,
  NewUserIdentifierTypeEnum,
  NewUserVerifiableAddressStatusEnum,
  NewUserVerifiableAddressTypeEnum,
  SupportedJSONSchema,
  UserWithData,
} from "@cloudentity/acp-identity";

import { useWorkspaceDefaultClientApp } from "../../../admin/components/common/useWorkspaceDefaultClientApp";
import {
  getPathsAndTypes,
  getRequiredPaths,
} from "../../../admin/components/workspaceDirectory/identityPools/schemas/schemas.utils";
import { useGetPool } from "../../../admin/services/adminIdentityPoolsQuery";
import { useGetWorkspaceSchema } from "../../../admin/services/adminIdentitySchemasQuery";
import identityUsersApi from "../../../admin/services/adminIdentityUsersApi";
import { listUsersQueryKey } from "../../../admin/services/adminIdentityUsersQuery";
import { getTenantId } from "../../../common/api/paths";
import Alert from "../../../common/components/Alert";
import Dialog from "../../../common/components/Dialog";
import {
  notifyError,
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import UploadField from "../../../common/utils/forms/UploadField";

type TypesLookupTable = { [key: string]: string | undefined };

function castType(type: string, value: string) {
  switch (type) {
    case "number":
    case "integer": {
      const newValue = Number(value);
      return Number.isNaN(newValue) ? value : newValue;
    }
    case "boolean":
      return value === "true" || value === "t" || value === "yes" || value === "1";
    default:
      return value;
  }
}

export function constructObj(
  properties: string[],
  values: string[],
  payloadTypes: TypesLookupTable,
  metadataTypes: TypesLookupTable,
  businessMetadataTypes: TypesLookupTable
) {
  const payload = {};
  const metadata = {};
  const businessMetadata = {};
  const other = {};

  properties.forEach((path, index) => {
    if (path.startsWith("payload.")) {
      const payloadPath = path.replace("payload.", "");
      const type = payloadTypes[payloadPath] ?? "string";
      set(payload, payloadPath, castType(type, values[index]));
    } else if (path.startsWith("metadata.")) {
      const metadataPath = path.replace("metadata.", "");
      const type = metadataTypes[metadataPath] ?? "string";
      set(metadata, metadataPath, castType(type, values[index]));
    } else if (path.startsWith("business_metadata.")) {
      const businessMetadataPath = path.replace("business_metadata.", "");
      const type = businessMetadataTypes[businessMetadataPath] ?? "string";
      set(businessMetadata, businessMetadataPath, castType(type, values[index]));
    } else {
      const type = payloadTypes[path] ?? "string";
      set(other, path, castType(type, values[index]));
    }
  });

  return { payload, metadata, businessMetadata, other };
}

function getTypesLookupTable(schema?: SupportedJSONSchema): TypesLookupTable {
  return schema
    ? getPathsAndTypes(schema).reduce((acc, curr) => ({ ...acc, [curr.path]: curr.type }), {})
    : {};
}

async function processFile(file: File, processFile: (fileReader: FileReader) => Promise<void>) {
  return new Promise<void>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        await processFile(reader);
        resolve();
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = error => {
      reject(error);
    };
    reader.readAsText(file);
  });
}

const useStyles = makeStyles()(theme => ({
  info: {
    color: theme.palette.secondary.light,
    marginBottom: 32,
    lineHeight: "20px",
  },
}));

class FileProcessingError extends Error {}

interface Props {
  workspaceId: string;
  identityPoolId: string;
  allowMetadata: boolean;
  allowBusinessMetadata: boolean;
  createUserFn: ({
    newUser,
    metadata,
    business_metadata,
  }: {
    newUser: BaseNewUserPayload;
    metadata?: { [key: string]: object };
    business_metadata?: { [key: string]: object };
  }) => Promise<BaseUserWithData | UserWithData>;
  disableWhenMetadataHasRequiredProperties?: boolean;
  onClose: () => void;
}

export default function ImportUsersDialog({
  workspaceId,
  identityPoolId,
  allowMetadata,
  allowBusinessMetadata,
  createUserFn,
  disableWhenMetadataHasRequiredProperties,
  onClose,
}: Props) {
  const { classes } = useStyles();

  const [mode, setMode] = useState<"upload-file" | "upload-users">("upload-file");
  const [progress, setProgress] = useState(false);
  const [progressUsers, setProgressUsers] = useState<number | null>(null);
  const queryClient = useQueryClient();
  const tenantId = getTenantId();

  const defaultWorkspaceClientApp = useWorkspaceDefaultClientApp(workspaceId);

  const data = useMemo(() => ({ file: null }), []);
  const form = useForm({ id: "users-import", initialValues: data, progress });

  const poolQuery = useGetPool(identityPoolId);

  const payloadSchemaQuery = useGetWorkspaceSchema(workspaceId, poolQuery.data?.payload_schema_id, {
    enabled: poolQuery.isSuccess,
  });
  const payloadSchema = payloadSchemaQuery.data?.schema;

  const metadataSchemaQuery = useGetWorkspaceSchema(
    workspaceId,
    poolQuery.data?.metadata_schema_id,
    {
      enabled: poolQuery.isSuccess,
    }
  );
  const metadataSchema = metadataSchemaQuery.data?.schema;

  const businessMetadataSchemaQuery = useGetWorkspaceSchema(
    workspaceId,
    poolQuery.data?.business_metadata_schema_id,
    {
      enabled: poolQuery.isSuccess,
    }
  );
  const businessMetadataSchema = businessMetadataSchemaQuery.data?.schema;

  const adminMetadataRequiredProperties = (
    metadataSchema ? getRequiredPaths(metadataSchema) : []
  ).map(v => `metadata.${v}`);

  const businessMetadataRequiredProperties = (
    businessMetadataSchema ? getRequiredPaths(businessMetadataSchema) : []
  ).map(v => `business_metadata.${v}`);

  const requiredProperties: string[] = [
    ...(payloadSchema ? getRequiredPaths(payloadSchema) : []).map(v => `payload.${v}`),
    ...adminMetadataRequiredProperties,
    ...businessMetadataRequiredProperties,
  ];

  const payloadSchemaTypes = getTypesLookupTable(payloadSchema);
  const metadataSchemaTypes = getTypesLookupTable(metadataSchema);
  const businessMetadataSchemaTypes = getTypesLookupTable(businessMetadataSchema);

  const handleDialogClose = () => {
    queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, identityPoolId) });
    onClose();
  };

  const handleImport = async ({ file }: { file: File | null }) => {
    if (!file) return;

    try {
      setProgress(true);

      await processFile(file, async fileReader => {
        const { result } = fileReader;

        if (!result) throw new FileProcessingError("File can't be parsed");

        const lines = result
          .toString()
          .trim()
          .split(/[\r\n]+/g);

        if (!lines) throw new FileProcessingError("File cannot be empty");
        if (lines.length > 101)
          throw new FileProcessingError("Cannot add more than 100 users in a single import");

        const headers = lines[0].split(",");
        const identifier =
          (headers.includes("phone") && "phone") || (headers.includes("email") && "email") || null;

        if (!identifier) {
          throw new FileProcessingError(
            "File includes invalid data - identifier must be included (either email or phone)"
          );
        }

        const data = lines.slice(1).map(line => {
          const lineData = line.split(",");

          const hasNoRequiredHeader = requiredProperties.find(
            property => !headers.includes(property)
          );

          if (hasNoRequiredHeader) {
            throw new FileProcessingError(
              `File includes invalid data - required header "${hasNoRequiredHeader}" is missing`
            );
          }

          return constructObj(
            headers,
            lineData,
            payloadSchemaTypes,
            metadataSchemaTypes,
            businessMetadataSchemaTypes
          );
        });

        setMode("upload-users");
        setProgressUsers(0);

        for (let i = 0; i < data.length; i += 1) {
          await handleCreateUser(
            data[i].payload,
            data[i].metadata,
            data[i].businessMetadata,
            data[i].other,
            identifier
          );
          setProgressUsers(((i + 1) / data.length) * 100);
        }

        notifySuccess("All users imported");
      });
    } catch (err) {
      setMode("upload-file");
      if (err instanceof FileProcessingError) {
        notifyError(err.message);
      } else {
        notifyErrorOrDefaultTo("Error occurred when trying to create user")(err);
      }
    } finally {
      setProgress(false);
    }
  };

  const handleCreateUser = (
    payload,
    metadata,
    businessMetadata,
    other,
    identifier: "email" | "phone" | null
  ) => {
    const status = BaseNewUserPayloadStatusEnum.New;
    const verified = false;
    const credentials = [];

    const identifiers =
      (identifier === "email" && [
        {
          identifier: other.email,
          type: NewUserIdentifierTypeEnum.Email,
        },
      ]) ||
      (identifier === "phone" && [
        {
          identifier: other.phone,
          type: NewUserIdentifierTypeEnum.Mobile,
        },
      ]) ||
      [];

    const verifiableAddresses =
      (identifier === "email" && [
        {
          address: other.email,
          status: NewUserVerifiableAddressStatusEnum.Active,
          type: NewUserVerifiableAddressTypeEnum.Email,
          verified,
        },
      ]) ||
      (identifier === "phone" && [
        {
          address: other.phone,
          status: NewUserVerifiableAddressStatusEnum.Active,
          type: NewUserVerifiableAddressTypeEnum.Mobile,
          verified,
        },
      ]) ||
      [];

    return createUserFn({
      newUser: {
        payload,
        status: status,
        credentials: credentials,
        identifiers,
        verifiable_addresses: verifiableAddresses,
      },
      metadata,
      business_metadata: businessMetadata,
    }).then(res => {
      return identityUsersApi.sendActivationMessage({
        ipID: identityPoolId,
        userID: res.id!,
        serverId: workspaceId,
        postActivationUrl: defaultWorkspaceClientApp.url,
      });
    });
  };

  const createUserDisabled =
    disableWhenMetadataHasRequiredProperties &&
    (adminMetadataRequiredProperties.length > 0 || businessMetadataRequiredProperties.length > 0);

  return (
    <Dialog
      id="import-users-dialog"
      title="Import users"
      onCancel={handleDialogClose}
      withCloseIcon
    >
      <Form form={form}>
        {createUserDisabled && (
          <>
            <Alert severity="error" title="Create users is disabled">
              We apologize, but account creation is not possible due to permission issues. Please
              contact the administrator for assistance.
            </Alert>

            <FormFooter onCancel={onClose} />
          </>
        )}
        {!createUserDisabled && (
          <>
            <UploadField
              id="import-users-dialog-file"
              name="file"
              title="Upload CSV File"
              rules={{
                validate: {
                  isCSV: v =>
                    (v?.name?.endsWith(".csv") && v?.type === "text/csv") ||
                    "Provided file is not CSV",
                },
              }}
              disabled={progress || mode === "upload-users"}
              onRemoveFile={() => {
                setMode("upload-file");
              }}
            />

            <Typography component="div" variant="textSM" className={classes.info}>
              <p>
                Column names must match attributes and be comma-delimited. Any order is allowed. All{" "}
                {[
                  "payload",
                  allowMetadata && "metadata",
                  allowBusinessMetadata && "business metadata",
                ]
                  .filter(Boolean)
                  .join(", ")}{" "}
                properties require a corresponding prefix.
                <br />
                Required columns based on current schemas:
                <br />
                <code style={{ wordBreak: "break-all" }}>email,{requiredProperties.join(",")}</code>
                <br />
                Object and array field types are not currently supported in the import process.
                <br />
                Up to 100 records can be imported in a single import.
              </p>
            </Typography>

            {mode === "upload-users" && !!progressUsers && (
              <div style={{ paddingBottom: 32 }}>
                {progressUsers !== 100 ? "Uploading users..." : "Users uploaded"}

                <LinearProgress variant="determinate" value={progressUsers} />
              </div>
            )}

            <FormFooter
              onCancel={handleDialogClose}
              onSubmit={data => handleImport(data)}
              cancelText={!!progressUsers && progressUsers === 100 ? "Done" : "Cancel"}
              submitText="Import"
              disabled={mode === "upload-users"}
            />
          </>
        )}
      </Form>
    </Dialog>
  );
}
