import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import {
  SelfUserVerifiableAddress,
  SelfUserVerifiableAddressTypeEnum,
} from "@cloudentity/acp-identityself";

const useStyles = makeStyles()(() => ({
  column: {
    display: "flex",
    flexDirection: "column",
    marginTop: 8,
  },
  address: {
    marginBottom: 8,
    fontFamily: "monospace",
  },
  icon: {
    width: 24,
    marginLeft: 8,
  },
  label: {
    textTransform: "uppercase",
    color: "#9EA1B7",
  },
}));

interface Props {
  addresses: SelfUserVerifiableAddress[];
}

export default function SelfServiceCredentialVerificationCode({ addresses }: Props) {
  const { classes } = useStyles();

  const emailAddresses = addresses.filter(
    address => address.type === SelfUserVerifiableAddressTypeEnum.Email
  );
  const mobileAddresses = addresses.filter(
    address => address.type === SelfUserVerifiableAddressTypeEnum.Mobile
  );

  return (
    <Grid container spacing={2} id="credential-item-verification-code-container">
      {addresses.length === 0 && (
        <Grid item xs={6}>
          <Typography variant="textMD">No addresses</Typography>
        </Grid>
      )}

      {emailAddresses.length > 0 && (
        <Grid item xs={6}>
          <Typography variant="textXSM" component="label" className={classes.label}>
            Email
          </Typography>
          <div className={classes.column}>
            {emailAddresses.map(address => (
              <Typography variant="textMD" key={address.address} className={classes.address}>
                {address.address}
              </Typography>
            ))}
          </div>
        </Grid>
      )}

      {mobileAddresses.length > 0 && (
        <Grid item xs={6}>
          <Typography variant="textXSM" component="label" className={classes.label}>
            Mobile
          </Typography>
          <div className={classes.column}>
            {mobileAddresses.map(address => (
              <Typography variant="textMD" key={address.address} className={classes.address}>
                {address.address}
              </Typography>
            ))}
          </div>
        </Grid>
      )}
    </Grid>
  );
}
