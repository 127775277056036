import React, { CSSProperties } from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { TRANSPARENT_HEX_VALUE } from "../../../../common/components/nav/utils";
import WorkspacesColorInput from "../../workspaceDirectory/WorkspacesColorInput";

const useStyles = makeStyles()(theme => ({
  header: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    color: theme.palette.secondary.dark,
  },
  content: {
    display: "flex",
    gap: 24,
    flexWrap: "wrap",
    width: "100%",
    marginTop: 24,
  },
}));

interface Props {
  primary: string;
  header: string;
  onChangePrimary: (primary: string) => void;
  onChangeHeader: (header: string) => void;
  colors: string[];
  style?: CSSProperties;
}

export default function ColorScheme({
  primary,
  header,
  onChangePrimary,
  onChangeHeader,
  colors,
  style,
}: Props) {
  const { classes } = useStyles();

  return (
    <div style={style}>
      <Typography className={classes.header}>Select colors</Typography>
      <div className={classes.content}>
        <WorkspacesColorInput
          value={primary}
          label="Primary color"
          onChange={onChangePrimary}
          colors={colors.filter(c => c !== TRANSPARENT_HEX_VALUE)}
          style={{ flex: 1 }}
        />
        <WorkspacesColorInput
          value={header}
          label="Header color"
          onChange={onChangeHeader}
          colors={colors}
          style={{ flex: 1 }}
        />
      </div>
    </div>
  );
}
