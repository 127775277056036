import { useMutation } from "@tanstack/react-query";

import { getTenantId } from "../../common/api/paths";
import adminIDPsApi from "./adminIDPsApi";
import {
  getIDPQueryKey,
  listIDPsForIdentityPoolQueryKey,
  listIDPsQueryKey,
} from "./adminIDPsQuery";
import { queryClient } from "./queryClient";

export const useUpdateIDPMutation = (idpId: string, wid?: string) => {
  return useMutation({
    mutationFn: (req: { aid: string; type: string; iid: string; body: any }) => {
      return adminIDPsApi.updateIDP(req);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: getIDPQueryKey(idpId, wid) }),
        queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(getTenantId(), wid) }),
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(getTenantId()) }),
      ]),
  });
};
