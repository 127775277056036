import React, { Dispatch, SetStateAction } from "react";

import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";

import {
  OrganizationResponse,
  WorkspaceResponse,
  WorkspaceResponseTypeEnum,
} from "@cloudentity/acp-admin";

import { useAllWorkspaces } from "../../../../b2b/useAllWorkspaces";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PageContent from "../../common/PageContent";
import OrganizationsHierarchy from "./OrganizationsHierarchy";
import OrganizationsToolbar from "./OrganizationsToolbar";
import TenantOrganizationsTabEmptyState from "./TenantOrganizationsTabEmptyState";
import { GridMode, TENANT_ORGANIZATIONS_GRID_MODE_KEY } from "./utils";

interface Props {
  onSelectServer: (id: string) => void;
  onMenuOpen: (props: {
    anchorEl: HTMLElement;
    server: OrganizationResponse;
    rootServer?: OrganizationResponse | WorkspaceResponse;
  }) => void;
  setGridMode: Dispatch<SetStateAction<GridMode>>;
  onCreate: (v: boolean) => void;
}

export default function TenantOrganizationsTabHierarchy({
  onSelectServer,
  onMenuOpen,
  onCreate,
  setGridMode,
}: Props) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations = checkTenantPermissionsQuery.data?.list_organizations;

  const listAllWorkspacesQuery = useAllWorkspaces();
  const allWorkspaces = uniqBy(
    flatten((listAllWorkspacesQuery.data?.pages || []).map(page => page.workspaces || [])),
    w => w.id
  );
  const workspacesWithChildOrganizations = allWorkspaces
    .filter(w => w.type !== WorkspaceResponseTypeEnum.Organization)
    .filter(w => (w.number_of_child_organizations ?? 0) > 0)
    .filter(w => !w.template);

  const toolbar = (
    <OrganizationsToolbar
      createButtonLabel="Create Organization"
      searchText=""
      onUpdate={undefined}
      onCreate={
        checkTenantPermissionsQuery.data?.create_workspace ? () => onCreate(true) : undefined
      }
      gridMode={canListOrganizations ? "hierarchy" : undefined}
      setGridMode={canListOrganizations ? setGridMode : undefined}
      gridModeLocalStorageKey={TENANT_ORGANIZATIONS_GRID_MODE_KEY}
    />
  );

  return (
    <PageContent
      progress={
        listAllWorkspacesQuery.isLoading ||
        listAllWorkspacesQuery.hasNextPage ||
        listAllWorkspacesQuery.isFetchingNextPage
      }
    >
      <OrganizationsHierarchy
        toolbar={toolbar}
        emptyState={<TenantOrganizationsTabEmptyState onCreate={onCreate} />}
        onSelectServer={onSelectServer}
        onMenuOpen={onMenuOpen}
        additionalRootWorkspaces={workspacesWithChildOrganizations}
      />
    </PageContent>
  );
}
