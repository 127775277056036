import React, { useState } from "react";

import VerifiedIcon from "@mui/icons-material/Verified";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useQueryClient } from "@tanstack/react-query";
import { MoreVertical, Plus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { BaseUserWithData, UserVerifiableAddress, UserWithData } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../../common/api/paths";
import AccordionPaper from "../../../../../../../../common/components/AccordionPaper";
import ConfirmationDialog from "../../../../../../../../common/components/ConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../../common/components/notifications/notificationService";
import { useCheckPoolPermissions } from "../../../../../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../../../../../services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listUsersQueryKey,
} from "../../../../../../../services/adminIdentityUsersQuery";
import Tooltip from "../../../../../../common/Tooltip";
import AddUserAddressDialog from "../AddUserAddressDialog";
import EditUserAddressDialog from "../EditUserAddressDialog";
import { iconIdentifierMapper } from "../utils";
import IdentityPoolUserSidePanelChallengeOTP from "./IdentityPoolUserSidePanelChallengeOTP";
import IdentityPoolUserSidePanelResetPassword from "./IdentityPoolUserSidePanelResetPassword";
import IdentityPoolUserSidePanelVerifyAddress from "./IdentityPoolUserSidePanelVerifiyAddress";
import IdentityPoolUserSidePanelVerifyOTPChallengeDialog from "./IdentityPoolUserSidePanelVerifyOTPChallengeDialog";

const useStyles = makeStyles()(theme => ({
  accordionLabel: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
  },
  accordionLabelCounter: {
    backgroundColor: "#F0F7FF",
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  menuPaper: {
    minWidth: 165,
  },
  accordionFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  empty: {
    color: "gray",
    fontSize: 12,
  },
}));

interface Props {
  user: UserWithData | BaseUserWithData;
  workspaceId?: string;
  addressesAccordionOpen: boolean;
  setAddressesAccordionOpen: (isOpen: boolean) => void;
  poolId: string;
}

const IdentityPoolUserSidePanelAddresses = ({
  user,
  workspaceId,
  addressesAccordionOpen,
  setAddressesAccordionOpen,
  poolId,
}: Props) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const tenantId = getTenantId();

  const [moreMenu, setMoreMenu] = useState<{
    anchorEl: HTMLElement;
    address: UserVerifiableAddress;
  }>();
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState<UserVerifiableAddress>();
  const [deleteDialog, setDeleteDialog] = useState<UserVerifiableAddress>();
  const [verifyDialog, setVerifyDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();

  const handleDelete = (address: UserVerifiableAddress) => {
    setProgress(true);
    identityUsersApi
      .deleteUserVerifiableAddress({
        ipID: address.user_pool_id,
        userID: address.user_id,
        address: {
          address: address.address,
        },
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getUserQueryKey(tenantId, address.user_id) })
      )
      .then(() => queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, poolId) }))
      .then(() => setDeleteDialog(undefined))
      .then(() => notifySuccess("User address successfully removed"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to remove user address"))
      .finally(() => setProgress(false));
  };

  const checkPoolPermissionsQuery = useCheckPoolPermissions(user?.user_pool_id!, {
    enabled: !!user?.user_pool_id,
  });

  return (
    <>
      <AccordionPaper
        id="pool-user-addresses-accordion"
        label={
          <div className={classes.accordionLabel}>
            <span>Addresses</span>
            <Typography variant="textSM" component="div" className={classes.accordionLabelCounter}>
              {user.verifiable_addresses?.length ?? ""}
            </Typography>
          </div>
        }
        style={{ padding: 0, marginTop: 24, background: "none" }}
        expanded={addressesAccordionOpen}
        onChange={() => setAddressesAccordionOpen(!addressesAccordionOpen)}
      >
        {user.verifiable_addresses?.map(address => (
          <OutlinedInput
            id={`pool-user-address-${address.id}`}
            key={address.id}
            startAdornment={
              <InputAdornment position="start">
                {iconIdentifierMapper[address.type].icon}
              </InputAdornment>
            }
            endAdornment={
              <>
                {address.verified ? (
                  <Tooltip title="Verified address">
                    <VerifiedIcon color="primary" style={{ marginRight: 4 }} />
                  </Tooltip>
                ) : undefined}
                {!!checkPoolPermissionsQuery.data?.manage_user_addresses && (
                  <IconButton
                    aria-label="toggle menu visibility"
                    tabIndex={-1}
                    onClick={e => setMoreMenu({ address, anchorEl: e.currentTarget })}
                    id={`${address.id}-more-button`}
                    disabled={progress}
                  >
                    <MoreVertical size={16} />
                  </IconButton>
                )}
              </>
            }
            value={address.address}
            disabled
            fullWidth
            style={{ backgroundColor: "#FBFCFD", marginBottom: 16 }}
            inputProps={{
              style: { WebkitTextFillColor: theme.palette.secondary.light },
            }}
          />
        ))}

        <div className={classes.accordionFooter}>
          {!user.verifiable_addresses || !user.verifiable_addresses.length ? (
            <div className={classes.empty}>No Identifiers</div>
          ) : (
            <div />
          )}
          {!!checkPoolPermissionsQuery.data?.manage_user_addresses && (
            <Button id="add-user-address-button" onClick={() => setAddDialog(true)}>
              <Plus size={16} style={{ marginRight: 8 }} /> Add
            </Button>
          )}
        </div>
      </AccordionPaper>

      {moreMenu && (
        <Menu
          id="pool-user-side-panel-identifier-menu"
          anchorEl={moreMenu.anchorEl}
          keepMounted
          open={Boolean(moreMenu.anchorEl)}
          onClose={() => setMoreMenu(undefined)}
          classes={{ paper: classes.menuPaper }}
        >
          {!!checkPoolPermissionsQuery.data?.manage_user_addresses && (
            <MenuItem
              onClick={() => {
                setEditDialog(moreMenu.address);
                setMoreMenu(undefined);
              }}
            >
              Edit
            </MenuItem>
          )}

          {!!checkPoolPermissionsQuery.data?.manage_user_addresses && (
            <MenuItem
              onClick={() => {
                setDeleteDialog(moreMenu.address);
                setMoreMenu(undefined);
              }}
            >
              Delete
            </MenuItem>
          )}

          <IdentityPoolUserSidePanelResetPassword
            user={user}
            workspaceId={workspaceId}
            address={moreMenu.address.address}
            handleClose={() => setMoreMenu(undefined)}
          />

          {!moreMenu.address.verified &&
            !!checkPoolPermissionsQuery.data?.send_user_verification && (
              <IdentityPoolUserSidePanelVerifyAddress
                user={user}
                workspaceId={workspaceId}
                address={moreMenu.address.address}
              />
            )}

          {!!checkPoolPermissionsQuery.data?.manage_user_otps && (
            <IdentityPoolUserSidePanelChallengeOTP
              user={user}
              workspaceId={workspaceId}
              address={moreMenu.address.address}
              onVerifyDialogOpen={() => {
                setVerifyDialog(true);
                setMoreMenu(undefined);
              }}
            />
          )}
        </Menu>
      )}

      {verifyDialog && (
        <IdentityPoolUserSidePanelVerifyOTPChallengeDialog
          user={user}
          handleClose={() => {
            setVerifyDialog(false);
          }}
        />
      )}

      {addDialog && !!checkPoolPermissionsQuery.data?.manage_user_addresses && (
        <AddUserAddressDialog onClose={() => setAddDialog(false)} />
      )}

      {editDialog && (
        <EditUserAddressDialog address={editDialog} onClose={() => setEditDialog(undefined)} />
      )}

      {deleteDialog && (
        <ConfirmationDialog
          title="Delete Address"
          content={
            <>
              You're about to delete the <b>{deleteDialog.address}</b> address. This cannot be
              undone. Delete anyway?
            </>
          }
          confirmText="Delete"
          progress={progress}
          onCancel={() => setDeleteDialog(undefined)}
          onConfirm={() => handleDelete(deleteDialog)}
        />
      )}
    </>
  );
};

export default IdentityPoolUserSidePanelAddresses;
