import React, { CSSProperties } from "react";
import { Controller, RegisterOptions } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import FormInputLabel from "../../../common/components/FormInputLabel";
import { useFormContext } from "./Form";

export interface RadioGroupFieldProps {
  name: string;
  title?: string;
  helperText?: string;
  values: { value: string; key: string; label: string; disabled?: boolean }[];
  style?: CSSProperties;
  disabled?: boolean;
  row?: boolean;
  onChange?: (value: string) => void;
  rules?: Pick<RegisterOptions, "required" | "validate">;
}

function RadioGroupField({
  name,
  title,
  helperText,
  values,
  style,
  disabled,
  row = false,
  onChange,
  rules,
}: RadioGroupFieldProps) {
  const { form, disabled: formDisabled, id } = useFormContext();
  const inputId = `${id}-${name}`;

  return (
    <div style={{ marginBottom: 32, width: "100%", ...style }}>
      <Controller
        name={name}
        control={form.control}
        rules={rules}
        render={({ field, fieldState }) => (
          <FormControl component="fieldset">
            {title && (
              <FormInputLabel
                id={`${inputId}-label`}
                forId={`${inputId}-input`}
                label={title}
                optional={false}
              />
            )}
            <RadioGroup
              name={name}
              value={field.value}
              id={inputId}
              data-testid={inputId}
              onChange={e => {
                field.onChange(e.target.value);
                onChange && onChange(e.target.value);
              }}
              row={row}
            >
              {values.map(({ value, key, label, disabled: valueDisabled }) => (
                <FormControlLabel
                  value={value}
                  control={<Radio color="primary" />}
                  label={label}
                  key={key}
                  data-testid={`${inputId}-${key}`}
                  disabled={valueDisabled || formDisabled || disabled}
                />
              ))}
            </RadioGroup>

            <FormHelperText
              error={!!fieldState.error}
              id={`${inputId}-helper-text`}
              style={{ marginTop: 3 }}
            >
              {fieldState.error?.message || helperText}
            </FormHelperText>
          </FormControl>
        )}
      />
    </div>
  );
}

export default RadioGroupField;
