import React from "react";

import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { ChevronDown, ChevronRight, Eye } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { useFeature } from "../../../../common/utils/hooks/useFeature";

const useStyles = makeStyles()(theme => ({
  categoryName: {
    color: theme.palette.secondary.light,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  marginRight: {
    marginRight: 12,
  },
  itemButton: {
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  groupName: {
    color: theme.palette.secondary.light,
    fontWeight: 500,
  },
  itemContent: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginLeft: 8,
  },
  itemSelected: {
    position: "absolute",
    width: 4,
    height: 4,
    background: theme.palette.primary.main,
    borderRadius: "50%",
    left: 0,
  },
  fileName: {
    fontWeight: 400,
    padding: "4px 22px",
    color: theme.palette.secondary.light,
  },
  fileNameSelected: {
    color: theme.palette.primary.main,
  },
}));

export const Category = ({ children, unfolded, setUnfolded, name, path, id }) => {
  const { classes } = useStyles();

  return (
    <>
      <ListItemButton dense={true} onClick={() => setUnfolded(path)} style={{ height: 40 }}>
        {unfolded.includes(id) ? (
          <ChevronDown size={16} className={classes.marginRight} />
        ) : (
          <ChevronRight size={16} className={classes.marginRight} />
        )}
        <Typography className={classes.categoryName}>{name}</Typography>
      </ListItemButton>
      <Collapse in={unfolded.includes(id)}>
        <div style={{ marginLeft: 16 }}>{children}</div>
      </Collapse>
    </>
  );
};

export const Group = ({
  unfolded,
  onClick,
  onItemClick,
  name,
  id,
  paths,
  selectedPath,
  previewPath,
  hasUnsavedChanges,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <ListItemButton dense={true} onClick={() => onClick(id)} className={classes.itemButton}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {unfolded.includes(id) ? (
            <ChevronDown size={16} className={classes.marginRight} />
          ) : (
            <ChevronRight size={16} className={classes.marginRight} />
          )}
          <Typography className={classes.groupName}>{name}</Typography>
        </div>
        {("pages/" + previewPath).startsWith(id) && <Eye size={14} />}
      </ListItemButton>
      <Collapse in={unfolded.includes(id)}>
        <div>
          {paths
            .filter(path => path.startsWith(id))
            .map(path => (
              <Item
                key={path}
                fileName={path.replace(id, "")}
                path={path}
                onClick={onItemClick}
                selected={selectedPath === path}
                hasUnsavedChanges={selectedPath === path && hasUnsavedChanges}
              />
            ))}
        </div>
      </Collapse>
    </>
  );
};

export const Item = ({ fileName, path, selected, hasUnsavedChanges, onClick }) => {
  const { cx, classes } = useStyles();

  return (
    <div style={{ background: "#F7FAFF" }}>
      <ListItemButton dense={true} onClick={() => onClick(path)} style={{ height: 40 }}>
        <ListItemText>
          <div className={classes.itemContent}>
            {selected && <div className={classes.itemSelected} />}
            <Typography className={cx(classes.fileName, selected && classes.fileNameSelected)}>
              {fileName} {hasUnsavedChanges && "*"}
            </Typography>
          </div>
        </ListItemText>
      </ListItemButton>
    </div>
  );
};

export const pathToUnfoldedPath = path => {
  const s = path.split("/");
  return [s.slice(0, 2).join("/") + "/", s.slice(0, -1).join("/") + "/"];
};

interface Props {
  paths: string[];
  unfolded: string[];
  selectedPath: string;
  previewPath: string;
  hasUnsavedChanges: boolean;
  onEdit: (path: string) => void;
  onCategoryClick: (path: string[]) => void;
  onGroupClick: (path: string) => void;
}

export default function PagesList({
  paths = [],
  unfolded,
  selectedPath = "",
  previewPath = "",
  hasUnsavedChanges,
  onEdit,
  onCategoryClick,
  onGroupClick,
}: Props) {
  const isRAREnabled = useFeature("rar");

  return (
    <>
      <Category
        name="Authorization"
        path={["pages/authorization/"]}
        id="pages/authorization/"
        unfolded={unfolded}
        setUnfolded={onCategoryClick}
      >
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Consent Page"
          id="pages/authorization/consent/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        {isRAREnabled && (
          <Group
            onItemClick={onEdit}
            onClick={onGroupClick}
            unfolded={unfolded}
            name="RAR Consent Page"
            id="pages/authorization/consent-rar/"
            paths={paths}
            selectedPath={selectedPath}
            previewPath={previewPath}
            hasUnsavedChanges={hasUnsavedChanges}
          />
        )}
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Connect a Device"
          id="pages/authorization/device/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="CIBA Simulator"
          id="pages/authorization/ciba/simulator/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="2FA Selection"
          id="pages/authorization/otp/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Select IDP Page"
          id="pages/authorization/login/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Select Account Page"
          id="pages/authorization/select-account/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="RP-Initiated Logout User Prompt"
          id="pages/authorization/logout/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Demo Application"
          id="pages/authorization/demo/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="SAML Test Launch Screen"
          id="pages/authorization/saml/test/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
      </Category>
      <Category
        unfolded={unfolded}
        setUnfolded={onCategoryClick}
        name="Identity Pools"
        path={["pages/identity/"]}
        id="pages/identity/"
      >
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Account Activation"
          id="pages/identity/activate/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Activation Successful"
          id="pages/identity/activation_completed/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Address Verification Completed"
          id="pages/identity/address_verification_completed/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Login Page"
          id="pages/identity/login/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Registration"
          id="pages/identity/register/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Reset Credential Successful"
          id="pages/identity/reset_credential_confirmed/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Reset Password"
          id="pages/identity/reset_password/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Set Password"
          id="pages/identity/set_password/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Reset Passkey"
          id="pages/identity/reset_web_authn/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="Set Passkey"
          id="pages/identity/set_web_authn/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
      </Category>
      <Category
        unfolded={unfolded}
        setUnfolded={onCategoryClick}
        name="Error"
        path={["pages/error/"]}
        id="pages/error/"
      >
        <Group
          onItemClick={onEdit}
          onClick={onGroupClick}
          unfolded={unfolded}
          name="General Error Page"
          id="pages/error/"
          paths={paths}
          selectedPath={selectedPath}
          previewPath={previewPath}
          hasUnsavedChanges={hasUnsavedChanges}
        />
      </Category>
    </>
  );
}
