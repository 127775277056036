import React, { useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { Theme } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import Form, { useForm, useFormContext } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";
import adminThemesApi from "../../../services/adminThemesApi";
import { getThemeQueryKey, listThemesQueryKey } from "../../../services/adminThemesQuery";

interface Props {
  theme: Theme;
  onClose: () => void;
  onUpdated: (theme: Theme) => void;
}

export const handleThemeCreateEditError = msg => err => {
  const { form } = useFormContext();
  if (
    err.response?.status === 409 &&
    err.response?.data.error ===
      "failed to insert or update the resource because a resource with that value already exists"
  ) {
    form.setError(
      "name",
      {
        message: "Theme with given name already exists",
      },
      { shouldFocus: true }
    );
  } else {
    notifyErrorOrDefaultTo(msg)(err);
  }
};

export default function ThemeEdit({ theme, onClose, onUpdated }: Props) {
  const [progress, setProgress] = useState(false);

  const data = useMemo(() => theme, [theme]);

  const form = useForm({ id: "theme-edit", progress, initialValues: data });
  const queryClient = useQueryClient();

  const handleUpdate = data => {
    setProgress(true);
    adminThemesApi
      .updateTheme({
        themeID: theme.id || "",
        theme: {
          name: data.name.trim(),
          logo_url: data.logo_url.trim(),
          tenant_id: theme.tenant_id,
        },
      })
      .then(res =>
        Promise.all([
          Promise.resolve(res),
          queryClient.invalidateQueries({ queryKey: listThemesQueryKey(getTenantId()) }),
          queryClient.invalidateQueries({
            queryKey: getThemeQueryKey(getTenantId(), theme.id || ""),
          }),
        ])
      )
      .then(([res]) => {
        notifySuccess("Theme successfully updated");
        onUpdated(res.data);
      })
      .catch(handleThemeCreateEditError("Error occurred when trying to update theme"))
      .finally(() => setProgress(false));
  };

  return (
    <Dialog id="theme-edit-dialog" title="Theme Settings" onClose={onClose}>
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          autoFocus
          rules={{
            validate: {
              onlyAlphanumeric: validators.onlyAlphanumericWithWhitespaces({ label: "Name" }),
            },
          }}
        />

        <TextField
          name="logo_url"
          label="Theme Icon URL"
          rules={{
            validate: {
              validURL: validators.validURL({ label: "Theme Icon URL" }),
            },
          }}
          helperText="You can set up a theme icon URI, and it will appear in the themes list view to make it easier to identify theme."
        />

        <FormFooter onCancel={onClose} onSubmit={handleUpdate} submitText="Update" />
      </Form>
    </Dialog>
  );
}
