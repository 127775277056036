import React from "react";

import MenuItem from "@mui/material/MenuItem";
import { useQueryClient } from "@tanstack/react-query";

import {
  BaseUpdateUserStatusEnum,
  BaseUserWithData,
  UserWithData,
  UserWithDataStatusEnum,
} from "@cloudentity/acp-identity";

import adminB2BUsersApi from "../../../admin/services/adminB2BUsersApi";
import { useCheckPoolPermissions } from "../../../admin/services/adminIdentityPoolsQuery";
import {
  getB2BUserQueryKey,
  listUsersQueryKey,
} from "../../../admin/services/adminIdentityUsersQuery";
import { getTenantId } from "../../../common/api/paths";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";

interface Props {
  user?: UserWithData | BaseUserWithData;
  onClick: () => void;
}

export default function B2BManageUserActionsSetToActive({ user, onClick }: Props) {
  const queryClient = useQueryClient();

  const handleClick = () => {
    adminB2BUsersApi
      .updateB2BUser({
        ipID: user?.user_pool_id!,
        userID: user?.id!,
        updateUser: { ...user, status: BaseUpdateUserStatusEnum.Active },
      })
      .then(res => queryClient.setQueryData(getB2BUserQueryKey(getTenantId(), user?.id!), res.data))
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listUsersQueryKey(getTenantId(), user?.user_pool_id),
        })
      )
      .then(() => notifySuccess("User set to active"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update user"));
  };

  const checkPoolPermissionsQuery = useCheckPoolPermissions(user?.user_pool_id!, {
    enabled: !!user?.user_pool_id,
  });
  if (!checkPoolPermissionsQuery.data?.b2b_manage_users) {
    return null;
  }

  return user?.status === UserWithDataStatusEnum.Inactive ? (
    <MenuItem
      id="administrator-set-user-to-active-button"
      onClick={e => {
        e.stopPropagation();
        onClick();
        handleClick();
      }}
    >
      Set To Active
    </MenuItem>
  ) : null;
}
