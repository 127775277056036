import React, { ReactNode, useState } from "react";

import { getFromLocalStorage, setInLocalStorage } from "../utils/localStorage.utils";
import Alert from "./Alert";

interface Props {
  title: string;
  children: ReactNode;
  id: string;
}

export default function InfoBox({ title, children, id }: Props) {
  const [visible, setVisible] = useState(getFromLocalStorage(`info-box-${id}-hidden`) !== "true");

  const handleHide = () => {
    setVisible(false);
    setInLocalStorage(`info-box-${id}-hidden`, "true");
  };

  return visible ? (
    <Alert title={title} onClose={handleHide} severity="info" id={`info-box-${id}`}>
      {children}
    </Alert>
  ) : null;
}
