import React, { CSSProperties } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import logoMobile from "../../assets/images/logos/sa/sa_rgb_mark_wht.svg";
import logoDesktop from "../../assets/images/logos/sa/sa_rgb_wht.svg";
import { BRAND_NAME } from "../theme/theme";

interface Props {
  classes: any;
  logoUrl?: string | null;
  style?: CSSProperties;
}

const Logo = ({ classes, logoUrl = null, style = {} }: Props) => {
  const theme = useTheme();

  const matches = {
    downMd: useMediaQuery(theme.breakpoints.down("lg")),
    upMd: useMediaQuery(theme.breakpoints.up("lg")),
  };

  return (
    <>
      {logoUrl ? (
        <img id="logo" src={logoUrl} className={classes.logo} alt="Custom logo" style={style} />
      ) : (
        <>
          {matches.downMd && (
            <img src={logoMobile} alt={BRAND_NAME} className={classes.logo} style={style} />
          )}
          {matches.upMd && (
            <img src={logoDesktop} alt={BRAND_NAME} className={classes.logo} style={style} />
          )}
        </>
      )}
    </>
  );
};

export default Logo;
