import React, { useCallback, useEffect, useState } from "react";

import ViewStream from "@mui/icons-material/ViewStream";
import IconButton from "@mui/material/IconButton";
import debounce from "lodash/debounce";
import { makeStyles } from "tss-react/mui";

import Fab from "../../../../../common/components/Fab";
import AlignLeftIcon from "../../../../../common/components/icons/workspaces/AlignLeftIcon";
import { setInLocalStorage } from "../../../../../common/utils/localStorage.utils";
import SearchInput from "../../../common/SearchInput";
import { GridMode, GRID_MODE_KEY } from "./utils";

const useStyles = makeStyles<{ gridMode: GridMode }>()((theme, { gridMode }) => ({
  toolBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginBottom: gridMode === "full-card" ? 32 : 8,
  },
  button: {
    marginRight: 8,
    width: 40,
    height: 40,
  },
  icon: {
    fontSize: 24,
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
  fabButton: {
    marginLeft: 32,
  },
  searchContainer: {
    marginBottom: 4,
  },
}));

interface Props {
  gridMode?: GridMode;
  setGridMode?: (mode: GridMode) => void;
  onCreate?: () => void;
  searchText: string;
  onChangeSearchText: (searchText: string) => void;
}

function IdentityPoolsToolbar({
  gridMode = "full-card",
  setGridMode,
  onCreate,
  searchText: upperSearchText,
  onChangeSearchText,
}: Props) {
  const { cx, classes } = useStyles({ gridMode });
  const [searchText, setSearchText] = useState(upperSearchText ?? "");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = useCallback(
    debounce((searchText: string) => {
      if (onChangeSearchText) {
        onChangeSearchText(searchText);
      }
    }, 250),
    [onChangeSearchText]
  );

  const handleChangeSearchText = (newSearchText: string) => {
    setSearchText(newSearchText);
    handleUpdate(newSearchText);
  };

  const handleClearSearchText = () => {
    setSearchText("");
    handleUpdate("");
  };

  const handleGridModeChange = (mode: GridMode) => {
    if (!setGridMode) return;
    setGridMode(mode);
    setInLocalStorage(GRID_MODE_KEY, mode);
  };

  useEffect(() => {
    setSearchText(upperSearchText ?? "");
  }, [upperSearchText]);

  return (
    <div className={classes.toolBar}>
      <div className={classes.searchContainer}>
        <SearchInput
          value={searchText}
          placeholder="Search by identity pool name or ID"
          onSearch={handleChangeSearchText}
          onClear={handleClearSearchText}
        />
      </div>
      {setGridMode && (
        <div>
          <IconButton
            className={classes.button}
            onClick={() => handleGridModeChange("full-card")}
            id="grid-mode-button_full-card"
          >
            <ViewStream
              className={cx(classes.icon, gridMode === "full-card" && classes.activeIcon)}
            />
          </IconButton>
          <IconButton
            onClick={() => handleGridModeChange("table")}
            id="grid-mode-button_table"
            className={cx(classes.button, gridMode === "table" && classes.activeIcon)}
          >
            <AlignLeftIcon />
          </IconButton>

          {onCreate && (
            <Fab onClick={onCreate} id="create-new-pool-button" className={classes.fabButton}>
              + Create new
            </Fab>
          )}
        </div>
      )}
    </div>
  );
}

export default IdentityPoolsToolbar;
