import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";

import Link from "@mui/material/Link";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { ArrowUpRight } from "react-feather";

interface Props {
  id?: string;
  label?: string;
  value: string | ReactNode;
  customTooltipContent?: string | ReactNode;
  type?: "string" | "link" | "password";
  placement?: TooltipProps["placement"];
  typographyStyle?: CSSProperties;
  typographyProps?: TypographyProps;
}

const OverflowTooltip = ({
  id,
  label,
  value,
  customTooltipContent,
  type = "string",
  typographyStyle,
  typographyProps,
  placement = "top-end",
}: Props) => {
  const textElementRef = useRef<null | HTMLDivElement>(null);

  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    let compare = false;
    if (textElementRef && textElementRef.current) {
      compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    }

    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return () => window.removeEventListener("resize", compareSize);
  }, []);

  return (
    <Tooltip
      title={customTooltipContent ?? label ?? value ?? ""}
      disableHoverListener={!hoverStatus}
      placement={placement}
    >
      <Typography
        id={id}
        ref={textElementRef}
        style={{ overflow: "hidden", textOverflow: "ellipsis", ...typographyStyle }}
        {...typographyProps}
      >
        {type !== "link" && value}
        {type === "link" && (
          <span style={{ display: "flex", alignItems: "center" }}>
            <Link href={value as string} target="_blank" rel="noopener noreferrer">
              {label ?? value}
            </Link>
            <ArrowUpRight size="18" style={{ marginLeft: 4 }} />
          </span>
        )}
      </Typography>
    </Tooltip>
  );
};

export default OverflowTooltip;
