import React, { CSSProperties, ReactNode } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import DialogMUI, { DialogProps as DialogMUIProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { X } from "react-feather";
import { makeStyles } from "tss-react/mui";

type Size = "small" | "normal" | "large";

interface DialogProps extends Omit<DialogMUIProps, "title" | "children" | "open"> {
  size?: Size;
  title?: string | ReactNode;
  id: string;
  children: ReactNode;
  withCloseIcon?: boolean;
  onCancel?: () => void;
  open?: boolean;
  loading?: boolean;
  titleStyle?: CSSProperties;
}

const useStyles = makeStyles<{ size: Size }>()((theme, { size }) => ({
  dialogPaperRoot: {
    ...((size === "normal" && { minWidth: 584 }) ||
      (size === "large" && { minWidth: "80%" }) ||
      {}),
    position: "relative",
  },
  closeButton: {
    color: theme.palette.secondary.dark,
    zIndex: 1,
  },
  dialogTitleRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #e0e0e0",
  },
  progress: {
    width: "100%",
    textAlign: "center",
  },
}));

const Dialog = ({
  size = "normal",
  title,
  id,
  children,
  withCloseIcon,
  onCancel,
  onClose,
  open = true,
  loading = false,
  titleStyle,
  ...props
}: DialogProps) => {
  const { classes } = useStyles({ size });

  const closingIconButton =
    (withCloseIcon && onCancel && (
      <IconButton
        id="close-button"
        aria-label="close"
        className={classes.closeButton}
        onClick={onCancel}
        style={!title ? { position: "absolute", right: 16, top: 16 } : { marginRight: -12 }}
      >
        <X size={22} />
      </IconButton>
    )) ||
    null;

  return (
    <DialogMUI
      id={id}
      open={open}
      classes={{
        paper: classes.dialogPaperRoot,
      }}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      onClose={(e, reason) => {
        if (reason && reason === "backdropClick") {
          return;
        }

        onClose && onClose(e, reason);
      }}
      {...props}
    >
      {title && (
        <DialogTitle
          id={`${id}-title`}
          variant="h3"
          classes={{ root: classes.dialogTitleRoot }}
          style={titleStyle}
        >
          {title}
          {closingIconButton}
        </DialogTitle>
      )}
      <DialogContent id={`${id}-content`}>
        {!title && closingIconButton}
        {loading ? (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        ) : (
          children
        )}
      </DialogContent>
    </DialogMUI>
  );
};

export default Dialog;
