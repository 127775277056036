import {
  UserWorkspacesResponse,
  WorkspaceResponse,
  WorkspacesResponse,
} from "@cloudentity/acp-admin";

import { useCheckTenantPermissions } from "../../../services/adminPermissionsQuery";
import {
  listUserWorkspacesQueryKey,
  listWorkspacesQueryKey,
  useListUserWorkspaces,
  useListWorkspaces,
} from "../../../services/adminServersQuery";
import useItemsWithQuery from "./useItemsWithQuery";

export default function useWorkspacesSeqOrCursor({
  forceMode,
  workspaceType,
  themeId,
  ignoreUrlParams,
  template,
  forceLimit,
  enabled = true,
}: {
  forceMode?: "seq" | "cursor";
  workspaceType?: string;
  themeId?: string;
  ignoreUrlParams?: boolean;
  template?: boolean;
  forceLimit?: number;
  enabled?: boolean;
} = {}) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListWorkspaces =
    forceMode !== undefined
      ? forceMode === "seq"
      : checkTenantPermissionsQuery.data?.list_workspaces;

  const seqData = useItemsWithQuery<WorkspaceResponse, WorkspacesResponse>({
    id: "workspaces-seq",
    idGetter: pool => pool.id,
    getArray: (queryData, params) => {
      const data = queryData?.workspaces ?? [];
      return {
        data: data.slice(0, forceLimit ?? params.limit),
        nextPageAvailable: data.length > (forceLimit ?? params.limit),
      };
    },
    getTotalItemsQueryKey: params => [
      ...listWorkspacesQueryKey((forceLimit ?? params.limit) + 1),
      {
        template,
        workspaceTypes: workspaceType,
        themeId,
        searchPhrase: params.searchText || undefined,
        sort: params.sort,
        order: params.order,
      },
    ],
    useQueryFn: (page, params) =>
      useListWorkspaces(
        {
          template,
          workspaceTypes: workspaceType,
          themeId,
          searchPhrase: params.searchText || undefined,
          sort: params.sort,
          order: params.order,
          limit: (forceLimit ?? params.limit) + 1,
          beforeWorkspaceId: undefined,
          afterWorkspaceId: page.afterItemId,
        },
        { keepPreviousData: true, enabled: enabled && canListWorkspaces === true }
      ),
    initialSort: "name",
    enabled: enabled && canListWorkspaces === true,
    ignoreUrlParams,
  });

  const limit = 100;
  const cursorData = useItemsWithQuery<WorkspaceResponse, UserWorkspacesResponse>({
    id: "workspaces-cursor",
    idGetter: (_, queryData) => queryData.cursor,
    getArray: queryData => {
      const data = queryData?.workspaces ?? [];
      return {
        data: data.slice(0, limit),
        nextPageAvailable: data.length > limit,
      };
    },
    getTotalItemsQueryKey: () => listUserWorkspacesQueryKey(limit + 1),
    useQueryFn: page =>
      useListUserWorkspaces(
        {
          limit: limit + 1,
          cursor: page.afterItemId,
        },
        { keepPreviousData: true, enabled: enabled && canListWorkspaces === false }
      ),
    initialSort: "name",
    enabled: enabled && canListWorkspaces === false,
    ignoreUrlParams,
  });

  return canListWorkspaces ? seqData : cursorData;
}
