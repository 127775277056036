import React from "react";
import { useNavigate } from "react-router";

import Chip from "@mui/material/Chip";
import { AlertCircle } from "react-feather";
import { makeStyles } from "tss-react/mui";

import TooltipWithAction from "../../common/TooltipWithAction";
import { useWorkspace } from "../../common/useWorkspace";
import { useCommonStyles } from "./ClaimUtils";

const useStyles = makeStyles()(theme => ({
  content: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginLeft: 4,
    color: theme.palette.warning.main,
  },
}));

export default function ScopeChip({ hasWarning, scope }: { hasWarning: boolean; scope: string }) {
  const { cx, classes: componentClasses } = useCommonStyles();

  return (
    <Chip
      onClick={
        hasWarning
          ? e => {
              e.preventDefault();
              e.stopPropagation();
            }
          : undefined
      }
      label={hasWarning ? <ScopeWarningTooltip scope={scope} /> : <>{scope}</>}
      variant="outlined"
      className={cx(componentClasses.chip, hasWarning && componentClasses.warning)}
    />
  );
}

export function ScopeWarningTooltip({ scope }: { scope: string }) {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const [workspace] = useWorkspace();

  return (
    <TooltipWithAction
      title={
        <div>
          Scope is not available in the server. <br />
          Add the scope to the selected service.
        </div>
      }
      actionLabel="Go to Services"
      onAction={() => {
        navigate(`/${workspace}/services`);
      }}
    >
      <div className={classes.content}>
        {scope}
        <AlertCircle size={16} className={classes.icon} />
      </div>
    </TooltipWithAction>
  );
}
