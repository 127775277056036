import React from "react";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Check } from "react-feather";
import { makeStyles } from "tss-react/mui";

interface Props {
  step: number;
  title: string;
  selected?: boolean;
  visited?: boolean;
  id?: string;
}

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    marginBottom: 16,
    padding: "16px 12px",
    borderRadius: 4,
  },
  rootSelected: {
    background: theme.palette.primary.main,
    color: "white",
  },
  avatar: {
    width: 24,
    height: 24,
    marginRight: 14,
    color: "white",
    background: "black",
  },
  avatarSelected: {
    background: "white",
    color: theme.palette.primary.main,
  },
  avatarNotVisited: {
    background: "#10B981",
  },
}));

function WorkspaceDirectoryStepCard({ step, title, selected, visited, id }: Props) {
  const { cx, classes } = useStyles();

  return (
    <div className={cx([classes.root, selected && classes.rootSelected])}>
      <Avatar
        className={cx([
          classes.avatar,
          !visited && classes.avatarNotVisited,
          selected && classes.avatarSelected,
        ])}
      >
        {!visited ? <Check size={14} strokeWidth={3} /> : step}
      </Avatar>
      <Typography id={id} variant="body2">
        {title}
      </Typography>
    </div>
  );
}

export default WorkspaceDirectoryStepCard;
