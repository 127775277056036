import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { makeStyles } from "tss-react/mui";

import {
  GrantWorkspaceRoleRequestRoleEnum,
  GrantWorkspaceRoleRequestTypeEnum,
  IDPDiscoveryDiscoveryModeEnum,
  IDPsResponse,
  OrganizationResponse,
  OrganizationResponseAuthenticationMechanismsEnum,
  Server,
  ServerProfileEnum,
  ServerResponse,
  ServerResponseAuthenticationMechanismsEnum,
  ServerResponseProfileEnum,
  Styling,
  UpdateClientAdminRequest,
} from "@cloudentity/acp-admin";
import {
  NewUserIdentifierTypeEnum,
  NewUserPayloadStatusEnum,
  NewUserVerifiableAddressStatusEnum,
  NewUserVerifiableAddressTypeEnum,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import { getStylingQueryKey } from "../../../../common/api/stylingQuery";
import {
  handleErrorWithNotify,
  notifyErrorOrDefaultTo,
} from "../../../../common/components/notifications/notificationService";
import { setInLocalStorage } from "../../../../common/utils/localStorage.utils";
import adminClaimsApi from "../../../services/adminClaimsApi";
import adminClientsApi from "../../../services/adminClientsApi";
import adminIDPsApi from "../../../services/adminIDPsApi";
import {
  setDefaultAuthenticationMechanismsIfAvailableForWorkspace,
  useCreateWorkspaceIdentityPoolMutation,
} from "../../../services/adminIdentityPoolsMutations";
import { listWorkspacePoolsQueryKey } from "../../../services/adminIdentityPoolsQuery";
import adminIdentityUsersApi from "../../../services/adminIdentityUsersApi";
import adminOrganizationsApi from "../../../services/adminOrganizationsApi";
import { getCheckWorkspacePermissionsQueryKey } from "../../../services/adminPermissionsQuery";
import adminRolesApi from "../../../services/adminRolesApi";
import { getExecutionPointsQueryKey } from "../../../services/adminScriptsQuery";
import adminServersApi from "../../../services/adminServersApi";
import { listWorkspacesQueryKey } from "../../../services/adminServersQuery";
import adminTenantsApi from "../../../services/adminTenantsApi";
import { getTenantQueryKey, useGetTenant } from "../../../services/adminTenantsQuery";
import { IDPS_LIST_VIEW_MODE_KEY } from "../../identities/identities_view_tabbed/identitiesListSimple/utils";
import { initialColors } from "../../workspaceDirectory/WorkspacesColorInput";
import { getServerDefaultColorByProfile } from "../../workspaceDirectory/server-profiles";
import ColorScheme from "./ColorScheme";
import SetupBrandingPreview from "./SetupBrandingPreview";
import SetupLogo from "./SetupLogo";

const INITIATED_ORGANIZATION_NAME = "Sample Company";
export const INITIATED_ORGANIZATION_ID = "sample_company";

const useStyles = makeStyles()(theme => ({
  grid: {
    background: "#fff",
    padding: 32,
    overflowY: "auto",
    height: "100%",
  },
  subtitle: {
    fontSize: 24,
    fontWeight: 400,
    color: theme.palette.secondary.dark,
    lineHeight: "22px",
    margin: "10px 0",
  },
}));

export interface TenantMetadataRegistration {
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  zone_id: string;
}

export default function SetupBranding() {
  const { classes } = useStyles();

  const [progress, setProgress] = useState(false);
  const navigate = useNavigate();

  const tenantQuery = useGetTenant(getTenantId());

  const [branding, setBranding] = useState<{
    logoUrl: string;
    primaryColor: string;
    headerColor: string;
    palette: string[];
  }>({ logoUrl: "", primaryColor: "", headerColor: "", palette: [] });

  const companyName =
    (tenantQuery.data?.metadata?.registration as TenantMetadataRegistration)?.company_name || "";

  const handleBrandingChange = useCallback(
    b => setBranding(branding => ({ ...branding, ...b })),
    []
  );

  const getTenantQuery = useGetTenant(getTenantId());
  const queryClient = useQueryClient();

  const updateTenant = useCallback(
    (styling?: Styling) => {
      if (styling) {
        return adminTenantsApi
          .updateTenant({
            tenant: {
              ...getTenantQuery.data,
              metadata: {
                ...getTenantQuery.data?.metadata,
                should_visit_get_started: false,
              } as any,
              styling,
            },
          })
          .then(res =>
            queryClient.setQueryData(getTenantQueryKey(getTenantQuery.data?.id), res.data)
          )
          .then(() => queryClient.invalidateQueries({ queryKey: getTenantQueryKey(getTenantId()) }))
          .then(() => queryClient.invalidateQueries({ queryKey: getStylingQueryKey() }))
          .catch(handleErrorWithNotify("Error occurred while trying to update tenant"));
      }

      return Promise.resolve();
    },
    [getTenantQuery.data, queryClient]
  );

  const registrationMetadata = tenantQuery.data?.metadata
    ?.registration as TenantMetadataRegistration;

  const createWorkspaceIdentityPoolMutation = useCreateWorkspaceIdentityPoolMutation();

  const handleContinueClick = useCallback(() => {
    setProgress(true);

    let serverRes: ServerResponse;
    let orgRes: OrganizationResponse | undefined;
    let idpsRes: IDPsResponse;

    adminServersApi
      .createServer({
        server: {
          name: registrationMetadata?.company_name,
          profile: ServerProfileEnum.Consumer,
          tenant_id: getTenantId(),
          idp_discovery: {
            enabled: true,
            discovery_mode: IDPDiscoveryDiscoveryModeEnum.DomainMatching,
          },
          sso: {
            enabled: true,
          },
          color: getServerDefaultColorByProfile(ServerResponseProfileEnum.Consumer),
        },
        withDemoClient: true,
      })
      .then(res => {
        serverRes = res.data;
        return queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() });
      })
      .then(() => {
        const pool = {
          name: "Users",
          tenant_id: serverRes?.tenant_id,
          public_registration_allowed: true,
          payload_schema_id: "organizations_pool_default_payload",
          metadata_schema_id: "organizations_pool_default_metadata",
          identifier_case_insensitive: true,
        };

        setDefaultAuthenticationMechanismsIfAvailableForWorkspace({
          pool,
          supportedAuthenticationMechanisms: serverRes.authentication_mechanisms || [],
          authenticationMechanism: ServerResponseAuthenticationMechanismsEnum.Password,
          setAuthenticationMechanismsAsPreferred: true,
        });

        return createWorkspaceIdentityPoolMutation.mutateAsync({
          wid: serverRes?.id || "",
          withIdp: true,
          pool,
        });
      })
      .then(() => adminClientsApi.getClient({ cid: serverRes?.id + "-demo" }))
      .then(resDemoClient =>
        adminClientsApi.updateClient({
          cid: resDemoClient.data?.client_id || "",
          client: { ...resDemoClient.data, trusted: true } as unknown as UpdateClientAdminRequest,
        })
      )
      .then(() =>
        adminOrganizationsApi
          .createOrganization({
            org: {
              parent_id: serverRes?.id,
              id: INITIATED_ORGANIZATION_ID.toLocaleLowerCase(),
              name: INITIATED_ORGANIZATION_NAME,
              color: initialColors[Math.floor(Math.random() * initialColors.length)],
            },
          })
          .catch(err => {
            if (err?.response?.status === 409) {
              return adminOrganizationsApi.createOrganization({
                org: {
                  parent_id: serverRes?.id,
                  id: `${INITIATED_ORGANIZATION_ID.toLocaleLowerCase()}-${nanoid(
                    10
                  ).toLocaleLowerCase()}`,
                  name: INITIATED_ORGANIZATION_NAME,
                  color: initialColors[Math.floor(Math.random() * initialColors.length)],
                },
              });
            }
          })
      )
      .then(res => {
        orgRes = res?.data;

        const pool = {
          name: "Users",
          tenant_id: serverRes?.tenant_id,
          payload_schema_id: "organizations_pool_default_payload",
          metadata_schema_id: "organizations_pool_default_metadata",
          identifier_case_insensitive: true,
        };

        setDefaultAuthenticationMechanismsIfAvailableForWorkspace({
          pool,
          supportedAuthenticationMechanisms: orgRes?.authentication_mechanisms || [],
          authenticationMechanism: OrganizationResponseAuthenticationMechanismsEnum.Otp,
          setAuthenticationMechanismsAsPreferred: true,
        });

        return createWorkspaceIdentityPoolMutation.mutateAsync({
          wid: orgRes?.id || "",
          withIdp: true,
          pool,
        });
      })
      .then(poolRes =>
        adminIdentityUsersApi.createUser({
          ipID: poolRes.data?.id || "",
          newUser: {
            status: NewUserPayloadStatusEnum.Active,
            identifiers: [
              { identifier: registrationMetadata?.email, type: NewUserIdentifierTypeEnum.Email },
            ],
            verifiable_addresses: [
              {
                address: registrationMetadata?.email,
                verified: true,
                status: NewUserVerifiableAddressStatusEnum.Active,
                type: NewUserVerifiableAddressTypeEnum.Email,
              },
            ],
            payload: {
              first_name: (registrationMetadata?.first_name || "") as unknown as object,
              last_name: (registrationMetadata?.last_name || "") as unknown as object,
            },
          },
        })
      )
      .then(resUser =>
        adminRolesApi.grantWorkspaceRole({
          wid: orgRes?.id || "",
          request: {
            role: GrantWorkspaceRoleRequestRoleEnum.Manager,
            type: GrantWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
            tenant_id: serverRes?.tenant_id,
            identity_pool_id: resUser.data?.user_pool_id,
            identity_pool_user_id: resUser.data?.id,
          },
        })
      )
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getCheckWorkspacePermissionsQueryKey(orgRes?.id!),
        })
      )
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getExecutionPointsQueryKey(serverRes?.tenant_id, serverRes?.id!),
        })
      )
      .then(() =>
        adminServersApi.updateAuthorizationServer({
          wid: serverRes?.id || "",
          server: {
            ...serverRes,
            metadata: {
              payload: {
                organization_id: orgRes?.id,
                organization_name: orgRes?.name,
              } as unknown as { [key: string]: object },
              schema: {
                properties: {
                  organization_id: {
                    description: "Organization ID",
                    type: "string",
                  },
                  organization_name: {
                    description: "Organization Name",
                    type: "string",
                  },
                },
                type: "object",
              },
            },
          } as Server,
        })
      )
      .then(() =>
        adminClaimsApi.createClaim({
          claim: {
            authorization_server_id: serverRes?.id || "",
            source_type: "workspace",
            source_path: "metadata.organization_id",
            type: "access_token",
            name: "organization_id",
            scopes: [],
          },
        })
      )
      .then(() =>
        adminClaimsApi.createClaim({
          claim: {
            authorization_server_id: serverRes?.id || "",
            source_type: "workspace",
            source_path: "metadata.organization_name",
            type: "access_token",
            name: "organization_name",
            scopes: [],
          },
        })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listWorkspacePoolsQueryKey(orgRes?.id || "") })
      )
      .then(() => adminIDPsApi.getIDPs({ wid: serverRes?.id || "" }))
      .then(resIDPs => {
        idpsRes = resIDPs.data;
        return adminIDPsApi.getIDP({
          wid: serverRes?.id,
          type: "identity_pool",
          iid: (resIDPs.data?.idps || []).find(idp => idp.name === "Users")?.id || "",
        });
      })
      .then(resIDP =>
        adminIDPsApi.updateIDP({
          aid: resIDP.data?.authorization_server_id,
          type: "identity_pool",
          iid: resIDP.data?.id,
          body: {
            ...resIDP.data,
            hidden: true,
            discovery_settings: {
              ...resIDP.data?.discovery_settings,
              fallback_provider: true,
              instant_redirect: true,
              identifier_based_matching: true,
            },
          },
        })
      )
      .then(() => {
        return adminIDPsApi.getIDP({
          wid: serverRes?.id,
          type: "organization",
          iid:
            (idpsRes?.idps || []).find(idp => idp.name === INITIATED_ORGANIZATION_NAME)?.id || "",
        });
      })
      .then(resIDP =>
        adminIDPsApi.updateIDP({
          aid: resIDP.data?.authorization_server_id,
          type: "organization",
          iid: resIDP.data?.id,
          body: {
            ...resIDP.data,
            discovery_settings: { ...resIDP.data.discovery_settings, instant_redirect: true },
          },
        })
      )
      .then(() => {
        setInLocalStorage(IDPS_LIST_VIEW_MODE_KEY, "simple");
      })
      .then(() =>
        updateTenant({
          ...getTenantQuery.data?.styling,
          logo_url: branding?.logoUrl || "",
          colors: { header: branding?.headerColor, primary: branding?.primaryColor },
        })
      )
      .then(() => navigate(`/${serverRes?.id}/authentication/identities`))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to create workspace"))
      .finally(() => setProgress(false));
  }, [
    updateTenant,
    navigate,
    branding,
    getTenantQuery.data,
    queryClient,
    registrationMetadata,
    createWorkspaceIdentityPoolMutation,
  ]);

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item xs={4} className={classes.grid}>
        <div style={{ marginTop: 48 }}>
          <div>Your sign-in screen is almost ready</div>
          <Typography className={classes.subtitle}>Adjust appearance</Typography>
          <SetupLogo
            companyName={companyName}
            style={{ marginTop: 42 }}
            onBrandingChange={handleBrandingChange}
          />
          <Divider style={{ marginTop: 32, marginBottom: 32 }} />

          <ColorScheme
            primary={branding?.primaryColor || branding?.palette?.at(0) || ""}
            header={branding?.headerColor || branding?.palette?.at(1) || ""}
            onChangePrimary={primaryColor => setBranding(b => ({ ...b, primaryColor }))}
            onChangeHeader={headerColor => setBranding(b => ({ ...b, headerColor }))}
            colors={branding?.palette}
            style={{ marginTop: 32 }}
          />
          <Divider style={{ marginTop: 32, marginBottom: 32 }} />
          <div style={{ textAlign: "right" }}>
            <Button
              variant="text"
              onClick={() => navigate("/get-started")}
              disabled={progress}
              id="backButton"
            >
              Back
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              style={{ marginLeft: 8 }}
              onClick={handleContinueClick}
              loading={progress}
              id="confirmButton"
            >
              Continue
            </LoadingButton>
          </div>
        </div>
      </Grid>
      <Grid item xs={8}>
        <SetupBrandingPreview branding={branding} companyName={INITIATED_ORGANIZATION_NAME} />
      </Grid>
    </Grid>
  );
}
