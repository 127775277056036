import {
  MfaMethodsApi,
  MfaMethodsApiCreateMFAMethodRequest,
  MfaMethodsApiGetMFAMethodRequest,
  MfaMethodsApiListMFAMethodsRequest,
  MfaMethodsApiTestMFAMethodRequest,
  MfaMethodsApiUpdateMFAMethodRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => MfaMethodsApi = adminApiClazz(MfaMethodsApi);

const adminMfaMethodsApi = {
  createMfaMethod: (req: MfaMethodsApiCreateMFAMethodRequest) => api().createMFAMethod(req),
  getMfaMethods: (req: MfaMethodsApiListMFAMethodsRequest) => api().listMFAMethods(req),
  updateMfaMethod: (req: MfaMethodsApiUpdateMFAMethodRequest) => api().updateMFAMethod(req),
  testMFAMethod: (req: MfaMethodsApiTestMFAMethodRequest) => api().testMFAMethod(req),
  getMfaMethod: (req: MfaMethodsApiGetMFAMethodRequest) => api().getMFAMethod(req),
};

export default adminMfaMethodsApi;
