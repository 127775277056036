import {
  PoliciesApi,
  PoliciesApiCreatePolicyRequest,
  PoliciesApiDeletePolicyRequest,
  PoliciesApiGetPolicyRequest,
  PoliciesApiListPoliciesRequest,
  PoliciesApiListPolicyExecutionPointsRequest,
  PoliciesApiSetPolicyExecutionPointsRequest,
  PoliciesApiTestPolicyRequest,
  PoliciesApiUpdatePolicyRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => PoliciesApi = adminApiClazz(PoliciesApi);

const adminPoliciesApi = {
  getPolicies: (req: PoliciesApiListPoliciesRequest) => api().listPolicies(req),
  getPolicy: (req: PoliciesApiGetPolicyRequest) => api().getPolicy(req),
  savePolicy: (req: PoliciesApiUpdatePolicyRequest) => api().updatePolicy(req),
  removePolicy: (req: PoliciesApiDeletePolicyRequest) => api().deletePolicy(req),
  createPolicy: (req: PoliciesApiCreatePolicyRequest) => api().createPolicy(req),
  testPolicy: (req: PoliciesApiTestPolicyRequest) => api().testPolicy(req),
  getPolicyExecutionPoints: (req: PoliciesApiListPolicyExecutionPointsRequest) =>
    api().listPolicyExecutionPoints(req),
  updatePolicyExecutionPoints: (req: PoliciesApiSetPolicyExecutionPointsRequest) =>
    api().setPolicyExecutionPoints(req),
};

export default adminPoliciesApi;
