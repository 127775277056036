import React from "react";

import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";

import { WorkspaceResponseTypeEnum } from "@cloudentity/acp-admin";

import OrganizationsHierarchy from "../admin/components/workspaceDirectory/organizations/OrganizationsHierarchy";
import Progress from "../common/components/Progress";
import B2BOrganizationsEmptyState from "./B2BOrganizationsEmptyState";
import B2BOrganizationsToolbar from "./B2BOrganizationsToolbar";
import { useAllWorkspaces } from "./useAllWorkspaces";

export default function B2BOrganizationsHierarchy({
  onCreate,
  onSetGridMode,
  onMenuOpen,
  onSelectServer,
}) {
  const listAllWorkspacesQuery = useAllWorkspaces();
  const allWorkspaces = uniqBy(
    flatten((listAllWorkspacesQuery.data?.pages || []).map(page => page.workspaces || [])),
    w => w.id
  );
  const workspacesWithChildOrganizations = allWorkspaces
    .filter(w => w.type !== WorkspaceResponseTypeEnum.Organization)
    .filter(w => (w.number_of_child_organizations ?? 0) > 0)
    .filter(w => !w.template);

  if (
    listAllWorkspacesQuery.isLoading ||
    listAllWorkspacesQuery.hasNextPage ||
    listAllWorkspacesQuery.isFetchingNextPage
  ) {
    return <Progress />;
  }

  return (
    <OrganizationsHierarchy
      toolbar={
        <B2BOrganizationsToolbar
          searchText=""
          gridMode="hierarchy"
          onCreate={onCreate}
          onUpdate={undefined}
          onSetGridMode={onSetGridMode}
        />
      }
      emptyState={<B2BOrganizationsEmptyState onCreate={onCreate} />}
      onSelectServer={onSelectServer}
      onMenuOpen={onMenuOpen}
      additionalRootWorkspaces={workspacesWithChildOrganizations}
    />
  );
}
