import React from "react";

import WorkspaceDirectoryStepCard from "./WorkspaceDirectoryStepCard";

export default function WorkspaceDirectoryCreateDeveloperPortalSidePanel({ stepName, stepIndex }) {
  return (
    <div
      style={{
        width: 240,
        minWidth: 240,
        height: "100%",
        background: "#fff",
        padding: 12,
        borderRight: "1px solid #ECECEC",
      }}
    >
      <WorkspaceDirectoryStepCard
        step={1}
        title="Create developer portal"
        selected={stepName === "createDeveloperPortal"}
        visited={stepIndex < 4}
      />
      <WorkspaceDirectoryStepCard
        step={2}
        title="Connect developer pool"
        selected={stepName === "connectDeveloperPool"}
        visited={stepIndex < 5}
      />
    </div>
  );
}
