import React from "react";

import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import PageHeader from "../common/PageHeader";
import AuthNContextView from "./AuthNContextView";

export default function AuthNContext() {
  return (
    <>
      <PageContainer>
        <PageHeader title="Authentication Context" />
        <PageContent>
          <AuthNContextView />
        </PageContent>
      </PageContainer>
    </>
  );
}
