import React from "react";

import RouterLink from "../../../../common/components/RouterLink";
import { useWorkspace } from "../../common/useWorkspace";
import DocLink from "./DocLink";

export default function QuickstartArticleOIDC() {
  const [workspace] = useWorkspace();

  return (
    <>
      <p>
        SecureAuth provides comprehensive support for configuring outgoing claims in OIDC tokens and
        SAML assertions, ensuring secure and customized data transmission between identity providers
        (IDPs) and client applications.
      </p>
      <p>
        Claims in OIDC and attributes in SAML assertions represent pieces of information about the
        user or authentication context. These claims and assertions convey necessary details that
        applications can rely on for authorization decisions, personalization, or other use cases.
      </p>

      <p>
        <b>Configuring OIDC Claims</b>
      </p>
      <p>
        OIDC claims can be added to Access Tokens and ID Tokens. Here's how you can configure OIDC
        claims:
      </p>
      <ol>
        <li>
          Click <b>Show all</b> in the left navigation to see advanced views.
        </li>
        <li>
          Go to{" "}
          <RouterLink to={`/${workspace}/workspaces/tokens/claims`} target="_blank" withIcon>
            <b>OAuth &gt; Claims</b>
          </RouterLink>{" "}
          in the SecureAuth admin console.
        </li>
        <li>
          Select the appropriate type: <b>Access Tokens</b> or <b>ID Tokens</b>.
        </li>
        <li>
          Click <b>+ ADD CLAIM</b> to open the claim configuration dialog.
        </li>
        <li>
          <b>Enter Claim Details</b>: Provide a name, optional description, and specify the{" "}
          <b>Source Type</b>
          (such as AuthN Context, Risk Context, Client, etc.).
        </li>
        <li>
          <b>Select Source Path</b>: Choose the path within the source, such as Subject, Email,
          Phone, etc.
        </li>
        <li>
          <b>Scopes and User Info</b>: Specify if the claim should be included in certain scopes or
          limited to user info.
        </li>
        <li>
          Click <b>Add</b> to save the claim.
        </li>
      </ol>
      <p>
        This configuration allows admins to control which claims appear in the tokens used by
        applications.
      </p>

      <p>
        <b>Configuring SAML Assertions</b>
      </p>
      <p>To configure attributes in SAML assertions, follow these steps:</p>
      <ol>
        <li>
          Click <b>Show all</b> in the left navigation to see advanced views.
        </li>
        <li>
          Go to{" "}
          <RouterLink to={`/${workspace}/workspaces/tokens/claims`} target="_blank" withIcon>
            <b>OAuth &gt; Claims</b>
          </RouterLink>{" "}
          <b>&gt; SAML Assertion Attributes</b> in the SecureAuth admin console.
        </li>
        <li>
          Click <b>+ ADD CLAIM</b>.
        </li>
        <li>
          Fill in the claim's name, description, and <b>Source Type</b>.
        </li>
        <li>
          Select the source path (e.g., Subject, Email), and specify the SAML Name and Name Format.
        </li>
        <li>
          Once the claim is configured, click <b>Add</b> to save.
        </li>
      </ol>
      <p>This configuration determines the data conveyed in SAML responses.</p>

      <p>
        <b>Adding Extensions for Custom Claims</b>
      </p>
      <p>
        Extensions allow you to define custom logic for claims using JavaScript, supporting various
        NodeJS versions. Here's how to create an extension for custom claims:
      </p>
      <ol>
        <li>
          Click <b>Show all</b> in the left navigation to see advanced views.
        </li>
        <li>
          Go to{" "}
          <RouterLink to={`/${workspace}/workspaces/tokens/claims`} target="_blank" withIcon>
            <b>OAuth &gt; Claims</b>
          </RouterLink>{" "}
          <b>&gt; Custom Claims &gt; Manage Scripts</b> in the SecureAuth admin console.
        </li>
        <li>
          Click <b>+ CREATE NEW</b>.
        </li>
        <li>
          Select the <b>NodeJS Runtime </b>version (e.g., v20).
        </li>
        <li>
          Provide a name for the extension, create custom claim logic as needed and click Save.
        </li>
        <li>
          Under Custom Claims, you can select your script for <b>Pre-token Minting Extensions</b>,
          enabling you to enrich tokens with custom claims or modify authorization context
          attributes.
        </li>
      </ol>
      <br />
      <p>
        Learn more by visiting{" "}
        <DocLink href="https://docs.secureauth.com/cloudentity/en/adding-outgoing-token-claims.html">
          Adding Outgoing Token Claims
        </DocLink>
        .
      </p>
    </>
  );
}
