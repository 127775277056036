import React, { useEffect, useRef } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "tss-react/mui";

import {
  OrganizationResponse,
  OrganizationsResponse,
  WorkspaceResponse,
} from "@cloudentity/acp-admin";

import {
  listOrganizationsQueryKey,
  useListOrganizations,
} from "../../../services/adminOrganizationsQuery";
import useItemsWithQuery from "../../common/EnhancedTableAsync/useItemsWithQuery";
import PageContent from "../../common/PageContent";
import OrganizationCard from "./OrganizationCard";
import StyledTreeItem from "./OrganizationsHierarchyTreeItem";

const useStyles = makeStyles()(() => ({
  card: {
    width: 320,
  },
  button: {
    margin: "8px 0 8px 32px",
  },
}));

interface Props {
  toolbar?: React.JSX.Element;
  emptyState: React.JSX.Element;
  expanded: string[];
  onExpand: (organizationId: string, opened: boolean) => void;
  organization: Pick<OrganizationResponse, "id" | "number_of_child_organizations" | "name">;
  isRoot: boolean;
  selected?: string;
  onSelectServer: (id: string) => void;
  onMenuOpen?: (props: {
    anchorEl: HTMLElement;
    server: OrganizationResponse;
    rootServer?: OrganizationResponse | WorkspaceResponse;
  }) => void;
  onLoad: (organizationId: string, opened: boolean) => void;
  additionalRootWorkspaces?: WorkspaceResponse[];
  isWorkspace?: boolean;
  showRootCard?: boolean;
  hideHierarchyBelowId?: { id: string; reason: string };
  allowSelectRoot?: boolean;
  greyOutNodeWithId?: { id: string; reason: string };
}

export default function OrganizationsHierarchyNode({
  toolbar,
  emptyState,
  expanded,
  onExpand,
  organization,
  isRoot,
  selected,
  onSelectServer,
  onMenuOpen,
  onLoad,
  additionalRootWorkspaces,
  isWorkspace,
  showRootCard,
  hideHierarchyBelowId,
  allowSelectRoot,
  greyOutNodeWithId,
}: Props) {
  const { classes } = useStyles();
  const limit = 99;
  const opened = expanded.includes(organization?.id ?? "");

  const data = useItemsWithQuery<OrganizationResponse, OrganizationsResponse>({
    id: `organizations-tree-${organization.id}`,
    idGetter: organization => organization.id,
    getArray: queryData => {
      const data = queryData?.organizations ?? [];
      return {
        data: data.slice(0, limit),
        nextPageAvailable: data.length > limit,
      };
    },
    getTotalItemsQueryKey: params => [
      ...listOrganizationsQueryKey(organization.id, limit + 1),
      {
        sort: params.sort,
        order: params.order,
        template: false,
      },
    ],
    useQueryFn: (page, params) =>
      useListOrganizations(
        {
          sort: params.sort,
          order: params.order,
          limit: limit + 1,
          beforeOrganizationId: undefined,
          afterOrganizationId: page.afterItemId,
          parentId: organization.id,
          template: false,
        },
        { keepPreviousData: true, enabled: opened }
      ),
    initialSort: "name",
    keepPreviousTotalData: true,
    enabled: opened,
  });

  const children = (
    <>
      {isRoot &&
        additionalRootWorkspaces?.map(workspace => (
          <OrganizationsHierarchyNode
            toolbar={toolbar}
            emptyState={emptyState}
            expanded={expanded}
            onExpand={onExpand}
            key={workspace.id}
            organization={workspace}
            isRoot={false}
            selected={selected}
            onSelectServer={onSelectServer}
            onMenuOpen={
              onMenuOpen ? props => onMenuOpen({ ...props, rootServer: workspace }) : undefined
            }
            onLoad={onLoad}
            isWorkspace
            hideHierarchyBelowId={hideHierarchyBelowId}
            allowSelectRoot={allowSelectRoot}
            greyOutNodeWithId={greyOutNodeWithId}
          />
        ))}
      {data.totalData.map(organization => (
        <OrganizationsHierarchyNode
          toolbar={toolbar}
          emptyState={emptyState}
          expanded={expanded}
          onExpand={onExpand}
          key={organization.id}
          organization={organization}
          isRoot={false}
          selected={selected}
          onSelectServer={onSelectServer}
          onMenuOpen={
            onMenuOpen ? props => onMenuOpen({ ...props, rootServer: organization }) : undefined
          }
          onLoad={onLoad}
          hideHierarchyBelowId={hideHierarchyBelowId}
          allowSelectRoot={allowSelectRoot}
          greyOutNodeWithId={greyOutNodeWithId}
        />
      ))}
      {data.nextPageAvailable && (
        <LoadingButton
          id="load-more-button"
          variant="outlined"
          onClick={() => data.onLastPage()}
          loading={data.isFetching}
          className={classes.button}
        >
          Load more
        </LoadingButton>
      )}
    </>
  );

  const numberOfChildren =
    (organization.number_of_child_organizations ?? 0) +
    (isRoot ? additionalRootWorkspaces?.length ?? 0 : 0);
  const prevNumberOfChildren = useRef(numberOfChildren);

  const loading = opened && data.firstPageLoading;
  const canExpand =
    numberOfChildren > 0 &&
    !loading &&
    (hideHierarchyBelowId ? hideHierarchyBelowId?.id !== organization.id : true);

  const node = (
    <StyledTreeItem
      id={`tree-item-${organization.id ?? "root"}`}
      aria-label={`Tree item ${organization.name ?? organization.id}`}
      data-testid={`tree-item-${organization.id ?? "root"}`}
      nodeId={organization.id ?? "root"}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        canExpand && onExpand(organization.id ?? "", !opened);
      }}
      label={
        <div className={classes.card}>
          <OrganizationCard
            server={organization}
            progress={false}
            deleteProgress={false}
            selected={selected}
            onSelectServer={onSelectServer}
            onMenuOpen={onMenuOpen}
            isGrayedOut={
              (allowSelectRoot ? false : isRoot || isWorkspace) ||
              hideHierarchyBelowId?.id === organization.id ||
              greyOutNodeWithId?.id === organization.id
            }
            greyOutReason={
              greyOutNodeWithId?.id === organization.id
                ? greyOutNodeWithId?.reason
                : hideHierarchyBelowId?.id === organization.id
                ? hideHierarchyBelowId?.reason
                : undefined
            }
          />
        </div>
      }
      canExpand={canExpand}
      loading={loading}
    >
      {children}
    </StyledTreeItem>
  );

  useEffect(() => {
    if (!data.firstPageLoading) {
      onLoad(organization.id ?? "", opened);
    }
  }, [data.firstPageLoading, onLoad, organization, opened]);

  useEffect(() => {
    if (numberOfChildren > prevNumberOfChildren.current) {
      onExpand(organization.id ?? "", true);
    } else if (!numberOfChildren && !!prevNumberOfChildren) {
      onExpand(organization.id ?? "", false);
    }
    prevNumberOfChildren.current = numberOfChildren;
  }, [numberOfChildren, prevNumberOfChildren, onExpand, organization]);

  return isRoot ? (
    <PageContent progress={data.firstPageLoading} noPadding style={{ padding: 0 }}>
      {data.totalData.length || !!additionalRootWorkspaces?.length ? (
        <>
          {toolbar && <div style={{ float: "right" }}>{toolbar}</div>}
          <div style={{ marginRight: 400 }}>{showRootCard ? node : children}</div>
        </>
      ) : (
        emptyState
      )}
    </PageContent>
  ) : (
    node
  );
}
