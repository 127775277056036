import React, { CSSProperties } from "react";
import { Controller } from "react-hook-form";

import FormHelperText from "@mui/material/FormHelperText";
import { Minus, Plus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../components/FormInputLabel";
import { useFormContext } from "./Form";
import { validators } from "./validation";

const useStyles = makeStyles()(theme => ({
  inputContainer: {
    width: 200,
    marginLeft: 12,
    borderRadius: 4,
    border: `solid 1px rgba(0, 0, 0, 0.23)`,
    padding: 13,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.custom.greys.fieldBackground,
    boxShadow: theme.custom.shadows.formInner,
  },
  button: {
    borderRadius: "50%",
    border: `solid 1px ${theme.custom.greys.border}`,
    backgroundColor: "white",
    padding: 0,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:enabled": {
      cursor: "pointer",
    },
  },
}));

export interface NumberFieldProps {
  id?: string;
  title: string;
  name: string;
  description: string | React.ReactNode;
  min?: number;
  max?: number;
  tooltip?: string;
  disabled?: boolean;
  defaultValue?: number;
  style?: CSSProperties;
}

function NumberField({
  id,
  title,
  name,
  description,
  min,
  max,
  tooltip,
  disabled,
  defaultValue,
  style,
}: NumberFieldProps) {
  const { classes } = useStyles();
  const { form, disabled: formDisabled, id: formId } = useFormContext();
  const inputId = id || `${formId}-${name}`;

  return (
    <Controller
      name={name}
      control={form.control}
      rules={{
        validate: {
          inRange:
            min !== undefined && max !== undefined
              ? validators.inRange({ label: title, min, max })
              : () => true,
        },
      }}
      defaultValue={defaultValue || 0}
      render={({ field: { onChange, value }, fieldState }) => {
        return (
          <div id={inputId} style={{ marginBottom: 32, ...style }}>
            <div style={{ display: "flex", marginTop: 4 }}>
              <div style={{ flex: 1 }}>
                <FormInputLabel
                  id={`${inputId}-title`}
                  label={title}
                  optional={false}
                  tooltip={tooltip}
                />
                <FormHelperText id={`${inputId}-description`} style={{ paddingRight: 8 }}>
                  {description}
                </FormHelperText>
              </div>
              <div>
                <div className={classes.inputContainer}>
                  <button
                    type="button"
                    onClick={() => {
                      onChange(value - 1);
                    }}
                    id={`${inputId}-decrement-button`}
                    className={classes.button}
                    disabled={formDisabled || disabled || (min !== undefined && value <= min)}
                  >
                    <Minus size="16" />
                  </button>
                  <span id={`${inputId}-value`} data-testid={`${inputId}-value`} aria-label={title}>
                    {value}
                  </span>
                  <button
                    type="button"
                    onClick={() => {
                      onChange(value + 1);
                    }}
                    id={`${inputId}-increment-button`}
                    className={classes.button}
                    disabled={formDisabled || disabled || (max !== undefined && value >= max)}
                  >
                    <Plus size="16" />
                  </button>
                </div>
                <FormHelperText style={{ minHeight: 20 }} error>
                  {fieldState.error?.message}
                </FormHelperText>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

export default NumberField;
