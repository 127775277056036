import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";

import { useQueryClient } from "@tanstack/react-query";

import {
  ExtendedUser,
  ExtendedUserStatusEnum,
  IdentifierDetailsTypeEnum,
  PoolResponse,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../common/api/paths";
import Menu from "../../../../../../../common/components/Menu";
import {
  handleErrorWithNotify,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import { GlobalStoreContext } from "../../../../../../GlobalStore/GlobalStore";
import { listWorkspacePoolsQueryKey } from "../../../../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import { listUsersQueryKey } from "../../../../../../services/adminIdentityUsersQuery";
import { useWorkspace } from "../../../../../common/useWorkspace";
import { usePoolRootUrl } from "../../../utils";
import IdentityPoolThemeSelectorDialog from "../../IdentityPoolThemeSelectorDialog";
import { useIdentityPoolThemeSelector } from "../../useIdentityPoolThemeSelector";
import IdentityPoolUserDeleteDialog from "../user/IdentityPoolUserDeleteDialog";
import SendActivationMessageDialog from "./SendActivationMessageDialog";

interface Props {
  pool: PoolResponse;
  user: ExtendedUser;
  anchorEl: HTMLElement;
  onClose: () => void;
  handleSendActivationMessage: (userId: string, serverId?: string) => void;
  sendActivationMsgDialog: { userId: string } | undefined;
  setSendActivationMsgDialog: (msgDialog: { userId: string } | undefined) => void;
}

function IdentityPoolUserRowMenu({
  pool,
  user,
  anchorEl,
  onClose,
  handleSendActivationMessage,
  sendActivationMsgDialog,
  setSendActivationMsgDialog,
}: Props) {
  const globalStoreContext = useContext(GlobalStoreContext);

  const [progress, setProgress] = useState(false);
  const [removeUser, setRemoveUser] = useState<string>();
  const { rootUrl, mode } = usePoolRootUrl();
  const [workspace] = useWorkspace();

  const {
    mustSelectThemeServerId,
    themeWorkspaceId,
    isDialogOpen: themeSelectorDialog,
    closeDialog: closeThemeSelectorDialog,
    openDialog: openThemeSelectorDialog,
  } = useIdentityPoolThemeSelector({ pool });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleRemove = (userId: string) => {
    setProgress(true);
    identityUsersApi
      .deleteUser({ ipID: pool.id!, userID: userId })
      .then(() => globalStoreContext.deletedUsers.addDeletedUserId(userId))
      .then(() => {
        if (mode === "workspace") {
          queryClient.invalidateQueries({ queryKey: listWorkspacePoolsQueryKey(workspace) });
        }
      })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listUsersQueryKey(getTenantId(), user?.user_pool_id),
        })
      )
      .then(() => notifySuccess("User successfully deleted"))
      .catch(handleErrorWithNotify("Error occurred while trying to delete user"))
      .finally(() => {
        setProgress(false);
        setRemoveUser(undefined);
      });
  };

  const identifierToShow = user.identifiers_with_details?.find(
    identifier => identifier.type !== IdentifierDetailsTypeEnum.Federated
  );

  return (
    <>
      <Menu
        items={[
          {
            onClick: () => navigate(`${rootUrl}/${pool.id!}/users/${user.id}`),
            label: "Edit",
            id: "edit",
          },
          {
            onClick: () => setRemoveUser(user.id),
            label: "Delete",
            id: "delete",
          },
          ...(user.status === ExtendedUserStatusEnum.New
            ? [
                {
                  onClick: () => {
                    if (mustSelectThemeServerId) {
                      openThemeSelectorDialog();
                    } else {
                      handleSendActivationMessage(user.id ?? "", themeWorkspaceId);
                    }
                  },
                  label: "Send activation message",
                  id: "send-activation-message",
                },
              ]
            : []),
        ]}
        anchorEl={anchorEl}
        onClose={onClose}
      />

      {removeUser && (
        <IdentityPoolUserDeleteDialog
          onCancel={() => {
            setRemoveUser(undefined);
            onClose();
          }}
          onConfirm={() => {
            handleRemove(removeUser);
            onClose();
          }}
          progress={progress}
          identifier={identifierToShow?.value ?? null}
        />
      )}

      {sendActivationMsgDialog && (
        <SendActivationMessageDialog
          poolId={pool.id!}
          userId={sendActivationMsgDialog.userId}
          onClose={() => {
            setSendActivationMsgDialog(undefined);
            onClose();
          }}
        />
      )}

      {themeSelectorDialog && (
        <IdentityPoolThemeSelectorDialog
          pool={pool}
          onCancel={() => {
            closeThemeSelectorDialog();
            onClose();
          }}
          onSubmit={(serverId: string) => {
            closeThemeSelectorDialog();
            handleSendActivationMessage(user.id ?? "", serverId);
          }}
        />
      )}
    </>
  );
}

export default IdentityPoolUserRowMenu;
