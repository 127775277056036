import React, { Dispatch, SetStateAction } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";

import { OrganizationResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import FormSection from "../../../../common/components/FormSection";
import { useCheckTenantPermissions, useGetTenant } from "../../../services/adminTenantsQuery";
import { UseItemsReturnType } from "../../common/EnhancedTableAsync/useItemsWithQuery";
import PageContent from "../../common/PageContent";
import OrganizationCard from "./OrganizationCard";
import OrganizationsToolbar from "./OrganizationsToolbar";
import TenantOrganizationsTabEmptyState from "./TenantOrganizationsTabEmptyState";
import { GridMode, TENANT_ORGANIZATIONS_GRID_MODE_KEY } from "./utils";

interface Props {
  cardProgress: string;
  deleteProgress: string;
  onSelectServer: (id: string) => void;
  allData: UseItemsReturnType<OrganizationResponse>;
  templateData?: UseItemsReturnType<OrganizationResponse>;
  onMenuOpen: (props: {
    anchorEl: HTMLElement;
    server: OrganizationResponse;
    rootServer?: OrganizationResponse | WorkspaceResponse;
  }) => void;
  setGridMode: Dispatch<SetStateAction<GridMode>>;
  onCreate: (v: boolean) => void;
}

export default function TenantOrganizationsTabCards({
  cardProgress,
  deleteProgress,
  onSelectServer,
  allData,
  templateData,
  onMenuOpen,
  setGridMode,
  onCreate,
}: Props) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations = checkTenantPermissionsQuery.data?.list_organizations;
  const getTenantQuery = useGetTenant(getTenantId());

  const isEmpty =
    !allData.totalData.length &&
    !allData.areFiltersApplied &&
    !allData.searchText &&
    !templateData?.totalData.length &&
    !templateData?.areFiltersApplied &&
    !templateData?.searchText;

  const toolbar = (
    <OrganizationsToolbar
      createButtonLabel="Create Organization"
      searchText={allData.searchText}
      onUpdate={
        canListOrganizations
          ? text => {
              allData.onFiltersUpdate(text, allData.filters);
              templateData?.onFiltersUpdate(text, templateData.filters);
            }
          : undefined
      }
      onCreate={
        checkTenantPermissionsQuery.data?.create_workspace ? () => onCreate(true) : undefined
      }
      gridMode={canListOrganizations ? "cards" : undefined}
      setGridMode={canListOrganizations ? setGridMode : undefined}
      gridModeLocalStorageKey={TENANT_ORGANIZATIONS_GRID_MODE_KEY}
    />
  );

  const templateSection = (
    <>
      {templateData && !canListOrganizations && (
        <>
          <FormSection title="Templates" id="templates" style={{ marginBottom: 16 }} />
          {templateData.totalData.length > 0 ? (
            <Grid container spacing={3} style={{ marginBottom: 32 }}>
              {templateData.totalData.map(server => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={3} key={server.id}>
                    <OrganizationCard
                      server={server}
                      tenant={getTenantQuery.data}
                      progress={cardProgress === server.id}
                      deleteProgress={server.id === deleteProgress}
                      onSelectServer={onSelectServer}
                      withTemplateOrDefaultTemplate
                      onMenuOpen={onMenuOpen}
                    />
                  </Grid>
                );
              })}
              {templateData.nextPageAvailable && (
                <Grid item xs={12}>
                  <LoadingButton
                    id="load-more-templates-button"
                    variant="outlined"
                    onClick={() => templateData.onLastPage()}
                    loading={templateData.isFetching}
                  >
                    Load more
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          ) : (
            <div style={{ color: "gray", marginBottom: 32 }}>No templates found</div>
          )}
          <FormSection
            title="All Organizations"
            id="all-organizations"
            style={{ marginBottom: 16 }}
          />
        </>
      )}
    </>
  );

  return (
    <PageContent progress={allData.firstPageLoading || templateData?.firstPageLoading}>
      {!isEmpty ? (
        <>
          {toolbar}
          {templateSection}
          {allData.totalData.length > 0 ? (
            <Grid container spacing={3}>
              {allData.totalData.map(server => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={3} key={server.id}>
                    <OrganizationCard
                      server={server}
                      progress={cardProgress === server.id}
                      deleteProgress={server.id === deleteProgress}
                      onSelectServer={onSelectServer}
                      withTemplateOrDefaultTemplate
                      onMenuOpen={onMenuOpen}
                    />
                  </Grid>
                );
              })}
              {!!templateData && allData.nextPageAvailable && (
                <Grid item xs={12}>
                  <LoadingButton
                    id="load-more-non-templates-button"
                    variant="outlined"
                    onClick={() => allData.onLastPage()}
                    loading={allData.isFetching}
                  >
                    Load more
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          ) : (
            <div style={{ color: "gray", marginBottom: 32 }}>No organizations found</div>
          )}
        </>
      ) : (
        <TenantOrganizationsTabEmptyState onCreate={onCreate} />
      )}
    </PageContent>
  );
}
