import { useState } from "react";

export type DashboardGlobalStoreContextType = {
  compliantDialogVisible: boolean;
  setCompliantDialogVisible: (compliantDialogVisible: boolean) => void;
};

export const useDashboardGlobalStore = (): DashboardGlobalStoreContextType => {
  const [store, setStore] = useState<{ compliantDialogVisible: boolean }>({
    compliantDialogVisible: false,
  });

  const setCompliantDialogVisible = (compliantDialogVisible: boolean) => {
    setStore(store => ({
      ...store,
      compliantDialogVisible,
    }));
  };

  return {
    compliantDialogVisible: store.compliantDialogVisible,
    setCompliantDialogVisible,
  };
};
