import { Schema, Schemas } from "@cloudentity/acp-identity";

import { getTenantId } from "../../common/api/paths";
import identitySchemasApi from "./adminIdentitySchemasApi";
import {
  useQueryWithTenantPermissionCheck,
  useQueryWithWorkspacePermissionCheck,
  withQueryError,
} from "./queryUtils";

const LIST_SCHEMAS_QUERY = "LIST_SCHEMAS_QUERY";
const LIST_WORKSPACE_SCHEMAS_QUERY = "LIST_WORKSPACE_SCHEMAS_QUERY";
const GET_SCHEMA_QUERY = "GET_SCHEMA_QUERY";
const GET_WORKSPACE_SCHEMA_QUERY = "GET_WORKSPACE_SCHEMA_QUERY";

export const listSchemasQueryKey = tid => [LIST_SCHEMAS_QUERY, tid];
export const listWorkspaceSchemasQueryKey = (tid, wid) => [LIST_WORKSPACE_SCHEMAS_QUERY, tid, wid];
export const getSchemaQueryKey = (tid, schemaID) => [GET_SCHEMA_QUERY, tid, schemaID];
export const getWorkspaceSchemaQueryKey = (tid, wid, schemaID) => [
  GET_WORKSPACE_SCHEMA_QUERY,
  wid,
  tid,
  schemaID,
];

export const useListSchemas = (tid, options?) =>
  useQueryWithTenantPermissionCheck(
    "list_identity_pools",
    listSchemasQueryKey(tid),
    withQueryError<Schemas>(async () => {
      const data = await identitySchemasApi.listSchemas({});
      return data.data;
    }, "Error occurred while trying to list identity schemas"),
    options
  );

export const useListWorkspaceSchemas = (tid, wid, options?) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "discover_workspace",
    listWorkspaceSchemasQueryKey(tid, wid),
    withQueryError<Schemas>(async () => {
      const data = await identitySchemasApi.listWorkspaceSchemas({ wid });
      return data.data;
    }, "Error occurred while trying to list workspace schemas"),
    options
  );

export const useGetSchema = (schemaID, options?) =>
  useQueryWithTenantPermissionCheck(
    "list_identity_pools",
    getSchemaQueryKey(getTenantId(), schemaID),
    withQueryError<Schema>(async () => {
      const data = await identitySchemasApi.getSchema({ schID: schemaID });
      return data.data;
    }, "Error occurred while trying to get identity schema"),
    { ...options, enabled: (options?.enabled && !!schemaID) ?? !!schemaID }
  );

export const useGetWorkspaceSchema = (wid, schemaID, options?) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "discover_workspace",
    getWorkspaceSchemaQueryKey(getTenantId(), wid, schemaID),
    withQueryError<Schema>(async () => {
      const data = await identitySchemasApi.getWorkspaceSchema({ wid, schID: schemaID });
      return data.data;
    }, "Error occurred while trying to get workspace schema"),
    { ...options, enabled: (options?.enabled && !!schemaID) ?? !!schemaID }
  );
