import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ green?: boolean }>()((theme, { green }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 58,
    position: "absolute",
    top: 0,
    right: 0,
  },
  circleContainer: {
    position: "relative",
    height: 58,
    width: 58,
  },
  circleBack: {
    color: "#EAECF0",
  },
  circle: {
    strokeLinecap: "round",
    position: "absolute",
    top: 0,
    left: 0,
    ...(green ? { color: theme.custom.sa.complimentary.green.high } : {}),
  },
  innerText: {
    fontSize: 20,
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    position: "absolute",
  },
  caption: {
    color: theme.custom.sa.neutrals.n80,
    marginTop: 4,
  },
}));

interface Props {
  value: number;
  text: string;
  caption: string;
}

export default function ProgressIndicator({ value, text, caption }: Props) {
  const { classes } = useStyles({ green: value === 100 });
  return (
    <div className={classes.container}>
      <div className={classes.circleContainer}>
        <CircularProgress
          variant="determinate"
          value={100}
          size={58}
          className={classes.circle}
          classes={{ circle: classes.circleBack }}
        />
        <CircularProgress
          variant="determinate"
          value={value}
          size={58}
          className={classes.circle}
        />
        <div className={classes.innerText}>{text}</div>
      </div>
      <Typography variant="textMD" component="div" className={classes.caption}>
        {caption}
      </Typography>
    </div>
  );
}
