import React from "react";

import ChevronRight from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  menuButtonContainer: {
    padding: 0,
    margin: 0,
    position: "absolute",
    top: 15,
    right: -20,
    border: `solid 1px ${theme.custom.greys.border}`,
    borderRadius: "50%",
    backgroundColor: "white",
    boxShadow: theme.custom.shadows.surfaceResting,
    zIndex: 2,
  },
  menuButton: {
    margin: 0,
  },
}));

interface Props {
  onClick: (open: boolean) => void;
}

export default function NavDrawerExpander({ onClick }: Props) {
  const { cx, classes } = useStyles();

  return (
    <div className={classes.menuButtonContainer}>
      <IconButton
        aria-label="open drawer"
        onClick={() => onClick(true)}
        className={cx(classes.menuButton)}
        size="small"
      >
        <ChevronRight />
      </IconButton>
    </div>
  );
}
