import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";

import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import EditOrganizationDialog from "./EditOrganizationDialog";

interface Props {
  orgId: string;
  onClick: () => void;
}

export default function EditOrganizationMenuItem({ orgId, onClick }: Props) {
  const [showEditDialog, setEditDialog] = useState(false);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(orgId);

  return checkWorkspacePermissionsQuery.data?.update_organization ? (
    <>
      <MenuItem
        id={`${orgId}-edit-workspace`}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setEditDialog(true);
          onClick();
        }}
        key="edit"
      >
        Edit
      </MenuItem>

      {showEditDialog && (
        <EditOrganizationDialog
          onClose={() => {
            setEditDialog(false);
          }}
          serverID={orgId}
        />
      )}
    </>
  ) : null;
}
