import {
  IdpsApi,
  IdpsApiCreateStaticIDPRequest,
  IdpsApiDeleteIDPRequest,
  IdpsApiListIDPsForIdentityPoolRequest,
  IdpsApiListIDPsRequest,
  ServerResponse,
  ServerResponseProfileEnum,
} from "@cloudentity/acp-admin";
import { IDP } from "@cloudentity/acp-root";

import { adminApiClazz } from "./admin-services.common";

const api: () => IdpsApi = adminApiClazz(IdpsApi);

// when adding a new type also adjust: web/app/src/admin/components/identities/identities.utils.tsx
export const typeToApiString = {
  custom: { api: "Custom", name: "Custom" },
  static: { api: "Static", name: "Static" },
  azureb2c: { api: "AzureB2C", name: "AzureB2C" },
  azure: { api: "Azure", name: "Azure" },
  cognito: { api: "Cognito", name: "Cognito" },
  github: { api: "Github", name: "Github" },
  intelli_trust: { api: "IntelliTrust", name: "Entrust" },
  oidc: { api: "OIDC", name: "OIDC" },
  okta: { api: "Okta", name: "Okta" },
  auth0: { api: "Auth0", name: "Auth0" },
  saml: { api: "SAML", name: "SAML" },
  saml_v2: { api: "SAMLV2", name: "SAMLV2" },
  external: { api: "External", name: "External" },
  google: { api: "Google", name: "Google" },
  github_embedded: { api: "GithubEmbedded", name: "GithubEmbedded" },
  google_embedded: { api: "GoogleEmbedded", name: "GoogleEmbedded" },
  identity_pool: { api: "IdentityPool", name: "IdentityPool" },
  organization: { api: "Organization", name: "Organization" },
};

export type IdpType = keyof typeof typeToApiString;

export const socialIdpTypes: IdpType[] = ["github", "google", "github_embedded", "google_embedded"];

function lowercaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export const withIDPDefaults = <T extends Pick<IDP, "name">>(
  provider: T,
  type: IdpType,
  server?: ServerResponse,
  isHidden?: boolean
) => {
  return {
    ...provider,
    name: provider.name?.trim(),
    ...(server?.profile === ServerResponseProfileEnum.Consumer
      ? {
          hidden: isHidden !== undefined ? isHidden : !socialIdpTypes.includes(type),
          discovery_settings: {
            instant_redirect: true,
            identifier_based_matching: ["organization", "identity_pool"].includes(type),
          },
        }
      : {}),
  };
};

const adminIDPsApi = {
  getIDPs: (req: IdpsApiListIDPsRequest) => api().listIDPs(req),
  getIDP: ({ wid, type, iid }) => api()[`get${typeToApiString[type].api}IDP`]({ wid, iid }),
  getIDPClient: ({ wid, type, iid }) =>
    api()[`get${typeToApiString[type].api}IDPClient`]({ wid, iid }),
  createIDP: ({ wid, type, body }) =>
    api()[`create${typeToApiString[type].api}IDP`]({
      wid,
      [`${lowercaseFirstLetter(typeToApiString[type].api)}IDP`]: body,
    }),
  createStaticIDP: (req: IdpsApiCreateStaticIDPRequest) => api().createStaticIDP(req),
  updateIDP: ({ aid, type, iid, body }): Promise<any> =>
    api()[`update${typeToApiString[type].api}IDP`]({
      wid: aid,
      iid,
      [`${lowercaseFirstLetter(typeToApiString[type].api)}IDP`]: body,
    }),
  removeIDP: (req: IdpsApiDeleteIDPRequest) => api().deleteIDP(req),
  listIDPsForIdentityPool: (req: IdpsApiListIDPsForIdentityPoolRequest) =>
    api().listIDPsForIdentityPool(req),
};

export default adminIDPsApi;
