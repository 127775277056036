import React, { useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";

import { Server, ServerResponseTypeEnum, ServerTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import Progress from "../../../common/components/Progress";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../common/utils/forms/Form";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../common/utils/forms/validation";
import { trimStringValues } from "../../../common/utils/object.utils";
import { toSlugString } from "../../../common/utils/string.utlis";
import adminServersApi from "../../services/adminServersApi";
import {
  listServerBindingsQueryKey,
  listWorkspacesQueryKey,
} from "../../services/adminServersQuery";
import WorkspaceAvatar from "../nav/WorkspaceAvatar";
import { StepData } from "../welcome/useWorkspaceDirectoryData";
import WorkspacesColorInput from "./WorkspacesColorInput";
import WorkspacesPreviewInput from "./WorkspacesPreviewInput";

interface Props {
  stepsData: StepData;
  onChangeStepsData: (data: StepData) => void;
  actionsLeftMargin?: number;
  workspace: string | undefined;
  hideSkipCreateDeveloperPortal: boolean;
  onNext: () => void;
  onSkip: (createdWorkspace?: string) => void;
}

export default function WorkspaceDirectoryCreateCreateDeveloperPortalStep({
  stepsData,
  onChangeStepsData,
  actionsLeftMargin = 480,
  workspace,
  hideSkipCreateDeveloperPortal,
  onNext,
  onSkip,
}: Props) {
  const [createServerProgress, setCreateServerProgress] = useState(false);
  const form = useForm({
    id: "workspace-directory-create-create-developer-portal-step",
    initialValues: stepsData["createDeveloperPortal"],
  });

  const name = form.watch("name");

  const queryClient = useQueryClient();

  const setStepDataByField = (field, value) =>
    onChangeStepsData({
      ...stepsData,
      createDeveloperPortal: {
        ...stepsData.createDeveloperPortal,
        [field]: value,
      },
    });
  const setStepData = value =>
    onChangeStepsData({
      ...stepsData,
      createDeveloperPortal: value,
    });
  const handleChange = field => e => setStepDataByField(field, e.target.value);

  const handleChangeName = e => {
    const slugId = toSlugString(e.target.value);

    form.setValue("id", slugId);
    setStepData({ ...stepsData["createDeveloperPortal"], id: slugId, name: e.target.value });
  };

  const handleSubmit = data => {
    setCreateServerProgress(true);
    adminServersApi
      .createServer({
        server: {
          ...trimStringValues(data),
          type: ServerTypeEnum.Developer,
          color: stepsData["createDeveloperPortal"].color,
        } as Server,
      })
      .then(res => {
        setStepDataByField("authorizationServer", res.data);
        return res.data;
      })
      .then(res => {
        if (workspace) {
          adminServersApi.bindServer({ wid: res.id!, rid: workspace });
        }
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listServerBindingsQueryKey(getTenantId()) })
      )
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() => onNext())
      .catch(err => {
        if (err.response.status === 409) {
          handleErrorWithNotify("Developer ID is already used, please choose different one")(err);
        } else {
          handleErrorWithNotify("Error occur while trying to create developer portal")(err);
        }
      })
      .finally(() => setCreateServerProgress(false));
  };

  return (
    <Form form={form}>
      {createServerProgress && <Progress isGlobalLoader />}
      {!hideSkipCreateDeveloperPortal && (
        <Alert severity="info" style={{ marginBottom: 24 }}>
          <AlertTitle>Developer portal </AlertTitle>
          <div style={{ marginTop: 12 }}>
            Enable developers to register their applications to consume protected services. Allow
            access to the portal by connecting to 3rd party Identity providers.
          </div>
          <div style={{ marginTop: 20 }}>
            <Button
              size="large"
              id="skip-btn"
              variant="outlined"
              color="primary"
              onClick={() => onSkip(stepsData.addWorkspaceDetails.id)}
            >
              Skip
            </Button>
          </div>
        </Alert>
      )}

      <Paper style={{ padding: "24px 22px", marginBottom: 32 }}>
        <div>
          <TextFieldRequired
            name="name"
            label="Display Name"
            onChange={handleChangeName}
            autoFocus
          />
          <TextFieldRequired
            name="id"
            label="Developer ID"
            helperText="Once created this cannot be changed later"
            rules={{
              validate: {
                validID: validators.validID({ label: "Developer ID" }),
              },
            }}
            onChange={handleChange("id")}
          />
          <WorkspacesColorInput
            value={stepsData["createDeveloperPortal"].color}
            onChange={color => setStepDataByField("color", color)}
          />
          <WorkspacesPreviewInput
            name={name}
            color={stepsData["createDeveloperPortal"].color}
            icon={
              <WorkspaceAvatar
                server={{
                  type: ServerResponseTypeEnum.Developer,
                  color: stepsData["createDeveloperPortal"].color,
                }}
              />
            }
          />
        </div>
      </Paper>

      <div
        style={{
          position: "fixed",
          padding: "0 32px",
          marginLeft: actionsLeftMargin,
          height: 80,
          background: "#fff",
          borderTop: "1px solid #ECECEC",
          display: "flex",
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="large"
          id="next-btn"
          variant="contained"
          color="primary"
          disabled={createServerProgress}
          onClick={form.handleSubmit(handleSubmit)}
        >
          Next
        </Button>
      </div>
    </Form>
  );
}
