import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import PageContent from "../common/PageContent";
import { PAGE_HEADER_HEIGHT } from "../common/PageHeader";
import { StepData } from "../welcome/useWorkspaceDirectoryData";
import WorkspaceDirectoryCreateAddWorkspaceDetailsStep from "./WorkspaceDirectoryCreateAddWorkspaceDetailsStep";
import WorkspaceDirectoryCreateConnectDeveloperPoolStep from "./WorkspaceDirectoryCreateConnectDeveloperPoolStep";
import WorkspaceDirectoryCreateConnectUserPoolStep from "./WorkspaceDirectoryCreateConnectUserPoolStep";
import WorkspaceDirectoryCreateCreateDeveloperPortalStep from "./WorkspaceDirectoryCreateCreateDeveloperPortalStep";
import WorkspaceDirectoryCreateDefineWorkspaceTypeStep from "./WorkspaceDirectoryCreateDefineWorkspaceTypeStep";

interface Props {
  step: string;
  stepsData: StepData;
  workspace: string | undefined;
  hideSkipCreateDeveloperPortal: boolean;
  onChangeStepsData: (data: StepData) => void;
  onNext: (step: string) => () => void;
  onBack: (step: string) => () => void;
  onFinish: (createdWorkspace?: string) => void;
  type: StepData["defineWorkspaceType"]["selected"];
  onWorkspaceCreate?: (id: string) => void;
}

export default function WorkspaceDirectoryCreateContent({
  step,
  stepsData,
  workspace,
  hideSkipCreateDeveloperPortal,
  onChangeStepsData,
  onNext,
  onBack,
  onFinish,
  type,
  onWorkspaceCreate,
}: Props) {
  const pageContentStyle = {
    height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px - 80px)`,
    overflow: "auto",
  };
  return (
    <>
      {step === "defineWorkspaceType" && (
        <PageContent fullWidth={false} progress={false} style={pageContentStyle}>
          <Typography style={{ marginBottom: 32 }} variant="body2">
            What type of applications and services would you like to protect?
          </Typography>
          <WorkspaceDirectoryCreateDefineWorkspaceTypeStep
            actionsLeftMargin={360}
            stepsData={stepsData}
            onChangeStepsData={onChangeStepsData}
            onNext={onNext("addWorkspaceDetails")}
          />
        </PageContent>
      )}
      {step === "addWorkspaceDetails" && (
        <PageContent fullWidth={false} progress={false} style={pageContentStyle}>
          <Grid container justifyContent="center">
            <Grid item md={8} xs={12}>
              <Typography style={{ marginBottom: 32 }} variant="body2">
                Add workspace details
              </Typography>
              <WorkspaceDirectoryCreateAddWorkspaceDetailsStep
                actionsLeftMargin={360}
                stepsData={stepsData}
                onChangeStepsData={onChangeStepsData}
                onNext={onNext(
                  type === ("partners" as any) ? "createDeveloperPortal" : "connectUserPool"
                )}
                onBack={onBack("defineWorkspaceType")}
                onWorkspaceCreate={onWorkspaceCreate}
              />
            </Grid>
          </Grid>
        </PageContent>
      )}
      {step === "connectUserPool" && (
        <PageContent fullWidth={false} progress={false} style={pageContentStyle}>
          <WorkspaceDirectoryCreateConnectUserPoolStep
            formContainerJustify="center"
            actionsLeftMargin={360}
            workspaceId={stepsData["addWorkspaceDetails"].authorizationServer.id ?? ""}
            server={stepsData["addWorkspaceDetails"].authorizationServer}
            stepsData={stepsData}
            onNext={onNext("createDeveloperPortal")}
          />
        </PageContent>
      )}
      {step === "createDeveloperPortal" && (
        <PageContent fullWidth={false} progress={false} style={pageContentStyle}>
          <Grid container justifyContent="center">
            <Grid item md={8} xs={12}>
              <Typography style={{ marginBottom: 32 }} variant="body2">
                Create developer portal
              </Typography>
              <WorkspaceDirectoryCreateCreateDeveloperPortalStep
                actionsLeftMargin={360}
                stepsData={stepsData}
                workspace={workspace}
                hideSkipCreateDeveloperPortal={hideSkipCreateDeveloperPortal}
                onChangeStepsData={onChangeStepsData}
                onNext={onNext("connectDeveloperPool")}
                onSkip={onFinish}
              />
            </Grid>
          </Grid>
        </PageContent>
      )}
      {step === "connectDeveloperPool" && (
        <PageContent fullWidth={false} progress={false} style={pageContentStyle}>
          <WorkspaceDirectoryCreateConnectDeveloperPoolStep
            actionsLeftMargin={360}
            stepsData={stepsData}
            workspaceId={stepsData["createDeveloperPortal"].authorizationServer.id ?? ""}
            server={stepsData["createDeveloperPortal"].authorizationServer}
            onFinish={onFinish}
          />
        </PageContent>
      )}
    </>
  );
}
