import React from "react";

import TextField, { TextFieldProps } from "./TextField";

export default function TextFieldRequired({ rules, ...rest }: TextFieldProps) {
  return (
    <TextField
      rules={{
        ...rules,
        required: `${rest.label} is required`,
      }}
      {...rest}
    />
  );
}
