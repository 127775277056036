import React, { ReactNode } from "react";

import PopoverMUI, { PopoverProps as PopoverMUIProps } from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { X } from "react-feather";
import { makeStyles } from "tss-react/mui";

import IconButton from "./IconButton";

const useStyles = makeStyles<{ maxWidth?: number }>()((theme, { maxWidth }) => ({
  paper: {
    backgroundColor: theme.custom.sa.dark.dark,
    color: "white",
    padding: 16,
    maxWidth: maxWidth || 210,
  },
  closeIcon: {
    position: "absolute",
    top: 4,
    right: 4,
    color: "white",
  },
}));

interface PopoverProps extends PopoverMUIProps {
  header?: ReactNode;
  onCloseIcon?: () => void;
  maxWidth?: number;
}

const Popover = ({ children, header, onCloseIcon, maxWidth, ...props }: PopoverProps) => {
  const { classes } = useStyles({ maxWidth });

  return (
    <PopoverMUI
      classes={{
        paper: classes.paper,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      {...props}
    >
      {header && (
        <Typography variant="textSM" style={{ fontWeight: 600, lineHeight: "22px" }}>
          {header}
        </Typography>
      )}
      {!!onCloseIcon && (
        <IconButton icon={X} onClick={onCloseIcon} noBorder className={classes.closeIcon} />
      )}
      {children}
    </PopoverMUI>
  );
};

export default Popover;
