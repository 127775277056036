import React from "react";

import { makeStyles } from "tss-react/mui";

import Chip from "../../../../common/components/Chip";
import Fab from "../../../../common/components/Fab";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import { useWorkspace } from "../../common/useWorkspace";

const useStyles = makeStyles()(theme => ({
  tabHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  totalClaimsContainer: {
    display: "flex",
    alignItems: "center",
  },
  totalClaims: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    color: theme.palette.secondary.dark,
    marginRight: 12,
  },
}));

interface Props {
  onAdd: ({ type }: { type: string }) => void;
  length: number;
  subtitle: string;
  loading: boolean;
}

function TabHeader({ onAdd, length, subtitle, loading }: Props) {
  const { classes } = useStyles();

  const [workspace] = useWorkspace();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  return (
    <div className={classes.tabHeader}>
      <div className={classes.totalClaimsContainer}>
        <span className={classes.totalClaims}>{subtitle}</span>
        <Chip label={`${length} Claims`} />
      </div>

      {checkWorkspacePermissionsQuery.data?.update_workspace && (
        <Fab id="create-token-claim-button" onClick={onAdd} disabled={loading}>
          + Add claim
        </Fab>
      )}
    </div>
  );
}

export default TabHeader;
