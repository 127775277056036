import React, { useState } from "react";
import { useNavigate } from "react-router";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { AxiosError } from "axios";
import { makeStyles } from "tss-react/mui";

import { logout } from "../../common/auth/actions/actions";
import Form, { useForm } from "../../common/utils/forms/Form";
import FormFooter from "../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../common/utils/forms/TextFieldRequired";
import userApi from "../services/userApi";

const useStyles = makeStyles()(() => ({
  paperRoot: {
    padding: 32,
    borderRadius: 4,
  },
}));

const ChangePassword = () => {
  const { classes } = useStyles();
  const form = useForm({ id: "user-portal-change-password" });
  const navigate = useNavigate();

  const [state, setState] = useState<{
    newPassword: string;
    error: { status_code: number; error: string; details: { message: string }[] } | null;
  }>({
    newPassword: "",
    error: null,
  });

  const handleChange = prop => event => {
    setState({ ...state, [prop]: event.target.value });
  };

  const handleSubmit = data => {
    userApi
      .changePassword(data.current, data.new)
      .then(() => logout())
      .catch((e: AxiosError) => {
        setState({ ...state, error: e.response?.data as any });
      });
  };

  return (
    <Container style={{ marginTop: 40, position: "relative" }} maxWidth="md">
      <Paper className={classes.paperRoot}>
        <Typography
          id="change-password-header"
          variant="h5"
          style={{ marginBottom: 10, color: "rgba(0, 0, 0, 0.87)" }}
        >
          Change password
        </Typography>

        <Form form={form}>
          <TextFieldRequired
            name="current"
            label="Current password"
            autoFocus
            toggleVisibility
            defaultVisibility={false}
            externalErrors={
              state.error?.error === "incorrect old password" && "Invalid current password"
            }
          />

          <TextFieldRequired
            name="new"
            label="New password"
            onChange={handleChange("newPassword")}
            autoFocus
            toggleVisibility
            defaultVisibility={false}
            withPasswordStrengthMeter
            externalErrors={
              state.error?.status_code === 422 &&
              `${state.error.error}${
                state.error.details.length > 0 ? ": " : ""
              }${state.error.details.map(v => v.message).join(" | ")}`
            }
          />

          <TextFieldRequired
            name="repeat"
            label="Repeat password"
            autoFocus
            toggleVisibility
            defaultVisibility={false}
            rules={{
              validate: {
                equal: v => v === state.newPassword || "Passwords are different",
              },
            }}
          />

          <FormFooter
            onCancel={() => {
              navigate("/");
            }}
            onSubmit={handleSubmit}
            submitText="Save"
          />
        </Form>
      </Paper>
    </Container>
  );
};

export default ChangePassword;
