import React from "react";

import Button from "@mui/material/Button";
import { Play } from "react-feather";

import { openUrlInNewTab } from "../../../../common/utils/browser.utils";
import { useTrySignInLink } from "./useTrySignInLink";

export default function TrySignInButton({
  serverId,
  idpHint,
  size = "large",
  variant = "outlined",
}: {
  serverId: string;
  size: "small" | "medium" | "large";
  variant: "text" | "outlined" | "contained";
  idpHint?: string;
}) {
  const link = useTrySignInLink({ serverId, idpHint });

  if (!link) {
    return null;
  }

  return (
    <Button
      id="try-sign-in-button"
      variant={variant}
      size={size}
      onClick={() => {
        openUrlInNewTab(link);
      }}
    >
      <Play size={16} style={{ marginRight: 8 }} />
      Try Sign-in
    </Button>
  );
}
