import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";

import Colorize from "@mui/icons-material/Colorize";
import IconButton from "@mui/material/IconButton";
import throttle from "lodash/throttle";
import uniq from "lodash/uniq";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../../common/components/FormInputLabel";
import { TRANSPARENT_HEX_VALUE } from "../../../common/components/nav/utils";

export const initialColors = [
  "#C4C4C4",
  "#6366F1",
  "#8BC3E2",
  "#06B6D4",
  "#EC5348",
  "#EC8656",
  "#EAB344",
  "#76B141",
  "#3E48A2",
  "#E576C6",
  "#CF3E83",
  "#393954",
  "#252535",
];

interface Props {
  value: string;
  colors?: string[];
  label?: string;
  disabled?: boolean;
  style?: CSSProperties;
  onChange: (value: string) => void;
}

export default function WorkspacesColorInput({
  value,
  colors = initialColors,
  label,
  disabled = false,
  style,
  onChange,
}: Props) {
  const { classes, cx } = useStyles();

  const [customColor, setCustomColor] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const options = useMemo(() => uniq(colors), [colors]);

  useEffect(() => {
    if (value && colors?.length > 0 && !uniq(colors).includes(value)) {
      setCustomColor(true);
      onChange(value);
      if (inputRef.current) {
        inputRef.current.value = value;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors, value]);

  useEffect(() => {
    if (!!value) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDebouncedChange =
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(
      throttle(value => {
        onChange && onChange(value);
        if (inputRef.current) {
          inputRef.current.value = value;
        }
      }, 250),
      []
    );

  return (
    <div style={style}>
      <FormInputLabel id="color" label={label || "Color"} />
      <div className={classes.colors}>
        {options
          .filter(c => !!c)
          .map(color => (
            <div
              key={color}
              className={classes.color}
              style={{
                background:
                  color === TRANSPARENT_HEX_VALUE
                    ? "repeating-conic-gradient(#F0F0F0 0% 25%, transparent 0% 50%) 50% / 16px 16px"
                    : color,
                ...(value === color
                  ? { boxShadow: "0 0 0 1px #007FFF", border: "1px solid #fff" }
                  : {}),
              }}
              onClick={() => !disabled && onChange(color)}
            />
          ))}
        <input
          type="color"
          ref={inputRef}
          className={cx(classes.color, classes.input)}
          onClick={() => onChange(inputRef.current?.value!)}
          onChange={e => {
            onDebouncedChange && onDebouncedChange(e.currentTarget.value);
          }}
          style={{
            visibility: customColor ? "visible" : "hidden",
            ...(value === inputRef.current?.value
              ? { boxShadow: "0 0 0 1px #007FFF", border: "1px solid #fff" }
              : {}),
          }}
        />

        <div style={{ flex: 1 }} />
        <IconButton
          id="colorButton"
          size="large"
          onClick={() => {
            setCustomColor(true);
            inputRef.current?.click();
          }}
        >
          <Colorize style={{ width: 24, height: 24 }} />
        </IconButton>
      </div>
    </div>
  );
}

const useStyles = makeStyles()(() => ({
  colors: {
    padding: 12,
    border: "1px solid rgba(0, 0, 0, 0.37)",
    borderRadius: 4,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 61,
  },
  color: {
    width: 32,
    height: 32,
    border: "1px solid #D0D5DD",
    borderRadius: 4,
    marginRight: 12,
    "&:hover": {
      cursor: "pointer",
    },
  },
  input: {
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    cursor: "pointer",

    "&::-webkit-color-swatch": {
      borderRadius: 4,
      padding: 0,
      margin: 0,
    },
    "&::-webkit-color-swatch-wrapper": {
      border: "none",
      borderRadius: 10,
      padding: 0,
      margin: 0,
    },
  },
}));
