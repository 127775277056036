import React from "react";

import WarningIcon from "@mui/icons-material/ReportProblemOutlined";
import { CloudLightning, Cpu, Grid, Info, Shield, Users } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { RecentActivity, ServerResponse } from "@cloudentity/acp-admin";

import WorkspaceAvatar from "../nav/WorkspaceAvatar";
import ActivityComponent, { ActivityLabel, ActivityLink } from "./ActivityComponent";
import { activityToRowMap } from "./activityToRowMap";

interface Props {
  activity: RecentActivity;
  authorizationServer: ServerResponse | undefined;
}

export default function RecentActivityItem({ activity, authorizationServer }: Props) {
  const icon = (
    <ActivityIcon
      type={activity.payload?.subject as ActivityType}
      authorizationServer={authorizationServer}
    />
  );
  const message = getDescription(activity) || activity.payload?.action;
  const date = activity.date as string;

  if (getLink(authorizationServer?.id, activity)) {
    return (
      <ActivityComponent icon={icon} message={message} date={date}>
        <ActivityLink
          label={getName(activity)}
          to={getLink(authorizationServer?.id, activity) || `/${authorizationServer?.id}`}
        />
      </ActivityComponent>
    );
  }

  return (
    <ActivityComponent icon={icon} message={message} date={date}>
      <ActivityLabel
        label={
          getName(activity) || `${activity.payload?.primary_name} ${activity.payload?.subject}`
        }
      />
    </ActivityComponent>
  );
}

const useStyles = makeStyles()(theme => ({
  icon: {
    width: 24,
    height: 24,
    color: theme.palette.primary.main,
    marginRight: 15,
  },
}));

type ActivityType = "server" | "idp" | "service" | "api" | "client" | "policy" | "warning";

interface ActivityIconProps {
  type?: ActivityType;
  authorizationServer?: ServerResponse | undefined;
}

export function ActivityIcon({ type, authorizationServer }: ActivityIconProps) {
  const { classes } = useStyles();

  if (type === "idp") return <Users className={classes.icon} />;
  if (type === "service") return <Cpu className={classes.icon} />;
  if (type === "api") return <CloudLightning className={classes.icon} />;
  if (type === "client") return <Grid className={classes.icon} />;
  if (type === "policy") return <Shield className={classes.icon} />;
  if (type === "server")
    return (
      <WorkspaceAvatar
        server={authorizationServer}
        size="small"
        style={{
          width: 24,
          height: 24,
          marginRight: 15,
          fontSize: 12,
        }}
      />
    );
  if (type === "warning") {
    return <WarningIcon className={classes.icon} />;
  }

  return <Info className={classes.icon} />;
}

const getLink = (aid: string | undefined, v: RecentActivity) =>
  (activityToRowMap[v.payload?.subject ?? ""]?.[v.payload?.action]?.link ?? (() => undefined))(
    aid,
    v
  );

const getName = (v: RecentActivity) =>
  (activityToRowMap[v.payload?.subject ?? ""]?.[v.payload?.action]?.name ?? (() => ""))(v);

const getDescription = (v: RecentActivity) =>
  (activityToRowMap[v.payload?.subject ?? ""]?.[v.payload?.action]?.description ?? (() => ""))(v);
