import { Environment } from "@cloudentity/acp-admin";

import adminEnvironmentApi from "./adminEnvironmentApi";
import {
  useQueryWithTenantPermissionCheck,
  useQueryWithWorkspacePermissionCheck,
  withQueryError,
} from "./queryUtils";

const GET_ENVIRONMENT = "GET_ENVIRONMENT";
const GET_WORKSPACE_ENVIRONMENT = "GET_WORKSPACE_ENVIRONMENT";

export const getEnvironmentQueryKey = () => [GET_ENVIRONMENT];
export const getWorkspaceEnvironmentQueryKey = wid => [GET_WORKSPACE_ENVIRONMENT, wid];

export const useGetEnvironment = (options?) =>
  useQueryWithTenantPermissionCheck(
    "read_system_environment",
    getEnvironmentQueryKey(),
    withQueryError<Environment>(async () => {
      const data = await adminEnvironmentApi.environment({});
      return data.data;
    }, "Error occurred while trying to fetch environment"),
    options
  );

export const useGetWorkspaceEnvironment = (wid, options?) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "discover_workspace",
    getWorkspaceEnvironmentQueryKey(wid),
    withQueryError<Environment>(async () => {
      const data = await adminEnvironmentApi.getWorkspaceEnvironment({ wid });
      return data.data;
    }, "Error occurred while trying to fetch workspace environment"),
    options
  );
