import React, { useState } from "react";
import { useNavigate } from "react-router";

import Button from "@mui/material/Button";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { Link, Plus } from "react-feather";

import {
  ServerProfileEnum,
  ServerResponse,
  ServerResponseProfileEnum,
  Tenant,
} from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import adminServersApi from "../../services/adminServersApi";
import { listWorkspacesQueryKey, useListWorkspaces } from "../../services/adminServersQuery";
import adminTenantsApi from "../../services/adminTenantsApi";
import { getTenantQueryKey, useGetTenant } from "../../services/adminTenantsQuery";
import WelcomeTypeCard from "../welcome/WelcomeTypeCard";
import {
  getServerAvatarSquare,
  getServerDefaultColorByProfile,
  isOPBWithDemoWorkspace,
} from "./server-profiles";
import { workspaceAppTypes } from "./workspace-directory.common";

export const createDemoWorkspace = (
  profile: ServerResponseProfileEnum,
  id: string,
  queryClient: QueryClient,
  tenant?: Tenant
) => {
  const name =
    (profile === ServerResponseProfileEnum.Fapi20Security
      ? "Go Bank"
      : workspaceAppTypes.find(w => w.profile === profile)?.title || id) + " Demo";

  let hyperscaleBankServer: ServerResponse;
  return Promise.resolve()
    .then(() => {
      if (profile === ServerResponseProfileEnum.Fapi20Security) {
        return adminServersApi
          .createServer({
            server: {
              tenant_id: getTenantId(),
              id: "hyperscalebank-" + nanoid(10).toLowerCase(),
              name: "Hyperscale Bank Demo",
              color: getServerDefaultColorByProfile(profile),
              profile: profile as any,
              metadata: {},
            },
          })
          .then(res => {
            hyperscaleBankServer = res.data;
            return res;
          });
      }
      return Promise.resolve(null);
    })
    .then(() => {
      return adminServersApi
        .createServer({
          server: {
            tenant_id: getTenantId(),
            id,
            name,
            color: getServerDefaultColorByProfile(profile),
            profile: profile as any,
          },
        })
        .then(res => {
          if (profile === ServerResponseProfileEnum.Fapi20Security) {
            return adminTenantsApi
              .updateTenant({
                tenant: {
                  ...tenant,
                  metadata: {
                    ...tenant?.metadata,
                    generic_openbanking_demo_mapping: {
                      ...tenant?.metadata?.generic_openbanking_demo_mapping,
                      [res.data?.id || ""]: hyperscaleBankServer?.id,
                    },
                  },
                },
              })
              .then(() =>
                queryClient.invalidateQueries({ queryKey: getTenantQueryKey(getTenantId()) })
              );
          }
          return Promise.resolve(null);
        });
    })
    .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
    .catch(err => {
      handleErrorWithNotify("Error occur while trying to create workspace")(err);
    });
};

export const getDemoWorkspaceId = (profile: ServerResponseProfileEnum) => {
  return `${profile.replace("openbanking_", "ob").replace("_fapi_advanced", "")}-demo-${nanoid(
    10
  ).toLowerCase()}`;
};

export default function WorkspaceDirectoryCreateDefineWorkspaceTypeStep({
  stepsData,
  actionsLeftMargin = 480,
  onChangeStepsData,
  onNext,
}) {
  const [createServerProgress, setCreateServerProgress] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const getTenantQuery = useGetTenant(getTenantId());
  const listWorkspacesQuery = useListWorkspaces({
    workspaceTypes: "regular",
    searchPhrase: "demo",
  });
  const workspaces = listWorkspacesQuery.data?.workspaces ?? [];
  const hasDemoWorkspace = workspaces.some(v => v.id === "demo");

  const handleTryDemoOpenbanking = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    profile: ServerResponseProfileEnum
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setCreateServerProgress(profile);

    const id = getDemoWorkspaceId(profile);

    createDemoWorkspace(profile, id, queryClient, getTenantQuery.data).finally(() => {
      setCreateServerProgress(null);
      navigate(`/${id}/overview`);
    });
  };

  const ksaWorkspaceEnabled = useFeature("openbanking_ksa");
  const isConnectIdEnabled = useFeature("connect_id");
  const isOBBREnabled = useFeature("obbr");

  return (
    <>
      {workspaceAppTypes
        .filter(w => isConnectIdEnabled || w.profile !== "connect_id")
        .filter(w => {
          if (!ksaWorkspaceEnabled) {
            return w.value !== "openbanking_ksa";
          }
          return true;
        })
        .filter(w => {
          if (!isOBBREnabled) {
            return w.value !== ServerProfileEnum.OpenbankingBr;
          }
          return true;
        })
        .map(w => (
          <WelcomeTypeCard
            key={w.value}
            id={`welcome-card-type-${w.value}`}
            title={w.title}
            subtitle={w.subtitle}
            avatar={getServerAvatarSquare({ profile: w.profile })}
            selectable
            selected={stepsData["defineWorkspaceType"].selected === w.value}
            onClick={() =>
              onChangeStepsData({ ...stepsData, defineWorkspaceType: { selected: w.value } })
            }
            innerButton={
              (isOPBWithDemoWorkspace(w.profile) && {
                label: (
                  <>
                    <Plus size={16} style={{ marginRight: 8 }} /> Try the Demo
                  </>
                ),
                onClick: e => handleTryDemoOpenbanking(e, w.profile),
                loading: createServerProgress === w.profile,
              }) ||
              (w.profile === ServerResponseProfileEnum.Default &&
                hasDemoWorkspace && {
                  label: (
                    <>
                      <Link size={16} style={{ marginRight: 8 }} /> Open the Demo
                    </>
                  ),
                  onClick: () => navigate("/demo/overview"),
                  loading: false,
                }) ||
              undefined
            }
          />
        ))}

      <div
        style={{
          position: "fixed",
          padding: "0 32px",
          marginLeft: actionsLeftMargin,
          height: 80,
          background: "#fff",
          borderTop: "1px solid #ECECEC",
          display: "flex",
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="large"
          id="next-btn"
          variant="contained"
          disabled={!stepsData["defineWorkspaceType"].selected}
          color="primary"
          onClick={onNext}
        >
          Next
        </Button>
      </div>
    </>
  );
}
