import React from "react";
import { useLocation } from "react-router";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { Box, Feather, Lock, Mail } from "react-feather";
import { makeStyles } from "tss-react/mui";

import AdministratorsIcon from "../../../common/components/icons/AdministratorsIcon";
import EnforceIcon from "../../../common/components/icons/EnforceIcon";
import IdentityPoolIcon from "../../../common/components/icons/IdentityPoolIcon";
import OrganizationsIcon from "../../../common/components/icons/OrganizationsIcon";
import PermissionSystemIcon from "../../../common/components/icons/PermissionSystemIcon";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import {
  useCheckPoolPermissions,
  useGetPoolWithoutNotification,
} from "../../services/adminIdentityPoolsQuery";
import { useCheckTenantPermissions } from "../../services/adminTenantsQuery";
import useWorkspacesSeqOrCursor from "../common/EnhancedTableAsync/useWorkspacesSeqOrCursor";
import { isWorkspacesItemVisible } from "../workspaceDirectory/TenantView";
import { BUILD_IN_ADMIN_POOL_ID } from "../workspaceDirectory/administrator/AdministratorManagement";
import BackToWorkspaceNavItem from "./BackToWorkspaceNavItem";
import { NavDrawerContentProps } from "./NavDrawer";
import NavItem from "./NavItem";
import NavSection from "./NavSection";
import NavSectionItem from "./NavSectionItem";
import { useSections } from "./useSections";

const useStyles = makeStyles()(() => ({
  listPadding: {
    padding: 0,
    overflow: "auto",
  },
}));

const initSections = {
  appearance: {
    isActive: pathname => pathname.startsWith(`/appearance/`),
  },
  administrators: {
    isActive: pathname => pathname.startsWith(`/administrators/`),
  },
};

export default function TenantNavDrawerContent({
  isOpen,
  onItemClick,
  onSectionClick,
}: NavDrawerContentProps) {
  const { classes } = useStyles();

  const location = useLocation();
  const pathname = location.pathname;

  const withPermissions = useFeature("with_permissions");
  const isOrganizationsEnabled = useFeature("organizations");

  const checkPoolPermissions = useCheckPoolPermissions(BUILD_IN_ADMIN_POOL_ID);
  const buildInAdminPoolQuery = useGetPoolWithoutNotification(BUILD_IN_ADMIN_POOL_ID, {
    enabled: !!checkPoolPermissions.data?.get_identity_pool,
  });

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const listWorkspacesQuery = useWorkspacesSeqOrCursor();

  const { sections, sectionClick } = useSections(initSections, isOpen, onSectionClick);

  return (
    <>
      <List classes={{ padding: classes.listPadding }}>
        <BackToWorkspaceNavItem isOpen={isOpen} />

        <Divider />

        {isWorkspacesItemVisible(
          checkTenantPermissionsQuery.data || {},
          listWorkspacesQuery.totalData ?? []
        ) && (
          <NavItem
            text="Workspaces"
            to="/workspace-directory"
            id="workspace-directory-sidenav-item"
            icon={Box}
            onClick={onItemClick}
          />
        )}

        {isOrganizationsEnabled && (
          <NavItem
            text="Organizations"
            to="/organization-directory"
            id="organization-directory-sidenav-item"
            icon={OrganizationsIcon}
            hasTenantPermission="list_organizations"
            onClick={onItemClick}
          />
        )}

        <NavItem
          text="Identity Pools"
          to="/identity-pools/pools"
          isActive={pathname.startsWith("/identity-pools")}
          id="identity-pools-sidenav-item"
          icon={IdentityPoolIcon}
          hasTenantPermission="list_identity_pools"
          onClick={onItemClick}
        />

        {withPermissions && (
          <NavItem
            text="Permission Systems"
            to="/permissions"
            isActive={pathname.startsWith("/permissions")}
            id="permissions-sidenav-item"
            icon={PermissionSystemIcon}
            hasTenantPermission="read_permission_systems"
            onClick={onItemClick}
          />
        )}

        <NavItem
          text="MFA Settings"
          to="/mfa-settings"
          id="mfa-settings-sidenav-item"
          icon={Lock}
          hasTenantPermission="read_mfa_methods"
          onClick={onItemClick}
        />

        <NavItem
          text="Message Providers"
          to="/message-providers/emails"
          id="message-providers-sidenav-item"
          icon={Mail}
          hasTenantPermission="read_mfa_methods"
          isActive={pathname.startsWith("/message-providers")}
          onClick={onItemClick}
        />

        <NavItem
          text="Brute-force Protection"
          to="/brute-force-protection"
          id="brute-force-protection-sidenav-item"
          icon={EnforceIcon}
          hasTenantPermission="read_brute_force_protection"
          onClick={onItemClick}
        />

        <NavSection
          id="appearance"
          label="Appearance"
          isActive={initSections["appearance"].isActive(pathname)}
          isOpen={sections["appearance"].isOpen}
          icon={Feather}
          hasTenantPermission="read_themes"
          onClick={sectionClick}
          isDrawerOpen={isOpen}
        >
          <NavSectionItem
            id="appearance-branding"
            text="Branding"
            to="/appearance/branding"
            onClick={onItemClick}
          />
          <NavSectionItem
            id="appreance-themes"
            text="Themes"
            to="/appearance/themes"
            onClick={onItemClick}
          />
        </NavSection>

        {buildInAdminPoolQuery.isSuccess && (
          <NavSection
            id="administrators"
            label="Account"
            isActive={initSections["administrators"].isActive(pathname)}
            isOpen={sections["administrators"].isOpen}
            icon={AdministratorsIcon}
            hasTenantPermission="read_themes"
            onClick={sectionClick}
            isDrawerOpen={isOpen}
          >
            <NavSectionItem
              id="administrators-users"
              text="Administrators"
              to="/administrators/users"
              onClick={onItemClick}
            />
            <NavSectionItem
              id="administrators-sign-in"
              text="Sign-in and SSO"
              to="/administrators/sign-in"
              onClick={onItemClick}
            />
            {checkTenantPermissionsQuery.data?.get_tenant && (
              <NavSectionItem
                id="administrators-early-access-features"
                text="Early Access Features"
                to="/administrators/early-access-features"
                onClick={onItemClick}
              />
            )}
          </NavSection>
        )}
      </List>
    </>
  );
}
