import React, { useState } from "react";
import { useNavigate } from "react-router";

import Button from "@mui/material/Button";

import {
  BaseUserWithData,
  BaseUserWithDataStatusEnum,
  UserIdentifier,
  UserIdentifierTypeEnum,
  UserWithData,
  UserWithDataStatusEnum,
} from "@cloudentity/acp-identity";

import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import { usePoolRootUrl } from "../../../utils";
import IdentityPoolUserDeleteDialog from "./IdentityPoolUserDeleteDialog";

interface Props {
  poolId: string;
  user: UserWithData | BaseUserWithData;
  identifiers: UserIdentifier[];
  onRedirect?: () => void;
  onDeleteUser: (
    id: string,
    poolId: string
  ) => Promise<void | { status: UserWithDataStatusEnum | BaseUserWithDataStatusEnum }>;
}

export default function IdentityPoolUserSchemasTabDeleteButton({
  poolId,
  user,
  identifiers,
  onRedirect,
  onDeleteUser,
}: Props) {
  const [removeProgress, setRemoveProgress] = useState(false);
  const [removeDialog, setRemoveDialog] = useState(false);
  const { rootUrl } = usePoolRootUrl();

  const navigate = useNavigate();

  const handleRemove = () => {
    setRemoveProgress(true);
    onDeleteUser(user?.id!, poolId)
      .then(() => notifySuccess("User successfully deleted"))
      .then(() => {
        if (onRedirect) {
          onRedirect();
          return;
        }
        navigate(`${rootUrl}/${poolId}/users`);
      })
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to delete user"))
      .finally(() => {
        setRemoveProgress(false);
        setRemoveDialog(false);
      });
  };

  const identifierToShow = identifiers.find(
    identifier => identifier.type !== UserIdentifierTypeEnum.Federated
  );

  return (
    <>
      <Button
        variant="text"
        id="delete-user-button"
        color="error"
        onClick={() => setRemoveDialog(true)}
        size="large"
      >
        Delete User
      </Button>

      {removeDialog && (
        <IdentityPoolUserDeleteDialog
          onCancel={() => setRemoveDialog(false)}
          onConfirm={handleRemove}
          progress={removeProgress}
          identifier={identifierToShow?.identifier ?? null}
        />
      )}
    </>
  );
}
