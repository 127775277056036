import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { PoolResponse, Pools } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../common/api/paths";
import ConfirmationDialog from "../../../../../common/components/ConfirmationDialog";
import Progress from "../../../../../common/components/Progress";
import RouterLink from "../../../../../common/components/RouterLink";
import { notifyErrorOrDefaultTo } from "../../../../../common/components/notifications/notificationService";
import {
  getIDPQueryKey,
  listIDPsForIdentityPoolQueryKey,
  listIDPsQueryKey,
  useListIDPsForIdentityPool,
} from "../../../../services/adminIDPsQuery";
import identityPoolsApi from "../../../../services/adminIdentityPoolsApi";
import { listPoolsQueryKey, useListPools } from "../../../../services/adminIdentityPoolsQuery";
import { useListSchemas } from "../../../../services/adminIdentitySchemasQuery";
import { useCheckTenantPermissions } from "../../../../services/adminTenantsQuery";
import useItemsWithQuery from "../../../common/EnhancedTableAsync/useItemsWithQuery";
import IdentityPoolsCreate from "../identityPoolCreate/IdentityPoolsCreate";
import IdentityPoolsList from "./IdentityPoolsList";

export default function IdentityPoolsGrid() {
  const [createDialog, setCreateDialog] = useState(false);
  const [progress, setProgress] = useState(false);
  const [removeDialog, setRemoveDialog] = useState<PoolResponse>();
  const tenantId = getTenantId();

  const schemasQuery = useListSchemas(tenantId);
  const listIdentityPoolIDPsQuery = useListIDPsForIdentityPool(
    { ipID: removeDialog?.id! },
    { enabled: !!removeDialog }
  );

  const schemas = schemasQuery.data?.schemas ?? [];

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const hasManagePermission = checkTenantPermissionsQuery.data?.create_identity_pool;

  const queryClient = useQueryClient();

  const data = useItemsWithQuery<PoolResponse, Pools>({
    id: "pools",
    idGetter: pool => pool.id,
    getArray: (queryData, params) => {
      const data = queryData?.pools ?? [];
      return {
        data: data.slice(0, params.limit),
        nextPageAvailable: data.length > params.limit,
      };
    },
    getTotalItemsQueryKey: params => [
      ...listPoolsQueryKey(tenantId, params.limit + 1),
      {
        searchPhrase: params.searchText || undefined,
        sort: params.sort,
        order: params.order,
      },
    ],
    useQueryFn: (page, params) =>
      useListPools(
        {
          tid: tenantId,
          searchPhrase: params.searchText || undefined,
          sort: params.sort,
          order: params.order,
          limit: params.limit + 1,
          beforePoolId: undefined,
          afterPoolId: page.afterItemId,
        },
        { keepPreviousData: true, enabled: true }
      ),
    initialSort: "name",
  });

  const handleRemove = (id: string) => {
    setProgress(true);
    identityPoolsApi
      .deletePool({ ipID: id, withIdp: true })
      .then(() => queryClient.invalidateQueries({ queryKey: listPoolsQueryKey(tenantId) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(tenantId) }))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(tenantId) })
      )
      .then(() =>
        Promise.all(
          (listIdentityPoolIDPsQuery.data?.idps || []).map(idp =>
            queryClient.invalidateQueries({ queryKey: getIDPQueryKey(idp.id!) })
          )
        )
      )
      .then(() => setRemoveDialog(undefined))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to delete pool"))
      .finally(() => setProgress(false));
  };

  return schemasQuery.isLoading ? (
    <Progress />
  ) : (
    <>
      <IdentityPoolsList
        schemas={schemas}
        onCreate={hasManagePermission ? () => setCreateDialog(true) : undefined}
        onRemove={hasManagePermission ? pool => setRemoveDialog(pool) : undefined}
        data={{ ...data, data: data.data.filter(p => !p.system) }}
      />

      {createDialog && (
        <IdentityPoolsCreate
          onCreated={() => setCreateDialog(false)}
          onClose={() => setCreateDialog(false)}
        />
      )}

      {removeDialog && (
        <ConfirmationDialog
          title="Delete Pool"
          content={
            <>
              You're about to delete the <b>{removeDialog?.name}</b> pool.{" "}
              {(listIdentityPoolIDPsQuery.data?.idps || []).length > 0 && (
                <>
                  All connected IDPs will be deleted:
                  <ul>
                    {(listIdentityPoolIDPsQuery.data?.idps || []).map(idp => (
                      <li>
                        <RouterLink
                          to={`/${idp.authorization_server_id}/identities/${idp.method}/${idp.id}/configuration`}
                        >
                          {idp.name}
                        </RouterLink>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              This cannot be undone. Delete anyway?
            </>
          }
          confirmText="Delete"
          progress={progress}
          onConfirm={() => handleRemove(removeDialog.id ?? "")}
          onCancel={() => setRemoveDialog(undefined)}
        />
      )}
    </>
  );
}
