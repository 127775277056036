import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import FormInputLabel from "../../../../common/components/FormInputLabel";
import { notifyErrorOrDefaultTo } from "../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { slugify } from "./utils";

const useStyles = makeStyles()(() => ({
  prefixLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  prefixChangeButton: {
    fontSize: 12,
    minWidth: "unset",
    padding: "0 4px",
    marginLeft: 4,
  },
}));

const prefixRegexp = /^[a-z][a-z0-9_]{1,61}[a-z0-9]\/$/;

interface Props {
  onClose: () => void;
  onCreate: (data: any) => Promise<void>;
  systemNames: string[];
}

function PermissionCreate({ onClose, onCreate, systemNames }: Props) {
  const { classes } = useStyles();

  const [progress, setProgress] = useState(false);
  const [isPrefixEditable, setIsPrefixEditable] = useState(false);
  const form = useForm({ id: "permission-create", progress });
  const tenantId = getTenantId();
  const name = form.watch("name");

  useEffect(() => {
    form.setValue("prefix", slugify(`${tenantId}_${name}`) + "/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <Dialog id="permission-create-dialog" title="Create Permission System" onClose={onClose}>
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          autoFocus
          rules={{
            validate: {
              notUniq: v =>
                systemNames.map(s => s.toLowerCase()).indexOf(v.trim().toLowerCase()) === -1 ||
                "Permission system with a given name already exists",
            },
          }}
        />

        <TextField name="description" label="Description" />

        <div className={classes.prefixLabelContainer}>
          <FormInputLabel
            id="prefix-label"
            forId="permission-create-prefix-input"
            label="Prefix"
            style={{ marginBottom: 0 }}
          />
          {!isPrefixEditable && (
            <div>
              <Typography variant="textSM">Auto-generated value</Typography>
              <Button
                size="small"
                className={classes.prefixChangeButton}
                onClick={() => setIsPrefixEditable(!isPrefixEditable)}
              >
                Change
              </Button>
            </div>
          )}
        </div>
        <TextFieldRequired
          name="prefix"
          label="Prefix"
          hideLabel
          disabled={!isPrefixEditable}
          rules={{
            validate: {
              isValid: v =>
                prefixRegexp.test(v) ||
                "Prefix must start with a letter, have maximal length of 64 characters, end with a slash, consist of only lowercase letters, numbers and underscores",
            },
          }}
        />

        <FormFooter
          onCancel={onClose}
          onSubmit={data => {
            setProgress(true);
            onCreate(data)
              .catch(err => {
                if (
                  err.response?.status === 409 &&
                  err.response?.data.error === "permission system prefix must be unique"
                ) {
                  form.setError(
                    "prefix",
                    {
                      message: "Permission system with a given prefix already exists",
                    },
                    { shouldFocus: true }
                  );
                } else {
                  notifyErrorOrDefaultTo("Error occurred while trying to create permission system")(
                    err
                  );
                }
              })
              .finally(() => setProgress(false));
          }}
          submitText="Create"
        />
      </Form>
    </Dialog>
  );
}

export default PermissionCreate;
