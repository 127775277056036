import React from "react";

export default function QuickstartArticleSelfService() {
  return (
    <>
      <p>
        SecureAuth's self-service user management feature empowers end users to take control of
        their accounts through the user portal application (if enabled) or by integrating the
        management APIs directly into an organization's own application. This flexibility allows
        users to independently manage their profiles, update security settings, reset passwords, and
        control consent for connected applications. By enabling self-service, organizations can
        reduce IT administrative tasks while enhancing user satisfaction through a streamlined
        experience.
      </p>
      <p>
        Follow these steps to manage your profile, change your password, and monitor your security
        settings:
      </p>
      <ol>
        <li>
          <b>Accessing User Profile:</b>
          <ul>
            <li>Click on your name located at the top-right corner of the page.</li>
            <li>
              From the dropdown menu, select <b>User Profile</b> to open the profile management
              section.
            </li>
          </ul>
        </li>

        <li>
          <b>Updating Profile Information:</b>
          <ul>
            <li>
              In the <b>Profile</b> tab, you can update your personal details such as your family
              name and given name.
            </li>
            <li>
              Make your changes and click <b>Save</b> to apply them.
            </li>
          </ul>
        </li>

        <li>
          <b>Changing Password and Managing Sign-in Methods:</b>
          <ul>
            <li>
              Navigate to the <b>Security</b> tab.
            </li>
            <li>
              View and update your current sign-in methods, such as using an authenticator app,
              password, verification code, or passkey.
            </li>
            <li>
              Choose your preferred sign-in method and ensure it is set to <b>Active</b>.
            </li>
            <li>
              To change your password, follow the instructions provided under the password section
              to create a new, secure password.
            </li>
          </ul>
        </li>

        <li>
          <b>Managing Devices:</b>
          <ul>
            <li>
              In the <b>Security</b> tab, review the <b>Your devices</b> section, which lists all
              devices that have active or recent sessions.
            </li>
          </ul>
        </li>

        <li>
          <b>Managing Consent and Sharing:</b>
          <ul>
            <li>Go to the Privacy tab.</li>
            <li>
              View third-party applications that have account access. You can remove access to any
              applications you no longer trust or use.
            </li>
          </ul>
        </li>
      </ol>

      <p>
        These features ensure that users can maintain their profile, keep their accounts secure, and
        manage access to their data independently, enhancing both security and user satisfaction.
      </p>
    </>
  );
}
