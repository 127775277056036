import React, { useState } from "react";

import Row from "../EnhancedTableWithCollapsedRows/Row";
import { RowType } from "../EnhancedTableWithCollapsedRows/types";
import EnhancedTableAsync, { EnhancedTableAsyncProps } from "./EnhancedTableAsync";

interface Props extends Omit<EnhancedTableAsyncProps, "createRow"> {
  setMainMenu: any;
  setInnerRowMenu?: any;
  rowProgress: string;
  deleteRowProgress: string;
  innerTableHeader: string;
  onNameClick?: (data: RowType) => void;
}

export default function EnhancedTableAsyncWithCollapsedRow({
  setMainMenu,
  setInnerRowMenu,
  rowProgress,
  deleteRowProgress,
  innerTableHeader,
  onNameClick,
  ...props
}: Props) {
  const [openedRows, setOpenedRows] = useState<string[]>([]);

  return (
    <EnhancedTableAsync
      {...props}
      createRow={(row: RowType) => {
        const isOpen = openedRows.includes(row.id ?? "");
        return (
          <Row
            key={row.id}
            data={row}
            isOpen={isOpen}
            progress={rowProgress === row.id || deleteRowProgress === row.id}
            innerTableHeader={innerTableHeader}
            openedRows={openedRows}
            setOpenedRows={setOpenedRows}
            setMainMenu={setMainMenu}
            setInnerRowMenu={
              setInnerRowMenu
                ? ({ anchorEl, row: innerRow }) =>
                    setInnerRowMenu({
                      anchorEl,
                      row: innerRow,
                      parentRow: row,
                    })
                : undefined
            }
            onNameClick={onNameClick}
          />
        );
      }}
    />
  );
}
