import React, { ReactNode } from "react";

import { SelfUserWithDataV2AuthenticationMechanismsEnum } from "@cloudentity/acp-identityself";

import {
  SelfService2FAChip,
  SelfServiceActiveChip,
  SelfServiceInactiveChip,
  SelfServicePreferredChip,
} from "./SelfServiceChips";
import SelfServiceCommonAccordion from "./SelfServiceCommonAccordion";
import { FactorType, credentialTypeNameMapper } from "./utils";

interface Props {
  type: SelfUserWithDataV2AuthenticationMechanismsEnum;
  factorType?: FactorType;
  isPreferred: boolean;
  isInactive?: boolean;
  children: ReactNode;
  noDetailsPadding?: boolean;
  summaryInfo?: string;
}

export default function CredentialAccordion({
  type,
  factorType,
  isPreferred,
  isInactive,
  children,
  noDetailsPadding,
  summaryInfo,
}: Props) {
  return (
    <SelfServiceCommonAccordion
      id={`credential-item-${type}`}
      icon={credentialTypeNameMapper[type]?.icon}
      name={credentialTypeNameMapper[type]?.name}
      chips={
        <>
          {isPreferred && <SelfServicePreferredChip />}
          {factorType === FactorType["second-factor"] && <SelfService2FAChip />}
          {isInactive ? <SelfServiceInactiveChip /> : <SelfServiceActiveChip />}
        </>
      }
      noDetailsPadding={noDetailsPadding}
      summaryInfo={summaryInfo}
    >
      {children}
    </SelfServiceCommonAccordion>
  );
}
