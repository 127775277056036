import adminMfaMethodsApi from "./adminMfaMethodsApi";
import { useQueryWithTenantPermissionCheck, withQueryError } from "./queryUtils";

const GET_MFA_METHODS_QUERY = "GET_MFA_METHODS_QUERY";
const GET_MFA_METHOD_QUERY = "GET_MFA_METHOD_QUERY";

export const getMfaMethodsQueryKey = (tid: string) => [GET_MFA_METHODS_QUERY, tid];
export const getMfaMethodQueryKey = (tid: string, mfaId: string) => [
  GET_MFA_METHOD_QUERY,
  tid,
  mfaId,
];

export const useGetMfaMethods = (tid: string) =>
  useQueryWithTenantPermissionCheck(
    "read_mfa_methods",
    getMfaMethodsQueryKey(tid),
    withQueryError(async () => {
      const res = await adminMfaMethodsApi.getMfaMethods({});
      return res?.data;
    }, "Error occurred while trying to fetch MFA methods")
  );

export const useGetMfaMethod = (tid: string, mfaId: string) =>
  useQueryWithTenantPermissionCheck(
    "read_mfa_methods",
    getMfaMethodQueryKey(tid, mfaId),
    withQueryError(async () => {
      const res = await adminMfaMethodsApi.getMfaMethod({ mfaID: mfaId });
      return res?.data;
    }, "Error occurred while trying to fetch MFA method")
  );
