import React, { ReactNode } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { ChevronDown } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ size: "small" | "normal"; isActive?: boolean }>()(
  (theme, { size, isActive }) => ({
    summary: {
      background: "#FCFCFF",
      border: `1px solid ${theme.custom.greys.border}`,
      borderRadius: 4,
      padding: size === "normal" ? "22px 24px" : "8px 12px",
      color: theme.palette.secondary.light,
      alignItems: "center",
    },
    content: {
      alignItems: "center",
    },
    detailsRoot: {
      flexDirection: "column",
      border: `1px solid ${theme.custom.greys.border}`,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      borderTop: "none",
      padding: "22px 24px",
    },
    root: {
      "&:before": {
        display: "none",
      },
      marginBottom: 24,
    },
    expanded: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      ...(size === "normal"
        ? {}
        : {
            ".MuiAccordionSummary-content": {
              margin: "12px 0",
            },
          }),
    },
    expandIcon: {
      color: theme.palette.secondary.light,
      width: 22,
      height: 22,
    },
    spacing: {
      width: 12,
      height: 12,
      marginRight: 12,
      backgroundColor: isActive ? "#4CAF50" : "#ECECEC",
      borderRadius: "50%",
    },
    title: {
      fontWeight: 600,
      fontSize: 16,
      flex: 1,
    },
  })
);

interface Props {
  title: string | ReactNode;
  id: string;
  isActive?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
  size?: "small" | "normal";
}

export default function FormAccordion({
  title,
  id,
  isActive,
  style = {},
  children,
  size = "normal",
}: Props) {
  const { classes } = useStyles({ size, isActive });

  return (
    <Accordion elevation={0} variant="elevation" classes={{ root: classes.root }} style={style}>
      <AccordionSummary
        expandIcon={<ChevronDown className={classes.expandIcon} />}
        aria-controls={`${id}-form-accordion-panel`}
        id={`${id}-form-accordion-header`}
        classes={{ root: classes.summary, content: classes.content, expanded: classes.expanded }}
      >
        {isActive !== undefined && <div className={classes.spacing} />}
        <Typography className={classes.title}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.detailsRoot }}>{children}</AccordionDetails>
    </Accordion>
  );
}
