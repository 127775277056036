import React, { useState } from "react";

import Button from "@mui/material/Button";
import { useQueryClient } from "@tanstack/react-query";

import { ServerResponse } from "@cloudentity/acp-admin";
import { PoolAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../common/api/paths";
import Progress from "../../../common/components/Progress";
import {
  handleErrorWithNotify,
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import adminIDPsApi, { IdpType, withIDPDefaults } from "../../services/adminIDPsApi";
import { listIDPsForIdentityPoolQueryKey, listIDPsQueryKey } from "../../services/adminIDPsQuery";
import adminIdentityPoolsApi from "../../services/adminIdentityPoolsApi";
import { listPoolsQueryKey } from "../../services/adminIdentityPoolsQuery";
import IdentitiesConfigurationAuth0 from "../identities/IdentitiesConfigurationAuth0";
import IdentitiesConfigurationAzure from "../identities/IdentitiesConfigurationAzure";
import IdentitiesConfigurationAzureB2C from "../identities/IdentitiesConfigurationAzureB2C";
import IdentitiesConfigurationCognito from "../identities/IdentitiesConfigurationCognito";
import IdentitiesConfigurationCustom from "../identities/IdentitiesConfigurationCustom";
import IdentitiesConfigurationExternal from "../identities/IdentitiesConfigurationExternal";
import IdentitiesConfigurationGithub from "../identities/IdentitiesConfigurationGithub";
import IdentitiesConfigurationGoogle from "../identities/IdentitiesConfigurationGoogle";
import IdentitiesConfigurationEnsure from "../identities/IdentitiesConfigurationIntelliTrust";
import IdentitiesConfigurationOIDC from "../identities/IdentitiesConfigurationOIDC";
import IdentitiesConfigurationOkta from "../identities/IdentitiesConfigurationOkta";
import IdentitiesConfigurationSamlV2 from "../identities/IdentitiesConfigurationSamlV2";
import IdentitiesConfigurationStatic from "../identities/IdentitiesConfigurationStatic";
import IdentitiesCreateAddStep from "../identities/create/IdentitiesCreateAddStep";
import { addRedirectUrl, ProviderMapperType } from "../identities/identities.utils";
import IdentitiesConfigurationIdentityPool from "../identities/identityPools/IdentitiesConfigurationIdentityPool";
import IdentitiesConfigurationOrganization from "../identities/organization/IdentitiesConfigurationOrganization";
import { StepData } from "../welcome/useWorkspaceDirectoryData";

interface Props {
  actionsLeftMargin?: number;
  formContainerJustify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | undefined;
  workspaceId: string;
  stepsData: StepData;
  server: ServerResponse | undefined;
  onNext: () => void;
}

export default function WorkspaceDirectoryCreateConnectUserPoolStep({
  actionsLeftMargin = 480,
  workspaceId,
  stepsData,
  server,
  onNext,
}: Props) {
  const [connectIDPProgress, setConnectIDPProgress] = useState(false);
  const [selected, setSelected] = useState<ProviderMapperType>();

  const [view, setView] = useState("list");
  const [handleSubmit, setHandleSubmit] = useState<any>(null);
  const [errors, setErrors] = useState(null);

  const queryClient = useQueryClient();

  const redirectUrl = workspaceId ? `${server?.issuer_url}/login` : "";

  const handleConnectIdp = data => {
    setErrors(null);
    setConnectIDPProgress(true);
    return adminIDPsApi
      .createIDP({
        wid: workspaceId,
        type: selected?.body.method,
        body: withIDPDefaults(data, selected?.body.method as IdpType, server),
      })
      .then(onNext)
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(getTenantId(), workspaceId) })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(getTenantId()) })
      )
      .catch(err => {
        setErrors(err.response?.data ?? null);
        handleErrorWithNotify("Error occurred when trying to connect user pool")(err);
        return Promise.reject(err);
      })
      .finally(() => setConnectIDPProgress(false));
  };

  const handleConnectIdentityPoolIdp = data => {
    if (!data.identity_pool_id) {
      setConnectIDPProgress(true);
      return adminIdentityPoolsApi
        .createPool({
          pool: {
            name: data.name.trim(),
            tenant_id: getTenantId(),
            authentication_mechanisms: [PoolAuthenticationMechanismsEnum.Password],
            identifier_case_insensitive: true,
          },
        })
        .then(() => queryClient.invalidateQueries({ queryKey: listPoolsQueryKey(getTenantId()) }))
        .then(() => notifySuccess("Identity Pool successfully created"))
        .catch(notifyErrorOrDefaultTo("Error occurred when trying to create identity pool"))
        .finally(() => setConnectIDPProgress(false));
    }

    setErrors(null);
    setConnectIDPProgress(true);
    return adminIDPsApi
      .createIDP({
        wid: workspaceId,
        type: selected?.body.method,
        body: withIDPDefaults(data, selected?.body.method as IdpType, server),
      })
      .then(onNext)
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(getTenantId(), workspaceId) })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(getTenantId()) })
      )
      .catch(err => {
        setErrors(err.response?.data ?? null);
        handleErrorWithNotify("Error occurred when trying to connect user pool")(err);
      })
      .finally(() => setConnectIDPProgress(false));
  };

  return connectIDPProgress ? (
    <Progress isGlobalLoader />
  ) : (
    <>
      {view === "list" && (
        <IdentitiesCreateAddStep customSubmit selected={selected} setSelected={setSelected} />
      )}
      {view === "form" && selected && (
        <div style={{ paddingBottom: 32 }}>
          {selected.method === "azure" && (
            <IdentitiesConfigurationAzure
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "azureb2c" && (
            <IdentitiesConfigurationAzureB2C
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "cognito" && (
            <IdentitiesConfigurationCognito
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "github" && (
            <IdentitiesConfigurationGithub
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "oidc" && (
            <IdentitiesConfigurationOIDC
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "saml_v2" && (
            <IdentitiesConfigurationSamlV2
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              errors={errors}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
              onResetErrors={err => setErrors(err)}
            />
          )}
          {selected.method === "intelli_trust" && (
            <IdentitiesConfigurationEnsure
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "okta" && (
            <IdentitiesConfigurationOkta
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              errors={errors}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
              onResetErrors={err => setErrors(err)}
            />
          )}
          {selected.method === "auth0" && (
            <IdentitiesConfigurationAuth0
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "static" && (
            <IdentitiesConfigurationStatic
              idp={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "external" && (
            <IdentitiesConfigurationExternal
              idp={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "google" && (
            <IdentitiesConfigurationGoogle
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "cloudentity" && (
            <IdentitiesConfigurationCustom
              idp={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "custom" && (
            <IdentitiesConfigurationCustom
              idp={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
            />
          )}
          {selected.method === "identity_pool" && (
            <IdentitiesConfigurationIdentityPool
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdentityPoolIdp}
              onInit={fn => setHandleSubmit(fn)}
              workspaceId={workspaceId}
              workspaceIdForNewPool={workspaceId}
            />
          )}
          {selected.method === "organization" && (
            <IdentitiesConfigurationOrganization
              provider={addRedirectUrl(selected, redirectUrl).body}
              server={server}
              customSubmit
              onSubmit={handleConnectIdp}
              onInit={fn => setHandleSubmit(fn)}
              aid={workspaceId}
            />
          )}
        </div>
      )}

      <div
        style={{
          position: "fixed",
          padding: "0 32px",
          marginLeft: actionsLeftMargin,
          height: 80,
          background: "#fff",
          borderTop: "1px solid #ECECEC",
          display: "flex",
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {view === "list" && (
          <>
            <div />
            <Button
              size="large"
              id="next-btn"
              variant="contained"
              color="primary"
              disabled={!selected?.method}
              onClick={() => setView("form")}
            >
              Next
            </Button>
          </>
        )}
        {view === "form" && (
          <>
            <Button
              size="large"
              id="back-btn"
              variant="outlined"
              color="primary"
              disabled={connectIDPProgress}
              onClick={() => setView("list")}
            >
              Back
            </Button>
            <Button
              size="large"
              id="next-btn"
              variant="contained"
              color="primary"
              disabled={connectIDPProgress}
              onClick={handleSubmit}
            >
              Next
            </Button>
          </>
        )}
      </div>
    </>
  );
}
