import React from "react";
import { useNavigate } from "react-router";

import { Claim } from "@cloudentity/acp-admin";

import emptyLogo from "../../../../assets/images/emptyStates/workspaces-no-access.svg";
import { getTenantId } from "../../../../common/api/paths";
import EmptyState from "../../../../common/components/EmptyState";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import { useGetAuthorizationServer } from "../../../services/adminServersQuery";
import { useWorkspace } from "../../common/useWorkspace";
import { ClaimType } from "./ClaimUtils";
import VerifiedClaimsTable from "./VerifiedClaimsTable";

interface OAuthClaimsTabProps {
  claims: Claim[];
  fetching: boolean;
  highlightedItem: Claim | undefined;
  setShowCreateDialog: (value: { type: ClaimType } | undefined) => void;
  setShowEditDialog: (claim: Claim | undefined) => void;
  setRemoveConfirmDialog: (claim: Claim | undefined) => void;
  type: ClaimType;
  title: string;
  id: string;
  progressRow: string | null;
}

export default function OAuthVerifiedClaimsTab(props: OAuthClaimsTabProps) {
  const [workspace] = useWorkspace();
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);

  const verifiedClaimsEnabled = serverQuery.data?.identity_assurance?.enable_verified_claims;

  if (!verifiedClaimsEnabled) {
    return <VerifiedClaimsDisabled />;
  } else {
    if (props.claims.length === 0) {
      return <VerifiedClaimsEmptyState setShowCreateDialog={props.setShowCreateDialog} />;
    }

    return <VerifiedClaimsTable {...props} />;
  }
}

const VerifiedClaimsDisabled = () => {
  const [workspace] = useWorkspace();
  const navigate = useNavigate();

  return (
    <EmptyState
      title="Verified Claims Disabled"
      description="Verified Claims support not enabled for this workspace."
      img={emptyLogo}
      imgSize={22}
      style={{ marginTop: 80, marginBottom: 40 }}
      onSubmit={() => navigate(`/${workspace}/workspaces/oauth/identity_assurance`)}
      buttonContent="Open Settings"
    />
  );
};

const VerifiedClaimsEmptyState = ({ setShowCreateDialog }) => {
  const [workspace] = useWorkspace();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  return (
    <div>
      <EmptyState
        title="No verified claims"
        description="Define verified claims to be included in the ID Token."
        img={emptyLogo}
        imgSize={22}
        style={{ marginTop: 80, marginBottom: 40 }}
        onSubmit={
          checkWorkspacePermissionsQuery.data?.update_workspace
            ? () => setShowCreateDialog({ type: "id_token_verified_claim" })
            : undefined
        }
        buttonContent="+ Add claim"
      />
    </div>
  );
};
