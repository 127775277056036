import React from "react";

import DataArray from "@mui/icons-material/DataArray";
import { makeStyles } from "tss-react/mui";

export default function RecentActivitiesEmptyState() {
  const { classes } = useStyles();

  return (
    <div className={classes.emptyStateContainer}>
      <DataArray className={classes.icon} />
      <div className={classes.emptyStateTitle}>No new activities</div>
      <div className={classes.emptyStateDescription}>
        Your recent activities will show here when you start building
      </div>
    </div>
  );
}

const useStyles = makeStyles()(theme => ({
  icon: {
    fontSize: 48,
  },
  emptyStateContainer: {
    padding: "20px 70px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: theme.palette.secondary.light,
  },
  emptyStateTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: 16,
    marginBottom: 8,
  },
  emptyStateDescription: {
    textAlign: "center",
    fontSize: 14,
  },
}));
