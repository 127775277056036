import React from "react";

import EmptyState from "../../../../common/components/EmptyState";
import OrganizationEmptyStateIcon from "../../../../common/components/icons/OrganizationEmptyStateIcon";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";

export default function TenantOrganizationsTabEmptyState({ onCreate }) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  return (
    <EmptyState
      title="No Organization Created Yet"
      description="Create organization to delegate administration to your partners and customers, and empower them to self-manage."
      icon={OrganizationEmptyStateIcon}
      buttonContent="+ Create organization"
      onSubmit={
        checkTenantPermissionsQuery.data?.create_workspace ? () => onCreate(true) : undefined
      }
      style={{ marginTop: 80 }}
    />
  );
}
