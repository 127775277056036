import React from "react";

import RouterLink from "../../../../common/components/RouterLink";
import { useWorkspace } from "../../common/useWorkspace";
import DocLink from "./DocLink";

export default function QuickstartArticleSetUpMethods() {
  const [workspace] = useWorkspace();

  return (
    <>
      <p>
        Our platform allows you to configure and integrate various authentication methods tailored
        for modern SaaS applications, ensuring secure and seamless user access.
      </p>
      <p>
        Easily set up a range of authentication methods that align with your security needs,
        including built-in options such as traditional passwords, verification codes, and
        passwordless methods, as well as external integrations with providers like Google Workspace,
        GitHub, and Microsoft Entra ID.
      </p>
      <p>
        To configure authentication methods tailored for your security needs, follow these steps:
      </p>
      <ol>
        <li>
          Navigate to{" "}
          <RouterLink to={`/${workspace}/authentication/identities`} target="_blank" withIcon>
            <b>Authentication</b>
          </RouterLink>{" "}
          from the main menu in the admin dashboard.
        </li>
        <li>
          For using a SecureAuth built-in authentication method, select <b>Identity Pool</b> and
          click <b>Next</b> and then <b>+ Create Pool</b>.
        </li>
        <li>
          Fill in the necessary fields (Name, Description, Tag Color) and click on{" "}
          <b>Sign-In and Sign-Up</b> menu.
        </li>
        <li>
          In the <b>First-Factor</b> and <b>Second-Factor Authentication Methods</b> section, choose
          options like <b>Password, Verification Code, Authenticator app</b> and <b>Passkey</b>.
          Each method supports a unique user verification type:
          <ul>
            <li>
              <b>Password</b>: Standard login with a password.
            </li>
            <li>
              <b>Verification Code</b>: Sends a one-time code for enhanced verification via Email or
              SMS.
            </li>
            <li>
              <b>Authenticator app</b>: a mobile app that generates TOTP codes and can also receive
              push notifications and symbol-based push requests. This adds flexibility and enhances
              account security by requiring user approval in addition to the password.
            </li>
            <li>
              <b>Passkey</b>: A secure, passwordless option for user convenience. Using any FIDO2
              devices like YubiKey or Touch ID users can authenticate with a physical or biometric
              key, eliminating the need for traditional passwords.
            </li>
          </ul>
        </li>
        <li>
          <b>Additional Configuration Options:</b>
          <ul>
            <li>
              <b>Reduce 2FA Verification on Same Device</b>: Users won't be asked for 2FA again on
              the same device within a defined time frame. This can be adjusted based on
              organizational needs, and setting it to 0 seconds disables this feature entirely.
            </li>
            <li>
              <b>Level of Assurance Threshold</b>: Users will be prompted for a second
              authentication factor if the real-time level of assurance (LOA) drops below the
              required threshold. This setting helps ensure adaptive security based on risk factors,
              providing additional verification only when necessary.
            </li>
          </ul>
        </li>

        <li>
          <b>Explore More Authentication Options:</b> <br />
          For social login or external identity providers, click <b>+ Create Connection</b> and
          select from options like Microsoft Azure AD, Google, GitHub, or custom configurations.
        </li>
      </ol>
      <br />
      <p>
        Explore more on setting up authentication methods by visiting{" "}
        <DocLink href="https://docs.secureauth.com/cloudentity/en/modern-user-authentication-for-saas-applications.html#authentication-methods">
          SecureAuth Modern Use Authentication
        </DocLink>
        .
      </p>
    </>
  );
}
