import React from "react";

import Link from "@mui/material/Link";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";

import emptyImg from "../../../../../../../assets/images/emptyStates/workspace-users.svg";
import EmptyState from "../../../../../../../common/components/EmptyState";
import RouterLink from "../../../../../../../common/components/RouterLink";
import { usePoolRootUrl } from "../../../utils";
import IdentityPoolUsersAddImportUsers from "./IdentityPoolUsersAddImportUsers";

const useStyles = makeStyles()(() => ({
  description: {
    "& > a": {
      color: "inherit",
      textDecoration: "underline",
    },
  },
}));

interface Props {
  workspaceId: string;
  pool: PoolResponse;
  hasManageUserPermissions: boolean;
  isOrganizationWorkspace: boolean;
  onResetUsers: () => void;
  handleSendActivationMessage: (userId: string, serverId?: string) => void;
}

export default function IdentityPoolUsersEmptyState({
  workspaceId,
  pool,
  hasManageUserPermissions,
  isOrganizationWorkspace,
  onResetUsers,
  handleSendActivationMessage,
}: Props) {
  const { classes } = useStyles();
  const { rootUrl, mode: poolMode } = usePoolRootUrl();

  const workspaceEmptyDescription =
    (hasManageUserPermissions &&
      (isOrganizationWorkspace ? (
        "Add or import users to your organization"
      ) : (
        <div className={classes.description}>
          <RouterLink to={`${rootUrl}/${pool.id ?? ""}/signin-and-signup`}>
            Enable user self-registration
          </RouterLink>{" "}
          integrate own registration page over{" "}
          <Link
            href="https://cloudentity.com/developers/howtos/identity/exporting-and-importing-users/"
            target="_blank"
            rel="noreferrer"
          >
            APIs, migrate users
          </Link>{" "}
          from an existing provider, or create users manually.
        </div>
      ))) ||
    "";

  const tenantEmptyDescription = (
    <div className={classes.description}>
      Wait for the first users to register, integrate own registration page over{" "}
      <Link
        href="https://cloudentity.com/developers/howtos/identity/exporting-and-importing-users/"
        target="_blank"
        rel="noreferrer"
      >
        APIs, migrate users
      </Link>{" "}
      from an existing provider, or create users manually.{" "}
    </div>
  );

  return (
    <>
      <EmptyState
        style={{ marginTop: 100 }}
        img={emptyImg}
        imgSize={12}
        title={poolMode === "workspace" ? "No users created yet" : "No user has signed up yet"}
        description={poolMode === "workspace" ? workspaceEmptyDescription : tenantEmptyDescription}
        actionButton={
          hasManageUserPermissions ? (
            <IdentityPoolUsersAddImportUsers
              workspaceId={workspaceId}
              pool={pool}
              isOrganizationWorkspace={isOrganizationWorkspace}
              onResetUsers={onResetUsers}
              handleSendActivationMessage={handleSendActivationMessage}
            />
          ) : undefined
        }
      />
    </>
  );
}
