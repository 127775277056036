import React, { Fragment, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";

import { Claim } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import Switch from "../../../common/components/Switch";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { useLocalStorage } from "../../../common/utils/hooks/useLocalStorage";
import adminClaimsApi from "../../services/adminClaimsApi";
import { getClaimsQueryKey, useGetClaims } from "../../services/adminClaimsQuery";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import EnhancedTable from "../common/EnhancedTable";
import PageContainer from "../common/PageContainer";
import { useWorkspace } from "../common/useWorkspace";
import {
  fieldToTitle,
  getAllPaths,
  getPathsAndTypes,
} from "../workspaceDirectory/identityPools/schemas/schemas.utils";
import ChangeClaimsDialog from "./ChangeClaimsDialog";

const headCells = [
  {
    id: "key",
    label: "Attribute Name",
    align: "left",
    sortable: true,
  },
  {
    id: "path",
    label: "Attribute ID",
    align: "left",
    sortable: true,
  },
  {
    id: "id_token_claim_name",
    label: "ID Token Claim Name",
    align: "left",
  },
  {
    id: "access_token_claim_name",
    label: "Access Token Claim Name",
    align: "left",
  },
  {
    id: "include_in_id_tokens",
    label: "Include in ID Tokens",
    align: "left",
  },
  {
    id: "include_in_access_tokens",
    label: "Include in Access Tokens",
    align: "left",
  },
  { id: "actions", label: "", align: "right" },
];

export default function AttributeMapping() {
  const [workspace] = useWorkspace();
  const navigate = useNavigate();
  const [rowProgress, setRowProgress] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rowWithMenuOpen, setRowWithMenuOpen] = useState<null | {
    idTokenClaims: Claim[];
    accessTokenClaims: Claim[];
  }>(null);
  const [changeNamesDialogOpen, setChangeNamesDialogOpen] = useState(false);
  const [deleteClaimsConfirmation, setDeleteClaimsConfirmation] = useState<{
    type: "id_token" | "access_token";
    path: string;
    claims: Claim[];
  } | null>(null);

  const [, setNavMode] = useLocalStorage(`${workspace}_nav_mode`);
  const queryClient = useQueryClient();
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);
  const claimsQuery = useGetClaims(getTenantId(), workspace);

  const allSchemaPathsAndTypes = useMemo(
    () => getPathsAndTypes(serverQuery.data?.metadata?.schema || {}),
    [serverQuery.data?.metadata?.schema]
  );
  const workspaceClaims = (claimsQuery.data?.claims || []).filter(
    claim => claim.source_type === "workspace"
  );

  const allPaths = getAllPaths(serverQuery.data?.metadata?.schema || {});
  const claimsWithoutAttributes = workspaceClaims.filter(
    claim => !allPaths.includes(claim.source_path?.replace("metadata.", ""))
  );

  const handleCreateClaim = (type, attr) => {
    const claim = {
      authorization_server_id: workspace,
      source_type: "workspace",
      source_path: "metadata." + attr.path,
      type,
      name: (serverQuery.data?.type === "organization" ? "org." : "") + attr.path,
      scopes: [],
    };

    setRowProgress(attr.path);

    // This is about retry 10 times every 1000ms and append current loop index to claim name if claim name is still not uniq
    const createClaimFn = (claim: Claim, attempt) =>
      adminClaimsApi.createClaim({
        claim: { ...claim, name: attempt === 0 ? claim.name : claim.name + attempt },
      });

    let max = 10;
    let p = Promise.reject<unknown>();
    let t = 1000;

    function rejectDelay(reason) {
      return new Promise(function (resolve, reject) {
        setTimeout(reject.bind(null, reason), t);
      });
    }

    for (let i = 0; i < max; i++) {
      p = p
        .catch(() => createClaimFn(claim, i))
        .then(res => res)
        .catch(rejectDelay);
    }
    return p
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getClaimsQueryKey(getTenantId(), workspace) })
      )
      .then(() =>
        notifySuccess(
          `${type === "id_token" ? "ID token" : "Access token"} claim successfully created`
        )
      )
      .catch(
        notifyErrorOrDefaultTo(
          `Error occurred when trying to create ${
            type === "id_token" ? "ID token" : "Access token"
          } claim`
        )
      )
      .finally(() => setRowProgress(""));
  };

  const handleDeleteClaims = (type, path, claims) => {
    setRowProgress(path);
    return Promise.all(claims.map(c => adminClaimsApi.removeClaim({ claim: c.id })))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getClaimsQueryKey(getTenantId(), workspace) })
      )
      .then(() =>
        notifySuccess(
          `${type === "id_token" ? "ID token" : "Access token"} claim successfully deleted`
        )
      )
      .catch(notifyErrorOrDefaultTo("Claims successfully deleted"))
      .finally(() => setRowProgress(""));
  };

  return (
    <PageContainer>
      {claimsWithoutAttributes.length > 0 && (
        <Alert severity="info" style={{ marginBottom: 32 }}>
          <AlertTitle>No matched attributes</AlertTitle>
          <Typography>
            Workspace type claims that don't match custom attributes schema:
            <ul>
              {claimsWithoutAttributes.map(claim => (
                <li>{claim.name}</li>
              ))}
            </ul>
          </Typography>
        </Alert>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 24,
        }}
      >
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          Deliver workspace attributes to applications as token claims
        </Typography>
        <Button
          id="claims-configuration-btn"
          variant="text"
          onClick={() => {
            setNavMode("advanced");
            navigate(`/${workspace}/workspaces/tokens/claims`);
          }}
        >
          Claims configuration
        </Button>
      </div>
      <div>
        <EnhancedTable
          id="attribute-mapping"
          data={allSchemaPathsAndTypes}
          headCells={headCells}
          loading={claimsQuery.isLoading || serverQuery.isLoading}
          createRow={(row, index) => {
            const idTokenClaims = workspaceClaims.filter(
              claim => claim.source_path === "metadata." + row.path && claim.type === "id_token"
            );
            const accessTokenClaims = workspaceClaims.filter(
              claim => claim.source_path === "metadata." + row.path && claim.type === "access_token"
            );

            return (
              <Fragment key={row.path}>
                {index === 0 && <ServerAttributes />}
                <TableRow style={{ height: 67 }}>
                  <TableCell id={`attribute-mapping-attribute-name-${index}`}>
                    {rowProgress === row.path && (
                      <LinearProgress
                        style={{
                          position: "absolute",
                          width: "100%",
                          transform: "translate(-25px, -22px)",
                        }}
                      />
                    )}
                    {fieldToTitle(row.key)}
                  </TableCell>
                  <TableCell id={`attribute-mapping-attribute-id-${index}`}>{row.path}</TableCell>
                  <TableCell id={`attribute-mapping-id-token-claim-name-${index}`}>
                    {idTokenClaims.map(claim => claim.name || "").join(", ")}
                  </TableCell>
                  <TableCell id={`attribute-mapping-access-token-claim-name-${index}`}>
                    {accessTokenClaims.map(claim => claim.name || "").join(", ")}
                  </TableCell>
                  <TableCell id={`attribute-mapping-include-in-id-token-${index}`}>
                    <Switch
                      id={`attribute-mapping-include-in-id-token-switch-${index}`}
                      checked={idTokenClaims.length > 0}
                      disabled={!!rowProgress}
                      onClick={() =>
                        idTokenClaims.length > 0
                          ? idTokenClaims.length > 1 ||
                            (idTokenClaims.length === 1 &&
                              idTokenClaims[0].name !==
                                (serverQuery.data?.type === "organization" ? "org." : "") +
                                  row.path)
                            ? setDeleteClaimsConfirmation({
                                type: "id_token",
                                path: row.path,
                                claims: idTokenClaims,
                              })
                            : handleDeleteClaims("id_token", row.path, idTokenClaims)
                          : handleCreateClaim("id_token", row)
                      }
                    />
                  </TableCell>
                  <TableCell id={`attribute-mapping-include-in-access-token-${index}`}>
                    <Switch
                      id={`attribute-mapping-include-in-access-token-switch-${index}`}
                      checked={accessTokenClaims.length > 0}
                      disabled={!!rowProgress}
                      onClick={() =>
                        accessTokenClaims.length > 0
                          ? accessTokenClaims.length > 1 ||
                            (accessTokenClaims.length === 1 &&
                              accessTokenClaims[0].name !==
                                (serverQuery.data?.type === "organization" ? "org." : "") +
                                  row.path)
                            ? setDeleteClaimsConfirmation({
                                type: "access_token",
                                path: row.path,
                                claims: accessTokenClaims,
                              })
                            : handleDeleteClaims("access_token", row.path, accessTokenClaims)
                          : handleCreateClaim("access_token", row)
                      }
                    />
                  </TableCell>
                  <TableCell id={`attribute-mapping-menu-${index}`}>
                    {(idTokenClaims.length > 0 || accessTokenClaims.length > 0) && (
                      <IconButton
                        id={`attribute-mapping-menu-button-${index}`}
                        onClick={e => {
                          e.stopPropagation();
                          setRowWithMenuOpen({
                            idTokenClaims: idTokenClaims,
                            accessTokenClaims: accessTokenClaims,
                          });
                          setAnchorEl(e.currentTarget);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          }}
        />

        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            id="more-menu-change-claim-names"
            onClick={() => {
              setChangeNamesDialogOpen(true);
              setAnchorEl(null);
            }}
          >
            Change Claim Names
          </MenuItem>
        </Menu>

        {changeNamesDialogOpen && (
          <ChangeClaimsDialog
            idTokenClaims={rowWithMenuOpen?.idTokenClaims}
            accessTokenClaims={rowWithMenuOpen?.accessTokenClaims}
            onClose={() => setChangeNamesDialogOpen(false)}
          />
        )}

        {deleteClaimsConfirmation && (
          <ConfirmationDialog
            title="Delete Claims"
            content={
              <span>
                You're about to delete claims:
                <ul>
                  {deleteClaimsConfirmation?.claims.map(claim => (
                    <li>{claim.name}</li>
                  ))}
                </ul>
                This cannot be undone. Delete anyway?
              </span>
            }
            confirmText="Delete"
            progress={!!rowProgress}
            onCancel={() => setDeleteClaimsConfirmation(null)}
            onConfirm={() =>
              handleDeleteClaims(
                deleteClaimsConfirmation?.type,
                deleteClaimsConfirmation?.path,
                deleteClaimsConfirmation?.claims
              ).then(() => setDeleteClaimsConfirmation(null))
            }
          />
        )}
      </div>
    </PageContainer>
  );
}

const ServerAttributes = () => (
  <TableRow>
    <TableCell id="attribute-mapping-attribute-name-id">ID</TableCell>
    <TableCell id="attribute-mapping-attribute-id-id">id</TableCell>
    <TableCell id="attribute-mapping-id-token-claim-name-id">aid</TableCell>
    <TableCell id="attribute-mapping-access-token-claim-name-id">aid</TableCell>
    <TableCell id="attribute-mapping-include-in-id-token-id">
      <Switch id="attribute-mapping-include-in-id-token-switch-id" checked disabled />
    </TableCell>
    <TableCell id="attribute-mapping-include-in-access-token-id">
      <Switch id="attribute-mapping-include-in-access-token-switch-id" checked disabled />
    </TableCell>
    <TableCell />
  </TableRow>
);
