import React, { ReactNode } from "react";

import {
  AdministratorManagementStoreContextType,
  useAdministratorManagementStore,
} from "./AdministratorManagementStore";
import {
  ApplicationBuilderGlobalStoreContextType,
  useApplicationBuilderGlobalStore,
} from "./ApplicationBuilderGlobalStore";
import {
  AuditEventsGlobalStoreContextType,
  useAuditEventsGlobalStore,
} from "./AuditEventsGlobalStore";
import { AuthorizationStoreContextType, useAuthorizationStore } from "./AuthorizationStore";
import { DashboardGlobalStoreContextType, useDashboardGlobalStore } from "./DashboardGlobalStore";
import { DeletedUsersStoreContextType, useDeletedUsersStore } from "./DeletedUsersStore";
import {
  ServiceCapabilitiesGlobalStoreContextType,
  useServiceCapabilitiesGlobalStore,
} from "./ServiceCapabilitiesGlobalStore";

export type GlobalStoreContextType = {
  auditEvents: AuditEventsGlobalStoreContextType;
  serviceCapabilites: ServiceCapabilitiesGlobalStoreContextType;
  applicationBuilder: ApplicationBuilderGlobalStoreContextType;
  dashboard: DashboardGlobalStoreContextType;
  administratorManagement: AdministratorManagementStoreContextType;
  authorization: AuthorizationStoreContextType;
  deletedUsers: DeletedUsersStoreContextType;
};

export const GlobalStoreContext = React.createContext({} as GlobalStoreContextType);

export const useGlobalStoreContextValue = () => {
  const auditEvents = useAuditEventsGlobalStore();
  const serviceCapabilites = useServiceCapabilitiesGlobalStore();
  const applicationBuilder = useApplicationBuilderGlobalStore();
  const dashboard = useDashboardGlobalStore();
  const administratorManagement = useAdministratorManagementStore();
  const authorization = useAuthorizationStore();
  const deletedUsers = useDeletedUsersStore();

  return {
    auditEvents,
    serviceCapabilites,
    applicationBuilder,
    dashboard,
    administratorManagement,
    authorization,
    deletedUsers,
  };
};

export const GlobalStoreProvider = ({ children }: { children: ReactNode }) => {
  const value = useGlobalStoreContextValue();

  return <GlobalStoreContext.Provider value={value}>{children}</GlobalStoreContext.Provider>;
};

export const GlobalStoreProviderMock = ({
  children,
  value,
}: {
  children: ReactNode;
  value: Partial<GlobalStoreContextType>;
}) => {
  const v = useGlobalStoreContextValue();

  return (
    <GlobalStoreContext.Provider value={{ ...v, ...value }}>{children}</GlobalStoreContext.Provider>
  );
};
