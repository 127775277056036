import React, { useMemo, useState } from "react";

import Typography from "@mui/material/Typography";
import * as OTPAuth from "otpauth";
import { QRCodeSVG } from "qrcode.react";

import { SelfUserWithDataV2 } from "@cloudentity/acp-identityself";

import { useGetUserInfo } from "../../../admin/services/oauth2Query";
import identitySelfApi from "../../services/identitySelfApi";
import Form, { useForm } from "../../utils/forms/Form";
import FormFooter from "../../utils/forms/FormFooter";
import OTPField from "../../utils/forms/OTPField";
import Button from "../Button";
import Dialog from "../Dialog";
import { notifyErrorOrDefaultTo, notifySuccess } from "../notifications/notificationService";
import { getEmailForTotp } from "./utils";

const data = {
  oldTotp: "",
  newTotp: "",
};

interface Props {
  userData: SelfUserWithDataV2 | null;
}

export default function SelfServiceChangeTotp({ userData }: Props) {
  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);
  const userInfoQuery = useGetUserInfo();

  const email = getEmailForTotp(userInfoQuery.data, userData);

  const form = useForm({
    id: "change-totp",
    initialValues: data,
    progress,
  });

  function handleClose() {
    setDialog(false);
  }

  const totp = useMemo(() => {
    const secret = new OTPAuth.Secret({ size: 20 });
    return new OTPAuth.TOTP({
      issuer: "SecureAuth",
      label: email,
      algorithm: "SHA1",
      digits: 6,
      period: 30,
      secret,
    });
  }, [email]);

  function handleChangeTotp(newData: typeof data) {
    const delta = totp.validate({ token: newData.newTotp });

    if (delta !== null) {
      setProgress(true);
      identitySelfApi
        .changeTotpSecret({ new_totp_secret: totp.secret.base32, totp: newData.oldTotp })
        .then(handleClose)
        .then(() => notifySuccess("New authenticator app set successfully"))
        .catch(err => {
          if (
            err?.response?.data?.status_code === 401 &&
            (err?.response?.data?.error === "invalid totp code" ||
              err?.response?.data?.error === "totp code already used")
          ) {
            form.setError(
              "oldTotp",
              {
                message:
                  err?.response?.data?.error === "totp code already used"
                    ? "Provided code is already used"
                    : "Provided code is invalid",
              },
              { shouldFocus: true }
            );
          }

          return notifyErrorOrDefaultTo("Error occurred while trying to change authenticator app")(
            err
          );
        })
        .finally(() => setProgress(false));
    } else {
      form.setError(
        "newTotp",
        {
          message: "Provided code is invalid",
        },
        { shouldFocus: true }
      );
    }
  }

  return (
    <>
      <Button
        id="change-totp-button"
        onClick={() => setDialog(true)}
        variant="contained"
        size="small"
        style={{ fontSize: 12 }}
      >
        Change
      </Button>

      {dialog && (
        <Dialog onClose={handleClose} id="change-totp-dialog" title="Change Authenticator App">
          <Typography component="div" variant="textMD" style={{ marginBottom: 32 }}>
            To change your authenticator app, it's required to provide a code from the current
            authenticator app. If you're about to use the same application, rename the old entry to
            prevent from overwritting it with a newly added.
          </Typography>

          <Form form={form}>
            <OTPField
              name="oldTotp"
              label="Code from the current authenticator app"
              autoFocus
              length={6}
              rules={{
                required: "Code from the current authenticator app is required",
              }}
            />

            <Typography component="div" variant="textMD" style={{ marginBottom: 32 }}>
              Now scan the QR code in your authenticator app to add a new entry and type the new
              code below.
            </Typography>

            <div
              style={{ margin: "32px 0", textAlign: "center" }}
              id="qrcode-secret"
              data-qrcode-secret={totp.secret.base32}
            >
              <QRCodeSVG value={totp.toString()} />
            </div>

            <OTPField
              name="newTotp"
              label="Code from the new app / entry"
              autoFocus
              length={6}
              rules={{
                required: "Code from the new app / entry is required",
              }}
            />

            <FormFooter onCancel={handleClose} onSubmit={handleChangeTotp} submitText="Change" />
          </Form>
        </Dialog>
      )}
    </>
  );
}
