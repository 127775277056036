import { makeStyles } from "tss-react/mui";

export type GridMode = "small-card" | "full-card" | "table";

export const GRID_MODE_KEY = "workspace-driectory-grid-mode";

export const useWorkspaceCardStyles = makeStyles()(theme => ({
  root: {
    maxWidth: "100%",
    position: "relative",
  },
  cardTop: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  cardTopLeft: {
    display: "flex",
    maxWidth: "100%",
    width: "100%",
    cursor: "pointer",
  },
  card: {
    padding: 10,
    cursor: "default !important",
    pointerEvents: "all",
  },
  cardTopLeftName: {
    maxWidth: "calc(100% - 75px)",
    marginTop: 6,
    marginLeft: 8,
    display: "flex",
    flexDirection: "column",
    textWrap: "nowrap",
    "& > p": {
      color: "black",
      fontWeight: 600,
      lineHeight: "16px",
    },
    "& > span": {
      color: theme.palette.secondary.light,
    },
  },
  cardTopLeftType: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
  },
  cardBottomItem: {
    display: "flex",
    alignItems: "center",
    width: "50%",
  },
  cardBottomItemInactive: {
    opacity: 0.5,
  },
  cardBottomItemIcon: {
    backgroundColor: "#F2F3F4",
    width: 18,
    height: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardBottomItemText: {
    margin: "0 8px",
    color: theme.palette.secondary.light,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardActions: {
    display: "flex",
    position: "absolute",
    right: -8,
    top: -8,
  },
  iconButton: {
    padding: 8,
    marginLeft: 2,
  },
}));

export const useWorkspaceCardMenuStyles = makeStyles()(theme => ({
  menu: {
    boxShadow: theme.custom.shadows.menu,
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    display: "flex",
    color: theme.palette.primary.main,
  },
  menuItemManageDevPortals: {
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  menuItemDevPortal: {
    display: "flex",
    justifyContent: "space-between",
    "& > p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& > svg": {
      minWidth: 20,
    },
  },
  menuProgress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
