import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { UserPlus } from "react-feather";

import { GrantTenantRoleRequestRoleEnum } from "@cloudentity/acp-admin";
import { UserWithData } from "@cloudentity/acp-identity";

import Fab from "../../../../common/components/Fab";
import { useCheckPoolPermissions } from "../../../services/adminIdentityPoolsQuery";
import AdministratorUserCreate from "./AdministratorUserCreate";

interface Props {
  workspaceId: string;
  identityPoolID: string;
  onCreated?: (user: UserWithData) => void;
}

export default function AdministratorUsersCreateNew({
  workspaceId,
  identityPoolID,
  onCreated,
}: Props) {
  const [params] = useSearchParams();
  const [addUser, setAddUser] = useState(!!params.get("invite"));

  const checkPoolPermissionsQuery = useCheckPoolPermissions(identityPoolID);

  if (!checkPoolPermissionsQuery.data?.manage_identity_pool_users) {
    return null;
  }

  const initialAdministratorRole =
    params.get("invite") !== null &&
    Object.values(GrantTenantRoleRequestRoleEnum).includes(
      params.get("invite") as GrantTenantRoleRequestRoleEnum
    )
      ? (params.get("invite") as GrantTenantRoleRequestRoleEnum)
      : GrantTenantRoleRequestRoleEnum.Member;

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Fab onClick={() => setAddUser(true)} id="table-toolbar-action-button">
          <UserPlus size={16} strokeWidth={3} style={{ marginRight: 8 }} /> Invite Admin
        </Fab>
      </div>

      {addUser && (
        <AdministratorUserCreate
          workspaceId={workspaceId}
          identityPoolID={identityPoolID}
          role={initialAdministratorRole}
          onCreated={(user, addAnotherUser) => {
            onCreated && onCreated(user);
            setAddUser(addAnotherUser);
          }}
          onClose={() => {
            setAddUser(false);
          }}
        />
      )}
    </>
  );
}
