import React from "react";

import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ExternalLink } from "react-feather";
import { makeStyles } from "tss-react/mui";

import {
  ServerResponse,
  WorkspaceResponse,
  WorkspaceResponseTypeEnum,
} from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import { useGetAuthorizationServer } from "../../../services/adminServersQuery";
import { MainMenuType } from "./DeveloperPortalsController";

const useStyles = makeStyles()(() => ({
  link: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "inherit",
  },
}));

interface Props {
  mainMenu: MainMenuType;
  handleClose: () => void;
  onSelectServer: (id: string) => void;
  setEditWorkspace: (editWorkspace: { id: string } | null) => void;
  onDelete: (id: string, name: string, type: WorkspaceResponseTypeEnum | undefined) => void;
  onBindTheme: (server: WorkspaceResponse) => void;
  onUnbindTheme: (server: WorkspaceResponse) => void;
  handleBookmarkServer: (server: ServerResponse) => void;
}

function DeveloperPortalsMenu({
  mainMenu,
  handleClose,
  onSelectServer,
  setEditWorkspace,
  onDelete,
  onBindTheme,
  onUnbindTheme,
  handleBookmarkServer,
}: Props) {
  const { classes } = useStyles();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(mainMenu?.server.id);

  const getServerQuery = useGetAuthorizationServer(getTenantId(), mainMenu?.server.id, {
    enabled: !!mainMenu,
  });
  const server = getServerQuery.data;

  return (
    <Menu
      id="row-main-menu"
      anchorEl={mainMenu?.anchorEl}
      keepMounted
      open={Boolean(mainMenu)}
      onClose={handleClose}
    >
      <MenuItem
        id={`${mainMenu?.server.id}-open-workspace`}
        onClick={() => {
          onSelectServer(mainMenu?.server.id ?? "");
          handleClose();
        }}
      >
        Open
      </MenuItem>
      {checkWorkspacePermissionsQuery.data?.update_workspace && (
        <MenuItem
          id={`${mainMenu?.server.id}-edit-workspace`}
          onClick={() => {
            setEditWorkspace({ id: mainMenu?.server.id ?? "" });
            handleClose();
          }}
        >
          Edit
        </MenuItem>
      )}
      {checkWorkspacePermissionsQuery.data?.delete_workspace && (
        <MenuItem
          id={`${mainMenu?.server.id}-delete-workspace`}
          onClick={() => {
            onDelete(mainMenu?.server.id ?? "", mainMenu?.server.name ?? "", mainMenu?.server.type);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      )}

      {(mainMenu &&
        (mainMenu.server.theme_id ? (
          <MenuItem
            id={`${mainMenu.server.id}-unbind-theme`}
            onClick={() => {
              onUnbindTheme(mainMenu.server);
              handleClose();
            }}
          >
            Unbind Theme
          </MenuItem>
        ) : (
          <MenuItem
            id={`${mainMenu.server.id}-bind-theme`}
            onClick={() => {
              onBindTheme(mainMenu.server);
              handleClose();
            }}
          >
            Bind Theme
          </MenuItem>
        ))) ||
        null}

      {server && mainMenu && checkWorkspacePermissionsQuery.data?.update_workspace && (
        <MenuItem
          id={`${mainMenu.server.id}-quick-access`}
          onClick={() => {
            if (server) {
              handleBookmarkServer(server);
              handleClose();
            }
          }}
        >
          {server.enable_quick_access ? "Remove from quick access" : "Add to quick access"}
        </MenuItem>
      )}

      <Divider style={{ margin: "8px 0" }} key="visit-divider" />
      <MenuItem disabled style={{ fontSize: 12, fontWeight: 500 }} key="visit-title">
        Visit
      </MenuItem>
      <MenuItem key="developer-portal-link" id={`${mainMenu?.server.id}-developer-portal-link`}>
        <Link
          href={(mainMenu?.server.issuer_url || "") + "/app"}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
          className={classes.link}
        >
          Developer Portal <ExternalLink size="14" style={{ marginLeft: 8 }} />
        </Link>
      </MenuItem>
    </Menu>
  );
}
export default DeveloperPortalsMenu;
