import React from "react";

import OrganizationsToolbar from "../admin/components/workspaceDirectory/organizations/OrganizationsToolbar";
import { B2B_ORGANIZATIONS_GRID_MODE_KEY } from "../admin/components/workspaceDirectory/organizations/utils";
import { useCheckTenantPermissions } from "../admin/services/adminTenantsQuery";

export default function B2BOrganizationsToolbar({
  searchText,
  gridMode,
  onCreate,
  onUpdate,
  onSetGridMode,
}) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations = checkTenantPermissionsQuery?.data?.list_organizations;

  return (
    <OrganizationsToolbar
      createButtonLabel="Create Organization"
      searchText={searchText}
      onUpdate={onUpdate}
      onCreate={
        checkTenantPermissionsQuery.data?.create_organization ? () => onCreate(true) : undefined
      }
      gridMode={canListOrganizations ? gridMode : undefined}
      setGridMode={canListOrganizations ? onSetGridMode : undefined}
      gridModeLocalStorageKey={B2B_ORGANIZATIONS_GRID_MODE_KEY}
    />
  );
}
