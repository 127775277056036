import {
  ScriptsApi,
  ScriptsApiCreateScriptRequest,
  ScriptsApiDeleteScriptRequest,
  ScriptsApiDisableScriptDebugRequest,
  ScriptsApiEnableScriptDebugRequest,
  ScriptsApiGetScriptDebugRequest,
  ScriptsApiGetScriptRequest,
  ScriptsApiListScriptDebugsRequest,
  ScriptsApiListScriptExecutionPointsRequest,
  ScriptsApiListScriptsRequest,
  ScriptsApiPatchScriptRequest,
  ScriptsApiSetScriptExecutionPointsRequest,
  ScriptsApiTestScriptRequest,
  ScriptsApiUpdateScriptRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => ScriptsApi = adminApiClazz(ScriptsApi);

const adminScriptsApi = {
  listScripts: (req: ScriptsApiListScriptsRequest) => api().listScripts(req),
  getScript: (req: ScriptsApiGetScriptRequest) => api().getScript(req),
  createScript: (req: ScriptsApiCreateScriptRequest) => api().createScript(req),
  updateScript: (req: ScriptsApiUpdateScriptRequest) => api().updateScript(req),
  deleteScript: (req: ScriptsApiDeleteScriptRequest) => api().deleteScript(req),
  patchScript: (req: ScriptsApiPatchScriptRequest) => api().patchScript(req),
  listScriptExecutionPoints: (req: ScriptsApiListScriptExecutionPointsRequest) =>
    api().listScriptExecutionPoints(req),
  setScriptExecutionPoints: (req: ScriptsApiSetScriptExecutionPointsRequest) =>
    api().setScriptExecutionPoints(req),
  testScript: (req: ScriptsApiTestScriptRequest) => api().testScript(req),
  disableScriptDebug: (req: ScriptsApiDisableScriptDebugRequest) => api().disableScriptDebug(req),
  enableScriptDebug: (req: ScriptsApiEnableScriptDebugRequest) => api().enableScriptDebug(req),
  getScriptDebug: (req: ScriptsApiGetScriptDebugRequest) => api().getScriptDebug(req),
  listScriptDebugs: (req: ScriptsApiListScriptDebugsRequest) => api().listScriptDebugs(req),
};

export default adminScriptsApi;
