import React, { useEffect, useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";

import { Server } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import FormSection from "../../../../common/components/FormSection";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import ApplicationReadOnlyInputWithCopy from "../../../../common/components/applications/ApplicationReadOnlyInputWithCopy";
import ReadOnlyInputButtonWithLabel from "../../../../common/components/applications/ReadOnlyInputButtonWithLabel";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import { validators } from "../../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import adminServersApi from "../../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  useGetAuthorizationServer,
} from "../../../services/adminServersQuery";
import { useWorkspace } from "../../common/useWorkspace";
import SSOLogoutDomains from "./SSOLogoutDomains";

export default function AuthenticationLogout() {
  const queryClient = useQueryClient();

  const [workspace] = useWorkspace();
  const tenantId = getTenantId();

  const [updateProgress, setUpdateProgress] = useState(false);
  const [logoutDomainsDialog, setLogoutDomainsDialog] = useState(false);
  const serverQuery = useGetAuthorizationServer(tenantId, workspace);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);
  const canUpdateWorkspace = !!checkWorkspacePermissionsQuery?.data?.update_workspace;

  const data = useMemo(
    () => ({
      logout_redirect_domains: [],
      post_logout_url: "",
      ...(serverQuery.data?.sso?.enabled
        ? {
            domain: "",
          }
        : {}),
      ...(serverQuery.data?.sso ?? {}),
    }),
    [serverQuery.data]
  );

  const form = useForm({
    id: "sso-settings",
    initialValues: data,
    progress: updateProgress,
    disabled: !canUpdateWorkspace,
  });

  const isSSOEnabled = serverQuery.data?.sso?.enabled!!;
  const ssoDomainValue = serverQuery.data?.sso?.domain || "";
  const logoutRedirectDomains = form.watch("logout_redirect_domains");

  const effectiveLogoutRedirectDomains = [
    ...(!!ssoDomainValue && ssoDomainValue !== "" && isSSOEnabled
      ? [`${ssoDomainValue} (SSO domain)`]
      : []),
    ...(logoutRedirectDomains || []),
  ];

  const handleSetLogoutDomains = redirectDomains => {
    form.setValue("logout_redirect_domains", redirectDomains);
  };

  const handleUpdate = updatedData => {
    const body = {
      ...serverQuery.data,
      sso: {
        ...serverQuery.data?.sso,
        ...updatedData,
      },
      tenant_id: tenantId,
    };

    setUpdateProgress(true);
    adminServersApi
      .updateAuthorizationServer({ wid: serverQuery.data?.id!, server: body as Server })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), workspace),
        })
      )
      .then(() => notifySuccess("OAuth settings saved successfully"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update OAuth Settings"))
      .finally(() => setUpdateProgress(false));
  };

  useEffect(() => {
    form.register("logout_redirect_domains");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Form form={form}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Paper style={{ padding: 32 }}>
            <FormSection id="logout-subtitle-label" title="Logout Settings" />
            <Paper style={{ background: "#F7FAFF", padding: 32, marginBottom: 32 }}>
              {effectiveLogoutRedirectDomains.length > 0 ? (
                <ApplicationReadOnlyInputWithCopy
                  id="logout-redirect-domains"
                  label="Allowed Logout Redirect Domains"
                  value={effectiveLogoutRedirectDomains}
                  progress={updateProgress}
                  onEdit={() => setLogoutDomainsDialog(true)}
                  onRemove={() => handleSetLogoutDomains([])}
                  disabled={!canUpdateWorkspace}
                />
              ) : (
                <ReadOnlyInputButtonWithLabel
                  id="logout-redirect-domains-setup-label"
                  label="Allowed Logout Redirect Domains"
                  content="Add supplementary domains for logout redirect"
                  disabled={!canUpdateWorkspace || updateProgress}
                  onClick={() => setLogoutDomainsDialog(true)}
                />
              )}
              {logoutDomainsDialog && (
                <SSOLogoutDomains
                  ssoDomain={ssoDomainValue}
                  redirectDomains={logoutRedirectDomains || []}
                  onUpdate={redirectDomains => {
                    handleSetLogoutDomains(redirectDomains);
                    setLogoutDomainsDialog(false);
                  }}
                  onCancel={() => setLogoutDomainsDialog(false)}
                  isSSOEnabled={isSSOEnabled}
                />
              )}
            </Paper>
            <TextField
              name="post_logout_url"
              label="Post-Logout Redirect URL"
              helperText={
                <span>
                  Default logout redirect target if <code>redirect_to</code> parameter is not
                  provided.
                </span>
              }
              rules={{
                validate: { validURL: validators.validURL({ label: "Post-logout URL" }) },
              }}
            />
            <FormFooter onSubmit={handleUpdate} />
          </Paper>
        </Grid>
      </Grid>
      <RouteLeavingGuard />
    </Form>
  );
}
