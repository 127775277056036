import { useLocation } from "react-router";

import { Pool } from "@cloudentity/acp-identity";

import { parseDuration } from "../../../../common/utils/forms/validation";
import { useWorkspace } from "../../common/useWorkspace";

export function usePoolRootUrl() {
  const { pathname } = useLocation();
  const [workspace] = useWorkspace();
  return pathname.includes("/identity-pools/pools")
    ? { rootUrl: "/identity-pools/pools", mode: "tenant" }
    : { rootUrl: `/${workspace}/pools`, mode: "workspace" };
}

export function addDefaultPreferredAuthMechanism(payload) {
  if (
    payload.authentication_mechanisms.length <= 1 ||
    !payload.authentication_mechanisms.includes(payload.preferred_authentication_mechanism)
  ) {
    payload.preferred_authentication_mechanism = "";
  }

  if (
    payload.second_factor_authentication_mechanisms &&
    (payload.second_factor_authentication_mechanisms.length <= 1 ||
      !payload.second_factor_authentication_mechanisms.includes(
        payload.second_factor_preferred_authentication_mechanism
      ))
  ) {
    payload.second_factor_preferred_authentication_mechanism = "";
  }

  return payload;
}

const dayInSeconds = 24 * 60 * 60; // h * min * sec

export function convertDaysToDuration(days: number | undefined) {
  return `${(days ?? 0) * 24}h0m0s`;
}

export function convertDurationToDays(duration: string | undefined) {
  const seconds = parseDuration(duration ?? "");
  return Math.floor(seconds / dayInSeconds);
}

export type SaveFormType = Pool & { password_settings: { expires_after: number } };
