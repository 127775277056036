import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getTenantId } from "../../../../common/api/paths";
import RemoveConfirmationDialog from "../../../../common/components/RemoveConfirmationDialog";
import {
  handleErrorWithNotify,
  notifyError,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { setInLocalStorage } from "../../../../common/utils/localStorage.utils";
import { listIDPsQueryKey } from "../../../services/adminIDPsQuery";
import identityPoolsApi from "../../../services/adminIdentityPoolsApi";
import {
  listWorkspacePoolsQueryKey,
  useListWorkspacePools,
} from "../../../services/adminIdentityPoolsQuery";
import adminOrganizationsApi from "../../../services/adminOrganizationsApi";
import {
  getOrganizationQueryKey,
  listOrganizationsQueryKey,
  useGetOrganization,
} from "../../../services/adminOrganizationsQuery";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import {
  getAuthorizationServerQueryKey,
  getAuthorizationServersQueryKey,
  getWorkspaceQueryKey,
  listWorkspacesQueryKey,
} from "../../../services/adminServersQuery";
import Tooltip from "../../common/Tooltip";
import { ORGANIZATION_KEY_LOCAL_STORAGE, useOrganization } from "../../common/useOrganization";

interface Props {
  orgId: string;
  onClick: () => void;
}

export default function DeleteOrganizationMenuItem({ orgId, onClick }: Props) {
  const [organizationId] = useOrganization();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(orgId);
  const getOrganizationQuery = useGetOrganization(orgId);

  const numberOfChildOrganizations = getOrganizationQuery.data?.number_of_child_organizations || 0;

  const queryClient = useQueryClient();
  const removeOrganizationFn = (id: string) =>
    adminOrganizationsApi.deleteOrganization({ wid: id });
  const removeOrganizationMutation = useMutation({ mutationFn: removeOrganizationFn, retry: 10 });

  const listWorkspacePools = useListWorkspacePools(
    { wid: orgId ?? "" },
    { enabled: showDeleteDialog }
  );
  const workspacePools = listWorkspacePools.data?.pools ?? [];

  const handleDelete = () => {
    return Promise.all(
      workspacePools.map(pool => identityPoolsApi.deletePool({ ipID: pool.id!, withIdp: true }))
    )
      .then(() => removeOrganizationMutation.mutateAsync(orgId))
      .then(() => queryClient.removeQueries({ queryKey: getOrganizationQueryKey(orgId) }))
      .then(() => queryClient.removeQueries({ queryKey: getWorkspaceQueryKey(orgId) }))
      .then(() =>
        queryClient.removeQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), orgId),
        })
      )
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() => queryClient.invalidateQueries({ queryKey: listOrganizationsQueryKey() }))
      .then(() => queryClient.invalidateQueries({ queryKey: getAuthorizationServersQueryKey }))
      .then(() => queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(getTenantId()) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacePoolsQueryKey(orgId) }))
      .then(() => {
        if (orgId === organizationId) {
          setInLocalStorage(ORGANIZATION_KEY_LOCAL_STORAGE, "");
        }
      })
      .then(() => notifySuccess("Organization successfully removed"))
      .catch(err => {
        if (
          err.response?.data?.status_code === 400 &&
          err.response?.data?.error === "resource can't be removed"
        ) {
          notifyError("Organization has identity pool assigned. Remove identity pool first.");
          return;
        }

        handleErrorWithNotify("Error occur while trying to remove organization")(err);
      });
  };

  return checkWorkspacePermissionsQuery.data?.delete_organization ? (
    <>
      <Tooltip
        key={`${orgId}-delete-organization-tooltip`}
        title={
          numberOfChildOrganizations > 0
            ? "Cannot remove an organization that has sub-organizations. Remove them first."
            : undefined
        }
      >
        <span>
          <MenuItem
            id={`${orgId}-delete-workspace`}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShowDeleteDialog(true);
              onClick();
            }}
            key="delete"
            disabled={numberOfChildOrganizations > 0}
          >
            Delete
          </MenuItem>
        </span>
      </Tooltip>

      {showDeleteDialog && (
        <RemoveConfirmationDialog
          title="Delete Organization"
          type="organization"
          name={getOrganizationQuery.data?.name ?? ""}
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={handleDelete}
          progress={removeOrganizationMutation.isPending}
        />
      )}
    </>
  ) : null;
}
