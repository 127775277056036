import {
  useListAllUserWorkspaces,
  useListAllWorkspaces,
} from "../admin/services/adminServersQuery";
import { useCheckTenantPermissions } from "../admin/services/adminTenantsQuery";

export const useAllWorkspaces = () => {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const listAllWorkspacesQuery = useListAllWorkspaces("admin,developer,system,regular", {
    enabled: !!checkTenantPermissionsQuery.data?.list_workspaces,
  });

  const listAllUserWorkspacesQuery = useListAllUserWorkspaces({
    enabled: !checkTenantPermissionsQuery.data?.list_workspaces,
  });

  return checkTenantPermissionsQuery.data?.list_workspaces
    ? listAllWorkspacesQuery
    : listAllUserWorkspacesQuery;
};
