import React from "react";

import ChipMUI from "@mui/material/Chip";
import { CheckCircle } from "react-feather";
import { makeStyles } from "tss-react/mui";

import Tooltip from "../../../admin/components/common/Tooltip";

const useStyles = makeStyles()(() => ({
  chip: {
    marginLeft: 8,
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export function SelfServicePreferredChip() {
  const { classes } = useStyles();

  return (
    <ChipMUI
      label="Preferred method"
      color="primary"
      variant="outlined"
      size="small"
      className={classes.chip}
    />
  );
}

export function SelfServiceInactiveChip() {
  const { cx, classes } = useStyles();

  return (
    <ChipMUI
      label="Inactive"
      variant="outlined"
      size="small"
      className={cx(classes.chip, "chip-inactive")}
    />
  );
}

export function SelfServiceActiveChip() {
  const { classes } = useStyles();

  return (
    <ChipMUI
      label="Active"
      variant="outlined"
      color="success"
      size="small"
      className={classes.chip}
    />
  );
}

export function SelfService2FAChip({ trust }: { trust?: boolean }) {
  const { classes } = useStyles();

  return (
    <ChipMUI
      label={trust ? "2FA Trust" : "2FA"}
      variant="outlined"
      size="small"
      color="primary"
      className={classes.chip}
    />
  );
}

export function SelfServiceCurrentSessionChip({ small }: { small?: boolean }) {
  const { classes } = useStyles();

  return (
    <Tooltip title={small && "Current session"}>
      <ChipMUI
        label={
          <div className={classes.flexContainer}>
            <CheckCircle size={14} style={!small ? { marginRight: 4 } : {}} />{" "}
            {!small && "Current session"}
          </div>
        }
        color="primary"
        variant="outlined"
        size="small"
        className={classes.chip}
      />
    </Tooltip>
  );
}
