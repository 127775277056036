import {
  ClientsApi,
  ClientsApiCreateClientRequest,
  ClientsApiDeleteClientRequest,
  ClientsApiGetClientRequest,
  ClientsApiGetClientSAMLMetadataRequest,
  ClientsApiImportSAMLMetadataFromFileRequest,
  ClientsApiImportSAMLMetadataFromTextRequest,
  ClientsApiImportSAMLMetadataFromURLRequest,
  ClientsApiListClientsRequest,
  ClientsApiParseCertificateRequest,
  ClientsApiRevokeRotatedClientSecretsRequest,
  ClientsApiRotateClientSecretRequest,
  ClientsApiUpdateClientRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => ClientsApi = adminApiClazz(ClientsApi);

const adminClientsApi = {
  getClients: (req: ClientsApiListClientsRequest) => api().listClients(req),
  getClient: (req: ClientsApiGetClientRequest) => api().getClient(req),
  createClient: (req: ClientsApiCreateClientRequest) => api().createClient(req),
  updateClient: (req: ClientsApiUpdateClientRequest) => api().updateClient(req),
  removeClient: (req: ClientsApiDeleteClientRequest) => api().deleteClient(req),
  rotateClientSecret: (req: ClientsApiRotateClientSecretRequest) => api().rotateClientSecret(req),
  revokeRotatedClientSecrets: (req: ClientsApiRevokeRotatedClientSecretsRequest) =>
    api().revokeRotatedClientSecrets(req),
  importSAMLMetadataFromFile: (req: ClientsApiImportSAMLMetadataFromFileRequest) =>
    api().importSAMLMetadataFromFile(req),
  importSAMLMetadataFromText: (req: ClientsApiImportSAMLMetadataFromTextRequest) =>
    api().importSAMLMetadataFromText(req),
  importSAMLMetadataFromURL: (req: ClientsApiImportSAMLMetadataFromURLRequest) =>
    api().importSAMLMetadataFromURL(req),
  getClientSAMLMetadata: (req: ClientsApiGetClientSAMLMetadataRequest) =>
    api().getClientSAMLMetadata(req),
  parseCertificate: (req: ClientsApiParseCertificateRequest) => api().parseCertificate(req),
};

export default adminClientsApi;
