import React from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import PageContainer from "../../../admin/components/common/PageContainer";
import PageContent from "../../../admin/components/common/PageContent";
import emptyState from "../../../assets/images/emptyStates/workspaces-no-access.svg";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 100,
  },
  image: {
    width: 72,
    height: 72,
  },
  text: {
    color: "#969799",
    fontWeight: 400,
    marginTop: 16,
  },
  subtext: {
    color: "#A4A6A7",
    marginTop: 6,
  },
}));

export default function NoAccessState() {
  const { classes } = useStyles();
  return (
    <PageContainer>
      <PageContent>
        <div className={classes.container}>
          <img src={emptyState} alt="no workspaces found" className={classes.image} />
          <div
            style={{ display: "none" }}
            id="b2b-landing-version"
            data-testid="organization-member-empty-state"
          ></div>
          <Typography className={classes.text} variant="h4">
            Welcome!
          </Typography>
          <Typography className={classes.subtext} id="empty-state-description" variant="textMD">
            There are no applications that you are entitled to access.
          </Typography>
        </div>
      </PageContent>
    </PageContainer>
  );
}
