import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Check } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { useGetEnvironment } from "../../../services/adminEnvironmentQuery";
import { buildImgId, NO_LOGO_ID, NO_LOGO_URL } from "./SetupLogo";

const useStyles = makeStyles()(theme => ({
  logosContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 16,
    marginTop: 24,
    minHeight: 100,
    width: "100%",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    width: 100,
    height: 100,
    border: "1px solid #D0D5DD",
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer",
      boxShadow: `0 0 0 1.5px ${theme.palette.secondary.dark}`,
    },
  },
}));

const appendProxyUrl = (url: string, imgProxyUrl: string) =>
  imgProxyUrl ? imgProxyUrl + url : url;

export default function LogosList({ logos, selected, onSelect }) {
  const { classes } = useStyles();
  const theme = useTheme();

  const getEnvironmentQuery = useGetEnvironment();
  const imgProxyUrl = getEnvironmentQuery.data?.image_proxy_url || "";

  const [statuses, setStatuses] = useState<
    { id: string; status: "error" | "success"; url: string }[]
  >([]);

  useEffect(() => {
    setStatuses([]);
  }, [logos]);

  const successLogosToDisplay = statuses
    .sort((a, b) => ("" + a.id).localeCompare("" + b.id))
    .filter(item => item.status === "success")
    .map(item => item.id)
    .slice(0, 4);

  const allLogosLoaded = logos.length === statuses.length;

  useEffect(() => {
    if (allLogosLoaded) {
      const firstSuccessLogo = statuses
        .filter(item => item.status === "success")
        .sort((a, b) => ("" + a.id).localeCompare("" + b.id))
        .at(0);

      if (firstSuccessLogo) {
        onSelect({
          id: firstSuccessLogo.id,
          logoUrl: firstSuccessLogo.url,
        });
      } else {
        onSelect({
          id: NO_LOGO_ID,
          logoUrl: NO_LOGO_URL,
        });
      }
    }
  }, [logos, statuses, onSelect, imgProxyUrl, allLogosLoaded]);

  return (
    <div className={classes.logosContainer}>
      {logos.map((item, index) => {
        const src = appendProxyUrl(item?.url, imgProxyUrl);
        const isSelected = selected?.logoUrl === src;

        return (
          <div
            className={classes.logoContainer}
            style={{
              boxShadow: isSelected ? `0 0 0 1.5px ${theme.palette.secondary.dark}` : "",
              display: successLogosToDisplay.includes(buildImgId(index)) ? "flex" : "none",
              justifyContent: "center",
              background:
                "repeating-conic-gradient(#F0F0F0 0% 25%, transparent 0% 50%) 50% / 16px 16px",
            }}
            key={item?.url}
            onClick={() => {
              onSelect({ id: buildImgId(index), logoUrl: src });
            }}
          >
            {isSelected && <Check style={{ position: "absolute", top: 6, right: 6 }} size={12} />}
            <img
              src={src}
              style={{
                maxWidth: 98,
                maxHeight: 98,
              }}
              id={buildImgId(index)}
              alt="logo"
              onLoad={() => {
                setStatuses(prev => [
                  ...prev,
                  { id: buildImgId(index), status: "success", url: src },
                ]);
              }}
              crossOrigin="anonymous"
              onError={() => {
                setStatuses(prev => [
                  ...prev,
                  { id: buildImgId(index), status: "error", url: src },
                ]);
              }}
            />
          </div>
        );
      })}
      <NoLogoBox selected={selected} onSelect={onSelect} />
    </div>
  );
}

const NoLogoBox = ({ selected, onSelect }) => {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <div
      className={classes.logoContainer}
      style={{
        justifyContent: "center",
        boxShadow: selected?.id === NO_LOGO_ID ? `0 0 0 1.5px ${theme.palette.secondary.dark}` : "",
      }}
      id={NO_LOGO_ID}
      onClick={() =>
        onSelect({
          id: NO_LOGO_ID,
          logoUrl: NO_LOGO_URL,
        })
      }
    >
      {selected?.id === NO_LOGO_ID && (
        <Check style={{ position: "absolute", top: 6, right: 6 }} size={12} />
      )}
      <Typography>No Logo</Typography>
    </div>
  );
};
