import {
  EnvironmentApi,
  EnvironmentApiGetEnvironmentRequest,
  EnvironmentApiGetWorkspaceEnvironmentRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => EnvironmentApi = adminApiClazz(EnvironmentApi);

const adminEnvironmentApi = {
  environment: (req: EnvironmentApiGetEnvironmentRequest) => api().getEnvironment(req),
  getWorkspaceEnvironment: (req: EnvironmentApiGetWorkspaceEnvironmentRequest) =>
    api().getWorkspaceEnvironment(req),
};

export default adminEnvironmentApi;
