import React from "react";

import CheckboxMUI, { CheckboxProps } from "@mui/material/Checkbox";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  indeterminate: {
    opacity: 0.5,
  },
}));

function Checkbox(props: CheckboxProps) {
  const { classes } = useStyles();
  return (
    <CheckboxMUI color="primary" classes={{ indeterminate: classes.indeterminate }} {...props} />
  );
}

export default Checkbox;
