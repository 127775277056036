import { AuthenticationContextAttribute, Mapping, ServerResponse } from "@cloudentity/acp-admin";

import { IdpUiModelType } from "./identities.utils";

const defaultAttrSource = (mapping: Mapping) => ({
  name: mapping.source,
  description: mapping.source,
});

const defaultAttrTarget = (mapping: Mapping): AuthenticationContextAttribute => ({
  name: mapping.target,
  description: mapping.target,
  type: mapping.type,
});

const buildRowMapping = (
  mapping: Mapping,
  idp: IdpUiModelType,
  server: ServerResponse | undefined
): RowMapping => {
  const target =
    server?.authentication_context_settings?.attributes?.find(a => a.name === mapping.target) ||
    defaultAttrTarget(mapping);
  const allowWeakDecoding = mapping.allow_weak_decoding ?? false;

  if (mapping.mode === "static") {
    const source: StaticMappingTypes = (mapping as any).static;
    return {
      static: true,
      editValue: { source, target, allowWeakDecoding },
      value: { source, target, allowWeakDecoding },
    } as const;
  }

  const source: AuthenticationContextAttribute =
    (idp.attributes || []).find(a => a.name === mapping.source) || defaultAttrSource(mapping);

  return {
    static: false,
    editValue: { source, target, allowWeakDecoding },
    value: { source, target, allowWeakDecoding },
  } as const;
};

export const buildMappingsModel = (idp: IdpUiModelType, server: ServerResponse | undefined) =>
  idp.mappings?.map(mapping => buildRowMapping(mapping, idp, server)) ?? [];

export const hasModelChanged = (mappings: RowMapping[], idp: IdpUiModelType) =>
  idp.mappings?.length !== mappings.length ||
  mappings.some(
    mapping =>
      mapping.editValue.source !== mapping.value?.source ||
      mapping.editValue.target !== mapping.value?.target ||
      mapping.editValue.allowWeakDecoding !== mapping.value?.allowWeakDecoding
  );

export const allDefined = (mappings: RowMapping[]) =>
  mappings.every(
    m =>
      (m.static
        ? m.editValue.source !== null &&
          m.editValue.source !== "" &&
          (m.editValue.target?.type !== "string_array" ||
            !Array.isArray(m.editValue.source) ||
            m.editValue.source?.length > 0)
        : !!m.editValue.source?.name) && !!m.editValue.target?.name
  );

export type StaticMappingTypes = string | boolean | string[];

export type RowMapping =
  | {
      static: false;
      editValue: {
        source: AuthenticationContextAttribute | null;
        target: AuthenticationContextAttribute | null;
        allowWeakDecoding: boolean | null;
      };
      value?: {
        source: AuthenticationContextAttribute;
        target: AuthenticationContextAttribute;
        allowWeakDecoding: boolean;
      };
    }
  | {
      static: true;
      editValue: {
        source: StaticMappingTypes | null;
        target: AuthenticationContextAttribute | null;
        allowWeakDecoding: boolean | null;
      };
      value?: {
        source: StaticMappingTypes;
        target: AuthenticationContextAttribute;
        allowWeakDecoding: boolean;
      };
    };
