import React, { useContext, useEffect } from "react";

import welcomeGraphic from "../../../assets/images/backgrounds/tour/welcome-b2b-tour.svg";
import { getServerId } from "../../../common/api/paths";
import Carousel from "../../../common/components/Carousel";
import { BasicModalStep } from "../../../common/components/guidedTours/BasicModalStep";
import { GlobalToursContext } from "../GlobalTours";
import Slide from "../components/Slide";

export const B2B_ORG_MANAGEMENT_TOUR = "b2b-portal-org-management-tour";

export enum B2BOrgManagementTourSteps {
  WelcomeModal = "WelcomeModal",
}

export default function B2BOrgWelcomeTour() {
  const tour = `${B2B_ORG_MANAGEMENT_TOUR}`;

  const globalToursContext = useContext(GlobalToursContext);
  const currentTour = globalToursContext.getCurrentTour();
  const currentStep = globalToursContext.getCurrentStep();

  const TourSteps = [{ id: B2BOrgManagementTourSteps.WelcomeModal, targets: [] }];

  useEffect(() => {
    if (getServerId() === "admin") {
      globalToursContext.register({
        id: B2B_ORG_MANAGEMENT_TOUR,
        runOnce: true,
        startOnRegister: true,
        steps: TourSteps,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentTour === tour ? (
    <>
      {currentStep?.id === B2BOrgManagementTourSteps.WelcomeModal && (
        <BasicModalStep
          ActionsComponent={false}
          ProgressComponent={false}
          dialogStyles={{ minWidth: 600, border: "none", overflow: "visible" }}
          cardStyles={{ padding: 0, border: "none", overflow: "visible" }}
        >
          <Carousel
            slides={[
              {
                id: "1",
                image: welcomeGraphic,
                content: (
                  <Slide
                    header="Welcome to the B2B Delegated Admin Portal"
                    description="Manage organizations and delegate organization-level management further"
                    buttonText="Get Started"
                    handleButtonClick={() =>
                      globalToursContext.complete(currentTour, currentStep.id)
                    }
                    tour={B2B_ORG_MANAGEMENT_TOUR}
                    hideCloseButton
                  />
                ),
              },
            ]}
          />
        </BasicModalStep>
      )}
    </>
  ) : null;
}
