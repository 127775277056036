import React from "react";
import { useNavigate, useParams } from "react-router";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Tab from "@mui/material/Tab";
import { useQueryClient } from "@tanstack/react-query";

import { Schema } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../common/api/paths";
import Breadcrumbs from "../../../../../common/components/Breadcrumbs";
import Progress from "../../../../../common/components/Progress";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../common/components/notifications/notificationService";
import adminIdentitySchemasApi from "../../../../services/adminIdentitySchemasApi";
import {
  getSchemaQueryKey,
  listSchemasQueryKey,
  useGetSchema,
} from "../../../../services/adminIdentitySchemasQuery";
import { useCheckTenantPermissions } from "../../../../services/adminTenantsQuery";
import PageContent from "../../../common/PageContent";
import PageHeader from "../../../common/PageHeader";
import IdentitySchemaConfiguration from "./IdentitySchemaConfiguration";
import IdentitySchemaEditor from "./IdentitySchemaEditor";

export default function IdentitySchema() {
  const { id = "", tab = "" } = useParams<{ id: string; tab: string }>();
  const navigate = useNavigate();

  const schemaQuery = useGetSchema(id);
  const queryClient = useQueryClient();
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const handleSaveSchema = schema => {
    adminIdentitySchemasApi
      .updateSchema({
        schID: id,
        schema: { ...schemaQuery.data, schema: JSON.parse(schema) } as Schema,
      })
      .then(() => queryClient.invalidateQueries({ queryKey: listSchemasQueryKey(getTenantId()) }))
      .then(() => queryClient.invalidateQueries({ queryKey: getSchemaQueryKey(getTenantId(), id) }))
      .then(() => notifySuccess("Schema successfully saved"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to save schema"));
  };

  const handleTabChange = (tab: string) => {
    navigate(`/identity-pools/schemas/${id}/${tab}`);
  };

  return (
    <div style={{ position: "relative" }}>
      {schemaQuery.isLoading && <Progress />}
      {!schemaQuery.isLoading && (
        <>
          <PageHeader
            title={schemaQuery.data?.name ?? ""}
            breadcrumb={<Breadcrumb label={schemaQuery.data?.name ?? "details"} />}
            tabs={[
              <Tab
                label="Configuration"
                value="configuration"
                key="schema-configuration"
                id="schema-configuration"
              />,
              <Tab label="Schema" value="schema" key="schema" id="schema" />,
            ]}
            onChangeTab={handleTabChange}
            currentTab={tab}
          />
          <PageContent>
            {schemaQuery.data?.system && (
              <Alert severity="info" style={{ marginBottom: 32 }}>
                <AlertTitle>System schema</AlertTitle>
                <p>This schema cannot be edited nor removed</p>
              </Alert>
            )}

            {tab === "configuration" && <IdentitySchemaConfiguration />}

            {tab === "schema" && (
              <IdentitySchemaEditor
                initialSchema={JSON.stringify(schemaQuery.data?.schema, null, 2)}
                readOnly={
                  !!schemaQuery.data?.system ||
                  !checkTenantPermissionsQuery.data?.create_identity_pool
                }
                progress={schemaQuery.isFetching}
                onSubmit={handleSaveSchema}
              />
            )}
          </PageContent>
        </>
      )}
    </div>
  );
}

const Breadcrumb = ({ label }: { label: string }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumbs
      items={[
        {
          key: "list",
          label: "Identity Schemas list",
          onClick: () => navigate(`/identity-pools/schemas`),
        },
        { key: "detail", label },
      ]}
    />
  );
};
