import React from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import emptyGraphic from "../../../assets/images/icons/selfService/empty-addresses.svg";

const useStyles = makeStyles()(theme => ({
  emptyContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#9EA1B7",
    minHeight: 200,
    "& img": {
      width: 64,
    },
    "& span": {
      textAlign: "center",
    },
  },
  header: {
    margin: "16px 0 8px",
    fontWeight: "bold",
    color: theme.palette.secondary.light,
  },
  text: {
    lineHeight: "16px",
  },
}));

interface Props {
  header: string;
  text: string;
}

export default function SelfServiceEmptySidePanel({ header, text }: Props) {
  const { classes } = useStyles();

  return (
    <div className={classes.emptyContainer}>
      <img src={emptyGraphic} alt="no addresses" />
      <Typography variant="textMD" className={classes.header}>
        {header}
      </Typography>
      <Typography variant="textSM" className={classes.text}>
        {text}
      </Typography>
    </div>
  );
}
