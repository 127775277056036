import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import Grid from "@mui/material/Grid";

import { useInfiniteScrollForItemsWithQuery } from "../admin/components/common/EnhancedTableAsync/useItemsWithQuery";
import useOrganizationsSeqOrCursor from "../admin/components/common/EnhancedTableAsync/useOrganizationsSeqOrCursor";
import OrganizationCard from "../admin/components/workspaceDirectory/organizations/OrganizationCard";
import { useCheckTenantPermissions } from "../admin/services/adminTenantsQuery";
import Progress from "../common/components/Progress";
import { useGetPublicEnvironment } from "../user/services/userEnvironmentQuery";
import B2BOrganizationsEmptyState from "./B2BOrganizationsEmptyState";
import B2BOrganizationsToolbar from "./B2BOrganizationsToolbar";

export default function B2BOrganizationsCards({ onMenuOpen, onCreate, onSetGridMode }) {
  const navigate = useNavigate();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations = checkTenantPermissionsQuery?.data?.list_organizations;

  const organizationsInCardsMode = useOrganizationsSeqOrCursor({
    template: false,
    enabled: true,
  });
  useInfiniteScrollForItemsWithQuery({ onLastPage: organizationsInCardsMode.onLastPage });

  const getEnvironmentQuery = useGetPublicEnvironment();

  const isEmpty =
    !organizationsInCardsMode.totalData.length &&
    !organizationsInCardsMode.areFiltersApplied &&
    !organizationsInCardsMode.searchText;

  useEffect(() => {
    if (
      organizationsInCardsMode.isSuccess &&
      isEmpty &&
      checkTenantPermissionsQuery.isSuccess &&
      !checkTenantPermissionsQuery.data?.create_organization &&
      getEnvironmentQuery.isSuccess &&
      getEnvironmentQuery.data?.self_service
    ) {
      navigate("/profile");
    }
  }, [
    isEmpty,
    organizationsInCardsMode,
    checkTenantPermissionsQuery.isSuccess,
    checkTenantPermissionsQuery.data,
    getEnvironmentQuery.isSuccess,
    getEnvironmentQuery.data,
    navigate,
  ]);

  const progress =
    organizationsInCardsMode.isLoading ||
    checkTenantPermissionsQuery.isLoading ||
    getEnvironmentQuery.isLoading;

  if (progress) {
    return <Progress />;
  }

  return (
    <>
      {isEmpty && <B2BOrganizationsEmptyState onCreate={onCreate} />}
      {!isEmpty && (
        <B2BOrganizationsToolbar
          searchText={organizationsInCardsMode.searchText}
          gridMode="cards"
          onUpdate={
            canListOrganizations
              ? text => {
                  organizationsInCardsMode.onFiltersUpdate(text, organizationsInCardsMode.filters);
                }
              : undefined
          }
          onCreate={onCreate}
          onSetGridMode={onSetGridMode}
        />
      )}
      {!isEmpty && (
        <Grid container spacing={3}>
          {organizationsInCardsMode.totalData.length ? (
            organizationsInCardsMode.totalData.map(organization => (
              <Grid item xs={12} sm={12} md={6} lg={3} key={organization.id}>
                <OrganizationCard
                  server={organization}
                  onSelectServer={() => navigate(`/organizations/${organization.id}`)}
                  onMenuOpen={onMenuOpen}
                />
              </Grid>
            ))
          ) : (
            <div style={{ color: "gray" }}>No organizations found</div>
          )}
        </Grid>
      )}
    </>
  );
}
