import React from "react";

import Paper from "@mui/material/Paper";
import { UiSchema } from "@rjsf/utils";
import { makeStyles } from "tss-react/mui";

import SchemaForm from "./SchemaForm";

const useStyles = makeStyles()(() => ({
  paper: {
    borderTop: "none",
    borderRadius: "0 0 4px 4px",
    padding: 24,
    overflowY: "auto",
    "& #root__description": {
      display: "none",
    },
  },
}));

interface Props {
  metadata: any;
  setMetadata: (payload: any) => void;
  schema: any;
  metadataUISchema: UiSchema;
  submitAttempt: boolean;
  updateProgress: boolean;
  extraErrors: any;
  resetExtraErrors: () => void;
  disabled?: boolean;
}

const IdentityPoolUserSchemasTabForm = ({
  metadata,
  setMetadata,
  schema,
  metadataUISchema,
  submitAttempt,
  updateProgress,
  extraErrors,
  resetExtraErrors,
  disabled = false,
}: Props) => {
  const { classes } = useStyles();

  return (
    <Paper className={classes.paper}>
      <SchemaForm
        formData={metadata}
        setFormData={setMetadata}
        schema={schema}
        UISchema={metadataUISchema}
        submitAttempt={submitAttempt}
        updateProgress={updateProgress}
        extraErrors={extraErrors}
        resetExtraErrors={resetExtraErrors}
        disabled={disabled}
      />
    </Paper>
  );
};

export default IdentityPoolUserSchemasTabForm;
