import { useEffect } from "react";

const checkIfOpenAccordionFits = (
  availableHeight: number,
  accordionDetailsId: string
): { fits: boolean; heightLeft: number } => {
  const contentBoundingRect = document
    .querySelector(`#${accordionDetailsId}`)
    ?.getBoundingClientRect();

  if (!contentBoundingRect) {
    return { fits: false, heightLeft: availableHeight };
  }

  const heightLeft = Math.max(0, availableHeight - contentBoundingRect.height);
  if (heightLeft > 0) {
    return { fits: true, heightLeft };
  }
  return { fits: false, heightLeft: availableHeight };
};

interface Args {
  accordions: { id: string; onOpen: () => void }[];
  getAvailableHeight: () => number;
  trigger?: any;
}

export const useOpenAccordionsWhenFit = ({ accordions, getAvailableHeight, trigger }: Args) => {
  useEffect(() => {
    if (trigger !== undefined && !trigger) return;

    let heightLeft = getAvailableHeight();

    for (const { id, onOpen } of accordions) {
      const accordion = checkIfOpenAccordionFits(heightLeft, id);
      if (!accordion.fits) {
        break;
      }

      heightLeft = accordion.heightLeft;
      onOpen();
    }
    // should be executed only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);
};
