import React from "react";
import { useNavigate } from "react-router";

import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Edit2 } from "react-feather";

import { CustomApp } from "@cloudentity/acp-admin";

import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import EnhancedTable from "../common/EnhancedTable";
import { useWorkspace } from "../common/useWorkspace";
import { customAppTypesOptions } from "./custom-applications.common";

const headCells = [
  { label: "Name", id: "name" },
  { label: "Type", id: "type" },
  { label: "URL", id: "url" },
  { label: "", id: "actions", align: "right" },
];

const ID = "customApps-table";

interface Props {
  customApps: CustomApp[];
}

export default function CustomApplicationsTable({ customApps }: Props) {
  const navigate = useNavigate();

  const [workspace] = useWorkspace();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  return (
    <>
      <EnhancedTable
        id={ID}
        data={customApps}
        headCells={headCells}
        createRow={(customApp: CustomApp, i, classes) => (
          <TableRow
            key={i}
            id={`${ID}-row-${i}`}
            onClick={() => navigate(`/${workspace}/custom-applications/${customApp.id}`)}
            hover
            className={classes.row}
          >
            <TableCell id={`${ID}-url-${i}`}>
              <div>
                <Typography id={`customApp-url-${i}`} className={classes.firstLine}>
                  {customApp.name}
                </Typography>
                <Typography
                  id={`customApp-id-${i}`}
                  variant="caption"
                  noWrap
                  className={classes.secondLine}
                >
                  id: {customApp.id}
                </Typography>
              </div>
            </TableCell>
            <TableCell id={`${ID}-type-${i}`}>
              {customAppTypesOptions.find(o => o.value === customApp.type)?.name || customApp.type}
            </TableCell>
            <TableCell id={`${ID}-url-${i}`}>{customApp.url}</TableCell>
            <TableCell id={`${ID}-action-${i}`} width="1%">
              {checkWorkspacePermissionsQuery.data?.manage_custom_apps && (
                <IconButton
                  size="large"
                  onClick={() => navigate(`/${workspace}/custom-applications/${customApp.id}`)}
                >
                  <Edit2 size="14" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        )}
      />
    </>
  );
}
