import React from "react";

import RouterLink from "../../../../common/components/RouterLink";
import { useWorkspace } from "../../common/useWorkspace";
import DocLink from "./DocLink";

export default function QuickstartArticleIntegrateApp() {
  const [workspace] = useWorkspace();

  return (
    <>
      <p>
        SecureAuth enables seamless integration of your business applications using standard
        protocols such as <b>OAuth 2.0, SAML</b>, and <b>OpenID Connect (OIDC)</b>. This platform
        supports both client and service application types, allowing you to manage user
        authentication and service consumption efficiently.
      </p>

      <ol type="a">
        <li>
          <b>Integrate with a SAML Service Provider (SP)</b>
          <ol>
            <li>
              In the SecureAuth admin dashboard, go to{" "}
              <RouterLink to={`/${workspace}/applications`} target="_blank" withIcon>
                <b>Applications</b>
              </RouterLink>{" "}
              and click on <b>+ CREATE CLIENT</b>.
            </li>
            <li>
              In the <b>Create Application</b> window, enter an application name and select{" "}
              <b>SAML Service Provider</b> as the application type.
            </li>
            <li>
              Fill in details such as <b>Name</b> and <b>Application URL</b>.
            </li>
            <li>
              Go to the <b>SAML</b> tab and upload or configure the <b>SAML Provider Metadata</b> as
              needed.
            </li>
            <li>
              Go to the <b>Endpoints</b> tab and take note of the <i>SAML metadata</i> and{" "}
              <i>SAML SSO</i> URLs for configuring the SAML application.
            </li>
            <li>
              Click <b>Save</b>, and then configure your SAML app using the information obtained
              above..{" "}
            </li>
          </ol>
        </li>

        <li>
          <b>Integrate using OpenID Connect (OIDC)</b>
          <ol>
            <li>
              In the SecureAuth admin dashboard, go to{" "}
              <RouterLink to={`/${workspace}/applications`} target="_blank" withIcon>
                <b>Applications</b>
              </RouterLink>{" "}
              and click on <b>+ CREATE CLIENT</b>.
            </li>
            <li>
              Enter a name for the application (e.g., <b>test-id token</b>) and select{" "}
              <b>Server-side Web</b> or <b>Single Page</b> as the application type, depending on
              your use case.
            </li>
            <li>
              Go to the OAuth tab and set <b>Grant Types</b> according to your application's needs
              (e.g., Authorization Code) and set <b>Response Types</b> to ID tokens.
            </li>
            <li>
              Set any additional options under <b>ID Token</b> settings, such as{" "}
              <i>Signing and Encryption Algorithms</i> if required.
            </li>
            <li>
              Save the application settings. When users authenticate, your application will now be
              able to obtain ID tokens for securely identifying users via OIDC.
            </li>
          </ol>
        </li>

        <li>
          <b>Integrate using OAuth</b>
          <ol>
            <li>
              In the SecureAuth admin dashboard, go to{" "}
              <RouterLink to={`/${workspace}/applications`} target="_blank" withIcon>
                <b>Applications</b>
              </RouterLink>{" "}
              and click on <b>+ CREATE CLIENT</b>.
            </li>
            <li>
              Enter a name for the application and select <b>Server-side Web</b> or{" "}
              <b>Single Page</b> as the application type, depending on your use case.
            </li>
            <li>
              Under <b>Grant Types</b>, select the authorization grant flow needed (e.g.,
              Authorization Code) and under <b>Response Types</b>, specify the response needed
              (e.g., token) based on your application's requirements.
            </li>
            <li>
              <b>Save</b> the configuration
            </li>
            <li>
              Select the scopes needed (e.g., Profiles, OAuth2) for your application under the{" "}
              <b>Scopes</b> tab and click <b>Save</b>. Take note of Client ID and Client Secret
              under <b>Overview</b> tab.
            </li>
            <li>
              SecureAuth will provide an access token upon successful authorization using Client ID
              and Client Secret provided earlier. The access token can be used to access protected
              resources within your integrated application or microservices.
            </li>
          </ol>
        </li>
      </ol>
      <br />
      <p>
        Visit{" "}
        <DocLink href="https://docs.secureauth.com/cloudentity/en/applications-overview.html">
          SecureAuth Applications Overview
        </DocLink>{" "}
        for more details.
      </p>
    </>
  );
}
