import React from "react";

import { OrganizationResponse } from "@cloudentity/acp-admin";

import PageContent from "../../../admin/components/common/PageContent";
import PageHeader from "../../../admin/components/common/PageHeader";
import OrganizationDetails from "./OrganizationDetails";

interface Props {
  organization: OrganizationResponse | undefined;
  isPreview?: boolean;
}

export default function Settings({ organization, isPreview }: Props) {
  return organization ? (
    <div>
      <PageHeader title="Organization Details" maxWidth={false} />
      <PageContent fullWidth={false}>
        <OrganizationDetails organization={organization} isPreview={isPreview} />
      </PageContent>
    </div>
  ) : null;
}
