import React, { useEffect } from "react";

import { UiSchema, ValidationData } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { JSONSchema7 } from "json-schema";

import { useGetPool } from "../../services/adminIdentityPoolsQuery";
import { useGetSchema } from "../../services/adminIdentitySchemasQuery";
import SchemaForm from "../workspaceDirectory/identityPools/identityPool/users/user/SchemaForm";
import {
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../workspaceDirectory/identityPools/schemas/schemas.utils";

interface Props<T> {
  payload: any;
  poolId: string;
  disabled?: boolean;
  submitAttempt?: boolean;
  onChange?: (payload: any) => void;
  onValidate?: (result: ValidationData<T>) => void;
}

export default function IdentityPoolPayloadSchemaForm<T>({
  payload,
  poolId,
  disabled,
  submitAttempt,
  onChange,
  onValidate,
}: Props<T>) {
  const getIdentityPoolQuery = useGetPool(poolId);
  const getSchemaQuery = useGetSchema(getIdentityPoolQuery.data?.payload_schema_id, {
    enabled: getIdentityPoolQuery.isSuccess,
  });

  const schemaWithMappedTitles = mapFieldNameToTitle(getSchemaQuery.data?.schema ?? {});
  const uiSchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(schemaWithMappedTitles),
  };

  useEffect(() => {
    const result = validator.validateFormData(
      payload,
      (schemaWithMappedTitles as JSONSchema7) || {}
    );

    onValidate && onValidate(result);
  }, [payload, schemaWithMappedTitles, onValidate]);

  return (
    <>
      <SchemaForm
        formData={payload}
        setFormData={p => {
          onChange && onChange(p);
        }}
        schema={schemaWithMappedTitles}
        UISchema={uiSchema}
        submitAttempt={!!submitAttempt}
        extraErrors={{}}
        resetExtraErrors={() => {}}
        disabled={disabled}
      />
    </>
  );
}
