import {
  UsersApi,
  UsersApiAddUserIdentifierRequest,
  UsersApiAddUserVerifiableAddressRequest,
  UsersApiCreateUserRequest,
  UsersApiDeleteUserIdentifierRequest,
  UsersApiDeleteUserRequest,
  UsersApiDeleteUserVerifiableAddressRequest,
  UsersApiGetUserMetadataRequest,
  UsersApiGetUserRequest,
  UsersApiListUsersRequest,
  UsersApiRequestResetPasswordRequest,
  UsersApiSendActivationMessageRequest,
  UsersApiSetPasswordStateRequest,
  UsersApiSetUserMetadataRequest,
  UsersApiUpdateUserRequest,
  UsersApiUpdateUserVerifiableAddressRequest,
} from "@cloudentity/acp-identity";

import { identityApiClazz } from "./admin-services.common";

const api: () => UsersApi = identityApiClazz(UsersApi);

const identityUsersApi = {
  listUsers: (req: UsersApiListUsersRequest) => api().listUsers(req),
  getUser: (req: UsersApiGetUserRequest) => api().getUser(req),
  createUser: (req: UsersApiCreateUserRequest) => api().createUser(req),
  updateUser: (req: UsersApiUpdateUserRequest) => api().updateUser(req),
  deleteUser: (req: UsersApiDeleteUserRequest) => api().deleteUser(req),

  getUserMetadata: (req: UsersApiGetUserMetadataRequest) => api().getUserMetadata(req),
  setUserMetadata: (req: UsersApiSetUserMetadataRequest) => api().setUserMetadata(req),

  sendActivationMessage: (req: UsersApiSendActivationMessageRequest) =>
    api().sendActivationMessage(req),
  addUserIdentifier: (req: UsersApiAddUserIdentifierRequest) => api().addUserIdentifier(req),
  deleteUserIdentifier: (req: UsersApiDeleteUserIdentifierRequest) =>
    api().deleteUserIdentifier(req),
  addUserVerifiableAddress: (req: UsersApiAddUserVerifiableAddressRequest) =>
    api().addUserVerifiableAddress(req),
  deleteUserVerifiableAddress: (req: UsersApiDeleteUserVerifiableAddressRequest) =>
    api().deleteUserVerifiableAddress(req),
  updateUserVerifiableAddress: (req: UsersApiUpdateUserVerifiableAddressRequest) =>
    api().updateUserVerifiableAddress(req),
  requestResetPassword: (req: UsersApiRequestResetPasswordRequest) =>
    api().requestResetPassword(req),
  setPasswordState: (req: UsersApiSetPasswordStateRequest) => api().setPasswordState(req),
};

export default identityUsersApi;
