import { createTheme } from "@mui/material/styles";

import { custom } from "./custom";
import { overrides } from "./overrides";
import { palette } from "./palette";
import { typography } from "./typography";

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      shadows: {
        surfaceResting: string;
        surfaceHover: string;
        menu: string;
        formInner: string;
      };
      greys: {
        textDisabled: string;
        backgroundDisabled: string;
        background: string;
        border: string;
        default: string;
        fieldBackground: string;
      };
      headerBackground: string;
      sa: {
        brand: { low: string; medium: string; hover: string; high: string; higher: string };
        green: { low: string; medium: string; high: string };
        yellow: { low: string; medium: string; high: string };
        complimentary: {
          red: { low: string; medium: string; high: string };
          green: { low: string; medium: string; high: string };
          warning: { low: string; medium: string; high: string };
        };
        greys: { g0: string; g20: string; g40: string; g60: string; g80: string; g100: string };
        neutrals: {
          n0: string;
          n10: string;
          n20: string;
          n40: string;
          n60: string;
          n80: string;
          n100: string;
        };
        dark: { dark: string; paleGrey: string; darkGrey: string };
        core: { surface: string };
        shadows: { s600: string };
      };
    };
  }
}

declare module "@mui/material/styles/createTypography" {
  interface Typography {
    label: React.CSSProperties;
    textXSM: React.CSSProperties;
    textSM: React.CSSProperties;
    textMD: React.CSSProperties;
    textLG: React.CSSProperties;
  }

  interface TypographyOptions {
    label?: React.CSSProperties;
    textXSM?: React.CSSProperties;
    textSM?: React.CSSProperties;
    textMD?: React.CSSProperties;
    textLG?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true;
    textXSM: true;
    textSM: true;
    textMD: true;
    textLG: true;
  }
}

declare module "@mui/material/styles" {
  interface ThemeOptions {
    custom: any;
  }
}

export const theme = createTheme({
  custom,
  typography,
  palette,
  components: overrides,
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const BRAND_NAME = "SecureAuth";
