import React, { useEffect, useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import { makeStyles } from "tss-react/mui";

import { GrantIdentityPoolRoleRequestRoleEnum } from "@cloudentity/acp-admin";
import { BaseUpdateUserStatusEnum, UpdateUserStatusEnum } from "@cloudentity/acp-identity";

import WorkspaceRoleSelectField from "../../../admin/components/workspaceDirectory/administrator/WorkspaceRoleSelectField";
import { useFormExtraErrors } from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/identityPoolUsers.utils";
import { getUserRolesBySubjects } from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/list/utils";
import IdentityPoolUserSidePanel from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/sidePanel/IdentityPoolUserSidePanel";
import adminB2BUsersApi from "../../../admin/services/adminB2BUsersApi";
import {
  useCheckPoolPermissions,
  useGetPool,
} from "../../../admin/services/adminIdentityPoolsQuery";
import { useListWorkspaceSchemas } from "../../../admin/services/adminIdentitySchemasQuery";
import {
  getB2BUserQueryKey,
  listUsersQueryKey,
  useGetB2BUser,
  useGetUserMetadata,
} from "../../../admin/services/adminIdentityUsersQuery";
import {
  useCheckTenantPermissions,
  useCheckWorkspacePermissions,
} from "../../../admin/services/adminPermissionsQuery";
import {
  useListIdentityPoolRoles,
  useListWorkspaceRoles,
} from "../../../admin/services/adminRolesQuery";
import { getTenantId } from "../../../common/api/paths";
import DialogFullScreen from "../../../common/components/DialogFullScreen";
import { notifySuccess } from "../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../common/utils/forms/Form";
import B2BUserEditFooter from "./B2BUserEditFooter";
import UserEditBusinessMetadata from "./UserEditBusinessMetadata";
import UserEditMetadata from "./UserEditMetadata";
import UserEditPayload from "./UserEditPayload";

const useStyles = makeStyles()(() => ({
  gridContainer: {
    maxWidth: 1440,
    height: "100%",
  },
  grid: {
    height: "100%",
    "& #root__description": {
      display: "none",
    },
  },
}));

interface Props {
  poolId: string;
  userId: string;
  workspaceId: string;
  onClose: () => void;
}
export default function B2BUserEdit({ poolId, userId, workspaceId, onClose }: Props) {
  const { classes } = useStyles();
  const tenantId = getTenantId();

  const [progress, setProgress] = useState(false);
  const [submitAttempt, setSubmitAttempt] = useState(false);

  const queryClient = useQueryClient();

  const getUserQuery = useGetB2BUser(poolId, userId);
  const getUserAdminMetadataQuery = useGetUserMetadata(poolId, userId, "admin");
  const getUserBusinessMetadataQuery = useGetUserMetadata(poolId, userId, "business");

  const listWorkspaceSchemasQuery = useListWorkspaceSchemas(tenantId, workspaceId);

  const poolQuery = useGetPool(poolId);
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const schemas = listWorkspaceSchemasQuery.data?.schemas || [];
  const pool = poolQuery.data;
  const user = getUserQuery.data;

  const checkPoolPermissionsQuery = useCheckPoolPermissions(poolId);
  const canManageUsers = !!checkPoolPermissionsQuery.data?.b2b_manage_users;

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspaceId);
  const isWorkspaceRolesVisible = !!checkWorkspacePermissionsQuery.data?.read_roles;
  const listWorkspaceRolesQuery = useListWorkspaceRoles(workspaceId, {
    enabled: isWorkspaceRolesVisible,
  });
  const listIdentityPoolRolesQuery = useListIdentityPoolRoles(poolId, {
    enabled: isWorkspaceRolesVisible,
  });
  const userRolesBySubjects = useMemo(
    () =>
      getUserRolesBySubjects(
        user,
        [],
        listWorkspaceRolesQuery.data?.subjects,
        listIdentityPoolRolesQuery.data?.subjects
      ),
    [user, listWorkspaceRolesQuery.data, listIdentityPoolRolesQuery.data]
  );

  const initialRoles = useMemo(
    () => [
      ...userRolesBySubjects.workspace.map(r => r),
      ...(userRolesBySubjects.identityPool.includes(
        GrantIdentityPoolRoleRequestRoleEnum.UserManager
      )
        ? ["team_manager"]
        : []),
    ],
    [userRolesBySubjects]
  );

  const data = useMemo(() => ({ roles: initialRoles }), [initialRoles]);

  const form = useForm({
    id: "identity-pool-edit-user",
    initialValues: data,
    progress,
    noManagePermission: !canManageUsers,
  });

  const roles = form.watch("roles");

  const { extraErrors, setError, resetExtraErrors } = useFormExtraErrors();

  const payloadSchema = schemas.find(s => s.id === user?.payload_schema_id)?.schema || null;
  const metadataSchema =
    schemas.find(s => s.id === getUserAdminMetadataQuery.data?.schema_id)?.schema || null;
  const businessMetadataSchema =
    schemas.find(s => s.id === getUserBusinessMetadataQuery.data?.schema_id)?.schema || null;

  const [payload, setPayload] = useState(user?.payload || {});
  const [metadata, setMetadata] = useState(getUserAdminMetadataQuery.data?.metadata || {});
  const [businessMetadata, setBusinessMetadata] = useState(
    getUserBusinessMetadataQuery.data?.metadata || {}
  );

  const handleStatusUpdate = (status: UpdateUserStatusEnum | BaseUpdateUserStatusEnum) => {
    setProgress(true);

    adminB2BUsersApi
      .updateB2BUser({
        ipID: poolId,
        userID: userId,
        updateUser: { ...user, status: status as BaseUpdateUserStatusEnum },
      })
      .then(res => queryClient.setQueryData(getB2BUserQueryKey(tenantId, userId), res.data))
      .then(() => queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, poolId) }))
      .then(() => notifySuccess("User data successfully updated"))
      .catch(setError)
      .finally(() => setProgress(false));
  };

  useEffect(() => {
    setPayload(user?.payload || {});
  }, [user?.payload]);

  useEffect(() => {
    setMetadata(getUserAdminMetadataQuery.data?.metadata || {});
  }, [getUserAdminMetadataQuery.data?.metadata]);

  useEffect(() => {
    setBusinessMetadata(getUserBusinessMetadataQuery.data?.metadata || {});
  }, [getUserBusinessMetadataQuery.data?.metadata]);

  const isLoading =
    progress ||
    getUserQuery.isFetching ||
    poolQuery.isFetching ||
    listWorkspaceSchemasQuery.isFetching ||
    checkTenantPermissionsQuery.isFetching;

  const workspaceRolesChanged = (isWorkspaceRolesVisible && !isEqual(roles, initialRoles)) || false;

  return (
    <DialogFullScreen
      id="pool-user-dialog"
      onCancel={onClose}
      title="User Profile"
      isLoading={isLoading}
    >
      <Form form={form} noFormTag>
        {pool && user && (
          <Grid container spacing={4} className={classes.gridContainer}>
            <Grid item md={7} sm={12} className={classes.grid}>
              <Paper style={{ marginBottom: 32 }}>
                <div style={!canManageUsers ? { marginBottom: 16 } : {}}>
                  <UserEditPayload
                    user={user}
                    payloadSchema={payloadSchema}
                    progress={progress}
                    submitAttempt={submitAttempt}
                    extraErrors={extraErrors}
                    resetExtraErrors={resetExtraErrors}
                    disabled={!canManageUsers}
                    payload={payload}
                    setPayload={setPayload}
                  />
                </div>

                {!!checkPoolPermissionsQuery.data?.b2b_read_admin_metadata && (
                  <div style={{ marginBottom: 16 }}>
                    <UserEditMetadata
                      schemaId={getUserAdminMetadataQuery.data?.schema_id}
                      schemas={schemas}
                      progress={progress}
                      submitAttempt={submitAttempt}
                      extraErrors={extraErrors}
                      resetExtraErrors={resetExtraErrors}
                      disabled={!checkPoolPermissionsQuery.data?.b2b_manage_admin_metadata}
                      metadata={metadata}
                      setMetadata={setMetadata}
                    />
                  </div>
                )}

                {!!checkPoolPermissionsQuery.data?.b2b_read_business_metadata && (
                  <div style={{ marginBottom: 16 }}>
                    <UserEditBusinessMetadata
                      schemaId={getUserBusinessMetadataQuery.data?.schema_id}
                      schemas={schemas}
                      progress={progress}
                      submitAttempt={submitAttempt}
                      extraErrors={extraErrors}
                      resetExtraErrors={resetExtraErrors}
                      disabled={!checkPoolPermissionsQuery.data?.b2b_manage_business_metadata}
                      metadata={businessMetadata}
                      setMetadata={setBusinessMetadata}
                    />
                  </div>
                )}

                {isWorkspaceRolesVisible && (
                  <div style={{ marginTop: 16, padding: "0 32px" }}>
                    <WorkspaceRoleSelectField
                      user={user}
                      wid={workspaceId}
                      workspaceSubjects={listWorkspaceRolesQuery.data?.subjects}
                      disabled={!canManageUsers}
                      optional
                    />
                  </div>
                )}
              </Paper>

              <B2BUserEditFooter
                user={user}
                workspaceId={workspaceId}
                poolId={poolId}
                data={{ payload, metadata, businessMetadata }}
                submitAttempt={submitAttempt}
                workspaceRolesChanged={workspaceRolesChanged}
                canManageUsers={canManageUsers}
                resetExtraErrors={resetExtraErrors}
                setProgress={setProgress}
                setSubmitAttempt={setSubmitAttempt}
                setError={setError}
                userRolesBySubjects={userRolesBySubjects}
                onClose={onClose}
                schemas={{
                  payloadSchema: payloadSchema,
                  metadataSchema: metadataSchema,
                  businessMetadataSchema: businessMetadataSchema,
                }}
              />
            </Grid>
            <Grid item md={5} sm={12}>
              <IdentityPoolUserSidePanel
                workspaceId={workspaceId}
                user={user}
                progress={progress}
                onStatusChange={
                  !!checkPoolPermissionsQuery.data?.b2b_manage_users
                    ? handleStatusUpdate
                    : undefined
                }
                poolId={poolId}
                onlyInfo
              />
            </Grid>
          </Grid>
        )}
      </Form>
    </DialogFullScreen>
  );
}
