import {
  ThemesApi,
  ThemesApiCreateThemeRequest,
  ThemesApiDeleteThemeRequest,
  ThemesApiGetThemeRequest,
  ThemesApiUpdateThemeRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => ThemesApi = adminApiClazz(ThemesApi);

const adminThemesApi = {
  listThemes: () => api().listThemes(),
  createTheme: (req: ThemesApiCreateThemeRequest) => api().createTheme(req),
  getTheme: (req: ThemesApiGetThemeRequest) => api().getTheme(req),
  updateTheme: (req: ThemesApiUpdateThemeRequest) => api().updateTheme(req),
  deleteTheme: (req: ThemesApiDeleteThemeRequest) => api().deleteTheme(req),
};

export default adminThemesApi;
