import React, { ReactNode } from "react";

import { makeStyles } from "tss-react/mui";

import RouterLink from "../../../common/components/RouterLink";
import { timeDiff } from "./utils";

interface ActivityComponentProps {
  icon: ReactNode;
  message: string | undefined;
  date?: string;
  children: ReactNode;
}

export default function ActivityComponent({
  icon,
  message,
  date,
  children,
}: ActivityComponentProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.listItem}>
      <div style={{ position: "relative", top: 4 }}>{icon}</div>

      <div style={{ display: "flex", flexDirection: "column", flex: 1, overflow: "hidden" }}>
        {children}
        <span className={classes.message}>{message}</span>
      </div>

      {date && (
        <div className={classes.time}>
          {timeDiff(Math.round(Date.now() / 1000), new Date(date as string).getTime() / 1000)}
        </div>
      )}
    </div>
  );
}

interface ActivityLinkProps {
  label: string;
  to: string;
  onClick?: () => void;
}
export const ActivityLink = ({ label, to, onClick }: ActivityLinkProps) => {
  const { cx, classes } = useStyles();

  return (
    <RouterLink className={cx(classes.eventActive, classes.event)} to={to} onClick={onClick}>
      {label}
    </RouterLink>
  );
};

interface ActivityLabelProps {
  label: string;
}
export const ActivityLabel = ({ label }: ActivityLabelProps) => {
  const { classes } = useStyles();

  return <span className={classes.event}>{label}</span>;
};

const useStyles = makeStyles()(theme => ({
  listItem: {
    borderBottom: "solid 1px #ECECEC",
    padding: "8px 12px",
    display: "flex",
    alignItems: "flex-start",
    fontSize: 12,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  event: {
    fontWeight: 600,
    marginRight: 4,
    textDecoration: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  eventActive: {
    color: theme.palette.primary.main,
  },
  message: {
    color: "black",
  },
  time: {
    color: theme.palette.secondary.light,
    lineHeight: "16px",
  },
  icon: {
    width: 24,
    height: 24,
    color: theme.palette.primary.main,
    marginRight: 15,
  },
}));
