import React, { useCallback, useEffect, useState } from "react";

import debounce from "lodash/debounce";
import { makeStyles } from "tss-react/mui";

import Fab from "../../../../common/components/Fab";
import SearchInput from "../../common/SearchInput";

const useStyles = makeStyles()(() => ({
  toolBar: {
    marginBottom: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 48,
  },
}));

interface Props {
  searchText: string;
  onUpdate: (searchText: string) => void;
  onCreate?: () => void;
}

function AppearanceToolbar({ onUpdate, onCreate, searchText: upperSearchText }: Props) {
  const { classes } = useStyles();
  const [searchText, setSearchText] = useState(upperSearchText);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = useCallback(
    debounce((searchText: string) => {
      onUpdate(searchText);
    }, 250),
    [onUpdate]
  );

  const handleChangeSearchText = (newSearchText: string) => {
    setSearchText(newSearchText);
    handleUpdate(newSearchText);
  };

  const handleClearSearchText = () => {
    setSearchText("");
    handleUpdate("");
  };

  useEffect(() => {
    setSearchText(upperSearchText);
  }, [upperSearchText]);

  return (
    <div>
      <div className={classes.toolBar}>
        <SearchInput
          value={searchText}
          placeholder="Search"
          onSearch={handleChangeSearchText}
          onClear={handleClearSearchText}
        />
        {onCreate && (
          <Fab onClick={onCreate} id="create-theme-button">
            + Create Theme
          </Fab>
        )}
      </div>
    </div>
  );
}

export default AppearanceToolbar;
