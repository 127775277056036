import React from "react";

import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { Code, Feather, MoreVertical } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { ServerBindingResponse, Theme, WorkspaceResponse } from "@cloudentity/acp-admin";

import { BRAND_NAME } from "../../../../common/theme/theme";
import EnhancedTableAsync from "../../common/EnhancedTableAsync/EnhancedTableAsync";
import { UseItemsReturnType } from "../../common/EnhancedTableAsync/useItemsWithQuery";
import WorkspaceAvatar from "../../nav/WorkspaceAvatar";
import { getServerTitle } from "../server-profiles";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  nameContainer: {
    maxWidth: "calc(100% - 75px)",
    marginTop: 6,
    marginLeft: 8,
    display: "flex",
    flexDirection: "column",
    "& > span": {
      color: theme.palette.secondary.light,
    },
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "black",
    fontWeight: 600,
    lineHeight: "16px",
  },
  type: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dot: {
    width: 6,
    height: 6,
    backgroundColor: "#9EA1B7",
    borderRadius: 6,
    marginRight: 8,
  },
  dotActive: {
    backgroundColor: "#10B981",
  },
}));

const headCells = [
  {
    id: "name",
    label: "Workspace name",
    width: "50%",
  },
  {
    id: "theme",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Feather size="14" style={{ marginRight: 8 }} /> Theme
      </div>
    ),
  },
  {
    id: "developer_portal",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Code size="14" style={{ marginRight: 8 }} />
        Developer portal
      </div>
    ),
    minWidthCell: 200,
  },
  { id: "actions", label: "", align: "right" as const, width: 50 },
];

export interface WorkspacesTableProps {
  data: UseItemsReturnType<WorkspaceResponse>;
  onSelectServer: (id: string) => void;
  themes: Theme[];
  themesProgress: boolean;
  connectProgress: string;
  cardProgress: string;
  deleteProgress: string;
  serversBindings: ServerBindingResponse[];
  onMenuOpen: ({ anchorEl, server }: { anchorEl: HTMLElement; server: WorkspaceResponse }) => void;
}

function WorkspacesTable({
  data,
  onSelectServer,
  themes,
  themesProgress,
  connectProgress,
  cardProgress,
  deleteProgress,
  serversBindings,
  onMenuOpen,
}: WorkspacesTableProps) {
  const { cx, classes: componentClasses } = useStyles();

  const {
    data: workspaces,
    isFetching,
    sort,
    order,
    page,
    limit,
    nextPageAvailable,
    onPaginationUpdate,
    onFiltersUpdate,
    filters,
    searchText,
  } = data;

  return (
    <>
      <EnhancedTableAsync
        id="workspaces"
        data={workspaces}
        headCells={headCells}
        loading={isFetching}
        sort={sort}
        order={order}
        page={page}
        limit={limit}
        nextPageAvailable={nextPageAvailable}
        onPaginationUpdate={onPaginationUpdate}
        onFiltersUpdate={onFiltersUpdate}
        filters={filters}
        filtersSetup={[]}
        searchText={searchText}
        searchPlaceholder="Search by identity pool name or ID"
        noToolbar
        createRow={(row: WorkspaceResponse, i, classes) => {
          const { name, id, theme_id } = row;
          const bindings = serversBindings.filter(b => b.dependent === id);
          const progress = connectProgress === id || cardProgress === id || deleteProgress === id;

          return (
            <TableRow
              key={id}
              hover
              style={{ height: 57, position: "relative" }}
              id={`workspaces-table-row-${id}`}
              className={cx([classes.rowWithHoverIcons, classes.row])}
              onClick={() => id && !progress && onSelectServer(id)}
            >
              <TableCell id={`workspaces-table-name-${id}`} style={{ maxWidth: 0, minWidth: 400 }}>
                {progress && (
                  <LinearProgress
                    className={classes.progress}
                    style={{
                      position: "absolute",
                      width: "100%",
                      left: 0,
                      top: 0,
                    }}
                  />
                )}
                <div className={componentClasses.container}>
                  <WorkspaceAvatar server={row} />
                  <div className={componentClasses.nameContainer}>
                    <div className={componentClasses.name}>{name}</div>
                    <Typography variant="textXSM" className={componentClasses.type}>
                      {getServerTitle(row)}
                    </Typography>
                  </div>
                </div>
              </TableCell>
              <TableCell id={`workspaces-table-theme-${id}`}>
                {themesProgress ? (
                  <Skeleton variant="text" style={{ width: 100 }} />
                ) : (
                  <Typography
                    variant="textSM"
                    component="div"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: 300,
                    }}
                  >
                    {(theme_id &&
                      (themes.find(theme => theme.id === theme_id)?.name || theme_id)) ||
                      BRAND_NAME}
                  </Typography>
                )}
              </TableCell>
              <TableCell id={`workspaces-table-theme-${i}`}>
                <Typography variant="textSM" component="div" className={componentClasses.container}>
                  {bindings.length === 0 ? (
                    <>
                      <div className={componentClasses.dot} />
                      Disabled
                    </>
                  ) : (
                    <>
                      <div className={cx(componentClasses.dot, componentClasses.dotActive)} />
                      Enabled
                    </>
                  )}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    onMenuOpen({ anchorEl: e.currentTarget, server: row });
                  }}
                  id={`row-${id}-menu-button`}
                >
                  <MoreVertical />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        }}
      />
    </>
  );
}
export default WorkspacesTable;
