import {
  PermissionsApi,
  PermissionsApiCheckTenantPermissionsRequest,
  PermissionsApiCheckWorkspacePermissionsRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const apiPermissions: () => PermissionsApi = adminApiClazz(PermissionsApi);

const adminPermissionsApi = {
  checkTenantPermissions: (req: PermissionsApiCheckTenantPermissionsRequest) =>
    apiPermissions().checkTenantPermissions(req),
  checkWorkspacePermissions: (req: PermissionsApiCheckWorkspacePermissionsRequest) =>
    apiPermissions().checkWorkspacePermissions(req),
};

export default adminPermissionsApi;
