import React, { useContext } from "react";

import Menu from "@mui/material/Menu";

import { GlobalStoreContext } from "../admin/GlobalStore/GlobalStore";
import { UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles } from "../admin/components/manageAccess/WorkspaceAdministratorsTable";
import ManageUserActionsDeleteUser from "../admin/components/workspaceDirectory/administrator/ManageUserActionsDeleteUser";
import ManageUserActionsResendActivationEmail from "../admin/components/workspaceDirectory/administrator/ManageUserActionsResendActivationEmail";
import adminB2BUsersApi from "../admin/services/adminB2BUsersApi";
import { useCheckPoolPermissions } from "../admin/services/adminIdentityPoolsQuery";
import { listB2BUsersQueryKey } from "../admin/services/adminIdentityUsersQuery";
import { queryClient } from "../admin/services/queryClient";
import { getTenantId } from "../common/api/paths";

interface Props {
  menu: {
    anchorEl: HTMLElement;
    open: boolean;
    data: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles;
  };
  onClose: () => void;
}
export default function OrganizationsDirectoryAdministratorsRowMenu({ menu, onClose }: Props) {
  const globalStoreContext = useContext(GlobalStoreContext);

  const user = menu.data?.user;

  const checkPoolPermissionsQuery = useCheckPoolPermissions(user?.user_pool_id!, {
    enabled: !!user?.user_pool_id,
  });

  return (
    <Menu
      anchorEl={menu.anchorEl}
      open={Boolean(menu.open)}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {!checkPoolPermissionsQuery.data?.manage_identity_pool_users ? (
        <div style={{ padding: 8, color: "gray" }}>No actions</div>
      ) : (
        [
          <ManageUserActionsResendActivationEmail
            user={menu.data?.user}
            onClick={() => {}}
            key="resend"
          />,
          <ManageUserActionsDeleteUser
            user={menu.data?.user}
            onDeleted={onClose}
            onCloseConfirmation={() => {}}
            key="delete"
            onDeleteUser={(id, poolId) =>
              adminB2BUsersApi
                .deleteB2BUser({ ipID: poolId, userID: id })
                .then(() => globalStoreContext.deletedUsers.addDeletedUserId(id))
                .then(() =>
                  queryClient.invalidateQueries({
                    queryKey: listB2BUsersQueryKey(getTenantId(), poolId),
                  })
                )
            }
          />,
        ]
      )}
    </Menu>
  );
}
