import React, { useCallback, useState } from "react";
import { useLocation } from "react-router";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "tss-react/mui";

import { TOP_BAR_HEIGHT } from "../../../common/components/nav/utils";
import { shouldRenderMobileLikeNav } from "../AdminPanel";
import NavDrawerExpander from "./NavDrawerExpander";

export const drawerWidth = 248;
export const drawerCollapsedWidth = 58;

const useStyles = makeStyles()(theme => ({
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    boxShadow: theme.custom.shadows.menu,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "visible",
    width: drawerCollapsedWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: TOP_BAR_HEIGHT,
    height: `calc(100% - ${TOP_BAR_HEIGHT}px)`,
  },
  drawerPaperMobile: {
    marginTop: TOP_BAR_HEIGHT,
    height: `calc(100% - ${TOP_BAR_HEIGHT}px)`,
  },
}));

export type NavDrawerMode = "simple" | "advanced";

export interface NavDrawerContentProps {
  isOpen: boolean;
  onOpenDrawer: () => void;
  onSectionClick: () => void;
  onItemClick: (forceCollapseNav?: boolean) => void;
}

interface Props {
  props?: any;
  mode?: NavDrawerMode;
  onModeChange?: (mode: NavDrawerMode) => void;
  content: ({
    isOpen,
    onOpenDrawer,
    onSectionClick,
    onItemClick,
  }: NavDrawerContentProps) => React.ReactElement;
}

export default function NavDrawer({ mode, onModeChange, content: ContentComponent, props }: Props) {
  const { cx, classes } = useStyles();
  const theme = useTheme();

  const { pathname } = useLocation();
  const matches = {
    downMd: useMediaQuery(theme.breakpoints.down("lg")),
    upLg: useMediaQuery(theme.breakpoints.up("lg")),
  };
  const isMobileLikeNav = shouldRenderMobileLikeNav(pathname) || matches.downMd;

  const [isOpen, setOpen] = useState(!isMobileLikeNav);

  const clickAway = useCallback(() => {
    if (isMobileLikeNav) {
      setOpen(false);
    }
  }, [isMobileLikeNav]);

  const openDrawer = useCallback(() => setOpen(true), []);
  const sectionClick = useCallback(() => setOpen(true), []);

  const itemClick = collapseNav => {
    setTimeout(() => {
      const isMobileLikeNav = shouldRenderMobileLikeNav(window.location.pathname) || matches.downMd;
      if (isMobileLikeNav) {
        setOpen(false);
      } else {
        setOpen(!collapseNav);
      }
    });
  };

  return (
    <ClickAwayListener onClickAway={clickAway}>
      <Drawer
        id="nav-drawer"
        variant="permanent"
        className={cx(
          classes.drawer,
          isMobileLikeNav && {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }
        )}
        classes={{
          paper: cx(
            !isMobileLikeNav && classes.drawerPaper,
            isMobileLikeNav && classes.drawerPaperMobile,
            isMobileLikeNav && {
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen,
            }
          ),
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <>
          {isMobileLikeNav && !isOpen && <NavDrawerExpander onClick={openDrawer} />}
          <ContentComponent
            isOpen={isOpen}
            onModeChange={onModeChange}
            onOpenDrawer={openDrawer}
            onSectionClick={sectionClick}
            onItemClick={itemClick}
            {...props}
          />
        </>
      </Drawer>
    </ClickAwayListener>
  );
}
