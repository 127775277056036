import React, { useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { FeatureResponse } from "@cloudentity/acp-admin";

import Switch from "../../../../common/components/Switch";
import BetaFeatureIcon from "../../../../common/components/icons/BetaFeatureIcon";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { BRAND_NAME } from "../../../../common/theme/theme";
import { getUserEnvironmentQueryKey } from "../../../../user/services/userEnvironmentQuery";
import { getEnvironmentQueryKey } from "../../../services/adminEnvironmentQuery";
import adminFeaturesApi from "../../../services/adminFeaturesApi";
import { getBetaFeaturesKey, useGetBetaFeatures } from "../../../services/adminFeaturesQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PageContainer from "../../common/PageContainer";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";

export default function BetaFeatures() {
  return (
    <PageContainer>
      <PageHeader title="Early Access Features"></PageHeader>
      <PageContent>
        <BeteFeaturesInfo />
        <BetaFeaturesList />
      </PageContent>
    </PageContainer>
  );
}

const BetaFeaturesList = () => {
  const betaFeaturesQuery = useGetBetaFeatures();

  return (
    <>
      {(betaFeaturesQuery.data?.features || []).map(f => (
        <BetaFeaturesListItem feature={f} key={f.name} />
      ))}
    </>
  );
};

const useStyles = makeStyles()(theme => ({
  root: {
    padding: "24px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E9EBEC",
  },
  infoBox: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.secondary.dark,
    lineHeight: "16px",
  },
  desc: {
    marginTop: 8,
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.secondary.light,
    lineHeight: "16px",
  },
  switchBox: {
    display: "flex",
    alignItems: "center",
  },
  alertTitle: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
  },
  alertDescription: {
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 16,
  },
}));

const featureNameToDocsUrl = {
  organizations: "https://cloudentity.com/developers/howtos/beta-features/organizations/",
  roles: "https://cloudentity.com/developers/howtos/beta-features/roles/",
  self_service: "https://cloudentity.com/developers/howtos/beta-features/self-service/",
};

const BetaFeaturesListItem = ({ feature }: { feature: FeatureResponse }) => {
  const { classes } = useStyles();
  const [progress, setProgress] = useState(false);
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const queryClient = useQueryClient();

  const onToggle = (feature: FeatureResponse) => {
    setProgress(true);
    adminFeaturesApi
      .setBetaFeature({ setTenantFeature: { flag: feature.name, value: !feature.enabled } })
      .then(() => queryClient.invalidateQueries({ queryKey: getBetaFeaturesKey }))
      .then(() => queryClient.invalidateQueries({ queryKey: getEnvironmentQueryKey() }))
      .then(() => queryClient.invalidateQueries({ queryKey: getUserEnvironmentQueryKey() }))
      .then(() =>
        notifySuccess(
          <span>
            Feature <strong>{feature.display_name}</strong> has been{" "}
            <strong>{feature.enabled ? "disabled" : "enabled"}</strong>
          </span>
        )
      )
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to toggle feature"))
      .finally(() => setProgress(false));
  };

  return (
    <div className={classes.root}>
      <div className={classes.infoBox}>
        <BetaFeatureIcon />
        <div style={{ marginLeft: 12 }}>
          <div className={classes.title}>{feature.display_name}</div>
          <div className={classes.desc}>{feature.description}</div>
        </div>
      </div>
      <div className={classes.switchBox}>
        {featureNameToDocsUrl[feature.name || ""] && (
          <Link
            href={featureNameToDocsUrl[feature.name || ""]}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: 24, fontWeight: 600, fontSize: 12, lineHeight: "16px" }}
          >
            Learn more
          </Link>
        )}
        <Switch
          checked={feature.enabled}
          onChange={() => onToggle(feature)}
          disabled={progress || !checkTenantPermissionsQuery.data?.update_tenant}
        />
      </div>
    </div>
  );
};

const BeteFeaturesInfo = () => {
  const { classes } = useStyles();

  return (
    <Alert severity="info" style={{ marginBottom: 32 }}>
      <AlertTitle className={classes.alertTitle}>Get Ahead with Early Access Features</AlertTitle>
      <Typography className={classes.alertDescription}>
        Early access features offer you the chance to explore what's next for {BRAND_NAME}. Test
        drive our newest features and help shape their development with your valuable feedback.
        Experience innovation in action and be a part of our evolving product journey.
      </Typography>
      <Link
        style={{ display: "inline-block" }}
        href="https://support.secureauth.com/hc/en-us/requests/new"
        target="_blank"
        rel="noopener noreferrer"
      >
        Provide feedback
      </Link>
    </Alert>
  );
};
