import React from "react";

import AppsIcon from "@mui/icons-material/Apps";

import { ServerResponse } from "@cloudentity/acp-admin";

import saIcon from "../../../assets/images/logos/sa/sa_rgb_mark_blk.svg";
import { BRAND_NAME } from "../../../common/theme/theme";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import HeaderTile from "../common/HeaderTile";
import IdentitiesDetailsMappingsTable from "./IdentitiesDetailsMappingsTable";
import { IdpUiModelType } from "./identities.utils";

interface Props {
  method: IdpUiModelType;
  server: ServerResponse | undefined;
  updateProgress: boolean;
  onUpdate: (provider: IdpUiModelType) => void;
}

export default function IdentitiesDetailsMappings({
  method,
  server,
  updateProgress,
  onUpdate,
}: Props) {
  return (
    <>
      <HeaderTile>
        <CardWithIconAndTitle
          icon={AppsIcon}
          title={method.name || ""}
          subtitle="Source"
          id="identities-mappings-source-tile"
          noPadding
          noBorder
          style={{ flex: 50 }}
        />
        <div style={{ borderLeft: "1px solid rgb(215, 215, 215)", margin: "-24px 0" }} />
        <CardWithIconAndTitle
          img={saIcon}
          title={BRAND_NAME}
          subtitle="Authentication context"
          id="identities-mappings-target-tile"
          noPadding
          noBorder
          style={{ flex: 50, position: "relative", left: 24 }}
        />
      </HeaderTile>

      {!updateProgress && (
        <IdentitiesDetailsMappingsTable idp={method} server={server} onUpdate={onUpdate} />
      )}
    </>
  );
}
