import { useQuery } from "@tanstack/react-query";

import { Styling } from "@cloudentity/acp-admin";

import styling from "./styling";

const GET_STYLING_QUERY = "GET_STYLING_QUERY";

export const getStylingQueryKey = (wid?: string) =>
  wid ? [GET_STYLING_QUERY, wid] : [GET_STYLING_QUERY];

export const useGetStyling = (wid, options?) =>
  useQuery<Styling>({
    queryKey: getStylingQueryKey(wid),
    queryFn: async () => {
      const data = await styling.getStyling(wid);
      return data.data;
    },
    ...options,
  });
