import React, { useMemo, useState } from "react";

import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

import { AuthenticationContextSettings, Claim } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import IconTooltip from "../../../../common/components/IconTooltip";
import AutocompleteField from "../../../../common/utils/forms/AutocompleteField";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";
import { useGetScopes } from "../../../services/adminScopesQuery";
import {
  getSourcePathWithMetadataKey,
  PathOptions,
  SourcePath,
  SourceType,
  sourceTypeOptions,
  useCommonStyles,
} from "./ClaimUtils";
import { ScopeWarningTooltip } from "./ScopeChip";

export interface OAuthClaimsEditProps {
  options: AuthenticationContextSettings["attributes"];
  model: Claim & { metadata_key?: string };
  existingClaims: string[];
  onEdit: (claim: Claim) => void;
  onCancel: () => void;
  progress: boolean;
  workspace: string;
  scopesNames: string[];
}

const OAuthClaimsEdit = ({
  options,
  model,
  existingClaims,
  onEdit,
  onCancel,
  progress,
  workspace,
  scopesNames,
}: OAuthClaimsEditProps) => {
  const { classes: componentClasses } = useCommonStyles();

  const data = useMemo(
    () => ({
      source_type: sourceTypeOptions.find(t => t.value === model.source_type)?.value,
      source_path:
        PathOptions(model.source_type, options).find(o =>
          model.source_type === "identityPool"
            ? o.name === model.metadata_key?.split(".")[0]
            : o.name === model.source_path
        ) || model.source_path,
      metadata_key:
        model.source_type === "identityPool"
          ? model.metadata_key?.split(".")[1]
          : model.metadata_key,
      name: model.name,
      description: model.description,
      scopes: model.scopes || [],
    }),
    [model, options]
  );

  const form = useForm({ id: "edit-claim", initialValues: data, progress });

  const [paths, setPaths] = useState(PathOptions(model.source_type, options));

  const scopesQuery = useGetScopes(getTenantId(), workspace);
  const scopesOptions = useMemo(
    () => scopesQuery.data?.scopes?.map(scope => scope.name) ?? [],
    [scopesQuery.data?.scopes]
  );

  const handleEdit = data => {
    const claim = {
      ...model,
      source_type: data.source_type,
      source_path: getSourcePathWithMetadataKey(
        data.source_type,
        data.source_path,
        data.metadata_key
      ),
      name: data.name,
      description: data.description,
      scopes: data.scopes,
    };
    onEdit(claim);
  };

  return (
    <Dialog onClose={onCancel} id="edit-claim-dialog" title="Edit claim">
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          rules={{
            validate: {
              notUniq: validators.notUniq({
                label: "Name",
                options: existingClaims.filter(v => v !== data.name),
              }),
            },
          }}
          inputProps={{ id: "claim-name-input" }}
        />
        <TextField name="description" label="Description" />
        <SourceType options={sourceTypeOptions} authnCtxOptions={options} setPaths={setPaths} />
        <SourcePath options={paths} />
        <div
          id="claim-scopes-tooltip"
          style={{ marginBottom: 24, display: "flex", alignItems: "center" }}
        >
          <Typography variant="body2" style={{ fontWeight: 500 }}>
            Included in scopes
          </Typography>

          <IconTooltip
            title="This claim will be added only when the selected scopes are issued. If no scopes are issued, this claim will be added in all cases."
            style={{ marginLeft: 8 }}
          />
        </div>

        <AutocompleteField
          name="scopes"
          label="Scopes"
          defaultValue={data.scopes}
          options={scopesOptions}
          multiple
          loading={scopesQuery.isLoading}
          disabled={scopesQuery.isLoading}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
              const { key, ...rest } = getTagProps({ index });
              const hasWarning = !scopesNames.includes(option ?? "");
              return (
                <Chip
                  {...rest}
                  key={key}
                  label={hasWarning ? <ScopeWarningTooltip scope={option ?? ""} /> : option}
                  className={hasWarning ? componentClasses.warning : undefined}
                  style={{ marginRight: 4, marginBottom: 2 }}
                />
              );
            });
          }}
        />

        <FormFooter onCancel={onCancel} onSubmit={handleEdit} submitText="Update" />
      </Form>
    </Dialog>
  );
};

export default OAuthClaimsEdit;
