import React, { useState } from "react";

import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { Bell } from "react-feather";

import { getTenantId } from "../../../common/api/paths";
import { useTopBarStyles } from "../../../common/components/nav/utils";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { useGetWorkspace } from "../../services/adminServersQuery";
import { useGetRecentActivities } from "../../services/adminStatsQuery";
import { useWorkspace } from "../common/useWorkspace";
import ActivityComponent, { ActivityLink } from "../overview/ActivityComponent";
import RecentActivitiesEmptyState from "../overview/RecentActivitiesEmptyState";
import { RecentActivitiesList } from "../overview/RecentActivitiesList";
import { ActivityIcon } from "../overview/RecentActivityItem";
import { useNotifications } from "./useNotifications";

export default function TopBarNotifications() {
  const { classes } = useTopBarStyles({ stripped: false });
  const [workspace] = useWorkspace();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);
  const getWorkspaceQuery = useGetWorkspace(workspace, {
    enabled: !!checkWorkspacePermissionsQuery.data?.discover_workspace,
  });

  const getRecentActivitiesQuery = useGetRecentActivities(getTenantId(), workspace);
  const activities = getRecentActivitiesQuery.data ?? [];

  const { notifications } = useNotifications();

  return (
    <>
      <IconButton
        onClick={e => setAnchorEl(e.currentTarget)}
        aria-label="show activities"
        aria-haspopup="true"
        className={classes.iconButton}
        size="large"
      >
        <Badge color="primary" variant="dot" invisible={notifications.length === 0}>
          <Bell color="white" size="20" />
        </Badge>
      </IconButton>

      <Menu
        id="topbar-activity-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          style: { width: 412 },
        }}
      >
        <Typography variant="h5" className={classes.menuItemTitle}>
          Notifications
        </Typography>
        <Divider className={classes.divider} />

        <div style={{ maxHeight: 268, overflow: "scroll" }}>
          {activities.length === 0 && notifications.length === 0 && <RecentActivitiesEmptyState />}
          {notifications.map(notification => (
            <ActivityComponent
              key={notification.id}
              icon={<ActivityIcon type="warning" />}
              message={notification.message}
            >
              <ActivityLink
                label={notification.linkLabel}
                to={notification.linkTo}
                onClick={notification.onClick}
              />
            </ActivityComponent>
          ))}
          <RecentActivitiesList
            activities={activities}
            authorizationServer={getWorkspaceQuery.data}
          />
        </div>
      </Menu>
    </>
  );
}
