import { ClientAdminResponse } from "@cloudentity/acp-admin";

import adminClientsApi from "./adminClientsApi";
import { useQueryWithWorkspacePermissionCheck, withQueryError } from "./queryUtils";

const GET_CLIENTS_QUERY = "GET_CLIENTS_QUERY";
const GET_CLIENT_QUERY = "GET_CLIENT_QUERY";
const GET_CLIENT_SAML_METADATA_QUERY = "GET_CLIENT_SAML_METADATA_QUERY";

export const getClientsQueryKey = (tid: string, wid: string) => [GET_CLIENTS_QUERY, tid, wid];
export const getClientQueryKey = (cid: string) => [GET_CLIENT_QUERY, cid];
export const getClientSAMLMetadataQueryKey = (cid: string) => [GET_CLIENT_SAML_METADATA_QUERY, cid];

export const useGetClients = (tid: string, wid: string, options?: any) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "read_clients",
    getClientsQueryKey(tid, wid),
    withQueryError(async () => {
      const data = await adminClientsApi.getClients({ wid });
      return data.data;
    }, "Error occurred while trying to fetch clients"),
    options
  );

export const useGetClient = (cid: string, wid: string, options?) =>
  useQueryWithWorkspacePermissionCheck<ClientAdminResponse>(
    wid,
    "read_clients",
    getClientQueryKey(cid),
    withQueryError(async () => {
      const data = await adminClientsApi.getClient({ cid });
      return data.data;
    }, "Error occurred while trying to fetch client"),
    options
  );

export const useGetClientSAMLMetadata = (cid: string, wid: string, options?: any) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "read_clients",
    getClientSAMLMetadataQueryKey(cid),
    withQueryError(async () => {
      const data = await adminClientsApi.getClientSAMLMetadata({ cid });
      return data.data;
    }, "Error occurred while trying to fetch client SAML metadata"),
    options
  );
