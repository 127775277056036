import React, { useMemo, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import { AxiosResponse } from "axios";
import * as OTPAuth from "otpauth";
import { QRCodeSVG } from "qrcode.react";

import { SelfUserWithDataV2 } from "@cloudentity/acp-identityself";

import { useGetUserInfo } from "../../../admin/services/oauth2Query";
import { logout } from "../../auth/actions/actions";
import identitySelfApi from "../../services/identitySelfApi";
import Form, { useForm } from "../../utils/forms/Form";
import FormFooter from "../../utils/forms/FormFooter";
import OTPField from "../../utils/forms/OTPField";
import Button from "../Button";
import Dialog from "../Dialog";
import { notifyErrorOrDefaultTo, notifySuccess } from "../notifications/notificationService";
import { getEmailForTotp } from "./utils";

const data = {
  newTotp: "",
};

interface Props {
  userData: SelfUserWithDataV2 | null;
  fetchUser: (withSetter?: boolean) => Promise<AxiosResponse<SelfUserWithDataV2, any> | null>;
}

export default function SelfServiceSetTotp({ userData, fetchUser }: Props) {
  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(null);

  const userInfoQuery = useGetUserInfo();

  const email = getEmailForTotp(userInfoQuery.data, userData);

  const form = useForm({
    id: "set-totp",
    initialValues: data,
    progress,
  });

  const totp = useMemo(() => {
    const secret = new OTPAuth.Secret({ size: 20 });
    return new OTPAuth.TOTP({
      issuer: "SecureAuth",
      label: email,
      algorithm: "SHA1",
      digits: 6,
      period: 30,
      secret,
    });
  }, [email]);

  function handleClose() {
    setDialog(false);
  }

  function handleSetTotp(newData: typeof data) {
    const delta = totp.validate({ token: newData.newTotp });
    setError(null);

    if (delta !== null) {
      setProgress(true);

      identitySelfApi
        .setTotpSecret({ new_totp_secret: totp.secret.base32 })
        .then(handleClose)
        .then(() => notifySuccess("Authenticator app set successfully"))
        .then(() => fetchUser(true))
        .catch(e => {
          if (
            e.response?.status === 401 &&
            e.response?.headers?.["www-authenticate"]?.includes("insufficient_user_authentication")
          ) {
            setError(e);
            return;
          }

          return notifyErrorOrDefaultTo("Error occurred while trying to set authenticator app")(e);
        })
        .finally(() => setProgress(false));
    } else {
      form.setError(
        "newTotp",
        {
          message: "Provided code is invalid",
        },
        { shouldFocus: true }
      );
    }
  }

  return (
    <>
      <Button
        id="set-totp-button"
        onClick={() => setDialog(true)}
        variant="contained"
        size="small"
        style={{ fontSize: 12 }}
      >
        Enable
      </Button>

      {dialog && (
        <Dialog onClose={handleClose} id="set-password-dialog" title="Set Authenticator App">
          <Typography component="div" variant="textMD" style={{ marginBottom: 32 }}>
            Scan the QR code in the authenticator app and provide the code.
          </Typography>

          <div style={{ margin: "32px 0", textAlign: "center" }}>
            <QRCodeSVG value={totp.toString()} />
          </div>

          <Form form={form}>
            <OTPField
              name="newTotp"
              label="Code from the authenticator app"
              autoFocus
              length={6}
              rules={{
                required: "Code from the authenticator app is required",
              }}
            />

            {error && (
              <Alert
                severity="error"
                title="Reauthentication required"
                style={{ marginBottom: 16 }}
              >
                <AlertTitle>Reauthentication required</AlertTitle>
                Reauthenticate to set authenticator app
                <Button
                  onClick={() => logout("admin", { withPromptLogin: true })}
                  variant="outlined"
                  size="small"
                  color="error"
                  style={{ marginLeft: 8 }}
                >
                  Reauthenticate
                </Button>
              </Alert>
            )}

            <FormFooter onCancel={handleClose} onSubmit={handleSetTotp} submitText="Confirm" />
          </Form>
        </Dialog>
      )}
    </>
  );
}
