export const trimStringValues = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];

    if (typeof value === "string") {
      acc[key] = value.trim();
    } else if (Array.isArray(value)) {
      acc[key] = value.map(v => (typeof v === "string" ? v.trim() : trimStringValues(v)));
    } else if (typeof value === "object" && value !== null) {
      acc[key] = trimStringValues(value);
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
};

type NestedObject = { [key: string]: any };

export function findNestedObjectsInArrayWithValue(
  arr: NestedObject[],
  key: string,
  value: any
): NestedObject[] {
  let results: NestedObject[] = [];

  function recursiveSearch(obj: NestedObject) {
    if (typeof obj !== "object" || obj === null) {
      return;
    }

    for (const k in obj) {
      if (k === key && obj[k] === value) {
        results.push(obj);
      }
      if (Array.isArray(obj[k])) {
        obj[k].forEach((item: NestedObject) => recursiveSearch(item));
      } else if (typeof obj[k] === "object") {
        recursiveSearch(obj[k]);
      }
    }
  }

  arr.forEach(item => recursiveSearch(item));
  return results;
}
