import React, { useState } from "react";

import DialogFullScreen from "../../../../../common/components/DialogFullScreen";
import IdentityPool from "./IdentityPool";

export default function IdentityPoolForDialog({ poolId, serverId, onCancel }) {
  const [tab, setTab] = useState("signin-and-signup");

  return (
    <DialogFullScreen id="configure-identity-pool-dialog" onCancel={onCancel} noPadding>
      <IdentityPool
        id={poolId}
        serverId={serverId}
        tab={tab}
        hideUsers
        hideBreadcrumb
        hideCreate
        onTabChange={setTab}
      />
    </DialogFullScreen>
  );
}
