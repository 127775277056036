import React, { useCallback } from "react";

import { makeStyles } from "tss-react/mui";

import FiltersToolbar from "../common/EnhancedTableAsync/FiltersToolbar";
import AuthNContextCreateButton from "./AuthNContextCreateButton";

const useStyles = makeStyles()(() => ({
  toolbar: {
    paddingBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
}));

const filtersSetup = [];

const filters = {};

interface Props {
  search: string;
  onUpdateSearch: (search: string) => void;
}

export default function AuthNContextTableToolbar({ search, onUpdateSearch }: Props) {
  const { classes } = useStyles();

  const handleUpdateFilters = useCallback(
    search => {
      onUpdateSearch(search);
    },
    [onUpdateSearch]
  );

  return (
    <div className={classes.toolbar}>
      <FiltersToolbar
        filters={filters}
        filtersSetup={filtersSetup}
        searchText={search}
        searchPlaceholder="Search"
        onUpdate={handleUpdateFilters}
      />

      <div>
        <AuthNContextCreateButton />
      </div>
    </div>
  );
}
