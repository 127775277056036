import React, { useContext } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { getTenantId } from "../../../../common/api/paths";
import { GlobalStoreContext } from "../../../GlobalStore/GlobalStore";
import adminIdentityUsersApi from "../../../services/adminIdentityUsersApi";
import { listUsersQueryKey, useGetUser } from "../../../services/adminIdentityUsersQuery";
import { queryClient } from "../../../services/queryClient";
import ManageUserActionsDeleteUser from "./ManageUserActionsDeleteUser";
import ManageUserActionsResendActivationEmail from "./ManageUserActionsResendActivationEmail";
import ManageUserActionsResetPassword from "./ManageUserActionsResetPassword";
import ManageUserActionsSetToActive from "./ManageUserActionsSetToActive";
import ManageUserActionsSetToDeleted from "./ManageUserActionsSetToDeleted";
import ManageUserActionsSetToInactive from "./ManageUserActionsSetToInactive";

interface Props {
  anchorEl: HTMLElement;
  identityPoolId: string;
  userId: string;
  workspaceId: string;
  onClose: () => void;
  onEdit: () => void;
  onCloseEditDialog?: () => void;
}

export default function AdministratorUsersRowMenu({
  anchorEl,
  identityPoolId,
  userId,
  workspaceId,
  onEdit,
  onClose,
}: Props) {
  const getUserQuery = useGetUser(identityPoolId, userId, { enabled: !!anchorEl });
  const globalStoreContext = useContext(GlobalStoreContext);

  return (
    <>
      <Menu
        id="manage-user-row-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && getUserQuery.isSuccess && !getUserQuery.isFetching}
        onClose={() => onClose()}
        onBackdropClick={e => e.stopPropagation()}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          id="administrator-edit-user-button"
          onClick={e => {
            e.stopPropagation();
            onEdit();
            onClose();
          }}
        >
          Edit
        </MenuItem>
        <ManageUserActionsResendActivationEmail
          user={getUserQuery.data}
          workspaceId={workspaceId}
          onClick={() => onClose()}
        />
        <ManageUserActionsSetToActive user={getUserQuery.data} onClick={() => onClose()} />
        <ManageUserActionsSetToInactive user={getUserQuery.data} onClick={() => onClose()} />
        <ManageUserActionsSetToDeleted user={getUserQuery.data} onClick={() => onClose()} />
        <ManageUserActionsResetPassword
          user={getUserQuery.data}
          workspaceId={workspaceId}
          onConfirmed={onClose}
          onCloseConfirmation={onClose}
        />
        <ManageUserActionsDeleteUser
          user={getUserQuery.data}
          onDeleted={onClose}
          onCloseConfirmation={onClose}
          onDeleteUser={(id, poolId) =>
            adminIdentityUsersApi
              .deleteUser({ ipID: poolId, userID: id })
              .then(() => globalStoreContext.deletedUsers.addDeletedUserId(id))
              .then(() =>
                queryClient.invalidateQueries({
                  queryKey: listUsersQueryKey(getTenantId(), poolId),
                })
              )
          }
        />
      </Menu>
    </>
  );
}
