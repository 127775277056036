import isEmpty from "lodash/isEmpty";

export const getAMRLabel = option => amrOptions.find(o => o.value === option)?.name ?? option;

export const getLabel = options => option => options.find(o => o.value === option)?.name ?? option;

export const amrToResponse = data => (isEmpty(data.amr) ? { amr: null } : { amr: data.amr });

// https://tools.ietf.org/html/draft-ietf-oauth-amr-values-04#section-2
export const amrOptions = [
  {
    name: "Facial recognition",
    value: "face",
  },
  {
    name: "Fingerprint biometric",
    value: "fpt",
  },
  {
    name: "Use of geolocation information",
    value: "geo",
  },
  {
    name: "Proof-of-possession (PoP) of a hardware-secured key",
    value: "hwk",
  },
  {
    name: "Iris scan biometric",
    value: "iris",
  },
  {
    name: "Knowledge-based authentication",
    value: "kba",
  },
  {
    name: "Multiple-channel authentication",
    value: "mca",
  },
  {
    name: "Multiple-factor authentication",
    value: "mfa",
  },
  {
    name: "One-time password",
    value: "otp",
  },
  {
    name: "Personal Identification Number or pattern",
    value: "pin",
  },
  {
    name: "Password-based authentication",
    value: "pwd",
  },
  {
    name: "Risk-based authentication",
    value: "rba",
  },
  {
    name: "Retina scan biometric",
    value: "retina",
  },
  {
    name: "Smart card",
    value: "sc",
  },
  {
    name: "Confirmation using SMS message",
    value: "sms",
  },
  {
    name: "Proof-of-possession (PoP) of a software-secured key",
    value: "swk",
  },
  {
    name: "Confirmation by telephone call",
    value: "tel",
  },
  {
    name: "User presence test",
    value: "user",
  },
  {
    name: "Voice biometric",
    value: "vbm",
  },
  {
    name: "Windows integrated authentication",
    value: "wia",
  },
];
