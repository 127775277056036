import { useMutation } from "@tanstack/react-query";

import {
  RolesApiGrantTenantRoleRequest,
  RolesApiRevokeTenantRoleRequest,
} from "@cloudentity/acp-admin";

import adminRolesApi from "./adminRolesApi";
import { listTenantRoles } from "./adminRolesQuery";
import { queryClient } from "./queryClient";

export const useGrantTenantRoleMutation = () => {
  return useMutation({
    mutationFn: (req: RolesApiGrantTenantRoleRequest) => {
      return adminRolesApi.grantTenantRole(req);
    },
    onSuccess: () => Promise.all([queryClient.invalidateQueries({ queryKey: listTenantRoles() })]),
  });
};

export const useRevokeTenantRoleMutation = () => {
  return useMutation({
    mutationFn: (req: RolesApiRevokeTenantRoleRequest) => {
      return adminRolesApi.revokeTenantRole(req);
    },
    onSuccess: () => Promise.all([queryClient.invalidateQueries({ queryKey: listTenantRoles() })]),
  });
};
