import React from "react";

import { makeStyles } from "tss-react/mui";

import { ExtendedUser, UserWithData } from "@cloudentity/acp-identity";

import {
  divideAddressesByType,
  IdentifierInfo,
  MoreIdentifiers,
} from "./IdentityPoolUserCellComponents";

const useStyles = makeStyles()(() => ({
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
}));

interface Props {
  user: ExtendedUser | UserWithData;
}

const IdentityPoolUserAddressesCell = ({ user }: Props) => {
  const { classes } = useStyles();
  const addresses =
    (user as UserWithData).verifiable_addresses || (user as ExtendedUser).addresses || [];

  const { phoneAddresses, emailAddresses } = divideAddressesByType({ addresses });

  return (
    <div>
      {!addresses ||
        (!addresses.length && <span style={{ color: "lightgray" }}>No addresses</span>) || (
          <div>
            <div className={classes.flexBox}>
              {phoneAddresses.length > 0 && (
                <>
                  <IdentifierInfo
                    isVerified={!!phoneAddresses[0].verified}
                    identifier={phoneAddresses[0].address ?? ""}
                  />
                  {phoneAddresses.length > 1 && (
                    <MoreIdentifiers
                      identifiers={
                        phoneAddresses
                          .slice(1)
                          .map(({ address }) => address)
                          .filter(v => v) as string[]
                      }
                    />
                  )}
                </>
              )}
            </div>
            <div className={classes.flexBox}>
              {emailAddresses.length > 0 && (
                <>
                  <IdentifierInfo
                    isVerified={!!emailAddresses[0].verified}
                    identifier={emailAddresses[0].address ?? ""}
                  />
                  {emailAddresses.length > 1 && (
                    <MoreIdentifiers
                      identifiers={
                        emailAddresses
                          .slice(1)
                          .map(({ address }) => address)
                          .filter(v => v) as string[]
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default IdentityPoolUserAddressesCell;
