import React from "react";

import ServerSideAdvancedTable from "./ServerSideAdvancedTable";
import { AdvancedTableProps, ConditionalProps, Id } from "./types";

export default function AdvancedTable<Type extends Id>(
  props: AdvancedTableProps<Type> & ConditionalProps
) {
  return <ServerSideAdvancedTable<Type> {...props} />;
}
