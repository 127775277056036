import React, { forwardRef, useImperativeHandle, useRef } from "react";

import { FormProps } from "@rjsf/core";
import Form from "@rjsf/mui";
import { UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import difference from "lodash/difference";
import pick from "lodash/pick";
import pickBy from "lodash/pickBy";
import { makeStyles } from "tss-react/mui";

import {
  ArrayFieldItemTemplate,
  ArrayFieldTemplate,
  FieldTemplate,
  FieldErrorTemplate,
  transformErrors,
} from "./IdentityPoolUserFormCustomSchemaTemplates";

const useStyles = makeStyles()(theme => ({
  form: {
    "& #root-label": {
      marginBottom: "0 !important",
      fontSize: "16px !important",
    },
    "& .MuiGrid-root": {
      marginTop: "0 !important",
    },
    "& .MuiFormControl-root > .MuiPaper-root": {
      border: "none",
    },
    "& .MuiBox-root": {
      marginTop: 0,
      padding: 0,
      "& div": {
        border: "none",
      },
    },
    "& .MuiBox-root .MuiTypography-h5": {
      marginBottom: 8,
    },
    "& .MuiBox-root .MuiTypography-subtitle2": {
      marginTop: "16px !important",
      marginBottom: 16,
    },
    "& .MuiBox-root .MuiButton-text": {
      color: theme.palette.primary.main,
    },
    "& .MuiButton-text > .MuiSvgIcon-root": {
      fontSize: 18,
      marginRight: 6,
    },
    "& h6": {
      fontSize: 12,
      fontWeight: 600,
      color: "rgb(33, 37, 51)",
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      textTransform: "uppercase",
      marginTop: "8px !important",
    },
    "& .MuiSelect-select": {
      textOverflow: "unset",
      whiteSpace: "unset",
    },
    "& .field-array h6": {
      display: "none",
    },
  },
}));

export const omitPayloadPropertiesWithoutPathInSchema = (payload, schema) => {
  const schemaProperties = Object.keys((schema && schema.properties) || {}).sort((a, b) =>
    a.localeCompare(b)
  );
  const formDataProperties = Object.keys(payload).sort((a, b) => a.localeCompare(b));
  if (difference(formDataProperties, schemaProperties).length) {
    return pick(payload, schemaProperties);
  }

  return payload;
};

export interface SchemaFormRef {
  reset: () => void;
}

interface Props {
  formData: any;
  setFormData: (payload: any) => void;
  schema: any;
  UISchema: UiSchema;
  submitAttempt: boolean;
  updateProgress?: boolean;
  extraErrors: any;
  resetExtraErrors: () => void;
  disabled?: boolean;
}

const SchemaForm = forwardRef<SchemaFormRef, Props>(
  (
    {
      formData,
      setFormData,
      schema,
      UISchema,
      submitAttempt,
      extraErrors,
      updateProgress = false,
      resetExtraErrors,
      disabled = false,
    },
    ref
  ) => {
    const { classes } = useStyles();
    const formRef: FormProps["ref"] = useRef<any>();
    const touchedFields = useRef(new Set<string>());

    useImperativeHandle(
      ref,
      () => ({
        reset: () => formRef.current?.reset(),
      }),
      []
    );

    function customTransformErrors(errors) {
      const err = errors.reduce((acc, error) => {
        const isTouched = touchedFields.current.has(error.property);
        return isTouched || submitAttempt ? [...acc, error] : acc;
      }, []);

      return transformErrors(err);
    }

    return (
      <Form
        ref={formRef}
        validator={validator}
        formData={formData}
        schema={schema}
        uiSchema={UISchema}
        liveValidate
        templates={{
          ArrayFieldTemplate,
          ArrayFieldItemTemplate,
          FieldTemplate,
          FieldErrorTemplate,
        }}
        showErrorList={false}
        extraErrors={extraErrors}
        disabled={disabled || updateProgress}
        onBlur={id => {
          touchedFields.current.add(id.replace(/^root_/, ""));
          formRef.current?.validateForm();
        }}
        onChange={e => {
          resetExtraErrors();
          setFormData(pickBy(e.formData, v => v !== undefined));
        }}
        className={classes.form}
        omitExtraData
        liveOmit
        transformErrors={customTransformErrors}
      >
        <div />
      </Form>
    );
  }
);

export default SchemaForm;
