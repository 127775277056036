import React, { useState } from "react";

import { MoreVertical } from "react-feather";

import IconButton from "../../../../../common/components/IconButton";
import Menu from "../../../../../common/components/Menu";

interface Props {
  name: string;
  onClick: () => void;
}

export default function ConfigureButton({ name, onClick }: Props) {
  const [moreMenu, setMoreMenu] = useState<HTMLElement | null>(null);

  return (
    <>
      <IconButton
        size="small"
        icon={MoreVertical}
        onClick={e => {
          setMoreMenu(e.currentTarget);
        }}
        aria-label={`${name} configuration button`}
      />
      <Menu
        anchorEl={moreMenu}
        onClose={() => setMoreMenu(null)}
        items={[
          {
            onClick: () => {
              onClick();
              setMoreMenu(null);
            },
            label: "Configure",
            id: "configure-option",
          },
        ]}
      />
    </>
  );
}
