import React, { useCallback, useEffect, useState } from "react";

import ViewStream from "@mui/icons-material/ViewStream";
import IconButton from "@mui/material/IconButton";
import debounce from "lodash/debounce";
import { Plus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import Fab from "../../../../common/components/Fab";
import AlignLeftIcon from "../../../../common/components/icons/workspaces/AlignLeftIcon";
import GridIcon from "../../../../common/components/icons/workspaces/GridIcon";
import { setInLocalStorage } from "../../../../common/utils/localStorage.utils";
import SearchInput from "../../common/SearchInput";
import { GRID_MODE_KEY, GridMode } from "./utils";

const useStyles = makeStyles()(theme => ({
  toolBar: {
    marginBottom: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 48,
  },
  button: {
    marginRight: 8,
  },
  fabButton: {
    marginLeft: 16,
  },
  icon: {
    fontSize: 24,
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
}));

export interface WorkspacesToolbarProps {
  searchText: string;
  onUpdate?: (searchText: string) => void;
  onCreate?: () => void;
  gridMode?: GridMode;
  setGridMode?: (mode: GridMode) => void;
  searchDisabled?: boolean;
  createText?: string;
}

function WorkspacesToolbar({
  onUpdate,
  onCreate,
  searchText: upperSearchText,
  gridMode,
  setGridMode,
  searchDisabled = false,
  createText,
}: WorkspacesToolbarProps) {
  const { cx, classes } = useStyles();
  const [searchText, setSearchText] = useState(upperSearchText);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = useCallback(
    debounce((searchText: string) => {
      if (onUpdate) {
        onUpdate(searchText);
      }
    }, 500),
    [onUpdate]
  );

  const handleChangeSearchText = (newSearchText: string) => {
    setSearchText(newSearchText);
    handleUpdate(newSearchText);
  };

  const handleClearSearchText = () => {
    setSearchText("");
    handleUpdate("");
  };

  const handleGridModeChange = (mode: GridMode) => {
    if (!setGridMode) return;
    setGridMode(mode);
    setInLocalStorage(GRID_MODE_KEY, mode);
  };

  useEffect(() => {
    setSearchText(upperSearchText);
  }, [upperSearchText]);

  return (
    <div>
      <div className={classes.toolBar}>
        <div>
          {onUpdate && (
            <SearchInput
              value={searchText}
              placeholder="Search"
              onSearch={handleChangeSearchText}
              onClear={handleClearSearchText}
              disabled={searchDisabled}
            />
          )}
        </div>

        <div>
          {gridMode && setGridMode && (
            <>
              <IconButton
                className={classes.button}
                onClick={() => handleGridModeChange("small-card")}
                id="grid-mode-button_small-card"
              >
                <ViewStream
                  className={cx(classes.icon, gridMode === "small-card" && classes.activeIcon)}
                />
              </IconButton>
              <IconButton
                onClick={() => handleGridModeChange("full-card")}
                id="grid-mode-button_full-card"
                className={cx(classes.button, gridMode === "full-card" && classes.activeIcon)}
              >
                <GridIcon />
              </IconButton>
              <IconButton
                onClick={() => handleGridModeChange("table")}
                id="grid-mode-button_table"
                className={cx(classes.button, gridMode === "table" && classes.activeIcon)}
              >
                <AlignLeftIcon />
              </IconButton>
            </>
          )}
          {onCreate && (
            <Fab
              onClick={onCreate}
              startIcon={Plus}
              id="create-new-workspace-button"
              className={classes.fabButton}
            >
              {createText || "Create new"}
            </Fab>
          )}
        </div>
      </div>
    </div>
  );
}

export default WorkspacesToolbar;
