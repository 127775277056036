import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";

import { BaseUserWithData, UserWithData } from "@cloudentity/acp-identity";

import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../../common/components/notifications/notificationService";
import identityOTPApi from "../../../../../../../services/adminIdentityOTPApi";
import { useGetPool } from "../../../../../../../services/adminIdentityPoolsQuery";
import IdentityPoolThemeSelectorDialog from "../../../IdentityPoolThemeSelectorDialog";
import { useIdentityPoolThemeSelector } from "../../../useIdentityPoolThemeSelector";

interface Props {
  user: UserWithData | BaseUserWithData;
  workspaceId?: string;
  address: string;
  onVerifyDialogOpen: () => void;
}

export default function IdentityPoolUserSidePanelChallengeOTP({
  user,
  workspaceId,
  address,
  onVerifyDialogOpen,
}: Props) {
  const [progress, setProgress] = useState(false);

  const getPoolQuery = useGetPool(user?.user_pool_id || "");
  const { mustSelectThemeServerId, openDialog, closeDialog, isDialogOpen, themeWorkspaceId } =
    useIdentityPoolThemeSelector({ pool: getPoolQuery.data });

  const handleChallengeOTP = (serverId?: string) => {
    setProgress(true);
    identityOTPApi
      .requestOTPChallenge({
        ipID: user?.user_pool_id!,
        userID: user?.id!,
        requestOTPChallenge: { address },
        serverId: serverId || workspaceId,
      })
      .then(() => notifySuccess("OTP challenge requested"))
      .then(() => onVerifyDialogOpen())
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to request OTP challenge"))
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <>
      <MenuItem
        id="otp-challenge-button"
        onClick={() => {
          if (mustSelectThemeServerId) {
            openDialog();
            return;
          }
          handleChallengeOTP(themeWorkspaceId);
        }}
        disabled={progress}
      >
        Challenge OTP
      </MenuItem>

      {isDialogOpen && getPoolQuery.data && (
        <IdentityPoolThemeSelectorDialog
          pool={getPoolQuery.data}
          onCancel={() => closeDialog()}
          onSubmit={serverId => {
            handleChallengeOTP(serverId);
            closeDialog();
          }}
        />
      )}
    </>
  );
}
