import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { ChevronDown } from "react-feather";

import { getTenantId } from "../../../../common/api/paths";
import { GlobalStoreContext } from "../../../GlobalStore/GlobalStore";
import adminIdentityUsersApi from "../../../services/adminIdentityUsersApi";
import { listUsersQueryKey, useGetUser } from "../../../services/adminIdentityUsersQuery";
import { queryClient } from "../../../services/queryClient";
import ManageUserActionsDeleteUser from "./ManageUserActionsDeleteUser";
import ManageUserActionsResendActivationEmail from "./ManageUserActionsResendActivationEmail";
import ManageUserActionsResetPassword from "./ManageUserActionsResetPassword";
import ManageUserActionsSetToActive from "./ManageUserActionsSetToActive";
import ManageUserActionsSetToDeleted from "./ManageUserActionsSetToDeleted";
import ManageUserActionsSetToInactive from "./ManageUserActionsSetToInactive";

interface Props {
  identityPoolId: string;
  workspaceId: string;
  userId: string;
  onCloseEditDialog: () => void;
}

export default function ManageUserActions({
  identityPoolId,
  workspaceId,
  userId,
  onCloseEditDialog,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const globalStoreContext = useContext(GlobalStoreContext);

  const getUserQuery = useGetUser(identityPoolId, userId);

  return (
    <>
      <Button
        variant="contained"
        onClick={e => setAnchorEl(e.currentTarget)}
        endIcon={<ChevronDown size={18} />}
        disabled={getUserQuery.isFetching}
      >
        Manage
      </Button>
      <Menu
        id="manage-user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ManageUserActionsResendActivationEmail
          workspaceId={workspaceId}
          user={getUserQuery.data!}
          onClick={() => setAnchorEl(null)}
        />
        <ManageUserActionsSetToActive user={getUserQuery.data!} onClick={() => setAnchorEl(null)} />
        <ManageUserActionsSetToInactive
          user={getUserQuery.data!}
          onClick={() => setAnchorEl(null)}
        />
        <ManageUserActionsSetToDeleted
          user={getUserQuery.data!}
          onClick={() => setAnchorEl(null)}
        />
        <ManageUserActionsResetPassword
          user={getUserQuery.data!}
          workspaceId={workspaceId}
          onConfirmed={() => {
            setAnchorEl(null);
          }}
          onCloseConfirmation={() => {
            setAnchorEl(null);
          }}
        />
        <ManageUserActionsDeleteUser
          user={getUserQuery.data!}
          onDeleted={() => {
            onCloseEditDialog();
          }}
          onCloseConfirmation={() => {
            setAnchorEl(null);
          }}
          onDeleteUser={(id, poolId) =>
            adminIdentityUsersApi
              .deleteUser({ ipID: poolId, userID: id })
              .then(() => globalStoreContext.deletedUsers.addDeletedUserId(id))
              .then(() =>
                queryClient.invalidateQueries({
                  queryKey: listUsersQueryKey(getTenantId(), poolId),
                })
              )
          }
        />
      </Menu>
    </>
  );
}
