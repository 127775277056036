import React, { useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import debounce from "lodash/debounce";
import { makeStyles } from "tss-react/mui";

import { useGetPool } from "../../../../../../services/adminIdentityPoolsQuery";
import { useGetSchema } from "../../../../../../services/adminIdentitySchemasQuery";
import { getOptions, valueToFiltering } from "./UsersFilter.utils";

const useStyles = makeStyles()(() => ({
  inputRoot: {
    "& > .MuiOutlinedInput-root": {
      paddingTop: 7,
      paddingBottom: 7,
    },
  },
}));

export type Value = {
  value?: string;
  type?: string;
  path?: string;
  group?: string;
};

export type Values = Array<Value>;

const isOnlyPathSelected = (value: Values) => value.length % 2 === 1;

export const escapePercentageSignInValue = value => value.replaceAll("%", "%25");

interface Props {
  identityPoolId: string;
  onFilter: (filtering: any) => void;
  style?: React.CSSProperties;
}

export default function UsersFilter({ identityPoolId, onFilter, style }: Props) {
  const { classes } = useStyles();

  const [value, setValue] = useState<Values>([]);
  const [error, setError] = useState("");

  const poolQuery = useGetPool(identityPoolId);
  const payloadSchemaQuery = useGetSchema(poolQuery.data?.payload_schema_id, {
    enabled: poolQuery.isSuccess,
  });
  const metadataSchemaQuery = useGetSchema(poolQuery.data?.metadata_schema_id, {
    enabled: poolQuery.isSuccess,
  });
  const businessMetadataSchemaQuery = useGetSchema(poolQuery.data?.business_metadata_schema_id, {
    enabled: poolQuery.isSuccess && !!poolQuery.data?.business_metadata_schema_id,
  });

  const isValidValue = value => {
    try {
      decodeURIComponent(escapePercentageSignInValue(JSON.stringify(value)));
      return true;
    } catch (e) {
      setError((e as Error).message);
      return false;
    }
  };

  useEffect(() => {
    setError("");
    if (value.length % 2 === 0) {
      if (isValidValue(value)) {
        onFilter(valueToFiltering(value));
      }
    }
  }, [value, onFilter]);

  const handleInput = debounce(event => {
    setError("");
    if (value && value.length > 0 && value.length % 2 === 1 && event.target.value) {
      const v = [
        ...(value as Values),
        {
          value:
            typeof event.target.value === "string" ? event.target.value : event.target.value?.value,
        },
      ];
      if (isValidValue(v)) {
        onFilter(valueToFiltering(v));
      }
    }
  }, 1000);

  const handleChange = (event, options: Array<string | { value: "true" | "false" } | Value>) => {
    setError("");
    if (options.length % 2 === 0 && options.length > 0) {
      const last = options.pop();
      setValue([...(options as Values), { value: typeof last === "string" ? last : last?.value }]);
    } else {
      setValue(options as Values);
    }
  };

  const handleBlur = event => {
    if (value.length % 2 === 1) {
      if (event.target.value) {
        setValue([
          ...(value as Values),
          {
            value:
              typeof event.target.value === "string"
                ? event.target.value
                : event.target.value?.value,
          },
        ]);
      } else {
        setError("Type value to start searching");
      }
    } else if (value.length % 2 === 0) {
      isValidValue(value);
    } else {
      setError("");
    }
  };

  const handleFocus = () => setError("");

  return (
    <div style={style}>
      <Autocomplete
        id="users-filter"
        freeSolo={isOnlyPathSelected(value) && value[value.length - 1].type !== "boolean"}
        options={getOptions(
          value,
          payloadSchemaQuery.data?.schema || {},
          metadataSchemaQuery.data?.schema || {},
          businessMetadataSchemaQuery.data?.schema || {}
        )}
        value={value}
        onInput={handleInput}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        getOptionLabel={(option: string | Value) => {
          if (typeof option === "string") {
            return option;
          }
          return option.value !== undefined ? option.value : `${option.group}.${option.path}`;
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.group ? `${option.group}.${option.path}` : option.path}>
              {option.value !== undefined ? (
                <Typography key={option.group ? `${option.group}.${option.path}` : option.path}>
                  {option.value}
                </Typography>
              ) : (
                <div
                  key={option.group ? `${option.group}.${option.path}` : option.path}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {option.group && <Typography>{option.group}.</Typography>}
                    <Typography>{option.path}</Typography>
                  </div>
                  <div>
                    <Typography variant="caption">{option.type}</Typography>
                  </div>
                </div>
              )}
            </li>
          );
        }}
        multiple
        renderInput={params => (
          <TextField
            {...params}
            error={!!error}
            className={classes.inputRoot}
            helperText={error || ""}
            placeholder={
              value.length === 0
                ? "Search for users... (e.g. user_key is john.doe@cloudentity.com)"
                : value.length % 2 === 1
                ? "Type value and confirm with Enter key"
                : "and ..."
            }
          />
        )}
        renderTags={(values: Values) =>
          values.map((v, index) =>
            v.value !== undefined ? (
              <Typography style={{ marginLeft: 6 }} key={v.value + index}>
                {v.value}
              </Typography>
            ) : (
              <div
                style={{ display: "flex", alignItems: "center" }}
                key={v.group ? `${v.group}.${v.path}` : v.path}
              >
                {value.length > 1 && index > 1 && (
                  <Typography style={{ marginLeft: 6, marginRight: 6 }}>and</Typography>
                )}
                {v.group && <Typography>{v.group}.</Typography>}
                <Typography>{v.path}</Typography>

                <Typography style={{ marginLeft: 6 }}>
                  {v.type === "array"
                    ? "contains"
                    : !v.group && v.path === "user_key"
                    ? "is"
                    : "equals"}
                </Typography>
              </div>
            )
          )
        }
      />
      {!error && value[value.length - 1]?.type === "array" && (
        <FormHelperText>Use comma as a delimiter to provide many values</FormHelperText>
      )}
      {!error &&
        value[value.length - 1]?.path === "user_key" &&
        !value[value.length - 1]?.group && (
          <FormHelperText>
            user_key - limits user set to users that has identifier starting or ending with provided
            user_key or has verified address starting or ending with provided user_key or their ID
            is equal to provided user_key
          </FormHelperText>
        )}
    </div>
  );
}
