import React from "react";

import ButtonMUI, { ButtonProps } from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{
  error?: boolean;
  outlined?: boolean;
  small?: boolean;
  text?: boolean;
}>()((theme, { error, outlined, small, text }) => ({
  button: {
    ...(small
      ? {}
      : {
          minHeight: 40,
        }),
    ...(error
      ? {
          backgroundColor: "#FFF8F7",
        }
      : {}),
    "&:hover": {
      ...(!outlined && !text
        ? { backgroundColor: error ? theme.palette.error.main : theme.palette.primary.main }
        : {}),
      opacity: 0.9,
    },
  },
}));

export default function Button({ children, className, ...buttonProps }: ButtonProps) {
  const { cx, classes } = useStyles({
    error: buttonProps.color === "error",
    outlined: buttonProps.variant === "outlined",
    small: buttonProps.size === "small",
    text: buttonProps.variant === "text",
  });

  return (
    <ButtonMUI className={cx(classes.button, className)} {...buttonProps}>
      {children}
    </ButtonMUI>
  );
}
