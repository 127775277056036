import React from "react";

import RouterLink from "../../../../common/components/RouterLink";
import DocLink from "./DocLink";

export default function QuickstartArticleBranding() {
  return (
    <>
      <p>
        Our platform empowers administrators to customize the look and feel of customer-facing
        components, including login pages, consent pages, user portals, messages, and more. With
        flexible branding options, you can create a consistent user experience tailored to your
        organization's identity.
      </p>
      <p>
        Administrators can access a dedicated view to configure simple branding or create advanced
        themes, with an instant preview of adjustments.
      </p>
      <p>
        <b>Simple Branding</b>
      </p>
      <p>
        Simple branding allows you to make basic visual customizations for your workspace. These
        settings help create a recognizable and professional appearance across customer-facing
        pages.
      </p>
      <ol>
        <li>
          Go to{" "}
          <RouterLink to="/appearance/branding" target="_blank" withIcon>
            <b>Tenant Settings &gt; Appearance &gt; Branding</b>
          </RouterLink>
          .
        </li>
        <li>
          Customize the following options:
          <ul>
            <li>
              <b>Primary Color</b>: Select the primary color used across the user interface.
            </li>

            <li>
              <b>Header Color</b>: Define the color of the headers.
            </li>

            <li>
              <b>Logo URL</b>: Enter the URL of your company logo to display in messages and
              headers.
            </li>

            <li>
              <b>Logo Size</b>: Choose one of the available options: Small, Medium, or Large.
            </li>

            <li>
              <b>Font Type</b>: Enter the font URL to define a custom font.
            </li>
          </ul>
        </li>
        <li>
          Click <b>Publish</b> to save your changes or use the <b>Reset button</b> to revert to the
          original settings.
        </li>
      </ol>

      <p>
        <b>Advanced Theming</b>
      </p>
      <p>
        Advanced theming provides greater flexibility and control, allowing you to customize
        individual pages and messages or apply themes across multiple workspaces. This feature is
        ideal for organizations that require more tailored branding solutions.
      </p>
      <ol>
        <li>
          Go to{" "}
          <RouterLink to="/appearance/themes" target="_blank" withIcon>
            <b>Tenant Settings &gt; Appearance &gt; Themes</b>
          </RouterLink>
          .
        </li>
        <li>
          Click <b>+ CREATE THEME</b>.
        </li>
        <li>
          Enter a name for your theme in the <b>Name</b> field.
        </li>
        <li>
          Add an icon URL to <b>Theme Icon URL</b> field to help identify the theme in the themes
          list view.
        </li>
        <li>
          Customize settings in the <b>Pages</b> and <b>Messages</b> sections and <b>Save</b> your
          changes.
        </li>
        <li>
          Return to the themes list. Click the three dots next to your theme and select{" "}
          <b>Bind to Workspace</b> to apply the theme to a specific workspace.
        </li>{" "}
      </ol>
      <br />
      <p>
        For more details on setting up branding, refer to the{" "}
        <DocLink href="https://docs.secureauth.com/cloudentity/en/branding-412761.html">
          SecureAuth Branding documentation
        </DocLink>
        .
      </p>
    </>
  );
}
