import React, { useEffect, useMemo, useState } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import omit from "lodash/omit";

import { ClientAdminResponse, ServerResponse } from "@cloudentity/acp-admin";

import FormAccordion from "../../../common/components/FormAccordion";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import { BRAND_NAME } from "../../../common/theme/theme";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import TextFieldReadOnly from "../../../common/utils/forms/TextFieldReadOnly";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../common/utils/forms/validation";
import adminIDPsApi from "../../services/adminIDPsApi";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "./amrOptions";
import { IdpUiModelCustomType, providers, ProvidersType } from "./identities.utils";

const id = "identities-configuration-custom";

interface Props {
  idp: IdpUiModelCustomType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  customSubmit?: boolean;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelCustomType) => void;
  onDelete?: (idp: IdpUiModelCustomType) => void;
}

export default function IdentitiesConfigurationCustom({
  idp,
  server,
  updateProgress,
  inEdit,
  onLogoEdit,
  customSubmit,
  onCancel,
  onSubmit,
  onInit,
  onDelete,
}: Props) {
  const [client, setClient] = useState<ClientAdminResponse | null>(null);

  const data = useMemo(() => {
    return {
      ...idp,
      client_secret: client?.client_secret,
      issuer_url: client?.server_issuer_url,
    };
  }, [idp, client]);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const form = useForm({
    id,
    initialValues: data,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const submitFn = (idp: IdpUiModelCustomType, data: IdpUiModelCustomType) => {
    return omit(
      {
        ...idp,
        ...amrToResponse(data),
        ...data,
        settings: { ...data.settings, type: idp.settings?.type },
      },
      ["client_secret", "issuer_url"]
    ) as IdpUiModelCustomType;
  };

  const setValue = form.setValue;

  const providerMapData = useMemo(
    () =>
      providers.find(
        p => p.body.method === ProvidersType.custom && p.body.settings?.type === idp.settings?.type
      ),
    [idp.settings?.type]
  );

  useEffect(() => {
    onInit && onInit(() => form.handleSubmit(data => onSubmit && onSubmit(submitFn(idp, data))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inEdit) {
      adminIDPsApi
        .getIDPClient({ wid: idp.authorization_server_id, type: idp.method, iid: idp.id })
        .then(resClient => {
          setClient(resClient.data);
          setValue("client_secret", resClient.data.client_secret);
        });
    }
  }, [setValue, idp, inEdit]);

  const theme = useTheme();
  const matches = {
    downMd: useMediaQuery(theme.breakpoints.down("lg")),
  };

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <Form form={form}>
      <Grid container spacing={3} direction={matches.downMd ? "column-reverse" : "row"}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                subtitle={providerMapData.description}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32 }}
              />
            )}

            <CommonIdpConfigUpper
              provider={idp}
              inEdit={inEdit}
              onLogoEdit={onLogoEdit}
              onUpdate={onSubmit}
            />
            <TextFieldRequired
              name="settings.login_url"
              label="Login URL"
              rules={{
                validate: { validURL: validators.validURL({ label: "Login URL" }) },
              }}
            />
            {inEdit && (
              <TextFieldReadOnly
                name="client_id"
                label="Client ID"
                withCopy
                inputProps={{ autoComplete: "off" }}
              />
            )}
            {inEdit && (
              <>
                <TextFieldReadOnly
                  name="client_secret"
                  label="Client secret"
                  withCopy
                  toggleVisibility
                  defaultVisibility={false}
                  inputProps={{ autoComplete: "off" }}
                />
                <TextFieldReadOnly
                  name="issuer_url"
                  label="Issuer URL"
                  withProgress={!client}
                  withCopy
                />
              </>
            )}
            <CommonIdpConfig data={idp} />

            {inEdit && (
              <FormAccordion title="Advanced settings" id={id}>
                <AutocompleteField
                  name="static_amr"
                  label="Authentication Method Reference"
                  helperText="If set overwrites AMR obtained from this authentication method"
                  defaultValue={idp.static_amr ?? []}
                  options={amrOptions.map(v => v.value)}
                  getOptionLabel={getAMRLabel}
                  multiple
                />
                {serverSSOEnabled && <SSOIDPSettings />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={idp}
              onSubmit={data => onSubmit && onSubmit(submitFn(idp, data))}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {idp.settings?.type === "generic" && (
          <Grid item xs={12} lg={5}>
            <Alert id="identities-custom-idp-alert" severity="info">
              <AlertTitle>Connecting Custom IDP</AlertTitle>
              <p>
                The custom IDP connection allows integrating with an existing login page using login
                APIs.
              </p>

              <p>
                The {BRAND_NAME} will check if the login request has been accepted and redirects the
                end-user to the consent screen.
              </p>

              <p>
                When both login and consent requests are approved, the authorization code (or access
                and ID tokens in the implicit grant flow) redirects the user to the redirect URL.
              </p>

              <p>For details on how to build and integrate your Custom IDP,</p>
              <Link
                href="https://cloudentity.com/developers/howtos/identities/custom-idp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Connecting Custom Identity Provider.
              </Link>
            </Alert>
          </Grid>
        )}
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </Form>
  );
}
