import React, { useState } from "react";

import Add from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { useWorkspace } from "../common/useWorkspace";
import AddCustomApplicationDialog from "./AddCustomApplicationDialog";

export default function AddCustomApplicationButton() {
  const [isOpen, setIsOpen] = useState(false);

  const [workspace] = useWorkspace();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  if (!checkWorkspacePermissionsQuery.data?.manage_custom_apps) {
    return null;
  }

  return (
    <>
      <Button
        id="add-custom-application-button"
        variant="contained"
        color="primary"
        size="large"
        startIcon={<Add />}
        style={{ marginRight: 12 }}
        onClick={() => setIsOpen(true)}
      >
        CUSTOM APPLICATION
      </Button>

      {isOpen && (
        <AddCustomApplicationDialog
          onCreated={() => setIsOpen(false)}
          onCancel={() => setIsOpen(false)}
        />
      )}
    </>
  );
}
