import React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { getUserEmails } from "../../../admin/components/workspaceDirectory/administrator/AdministratorUserEdit";
import ManageUserActionsResendActivationEmail from "../../../admin/components/workspaceDirectory/administrator/ManageUserActionsResendActivationEmail";
import ManageUserActionsResetPassword from "../../../admin/components/workspaceDirectory/administrator/ManageUserActionsResetPassword";
import { useGetB2BUser } from "../../../admin/services/adminIdentityUsersQuery";
import B2BManageUserActionsDeleteUser from "./B2BManageUserActionsDeleteUser";
import B2BManageUserActionsSetToActive from "./B2BManageUserActionsSetToActive";
import B2BManageUserActionsSetToDeleted from "./B2BManageUserActionsSetToDeleted";
import B2BManageUserActionsSetToInactive from "./B2BManageUserActionsSetToInactive";

interface Props {
  anchorEl: HTMLElement;
  identityPoolId: string;
  userId: string;
  workspaceId: string;
  onClose: () => void;
  onEdit: () => void;
  onCloseEditDialog?: () => void;
}

export default function B2BUsersRowMenu({
  anchorEl,
  identityPoolId,
  userId,
  workspaceId,
  onEdit,
  onClose,
}: Props) {
  const getB2BUserQuery = useGetB2BUser(identityPoolId, userId, { enabled: !!anchorEl });
  const emails = getUserEmails(getB2BUserQuery.data!);

  return (
    <>
      <Menu
        id="manage-user-row-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && getB2BUserQuery.isSuccess && !getB2BUserQuery.isFetching}
        onClose={() => onClose()}
        onBackdropClick={e => e.stopPropagation()}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          id="administrator-edit-user-button"
          onClick={e => {
            e.stopPropagation();
            onEdit();
            onClose();
          }}
        >
          Edit
        </MenuItem>
        <ManageUserActionsResendActivationEmail
          user={getB2BUserQuery.data}
          workspaceId={workspaceId}
          onClick={() => onClose()}
        />
        <B2BManageUserActionsSetToActive user={getB2BUserQuery.data} onClick={() => onClose()} />
        <B2BManageUserActionsSetToInactive user={getB2BUserQuery.data} onClick={() => onClose()} />
        <B2BManageUserActionsSetToDeleted user={getB2BUserQuery.data} onClick={() => onClose()} />
        <ManageUserActionsResetPassword
          user={getB2BUserQuery.data}
          workspaceId={workspaceId}
          onConfirmed={onClose}
          onCloseConfirmation={onClose}
        />
        <B2BManageUserActionsDeleteUser
          user={getB2BUserQuery.data}
          email={emails}
          onDeleted={onClose}
          onCloseConfirmation={onClose}
        />
      </Menu>
    </>
  );
}
