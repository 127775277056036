import React from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import SelectablePaper from "../../../common/components/SelectablePaper";

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    marginBottom: 16,
    padding: 24,
    background: "#fff",
  },
  avatar: {
    width: 48,
    height: 48,
    marginRight: 16,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  spacer: {
    flex: 1,
  },
  innerButton: {
    textTransform: "uppercase",
  },
  subtitle: {
    color: theme.palette.secondary.light,
  },
}));

interface WelcomeTypeCardProps {
  id: string;
  avatar: JSX.Element;
  title: string;
  subtitle: string;
  selected?: boolean;
  selectable?: boolean;
  innerButton?: {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    label: string | React.ReactNode;
    loading: boolean;
  };
  onClick?: () => void;
}

export default function WelcomeTypeCard({
  id,
  avatar,
  title,
  subtitle,
  selected,
  selectable,
  innerButton,
  onClick,
}: WelcomeTypeCardProps) {
  const { classes } = useStyles();
  return (
    <SelectablePaper
      id={id}
      className={classes.root}
      selectable={selectable}
      selected={selected}
      onSelect={onClick}
    >
      <Avatar variant="square" className={classes.avatar}>
        {avatar}
      </Avatar>
      <div className={classes.content}>
        <Typography style={{ fontWeight: 700 }} variant="h5">
          {title}
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          {subtitle}
        </Typography>
      </div>
      <div className={classes.spacer} />
      {innerButton && (
        <LoadingButton
          className={classes.innerButton}
          onClick={innerButton.onClick}
          loading={innerButton.loading}
          variant="outlined"
        >
          {innerButton.label}
        </LoadingButton>
      )}
    </SelectablePaper>
  );
}
