import React, { useMemo, useState } from "react";
import { useParams } from "react-router";

import { useQueryClient } from "@tanstack/react-query";

import { AddUserVerifiableAddressTypeEnum } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../common/api/paths";
import Dialog from "../../../../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import CheckboxField from "../../../../../../../common/utils/forms/CheckboxField";
import Form, { useForm } from "../../../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../../../common/utils/forms/FormFooter";
import PhoneField from "../../../../../../../common/utils/forms/PhoneField";
import RadioGroupField from "../../../../../../../common/utils/forms/RadioGroupField";
import TextFieldRequired from "../../../../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../../../../common/utils/forms/validation";
import adminIdentityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listUsersQueryKey,
} from "../../../../../../services/adminIdentityUsersQuery";

export default function AddUserAddressDialog({ onClose }) {
  const { id = "", userID = "" } = useParams<{ id: string; userID: string; tab: string }>();
  const tenantId = getTenantId();

  const [requestError, setRequestError] = useState("");
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();
  const data = useMemo(() => ({ identifier: "email", email: "", phone: "" }), []);
  const form = useForm({ id: "add-user-address", initialValues: data, progress });
  const identifier = form.watch("identifier");

  const handleSubmit = data => {
    setProgress(true);
    adminIdentityUsersApi
      .addUserVerifiableAddress({
        ipID: id,
        userID,
        newAddress: {
          address: data.email || data.phone,
          type: data.email
            ? AddUserVerifiableAddressTypeEnum.Email
            : AddUserVerifiableAddressTypeEnum.Mobile,
          verified: data.verified,
        },
      })
      .then(() => queryClient.invalidateQueries({ queryKey: getUserQueryKey(tenantId, userID) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, id) }))
      .then(() => onClose())
      .then(() => notifySuccess("User address successfully added"))
      .catch(err => {
        if (err.response.status === 409) {
          return form.setError(
            "email",
            {
              message: "User address with given value already exists",
            },
            { shouldFocus: true }
          );
        }
        return notifyErrorOrDefaultTo("Error occurred when trying to add user address")(err);
      })
      .finally(() => setProgress(false));
  };
  return (
    <Dialog onClose={onClose} id="add-user-address-dialog" title="Add User Address">
      <Form form={form}>
        <RadioGroupField
          name="identifier"
          values={[
            { key: "email", label: "Email", value: "email" },
            { key: "mobile", label: "Mobile", value: "mobile" },
          ]}
          onChange={() => setRequestError("")}
          style={{ marginBottom: 12 }}
        />

        {identifier === "email" && (
          <TextFieldRequired
            name="email"
            label="Email"
            rules={{
              validate: {
                validEmail: validators.validEmail({ label: "Value" }),
              },
            }}
            inputProps={{ autoComplete: "off" }}
            optional={false}
            externalErrors={requestError || null}
            onChange={() => setRequestError("")}
          />
        )}
        {identifier === "mobile" && (
          <PhoneField
            name="phone"
            label="Phone"
            selector="#add-user-address-phone-input"
            optional={false}
            required
            externalErrors={requestError || null}
            onChange={() => setRequestError("")}
          />
        )}

        <CheckboxField name="verified" label="Verified" />

        <FormFooter onCancel={onClose} onSubmit={handleSubmit} submitText="Add" />
      </Form>
    </Dialog>
  );
}
