import React, { ReactNode } from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: 32,
  },
  content: {
    flex: 1,
    margin: "0 24px",
  },
  title: {
    marginBottom: 8,
  },
  text: {
    color: theme.palette.secondary.light,
  },
  iconContainer: {
    backgroundColor: "#F0F7FF",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    height: 100,
    img: {
      maxWidth: 60,
      width: "100%",
    },
  },
  icon: {
    width: 36,
    height: 36,
    color: theme.palette.primary.main,
  },
}));

interface Props {
  img?: string;
  icon?: any;
  graphic?: any;
  title: string;
  text: ReactNode;
}

const InstructionWithGraphic = ({ icon: Icon, img, graphic, title, text }: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        {img && <img src={img} alt="icon" />}
        {Icon && <Icon className={classes.icon} />}
      </div>
      <div className={classes.content}>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="textMD" className={classes.text}>
          {text}
        </Typography>
      </div>
      {graphic && <img src={graphic} alt="graphic" />}
    </div>
  );
};

export default InstructionWithGraphic;
