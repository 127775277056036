import isEmpty from "lodash/isEmpty";

import { SupportedJSONSchema } from "@cloudentity/acp-identity";

import { getPathsAndTypes } from "../../../schemas/schemas.utils";
import { Values } from "./UsersFilter";

const valueToType = value => (Array.isArray(value) ? "array" : typeof value);

const valueToString: (value: any) => string = value => {
  switch (value) {
    case Array.isArray(value):
      return value.join(",");
    case typeof value === "boolean":
      return value ? "true" : "false";
    default:
      return value as string;
  }
};

export const toValue = searchText => {
  if (isEmpty(searchText)) {
    return [];
  }

  const keys = Object.keys(searchText);
  const payloadKeys = Object.keys(searchText["payload"] || {});
  const metadataKeys = Object.keys(searchText["metadata"] || {});

  return [
    ...keys
      .filter(k => k !== "payload" && k !== "metadata")
      .map(k => [
        { path: k, type: valueToType(searchText[k]), group: undefined, value: undefined },
        { value: valueToString(searchText[k]) },
      ])
      .flat(),
    ...payloadKeys
      .map(k => [
        {
          path: k,
          group: "payload",
          type: valueToType(searchText["payload"][k]),
          value: undefined,
        },
        {
          value: valueToString(searchText["payload"][k]),
        },
      ])
      .flat(),
    ...metadataKeys
      .map(k => [
        {
          path: k,
          group: "metadata",
          type: valueToType(searchText["metadata"][k]),
          value: undefined,
        },
        {
          value: valueToString(searchText["metadata"][k]),
        },
      ])
      .flat(),
  ];
};

export const valueToFiltering = value => {
  let f: any = [];
  for (let i = 0; i < value.length; i = i + 2) {
    f[i] = {
      group: value[i].group,
      path: value[i].path,
      type: value[i].type,
      value:
        value[i].type === "array"
          ? value[i + 1].value?.split(",")
          : value[i].type === "boolean"
          ? value[i + 1].value === "true"
          : value[i].type === "number"
          ? parseInt(value[i + 1].value || "") ?? value[i + 1].value
          : value[i + 1].value,
    };
  }

  return f.reduce(
    (acc, v) => ({
      ...acc,
      ...(v.group
        ? {
            [v.group]: { ...acc[v.group], [v.path]: v.value },
          }
        : { [v.path]: v.value }),
    }),
    {}
  );
};

const isEmptyValue = (value: Values) => value.length % 2 === 0;

export const getOptions = (
  value: Values,
  payloadSchema: SupportedJSONSchema,
  metadataSchema: SupportedJSONSchema,
  businessMetadataSchema: SupportedJSONSchema
) => {
  const selectedPaths = value
    .map(v => (v.group ? `${v.group}.${v.path}` : v.path))
    .filter(v => !!v);

  const commonOptions = [
    { path: "user_key", type: "string" },
    ...getPathsAndTypes(payloadSchema).map(v => ({
      ...v,
      group: "payload",
    })),
    ...getPathsAndTypes(metadataSchema).map(v => ({
      ...v,
      group: "metadata",
    })),
    ...getPathsAndTypes(businessMetadataSchema).map(v => ({
      ...v,
      group: "business_metadata",
    })),
  ].filter(v => !selectedPaths.includes("group" in v && v.group ? `${v.group}.${v.path}` : v.path));

  return isEmptyValue(value)
    ? commonOptions
    : value.length % 2 === 1 && value[value.length - 1].type === "boolean"
    ? [{ value: "true" }, { value: "false" }]
    : [];
};

export const getInitialValue = (searchText: string) => {
  try {
    let parsed = JSON.parse(searchText);
    return toValue(parsed);
  } catch (e) {
    return [];
  }
};
