import React from "react";

import WarningIcon from "@mui/icons-material/WarningAmberOutlined";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "tss-react/mui";

import { Script } from "@cloudentity/acp-admin";

import { useGetEnvironment } from "../../services/adminEnvironmentQuery";
import Tooltip from "../common/Tooltip";
import { useExtensions } from "./extensions-context";

interface Props {
  script: Script;
}

export const zeroDate = "0001-01-01T00:00:00Z";

export default function OutdatedRuntimeButton({ script }: Props) {
  const { classes } = useStyles();

  const getEnvironmentQuery = useGetEnvironment();
  const { openBottomSectionTab, openTab, tabs } = useExtensions();

  const scriptTab = tabs.find(tab => tab.script?.id === script?.id);

  const runtime = getEnvironmentQuery.data?.script_runtimes?.find(sr =>
    scriptTab
      ? scriptTab.script.env_version === sr.env_version
      : script.env_version === sr.env_version
  );

  if (zeroDate === runtime?.valid_until) {
    return null;
  }

  return (
    <Tooltip title="Deprecated runtime">
      <IconButton
        style={{
          width: 28,
          height: 28,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 4,
        }}
        aria-label={`Deprecated runtime warning for script ${script.name}`}
        onClick={e => {
          e.stopPropagation();
          openTab(script);
          openBottomSectionTab("runtime");
        }}
      >
        <WarningIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
}

const useStyles = makeStyles()(theme => ({
  icon: {
    fontSize: 18,
    color: theme.palette.secondary.light,
    margin: "0 4px",
  },
}));
