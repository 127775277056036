import React, { useState } from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useQueryClient } from "@tanstack/react-query";
import { MoreVertical, Plus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import {
  BaseUserWithData,
  UserIdentifier,
  UserIdentifierTypeEnum,
  UserWithData,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../../common/api/paths";
import AccordionPaper from "../../../../../../../../common/components/AccordionPaper";
import ConfirmationDialog from "../../../../../../../../common/components/ConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../../common/components/notifications/notificationService";
import { useCheckPoolPermissions } from "../../../../../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../../../../../services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listUsersQueryKey,
} from "../../../../../../../services/adminIdentityUsersQuery";
import AddUserIdentifierDialog from "../AddUserIdentifierDialog";
import { iconIdentifierMapper } from "../utils";

const useStyles = makeStyles()(theme => ({
  accordionLabel: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
  },
  accordionLabelCounter: {
    backgroundColor: "#F0F7FF",
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  menuPaper: {
    minWidth: 165,
  },
  accordionFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  empty: {
    color: "gray",
    fontSize: 12,
  },
}));

interface Props {
  user: UserWithData | BaseUserWithData;
  identifiersAccordionOpen: boolean;
  setIdentifiersAccordionOpen: (isOpen: boolean) => void;
  poolId: string;
}

export default function IdentityPoolUserSidePanelIdentifiers({
  user,
  identifiersAccordionOpen,
  setIdentifiersAccordionOpen,
  poolId,
}: Props) {
  const { classes } = useStyles();
  const theme = useTheme();

  const [moreMenu, setMoreMenu] = useState<{
    anchorEl: HTMLElement;
    identifier: UserIdentifier;
  }>();
  const [addDialog, setAddDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState<UserIdentifier>();
  const [progress, setProgress] = useState(false);
  const queryClient = useQueryClient();
  const tenantId = getTenantId();

  const identifiers = (user.identifiers ?? []).filter(
    identifier => identifier.type !== UserIdentifierTypeEnum.Federated
  );

  const handleDelete = (identifier: UserIdentifier) => {
    setProgress(true);
    identityUsersApi
      .deleteUserIdentifier({
        ipID: identifier.user_pool_id,
        userID: identifier.user_id,
        identifier: {
          identifier: identifier.identifier,
        },
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getUserQueryKey(tenantId, identifier.user_id) })
      )
      .then(() => queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, poolId) }))
      .then(() => setDeleteDialog(undefined))
      .then(() => notifySuccess("User identifier successfully removed"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to remove user identifier"))
      .finally(() => setProgress(false));
  };

  const checkPoolPermissionsQuery = useCheckPoolPermissions(user?.user_pool_id!, {
    enabled: !!user?.user_pool_id,
  });

  return (
    <>
      <AccordionPaper
        id="pool-user-identifiers-accordion"
        label={
          <div className={classes.accordionLabel}>
            <span>Identifiers</span>
            <Typography variant="textSM" component="div" className={classes.accordionLabelCounter}>
              {identifiers.length ?? ""}
            </Typography>
          </div>
        }
        style={{ padding: 0, marginTop: 24, background: "none" }}
        expanded={identifiersAccordionOpen}
        onChange={() => setIdentifiersAccordionOpen(!identifiersAccordionOpen)}
      >
        {identifiers.map(identifier => (
          <OutlinedInput
            id={`pool-user-identifier-${identifier.id}`}
            key={identifier.id}
            startAdornment={
              <InputAdornment position="start">
                {iconIdentifierMapper[identifier.type].icon}
              </InputAdornment>
            }
            endAdornment={
              !!checkPoolPermissionsQuery.data?.manage_user_identifiers && (
                <IconButton
                  aria-label="toggle menu visibility"
                  tabIndex={-1}
                  onClick={e => setMoreMenu({ identifier, anchorEl: e.currentTarget })}
                  id={`${identifier.id}-more-button`}
                  disabled={progress}
                >
                  <MoreVertical size={16} />
                </IconButton>
              )
            }
            value={identifier.identifier}
            disabled
            fullWidth
            style={{ backgroundColor: "#FBFCFD", marginBottom: 16 }}
            inputProps={{
              style: { WebkitTextFillColor: theme.palette.secondary.light },
            }}
          />
        ))}

        <div className={classes.accordionFooter}>
          {!identifiers.length ? <div className={classes.empty}>No Identifiers</div> : <div />}
          {!!checkPoolPermissionsQuery.data?.manage_user_identifiers && (
            <Button id="add-user-identifier-button" onClick={() => setAddDialog(true)}>
              <Plus size={16} style={{ marginRight: 8 }} /> Add
            </Button>
          )}
        </div>
      </AccordionPaper>

      {moreMenu && !!checkPoolPermissionsQuery.data?.manage_user_identifiers && (
        <Menu
          id="pool-user-side-panel-identifier-menu"
          anchorEl={moreMenu.anchorEl}
          keepMounted
          open={Boolean(moreMenu.anchorEl)}
          onClose={() => setMoreMenu(undefined)}
          classes={{ paper: classes.menuPaper }}
        >
          <MenuItem
            id="pool-user-side-panel-identifier-menu__delete-button"
            onClick={() => {
              setDeleteDialog(moreMenu.identifier);
              setMoreMenu(undefined);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      )}

      {addDialog && !!checkPoolPermissionsQuery.data?.manage_user_identifiers && (
        <AddUserIdentifierDialog onClose={() => setAddDialog(false)} />
      )}

      {deleteDialog && (
        <ConfirmationDialog
          title="Delete Identifier"
          content={
            <>
              You're about to delete the <b>{deleteDialog.identifier}</b> identifier. This cannot be
              undone. Delete anyway?
            </>
          }
          confirmText="Delete"
          progress={progress}
          onCancel={() => setDeleteDialog(undefined)}
          onConfirm={() => handleDelete(deleteDialog)}
        />
      )}
    </>
  );
}
