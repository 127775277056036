export const custom = {
  shadows: {
    surfaceResting: "0px 1px 1px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(0, 0, 0, 0.31)",
    surfaceHover: "0px 1px 16px -4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.31)",
    menu: "0px 4px 24px rgba(0, 0, 0, 0.1), 0px 3px 25px rgba(0, 0, 0, 0.1)",
    formInner: "0px 1px 1px rgba(0, 0, 0, 0.04), 0px 3px 2px rgba(0, 0, 0, 0.04)",
  },
  greys: {
    textDisabled: "#A0A3B5",
    backgroundDisabled: "#F5F5F5",
    background: "#F4F4F4",
    border: "#DCDCDC",
    default: "#C2C3C6",
    fieldBackground: "#FCFCFF",
  },
  headerBackground: "#06223B",
  sa: {
    brand: {
      low: "#eff4fb",
      medium: "#84ADE1",
      hover: "#0075e2",
      high: "#0e2139",
    },
    green: {
      low: "#EAFDCE",
      medium: "#C3F380",
      high: "#A0DA54",
    },
    yellow: {
      low: "#FDF9D6",
      medium: "#FAF1A7",
      high: "#FFEB3C",
    },
    complimentary: {
      red: {
        low: "#FFF8F7",
        medium: "#FFE2E1",
        high: "#CF3228",
      },
      green: {
        low: "#F5FFFA",
        medium: "#ECFDF5",
        high: "#10B981",
      },
      warning: {
        low: "#FFF9DC",
        medium: "#FFF6B4",
        high: "#E08D16",
      },
    },
    greys: {
      g0: "#FBFCFD",
      g20: "#F2F3F4",
      g40: "#E9EBEC",
      g60: "#CECECE",
      g80: "#A4A6A7",
      g100: "#969799",
    },
    neutrals: {
      n0: "#FCFCFF",
      n10: "#F7F8FF",
      n20: "#F2F4FF",
      n40: "#D0D5DD",
      n60: "#9EA1B7",
      n80: "#626576",
      n100: "#212533",
    },
    dark: {
      dark: "#0D1F32",
      paleGrey: "#213549",
      darkGrey: "#213549",
    },
    core: {
      surface: "#F7FAFF",
    },
    shadows: {
      s600: "0px 1.478px 3.449px -1.478px rgba(24, 39, 75, 0.12), 0px 2.463px 7.883px -0.985px rgba(24, 39, 75, 0.10)",
    },
  },
};
