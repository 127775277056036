import React from "react";
import { useLocation } from "react-router";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { Users } from "react-feather";
import { makeStyles } from "tss-react/mui";

import BackToWorkspaceNavItem from "../admin/components/nav/BackToWorkspaceNavItem";
import { NavDrawerContentProps } from "../admin/components/nav/NavDrawer";
import NavItem from "../admin/components/nav/NavItem";
import { useCheckTenantPermissions } from "../admin/services/adminPermissionsQuery";
import OrganizationsIcon from "../common/components/icons/OrganizationsIcon";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  listPadding: {
    padding: 0,
    overflow: "auto",
  },
}));

export default function OrganizationsDirectoryNavDrawerContent({
  onItemClick,
  isOpen,
}: NavDrawerContentProps) {
  const { classes } = useStyles();
  const { pathname } = useLocation();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canManageAdministrators = !!checkTenantPermissionsQuery.data?.manage_admin_role;

  return (
    <div className={classes.container}>
      <List classes={{ padding: classes.listPadding }}>
        <BackToWorkspaceNavItem isOpen={isOpen} navigateToUrl="users" />
        <Divider />

        <NavItem
          text="Organizations"
          to="/organizations"
          id="organizations-side-nav-item"
          icon={OrganizationsIcon}
          onClick={onItemClick}
          isActive={pathname.startsWith(`/organizations`)}
        />

        {canManageAdministrators && (
          <NavItem
            text="Administrators"
            to="/administrators"
            id="administrators-side-nav-item"
            icon={Users}
            onClick={onItemClick}
            isActive={pathname.startsWith("/administrators")}
            ignoreMatchAndActiveToAllowNavigate
          />
        )}
      </List>
    </div>
  );
}
