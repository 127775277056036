import { License } from "@cloudentity/acp-admin";

import adminLicenseApi from "./adminLicenseApi";
import { useQueryWithTenantPermissionCheck, withQueryError } from "./queryUtils";

const GET_LICENSE_QUERY = "GET_LICENSE_QUERY";

export const getLicenseQueryKey = () => [GET_LICENSE_QUERY];

export const useGetLicense = (options?) =>
  useQueryWithTenantPermissionCheck<License>(
    "get_tenant", // FIXME
    getLicenseQueryKey(),
    withQueryError<License>(async () => {
      const data = await adminLicenseApi.getLicense();
      return data.data;
    }, "Error occurred while trying to fetch license"),
    options
  );
