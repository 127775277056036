import React, { CSSProperties, ElementType, ReactNode } from "react";

import Dialog from "@mui/material/Dialog";

import { StepActions, StepActionsProps, StepContainer } from "./StepComponents";
import SvgMask from "./SvgMask";

export interface BasicModalStepProps {
  ProgressComponent?: ElementType | false;
  ActionsComponent?: ElementType<StepActionsProps> | false;
  cardStyles?: CSSProperties;
  dialogStyles?: CSSProperties;
  onMaskClick?: () => void;
}

export const EmptyComponent = ({ children }: { children: ReactNode }) => <>{children}</>;

export const BasicModalStep = ({
  ActionsComponent,
  ProgressComponent,
  cardStyles,
  dialogStyles,
  onMaskClick,
  children,
}: BasicModalStepProps & { children: ReactNode }) => {
  const Actions = ActionsComponent === false ? EmptyComponent : ActionsComponent || StepActions;
  const Progress = ProgressComponent === false ? EmptyComponent : ProgressComponent;

  return (
    <>
      <SvgMask onClick={onMaskClick} />
      <Dialog open hideBackdrop onBackdropClick={onMaskClick} PaperProps={{ style: dialogStyles }}>
        <StepContainer cardStyles={cardStyles}>
          {children}
          <Actions>{Progress && <Progress />}</Actions>
        </StepContainer>
      </Dialog>
    </>
  );
};
