import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";

import { useListWorkspacePools } from "../../../../services/adminIdentityPoolsQuery";
import { useWorkspace } from "../../../common/useWorkspace";
import { usePoolRootUrl } from "../utils";
import IdentityPool from "./IdentityPool";

export default function IdentityPoolForView() {
  const { id = "", tab: initTab = "users" } = useParams<{ id: string; tab: string }>();
  const [workspace] = useWorkspace();

  const { rootUrl, mode } = usePoolRootUrl();

  const navigate = useNavigate();

  const handleTabChange = (tab: string) => {
    navigate(`/${rootUrl}/${id}/${tab}`);
  };

  const listWorkspacePoolsQuery = useListWorkspacePools(
    { wid: workspace },
    { enabled: mode === "workspace" }
  );

  const workspacePools = useMemo(
    () => listWorkspacePoolsQuery.data?.pools ?? [],
    [listWorkspacePoolsQuery.data]
  );

  useEffect(() => {
    if (
      mode === "workspace" &&
      listWorkspacePoolsQuery.isSuccess &&
      !workspacePools.find(pool => pool.id === id)
    ) {
      navigate(`/${rootUrl}`);
    }
  }, [workspace, workspacePools, listWorkspacePoolsQuery.isSuccess, id, mode, navigate, rootUrl]);

  return <IdentityPool id={id} serverId={workspace} tab={initTab} onTabChange={handleTabChange} />;
}
