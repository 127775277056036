import React, { Dispatch, SetStateAction } from "react";

import MoreHoriz from "@mui/icons-material/MoreHoriz";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { Code, Feather } from "react-feather";

import {
  OrganizationResponse,
  ServerBindingResponse,
  Theme,
  WorkspaceResponse,
} from "@cloudentity/acp-admin";

import OverflowTooltip from "../../../../common/components/OverflowTooltip";
import SelectablePaper from "../../../../common/components/SelectablePaper";
import { BRAND_NAME } from "../../../../common/theme/theme";
import WorkspaceAvatar from "../../nav/WorkspaceAvatar";
import { getServerTitle } from "../server-profiles";
import { WorkspaceTab } from "../workspace-directory.common";
import { useWorkspaceCardStyles } from "./utils";

export interface WorkspaceCardProps<
  T extends Pick<OrganizationResponse | WorkspaceResponse, "id" | "name" | "theme_id">
> {
  tab: WorkspaceTab;
  server: T;
  progress: boolean;
  connectProgress?: boolean;
  deleteProgress: boolean;
  serversBindings: ServerBindingResponse[];
  onSelectServer: (aid: string) => void;
  isFullCard: boolean;
  themes: Theme[];
  themesProgress: boolean;
  onMenuOpen: Dispatch<SetStateAction<{ anchorEl: HTMLElement; server: T } | undefined>>;
}

export default function WorkspaceCard<
  T extends Pick<OrganizationResponse | WorkspaceResponse, "id" | "name" | "theme_id" | "template">
>({
  tab,
  server,
  progress,
  connectProgress,
  deleteProgress,
  serversBindings,
  onSelectServer,
  isFullCard,
  themes,
  themesProgress,
  onMenuOpen,
}: WorkspaceCardProps<T>) {
  const { cx, classes } = useWorkspaceCardStyles();
  const { id = "", name = "" } = server;

  return (
    <div className={classes.root}>
      {deleteProgress && (
        <CircularProgress style={{ position: "absolute", top: 32, left: "calc(50% - 20px)" }} />
      )}
      {!deleteProgress && (
        <SelectablePaper id={`${id}-paper`} className={classes.card} selectable>
          <div className={classes.cardTop}>
            <div className={classes.cardTopLeft} onClick={() => onSelectServer(id)}>
              <WorkspaceAvatar server={server} />
              <div className={classes.cardTopLeftName}>
                <OverflowTooltip
                  id="workspace-card-name-tooltip"
                  value={name}
                  typographyStyle={{ fontWeight: 600 }}
                />
                <Typography variant="textXSM" className={classes.cardTopLeftType}>
                  {getServerTitle(server)}
                </Typography>
              </div>
            </div>
            <div className={classes.cardActions}>
              <IconButton
                onClick={e => onMenuOpen({ anchorEl: e.currentTarget, server })}
                id={`${id}-menu-button`}
                className={classes.iconButton}
              >
                {!progress ? <MoreHoriz /> : <CircularProgress size="21px" />}
              </IconButton>
            </div>
          </div>
          {isFullCard && (
            <div>
              <div className={classes.cardBottom}>
                <div className={classes.cardBottomItem}>
                  <div className={classes.cardBottomItemIcon}>
                    <Feather size="14" />
                  </div>
                  <Typography variant="textXSM" className={classes.cardBottomItemText}>
                    {themesProgress ? (
                      <Skeleton variant="text" style={{ width: 100 }} />
                    ) : (
                      (server.theme_id &&
                        (themes.find(theme => theme.id === server.theme_id)?.name ||
                          server.theme_id)) ||
                      BRAND_NAME
                    )}
                  </Typography>
                </div>

                {(tab === WorkspaceTab.WORKSPACES_SEQ ||
                  tab === WorkspaceTab.WORKSPACES_CURSOR) && (
                  <div
                    className={cx(
                      classes.cardBottomItem,
                      !serversBindings.some(binding => binding.dependent === server.id) &&
                        classes.cardBottomItemInactive
                    )}
                  >
                    <div className={classes.cardBottomItemIcon}>
                      <Code size="14" />
                    </div>
                    <Typography variant="textXSM" className={classes.cardBottomItemText}>
                      {connectProgress ? (
                        <Skeleton variant="text" style={{ width: 100 }} />
                      ) : (
                        "Developer Portal"
                      )}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          )}
        </SelectablePaper>
      )}
    </div>
  );
}
