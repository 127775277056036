import { ReactNode } from "react";

import axios from "axios";
import { enqueueSnackbar } from "notistack";

import { allowedMessagesFor401 } from "../../api/unauthorized-redirect.interceptor";

const commonSnackbarOptions = { autoHideDuration: 6000, preventDuplicate: true };

export const handleErrorWithNotify = (message: string) => (err: unknown) => {
  if (
    axios.isAxiosError(err) &&
    (err.response?.status !== 401 || allowedMessagesFor401.includes(err.response?.data?.error))
  ) {
    const extendedMessage =
      err.message === "Network Error"
        ? `${message}: Network Error - Check your internet connection or turn off ad blocking plugin`
        : message;

    enqueueSnackbar({ message: extendedMessage, variant: "error", ...commonSnackbarOptions });
  } else {
    console.error(message, err);
  }
  return err as any;
};

export const notifyErrorOrDefaultTo = (message: string) => (err: unknown) => {
  if (axios.isAxiosError(err)) {
    const errorMessage = err.response?.data?.error ?? message;
    return handleErrorWithNotify(errorMessage)(err);
  }
  return err as any;
};

export const notifySuccess = (message: string | ReactNode) => {
  enqueueSnackbar({ message, variant: "success", ...commonSnackbarOptions });
};

export const notifyError = (message: string) => {
  enqueueSnackbar({ message, variant: "error", ...commonSnackbarOptions });
};

export const notifyWarning = (message: string) => {
  enqueueSnackbar({ message, variant: "warning", ...commonSnackbarOptions });
};

export const notifyInfo = (message: string) => {
  enqueueSnackbar({ message, variant: "info", ...commonSnackbarOptions });
};
