import React, { CSSProperties } from "react";

import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

import OverflowTooltip from "../../../common/components/OverflowTooltip";

const useStyles = makeStyles<{
  noIconWidth?: boolean;
  active?: boolean;
  noPadding?: boolean;
  noBorder?: boolean;
}>()((theme, { noIconWidth, active, noPadding, noBorder }) => ({
  container: {
    position: "relative",
    border: active ? `1px solid ${theme.palette.primary.main}` : "1px solid transparent",
    borderRadius: 4,
    backgroundColor: active === false ? "#F4F4F4" : "white",
    padding: noPadding ? 0 : "16px 12px",
    boxShadow: noBorder ? "none" : `0 0 0 1px rgba(0,0,0, 0.12)`,
  },
  icon: {
    width: noIconWidth ? "unset" : 48,
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
  },
  avatar: {
    marginRight: 8,
  },
}));

interface Props {
  title: string;
  subtitle?: string | JSX.Element;
  icon?: any;
  img?: any;
  avatar?: any;
  avatarColor?: string;
  id: string;
  style?: CSSProperties;
  titleStyle?: CSSProperties;
  subtitleStyle?: CSSProperties;
  noPadding?: boolean;
  noBorder?: boolean;
  active?: boolean;
  noIconWidth?: boolean;
}

export default function CardWithIconAndTitle({
  icon: Icon,
  img,
  avatar,
  avatarColor,
  title,
  subtitle,
  id,
  style = {},
  titleStyle = {},
  subtitleStyle = {},
  noPadding,
  noBorder,
  active,
  noIconWidth,
}: Props) {
  const { classes } = useStyles({ noIconWidth, active, noPadding, noBorder });
  const theme = useTheme();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-start"
      wrap="nowrap"
      className={classes.container}
      style={style}
    >
      {(img || Icon) && (
        <div className={classes.icon}>
          {img && <img src={img} style={{ width: 32 }} alt={title} />}
          {Icon && <Icon />}
        </div>
      )}
      {avatar && (
        <Avatar
          className={classes.avatar}
          style={{
            background: avatarColor,
          }}
        >
          {avatar}
        </Avatar>
      )}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <OverflowTooltip
          id={id}
          value={title}
          typographyStyle={{
            maxWidth: `calc(100% - 16px - ${noPadding ? 0 : 16}px)`,
            color:
              (active && theme.palette.primary.main) ||
              (active === false && theme.palette.secondary.light) ||
              "unset",
            ...titleStyle,
          }}
        />

        {subtitle && (
          <OverflowTooltip
            id={`${id}-subtitle`}
            value={subtitle}
            typographyStyle={{
              maxWidth: `calc(100% - 12px - ${noPadding ? 0 : 16}px)`,
              color: (active === false && theme.palette.secondary.light) || "unset",
              ...subtitleStyle,
            }}
            typographyProps={{
              variant: "caption",
              noWrap: true,
            }}
          />
        )}
      </div>
    </Grid>
  );
}
