import { Policies, PolicyExecutionPoints } from "@cloudentity/acp-admin";

import adminPoliciesApi from "./adminPoliciesApi";
import { useQueryWithWorkspacePermissionCheck, withQueryError } from "./queryUtils";

const GET_POLICY_QUERY = "GET_POLICY_QUERY";
const GET_POLICIES_QUERY = "GET_POLICIES_QUERY";
const GET_POLICY_EXECUTION_POINTS_QUERY = "GET_POLICY_EXECUTION_POINTS_QUERY";

export const getPolicyQueryKey = (pid: string | undefined) => [GET_POLICY_QUERY, pid];
export const getPoliciesQueryKey = (
  tid: string,
  wid: string,
  policyTypes?: string,
  searchPhrase = ""
) => [GET_POLICIES_QUERY, tid, wid, policyTypes, searchPhrase];
export const getPolicyExecutionPointsQueryKey = (tid, wid) => [
  GET_POLICY_EXECUTION_POINTS_QUERY,
  tid,
  wid,
];

export const useGetPolicy = (pid: string, wid: string, options?) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "read_policies",
    getPolicyQueryKey(pid),
    withQueryError(async () => {
      const data = await adminPoliciesApi.getPolicy({ pid });
      return data.data;
    }, "Error occurred while trying to fetch policy"),
    options
  );

export const useGetPolicies = (
  tid,
  wid,
  policyTypes?,
  limit = 100, // FIXME: we shouldn't list policies in places where we are trying to match policy by ID as it won't find policy if there are more policies than limit
  searchPhrase = ""
) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "read_policies",
    getPoliciesQueryKey(tid, wid, policyTypes, searchPhrase),
    withQueryError<Policies>(async () => {
      const data = await adminPoliciesApi.getPolicies({
        wid,
        policyTypes,
        searchPhrase,
        limit,
      });
      return data.data;
    }, "Error occurred while trying to fetch policies")
  );

export const useGetPolicyExecutionPoints = (tid, wid, options?) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "read_policies",
    getPolicyExecutionPointsQueryKey(tid, wid),
    withQueryError<PolicyExecutionPoints>(async () => {
      const data = await adminPoliciesApi.getPolicyExecutionPoints({ wid });
      return data.data;
    }, "Error occurred while trying to fetch policy execution points"),
    options
  );
