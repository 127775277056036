import React from "react";

import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Trash } from "react-feather";

import { Claim } from "@cloudentity/acp-admin";

import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import EnhancedTable from "../../common/EnhancedTable";
import { useWorkspace } from "../../common/useWorkspace";
import { ClaimType, prepareClaimForUpdate } from "./ClaimUtils";
import TabHeader from "./TabHeader";

const headCells = [
  { id: "name", label: "Name", align: "left", sortable: true, style: { paddingLeft: 32 } },
  {
    id: "description",
    label: "Description",
    align: "left",
    sortable: true,
    style: { paddingLeft: 32 },
  },
  { id: "actions", label: "", align: "right" },
];

interface OAuthClaimsTabProps {
  claims: Claim[];
  fetching: boolean;
  highlightedItem: Claim | undefined;
  setShowCreateDialog: (value: { type: ClaimType } | undefined) => void;
  setShowEditDialog: (claim: Claim | undefined) => void;
  setRemoveConfirmDialog: (claim: Claim | undefined) => void;
  type: ClaimType;
  title: string;
  id: string;
  progressRow: string | null;
}

export default function VerifiedClaimsTable({
  claims,
  fetching,
  highlightedItem,
  setShowCreateDialog,
  setShowEditDialog,
  setRemoveConfirmDialog,
  type,
  title,
  id,
  progressRow,
}: OAuthClaimsTabProps) {
  const [workspace] = useWorkspace();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  return (
    <div>
      <TabHeader
        onAdd={() => setShowCreateDialog({ type })}
        length={claims.length}
        subtitle={title}
        loading={fetching}
      />

      <EnhancedTable
        id={id}
        highlightedItem={highlightedItem}
        data={claims}
        headCells={headCells}
        loading={fetching}
        createRow={(row, _, classes, cx, highlighted) => (
          <TableRow
            key={row.id}
            hover={checkWorkspacePermissionsQuery.data?.update_workspace}
            onClick={
              checkWorkspacePermissionsQuery.data?.update_workspace
                ? () => setShowEditDialog(prepareClaimForUpdate(row))
                : undefined
            }
            className={cx(
              checkWorkspacePermissionsQuery.data?.update_workspace
                ? [classes.rowWithHoverIcons, classes.row]
                : []
            )}
            style={{
              height: 57,
              position: "relative",
              ...(highlighted && { backgroundColor: "#F7FAFF" }),
            }}
          >
            <TableCell style={{ paddingLeft: 32 }}>
              {progressRow === row.id && (
                <LinearProgress
                  style={{
                    position: "absolute",
                    width: "100%",
                    left: 0,
                    top: 0,
                  }}
                />
              )}
              {row.name}
            </TableCell>
            <TableCell style={{ paddingLeft: 32 }}>{row.description}</TableCell>
            <TableCell align="right" padding="none" style={{ padding: "0 32px 0 5px" }}>
              {checkWorkspacePermissionsQuery.data?.update_workspace && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    setRemoveConfirmDialog(row);
                  }}
                  aria-label="delete"
                  className={classes.iconButton}
                  size="large"
                >
                  <Trash size="14" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        )}
        emptyStateText="No claims found"
      />
    </div>
  );
}
