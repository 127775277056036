import React from "react";

import { useListUsersWithPagination } from "../../../services/adminIdentityUsersQuery";
import PageContainer from "../../common/PageContainer";
import PageHeader from "../../common/PageHeader";
import AdministratorUsersTable from "./AdministratorUsersTable";
import { useTenantRoles } from "./useTenantRoles";

export const BUILD_IN_ADMIN_POOL_ID = "built-in-admin-pool";

export default function AdministratorManagement() {
  const { isResolved: isTenantRolesResolved } = useTenantRoles({
    identityPoolId: BUILD_IN_ADMIN_POOL_ID,
  });

  const usersWithPagination = useListUsersWithPagination(BUILD_IN_ADMIN_POOL_ID);

  return (
    <PageContainer progress={usersWithPagination.isLoading || !isTenantRolesResolved}>
      <PageHeader title="Administrators"></PageHeader>

      {isTenantRolesResolved && usersWithPagination.isSuccess ? (
        <AdministratorUsersTable
          workspaceId="admin"
          identityPoolID={BUILD_IN_ADMIN_POOL_ID}
          usersWithPagination={usersWithPagination}
        />
      ) : null}
    </PageContainer>
  );
}
