import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import RouterLink from "../../../../common/components/RouterLink";
import SelectField from "../../../../common/utils/forms/SelectField";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import {
  getTenantRoleOptionsByPermissions,
  tenantRoleOptions,
} from "../../workspaceDirectory/administrator/AdministratorUserEdit";

const useStyles = makeStyles()(theme => ({
  header: {
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    color: theme.palette.secondary.light,
    maxWidth: 480,
  },
}));

export const IdentitiesDetailsProvisioningAccessRole = () => {
  const { classes } = useStyles();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const isDisabled = !(
    checkTenantPermissionsQuery.data?.manage_admin_role ||
    checkTenantPermissionsQuery.data?.manage_auditor_role ||
    checkTenantPermissionsQuery.data?.manage_business_admin_role
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Typography variant="h5" className={classes.header}>
          Admin portal access role
        </Typography>
        <Typography variant="textMD" className={classes.headerText} component="div">
          User authenticating with this provider gets this access role unless a role exists at user
          record level. Fine grained access control beyond the roles can be configured using the{" "}
          <RouterLink to="/admin/applications/admin/policies">
            admin portal access policy
          </RouterLink>
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SelectField
          id="admin-role-type"
          name="admin_role_type"
          options={
            isDisabled
              ? tenantRoleOptions
              : getTenantRoleOptionsByPermissions(checkTenantPermissionsQuery.data)
          }
          disabled={isDisabled}
        />
      </Grid>
    </Grid>
  );
};
