import { QueryFunctionContext, useInfiniteQuery, useQuery } from "@tanstack/react-query";

import {
  ServerResponse,
  ServersApiListAuthorizationServersRequest,
  ServersBindingsResponse,
  UserWorkspacesResponse,
  WorkspaceResponseTypeEnum,
  WorkspacesApiListUserWorkspacesRequest,
  WorkspacesApiListWorkspacesRequest,
  WorkspacesResponse,
} from "@cloudentity/acp-admin";

import adminPermissionsApi from "./adminPermissionsApi";
import { useCheckWorkspacePermissions } from "./adminPermissionsQuery";
import adminServersApi from "./adminServersApi";
import {
  useQueryWithTenantPermissionCheck,
  useQueryWithWorkspacePermissionCheck,
  withQueryError,
} from "./queryUtils";

const GET_WORKSPACE_QUERY = "GET_WORKSPACE_QUERY";
const GET_WORKSPACES_QUERY = "GET_WORKSPACES_QUERY";
const GET_AUTHORIZATION_SERVER_QUERY = "GET_AUTHORIZATION_SERVER_QUERY";
const GET_AUTHORIZATION_SERVERS_QUERY = "GET_AUTHORIZATION_SERVERS_QUERY";
const LIST_WORKSPACES_QUERY = "LIST_WORKSPACES_QUERY";
const LIST_ALL_WORKSPACES_INFINITE_QUERY_KEY = [LIST_WORKSPACES_QUERY, "all"];
const LIST_USER_WORKSPACES_QUERY = "LIST_USER_WORKSPACES_QUERY";
const LIST_ALL_USER_WORKSPACES_INFINITE_QUERY = [LIST_WORKSPACES_QUERY, "user", "all"];
const LIST_AUTHORIZATION_SERVER_HIERARCHY_BY_PARENT_ID_QUERY_KEY = (wid: string) => [
  "LIST_AUTHORIZATION_SERVERS_HIERARCHY_BY_PARENT_ID_QUERY_KEY",
  wid,
];
export const GET_SERVER_CONSENT_QUERY = "GET_SERVER_CONSENT_QUERY";
export const GET_CIBA_AUTHENTICATION_SERVICE_QUERY = "GET_CIBA_AUTHENTICATION_SERVICE_QUERY";
export const LIST_SERVER_BINDINGS_QUERY = "LIST_SERVER_BINDINGS_QUERY";

export const getAuthorizationServerQueryKey = (tid, wid) => [
  GET_AUTHORIZATION_SERVER_QUERY,
  tid,
  wid,
];

export const getAuthorizationServersQueryKey = [GET_AUTHORIZATION_SERVERS_QUERY];

export const getWorkspacesQueryKey = (wids: string[]) => [GET_WORKSPACES_QUERY, wids];

export const getWorkspaceQueryKey = (wid: string) => [GET_WORKSPACE_QUERY, wid];

export const listWorkspacesQueryKey = (limit?: number) =>
  limit !== undefined ? [LIST_WORKSPACES_QUERY, limit] : [LIST_WORKSPACES_QUERY];

export const listUserWorkspacesQueryKey = (limit?: number) =>
  limit !== undefined ? [LIST_USER_WORKSPACES_QUERY, limit] : [LIST_USER_WORKSPACES_QUERY];

export const useGetAuthorizationServer = (tid, wid, options?) =>
  useQueryWithWorkspacePermissionCheck(
    wid,
    "get_workspace",
    getAuthorizationServerQueryKey(tid, wid),
    async () => {
      const data = await adminServersApi.getAuthorizationServer({ wid });
      return data.data;
    },
    options
  );

// use list workspace instead
export const useGetAuthorizationServers = (
  params: ServersApiListAuthorizationServersRequest,
  options?: any
) =>
  useQuery({
    queryKey: getAuthorizationServersQueryKey,
    queryFn: async () => {
      const data = await adminServersApi.listAuthorizationServers(params);
      return data.data;
    },
    ...options,
  });

export const useGetWorkspace = (wid: string, options?: any) =>
  useQuery<ServerResponse>({
    queryKey: getWorkspaceQueryKey(wid),
    queryFn: async () => {
      const data = await adminServersApi.getWorkspace({ wid });
      return data.data;
    },
    ...{
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
    },
  });

export const useGetWorkspaces = (wids: string[], options?: any) =>
  useQuery<ServerResponse[]>({
    queryKey: getWorkspacesQueryKey(wids),
    queryFn: async () => {
      const data = await Promise.all(wids.map(wid => adminServersApi.getWorkspace({ wid })));
      return data.map(data => data.data);
    },
    ...options,
  });

export const useListWorkspaces = (
  { limit, ...params }: WorkspacesApiListWorkspacesRequest,
  options?: any
) =>
  useQuery<WorkspacesResponse>({
    queryKey: [...listWorkspacesQueryKey(limit), params],
    queryFn: async () => {
      const data = await adminServersApi.listWorkspaces({ limit, ...params });
      return data.data;
    },
    ...options,
  });

export const useListUserWorkspaces = (
  { limit, ...params }: WorkspacesApiListUserWorkspacesRequest,
  options?: any
) =>
  useQuery<UserWorkspacesResponse>({
    queryKey: [...listUserWorkspacesQueryKey(limit), params],
    queryFn: async () => {
      const data = await adminServersApi.listUserWorkspaces({ limit, ...params });
      return data.data;
    },
    ...options,
  });

export const useGetServerConsent = wid =>
  useQuery(
    {
      queryKey: [GET_SERVER_CONSENT_QUERY, wid],
      queryFn: withQueryError(async () => {
        const data = await adminServersApi.getServerConsent({ wid });
        return data.data;
      }, "Error occurred while trying to fetch server consent"),
      ...{ refetchOnWindowFocus: false, refetchOnMount: "always" },
    } // TODO since there is no topic yet for server consent refetch it as regular on every mount
  );

export const useGetCIBAAuthenticationService = wid =>
  useQuery(
    {
      queryKey: [GET_CIBA_AUTHENTICATION_SERVICE_QUERY, wid],
      queryFn: withQueryError(async () => {
        const data = await adminServersApi.getCIBAAuthenticationService({ wid });
        return data.data;
      }, "Error occurred while trying to fetch CIBA authentication service"),
      ...{ refetchOnWindowFocus: false, refetchOnMount: "always" },
    } // TODO since there is no topic yet for server consent refetch it as regular on every mount
  );

export const listServerBindingsQueryKey = tid => [LIST_SERVER_BINDINGS_QUERY, tid];

export const useListServerBindings = (tid, options?) =>
  useQueryWithTenantPermissionCheck(
    "read_server_bindings",
    listServerBindingsQueryKey(tid),
    withQueryError<ServersBindingsResponse>(async () => {
      const data = await adminServersApi.listServersBindings({});
      return data.data;
    }, "Error occurred while trying to list server bindings"),
    options
  );

type WorkspaceTypes =
  | `${WorkspaceResponseTypeEnum}`
  | `${WorkspaceResponseTypeEnum},${WorkspaceResponseTypeEnum}`
  | `${WorkspaceResponseTypeEnum},${WorkspaceResponseTypeEnum},${WorkspaceResponseTypeEnum}`
  | `${WorkspaceResponseTypeEnum},${WorkspaceResponseTypeEnum},${WorkspaceResponseTypeEnum},${WorkspaceResponseTypeEnum}`
  | `${WorkspaceResponseTypeEnum},${WorkspaceResponseTypeEnum},${WorkspaceResponseTypeEnum},${WorkspaceResponseTypeEnum},${WorkspaceResponseTypeEnum}`;

export const useListAllWorkspaces = (workspaceTypes?: WorkspaceTypes, options?: any) => {
  const limit = 100;
  const query = useInfiniteQuery<WorkspacesResponse>({
    queryKey: LIST_ALL_WORKSPACES_INFINITE_QUERY_KEY,
    queryFn: async ({
      pageParam,
    }: QueryFunctionContext<string[], { afterWorkspaceId: string | undefined } | null>) => {
      const listWorkspacesRes = await adminServersApi.listWorkspaces({
        limit,
        workspaceTypes,
        afterWorkspaceId: pageParam?.afterWorkspaceId,
        order: "asc",
        sort: "name",
      });

      return listWorkspacesRes.data;
    },
    ...{
      ...options,
      getNextPageParam: lastPage => {
        if ((lastPage.workspaces || []).length === limit) {
          return { afterWorkspaceId: lastPage.workspaces?.at(-1)?.id };
        }

        return null;
      },
    },
  });

  if (query.hasNextPage && !query.isFetchingNextPage) {
    query.fetchNextPage();
  }

  return query;
};

export const useListAllUserWorkspaces = (options?: any) => {
  const query = useInfiniteQuery<UserWorkspacesResponse>({
    queryKey: LIST_ALL_USER_WORKSPACES_INFINITE_QUERY,
    queryFn: async (context: QueryFunctionContext<string[], { cursor: string } | null>) => {
      const listUserWorkspacesRes = await adminServersApi.listUserWorkspaces({
        limit: 100,
        cursor: context?.pageParam?.cursor,
      });

      return listUserWorkspacesRes.data;
    },
    ...{
      ...options,
      getNextPageParam: lastPage => {
        if (!!lastPage.cursor) {
          return { cursor: lastPage.cursor };
        }
        return null;
      },
    },
  });

  if (query.hasNextPage && !query.isFetchingNextPage) {
    query.fetchNextPage();
  }

  return query;
};

export const useListAuthorizationServersHierarchyByParentId = (wid: string, options?: any) => {
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(wid);

  const query = useInfiniteQuery<ServerResponse>({
    queryKey: LIST_AUTHORIZATION_SERVER_HIERARCHY_BY_PARENT_ID_QUERY_KEY(wid),
    queryFn: async (context: QueryFunctionContext<string[], { wid: string } | null>) => {
      const permissionsRes = await adminPermissionsApi.checkWorkspacePermissions({
        wid: context?.pageParam?.wid || wid,
      });

      if (!!permissionsRes.data?.get_workspace) {
        const serverRes = await adminServersApi.getAuthorizationServer({
          wid: context?.pageParam?.wid || wid,
        });
        return serverRes.data;
      }

      return null;
    },
    ...{
      ...options,
      retry: 0,
      refetchOnWindowFocus: false,
      enabled: !!checkWorkspacePermissionsQuery.data?.get_workspace,
      getNextPageParam: lastPage => {
        if (!!lastPage?.parent_id) {
          return { wid: lastPage?.parent_id };
        }
        return null;
      },
    },
  });

  if (query.hasNextPage && !query.isFetchingNextPage && !query.isFetching) {
    query.fetchNextPage();
  }

  return query;
};
