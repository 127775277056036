import React from "react";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { AxiosResponse } from "axios";
import { makeStyles } from "tss-react/mui";

import { ListClientsWithAccess } from "@cloudentity/acp-public";

import AppAvatar from "../AppAvatar";
import FormSection from "../FormSection";
import SelfServiceCommonAccordion from "./SelfServiceCommonAccordion";
import SelfServiceRevokeAccess from "./SelfServiceRevokeAccess";
import SelfServiceTabContent from "./SelfServiceTabContent";
import { ParsedClient, useCommonStyles } from "./utils";

const useStyles = makeStyles()(theme => ({
  row: {
    padding: "10px 32px",
    color: theme.palette.secondary.light,
  },
  summaryContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  summary: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    alignContent: "center",
    color: theme.palette.secondary.light,
    padding: 16,
    borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
  },
  list: {
    paddingBottom: 8,
  },
  expanded: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
}));

interface Props {
  clientsData: ParsedClient[];
  fetchClients: (withSetter?: boolean) => Promise<AxiosResponse<ListClientsWithAccess, any> | null>;
}

export default function SelfServiceAccessManagement({ clientsData, fetchClients }: Props) {
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();

  return (
    <SelfServiceTabContent>
      <Paper className={commonClasses.paper}>
        <FormSection
          id="dashboard-header"
          title="Consent & sharing"
          subtitle="These are the third-party applications with account access. Remove access for third-party apps and services which you no longer trust or use."
        />

        {!clientsData.length && <div className={commonClasses.empty}>No applications</div>}

        {clientsData.map(data => (
          <SelfServiceCommonAccordion
            id={`client-summary-accordion-${data.client?.id}`}
            key={data.client?.id ?? ""}
            icon={<AppAvatar>{data.client?.name?.at(0)}</AppAvatar>}
            name={data.client?.name ?? ""}
            chips={<SelfServiceRevokeAccess client={data} fetchClients={fetchClients} />}
            noDetailsPadding
          >
            <div className={classes.summary}>
              <Typography variant="caption">Browse data scopes in use:</Typography>
            </div>
            <div id={`client-summary-scopes-list-${data.client?.id}`}>
              {data.granted_scopes?.map(scope => (
                <div className={classes.row} key={scope.scope_id}>
                  <Typography variant="h6" style={{ textTransform: "none" }}>
                    {scope.scope_display_name}
                  </Typography>
                  <Typography variant="textSM" style={{ lineHeight: "10px" }}>
                    {scope.scope_description}
                  </Typography>
                </div>
              ))}
            </div>
          </SelfServiceCommonAccordion>
        ))}
      </Paper>
    </SelfServiceTabContent>
  );
}
