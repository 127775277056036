import React, { useState } from "react";

import Apps from "@mui/icons-material/Apps";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Edit2 } from "react-feather";
import { makeStyles } from "tss-react/mui";

import IdpEditLogoUrlDialog from "./IdpEditLogoUrlDialog";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    flexDirection: "column",
    marginBottom: 24,
    marginRight: 20,
  },
  header: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 12,
    color: theme.palette.secondary.dark,
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  logoContainer: {
    width: 50,
    height: 50,
    background: "white",
    color: "#C9C9C9",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    boxShadow: theme.custom.shadows.surfaceResting,
    position: "relative",
  },
  logoEditButton: {
    zIndex: 1,
    marginTop: 28,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  editButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
    border: "none",
    borderRadius: "4px 0",
    padding: 5,
    cursor: "pointer",
  },
  menuContainer: {
    borderRadius: 4,
  },
}));

interface Props {
  logoURI: string | undefined;
  disabled?: boolean;
  onEdit: (logoURI: string) => void;
}
export default function IdpLogoManagement({ logoURI, disabled, onEdit }: Props) {
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editURIDialog, setEditURIDialog] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>Logo</div>
      <div className={classes.logoContainer}>
        {logoURI && <img src={logoURI} alt="client-logo" style={{ width: 42, height: 42 }} />}
        {!logoURI && <Apps style={{ width: 50, height: 50 }} />}
        {!disabled && (
          <button
            type="button"
            className={classes.editButton}
            onClick={handleClick}
            id="summary-panel-button-edit-logo"
            aria-label="Edit logo"
          >
            <Edit2 size={12} />
          </button>
        )}
      </div>
      <Menu
        id="summary-panel-logo-management-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menuContainer }}
      >
        <MenuItem
          onClick={() => {
            setEditURIDialog(true);
            handleClose();
          }}
        >
          Edit logo URI
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEdit("");
            handleClose();
          }}
        >
          Revert to default avatar
        </MenuItem>
      </Menu>
      {editURIDialog && (
        <IdpEditLogoUrlDialog
          logoURI={logoURI}
          onCancel={() => setEditURIDialog(false)}
          onEdit={onEdit}
        />
      )}
    </div>
  );
}
