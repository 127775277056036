import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import { X } from "react-feather";

import PageContainer from "../common/PageContainer";
import PageHeader, { PAGE_HEADER_HEIGHT } from "../common/PageHeader";
import useWorkspaceDirectoryData from "../welcome/useWorkspaceDirectoryData";
import WorkspaceDirectoryCreateContent from "./WorkspaceDirectoryCreateContent";
import WorkspaceDirectoryCreateSidePanel from "./WorkspaceDirectoryCreateSidePanel";

export const workspaceCreateSteps = [
  "defineWorkspaceType",
  "addWorkspaceDetails",
  "connectUserPool",
  "createDeveloperPortal",
  "connectDeveloperPool",
];

interface Props {
  onClose: (createdWorkspace?: string) => void;
}

export default function WorkspaceDirectoryCreate({ onClose }: Props) {
  const [currentStep, setCurrentStep] = useState("defineWorkspaceType");
  const [stepsData, setStepsData] = useWorkspaceDirectoryData();
  const [createdWorkspace, setCreatedWorkspace] = useState<string>();
  const type = stepsData.defineWorkspaceType.selected;

  return (
    <PageContainer>
      <PageHeader title="Create new workspace" containerStyle={{ paddingLeft: 32, margin: 0 }} />
      <IconButton
        onClick={() => onClose(createdWorkspace)}
        style={{ position: "absolute", top: 32, right: 32 }}
      >
        <X />
      </IconButton>
      <div
        style={{
          display: "flex",
          height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px)`,
          overflow: "hidden",
        }}
      >
        <WorkspaceDirectoryCreateSidePanel
          stepName={currentStep}
          stepIndex={workspaceCreateSteps.indexOf(currentStep)}
          type={type}
        />
        <WorkspaceDirectoryCreateContent
          step={currentStep}
          stepsData={stepsData}
          workspace={stepsData["addWorkspaceDetails"].authorizationServer.id}
          hideSkipCreateDeveloperPortal={false}
          onChangeStepsData={data => setStepsData(data)}
          onNext={step => () => setCurrentStep(step)}
          onBack={step => () => setCurrentStep(step)}
          onFinish={onClose}
          type={type}
          onWorkspaceCreate={(id: string) => setCreatedWorkspace(id)}
        />
      </div>
    </PageContainer>
  );
}
