import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { getTenantId } from "../../../../../common/api/paths";
import Dialog from "../../../../../common/components/Dialog";
import {
  handleErrorWithNotify,
  notifySuccess,
} from "../../../../../common/components/notifications/notificationService";
import adminIDPsApi, { IdpType, withIDPDefaults } from "../../../../services/adminIDPsApi";
import {
  listIDPsForIdentityPoolQueryKey,
  listIDPsQueryKey,
} from "../../../../services/adminIDPsQuery";
import { useGetAuthorizationServer } from "../../../../services/adminServersQuery";
import PageHeader from "../../../common/PageHeader";
import IdentitiesCreateConfigurationStep from "../../create/IdentitiesCreateConfigurationStep";
import {
  addName,
  addRedirectUrl,
  grantTenantMemberRoleForIDP,
  IdpUiModelType,
  providers,
} from "../../identities.utils";

export const useStyles = makeStyles()(() => ({
  dialogRoot: {
    "& .MuiDialogContent-root": {
      padding: 0,
      paddingTop: "0 !important",
    },
    "& .MuiGrid-root": {
      maxWidth: "100%",
      flex: 1,
      padding: "24px 0 16px 24px",
    },
  },
  paper: {
    width: 746,
    maxWidth: 746,
  },
}));

interface Props {
  method: string;
  workspace: string;
  handleClose: () => void;
  onCreated: () => void;
  isHidden?: boolean;
}

export default function CreateIdp({ method, workspace, handleClose, onCreated, isHidden }: Props) {
  const { classes } = useStyles();

  const tenantId = getTenantId();
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(false);
  const [errors, setErrors] = useState(null);

  const provider = providers.find(provider => provider.method === method);

  const getAuthorizationServerQuery = useGetAuthorizationServer(tenantId, workspace);
  const authorizationServer = getAuthorizationServerQuery.data;

  const redirectUrl = authorizationServer ? `${authorizationServer.issuer_url}/login` : "";

  const handleCreate = (type: IdpType, provider: IdpUiModelType) => {
    setProgress(true);

    return adminIDPsApi
      .createIDP({
        wid: workspace,
        body: withIDPDefaults(provider, type, getAuthorizationServerQuery.data, isHidden),
        type,
      })
      .then(res => {
        if (workspace === "admin") {
          return grantTenantMemberRoleForIDP(
            res.data.authorization_server_id,
            res.data.id,
            res.data.tenant_id
          ).then(() => res);
        }
        return res;
      })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsQueryKey(tenantId, workspace) })
      )
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listIDPsForIdentityPoolQueryKey(tenantId) })
      )
      .then(() => notifySuccess("Identity provider created"))
      .then(onCreated)
      .catch(handleErrorWithNotify("Error occurred while creating provider"))
      .finally(() => setProgress(false));
  };

  const handleCancel = () => {
    handleClose();
    setErrors(null);
  };

  return provider ? (
    <Dialog
      id="create-idp-dialog"
      classes={{ root: classes.dialogRoot, paper: classes.paper }}
      onCancel={handleCancel}
      withCloseIcon
    >
      <PageHeader title={`Register ${provider.name}`} />
      <IdentitiesCreateConfigurationStep
        data={addRedirectUrl(addName(provider), redirectUrl)}
        server={authorizationServer}
        onCancel={handleCancel}
        onSubmit={(type, provider) => handleCreate(type, provider).then(handleCancel)}
        errors={errors}
        onResetErrors={err => setErrors(err)}
        progress={progress}
      />
    </Dialog>
  ) : null;
}
