import React from "react";

import { AutocompleteRenderGroupParams } from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { AuthenticationContextAttribute } from "@cloudentity/acp-admin";

import AutocompleteField from "../../../../common/utils/forms/AutocompleteField";

const useStyles = makeStyles()(theme => ({
  root: {
    "& .MuiInputBase-root": {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
    },
  },
  code: {
    backgroundColor: "transparent",
    color: theme.palette.secondary.light,
  },
}));

interface Props {
  id: string;
  name: string;
  options: AuthenticationContextAttribute[];
  defaultValue?: string;
  disabled?: boolean;
  groupBy?: (option: any) => string;
  renderGroup?: ((params: AutocompleteRenderGroupParams) => React.ReactNode) | undefined;
  showName?: boolean;
  afterChange?: (value: any) => void;
}

export default function AuthnContextAutocomplete({
  id,
  name,
  options,
  defaultValue,
  disabled,
  groupBy,
  renderGroup,
  showName,
  afterChange,
}: Props) {
  const { classes } = useStyles();
  return (
    <AutocompleteField
      id={id}
      name={name}
      options={options.map(o => o.name)}
      rules={{ required: "Value is required" }}
      autoHighlight
      size="small"
      filterSelectedOptions={false}
      getOptionLabel={option => options.find(o => o.name === option)?.description ?? option ?? ""}
      renderOption={({ key, ...props }, option) => {
        const optionObj = options.find(o => o.name === option);
        const type = optionObj?.name?.split(".")[0];
        return (
          <li
            key={key}
            {...props}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography>{optionObj?.description}</Typography>
            {showName && type !== "addresses" && type !== "identifiers" ? (
              <Typography variant="caption">
                <code className={classes.code}>{optionObj?.name?.split(".")[1]}:</code>
                <code className={classes.code}>{optionObj?.type}</code>
              </Typography>
            ) : (
              <Typography variant="caption">
                <code className={classes.code}>{optionObj?.type}</code>
              </Typography>
            )}
          </li>
        );
      }}
      style={{ marginBottom: 0 }}
      className={classes.root}
      defaultValue={defaultValue}
      disabled={disabled}
      groupBy={groupBy}
      renderGroup={renderGroup}
      afterChange={afterChange}
    />
  );
}
