import { useParams } from "react-router";

import { usePrevious } from "../../../common/utils/hooks/usePrevious";
import { getFromLocalStorage } from "../../../common/utils/localStorage.utils";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";

export const ORGANIZATION_KEY_LOCAL_STORAGE = "organization";

export const useOrganization = () => {
  const { id } = useParams<{ id: string }>();
  const organizationId = id || getFromLocalStorage(ORGANIZATION_KEY_LOCAL_STORAGE) || "";

  useCheckWorkspacePermissions(organizationId);

  const previousOrganizationId = usePrevious(organizationId);

  return [organizationId, previousOrganizationId] as const;
};
