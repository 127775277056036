import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { useQueryClient } from "@tanstack/react-query";

import { Schema } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../common/api/paths";
import Dialog from "../../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../../../../common/utils/forms/TextFieldRequired";
import adminIdentitySchemasApi from "../../../../services/adminIdentitySchemasApi";
import { listSchemasQueryKey } from "../../../../services/adminIdentitySchemasQuery";

const newSchema = {
  properties: {
    given_name: {
      description: "User First Name",
      type: "string",
      minLength: 1,
    },
    family_name: {
      description: "User Last Name",
      type: "string",
      minLength: 1,
    },
    name: {
      description: "User Full Name",
      type: "string",
      minLength: 1,
    },
  },
  description: "sample user data schema",
  type: "object",
  required: ["family_name", "given_name", "name"],
};

interface Props {
  onClose: () => void;
  schemas: Schema[];
}

export default function IdentitySchemaCreate({ onClose, schemas }: Props) {
  const [progress, setProgress] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const data = useMemo(() => ({ name: "" }), []);

  const form = useForm({ id: "identity-schema-create", initialValues: data, progress });

  const handleCreate = data => {
    setProgress(true);
    adminIdentitySchemasApi
      .createSchema({
        schema: {
          name: data.name,
          tenant_id: getTenantId(),
          schema: newSchema,
        },
      })
      .then(res =>
        Promise.all([
          Promise.resolve(res),
          queryClient.invalidateQueries({ queryKey: listSchemasQueryKey(getTenantId()) }),
        ])
      )
      .then(([res]) => {
        notifySuccess("Schema successfully created");
        navigate(`/identity-pools/schemas/${res.data.id}/schema`);
        onClose();
      })
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to create schema"))
      .finally(() => {
        setProgress(false);
      });
  };

  const schemaNames = schemas.map(schema => schema.name);

  return (
    <Dialog id="identity-schema-create-dialog" title="Create Identity Schema" onClose={onClose}>
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          autoFocus
          rules={{
            validate: {
              notUniq: v =>
                schemaNames.map(s => s.toLowerCase()).indexOf(v.toLowerCase()) === -1 ||
                "Schema with a given name already exists",
            },
          }}
        />

        <FormFooter onCancel={onClose} onSubmit={handleCreate} submitText="Create" />
      </Form>
    </Dialog>
  );
}
