import { useEffect, useState } from "react";

function useDragAndDropList({
  items,
  setItems,
}: {
  items: string[];
  setItems: (items: string[]) => void;
}) {
  const [mouseDownClientX, setMouseDownClientX] = useState<number | null>(null);
  const [mouseDownClientY, setMouseDownClientY] = useState<number | null>(null);
  const [draggedNode, setDraggedNode] = useState<{ id: string; index: number } | null>(null);

  function onDragStart(id: string, index: number, clientX: number, clientY: number) {
    setMouseDownClientX(clientX);
    setMouseDownClientY(clientY);
    setDraggedNode({ id, index });
  }

  useEffect(() => {
    if (mouseDownClientX === null || mouseDownClientY === null || !draggedNode) return;

    const node = document.getElementById(`${draggedNode.id}`);
    if (!node) return;

    const boundingRect = node.getBoundingClientRect();
    const dragOffsetX = mouseDownClientX - boundingRect.x;
    const dragOffsetY = mouseDownClientY - boundingRect.y;
    let currentNewIndex = -1;

    const phantomNode = node.cloneNode(true) as HTMLElement;
    phantomNode.style.position = "fixed";
    phantomNode.style.zIndex = "100000";
    phantomNode.style.backgroundColor = "white";
    phantomNode.style.opacity = "1";
    phantomNode.style.width = `${boundingRect.width}px`;
    document.body.appendChild(phantomNode);

    function onMouseMove(e: any) {
      if (!draggedNode) return;
      const phantomNodeX = e.clientX - dragOffsetX;
      const phantomNodeY = e.clientY - dragOffsetY;
      phantomNode.style.left = `${phantomNodeX}px`;
      phantomNode.style.top = `${phantomNodeY}px`;

      const [{ index: newIndex }] = Array.from(document.querySelectorAll(".draggable-item"))
        .filter(item => item !== phantomNode)
        .map(item => item.getBoundingClientRect())
        .map((boundingRect, index) => ({
          index,
          distance:
            Math.abs(phantomNodeX - boundingRect.x) + Math.abs(phantomNodeY - boundingRect.y),
        }))
        .sort((a, b) => a.distance - b.distance);

      if (currentNewIndex !== newIndex) {
        currentNewIndex = newIndex;
      } else {
        return;
      }

      const newItems =
        draggedNode.index === currentNewIndex
          ? items
          : items.flatMap((column, i) => {
              if (i === draggedNode.index) return [];
              if (i === draggedNode.index) return [];
              if (i === currentNewIndex) {
                if (draggedNode.index > currentNewIndex) {
                  return [items[draggedNode.index], column];
                } else {
                  return [column, items[draggedNode.index]];
                }
              }
              return [column];
            });

      setItems(newItems);
    }

    function onMouseUp() {
      if (!phantomNode) return;
      document.body.removeChild(phantomNode);
      setDraggedNode(null);
    }

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    onMouseMove({ clientX: mouseDownClientX, clientY: mouseDownClientY });

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
    // items variable should not be in deps, because we override it inside useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouseDownClientX, mouseDownClientY, draggedNode]);

  return {
    draggedNode,
    onDragStart,
  };
}

export default useDragAndDropList;
