import React, { lazy, Suspense } from "react";

import Progress from "../../components/Progress";
import { PhoneFieldProps } from "./PhoneFieldLazy";

const PhoneFieldLazy = lazy(() => import("./PhoneFieldLazy"));

export default function PhoneField(props: PhoneFieldProps) {
  return (
    <div style={{ position: "relative", minHeight: 89 }}>
      <Suspense fallback={<Progress size={32} style={{ top: 14 }} />}>
        <PhoneFieldLazy {...props} />
      </Suspense>
    </div>
  );
}
