import { useState } from "react";

import { ServerResponse } from "@cloudentity/acp-admin";

import { IdpType } from "../../services/adminIDPsApi";

export type StepData = {
  defineWorkspaceType: { selected: IdpType | "" };
  addWorkspaceDetails: {
    name: string;
    id: string;
    color: string;
    with_demo_client: boolean;
    authorizationServer: ServerResponse;
  };
  createDeveloperPortal: {
    name: string;
    id: string;
    color: string;
    authorizationServer: ServerResponse;
  };
};

export default function useWorkspaceDirectoryData() {
  const [stepData, setStepData] = useState<StepData>({
    defineWorkspaceType: {
      selected: "",
    },
    addWorkspaceDetails: {
      name: "",
      id: "",
      color: "",
      with_demo_client: false,
      authorizationServer: {
        id: "",
        tenant_id: "",
      },
    },
    createDeveloperPortal: {
      name: "",
      id: "",
      color: "#C4C4C4",
      authorizationServer: {
        id: "",
        tenant_id: "",
      },
    },
  });

  return [stepData, setStepData] as const;
}
