import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { Users } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { OrganizationResponse } from "@cloudentity/acp-admin";

import { NavDrawerContentProps } from "../admin/components/nav/NavDrawer";
import NavItem from "../admin/components/nav/NavItem";
import OrganizationSelector from "../admin/components/workspaces/OrganizationSelector";
import { useCheckWorkspacePermissions } from "../admin/services/adminPermissionsQuery";
import { listWorkspacesQueryKey } from "../admin/services/adminServersQuery";
import { useCheckTenantPermissions } from "../admin/services/adminTenantsQuery";
import FingerprintIcon from "../common/components/icons/FingerprintIcon";
import OrganizationDetailsIcon from "../common/components/icons/OrganizationDetailsIcon";
import OrganizationsIcon from "../common/components/icons/OrganizationsIcon";
import B2BAddOrganization from "./directory/B2BAddOrganization";

const useStyles = makeStyles()(() => ({
  listPadding: {
    padding: 0,
    overflow: "auto",
  },
  listItemRoot: {
    paddingTop: 16,
    paddingBottom: 16,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatarName: {
    display: "flex",
    alignItems: "center",
  },
  avatarProgress: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
}));

interface OrganizationsNavDrawerContentProps extends NavDrawerContentProps {
  organization?: OrganizationResponse;
}

export default function OrganizationsNavDrawerContent({
  onItemClick,
  organization,
}: OrganizationsNavDrawerContentProps) {
  const { id } = useParams<{ id: string }>();
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [createOrganizationDialog, setCreateOrganizationDialog] = useState(false);

  const queryClient = useQueryClient();
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(id);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <List classes={{ padding: classes.listPadding }}>
        <OrganizationSelector
          onCreateClick={() => setCreateOrganizationDialog(true)}
          organization={organization}
        />

        {createOrganizationDialog && (
          <B2BAddOrganization
            onCancel={() => {
              setCreateOrganizationDialog(false);
            }}
            onCreated={() => {
              queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() });
            }}
            onSkip={server => {
              setCreateOrganizationDialog(false);
              navigate(`/organizations/${server.id}/dashboard`);
            }}
            onInviteSent={server => {
              setCreateOrganizationDialog(false);
              navigate(`/organizations/${server.id}/dashboard`);
            }}
          />
        )}

        <Divider />

        <NavItem
          text="Organization Details"
          to="organization-details"
          id="settings-side-nav-item"
          icon={OrganizationDetailsIcon}
          onClick={onItemClick}
          isActive={pathname.startsWith(`/organizations/${id}/settings`)}
        />

        {checkWorkspacePermissionsQuery.data?.read_idps && (
          <NavItem
            text="Sign-in and SSO"
            to="sign-in"
            id="sign-in-side-nav-item"
            icon={FingerprintIcon}
            onClick={onItemClick}
            isActive={pathname.startsWith(`/organizations/${id}/sign-in`)}
          />
        )}

        {(checkTenantPermissionsQuery.data?.list_organizations ||
          checkTenantPermissionsQuery.data?.create_organization) && (
          <NavItem
            text="Suborganizations"
            to="suborganizations"
            id="suborganizations-side-nav-item"
            icon={OrganizationsIcon}
            onClick={onItemClick}
            isActive={pathname.startsWith(`/organizations/${id}/suborganizations`)}
          />
        )}

        <NavItem
          text="Users"
          to="users"
          id="users-side-nav-item"
          icon={Users}
          onClick={onItemClick}
          isActive={pathname.startsWith(`/organizations/${id}/users`)}
          ignoreMatchAndActiveToAllowNavigate
        />
      </List>

      <List classes={{ padding: classes.listPadding }}>
        {checkTenantPermissionsQuery.data?.create_organization && (
          <ListItemButton
            id={id}
            key="organizations-list-item"
            onClick={() => navigate("/organizations")}
          >
            <ListItemIcon>
              <OrganizationsIcon />
            </ListItemIcon>
            <ListItemText
              style={{ whiteSpace: "nowrap" }}
              disableTypography
              primary={<Typography variant="body2">Organizations</Typography>}
            />
          </ListItemButton>
        )}
      </List>
    </div>
  );
}
