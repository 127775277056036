import React, { useState } from "react";

import MenuItem from "@mui/material/MenuItem";

import { BaseUserWithData, UserWithData, UserWithDataStatusEnum } from "@cloudentity/acp-identity";

import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { useCheckPoolPermissions } from "../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../services/adminIdentityUsersApi";
import IdentityPoolUserSelectAddressDialog from "../identityPools/identityPool/users/user/IdentityPoolUserSelectAddressDialog";

interface Props {
  user?: UserWithData | BaseUserWithData;
  workspaceId: string;
  onConfirmed: () => void;
  onCloseConfirmation: () => void;
}

export default function ManageUserActionsResetPassword({
  user,
  workspaceId,
  onConfirmed,
  onCloseConfirmation,
}: Props) {
  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  const addresses = user?.verifiable_addresses ?? [];

  const handleResetPassword = (address: string) => {
    setProgress(true);
    identityUsersApi
      .requestResetPassword({
        ipID: user?.user_pool_id!,
        userID: user?.id!,
        requestResetPassword: { address },
        serverId: workspaceId || "admin",
      })
      .then(() => {
        notifySuccess("Reset password requested");
        setDialog(false);
        onConfirmed();
      })
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to reset password"))
      .finally(() => {
        setProgress(false);
      });
  };

  const checkPoolPermissionsQuery = useCheckPoolPermissions(user?.user_pool_id!, {
    enabled: !!user?.user_pool_id,
  });

  if (!checkPoolPermissionsQuery.data?.manage_user_passwords) {
    return null;
  }

  return addresses.length > 0 && user?.status === UserWithDataStatusEnum.Active ? (
    <>
      <MenuItem
        id="reset-password-button"
        onClick={() => {
          if (addresses.length > 1) {
            setDialog(true);
          } else {
            handleResetPassword(addresses[0].address);
          }
        }}
        disabled={progress}
      >
        Reset Password
      </MenuItem>

      {dialog && (
        <IdentityPoolUserSelectAddressDialog
          id="reset-password-dialog"
          poolId={user?.user_pool_id || ""}
          title="Select user address to request reset password"
          submitText="Reset password"
          addresses={addresses}
          onSubmit={handleResetPassword}
          progress={progress}
          onCancel={() => {
            setDialog(false);
            onCloseConfirmation();
          }}
        />
      )}
    </>
  ) : null;
}
