import React, { useMemo, useState } from "react";

import Close from "@mui/icons-material/Close";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import uniq from "lodash/uniq";
import { makeStyles } from "tss-react/mui";

import Dialog from "../../../../common/components/Dialog";
import { inputWithButtonStyles } from "../../../../common/styles/inputWithButtonStyles";
import Form, { useForm, useFormContext } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import { validators } from "../../../../common/utils/forms/validation";

const useStyles = makeStyles()(theme => ({
  contentText: {
    color: theme.palette.secondary.light,
    fontSize: 14,
    marginBottom: 24,
  },
  domainRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 24,
  },
  addButtonRoot: {
    border: "1px solid #A0A3B5",
    borderLeft: "none",
    borderRadius: "0 4px 4px 0",
    padding: "12.5px 13px",
  },
  inputRoot: {
    ...inputWithButtonStyles,
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 32,
  },
}));

export interface SSOLogoutDomainProps {
  ssoDomain: string | undefined;
  redirectDomains: string[];
  onCancel: () => void;
  onUpdate: (redirectDomains: string[]) => void;
  isSSOEnabled: boolean;
}

function getDataValue(value: string) {
  return { key: String(Math.floor(Math.random() * 1000) + 1), value };
}

function CreateRow({
  classes,
  data,
  setData,
  watchValues,
  fieldKey,
  value,
  index,
  disabled,
}: {
  classes: any;
  data: { key: string; value: string }[];
  setData: (data: { key: string; value: string }[]) => void;
  watchValues: any;
  fieldKey: string;
  value: string;
  index: number;
  disabled: boolean;
}) {
  const { form } = useFormContext();

  return (
    <div className={classes.domainRow}>
      <TextField
        name={`redirectDomain-${fieldKey}`}
        label="A valid domain"
        defaultValue={value}
        disabled={disabled}
        rules={{
          validate: !disabled
            ? {
                validDomain: v =>
                  v.length === 0 ||
                  validators.validDomain({ label: "This value", require_tld: false })(v),
              }
            : {},
        }}
        hideLabel
        style={{ flex: 1, marginBottom: 0 }}
        classes={{
          root: classes.inputRoot,
        }}
        withEndAdornment={
          !disabled && watchValues[`redirectDomain-${fieldKey}`] ? (
            <IconButton
              aria-label="clear input"
              tabIndex={-1}
              onClick={() => form.setValue(`redirectDomain-${fieldKey}`, "")}
              style={{ padding: 6, marginRight: 6 }}
              data-testid={`redirectDomain-${index}-clear-button`}
              size="large"
            >
              <Close style={{ color: "#A0A3B5", width: 18, height: 18 }} />
            </IconButton>
          ) : null
        }
        inputProps={{
          "data-testid": `redirectDomain-${index}-input`,
        }}
      />
      {!disabled && (
        <IconButton
          onClick={() => {
            if (data.length === 1) {
              form.setValue(`redirectDomain-${data[0].key}`, "");
            } else {
              setData(data.filter(d => d.key !== fieldKey));
            }
          }}
          classes={{
            root: classes.addButtonRoot,
          }}
          data-testid={`redirectDomain-${index}-button`}
          size="large"
        >
          <DeleteOutline style={{ color: "#A0A3B5" }} />
        </IconButton>
      )}
    </div>
  );
}

export default function SSOLogoutDomains({
  ssoDomain,
  redirectDomains,
  onCancel,
  onUpdate,
  isSSOEnabled,
}: SSOLogoutDomainProps) {
  const { classes } = useStyles();

  const [data, setData] = useState<{ key: string; value: string }[]>(
    redirectDomains.length > 0 ? redirectDomains.map(getDataValue) : [getDataValue("")]
  );
  const formData = useMemo(
    () => data.reduce((acc, curr) => ({ ...acc, [`redirectDomain-${curr.key}`]: curr.value }), {}),
    [data]
  );

  const form = useForm({
    id: "sso-settings-logout-domains",
    initialValues: formData,
    progress: false,
  });
  const watchValues = form.watch();

  return (
    <Dialog
      onClose={onCancel}
      data-testid="redirect-domains-dialog"
      id="redirect-domains-dialog"
      title="Allowed Logout Redirect Domains"
    >
      <Form form={form}>
        <Typography className={classes.contentText}>
          On logout, the following domains are permitted for the <code>redirect_to</code> parameter
        </Typography>

        {!!ssoDomain && ssoDomain !== "" && isSSOEnabled && (
          <CreateRow
            classes={classes}
            data={data}
            setData={setData}
            watchValues={watchValues}
            fieldKey="domain"
            value={`${ssoDomain} (SSO domain)`}
            index={0}
            disabled={true}
          />
        )}
        {data.map(({ key, value }, index) => {
          return (
            <CreateRow
              key={key}
              classes={classes}
              data={data}
              setData={setData}
              watchValues={watchValues}
              fieldKey={key}
              value={value}
              index={index + (!!ssoDomain && ssoDomain !== "" && isSSOEnabled ? 1 : 0)}
              disabled={false}
            />
          );
        })}
        <div className={classes.addButtonContainer}>
          <Button
            onClick={() => {
              setData([...data, getDataValue("")]);
            }}
            color="primary"
            style={{ fontSize: 16 }}
          >
            + Add new
          </Button>
        </div>
        <FormFooter
          onCancel={onCancel}
          onSubmit={formData => {
            onUpdate(
              uniq(
                data
                  .filter(d => formData[`redirectDomain-${d.key}`].length !== 0)
                  .map(d => formData[`redirectDomain-${d.key}`])
              )
            );
          }}
        />
      </Form>
    </Dialog>
  );
}
