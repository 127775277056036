import React from "react";

import Grid from "@mui/material/Grid";
import { AxiosResponse } from "axios";
import { makeStyles } from "tss-react/mui";
import UAParser from "ua-parser-js";

import { ListUserSessions, UserSession } from "@cloudentity/acp-public";

import InfoField from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/InfoField";
import { getDateTime } from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/utils";
import { useGetUserInfo } from "../../../admin/services/oauth2Query";
import FormSection from "../FormSection";
import { SelfService2FAChip, SelfServiceCurrentSessionChip } from "./SelfServiceChips";
import SelfServiceCommonAccordion from "./SelfServiceCommonAccordion";
import SelfServiceRevokeMfaSession from "./SelfServiceRevokeMfaSession";
import SelfServiceRevokeSession from "./SelfServiceRevokeSession";
import { calcCurrentSession, getDeviceIcon, useCommonStyles } from "./utils";

const useStyles = makeStyles()(theme => ({
  item: {
    width: "100%",
    marginLeft: 0,
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
    padding: "16px 0",
    "&:first-of-type": {
      paddingTop: 0,
    },
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  chip: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  paper: {
    marginBottom: 16,
    backgroundColor: "#FBFCFD",
  },
  footer: {
    marginTop: 8,
    "& > div:first-of-type": {
      marginLeft: 0,
    },
  },
}));

function EmptyUserSession() {
  const { classes: commonClasses } = useCommonStyles();

  const userAgent = window.navigator.userAgent;
  const parser = new UAParser(userAgent);
  const parserResults = parser.getResult();

  if (!parserResults) return <div className={commonClasses.empty}>No sessions</div>;

  return (
    <SelfServiceCommonAccordion
      id="empty-user-session-container"
      icon={getDeviceIcon(parserResults.device.type)}
      name={parserResults.os.name ?? ""}
      summaryInfo="1 session"
      chips={
        <>
          <SelfServiceCurrentSessionChip />
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InfoField
            id="session-browser-name"
            label="Browser"
            value={`${parserResults.browser.name} ${parserResults.browser.version}`}
            noMarginTop
          />
        </Grid>
      </Grid>
    </SelfServiceCommonAccordion>
  );
}

export interface SelfServiceSessionsProps {
  sessionData: UserSession[];
  fetchSessions: (withSetter?: boolean) => Promise<AxiosResponse<ListUserSessions, any> | null>;
  mfaSessionData: UserSession[];
  fetchMfaSessions: (withSetter?: boolean) => Promise<AxiosResponse<ListUserSessions, any> | null>;
}

export default function SelfServiceSessions({
  sessionData,
  fetchSessions,
  mfaSessionData,
  fetchMfaSessions,
}: SelfServiceSessionsProps) {
  const { classes } = useStyles();

  const userInfoQuery = useGetUserInfo();
  const sid = (userInfoQuery.data as any)?.sid;
  const currentSessionId = calcCurrentSession(sid, sessionData);

  const userAgentData = sessionData
    .map(session => {
      const parser = new UAParser(session.user_agent);
      return { parser: parser.getResult(), ...session };
    })
    .reduce((acc, curr) => {
      const key = curr.parser.os.name ?? "";
      return {
        ...acc,
        [key]: [...(acc[key] ?? []), curr],
      };
    }, {} as { [key: string]: (UserSession & { parser: UAParser.IResult })[] });

  return (
    <>
      <FormSection
        title="Your devices"
        subtitle="You're signed in on these devices or have been in the last few days. There might be multiple activity sessions from the same device."
      />

      {!sessionData.length && <EmptyUserSession />}

      {Object.entries(userAgentData).map(([key, details]) => {
        const hasCurrentSession = !!details.find(d => d.id === currentSessionId);
        return (
          <SelfServiceCommonAccordion
            id="user-session-container"
            key={key}
            icon={getDeviceIcon(details?.[0].parser.device.type)}
            name={key}
            summaryInfo={`${details.length} session${details.length > 1 ? "s" : ""}`}
            chips={hasCurrentSession ? <SelfServiceCurrentSessionChip small /> : <></>}
            noDetailsPadding
          >
            {details.map(detail => {
              const mfaSession = mfaSessionData.find(
                ({ user_agent }) => user_agent === detail.user_agent
              );

              return (
                <Grid
                  container
                  spacing={2}
                  key={detail.id}
                  id={`session-item-${detail.id}`}
                  className={classes.item}
                >
                  <Grid item xs={6}>
                    <InfoField
                      id="session-browser-name"
                      label="Browser"
                      value={`${detail.parser.browser.name} ${detail.parser.browser.version}`}
                      noMarginTop
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoField
                      id="session-ip"
                      label="IP address"
                      value={detail.ip_address}
                      noMarginTop
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoField
                      id="session-issue_time"
                      label="First sign-in"
                      value={getDateTime(detail.issue_time)}
                      noMarginTop
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InfoField
                      id="session-active_time"
                      label="Last used"
                      value={getDateTime(detail.active_time)}
                      noMarginTop
                    />
                  </Grid>

                  {currentSessionId === detail.id ? (
                    <Grid item xs={6} className={classes.footer}>
                      <SelfServiceCurrentSessionChip />
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <SelfServiceRevokeSession
                        session={detail}
                        fetchSessions={fetchSessions}
                        fetchMfaSessions={fetchMfaSessions}
                      />
                    </Grid>
                  )}

                  {mfaSession?.id && (
                    <>
                      <Grid item xs={6} className={classes.footer}>
                        <SelfService2FAChip trust />
                      </Grid>
                      <Grid item xs={6}>
                        <SelfServiceRevokeMfaSession
                          id={mfaSession.id}
                          session={detail}
                          fetchSessions={fetchSessions}
                          fetchMfaSessions={fetchMfaSessions}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
          </SelfServiceCommonAccordion>
        );
      })}
    </>
  );
}
