import React from "react";

import ChipMUI, { ChipProps as ChipMUIProps } from "@mui/material/Chip";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ inline: boolean }>()((theme, { inline }) => ({
  chip: {
    borderRadius: 4,
    marginRight: 6,
    border: `solid 1px ${theme.custom.greys.border}`,
    backgroundColor: "#FBFCFD",
    color: theme.palette.secondary.light,
    ...(inline ? { height: 21, margin: "0 4px" } : {}),
  },
  label: {
    ...(inline ? { padding: "0 4px" } : {}),
  },
}));

interface ChipProps extends Omit<ChipMUIProps, "label"> {
  key?: string | number;
  id?: string;
  label: React.ReactNode;
  inline?: boolean;
}

function Chip({ id, label, inline = false, ...props }: ChipProps) {
  const { classes } = useStyles({ inline });

  return (
    <ChipMUI
      label={label}
      id={id}
      variant="outlined"
      className={classes.chip}
      classes={{ label: classes.label }}
      {...props}
    />
  );
}

export default Chip;
