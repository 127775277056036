import { useQuery } from "@tanstack/react-query";

import { PublicEnvironment } from "@cloudentity/acp-public";

import { withQueryError } from "../../admin/services/queryUtils";
import { UseFeatureFn } from "../../common/utils/hooks/useFeature";
import userApi from "./userApi";

const GET_USER_ENVIRONMENT = "GET_USER_ENVIRONMENT";

export const getUserEnvironmentQueryKey = () => [GET_USER_ENVIRONMENT];

export const useGetPublicEnvironment = (options?) =>
  useQuery<PublicEnvironment>({
    queryKey: getUserEnvironmentQueryKey(),
    queryFn: withQueryError<PublicEnvironment>(async () => {
      const data = await userApi.getPublicEnvironment();
      return data.data;
    }, "Error occurred while trying to fetch environment"),
    ...options,
  });

export const useFeature: UseFeatureFn = flagName => {
  const getEnvironmentQuery = useGetPublicEnvironment();

  return Boolean(getEnvironmentQuery.data?.[flagName]);
};
