import { ImagesApi, ImagesApiSearchImagesRequest } from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => ImagesApi = adminApiClazz(ImagesApi);

const adminImagesApi = {
  searchImages: (req: ImagesApiSearchImagesRequest) => api().searchImages(req),
};

export default adminImagesApi;
