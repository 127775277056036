import React from "react";

import LinearProgress from "@mui/material/LinearProgress";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import SelectMUI from "@mui/material/Select";
import { BaseSelectProps } from "@mui/material/Select/Select";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ smaller?: boolean; minWidth?: number }>()(
  (_, { smaller, minWidth }) => ({
    container: {
      position: "relative",
    },
    loading: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
    },
    select: {
      minWidth: minWidth || 120,
      ...(smaller
        ? {
            "& > div": {
              padding: "4px 32px 4px 8px",
              paddingBottom: 4,
              fontSize: 12,
            },
          }
        : {}),
    },
  })
);

interface Props<T extends string | number> extends BaseSelectProps {
  items: { label: MenuItemProps["children"]; value: T; id?: string; hidden?: boolean }[];
  smaller?: boolean;
  minWidth?: number;
  loading?: boolean;
}

export default function Select<T extends string | number>({
  items,
  smaller,
  minWidth,
  loading,
  ...props
}: Props<T>) {
  const { classes } = useStyles({ smaller, minWidth });

  return (
    <div className={classes.container}>
      {loading && <LinearProgress className={classes.loading} />}
      <SelectMUI className={classes.select} disabled={loading || props.disabled} {...props}>
        {items.map(({ label, value, id, hidden }) => (
          <MenuItem
            key={value}
            value={value}
            id={`menu-item-${value}` || id}
            style={hidden ? { display: "none" } : {}}
          >
            {label}
          </MenuItem>
        ))}
      </SelectMUI>
    </div>
  );
}
