import React from "react";

import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Search, X } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ fullWidth?: boolean; disabled?: boolean }>()(
  (theme, { fullWidth, disabled }) => ({
    searchInput: {
      padding: 13,
      fontSize: 12,
      lineHeight: "22px",
      height: "unset",
    },
    searchInputRoot: {
      border: "1px solid #B7BBD1",
      paddingRight: 4,
      "&.Mui-focused fieldset": {
        borderWidth: "1px !important",
      },
      width: fullWidth ? "100%" : 340,
    },
    searchInputNotched: {
      border: "1px solid #ECECEC",
    },
    icon: {
      color: disabled ? "#B7BBD1" : theme.palette.primary.main,
    },
  })
);

interface Props {
  value: string;
  placeholder: string;
  helperText?: string;
  fullWidth?: boolean;
  onSearch: (value: string) => void;
  onClear: () => void;
  disabled?: boolean;
}

export default function SearchInput({
  value,
  placeholder,
  helperText,
  fullWidth,
  onSearch,
  onClear,
  disabled = false,
}: Props) {
  const { classes } = useStyles({ fullWidth, disabled });

  return (
    <div style={fullWidth ? { width: "100%" } : undefined} id="search-input-container">
      <OutlinedInput
        value={value}
        onChange={e => onSearch(e.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <Search size="16" className={classes.icon} strokeWidth="2.5" />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {value !== "" ? (
              <IconButton
                aria-label="clear search input"
                style={{ padding: 6 }}
                onClick={onClear}
                onMouseDown={onClear}
                data-testid="clear-search-text-input"
                size="large"
              >
                <X size="16" className={classes.icon} strokeWidth="2.5" />
              </IconButton>
            ) : (
              <div style={{ width: 28 }} />
            )}
          </InputAdornment>
        }
        classes={{
          root: classes.searchInputRoot,
          input: classes.searchInput,
          notchedOutline: classes.searchInputNotched,
        }}
        placeholder={placeholder}
        inputProps={{
          "data-testid": "search-text-input",
        }}
        disabled={disabled}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
}
