import React, { ReactNode } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import CodeBlock from "./CodeBlock";

const useStyles = makeStyles<{ dense?: boolean }>()((theme, { dense }) => ({
  block: {
    display: "flex",
    alignItems: "flex-start",
    paddingBottom: dense ? 12 : 32,
    position: "relative",
  },
  leftLine: {
    borderLeft: "solid 1px #E9EBFA",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 16,
  },
  avatar: {
    backgroundColor: "white",
    color: theme.palette.secondary.light,
    border: "solid 1px #E9EBFA",
    width: 32,
    height: 32,
    fontSize: 14,
  },
  content: {
    margin: "0 12px",
    flex: 1,
    fontSize: 12,
    color: theme.palette.secondary.light,
    "& code": {
      padding: "0 4px",
      borderRadius: 4,
    },
  },
  title: {
    marginBottom: 10,
  },
  list: {
    paddingLeft: 14,
    marginTop: 8,
    "& > li": {
      paddingLeft: 8,
    },
  },
  button: {
    minHeight: 50,
  },
}));

interface Props {
  items: {
    id: string;
    title?: string | ReactNode;
    description?: string | ReactNode;
    button?: {
      label: string;
      onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
      loading?: boolean;
    };
    code?: string[];
    additionalCodes?: string[][];
    codeShowHide?: () => void;
    list?: { id: string; node: ReactNode }[];
  }[];
  dense?: boolean;
}

const StepQuickstart = ({ items, dense }: Props) => {
  const { classes } = useStyles({ dense });

  return (
    <div id="step-quickstart-container">
      {items.map(
        ({ id, title, description, button, code, additionalCodes, list, codeShowHide }, index) => (
          <div key={id} className={classes.block} id={`step-block-${index}`}>
            {index !== items.length - 1 && <div className={classes.leftLine} />}
            <Avatar className={classes.avatar}>{index + 1}</Avatar>
            <div className={classes.content}>
              {title && (
                <Typography variant="h5" className={classes.title}>
                  {title}
                </Typography>
              )}
              {description && <div>{description}</div>}
              {code && <CodeBlock lines={code} codeShowHide={codeShowHide} />}
              {additionalCodes &&
                additionalCodes.map(code => <CodeBlock lines={code} key={code[0]} />)}
              {list && (
                <ul className={classes.list}>
                  {list.map(({ id, node }) => (
                    <li key={id}>{node}</li>
                  ))}
                </ul>
              )}
            </div>
            {button && (
              <LoadingButton
                variant="outlined"
                onClick={button.onClick}
                loading={button.loading}
                className={classes.button}
              >
                {button.label}
              </LoadingButton>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default StepQuickstart;
