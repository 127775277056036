import React, { useState } from "react";

import { AxiosResponse } from "axios";

import { SelfUserCredentials, SelfUserWithDataV2 } from "@cloudentity/acp-identityself";

import identitySelfApi from "../../services/identitySelfApi";
import Button from "../Button";
import ConfirmationDialog from "../ConfirmationDialog";
import { notifyErrorOrDefaultTo, notifySuccess } from "../notifications/notificationService";

interface Props {
  credential: SelfUserCredentials;
  fetchUser: (withSetter?: boolean) => Promise<AxiosResponse<SelfUserWithDataV2, any> | null>;
}

export default function SelfServiceCredentialPasskeyDelete({ credential, fetchUser }: Props) {
  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  function handleClose() {
    setDialog(false);
  }

  function handleRemovePasskey() {
    setProgress(true);
    identitySelfApi
      .deleteWebAuthnKey(credential.id ?? "")
      .then(handleClose)
      .then(() => notifySuccess("Passkey deleted"))
      .then(() => fetchUser(true))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to delete passkey"))
      .finally(() => setProgress(false));
  }

  return (
    <>
      <Button
        size="small"
        color="error"
        style={{ marginTop: 8, marginRight: 8, fontSize: 12, background: "none" }}
        onClick={() => setDialog(true)}
      >
        Remove passkey
      </Button>

      {dialog && (
        <ConfirmationDialog
          title="Remove passkey"
          content={
            <div style={{ marginBottom: 16 }}>
              You're about to remove passkey <b>{credential.name ?? ""}</b>
            </div>
          }
          onCancel={handleClose}
          onConfirm={handleRemovePasskey}
          progress={progress}
        />
      )}
    </>
  );
}
