import React, { ChangeEvent, CSSProperties, ReactNode } from "react";
import { Controller, RegisterOptions } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import { OutlinedTextFieldProps } from "@mui/material/TextField";

import AdornmentTextField from "../../components/AdornmentTextField";
import FormInputLabel from "../../components/FormInputLabel";
import PasswordStrengthMeter from "../../components/PasswordStrengthMeter";
import { useFormContext } from "./Form";
import { validators } from "./validation";

export interface TextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
  id?: string;
  name: string;
  label?: string;
  labelIcon?: string;
  hideLabel?: boolean;
  labelCaption?: string;
  defaultValue?: string;
  helperText?: string | ReactNode;
  disabled?: boolean;
  externalErrors?: any;
  style?: CSSProperties;
  tooltip?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  optional?: boolean;
  toggleVisibility?: boolean;
  defaultVisibility?: boolean;
  withProgress?: boolean;
  withCopy?: boolean;
  withLink?: boolean;
  withStartAdornment?: ReactNode | null;
  withEndAdornment?: ReactNode | null;
  withPasswordStrengthMeter?: boolean;
  valueAsNumber?: boolean;
  shouldUnregister?: boolean;
  rules?: Pick<RegisterOptions, "required" | "validate">;
  fontColor?: CSSProperties["color"];
}

export default function TextField({
  id,
  type,
  name,
  label = "",
  labelIcon,
  hideLabel,
  labelCaption,
  defaultValue = "",
  helperText = "",
  disabled,
  externalErrors = null,
  style,
  tooltip,
  onChange = () => {},
  optional,
  toggleVisibility = false,
  defaultVisibility = true,
  withProgress = false,
  withCopy = false,
  withLink = false,
  withStartAdornment = null,
  withEndAdornment = null,
  withPasswordStrengthMeter = false,
  valueAsNumber = false,
  shouldUnregister = false,
  rules,
  fontColor,
  ...props
}: TextFieldProps) {
  const { form, disabled: formDisabled, id: formId } = useFormContext();

  const required = rules?.required;
  const isOptional = optional !== undefined ? optional : !required;
  const inputID = `${id || formId}-${name}`;

  return (
    <Controller
      name={name}
      control={form.control}
      defaultValue={defaultValue}
      rules={{
        ...rules,
        validate: {
          maxLength: validators.maxLength({ label }),
          ...rules?.validate,
        },
      }}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => {
        const error = fieldState.error;

        return (
          <>
            <FormControl
              variant="outlined"
              fullWidth
              error={!!error || !!externalErrors}
              style={{ marginBottom: 32, ...style }}
            >
              {!hideLabel && (
                <FormInputLabel
                  id={`${inputID}-label`}
                  forId={`${inputID}-input`}
                  label={label}
                  icon={labelIcon}
                  optional={isOptional}
                  caption={labelCaption}
                  tooltip={tooltip}
                />
              )}

              <AdornmentTextField
                name={name}
                value={field.value}
                id={`${inputID}-input`}
                type={type}
                label={label}
                onChange={e => {
                  onChange && onChange(e);
                  return field.onChange(
                    type === "number" && valueAsNumber ? Number(e.target.value) : e.target.value
                  );
                }}
                toggleVisibility={toggleVisibility}
                defaultVisibility={defaultVisibility}
                disabled={disabled || formDisabled}
                error={!!error || !!externalErrors}
                helperText={error?.message || externalErrors || helperText}
                fullWidth
                withProgress={withProgress}
                withCopy={withCopy}
                withStartAdornment={withStartAdornment}
                withEndAdornment={withEndAdornment}
                withLink={withLink}
                fontColor={fontColor}
                {...props}
              />

              {withPasswordStrengthMeter && <PasswordStrengthMeter password={field.value} />}
            </FormControl>
          </>
        );
      }}
    />
  );
}
