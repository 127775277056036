import React from "react";

import DocLink from "./DocLink";

export default function QuickstartArticleUserOnboarding() {
  return (
    <>
      <p>
        Our platform provides flexible user onboarding and management options, including
        self-service registration, admin-initiated user creation, and custom registration page
        integration. Admins can create users with set credentials, send invitations for account
        activation, and enforce specific data attributes using JSON schemas for payload and metadata
        validation. The platform ensures secure registration practices, including protection against
        account enumeration vulnerabilities, customizable registration forms, and scalable
        infrastructure to handle large-scale onboarding.
      </p>
      <p>
        Easily set up a range of authentication methods that align with your security needs,
        including built-in options such as traditional passwords, verification codes, and
        passwordless methods, as well as external integrations with providers like Google Workspace,
        GitHub, and Microsoft Entra ID.
      </p>

      <ol type="a">
        <li>
          <b>Create a User with a Password</b>: Admins can create users directly in the dashboard by
          setting up credentials for them. This approach is suitable for cases where the admin needs
          full control over the user's initial setup.
          <p>Steps:</p>
          <ol>
            <li>
              Go to the <b>Users</b> section in the admin dashboard.
            </li>
            <li>
              Click <b>Create User</b>.
            </li>
            <li>
              Fill in the user's basic information, such as <b>Email address, First Name</b> and{" "}
              <b>Last Name</b>.
            </li>
            <li>
              Select the <b>Set credentials</b> option and enter a password for the user. You can
              also force the user to change the password upon first login.
            </li>
          </ol>
        </li>

        <li>
          <b>Invite a User to Set Up Their Own Account</b>: Instead of setting a password directly,
          admins can send an invitation to users, allowing them to complete the onboarding process
          and set their own credentials. This approach improves security by involving the user in
          the setup of their own password.
          <p>Steps:</p>
          <ol>
            <li>
              Go to the <b>Users</b> section in the admin dashboard.
            </li>

            <li>
              Click <b>Create User</b>.
            </li>
            <li>
              Fill in the user's basic information, such as <b>Email address, First Name</b> and{" "}
              <b>Last Name</b>.
            </li>
            <li>
              Choose the <b>Send invitation</b> option.
            </li>
            <li>
              Click <b>Create</b>.
            </li>
          </ol>
          <p>
            An email invitation will be sent to the user, allowing them to set up their own account
            credentials and activate their account.Once completed, the user's account is ready for
            immediate use.
          </p>
        </li>
        <li>
          <b>Manage User Accounts</b>: The platform also offers various management functions to
          maintain and update user accounts, ensuring they stay active and secure. When you click on
          the Edit option for a user, you'll see the settings below on the <b>Manage</b> section.
          <ol>
            <li>
              <b>Initiate reset password</b>: Allows administrators to start a password reset for
              the user, helping them regain access if they've forgotten their password.
            </li>
            <li>
              <b>Force reset password</b>: Immediately resets the user's password and requires them
              to set a new one upon their next login, enhancing security.
            </li>
            <li>
              <b>Force change password</b>: Requires the user to change their password on the next
              login, typically as a security measure without an immediate reset.
            </li>
            <li>
              <b>Set to inactive</b>: Temporarily disables the user's account, preventing access
              until reactivated.
            </li>
            <li>
              <b>Set to New</b>: Marks the account as new, often used when an account is created but
              hasn't yet been fully configured or used.
            </li>
            <li>
              <b>Set to Deleted</b>: Permanently removes the user's account from the platform, along
              with all associated data.
            </li>
          </ol>
        </li>
      </ol>
      <p>
        These management options allow administrators to efficiently maintain user profiles and
        ensure their accounts comply with organizational security requirements.
      </p>
      <br />
      <p>
        For more details, refer to{" "}
        <DocLink href="https://docs.secureauth.com/cloudentity/en/user-registration.html">
          SecureAuth User Registration
        </DocLink>
        .
      </p>
    </>
  );
}
