import React, { useState } from "react";
import { useNavigate } from "react-router";

import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";

import { Schema } from "@cloudentity/acp-identity";

import AccordionPaper from "../../../../../../../common/components/AccordionPaper";
import Alert from "../../../../../../../common/components/Alert";
import AutocompleteField from "../../../../../../../common/utils/forms/AutocompleteField";
import { useFormContext } from "../../../../../../../common/utils/forms/Form";
import { useCheckTenantPermissions } from "../../../../../../services/adminTenantsQuery";
import { getLabel } from "../../../../../identities/amrOptions";

type DataKeyType = "metadata" | "business_metadata" | "payload";

const typeMapper: {
  [key in DataKeyType]: { name: string; label: string };
} = {
  metadata: {
    name: "metadata_schema_id",
    label: "Metadata",
  },
  business_metadata: {
    name: "business_metadata_schema_id",
    label: "Business Metadata",
  },
  payload: {
    name: "payload_schema_id",
    label: "User",
  },
};

interface Props {
  schemas: Schema[];
  alert: boolean;
  poolId: string;
  disabled?: boolean;
  dataKey: DataKeyType;
  onChange: (schemaId: string) => void;
}

const IdentityPoolUserSchemasTabSelect = ({
  schemas,
  alert,
  poolId,
  disabled,
  dataKey,
  onChange,
}: Props) => {
  const { form } = useFormContext();
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const { name, label } = typeMapper[dataKey];

  return (
    <Paper style={{ margin: "24px 0" }}>
      <AccordionPaper
        id={`pool-user-${dataKey}-schema-accordion`}
        label={`${label} Attributes Schema`}
        expanded={expandedAccordion}
        onChange={() => setExpandedAccordion(!expandedAccordion)}
        withBottomBorder={false}
        mode={alert ? "warning" : "default"}
      >
        <AutocompleteField
          name={name}
          options={schemas.map(s => s.id)}
          getOptionLabel={getLabel(
            schemas.map(s => ({
              name: s.name,
              value: s.id,
            }))
          )}
          onChange={(_, value) => {
            onChange(value as string);
            form.setValue(name, value);
          }}
          rules={{
            required: `${label} schema is required`,
          }}
          disableClearable
          disabled={disabled || !checkTenantPermissionsQuery.data?.list_identity_pools}
        />

        {alert && <SchemaMigrationWarning poolId={poolId} label={label} />}
      </AccordionPaper>
    </Paper>
  );
};

const SchemaMigrationWarning = ({ poolId, label }: { poolId: string; label: string }) => {
  const navigate = useNavigate();
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  return (
    <Alert severity="warning" title="Schema Migration Warning">
      {label} schema is different than default identity pool schema.{" "}
      {checkTenantPermissionsQuery.data?.list_identity_pools ? (
        <>
          You can configure pool schema{" "}
          <Link onClick={() => navigate(`/identity-pools/pools/${poolId}/schemas`)}>here</Link>.
        </>
      ) : null}
    </Alert>
  );
};

export default IdentityPoolUserSchemasTabSelect;
