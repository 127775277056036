import React from "react";

import CssBaseline from "@mui/material/CssBaseline";

import PageContainer from "../admin/components/common/PageContainer";
import NavAndContentContainer from "../admin/components/nav/NavAndContentContainer";
import TopBar from "../admin/components/nav/TopBar";
import { OrgNavDrawer } from "./B2BOrganizationView";
import Settings from "./components/Settings/Settings";

export default function B2BPortalPreview({ organization, logoUrl }) {
  return (
    <div style={{ position: "relative" }}>
      <CssBaseline />
      <PageContainer>
        <TopBar stripped logoUrl={logoUrl} />;
        <NavAndContentContainer nav={() => OrgNavDrawer({ organization })}>
          <Settings organization={organization} isPreview />
        </NavAndContentContainer>
      </PageContainer>
    </div>
  );
}
