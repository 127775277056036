import React, { useMemo, useState } from "react";
import { useParams } from "react-router";

import { useQueryClient } from "@tanstack/react-query";

import { AddUserIdentifierTypeEnum } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../common/api/paths";
import Dialog from "../../../../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../../../common/utils/forms/FormFooter";
import PhoneField from "../../../../../../../common/utils/forms/PhoneField";
import RadioGroupField from "../../../../../../../common/utils/forms/RadioGroupField";
import TextFieldRequired from "../../../../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../../../../common/utils/forms/validation";
import adminIdentityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listUsersQueryKey,
} from "../../../../../../services/adminIdentityUsersQuery";

export default function AddUserIdentifierDialog({ onClose }) {
  const { id = "", userID = "" } = useParams<{ id: string; userID: string; tab: string }>();
  const tenantId = getTenantId();

  const [requestError, setRequestError] = useState("");
  const [progress, setProgress] = useState(false);

  const queryClient = useQueryClient();
  const data = useMemo(
    () => ({
      identifierType: AddUserIdentifierTypeEnum.Email,
      [AddUserIdentifierTypeEnum.Email]: "",
      [AddUserIdentifierTypeEnum.Mobile]: "",
      [AddUserIdentifierTypeEnum.Uid]: "",
      [AddUserIdentifierTypeEnum.External]: "",
    }),
    []
  );
  const form = useForm({ id: "add-user-identifier", initialValues: data, progress });
  const identifierType = form.watch("identifierType");

  const handleSubmit = data => {
    setProgress(true);
    adminIdentityUsersApi
      .addUserIdentifier({
        ipID: id,
        userID,
        newIdentifier: {
          identifier:
            data[AddUserIdentifierTypeEnum.Email] ||
            data[AddUserIdentifierTypeEnum.Mobile] ||
            data[AddUserIdentifierTypeEnum.Uid] ||
            data[AddUserIdentifierTypeEnum.External],
          type: data.identifierType,
        },
      })
      .then(() => queryClient.invalidateQueries({ queryKey: getUserQueryKey(tenantId, userID) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, id) }))
      .then(() => onClose())
      .then(() => notifySuccess("User identifier successfully added"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to add user identifier"))
      .finally(() => setProgress(false));
  };
  return (
    <Dialog onClose={onClose} id="add-user-identifier-dialog" title="Add User Identifier">
      <Form form={form}>
        <RadioGroupField
          name="identifierType"
          values={[
            {
              key: AddUserIdentifierTypeEnum.Email,
              label: "Email",
              value: AddUserIdentifierTypeEnum.Email,
            },
            {
              key: AddUserIdentifierTypeEnum.Mobile,
              label: "Mobile",
              value: AddUserIdentifierTypeEnum.Mobile,
            },
            {
              key: AddUserIdentifierTypeEnum.Uid,
              label: "Uid",
              value: AddUserIdentifierTypeEnum.Uid,
            },
            {
              key: AddUserIdentifierTypeEnum.External,
              label: "External",
              value: AddUserIdentifierTypeEnum.External,
            },
          ]}
          onChange={() => setRequestError("")}
          style={{ marginBottom: 12 }}
        />

        {identifierType === AddUserIdentifierTypeEnum.Email && (
          <TextFieldRequired
            name={AddUserIdentifierTypeEnum.Email}
            label="Email"
            rules={{
              validate: {
                validEmail: validators.validEmail({ label: "Value" }),
              },
            }}
            inputProps={{ autoComplete: "off" }}
            optional={false}
            externalErrors={requestError || null}
            onChange={() => setRequestError("")}
          />
        )}
        {identifierType === AddUserIdentifierTypeEnum.Mobile && (
          <PhoneField
            name={AddUserIdentifierTypeEnum.Mobile}
            label="Phone"
            selector="#add-user-identifier-phone-input"
            optional={false}
            required
            externalErrors={requestError || null}
            onChange={() => setRequestError("")}
          />
        )}

        {identifierType === AddUserIdentifierTypeEnum.Uid && (
          <TextFieldRequired
            name={AddUserIdentifierTypeEnum.Uid}
            label="Uid"
            rules={{
              validate: {
                onlyAsciiWithoutWhitespaces: validators.onlyAsciiWithoutWhitespaces({
                  label: "Uid",
                }),
              },
            }}
            optional={false}
          />
        )}
        {identifierType === AddUserIdentifierTypeEnum.External && (
          <TextFieldRequired
            name={AddUserIdentifierTypeEnum.External}
            label="External"
            rules={{
              validate: {
                onlyAsciiWithoutWhitespaces: validators.onlyAsciiWithoutWhitespaces({
                  label: "External",
                }),
              },
            }}
            optional={false}
          />
        )}

        <FormFooter onCancel={onClose} onSubmit={handleSubmit} submitText="Add" />
      </Form>
    </Dialog>
  );
}
