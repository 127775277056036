import React, { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ChevronDown } from "react-feather";

import {
  BaseUserWithData,
  UpdateUserStatusEnum,
  UserWithData,
  UserWithDataStatusEnum,
} from "@cloudentity/acp-identity";

import { useCheckPoolPermissions } from "../../../../../../../services/adminIdentityPoolsQuery";
import ManageUserActionsResendActivationEmail from "../../../../../administrator/ManageUserActionsResendActivationEmail";
import IdentityPoolUserSidePanelForceChangePassword from "./IdentityPoolUserSidePanelForceChangePassword";
import IdentityPoolUserSidePanelForceResetPassword from "./IdentityPoolUserSidePanelForceResetPassword";
import IdentityPoolUserSidePanelResetPassword from "./IdentityPoolUserSidePanelResetPassword";
import IdentityPoolUserSidePanelSetPasswordAsValid from "./IdentityPoolUserSidePanelSetPasswordAsValid";

interface Props {
  workspaceId?: string;
  user: UserWithData | BaseUserWithData;
  progress: boolean;
  onStatusChange: (status: UpdateUserStatusEnum) => void;
}

const IdentityPoolUserSidePanelManageButton = ({
  workspaceId,
  user,
  progress,
  onStatusChange,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleStatusChange = (status: UpdateUserStatusEnum) => {
    handleClose();
    onStatusChange(status);
  };

  const checkPoolPermissionsQuery = useCheckPoolPermissions(user?.user_pool_id!, {
    enabled: !!user?.user_pool_id,
  });

  return (
    <>
      <LoadingButton
        id="manage-user-button"
        loading={progress}
        variant="contained"
        onClick={e => setAnchorEl(e.currentTarget)}
        endIcon={<ChevronDown size={18} />}
      >
        Manage
      </LoadingButton>

      <Menu
        id="manage-user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ManageUserActionsResendActivationEmail
          user={user}
          onClick={handleClose}
          workspaceId={workspaceId}
        />

        <IdentityPoolUserSidePanelResetPassword
          user={user}
          workspaceId={workspaceId}
          handleClose={handleClose}
        />
        {!!checkPoolPermissionsQuery.data?.manage_user_passwords && (
          <IdentityPoolUserSidePanelForceResetPassword user={user} handleClose={handleClose} />
        )}
        {!!checkPoolPermissionsQuery.data?.manage_user_passwords && (
          <IdentityPoolUserSidePanelForceChangePassword user={user} handleClose={handleClose} />
        )}
        {!!checkPoolPermissionsQuery.data?.manage_user_passwords && (
          <IdentityPoolUserSidePanelSetPasswordAsValid user={user} handleClose={handleClose} />
        )}

        {user.status !== UserWithDataStatusEnum.Active && (
          <MenuItem
            id="set-user-to-active-button"
            onClick={() => {
              handleStatusChange(UpdateUserStatusEnum.Active);
            }}
            disabled={progress}
          >
            Set to Active
          </MenuItem>
        )}
        {user.status !== UserWithDataStatusEnum.Inactive && (
          <MenuItem
            id="set-user-to-inactive-button"
            onClick={() => {
              handleStatusChange(UpdateUserStatusEnum.Inactive);
            }}
            disabled={progress}
          >
            Set to Inactive
          </MenuItem>
        )}
        {user.status !== UserWithDataStatusEnum.New && (
          <MenuItem
            id="set-user-to-new-button"
            onClick={() => {
              handleStatusChange(UpdateUserStatusEnum.New);
            }}
            disabled={progress}
          >
            Set to New
          </MenuItem>
        )}
        {user.status !== UserWithDataStatusEnum.Deleted && (
          <MenuItem
            id="set-user-to-deleted-button"
            onClick={() => {
              handleStatusChange(UpdateUserStatusEnum.Deleted);
            }}
            disabled={progress}
          >
            Set to Deleted
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default IdentityPoolUserSidePanelManageButton;
