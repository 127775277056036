import React from "react";

import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ChevronDown, ChevronRight, MoreVertical } from "react-feather";
import { makeStyles } from "tss-react/mui";

import OverflowTooltip from "../../../../common/components/OverflowTooltip";
import { InnerRowType, InnerRowsProps, RowType } from "./types";

const useStyles = makeStyles()(theme => ({
  data: {
    cursor: "pointer !important",
  },
  innerTableRow: {
    "&:last-child td": {
      borderBottom: "none",
    },
  },
  innerTableCell: {
    padding: "0 0 0 62px",
    backgroundColor: theme.palette.background.default,
  },
  innerRowInfo: {
    display: "flex",
    maxWidth: "100%",
    width: "100%",
  },
  innerRowInfoText: {
    maxWidth: "calc(100% - 75px)",
    marginTop: 6,
    display: "flex",
    flexDirection: "column",
    "& > p": {
      color: "black",
      lineHeight: "16px",
    },
    "& > span": {
      color: theme.palette.secondary.light,
    },
  },
  innerRowInfoSubtext: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  workspaceNumberCell: {
    color: theme.palette.primary.main,
  },
  openerCell: {
    width: 56,
    padding: 16,
  },
  innerTableHeader: {
    padding: "12px 16px",
  },
  withHover: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

interface Props {
  data: RowType;
  isOpen: boolean;
  progress: boolean;
  openedRows: string[];
  setOpenedRows: (openedRows: string[]) => void;
  setMainMenu: (mainMenu: any) => void;
  setInnerRowMenu?: (innerRowMenu: any) => void;
  innerTableHeader: string;
  onNameClick?: (data: RowType) => void;
  showInnerRowsCount?: boolean;
}

export function InnerRows({
  rowId,
  innerRows,
  progress,
  innerTableHeader,
  setInnerRowMenu,
}: InnerRowsProps & {
  innerRows: InnerRowType[];
}) {
  const { classes, cx } = useStyles();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.innerTableHeader}>{innerTableHeader}</TableCell>
          {setInnerRowMenu && <TableCell component="th" align="right" />}
        </TableRow>
      </TableHead>
      <TableBody>
        {innerRows.map(innerRow => {
          return (
            <TableRow
              key={`table-data-${rowId}-inner-row-${innerRow.id}`}
              id={`table-data-${rowId}-inner-row-${innerRow.id}`}
              className={classes.innerTableRow}
            >
              <TableCell>
                <div className={classes.innerRowInfo}>
                  <Avatar
                    style={{
                      marginRight: 8,
                      backgroundColor: innerRow.color,
                    }}
                  >
                    {innerRow.icon}
                  </Avatar>
                  <div
                    className={cx(
                      classes.innerRowInfoText,
                      innerRow.onTextClick && classes.withHover
                    )}
                    onClick={() => innerRow.onTextClick && innerRow.onTextClick()}
                  >
                    <OverflowTooltip
                      id={`table-inner-data-row-${rowId}-tooltip`}
                      value={innerRow.text}
                    />
                    <Typography variant="textXSM" className={classes.innerRowInfoSubtext}>
                      {innerRow.subText}
                    </Typography>
                  </div>
                </div>
              </TableCell>
              {setInnerRowMenu && (
                <TableCell align="right">
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      if (innerRow.id) {
                        setInnerRowMenu({
                          anchorEl: e.currentTarget,
                          row: innerRow,
                        });
                      }
                    }}
                    id={`${rowId}-inner-row-menu-button`}
                    disabled={!!progress}
                  >
                    <MoreVertical />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function Row({
  data,
  isOpen,
  progress,
  openedRows,
  setOpenedRows,
  setMainMenu,
  setInnerRowMenu,
  innerTableHeader,
  onNameClick,
  showInnerRowsCount,
}: Props) {
  const { classes } = useStyles();
  const isInnerRowsComponent = !data.innerRows && !!data.InnerRows;
  const innerRowsLength = data.innerRows?.length ?? 0;
  const canOpen = isInnerRowsComponent || innerRowsLength > 0;

  return (
    <>
      {progress && <LinearProgress style={{ position: "absolute", width: "100%" }} />}
      <TableRow
        className={classes.data}
        style={{ cursor: innerRowsLength ? "pointer" : "default" }}
        onClick={
          canOpen
            ? () => {
                setOpenedRows(
                  isOpen ? openedRows.filter(v => v !== data.id) : [...openedRows, data.id ?? ""]
                );
              }
            : undefined
        }
        id={`table-data-main-row-${data.id}`}
        key={`table-data-main-row-${data.id}`}
      >
        {canOpen ? (
          <TableCell component="th" className={classes.openerCell}>
            {isOpen ? <ChevronDown /> : <ChevronRight />}
          </TableCell>
        ) : (
          <TableCell className={classes.openerCell} />
        )}
        <TableCell component="th" style={{ display: "flex", alignItems: "center" }}>
          {data.icon}
          <div
            style={{ marginLeft: 8 }}
            className={onNameClick && classes.withHover}
            onClick={() => onNameClick && onNameClick(data)}
          >
            {data.name}
          </div>
        </TableCell>
        {showInnerRowsCount && (
          <TableCell component="th" align="center" className={classes.workspaceNumberCell}>
            {innerRowsLength}
          </TableCell>
        )}
        <TableCell width="5%">
          <IconButton
            onClick={e => {
              e.stopPropagation();
              if (data.id) {
                setMainMenu({
                  anchorEl: e.currentTarget,
                  row: data,
                });
              }
            }}
            id={`${data.id}-menu-button`}
            disabled={!!progress}
          >
            <MoreVertical />
          </IconButton>
        </TableCell>
      </TableRow>
      {canOpen && (
        <TableRow key={`table-inner-data-row-${data.id}`} style={{ border: "none" }}>
          <TableCell
            className={classes.innerTableCell}
            colSpan={4}
            style={{ border: "none", borderBottom: isOpen ? "solid 1px #e0e0e0" : "none" }}
          >
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              {(data.innerRows && (
                <InnerRows
                  rowId={data.id}
                  innerRows={data.innerRows}
                  progress={progress}
                  innerTableHeader={innerTableHeader}
                  setInnerRowMenu={setInnerRowMenu}
                />
              )) ||
                (data.InnerRows && (
                  <data.InnerRows
                    rowId={data.id}
                    progress={progress}
                    innerTableHeader={innerTableHeader}
                    setInnerRowMenu={setInnerRowMenu}
                  />
                ))}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
export default Row;
