import React from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import zxcvbn, { ZXCVBNResult } from "zxcvbn";

import { PasswordPolicyStrengthEnum } from "@cloudentity/acp-identity";

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: 16,
  },
  meter: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridColumnGap: 8,
  },
  step: {
    height: 4,
    backgroundColor: "lightgray",
    borderRadius: 4,
  },

  weak: {
    backgroundColor: "#F25F5C",
  },
  fair: {
    backgroundColor: "#FFE066",
  },
  strong: {
    backgroundColor: "yellowgreen",
  },
  veryStrong: {
    backgroundColor: "forestgreen",
  },
  strengthText: {
    color: theme.palette.secondary.light,
  },
}));

export const passwordPolicyToScore = {
  [PasswordPolicyStrengthEnum.Any]: 0,
  [PasswordPolicyStrengthEnum.Weak]: 1,
  [PasswordPolicyStrengthEnum.Fair]: 2,
  [PasswordPolicyStrengthEnum.Strong]: 3,
  [PasswordPolicyStrengthEnum.VeryStrong]: 4,
};

function getPasswordLabelAndClass(result: ZXCVBNResult) {
  switch (result.score) {
    case 0:
      return { label: "Weak", class: "weak" };
    case 1:
      return { label: "Weak", class: "weak" };
    case 2:
      return { label: "Fair", class: "fair" };
    case 3:
      return { label: "Strong", class: "strong" };
    case 4:
      return { label: "Very Strong", class: "veryStrong" };
    default:
      return { label: "Weak", class: "weak" };
  }
}

interface Props {
  password: string | undefined;
}

function PasswordStrengthMeter({ password }: Props) {
  const { cx, classes } = useStyles();

  const result = password ? zxcvbn(password) : null;
  const data = result ? getPasswordLabelAndClass(result) : null;

  return (
    <div className={classes.container} id="password-strength-meter-container">
      <div className={classes.meter}>
        {Array.from({ length: 5 }, (_, i) => {
          const isColored = result && data && i <= result.score;
          return (
            <div
              key={i}
              className={cx(classes.step, isColored && classes[data.class])}
              data-stepvalue={isColored ? data.class : "none"}
            />
          );
        })}
      </div>
      <Typography variant="caption" className={classes.strengthText}>
        {data ? (
          <>
            Password strength: <b>{data.label}</b>
          </>
        ) : (
          "No password"
        )}
      </Typography>
    </div>
  );
}

export default PasswordStrengthMeter;
