import React, { useRef } from "react";

import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { AlertOctagon, AlertTriangle, Info, X } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "iconContainer">()((theme, _, classes) => ({
  common: {
    backgroundColor: "white !important",
    padding: "0 !important",
    height: 56,
    "& #notistack-snackbar": {
      padding: 0,
      height: "100%",
      color: theme.palette.secondary.dark,
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: "24px",
    },
    "& > div:last-of-type": {
      marginRight: 0,
      paddingRight: 12,
    },
    "&.notistack-MuiContent-success": {
      [`& .${classes.iconContainer}`]: {
        backgroundColor: theme.palette.success.main,
      },
    },
    "&.notistack-MuiContent-error": {
      [`& .${classes.iconContainer}`]: {
        backgroundColor: theme.palette.error.main,
      },
    },
    "&.notistack-MuiContent-warning": {
      [`& .${classes.iconContainer}`]: {
        backgroundColor: "#FFD33D",
        "& > svg": {
          color: theme.palette.secondary.dark,
        },
      },
    },
    "&.notistack-MuiContent-info": {
      [`& .${classes.iconContainer}`]: {
        backgroundColor: "#008EFF",
      },
    },
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: 56,
    marginRight: 16,
  },
  icon: {
    color: "white",
  },
}));

export default function NotificationsProvider({ children }) {
  const notistackRef = useRef<any>(null);
  const { classes } = useStyles();

  const onClickDismiss = (key: SnackbarKey) => {
    notistackRef?.current?.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      className={classes.common}
      iconVariant={{
        success: (
          <div className={classes.iconContainer}>
            <CheckIcon className={classes.icon} />
          </div>
        ),
        error: (
          <div className={classes.iconContainer}>
            <AlertOctagon className={classes.icon} />
          </div>
        ),
        warning: (
          <div className={classes.iconContainer}>
            <AlertTriangle className={classes.icon} />
          </div>
        ),
        info: (
          <div className={classes.iconContainer}>
            <Info className={classes.icon} />
          </div>
        ),
      }}
      action={key => (
        <IconButton
          onClick={() => onClickDismiss(key)}
          id="notification-dismiss-button"
          size="small"
        >
          <X size="20" />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}
