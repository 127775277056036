import React from "react";
import { useNavigate } from "react-router";

import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";

import OverflowTooltip from "../../../../../common/components/OverflowTooltip";
import SelectablePaper from "../../../../../common/components/SelectablePaper";
import { useWorkspaceCardStyles } from "../../workspaces/utils";
import { usePoolRootUrl } from "../utils";
import IdentityPoolCardIcons from "./IdentityPoolCardIcons";
import { getNumberOfUsers } from "./utils";

const useStyles = makeStyles()(() => ({
  population: {
    fontWeight: "bold",
    padding: "3px 12px",
    backgroundColor: "#F2F3F4",
    minWidth: 50,
    textAlign: "center",
  },
}));

interface Props {
  pool: PoolResponse;
  onMenuOpen?: ({ elementId, pool }: { elementId: string; pool: PoolResponse }) => void;
  onSelect?: (id: string) => void;
  showIcons?: boolean;
}

export default function IdentityPoolsGridItem({
  pool,
  onMenuOpen,
  onSelect,
  showIcons = true,
}: Props) {
  const { classes: componentClasses } = useStyles();
  const { classes } = useWorkspaceCardStyles();

  const { rootUrl } = usePoolRootUrl();

  const navigate = useNavigate();
  const { id, name, description } = pool;

  return (
    <SelectablePaper id={`${pool.id}`} className={classes.card} selectable>
      <div className={classes.cardTop}>
        <div
          className={classes.cardTopLeft}
          onClick={() => {
            if (onSelect) {
              onSelect(pool.id ?? "");
            } else {
              navigate(`${rootUrl}/${pool.id}/users`);
            }
          }}
        >
          <Avatar style={{ backgroundColor: pool.badge_color || "#BDBDBD" }}>
            {(name && name[0] && name[0].toUpperCase()) || ""}
          </Avatar>
          <div className={classes.cardTopLeftName}>
            <OverflowTooltip id="pool-card-name-tooltip" value={name} />
            <Typography variant="textXSM" className={classes.cardTopLeftType}>
              {description || "No description"}
            </Typography>
          </div>
        </div>
        {onMenuOpen && (
          <div className={classes.cardActions}>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                onMenuOpen({ elementId: e.currentTarget.id, pool });
              }}
              id={`pool-${id}-menu-button`}
              className={classes.iconButton}
            >
              <MoreHoriz />
            </IconButton>
          </div>
        )}
      </div>
      <div>
        <div className={classes.cardBottom}>
          <div className={classes.cardBottomItem}>
            {showIcons && <IdentityPoolCardIcons pool={pool} />}
          </div>
          <div className={classes.cardBottomItem} style={{ justifyContent: "flex-end" }}>
            <Typography
              variant="textSM"
              className={componentClasses.population}
              id={`pool-${pool.id}-population`}
            >
              {getNumberOfUsers(pool.number_of_users)}
            </Typography>
          </div>
        </div>
      </div>
    </SelectablePaper>
  );
}
