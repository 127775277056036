import React, { useCallback, useRef, useState } from "react";
import { Controller } from "react-hook-form";

import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import throttle from "lodash/throttle";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../components/FormInputLabel";
import { TRANSPARENT_HEX_VALUE } from "../../components/nav/utils";
import { useFormContext } from "./Form";

const useStyles = makeStyles()(theme => ({
  container: {
    marginBottom: 12,
  },
  innerContainer: {
    border: "solid 1px #c4c4c4",
    boxSizing: "border-box",
    borderRadius: 4,
    backgroundColor: theme.custom.greys.fieldBackground,
    padding: 12,
    display: "flex",
    alignItems: "center",
  },
  focused: {
    borderColor: theme.palette.primary.main,
  },
  input: {
    height: 24,
    width: 24,
    background: "none",
    borderRadius: 4,
    border: "none",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    marginRight: 12,

    "&::-webkit-color-swatch": {
      borderColor: theme.custom.greys.border,
      borderRadius: 4,
      padding: 0,
      margin: 0,
    },
    "&::-webkit-color-swatch-wrapper": {
      border: "none",
      borderRadius: 10,
      padding: 0,
      margin: 0,
    },
  },
  textInput: {
    width: 80,
    "&>input": {
      padding: 0,
    },
  },
  error: {
    border: `solid 1px ${theme.palette.error.main}`,
  },
  colorBox: {
    position: "absolute",
    width: 24,
    height: 24,
    borderRadius: 4,
    marginRight: 12,
    cursor: "pointer",
    background: "repeating-conic-gradient(#CECECE 0% 25%, transparent 0% 50%) 50% / 16px 16px",
  },
}));

const hexColorRegex = new RegExp(/^#[0-9A-F]{6}(:?[0-9A-F]{2})?$/i);

export interface ColorFieldProps {
  name: string;
  label: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

export default function ColorField({
  name,
  label,
  defaultValue,
  disabled,
  onChange,
  ...props
}: ColorFieldProps) {
  const { cx, classes } = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const { form, disabled: formDisabled, id } = useFormContext();

  const onDebouncedChange =
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(
      throttle(value => {
        if (onChange && hexColorRegex.test(value)) {
          onChange(value);
        }
        form.setValue(name, value);
        form.trigger(name);
        if (inputRef.current) {
          inputRef.current.value = value;
        }
      }, 100),
      [name]
    );

  return (
    <Controller
      name={name}
      control={form.control}
      rules={{
        pattern: {
          value: hexColorRegex,
          message: "Invalid HEX color",
        },
      }}
      defaultValue={defaultValue || null}
      render={({ fieldState, field }) => (
        <div className={classes.container}>
          <FormInputLabel
            id={`${id}-${name}-switch-label`}
            forId={`${id}-${name}-text-input`}
            label={label}
            style={{ marginBottom: 4 }}
          />
          <div
            className={cx(
              classes.innerContainer,
              isFocused && classes.focused,
              fieldState.error && classes.error
            )}
          >
            <input
              ref={inputRef}
              type="color"
              id={`${id}-${name}-switch`}
              className={classes.input}
              value={field.value}
              onChange={e => {
                onDebouncedChange && onDebouncedChange(e.currentTarget.value);
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              disabled={disabled || formDisabled}
              style={{ visibility: field.value === TRANSPARENT_HEX_VALUE ? "hidden" : "visible" }}
              {...props}
            />
            {field.value === TRANSPARENT_HEX_VALUE && (
              <div
                onClick={() => {
                  inputRef.current?.click();
                  setIsFocused(true);
                }}
                className={classes.colorBox}
                style={{
                  visibility: field.value !== TRANSPARENT_HEX_VALUE ? "hidden" : "visible",
                }}
              ></div>
            )}
            <Input
              id={`${id}-${name}-text-input`}
              value={field.value}
              disabled={disabled || formDisabled}
              onChange={e => {
                if (!e.currentTarget.value.includes("#")) {
                  e.currentTarget.value = `#${e.currentTarget.value}`;
                }

                onDebouncedChange && onDebouncedChange(e.currentTarget.value);
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  e.currentTarget.blur();
                }
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className={classes.textInput}
              disableUnderline
            />
          </div>
          <FormHelperText style={{ minHeight: 20 }} error>
            {fieldState.error?.message}
          </FormHelperText>
        </div>
      )}
    />
  );
}
