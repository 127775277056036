import React from "react";

import Button from "@mui/material/Button";
import { User } from "react-feather";

import { useTopBarStyles } from "./utils";

interface Props {
  stripped?: boolean;
  email?: string;
  tenantId: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function NavbarButton({ stripped, email, tenantId, onClick }: Props) {
  const { cx, classes } = useTopBarStyles({ stripped });

  return (
    <Button
      className={classes.profileButton}
      onClick={onClick}
      aria-label="show profile settings"
      aria-haspopup="true"
      id="profile-navbar-button"
    >
      <div className={classes.avatar}>{email ? email.slice(0, 2) : <User size={20} />}</div>
      {!stripped && (
        <>
          <div className={classes.hideDesktop} style={{ marginLeft: 8 }}>
            {email ? <div className={cx(classes.name, classes.textEllipsis)}>{email}</div> : null}
            <div className={cx(classes.tenantName, classes.textEllipsis)}>
              <span className={classes.tenantNameLabel}>Tenant</span>
              {tenantId}
            </div>
          </div>
          <div className={cx(classes.tenantNameSpace, classes.hideDesktop)} />
        </>
      )}
      <span style={{ marginLeft: 8 }}>▾</span>
    </Button>
  );
}
