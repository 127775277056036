import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import {
  OrganizationResponse,
  OrganizationResponseSubjectFormatEnum,
  WorkspaceResponse,
  WorkspaceResponseSubjectFormatEnum,
} from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import adminServersApi from "../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  listWorkspacesQueryKey,
} from "../../services/adminServersQuery";
import { useListThemes } from "../../services/adminThemesQuery";

interface Props {
  server: WorkspaceResponse | OrganizationResponse;
  onClose: () => void;
}

export default function BindThemToServerDialog({ server, onClose }: Props) {
  const [progress, setProgress] = useState(false);

  const form = useForm({
    id: "webhook-api-key-info",
    initialValues: server as Omit<WorkspaceResponse | OrganizationResponse, "subject_format"> & {
      subject_format: OrganizationResponseSubjectFormatEnum | WorkspaceResponseSubjectFormatEnum;
    },
    progress,
  });

  const themesQuery = useListThemes(getTenantId());
  const queryClient = useQueryClient();

  const handleSubmit = data => {
    setProgress(true);
    adminServersApi
      .bindServerTheme({ wid: server.id!, themeID: data.theme_id })
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: getAuthorizationServerQueryKey(getTenantId(), server.id),
        })
      )
      .then(() => {
        notifySuccess("Server successfully bound to theme");
        onClose();
      })
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to bind server to theme"))
      .finally(() => setProgress(false));
  };

  const getOptionLabel = option =>
    (themesQuery.data?.themes || []).find(theme => theme.id === option)?.name || option;

  return (
    <Dialog id="bind-theme-to-server-dialog" open title="Bind theme">
      <Form form={form}>
        <AutocompleteField
          name="theme_id"
          label="Theme"
          options={(themesQuery.data?.themes || []).map(t => t.id)}
          optional={false}
          getOptionLabel={getOptionLabel}
          rules={{
            required: "Theme is required",
          }}
        />
        <FormFooter onCancel={onClose} onSubmit={handleSubmit} submitText="Bind" />
      </Form>
    </Dialog>
  );
}
