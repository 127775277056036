import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { TreeItem, TreeItemProps } from "@mui/x-tree-view/TreeItem";
import { makeStyles } from "tss-react/mui";

import { CloseSquare, MinusSquare, PlusSquare } from "./utils";

const useStyles = makeStyles<{ canExpand?: boolean }>()((_, { canExpand }) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed gray`,
  },
  label: {
    padding: "8px 0",
  },
  focused: {
    backgroundColor: "transparent !important",
  },
  selected: {
    backgroundColor: "transparent !important",
  },
  content: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    ...(canExpand ? {} : { pointerEvents: "none" }),
  },
}));

interface Props extends TreeItemProps {
  canExpand: boolean;
  loading: boolean;
}

export default function OrganizationsHierarchyTreeItem({
  canExpand,
  loading,
  children,
  ...props
}: Props) {
  const { classes } = useStyles({ canExpand });
  const expandIcon = (loading && <CircularProgress size={14} />) ||
    (canExpand && (
      <span data-testid={`${props["data-testid"]}-expand-icon`}>
        <PlusSquare />
      </span>
    )) || (
      <span data-testid={`${props["data-testid"]}-expand-icon-disabled`}>
        <CloseSquare />
      </span>
    );

  return (
    <TreeItem
      {...props}
      classes={{
        iconContainer: classes.iconContainer,
        group: classes.group,
        label: classes.label,
        focused: classes.focused,
        selected: classes.selected,
        content: classes.content,
      }}
      expandIcon={expandIcon}
      collapseIcon={
        <span>
          <MinusSquare data-testid={`${props["data-testid"]}-collapse-icon`} />
        </span>
      }
    >
      {children}
    </TreeItem>
  );
}
