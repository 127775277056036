import React, { CSSProperties, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";

import Colorize from "@mui/icons-material/Colorize";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import { makeStyles } from "tss-react/mui";

import { ServerInfo, ServerResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import { initialColors } from "../workspaceDirectory/WorkspacesColorInput";
import { SizeType, getServerAvatarIcon } from "../workspaceDirectory/server-profiles";

function getSize(size: Props["size"]) {
  if (size === "large") return 80;
  if (size === "small") return 32;
  if (size === "smaller") return 24;
  return 40;
}

function getFontSize(size: Props["size"]) {
  if (size === "large") return 24;
  if (size === "small") return 12;
  if (size === "smaller") return 10;
  return 14;
}

const useStyles = makeStyles<void, "colorize">()((theme, _, classes) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pickerButton: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    border: "none",
    background: "none",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    cursor: "pointer",
    "&:hover": {
      [`& .${classes.colorize}`]: {
        visibility: "visible",
      },
    },
  },
  colorize: {
    visibility: "hidden",
    border: `solid 1px ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 26,
    height: 26,
    borderRadius: "50%",
    backgroundColor: "#F4F4F4",
    "&:hover": {
      backgroundColor: "#F4F4F4",
    },
  },
  pickerContainer: {
    padding: 4,
    borderRadius: 4,
    width: 210,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  colors: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 16,
    marginLeft: 6,
  },
  color: {
    width: 16,
    height: 16,
    border: "1px solid #D0D5DD",
    borderRadius: 4,
    marginRight: 12,
    marginBottom: 8,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

interface Props {
  server?: Pick<
    ServerResponse | WorkspaceResponse | (ServerInfo & { id: string }),
    "id" | "name" | "type" | "profile" | "color"
  >;
  size?: SizeType;
  style?: CSSProperties;
  progress?: boolean;
  withColorPicker?: boolean;
  onColorSelected?: (color: string) => void;
  isOrganization?: boolean;
}

export default function WorkspaceAvatar({
  server,
  size,
  style,
  progress,
  withColorPicker,
  onColorSelected,
  isOrganization,
}: Props) {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [color, setColor] = useState("");
  const pxSize = getSize(size);

  useEffect(() => {
    if (server?.color) {
      setColor(server.color);
    }
  }, [server]);

  return (
    <div className={classes.container}>
      {progress ? (
        <CircularProgress />
      ) : (
        <>
          {withColorPicker && onColorSelected && (
            <>
              <button
                type="button"
                className={classes.pickerButton}
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                }}
              >
                <div className={classes.colorize}>
                  <Colorize style={{ fontSize: 14 }} />
                </div>
              </button>

              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => {
                  if (color !== server?.color) {
                    onColorSelected(color);
                  }
                  setAnchorEl(null);
                }}
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
              >
                <div className={classes.pickerContainer}>
                  <HexColorPicker color={color} onChange={setColor} />

                  <div className={classes.colors}>
                    {initialColors.map(paletteColor => (
                      <div
                        key={paletteColor}
                        className={classes.color}
                        style={{
                          background: paletteColor,
                          ...(color === paletteColor
                            ? { boxShadow: "0 0 0 1px #007FFF", border: "1px solid #fff" }
                            : {}),
                        }}
                        onClick={() => setColor(paletteColor)}
                      />
                    ))}
                  </div>
                </div>
              </Popover>
            </>
          )}

          <Avatar
            style={{
              width: pxSize,
              height: pxSize,
              fontSize: getFontSize(size),
              fontWeight: 600,
              backgroundColor: server?.color,
              ...style,
            }}
          >
            {getServerAvatarIcon(server, size, isOrganization)}
          </Avatar>
        </>
      )}
    </div>
  );
}
