import { numberFormatter } from "../../../common/utils";

export type GridMode = "full-card" | "table";

export const GRID_MODE_KEY = "identity-pools-grid-mode";

export const getNumberOfUsers = (num: number | undefined) => {
  const numberOfUsers = numberFormatter(num);
  return numberOfUsers.number !== null
    ? `${numberOfUsers.number.toLocaleString("en-US")} ${numberOfUsers.unit ?? ""}`
    : "0";
};

export const getOptionLabel = options => option =>
  options.find(o => o.value === option)?.name ?? option;

export const toAutocompleteOptions = options => options.map(o => o.value);
