import {
  TemplatesApi,
  TemplatesApiDeleteTemplateRequest,
  TemplatesApiGetDefaultTemplateRequest,
  TemplatesApiGetTemplateRequest,
  TemplatesApiListThemeTemplatesRequest,
  TemplatesApiUpsertTemplateRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => TemplatesApi = adminApiClazz(TemplatesApi);

const adminTemplatesApi = {
  listDefaultTemplates: () => api().listDefaultTemplates(),
  listThemeTemplates: (req: TemplatesApiListThemeTemplatesRequest) => api().listThemeTemplates(req),
  upsertTemplate: (req: TemplatesApiUpsertTemplateRequest) => api().upsertTemplate(req),
  getDefaultTemplate: (req: TemplatesApiGetDefaultTemplateRequest) => api().getDefaultTemplate(req),
  getTemplate: (req: TemplatesApiGetTemplateRequest) => api().getTemplate(req),
  deleteTemplate: (req: TemplatesApiDeleteTemplateRequest) => api().deleteTemplate(req),
};

export default adminTemplatesApi;
