import {
  OrganizationResponse,
  OrganizationsResponse,
  UserOrganizationsResponse,
} from "@cloudentity/acp-admin";

import {
  listOrganizationsQueryKey,
  listUserOrganizationsQueryKey,
  useListOrganizations,
  useListUserOrganizations,
} from "../../../services/adminOrganizationsQuery";
import { useCheckTenantPermissions } from "../../../services/adminPermissionsQuery";
import useItemsWithQuery from "./useItemsWithQuery";

export default function useOrganizationsSeqOrCursor({
  forceMode,
  template,
  enabled = true,
  parentId,
}: {
  forceMode?: "seq" | "cursor";
  template?: boolean;
  enabled?: boolean;
  parentId?: string;
} = {}) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations =
    forceMode !== undefined
      ? forceMode === "seq"
      : checkTenantPermissionsQuery.data?.list_organizations;

  const limit = 50;

  const seqData = useItemsWithQuery<OrganizationResponse, OrganizationsResponse>({
    id: "organizations-seq",
    idGetter: pool => pool.id,
    getArray: (queryData, params) => {
      const data = queryData?.organizations ?? [];
      return {
        data: data.slice(0, params.limit),
        nextPageAvailable: data.length > params.limit,
      };
    },
    getTotalItemsQueryKey: params => [
      ...listOrganizationsQueryKey(parentId, params.limit + 1),
      {
        template,
        searchPhrase: params.searchText || undefined,
        sort: params.sort,
        order: params.order,
      },
    ],
    useQueryFn: (page, params) =>
      useListOrganizations(
        {
          template,
          searchPhrase: params.searchText || undefined,
          sort: params.sort,
          order: params.order,
          limit: params.limit + 1,
          beforeOrganizationId: undefined,
          afterOrganizationId: page.afterItemId,
          parentId,
        },
        { keepPreviousData: true, enabled: enabled && canListOrganizations === true }
      ),
    initialSort: "name",
    enabled: enabled && canListOrganizations === true,
  });

  const cursorData = useItemsWithQuery<OrganizationResponse, UserOrganizationsResponse>({
    id: "organizations-cursor",
    idGetter: (_, queryData) => queryData.cursor,
    getArray: queryData => {
      const data = queryData?.organizations ?? [];
      return {
        data: data.slice(0, limit),
        nextPageAvailable: data.length > limit,
      };
    },
    getTotalItemsQueryKey: () => listUserOrganizationsQueryKey(limit + 1),
    useQueryFn: page =>
      useListUserOrganizations(
        {
          limit: limit + 1,
          cursor: page.afterItemId,
        },
        { keepPreviousData: true, enabled: enabled && canListOrganizations === false }
      ),
    initialSort: "name",
    enabled: enabled && canListOrganizations === false,
  });

  return canListOrganizations ? seqData : cursorData;
}
