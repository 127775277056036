import React from "react";
import { useNavigate, useParams } from "react-router";

import Tab from "@mui/material/Tab";

import AuthNContextView from "../authnContext/AuthNContextView";
import PageContainer from "../common/PageContainer";
import PageContent from "../common/PageContent";
import PageHeader from "../common/PageHeader";
import { useWorkspace } from "../common/useWorkspace";
import AuthenticationLogout from "../identities/identities_view_tabbed/AuthenticationLogout";
import AuthenticationSession from "../identities/identities_view_tabbed/AuthenticationSession";
import SSOConfigurationView from "../identities/identities_view_tabbed/SSOConfigurationView";

export default function AuthenticationSettings() {
  const navigate = useNavigate();
  const [workspace] = useWorkspace();

  const { tab } = useParams<{ tab: string }>();

  const onChangeTab = (tab: string) => {
    navigate(`/${workspace}/authentication/settings/${tab}`);
  };

  return (
    <PageContainer>
      <PageHeader
        title="User Session Settings"
        tabs={[
          <Tab id="session" label="Persistence" value="session" key="session" />,
          <Tab id="logout" label="Logout" value="logout" key="logout" />,
          <Tab id="attributes" label="Attributes" value="attributes" key="attributes" />,
        ]}
        currentTab={tab}
        onChangeTab={onChangeTab}
      />
      <PageContent fullWidth={tab === "authn-context-attributes" || tab === "attributes"}>
        {tab === "session" && <AuthenticationSession />}
        {tab === "logout" && <AuthenticationLogout />}
        {tab === "attributes" && <AuthNContextView />}
        {tab === "authn-context-attributes" && <AuthNContextView />}
        {tab === "user-session" && <SSOConfigurationView />}
      </PageContent>
    </PageContainer>
  );
}
