import { useState } from "react";

import set from "lodash/set";

import { handleErrorWithNotify } from "../../../../../../common/components/notifications/notificationService";

export const useFormExtraErrors = () => {
  const [extraErrors, setExtraErrors] = useState({});

  const setError = err => {
    if (
      err?.response?.data?.error === "some fields did not pass the validation" &&
      err?.response?.data?.status_code === 422
    ) {
      const details: { Causes: { InstanceLocation: string; Message: string }[] } = err?.response
        ?.data?.details ?? { Causes: [] };

      const paths = details.Causes.map(c => ({
        path: c.InstanceLocation.split("/").slice(1),
        message: c.Message,
      }));

      const extraErrors = paths.reduce((acc, p) => set(acc, p.path, { __errors: [p.message] }), {});

      setExtraErrors(extraErrors);
      return;
    }

    handleErrorWithNotify("Error occurred while trying to update user payload")(err);
    return err;
  };

  const resetExtraErrors = () => setExtraErrors({});

  return { extraErrors, setError, resetExtraErrors };
};
