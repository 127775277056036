import React, { ReactNode } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import Fab from "./Fab";

const useStyles = makeStyles()(theme => ({
  fab: {
    marginTop: 24,
  },
  title: {
    marginTop: 24,
    color: theme.palette.secondary.dark,
  },
}));

export interface EmptyStateProps {
  icon?: any;
  img?: any;
  customImg?: any;
  imgSize?: number;
  title: string | JSX.Element;
  description?: string | ReactNode;
  id?: string;
  buttonContent?: string | ReactNode;
  onSubmit?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  style?: any;
  buttonId?: string;
  actionButton?: ReactNode;
}

export default function EmptyState({
  icon: Icon,
  img,
  customImg,
  imgSize = 8,
  title,
  description,
  id = "empty-state",
  buttonContent = "",
  onSubmit,
  disabled,
  style = {},
  buttonId,
  actionButton,
}: EmptyStateProps) {
  const { classes } = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ maxWidth: 460, margin: "0 auto", textAlign: "center", ...style }}
      id={id}
    >
      {Icon && (
        <Grid item>
          <Icon style={{ fontSize: 96, opacity: 0.4 }} />
        </Grid>
      )}
      {img && <img src={img} alt="logo" style={{ width: 8 * imgSize, height: 8 * imgSize }} />}
      {customImg && <img src={customImg} alt="logo" />}
      <Grid item>
        <Typography id="empty-state-title" variant="h3" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item style={{ marginTop: 8 }}>
        <Typography id="empty-state-description" variant="body2" component="div">
          {description}
        </Typography>
      </Grid>
      {onSubmit && (
        <Fab
          id={buttonId || `empty-state-${id}-button`}
          onClick={onSubmit}
          disabled={disabled}
          className={classes.fab}
        >
          {buttonContent}
        </Fab>
      )}
      {actionButton && <div className={classes.fab}>{actionButton}</div>}
    </Grid>
  );
}
