import React from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: 16,
    marginBottom: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.custom.greys.fieldBackground,
  },
  button: {
    backgroundColor: "white",
  },
}));

interface Props {
  id: string;
  label: string;
  value: string;
  buttonLabel: string;
  onClick: () => void;
}

function FormInfoBoxWithButton({ id, label, value, buttonLabel, onClick }: Props) {
  const { classes } = useStyles();

  return (
    <Paper className={classes.paper}>
      <div>
        <Typography variant="textMD" id={`${id}-label`}>
          {label}
        </Typography>
        <Typography>{value}</Typography>
      </div>
      <Button id={`${id}-button`} variant="outlined" className={classes.button} onClick={onClick}>
        {buttonLabel}
      </Button>
    </Paper>
  );
}

export default FormInfoBoxWithButton;
