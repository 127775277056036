import React from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

interface AlertEnabledIdpDiscoveryProps {
  show: boolean;
  setEnabledDiscoveryAlert: (show: boolean) => void;
}

const AlertEnabledIdpDiscovery = ({
  show,
  setEnabledDiscoveryAlert,
}: AlertEnabledIdpDiscoveryProps) => {
  return show ? (
    <Alert
      severity="info"
      style={{ marginBottom: 32 }}
      onClose={() => {
        setEnabledDiscoveryAlert(false);
        window.history.replaceState({}, "");
      }}
    >
      <AlertTitle>IdP discovery is enabled</AlertTitle>
      Enter the email domain(s) associated with this IdP below
    </Alert>
  ) : null;
};

export default AlertEnabledIdpDiscovery;
