import React, { useCallback, useContext, useState } from "react";

import Button from "@mui/material/Button";
import { useQueryClient } from "@tanstack/react-query";

import { UserWithData } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import ConfirmationDialog from "../../../../common/components/ConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import { GlobalStoreContext } from "../../../GlobalStore/GlobalStore";
import adminIdentityUsersApi from "../../../services/adminIdentityUsersApi";
import { listUsersQueryKey } from "../../../services/adminIdentityUsersQuery";

interface Props {
  disabled: boolean;
  user: UserWithData;
  email: string;
  onDeleted: () => void;
}

export default function AdministratorUserDeleteButton({ disabled, user, email, onDeleted }: Props) {
  const [progress, setProgress] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const globalStoreContext = useContext(GlobalStoreContext);

  const queryClient = useQueryClient();

  const handleConfirm = useCallback(() => {
    setProgress(true);
    adminIdentityUsersApi
      .deleteUser({ ipID: user?.user_pool_id!, userID: user?.id! })
      .then(() => globalStoreContext.deletedUsers.addDeletedUserId(user.id))
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listUsersQueryKey(getTenantId(), user?.user_pool_id),
        })
      )
      .then(() => {
        onDeleted();
        notifySuccess("User successfully deleted");
      })
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to delete user"))
      .finally(() => {
        setProgress(false);
        setConfirmation(false);
      });
  }, [queryClient, globalStoreContext, user, onDeleted]);

  return (
    <>
      <Button
        variant="text"
        id="administrator-user-delete"
        color="error"
        onClick={() => setConfirmation(true)}
        disabled={disabled}
      >
        Delete User
      </Button>
      {confirmation && (
        <ConfirmationDialog
          title="Delete User"
          content={
            <div>
              You're about to remove{" "}
              <strong>
                {user?.payload?.given_name + " " + user?.payload?.family_name} ({email})
              </strong>
              .<br />
              This cannot be undone. Delete anyway?
            </div>
          }
          confirmText="Delete"
          progress={progress}
          onCancel={() => setConfirmation(false)}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
}
