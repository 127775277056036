import { useEffect, useRef } from "react";

const useEffectNotOnMount = (
  fn: Parameters<typeof useEffect>[0],
  deps: Parameters<typeof useEffect>[1]
) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
};

export default useEffectNotOnMount;
