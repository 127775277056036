import React from "react";

import { ServerResponse } from "@cloudentity/acp-admin";

import IdentitiesConfigurationAuth0 from "./IdentitiesConfigurationAuth0";
import IdentitiesConfigurationAzure from "./IdentitiesConfigurationAzure";
import IdentitiesConfigurationAzureB2C from "./IdentitiesConfigurationAzureB2C";
import IdentitiesConfigurationCognito from "./IdentitiesConfigurationCognito";
import IdentitiesConfigurationCustom from "./IdentitiesConfigurationCustom";
import IdentitiesConfigurationExternal from "./IdentitiesConfigurationExternal";
import IdentitiesConfigurationGithub from "./IdentitiesConfigurationGithub";
import IdentitiesConfigurationGithubEmbedded from "./IdentitiesConfigurationGithubEmbedded";
import IdentitiesConfigurationGoogle from "./IdentitiesConfigurationGoogle";
import IdentitiesConfigurationGoogleEmbedded from "./IdentitiesConfigurationGoogleEmbedded";
import IdentitiesConfigurationEnsure from "./IdentitiesConfigurationIntelliTrust";
import IdentitiesConfigurationOIDC from "./IdentitiesConfigurationOIDC";
import IdentitiesConfigurationOkta from "./IdentitiesConfigurationOkta";
import IdentitiesConfigurationSaml from "./IdentitiesConfigurationSaml";
import IdentitiesConfigurationSamlV2 from "./IdentitiesConfigurationSamlV2";
import IdentitiesConfigurationStatic from "./IdentitiesConfigurationStatic";
import { IdpUiModelType } from "./identities.utils";
import IdentitiesConfigurationIdentityPool from "./identityPools/IdentitiesConfigurationIdentityPool";
import IdentitiesConfigurationOrganization from "./organization/IdentitiesConfigurationOrganization";

interface Props {
  idp: IdpUiModelType;
  server: ServerResponse | undefined;
  errors: any;
  updateProgress: boolean;
  onUpdate: (idp: IdpUiModelType) => Promise<any>;
  onResetErrors: (err: any) => void;
  onDelete: (idp: IdpUiModelType) => void;
}

export default function IdentitiesDetailsConfiguration({
  idp,
  server,
  errors,
  updateProgress,
  onUpdate,
  onResetErrors,
  onDelete,
}: Props) {
  const aid = idp.authorization_server_id;

  return (
    <>
      {idp.method === "static" && (
        <IdentitiesConfigurationStatic
          idp={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          onDelete={onDelete}
          inEdit
        />
      )}
      {idp.method === "custom" && (
        <IdentitiesConfigurationCustom
          idp={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          onDelete={onDelete}
          inEdit
        />
      )}
      {idp.method === "external" && (
        <IdentitiesConfigurationExternal
          idp={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          onDelete={onDelete}
          inEdit
        />
      )}
      {idp.method === "oidc" && (
        <IdentitiesConfigurationOIDC
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          inEdit
          onDelete={onDelete}
        />
      )}
      {idp.method === "azure" && (
        <IdentitiesConfigurationAzure
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          inEdit
          onDelete={onDelete}
        />
      )}
      {idp.method === "azureb2c" && (
        <IdentitiesConfigurationAzureB2C
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          inEdit
          onDelete={onDelete}
        />
      )}
      {idp.method === "cognito" && (
        <IdentitiesConfigurationCognito
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          inEdit
          onDelete={onDelete}
        />
      )}
      {idp.method === "github" && (
        <IdentitiesConfigurationGithub
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          inEdit
          onDelete={onDelete}
        />
      )}
      {idp.method === "saml" && (
        <IdentitiesConfigurationSaml
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          errors={errors}
          onSubmit={onUpdate}
          onResetErrors={onResetErrors}
          inEdit
          onDelete={onDelete}
        />
      )}
      {idp.method === "saml_v2" && (
        <IdentitiesConfigurationSamlV2
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          errors={errors}
          onSubmit={onUpdate}
          onResetErrors={onResetErrors}
          inEdit
          onDelete={onDelete}
        />
      )}
      {idp.method === "intelli_trust" && (
        <IdentitiesConfigurationEnsure
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          inEdit
          onDelete={onDelete}
        />
      )}
      {idp.method === "okta" && (
        <IdentitiesConfigurationOkta
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          errors={errors}
          onSubmit={onUpdate}
          onResetErrors={onResetErrors}
          onDelete={onDelete}
          inEdit
        />
      )}
      {idp.method === "auth0" && (
        <IdentitiesConfigurationAuth0
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          onDelete={onDelete}
          inEdit
        />
      )}
      {idp.method === "google" && (
        <IdentitiesConfigurationGoogle
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          inEdit
          onDelete={onDelete}
        />
      )}
      {idp.method === "github_embedded" && (
        <IdentitiesConfigurationGithubEmbedded
          idp={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          onDelete={onDelete}
          inEdit
        />
      )}
      {idp.method === "google_embedded" && (
        <IdentitiesConfigurationGoogleEmbedded
          idp={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          onDelete={onDelete}
          inEdit
        />
      )}
      {idp.method === "identity_pool" && (
        <IdentitiesConfigurationIdentityPool
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          onDelete={onDelete}
          inEdit
          workspaceId={aid}
          workspaceIdForNewPool={aid}
        />
      )}
      {idp.method === "organization" && (
        <IdentitiesConfigurationOrganization
          provider={idp}
          server={server}
          updateProgress={updateProgress}
          onSubmit={onUpdate}
          onDelete={onDelete}
          inEdit
          aid={aid}
        />
      )}
    </>
  );
}
