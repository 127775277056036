import React, { useMemo, useState } from "react";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { AxiosResponse } from "axios";
import { Edit } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { SelfUserCredentials, SelfUserWithDataV2 } from "@cloudentity/acp-identityself";

import Dialog from "../../../../../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../../common/components/notifications/notificationService";
import identitySelfApi from "../../../../../../../../common/services/identitySelfApi";
import Form, { useForm } from "../../../../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../../../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../../../../../../../common/utils/forms/TextFieldRequired";
import InfoField from "../InfoField";

const useStyles = makeStyles()(theme => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  editButton: {
    marginLeft: 8,
    position: "relative",
    bottom: 1,
  },
  emptyLabel: {
    color: theme.custom.greys.textDisabled,
  },
}));

interface Props {
  credential: SelfUserCredentials;
  fetchUser: (withSetter?: boolean) => Promise<AxiosResponse<SelfUserWithDataV2, any> | null>;
}
export default function PasskeyName({ credential, fetchUser }: Props) {
  const { classes } = useStyles();

  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  const initialData = useMemo(
    () => ({
      name: credential.name || "",
    }),
    [credential]
  );

  const form = useForm({
    id: "set-passkey-name",
    initialValues: initialData,
    progress,
  });

  function handleClose() {
    setDialog(false);
  }

  function handleSetName(newData: typeof initialData) {
    setProgress(true);
    identitySelfApi
      .nameWebAuthnKey(credential.id ?? "", { name: newData.name })
      .then(handleClose)
      .then(() => notifySuccess("Passkey name set successfully"))
      .then(() => fetchUser(true))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to set passkey name"))
      .finally(() => setProgress(false));
  }

  return (
    <>
      <Grid item xs={12}>
        <InfoField
          id={`field-passkey-name-${credential.id}`}
          label="Name"
          value={
            <div className={classes.flexContainer}>
              <span>{credential.name || <span className={classes.emptyLabel}>Not set</span>}</span>
              <IconButton
                size="small"
                className={classes.editButton}
                onClick={() => setDialog(true)}
              >
                <Edit size={12} />
              </IconButton>
            </div>
          }
          noMarginTop
        />
      </Grid>

      {dialog && (
        <Dialog id="set-passkey-name-dialog" title="Set passkey name" onCancel={handleClose}>
          <Form form={form}>
            <TextFieldRequired name="name" label="Name" autoFocus />

            <FormFooter onCancel={handleClose} onSubmit={handleSetName} submitText="Update" />
          </Form>
        </Dialog>
      )}
    </>
  );
}
