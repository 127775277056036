import React, { ReactNode, useState } from "react";
import { useNavigate } from "react-router";

import { useQueryClient } from "@tanstack/react-query";

import { Script, ScriptExecutionPoint, ScriptExecutionPointTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import SetExtensionCard from "../../../common/components/extensions/SetExtensionCard";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import { useLocalStorage } from "../../../common/utils/hooks/useLocalStorage";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import adminScriptsApi from "../../services/adminScriptsApi";
import {
  getExecutionPointsQueryKey,
  useGetScripts,
  useListScriptExecutionPoints,
} from "../../services/adminScriptsQuery";

interface Props {
  label: string | ReactNode;
  stringLabel?: string;
  description?: ReactNode;
  caption?: ReactNode;
  tooltip?: ReactNode;
  targetFk: string;
  type: ScriptExecutionPointTypeEnum;
  serverId: string;
  hideButtonLink?: boolean;
}

export default function ConnectScriptExtension({
  label,
  stringLabel,
  description,
  caption,
  tooltip,
  targetFk,
  type,
  serverId,
  hideButtonLink,
}: Props) {
  const tenantId = getTenantId();
  const navigate = useNavigate();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(serverId);

  const [isExtensionInputFocused, setIsExtensionInputFocused] = useState(false);
  const [updateScriptsProgress, setUpdateScriptsProgress] = useState(false);
  const [, setNavMode] = useLocalStorage(`${serverId}_nav_mode`);

  const queryClient = useQueryClient();

  const getScriptsQuery = useGetScripts(tenantId, serverId);

  const getExecutionPointsQuery = useListScriptExecutionPoints(tenantId, serverId);

  const scriptID = (getExecutionPointsQuery.data?.execution_points ?? []).find(
    (wep: ScriptExecutionPoint) => wep.type === type && wep.target_fk === targetFk
  )?.script_id;

  const handleChangeScript = (value: Script | null) => {
    const executions = [
      ...(getExecutionPointsQuery.data?.execution_points ?? []).filter(
        (ep: ScriptExecutionPoint) => ep.type !== type && ep.target_fk === targetFk
      ),
      {
        tenant_id: tenantId,
        server_id: serverId,
        type,
        target_fk: targetFk,
        script_id: value ? value.id : undefined,
      },
    ];

    setUpdateScriptsProgress(true);
    adminScriptsApi
      .setScriptExecutionPoints({ wid: serverId, executions: { execution_points: executions } })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: getExecutionPointsQueryKey(tenantId, serverId) })
      )
      .then(() => notifySuccess(`${stringLabel || label} updated successfully`))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to set script execution points"))
      .finally(() => {
        setUpdateScriptsProgress(false);
        setIsExtensionInputFocused(false);
      });
  };

  const progress =
    getScriptsQuery.isLoading || getExecutionPointsQuery.isLoading || updateScriptsProgress;

  return (
    <SetExtensionCard
      id="connect-extension-container"
      inputId="script-input"
      label={label}
      tooltip={tooltip}
      description={description}
      options={getScriptsQuery.data?.scripts || []}
      value={(getScriptsQuery.data?.scripts || []).find(s => s.id === scriptID) || null}
      disabled={progress || !checkWorkspacePermissionsQuery.data?.manage_scripts}
      isInputFocused={isExtensionInputFocused}
      setIsInputFocused={setIsExtensionInputFocused}
      progress={progress}
      handleChange={handleChangeScript}
      caption={caption}
      hideButtonLink={hideButtonLink}
      onClick={() => {
        setNavMode("advanced");
        navigate(`/${serverId}/extensions`);
      }}
    />
  );
}
