import React, { useState } from "react";

import { TOP_BAR_HEIGHT } from "../../../../common/components/nav/utils";
import EnhancedTable from "../EnhancedTable";
import { PAGE_HEADER_HEIGHT } from "../PageHeader";
import Row from "./Row";
import { RowType } from "./types";

interface Props {
  id: string;
  data: RowType[];
  headCells: any;
  setMainMenu: any;
  setInnerRowMenu?: any;
  rowProgress: string;
  deleteRowProgress: string;
  innerTableHeader: string;
  onNameClick?: (data: RowType) => void;
}

function EnhancedTableWithCollapsedRow({
  id,
  data,
  headCells,
  setMainMenu,
  setInnerRowMenu,
  rowProgress,
  deleteRowProgress,
  innerTableHeader,
  onNameClick,
}: Props) {
  const [openedRows, setOpenedRows] = useState<string[]>([]);

  return (
    <EnhancedTable
      id={id}
      data={data}
      headCells={headCells}
      createRow={(row: RowType) => {
        const isOpen = openedRows.includes(row.id ?? "");
        return (
          <Row
            key={row.id}
            data={row}
            isOpen={isOpen}
            progress={rowProgress === row.id || deleteRowProgress === row.id}
            innerTableHeader={innerTableHeader}
            openedRows={openedRows}
            setOpenedRows={setOpenedRows}
            setMainMenu={setMainMenu}
            setInnerRowMenu={
              setInnerRowMenu
                ? ({ anchorEl, row: innerRow }) =>
                    setInnerRowMenu({
                      anchorEl,
                      row: innerRow,
                      parentRow: row,
                    })
                : undefined
            }
            onNameClick={onNameClick}
            showInnerRowsCount={headCells.length === 3}
          />
        );
      }}
      scrollToTopOnChangeRowsPerPage={false}
      stickyHeader
      paperStyles={{
        backgroundColor: "white",
      }}
      tableContainerStyle={{
        height: "calc(100% - 52px)",
        maxHeight: `calc(100vh - ${TOP_BAR_HEIGHT}px - ${PAGE_HEADER_HEIGHT}px - 64px - 57px)`,
      }}
      emptyStateText="No data"
      emptyTextColSpan={4}
    />
  );
}

export default EnhancedTableWithCollapsedRow;
