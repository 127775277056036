import React, { useMemo } from "react";

import Typography from "@mui/material/Typography";

import Dialog from "../../../../common/components/Dialog";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextFieldReadOnly from "../../../../common/utils/forms/TextFieldReadOnly";

interface Props {
  dataKey: string;
  onClose: () => void;
}

function PermissionTokenCopyKeyDialog({ dataKey, onClose }: Props) {
  const data = useMemo(() => ({ dataKey }), [dataKey]);
  const form = useForm({ id: "permission-token-copy-key", initialValues: data });

  return (
    <Dialog id="permission-token-copy-key-dialog" title="Permission Token Key">
      <Typography style={{ marginBottom: 32 }}>
        Here is the generated key. It won't be available later so please store it now in some secure
        place so you can use it later.
      </Typography>
      <Form form={form}>
        <TextFieldReadOnly name="dataKey" label="Key" withCopy />

        <FormFooter onSubmit={onClose} submitText="Continue" />
      </Form>
    </Dialog>
  );
}

export default PermissionTokenCopyKeyDialog;
