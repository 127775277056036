import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import { X } from "react-feather";

import PageContainer from "../common/PageContainer";
import PageHeader, { PAGE_HEADER_HEIGHT } from "../common/PageHeader";
import useWorkspaceDirectoryData from "../welcome/useWorkspaceDirectoryData";
import WorkspaceDirectoryCreateContent from "./WorkspaceDirectoryCreateContent";
import WorkspaceDirectoryCreateDeveloperPortalSidePanel from "./WorkspaceDirectoryCreateDeveloperPortalSidePanel";

const developerPortalCreateSteps = ["createDeveloperPortal", "connectDeveloperPool"];

export default function WorkspaceDirectoryCreateDeveloperPortal({ workspace, onFinish, onCancel }) {
  const [currentStep, setCurrentStep] = useState<string>("createDeveloperPortal");
  const [stepsData, setStepsData] = useWorkspaceDirectoryData();

  const type = stepsData.defineWorkspaceType.selected;

  return (
    <PageContainer>
      <PageHeader title="Create developer portal" containerStyle={{ paddingLeft: 32, margin: 0 }} />
      <IconButton
        onClick={currentStep === "createDeveloperPortal" ? onCancel : onFinish}
        style={{ position: "absolute", top: 32, right: 32 }}
      >
        <X />
      </IconButton>
      <div
        style={{
          display: "flex",
          height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px)`,
          overflow: "hidden",
        }}
      >
        <WorkspaceDirectoryCreateDeveloperPortalSidePanel
          stepName={currentStep}
          stepIndex={developerPortalCreateSteps.indexOf(currentStep)}
        />
        <WorkspaceDirectoryCreateContent
          step={currentStep}
          stepsData={stepsData}
          workspace={workspace}
          hideSkipCreateDeveloperPortal
          onChangeStepsData={data => setStepsData(data)}
          onNext={step => () => setCurrentStep(step)}
          onBack={step => () => setCurrentStep(step)}
          onFinish={onFinish}
          type={type}
        />
      </div>
    </PageContainer>
  );
}
