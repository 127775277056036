import { useQuery } from "@tanstack/react-query";

import {
  OrganizationResponse,
  OrganizationsApiListOrganizationsRequest,
  OrganizationsApiListUserOrganizationsRequest,
  OrganizationsResponse,
  UserOrganizationsResponse,
} from "@cloudentity/acp-admin";

import adminOrganizationsApi from "./adminOrganizationsApi";

const GET_ORGANIZATION_QUERY = "GET_ORGANIZATION_QUERY";
const LIST_ORGANIZATIONS_QUERY = "LIST_ORGANIZATIONS_QUERY";
const LIST_USER_ORGANIZATIONS_QUERY = "LIST_USER_ORGANIZATIONS_QUERY";

export const getOrganizationQueryKey = wid => [GET_ORGANIZATION_QUERY, wid];

export const listOrganizationsQueryKey = (parentId?: string, limit?: number) => [
  LIST_ORGANIZATIONS_QUERY,
  ...(parentId !== undefined || limit !== undefined ? [parentId] : []),
  ...(limit !== undefined ? [limit] : []),
];

export const listUserOrganizationsQueryKey = (limit?: number) =>
  limit !== undefined ? [LIST_USER_ORGANIZATIONS_QUERY, limit] : [LIST_USER_ORGANIZATIONS_QUERY];

export const useGetOrganization = (wid, options?) =>
  useQuery<OrganizationResponse>({
    queryKey: getOrganizationQueryKey(wid),
    queryFn: async () => {
      const data = await adminOrganizationsApi.getOrganization({ wid });
      return data.data;
    },
    ...options,
  });

export const useListOrganizations = (
  { parentId, limit, ...params }: OrganizationsApiListOrganizationsRequest,
  options?: any
) =>
  useQuery<OrganizationsResponse>({
    queryKey: [...listOrganizationsQueryKey(parentId, limit), params],
    queryFn: async () => {
      const data = await adminOrganizationsApi.listOrganizations({ parentId, limit, ...params });
      return data.data;
    },
    ...options,
  });

export const useListUserOrganizations = (
  { limit, ...params }: OrganizationsApiListUserOrganizationsRequest,
  options?: any
) =>
  useQuery<UserOrganizationsResponse>({
    queryKey: [...listUserOrganizationsQueryKey(limit), params],
    queryFn: async () => {
      const data = await adminOrganizationsApi.listUserOrganizations({ limit, ...params });
      return data.data;
    },
    ...options,
  });
