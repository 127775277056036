import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import add from "date-fns/add";
import min from "date-fns/min";
import { makeStyles } from "tss-react/mui";

import { UserCredential, UserCredentialStateEnum } from "@cloudentity/acp-identity";
import { SelfUserCredentials } from "@cloudentity/acp-identityself";

import { convertDurationToDays } from "../../../../utils";
import { getDateTime } from "../utils";

function emptyDate(passwordExpiresAt: string | undefined) {
  return passwordExpiresAt === "0001-01-01T00:00:00Z" || !passwordExpiresAt;
}

export function countExpiresDate(
  passwordExpiresAt: string | undefined,
  passwordUpdatedAt: string | undefined,
  expiresAfter: string | undefined
) {
  try {
    const expiresAfterDays = convertDurationToDays(expiresAfter);

    if (expiresAfterDays === 0) {
      if (emptyDate(passwordExpiresAt)) {
        return "Never";
      }
      return getDateTime(passwordExpiresAt);
    } else if (expiresAfterDays > 0 && passwordUpdatedAt) {
      const updatedDate = new Date(passwordUpdatedAt);
      const expiresDate = add(updatedDate, { days: expiresAfterDays });

      if (emptyDate(passwordExpiresAt)) {
        return getDateTime(expiresDate.toString());
      } else {
        const passwordExpiresAtDate = new Date(passwordExpiresAt!);
        return getDateTime(min([passwordExpiresAtDate, expiresDate]).toString());
      }
    }
    return "N/A";
  } catch (err) {
    return "N/A";
  }
}

const useStyles = makeStyles()(theme => ({
  empty: {
    color: "#9EA1B7",
    textTransform: "uppercase",
    marginBottom: 8,
  },
  red: {
    color: theme.palette.error.main,
  },
}));

export default function PasswordStatus({
  password,
}: {
  password: UserCredential | undefined | SelfUserCredentials;
}) {
  const { cx, classes } = useStyles();

  if (!password) {
    return (
      <Grid item xs={12}>
        <Typography variant="textMD" component="div" className={classes.empty}>
          Password not set
        </Typography>
      </Grid>
    );
  }

  if (
    password &&
    (password as UserCredential)?.payload &&
    !((password as UserCredential).payload as any).hashed_password?.config?.method
  ) {
    return (
      <Grid item xs={12}>
        <Typography variant="textMD" component="div" className={cx(classes.empty, classes.red)}>
          Password not set
          {password.state === UserCredentialStateEnum.MustBeReset &&
            " & Forced password reset pending"}
        </Typography>
      </Grid>
    );
  }

  if (password.state === UserCredentialStateEnum.MustBeReset) {
    return (
      <Grid item xs={12}>
        <Typography variant="textMD" component="div" className={cx(classes.empty, classes.red)}>
          Forced password reset pending
        </Typography>
      </Grid>
    );
  }

  if (password.state === UserCredentialStateEnum.MustBeChanged) {
    return (
      <Grid item xs={12}>
        <Typography variant="textMD" component="div" className={cx(classes.empty, classes.red)}>
          Forced password change pending
        </Typography>
      </Grid>
    );
  }

  return null;
}
