import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Script, ScriptDebug } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import { parseDuration } from "../../../common/utils/forms/validation";
import { useListScriptDebugs } from "../../services/adminScriptsQuery";
import { useWorkspace } from "../common/useWorkspace";
import { BottomSectionTabs, ScriptTab } from "./utils";

interface DebuggingScript {
  scriptId: string;
  endTimestamp: number;
  millisecondsUntilEnd: number;
  duration: DurationEnum;
}

export enum DurationEnum {
  "off" = "off",
  "1m0s" = "1m0s",
  "5m0s" = "5m0s",
  "10m0s" = "10m0s",
  "15m0s" = "15m0s",
  "30m0s" = "30m0s",
  "1h0m0s" = "1h0m0s",
}

type ExtensionsStoreType = {
  tabs: ScriptTab[];
  currentScriptTab: ScriptTab | undefined;
  debuggingScripts: DebuggingScript[];
  bottomSectionTabName: BottomSectionTabs;
  debuggingScript: DebuggingScript | undefined;
  updateTab: (scriptTab: ScriptTab, newScript: Script) => void;
  openTab: (script: Script) => void;
  closeTab: (tabId: string) => void;
  closeAllTabs: () => void;
  openBottomSectionTab: (tab: BottomSectionTabs) => void;
};

const ExtensionsContext = createContext<ExtensionsStoreType | undefined>(undefined);

const useExtensions = () => {
  const context = useContext(ExtensionsContext);
  if (context === undefined) {
    throw new Error("useExtensions must be used within a ExtensionsProvider");
  }

  return context;
};

const ExtensionsProvider = ({ children }: { children: ReactNode }) => {
  const [store, setStore] = useState<{
    tabs: ScriptTab[];
    currentScriptId: string | undefined;
    debuggingScripts: DebuggingScript[];
    bottomSectionTabName: BottomSectionTabs;
  }>({
    tabs: [],
    currentScriptId: undefined,
    debuggingScripts: [],
    bottomSectionTabName: "input",
  });

  const [workspace] = useWorkspace();
  const getScriptDebugsQuery = useListScriptDebugs(getTenantId(), workspace);

  const timeout = useRef<NodeJS.Timeout>();
  const currentScriptTab = store.tabs.find(tab => tab.script.id === store.currentScriptId);
  const debuggingScript = store.debuggingScripts.find(ds => ds.scriptId === store.currentScriptId);

  const setDebuggingScripts = (activeScripts: ScriptDebug[]) => {
    const debuggingScripts = activeScripts
      .map(({ script_id, started_at, duration }) => {
        if (!started_at || !duration) return null;

        const endTimestamp = new Date(started_at).getTime() + parseDuration(duration) * 1000;

        return {
          scriptId: script_id,
          endTimestamp: new Date(started_at).getTime() + parseDuration(duration) * 1000,
          millisecondsUntilEnd: endTimestamp - Date.now() - 1,
          duration,
        };
      })
      .filter((v): v is DebuggingScript => !!v);

    setStore(store => ({
      ...store,
      debuggingScripts,
    }));
  };

  const openBottomSectionTab = (tab: BottomSectionTabs) =>
    setStore(store => ({ ...store, bottomSectionTabName: tab }));

  const setCurrentScriptId = (currentScriptId: string | undefined) =>
    setStore(store => ({ ...store, currentScriptId }));

  const updateTab = (scriptTab: ScriptTab, newScript: Script) => {
    setStore(store => ({
      ...store,
      tabs: store.tabs.map(tab => {
        return tab.script.id === scriptTab.script.id
          ? {
              ...scriptTab,
              script: newScript,
            }
          : tab;
      }),
      currentScriptId: newScript.id,
    }));
  };

  const openTab = (script: Script) => {
    const scriptTab = {
      script,
    };

    if (!store.tabs.some(tab => tab.script.id === script.id)) {
      setStore(store => ({ ...store, tabs: [...store.tabs, scriptTab] }));
    }
    setCurrentScriptId(script.id);
  };

  const closeTab = (tabId: string) => {
    const filteredTabs = store.tabs.filter(tab => tab.script.id !== tabId);
    setStore(store => ({ ...store, tabs: filteredTabs }));

    if (currentScriptTab?.script.id === tabId) {
      setCurrentScriptId(
        filteredTabs.length ? filteredTabs[filteredTabs.length - 1].script.id : undefined
      );
    }
  };

  const closeAllTabs = useCallback(() => {
    setStore(store => ({ ...store, tabs: [], currentScriptId: undefined }));
  }, []);

  useEffect(() => {
    function updateCountDownData() {
      if (!store.debuggingScripts.length) {
        return;
      }

      const newDebuggingScripts = store.debuggingScripts.flatMap(({ endTimestamp, ...rest }) => {
        const millisecondsUntilEnd = endTimestamp - Date.now();
        if (millisecondsUntilEnd > 0) {
          return [{ ...rest, endTimestamp, millisecondsUntilEnd }];
        } else {
          return [];
        }
      });

      setStore(store => ({ ...store, debuggingScripts: newDebuggingScripts }));
    }

    timeout.current = setTimeout(updateCountDownData, 1000);
    return () => clearTimeout(timeout.current);
  }, [store.debuggingScripts]);

  useEffect(() => {
    if (getScriptDebugsQuery.data) {
      setDebuggingScripts(getScriptDebugsQuery.data.script_debugs ?? []);
    }
  }, [getScriptDebugsQuery.data]);

  const value = {
    tabs: store.tabs,
    debuggingScripts: store.debuggingScripts,
    bottomSectionTabName: store.bottomSectionTabName,
    currentScriptTab,
    debuggingScript,
    updateTab,
    openTab,
    closeTab,
    closeAllTabs,
    openBottomSectionTab,
  };

  return <ExtensionsContext.Provider value={value}>{children}</ExtensionsContext.Provider>;
};

export { ExtensionsProvider, useExtensions };
