import React, { ReactElement } from "react";

import Typography from "@mui/material/Typography";

import { TenantPermissionsResponse } from "@cloudentity/acp-admin";

import { useCheckTenantPermissions } from "../services/adminTenantsQuery";

interface Props {
  hasPermission: keyof TenantPermissionsResponse;
  children: ReactElement;
}

export default function CheckTenantPermissions({ hasPermission, children }: Props) {
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  if (!!checkTenantPermissionsQuery.data?.[hasPermission]) {
    return children;
  }

  return <Typography>You don't have permission to access this view</Typography>;
}
