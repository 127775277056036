import {
  RecentActivitiesApi,
  RecentActivitiesApiGetRecentActivitiesRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const activitiesApi: () => RecentActivitiesApi = adminApiClazz(RecentActivitiesApi);

const adminStatsApi = {
  getRecentActivities: (req: RecentActivitiesApiGetRecentActivitiesRequest) =>
    activitiesApi().getRecentActivities(req),
};

export default adminStatsApi;
