import React, { useContext, useEffect, useMemo, useState } from "react";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { useQueryClient } from "@tanstack/react-query";
import { JSONSchema7 } from "json-schema";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import omitBy from "lodash/omitBy";
import { makeStyles } from "tss-react/mui";

import {
  GrantIdentityPoolRoleRequestRoleEnum,
  GrantTenantRoleRequestRoleEnum,
  GrantTenantRoleRequestTypeEnum,
  GrantWorkspaceRoleRequestRoleEnum,
  RevokeTenantRoleRequestRoleEnum,
  RevokeTenantRoleRequestTypeEnum,
  WorkspaceRoleSubject,
} from "@cloudentity/acp-admin";
import { PoolResponse, Schema, UserWithData } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../../../common/api/paths";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../../../common/utils/forms/FormFooter";
import { trimStringValues } from "../../../../../../../common/utils/object.utils";
import { GlobalStoreContext } from "../../../../../../GlobalStore/GlobalStore";
import identityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import adminIdentityUsersApi from "../../../../../../services/adminIdentityUsersApi";
import {
  getUserQueryKey,
  listUsersQueryKey,
} from "../../../../../../services/adminIdentityUsersQuery";
import adminRolesApi from "../../../../../../services/adminRolesApi";
import { listTenantRoles, listUserRoles } from "../../../../../../services/adminRolesQuery";
import { useWorkspace } from "../../../../../common/useWorkspace";
import TenantRoleSelectField from "../../../../administrator/TenantRoleSelectField";
import WorkspaceRoleSelectField from "../../../../administrator/WorkspaceRoleSelectField";
import {
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../../../schemas/schemas.utils";
import { useFormExtraErrors } from "../identityPoolUsers.utils";
import { grantAndRevokeWorkspaceRoles } from "../list/utils";
import IdentityPoolUserSchemasTabDeleteButton from "./IdentityPoolUserSchemasTabDeleteButton";
import IdentityPoolUserSchemasTabForm from "./IdentityPoolUserSchemasTabForm";
import IdentityPoolUserSchemasTabSelect from "./IdentityPoolUserSchemasTabSelect";

const useStyles = makeStyles()(theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 24px",
  },
  emailField: {
    padding: "24px 24px 0",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  empty: {
    borderTop: "none",
    borderRadius: "0 0 4px 4px",
    padding: "36px 24px 24px 24px",
    color: theme.custom.greys.textDisabled,
  },
}));

interface Props {
  schemaId: "payload_schema_id" | "metadata_schema_id" | "business_metadata_schema_id";
  dataKey: "metadata" | "business_metadata" | "payload";
  pool: PoolResponse;
  user: UserWithData;
  schemas: Schema[];
  noManagePermission: boolean;
  withRoles?:
    | {
        isTenantRoleVisible: boolean;
        isWorkspaceRolesVisible: boolean;
        workspaceSubjects: WorkspaceRoleSubject[];
        tenantRole: GrantTenantRoleRequestRoleEnum | "";
        workspaceRoles: string[];
        userRolesBySubjects: {
          tenant: GrantTenantRoleRequestRoleEnum[];
          workspace: GrantWorkspaceRoleRequestRoleEnum[];
          identityPool: GrantIdentityPoolRoleRequestRoleEnum[];
        };
      }
    | undefined;
  isOrganization: boolean;
}

const IdentityPoolUserSchemasTab = ({
  schemaId,
  dataKey,
  pool,
  user,
  schemas,
  noManagePermission,
  withRoles,
  isOrganization,
}: Props) => {
  const { classes } = useStyles();
  const tenantId = getTenantId();
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [updateProgress, setUpdateProgress] = useState(false);
  const [workspace] = useWorkspace();
  const globalStoreContext = useContext(GlobalStoreContext);

  const { extraErrors, setError, resetExtraErrors } = useFormExtraErrors();

  const queryClient = useQueryClient();

  const poolId = pool.id ?? "";
  const userId = user.id ?? "";

  const data = useMemo(
    () => ({
      [schemaId]: user[schemaId],
      ...(withRoles?.isTenantRoleVisible && { role: withRoles?.tenantRole }),
      ...(withRoles?.isWorkspaceRolesVisible && { roles: withRoles?.workspaceRoles }),
    }),
    // don't change on user update to prevent unsaved changes overwrite on user's status/identifiers/addresses update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      schemaId,
      withRoles?.isTenantRoleVisible,
      withRoles?.isWorkspaceRolesVisible,
      withRoles?.tenantRole,
      withRoles?.workspaceRoles,
    ]
  );

  const form = useForm({
    id: "identity-pool-edit-user",
    initialValues: data,
    progress: updateProgress,
    noManagePermission,
  });

  const metadataSchemaId = form.watch(schemaId as any);
  const metadataSchema = useMemo(
    () => schemas.find(s => s.id === metadataSchemaId) || null,
    [schemas, metadataSchemaId]
  );

  const [metadata, setMetadata] = useState({});

  const metadataSchemaWithMappedTitles = mapFieldNameToTitle(metadataSchema?.schema || {});

  const validateMetadata = validator.validateFormData(
    metadata,
    (metadataSchemaWithMappedTitles as JSONSchema7) || {}
  );

  const metadataUISchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(metadataSchemaWithMappedTitles || {}),
  };

  const handleSave = data => {
    setUpdateProgress(true);
    resetExtraErrors();

    identityUsersApi
      .updateUser({
        ipID: poolId,
        userID: userId,
        updateUser: {
          ...user,
          ...data,
          [dataKey]: trimStringValues(metadata),
        },
      })
      .catch(setError)
      .then(async res => {
        if (user && withRoles) {
          await grantAndRevokeWorkspaceRoles(
            queryClient,
            workspace,
            user,
            withRoles.userRolesBySubjects,
            data.roles
          );
        }

        if (data.role && withRoles) {
          const common = {
            identity_pool_id: poolId,
            identity_pool_user_id: userId,
            tenant_id: tenantId,
          };

          if (data.role && !withRoles.tenantRole) {
            await adminRolesApi.grantTenantRole({
              request: {
                role: data.role as GrantTenantRoleRequestRoleEnum,
                type: GrantTenantRoleRequestTypeEnum.IdentityPoolUser,
                ...common,
              },
            });
          }

          if (data.role && withRoles.tenantRole) {
            await adminRolesApi.revokeTenantRole({
              request: {
                role: withRoles.tenantRole as unknown as RevokeTenantRoleRequestRoleEnum,
                type: RevokeTenantRoleRequestTypeEnum.IdentityPoolUser,
                ...common,
              },
            });

            await adminRolesApi.grantTenantRole({
              request: {
                role: data.role as GrantTenantRoleRequestRoleEnum,
                type: GrantTenantRoleRequestTypeEnum.IdentityPoolUser,
                ...common,
              },
            });
          }
        }
        return res;
      })
      .then(res => queryClient.setQueryData(getUserQueryKey(tenantId, userId), res.data))
      .then(() => queryClient.invalidateQueries({ queryKey: listUsersQueryKey(tenantId, poolId) }))
      .then(() => queryClient.invalidateQueries({ queryKey: listTenantRoles() }))
      .then(() => queryClient.invalidateQueries({ queryKey: listUserRoles(poolId, userId) }))
      .then(() => notifySuccess("User data successfully updated"))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to update user"))
      .finally(() => setUpdateProgress(false));
  };

  useEffect(() => {
    setMetadata(user[dataKey] || {});
    // don't change on user update to prevent unsaved changes overwrite on user's status/identifiers/addresses update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey]);

  const role = form.watch("role");
  const roles = form.watch("roles");

  const selectChanged = metadataSchemaId !== user[schemaId];
  const omitValuesFn = v => isEmpty(v) && !isNumber(v) && !isBoolean(v);
  const formChanged = !isEqual(
    omitBy(user[dataKey] || {}, omitValuesFn),
    omitBy(metadata, omitValuesFn)
  );

  const workspaceRolesChanged =
    (withRoles?.isWorkspaceRolesVisible && !isEqual(roles, withRoles?.workspaceRoles)) || false;
  const tenantRoleChanged =
    (withRoles?.isTenantRoleVisible && !isEqual(role, withRoles?.tenantRole)) || false;

  const changed = selectChanged || formChanged || workspaceRolesChanged || tenantRoleChanged;

  return (
    <Form form={form} noFormTag>
      <div className={classes.container}>
        {isEmpty(metadataSchemaWithMappedTitles) && (
          <Paper className={classes.empty}>
            <Typography>No attributes defined in schema</Typography>
          </Paper>
        )}
        {!isEmpty(metadataSchemaWithMappedTitles) && (
          <IdentityPoolUserSchemasTabForm
            metadata={metadata}
            setMetadata={setMetadata}
            schema={metadataSchemaWithMappedTitles}
            metadataUISchema={metadataUISchema}
            submitAttempt={submitAttempt}
            updateProgress={updateProgress}
            extraErrors={extraErrors}
            resetExtraErrors={resetExtraErrors}
            disabled={noManagePermission}
          />
        )}

        {withRoles &&
          (withRoles.isTenantRoleVisible ||
            (withRoles.isWorkspaceRolesVisible && (isOrganization || workspace === "admin"))) && (
            <Paper style={{ padding: "24px 24px 0", margin: "32px 0 0" }}>
              {withRoles.isTenantRoleVisible && (
                <TenantRoleSelectField user={user} identityPoolId={poolId} />
              )}
              {withRoles.isWorkspaceRolesVisible && (
                <WorkspaceRoleSelectField
                  user={user}
                  wid={workspace}
                  workspaceSubjects={withRoles.workspaceSubjects}
                  optional
                />
              )}
            </Paper>
          )}

        <IdentityPoolUserSchemasTabSelect
          schemas={schemas}
          alert={metadataSchemaId !== pool[schemaId]}
          poolId={poolId}
          dataKey={dataKey}
          disabled={noManagePermission}
          onChange={() => setMetadata(user[dataKey] || {})}
        />

        <div className={classes.footer}>
          {!noManagePermission && (
            <IdentityPoolUserSchemasTabDeleteButton
              poolId={poolId}
              user={user}
              identifiers={user.identifiers ?? []}
              onDeleteUser={(id, poolId) =>
                adminIdentityUsersApi
                  .deleteUser({ ipID: poolId, userID: id })
                  .then(() => globalStoreContext.deletedUsers.addDeletedUserId(id))
                  .then(() =>
                    queryClient.invalidateQueries({
                      queryKey: listUsersQueryKey(getTenantId(), poolId),
                    })
                  )
              }
            />
          )}

          <FormFooter
            disabled={!changed || (submitAttempt && validateMetadata.errors.length > 0)}
            onSubmit={data => {
              setSubmitAttempt(true);
              if (validateMetadata.errors.length > 0) {
                return;
              }
              handleSave(data);
            }}
          />
        </div>
      </div>
    </Form>
  );
};

export default IdentityPoolUserSchemasTab;
