import React from "react";
import { useWatch } from "react-hook-form";

import { PoolResponse } from "@cloudentity/acp-identity";

import CheckboxField from "../../../../../common/utils/forms/CheckboxField";
import { useFormContext } from "../../../../../common/utils/forms/Form";
import SelectField from "../../../../../common/utils/forms/SelectField";
import { useIdentityPoolThemeSelector } from "./useIdentityPoolThemeSelector";

interface Props {
  pool?: PoolResponse;
  withEmptyValue?: boolean;
  showRememberCheckbox?: boolean;
}

export default function IdentityPoolThemeSelector({
  pool,
  withEmptyValue,
  showRememberCheckbox,
}: Props) {
  const { options } = useIdentityPoolThemeSelector({ pool, withEmptyValue });
  const { form } = useFormContext();
  const rememberThemeServerId = useWatch({
    name: "rememberThemeWorkspaceId",
    control: form.control,
  });

  return (
    <>
      <SelectField
        name="themeWorkspaceId"
        label="Workspace"
        options={options}
        labelProps={{
          tooltip:
            "This identity pool is connected to more than one workspace that determines graphical theme used, landing page etc. Select in context of which workspace this message must be sent.",
        }}
        rules={withEmptyValue ? {} : { required: "Workspace is required" }}
        optional={false}
      />

      {showRememberCheckbox && (
        <CheckboxField
          name="rememberThemeWorkspaceId"
          label="Remember workspace selection for this pool"
          helperText={
            rememberThemeServerId ? "The preference can be changed later in the pool settings." : ""
          }
          style={{ marginTop: -16 }}
        />
      )}
    </>
  );
}
