import React from "react";

import { PoolResponse } from "@cloudentity/acp-identity";

import Dialog from "../../../../../common/components/Dialog";
import Form, { useForm } from "../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../common/utils/forms/FormFooter";
import IdentityPoolThemeSelector from "./IdentityPoolThemeSelector";
import { setPoolWorkspaceId } from "./useIdentityPoolThemeSelector";

const data = {
  themeWorkspaceId: "",
  rememberThemeWorkspaceId: false,
};

interface Props {
  pool: PoolResponse;
  onCancel: () => void;
  onSubmit: (serverId: string) => void;
}

export default function IdentityPoolThemeSelectorDialog({ pool, onCancel, onSubmit }: Props) {
  const form = useForm({
    id: "identity-pool-theme-selector-dialog",
    initialValues: data,
  });

  return (
    <Dialog id="identity-pool-theme-selector-dialog" title="Select workspace to send message">
      <Form form={form}>
        <IdentityPoolThemeSelector pool={pool} showRememberCheckbox />
        <FormFooter
          onCancel={onCancel}
          submitText="Continue"
          onSubmit={data => {
            if (data.rememberThemeWorkspaceId) {
              setPoolWorkspaceId(pool?.id || "", data.themeWorkspaceId);
              return onSubmit(data.themeWorkspaceId);
            }
            return onSubmit(data.themeWorkspaceId);
          }}
        />
      </Form>
    </Dialog>
  );
}
