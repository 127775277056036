import React, { useState } from "react";

import Chip from "@mui/material/Chip";
import { makeStyles } from "tss-react/mui";

import IconTooltip from "../../../common/components/IconTooltip";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import { useFormContext } from "../../../common/utils/forms/Form";
import { validators } from "../../../common/utils/forms/validation";

const useStyles = makeStyles()(theme => ({
  addLabel: {
    marginRight: 8,
    color: theme.palette.primary.main,
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
}));

interface Props {
  name?: string;
  disabled?: boolean;
}

export default function Domains({ name, disabled }: Props) {
  const { classes } = useStyles();

  const [currentDomainInEdit, setCurrentCurrentDomainInEditInEdit] = useState("");
  const { form } = useFormContext();
  const domains = form.watch(name || "domains");

  return (
    <AutocompleteField
      name={name || "domains"}
      label={
        <div className={classes.label}>
          Email domains
          <IconTooltip
            title={
              <>
                When email with this domain is entered at sign-in, user will be displayed
                authentication options set for this organization.
              </>
            }
          />
        </div>
      }
      defaultValue={[]}
      options={currentDomainInEdit ? [{ value: currentDomainInEdit, raw: true }] : []}
      getOptionLabel={option => (typeof option === "object" ? option.value : option)}
      rules={{
        validate: {
          validDomains: validators.validDomains({ label: "Domains" }),
          noDuplicatedElements: validators.noDuplicatedElements({
            label: "Domains",
          }),
        },
      }}
      renderOption={(props, option, params) => (
        <li {...props}>
          {params && option.raw && <span className={classes.addLabel}>Add</span>}
          {typeof option === "object" ? option.value : option}
        </li>
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          const { key, ...rest } = getTagProps({ index });
          return (
            <Chip {...rest} key={key} label={typeof option === "object" ? option.value : option} />
          );
        });
      }}
      freeSolo
      multiple
      optional={false}
      autoSelect
      onInputChange={(_, text) => setCurrentCurrentDomainInEditInEdit(text)}
      onBlur={() => {
        if (currentDomainInEdit) {
          form.setValue(
            name || "domains",
            [...domains, { value: currentDomainInEdit, raw: true }],
            { shouldValidate: true, shouldDirty: true }
          );
        }
      }}
      disabled={disabled}
    />
  );
}
