import React from "react";

import ConfirmationDialog from "../../../../../../../common/components/ConfirmationDialog";

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  progress: boolean;
  identifier: string | null;
}

const IdentityPoolUserDeleteDialog = ({ onConfirm, onCancel, progress, identifier }: Props) => {
  return (
    <ConfirmationDialog
      title="Delete User"
      content={
        identifier ? (
          <>
            You're about to delete user with <b>{identifier}</b> identifier. This cannot be undone.
            Delete anyway?
          </>
        ) : (
          <>You're about to delete the user. This cannot be undone. Delete anyway?</>
        )
      }
      confirmText="Delete"
      onCancel={onCancel}
      onConfirm={onConfirm}
      progress={progress}
    />
  );
};

export default IdentityPoolUserDeleteDialog;
