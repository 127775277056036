import React from "react";

import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import UploadFileIcon from "jsx:../../../assets/images/icons/identities/saml/file-arrow-up.svg";
import UploadUrlIcon from "jsx:../../../assets/images/icons/identities/saml/file-link.svg";
import IdentifierSourceAttributeIcon from "jsx:../../../assets/images/icons/identities/saml/identifier-source-attribute.svg";
import IdentifierSourceSubjectIcon from "jsx:../../../assets/images/icons/identities/saml/identifier-source-subject.svg";
import ManualConfigurationIcon from "jsx:../../../assets/images/icons/identities/saml/manual-configuration-icon.svg";
import MetadataBasedIcon from "jsx:../../../assets/images/icons/identities/saml/metadata-based-icon.svg";

interface Props {
  active?: boolean;
}

const getProps: any = (theme: Theme, props: Props) => ({
  stroke: props.active ? theme.palette.primary.main : theme.palette.secondary.light,
});

export function SamlConfigMetadataBasedIcon(props: Props) {
  const theme = useTheme();
  return <MetadataBasedIcon {...getProps(theme, props)} />;
}

export function SamlConfigManualIcon(props: Props) {
  const theme = useTheme();
  return <ManualConfigurationIcon {...getProps(theme, props)} />;
}

export function SamlConfigUploadUrlIcon(props: Props) {
  const theme = useTheme();
  return <UploadUrlIcon {...getProps(theme, props)} />;
}

export function SamlConfigUploadFileIcon(props: Props) {
  const theme = useTheme();
  return <UploadFileIcon {...getProps(theme, props)} />;
}

export function SamlIdentifierSourceSubjectIcon(props: Props) {
  const theme = useTheme();
  return <IdentifierSourceSubjectIcon {...getProps(theme, props)} />;
}

export function SamlIdentifierSourceAttributeIcon(props: Props) {
  const theme = useTheme();
  return <IdentifierSourceAttributeIcon {...getProps(theme, props)} />;
}
