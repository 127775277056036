import React, { useState } from "react";

import OutlinedInput from "@mui/material/OutlinedInput";
import { makeStyles } from "tss-react/mui";

import ConfirmationDialog from "./ConfirmationDialog";

const useStyles = makeStyles()(() => ({
  text: {
    marginBottom: 28,
  },
  name: {
    wordBreak: "break-word",
  },
}));

interface Props {
  title: string;
  name: string;
  progress?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  type:
    | "application"
    | "identity"
    | "workspace"
    | "service"
    | "authorizer"
    | "policy"
    | "pool"
    | "user population"
    | "custom application"
    | "organization";
}

const RemoveConfirmationDialog = ({ title, name, onCancel, onConfirm, progress, type }: Props) => {
  const { classes } = useStyles();
  const [confirmDeleteName, setConfirmDeleteName] = useState("");

  return (
    <ConfirmationDialog
      id="confirmation-dialog"
      title={title}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmText="Delete"
      progress={progress}
      confirmButtonProps={{
        disabled: name !== confirmDeleteName,
      }}
      content={
        <div id="confirmation-dialog-content">
          <div className={classes.text}>
            You're about to permanently delete the <b className={classes.name}>{name}</b> {type}.
            This cannot be undone. Please type its name to validate the deletion.
          </div>
          <OutlinedInput
            onChange={e => {
              setConfirmDeleteName(e.currentTarget.value);
            }}
            id="confirmation-dialog-name-input"
            style={{ marginTop: 8 }}
            fullWidth
            inputProps={{
              style: { padding: "16px 14px", backgroundColor: "#FBFCFD" },
            }}
            value={confirmDeleteName}
            autoFocus
            onKeyDown={e => e.stopPropagation()} // FIX for dialog opened from menu item and input key triggering menu item selection
          />
        </div>
      }
    />
  );
};

export default RemoveConfirmationDialog;
