import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import { makeStyles } from "tss-react/mui";

import { ServerResponseProfileEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import RouterLink from "../../../../common/components/RouterLink";
import { TOP_BAR_HEIGHT } from "../../../../common/components/nav/utils";
import { handleErrorWithNotify } from "../../../../common/components/notifications/notificationService";
import { useFeature } from "../../../../common/utils/hooks/useFeature";
import {
  getFromLocalStorage,
  setInLocalStorage,
} from "../../../../common/utils/localStorage.utils";
import adminServersApi from "../../../services/adminServersApi";
import { listWorkspacesQueryKey } from "../../../services/adminServersQuery";
import PageContainer from "../../common/PageContainer";
import PageContent from "../../common/PageContent";
import TopBar from "../../nav/TopBar";
import { getServerDefaultColorByProfile } from "../../workspaceDirectory/server-profiles";
import { workspaceAppTypes } from "../../workspaceDirectory/workspace-directory.common";
import DemoWorkspaceCard from "./DemoWorkspaceCard";

const useStyles = makeStyles<{ disabled: boolean }>()((_, { disabled }) => ({
  container: {
    marginTop: TOP_BAR_HEIGHT,
    posistion: "relative",
  },
  title: {
    margin: "10px 0",
  },
  content: {
    backgroundColor: "#EBF6FF",
    padding: "12px 28px 28px",
    margin: "28px 0 10px",
  },
  cardsContainer: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    fontSize: 12,
    margin: "0 28px",
    ...(disabled ? { pointerEvents: "none" } : {}),
  },
  intro: {
    margin: "0 28px",
  },
  loading: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
  },
}));

export const localStorageWelcomeWorkspaceVisitedKey = `welcome-workspaces-visited`;

export default function WelcomeWorkspaces() {
  const [progress, setProgress] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { classes } = useStyles({ disabled: progress });

  const isOBBREnabled = useFeature("obbr");

  const handleTryDemoOpenbanking = (profile: ServerResponseProfileEnum) => {
    setProgress(true);

    const id = `${profile
      .replace("openbanking_", "ob")
      .replace("_fapi_advanced", "")}-demo-${nanoid(10).toLowerCase()}`;

    const name = (workspaceAppTypes.find(w => w.profile === profile)?.title || id) + " Demo";

    adminServersApi
      .createServer({
        server: {
          tenant_id: getTenantId(),
          id,
          name,
          color: getServerDefaultColorByProfile(profile),
          profile: profile as any,
        },
      })
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .catch(err => {
        handleErrorWithNotify("Error occur while trying to create workspace")(err);
      })
      .finally(() => {
        setProgress(false);
        navigate(`/${id}/overview`);
      });
  };

  useEffect(() => {
    if (!getFromLocalStorage(localStorageWelcomeWorkspaceVisitedKey)) {
      setInLocalStorage(localStorageWelcomeWorkspaceVisitedKey, "true");
    }
  }, []);

  return (
    <PageContainer>
      <TopBar />
      <div className={classes.container}>
        {progress && <LinearProgress className={classes.loading} />}

        <PageContent fullWidth={false}>
          <div className={classes.intro}>
            <div>Let's Get Started</div>
            <Typography variant="h2" className={classes.title}>
              Create a Workspace
            </Typography>
            <div>
              Our pre-configured workspaces are designed to get you started quickly with helping
              solve your problem. Select from one of the workspaces types to start.
            </div>
          </div>
          <div className={classes.content}>
            <Typography variant="caption">Demos</Typography>
            <div className={classes.cardsContainer}>
              <DemoWorkspaceCard
                type="default"
                id="default"
                title="B2B CIAM DEMO"
                onClick={() => navigate("/demo/overview")}
                disabled={progress}
              />
              <DemoWorkspaceCard
                type="fdx"
                id="openbanking-fdx"
                title="Financial Data Exchange"
                onClick={() => handleTryDemoOpenbanking(ServerResponseProfileEnum.Fdx)}
                disabled={progress}
              />
              <DemoWorkspaceCard
                type="openbanking-cdr"
                id="openbanking-cdr"
                title="CDR Australia"
                onClick={() => handleTryDemoOpenbanking(ServerResponseProfileEnum.CdrAustralia)}
                disabled={progress}
              />
              {isOBBREnabled && (
                <DemoWorkspaceCard
                  type="openbanking-br"
                  id="openbanking-br"
                  title="Open Finance Brazil"
                  onClick={() => handleTryDemoOpenbanking(ServerResponseProfileEnum.OpenbankingBr)}
                  disabled={progress}
                />
              )}
              <DemoWorkspaceCard
                type="openbanking-uk"
                id="openbanking-uk"
                title="Open Banking UK"
                onClick={() =>
                  handleTryDemoOpenbanking(ServerResponseProfileEnum.OpenbankingUkFapiAdvanced)
                }
                disabled={progress}
              />
            </div>
          </div>
          <RouterLink to="/welcome" className={classes.link}>
            View all workspaces
          </RouterLink>
        </PageContent>
      </div>
    </PageContainer>
  );
}
