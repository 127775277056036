import React, { useEffect, useState } from "react";

import DragIndicator from "@mui/icons-material/DragIndicator";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { AccessorFnColumnDef, DisplayColumnDef } from "@tanstack/react-table";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { Minus, Plus, X } from "react-feather";
import { makeStyles } from "tss-react/mui";

import InMenuSearch from "../../../admin/components/common/InMenuSearch";
import useDragAndDropList from "./useDragAndDropList";

const useStyles = makeStyles()(theme => ({
  header: {
    fontWeight: "bold",
    padding: "4px 8px 12px 12px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    backgroundColor: "#F2F3F4",
  },
  content: {
    paddingTop: 8,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 8px",
    "& > button": {
      fontSize: 10,
      padding: 0,
      minWidth: 40,
      "&:first-of-type": {
        color: theme.palette.secondary.light,
      },
    },
  },
  columnsList: {
    maxHeight: 500,
    overflowY: "auto",
  },
  activeList: {
    backgroundColor: "#F7FAFF",
    padding: 0,
    fontSize: 12,
  },
  list: {
    padding: 0,
  },
  li: {
    fontSize: 12,
    listStyle: "none",
    padding: "3px 8px",
    display: "flex",
    alignItems: "center",
    "& > div": {
      flex: 1,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: 127,
    },
  },
  prefixIconButton: {
    marginRight: 8,
  },
  postfixIcon: {
    marginLeft: 11,
    fontSize: 16,
    cursor: "grab",
  },
}));

interface Props<Type> {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  initialActiveColumns: string[];
  columns: DisplayColumnDef<Type, any[]>[] | AccessorFnColumnDef<Type, any>[];
  activeColumns: string[];
  setActiveColumns: (activeColumns: string[]) => void;
}

const AdvancedTableColumnFilter = <Type,>({
  anchorEl,
  handleClose,
  initialActiveColumns,
  columns,
  activeColumns,
  setActiveColumns,
}: Props<Type>) => {
  const { cx, classes } = useStyles();
  const [editableActiveColumns, setEditableActiveColumns] = useState(activeColumns);
  const [searchText, setSearchText] = useState("");

  const activeColumnsList = editableActiveColumns
    .map(activeColumn => columns.find(column => column.id === activeColumn))
    .filter((column): column is AccessorFnColumnDef<Type, any> => !!column);

  const inactiveColumnsList = columns.filter(
    column => !editableActiveColumns.includes(column.id ?? "")
  );

  const getStringHeader = (
    column: DisplayColumnDef<Type, any[]> | AccessorFnColumnDef<Type, any>
  ) => {
    const { id, header } = column;
    return typeof header === "string" ? header : startCase(id ?? "");
  };

  const activeColumnsToRender = activeColumnsList.filter(column =>
    getStringHeader(column).toLowerCase().includes(searchText.toLowerCase())
  );

  const { draggedNode, onDragStart } = useDragAndDropList({
    items: activeColumnsToRender.map(column => column.id ?? ""),
    setItems: setEditableActiveColumns,
  });

  useEffect(() => {
    setEditableActiveColumns(activeColumns);
  }, [activeColumns]);

  return (
    <Menu
      id="clients-table-more-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => {
        setEditableActiveColumns(activeColumns);
        handleClose();
      }}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Typography variant="textMD" className={classes.header}>
        Table Settings
        <IconButton
          onClick={() => {
            setEditableActiveColumns(activeColumns);
            handleClose();
          }}
          size="small"
          className={classes.closeButton}
          id="advanced-table-settings-close-button"
          aria-label="close table settings button"
        >
          <X size={18} />
        </IconButton>
      </Typography>
      <Divider />
      <div className={classes.content}>
        <InMenuSearch onSearch={setSearchText} />

        <div className={classes.buttons}>
          <Button
            size="small"
            onClick={() => setEditableActiveColumns(initialActiveColumns)}
            id="advanced-table-settings-reset-button"
            aria-label="reset table settings"
          >
            RESET
          </Button>
          <Button
            size="small"
            onClick={() => setActiveColumns(editableActiveColumns)}
            disabled={isEqual(activeColumns, editableActiveColumns)}
            id="advanced-table-settings-save-button"
            aria-label="save table settings"
          >
            SAVE
          </Button>
        </div>

        <div className={classes.columnsList}>
          <ul className={classes.activeList}>
            {activeColumnsToRender.map((column, index) => {
              const id = `draggable-id-${column.id}`;
              return (
                <li
                  id={id}
                  key={column.id}
                  className={cx(classes.li, "draggable-item")}
                  style={id === draggedNode?.id ? { opacity: 0.5 } : {}}
                >
                  <IconButton
                    id={`advanced-table-settings-remove-item-${column.id}`}
                    size="small"
                    className={classes.prefixIconButton}
                    aria-label={`remove column ${getStringHeader(column)}`}
                    onClick={() =>
                      setEditableActiveColumns(
                        editableActiveColumns.filter(key => key !== column.id)
                      )
                    }
                  >
                    <Minus size={12} />
                  </IconButton>
                  <div>{getStringHeader(column)}</div>
                  {!searchText && (
                    <DragIndicator
                      data-id={id}
                      data-index={index}
                      className={classes.postfixIcon}
                      onMouseDown={e => {
                        onDragStart(id, index, e.clientX, e.clientY);
                      }}
                    />
                  )}
                </li>
              );
            })}
          </ul>

          <ul className={classes.list}>
            {inactiveColumnsList
              .filter(column =>
                getStringHeader(column).toLowerCase().includes(searchText.toLowerCase())
              )
              .map(column => (
                <li className={classes.li} key={column.id}>
                  <IconButton
                    id={`advanced-table-settings-add-item-${column.id}`}
                    size="small"
                    className={classes.prefixIconButton}
                    aria-label={`add column ${getStringHeader(column)}`}
                    onClick={() =>
                      setEditableActiveColumns([...editableActiveColumns, column.id ?? ""])
                    }
                  >
                    <Plus size={12} />
                  </IconButton>

                  <div>{getStringHeader(column)}</div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </Menu>
  );
};

export default AdvancedTableColumnFilter;
