import { useQuery } from "@tanstack/react-query";

import { System, Systems } from "@cloudentity/acp-permissions";

import { getTenantId } from "../../common/api/paths";
import identitySystemsApi from "./adminPermissionsSystemsApi";
import { withQueryError } from "./queryUtils";

const LIST_PERMISSION_SYSTEMS_QUERY = "LIST_PERMISSION_SYSTEMS_QUERY";
const GET_PERMISSION_SYSTEM_QUERY = "GET_PERMISSION_SYSTEM_QUERY";

export const listPermissionSystemsQueryKey = (tid: string) => [LIST_PERMISSION_SYSTEMS_QUERY, tid];
export const getPermissionSystemQueryKey = (tid: string, psId: string) => [
  GET_PERMISSION_SYSTEM_QUERY,
  tid,
  psId,
];

export const useListPermissionSystems = (tid: string, options?: any) =>
  useQuery<Systems>({
    queryKey: listPermissionSystemsQueryKey(tid),
    queryFn: withQueryError<Systems>(async () => {
      const data = await identitySystemsApi.listSystems({});
      return data.data;
    }, "Error occurred while trying to list permission systems"),
    ...options,
  });

export const useGetPermissionSystem = (psId: string, enabled?: boolean) =>
  useQuery<System>({
    queryKey: getPermissionSystemQueryKey(getTenantId(), psId),
    queryFn: withQueryError<System>(async () => {
      const data = await identitySystemsApi.getSystem({ psID: psId });
      return data.data;
    }, "Error occurred while trying to get permission system"),
    ...{ enabled },
  });
