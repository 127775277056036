import React, { useState } from "react";

import { makeStyles } from "tss-react/mui";

import {
  ServerBindingResponse,
  ServerResponse,
  WorkspaceResponse,
  WorkspaceResponseTypeEnum,
  WorkspacesResponse,
} from "@cloudentity/acp-admin";

import { listWorkspacesQueryKey, useListWorkspaces } from "../../../services/adminServersQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import EnhancedTableAsyncWithCollapsedRow from "../../common/EnhancedTableAsync/EnhancedTableAsyncWithCollapsedRows";
import useItemsWithQuery from "../../common/EnhancedTableAsync/useItemsWithQuery";
import PageContent from "../../common/PageContent";
import WorkspaceAvatar from "../../nav/WorkspaceAvatar";
import EditWorkspaceDialog from "../EditWorkspaceDialog";
import { getServerTitle } from "../server-profiles";
import DeveloperPortalsMenu from "./DeveloperPortalsMenu";
import WorkspacesToolbar from "./WorkspacesToolbar";

const useStyles = makeStyles()(() => ({
  content: {
    tbody: {
      position: "relative",
    },
  },
}));

const columns = [
  { label: "Developer portal", id: "chevron", align: "left" as const, colSpan: 2 },
  { label: "", id: "actions", align: "center" as const, style: { width: "5%" } },
];

export type MainMenuType = null | {
  anchorEl: HTMLElement;
  server: WorkspaceResponse;
};

interface Props {
  serversBindings: ServerBindingResponse[];
  progress: string;
  deleteProgress: string;
  onDelete: (id: string, name: string, type: WorkspaceResponseTypeEnum | undefined) => void;
  onSelectServer: (id: string) => void;
  onBindTheme: (server: WorkspaceResponse) => void;
  onUnbindTheme: (server: WorkspaceResponse) => void;
  handleBookmarkServer: (server: ServerResponse) => void;
  onWorkspaceCreate: ({ aid }: { aid: string }) => void;
}

function DeveloperPortalsController({
  serversBindings,
  progress,
  deleteProgress,
  onDelete,
  onSelectServer,
  onBindTheme,
  onUnbindTheme,
  handleBookmarkServer,
  onWorkspaceCreate,
}: Props) {
  const { classes } = useStyles();

  const [mainMenu, setMainMenu] = useState<MainMenuType>(null);
  const [editWorkspace, setEditWorkspace] = useState<{ id: string } | null>(null);

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const data = useItemsWithQuery<WorkspaceResponse, WorkspacesResponse>({
    id: "workspaces",
    idGetter: pool => pool.id,
    getArray: (queryData, params) => {
      const data = queryData?.workspaces ?? [];
      return {
        data: data.slice(0, params.limit),
        nextPageAvailable: data.length > params.limit,
      };
    },
    getTotalItemsQueryKey: params => [
      ...listWorkspacesQueryKey(params.limit + 1),
      {
        workspaceTypes: "developer",
        searchPhrase: params.searchText || undefined,
        sort: params.sort,
        order: params.order,
      },
    ],
    useQueryFn: (page, params) =>
      useListWorkspaces(
        {
          workspaceTypes: "developer",
          searchPhrase: params.searchText || undefined,
          sort: params.sort,
          order: params.order,
          limit: params.limit + 1,
          beforeWorkspaceId: undefined,
          afterWorkspaceId: page.afterItemId,
        },
        { keepPreviousData: true, enabled: true }
      ),
    initialSort: "name",
  });

  const {
    data: workspaces,
    searchText,
    filters,
    onFiltersUpdate,
    isLoading,
    sort,
    order,
    limit,
    page,
    nextPageAvailable,
    onPaginationUpdate,
  } = data;

  const workspacesWithServers = workspaces.map(workspace => ({
    ...workspace,
    bindings: serversBindings.filter(binding => binding.server_id === workspace.id),
  }));

  const handleClose = () => {
    setMainMenu(null);
  };

  return (
    <PageContent className={classes.content} progress={data.firstPageLoading}>
      <WorkspacesToolbar
        searchText={searchText}
        onUpdate={text => onFiltersUpdate(text, filters)}
        onCreate={
          checkTenantPermissionsQuery.data?.create_workspace
            ? () => onWorkspaceCreate({ aid: "" })
            : undefined
        }
      />

      <EnhancedTableAsyncWithCollapsedRow
        id="developer-portals-table-2"
        data={workspacesWithServers.map(portal => ({
          ...portal,
          icon: <WorkspaceAvatar server={portal} />,
          innerRows: portal.bindings.map(binding => ({
            id: binding.dependent,
            icon: (
              <WorkspaceAvatar server={{ id: binding.dependent, ...binding.dependent_server }} />
            ),
            text: binding.dependent_server?.name,
            subText: getServerTitle({ id: binding.dependent, ...binding.dependent_server }),
          })),
        }))}
        headCells={columns}
        setMainMenu={({ anchorEl, row }) =>
          setMainMenu({ anchorEl, server: row as WorkspaceResponse })
        }
        rowProgress={progress}
        deleteRowProgress={deleteProgress}
        innerTableHeader="Workspaces"
        loading={isLoading}
        sort={sort}
        order={order}
        limit={limit}
        searchText={searchText}
        filters={filters}
        onFiltersUpdate={onFiltersUpdate}
        page={page}
        nextPageAvailable={nextPageAvailable}
        onPaginationUpdate={onPaginationUpdate}
        filtersSetup={[]}
        searchPlaceholder=""
        noToolbar
      />

      <DeveloperPortalsMenu
        mainMenu={mainMenu}
        handleClose={handleClose}
        onSelectServer={onSelectServer}
        setEditWorkspace={setEditWorkspace}
        onDelete={onDelete}
        onBindTheme={onBindTheme}
        onUnbindTheme={onUnbindTheme}
        handleBookmarkServer={handleBookmarkServer}
      />

      {editWorkspace && (
        <EditWorkspaceDialog onClose={() => setEditWorkspace(null)} serverID={editWorkspace.id} />
      )}
    </PageContent>
  );
}
export default DeveloperPortalsController;
