import React, { useMemo, useState } from "react";

import { AuthenticationContextSettings, Claim } from "@cloudentity/acp-admin";

import Dialog from "../../../../common/components/Dialog";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";
import {
  getSourcePathWithMetadataKey,
  PathOptions,
  SourcePath,
  SourceType,
  sourceTypeOptions,
} from "./ClaimUtils";

export interface SamlClaimsEditProps {
  options: AuthenticationContextSettings["attributes"];
  model: Claim & { metadata_key?: string };
  existingClaims: string[];
  onEdit: (claim: Claim) => void;
  onCancel: () => void;
  progress: boolean;
}

const SamlClaimsEdit = ({
  options,
  model,
  existingClaims,
  onEdit,
  onCancel,
  progress,
}: SamlClaimsEditProps) => {
  const data = useMemo(
    () => ({
      source_type: sourceTypeOptions.find(t => t.value === model.source_type)?.value,
      source_path:
        PathOptions(model.source_type, options).find(o => o.name === model.source_path) ||
        model.source_path,
      metadata_key: model.metadata_key,
      name: model.name,
      description: model.description,
      saml_name: model.saml_name,
      saml_name_format: model.saml_name_format,
    }),
    [model, options]
  );

  const form = useForm({ id: "edit-claim", initialValues: data, progress });

  const [paths, setPaths] = useState(PathOptions(model.source_type, options));

  const handleEdit = data => {
    const claim = {
      ...model,
      source_type: data.source_type,
      source_path: getSourcePathWithMetadataKey(
        data.source_type,
        data.source_path,
        data.metadata_key
      ),
      name: data.name,
      description: data.description,
      saml_name: data.saml_name,
      saml_name_format: data.saml_name_format,
    };
    onEdit(claim);
  };

  return (
    <Dialog onClose={onCancel} id="edit-claim-dialog" title="Edit claim">
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          rules={{
            validate: {
              notUniq: validators.notUniq({
                label: "Name",
                options: existingClaims.filter(v => v !== data.name),
              }),
            },
          }}
        />
        <TextField name="description" label="Description" />
        <SourceType options={sourceTypeOptions} authnCtxOptions={options} setPaths={setPaths} />
        <SourcePath options={paths} />

        <TextFieldRequired name="saml_name" label="SAML name" />

        <TextFieldRequired name="saml_name_format" label="SAML name format" />

        <FormFooter onCancel={onCancel} onSubmit={handleEdit} submitText="Update" />
      </Form>
    </Dialog>
  );
};

export default SamlClaimsEdit;
