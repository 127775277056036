import React from "react";

import { makeStyles } from "tss-react/mui";

import { ExtendedUser } from "@cloudentity/acp-identity";

import {
  divideIdentifiersByType,
  IdentifierInfo,
  MoreIdentifiers,
} from "./IdentityPoolUserCellComponents";

const useStyles = makeStyles()(() => ({
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
}));

interface Props {
  user: ExtendedUser;
}

const IdentityPoolUserIdentifierCell = ({ user }: Props) => {
  const { classes } = useStyles();

  const userIdentifiers = user.identifiers_with_details || user.identifiers || [];
  const withDetails = !!user.identifiers_with_details;
  const identifiers = (userIdentifiers as any[]).filter(v => (withDetails ? v.value : v)) ?? [];
  const verifiedAddresses = user["verified addresses"] || []; // FIX on BE - underscore instead of space

  const { phoneIdentifiers, emailIdentifiers, otherIdentifiers } = divideIdentifiersByType({
    identifiers,
    sortFn: (a, b) => (verifiedAddresses.includes(a) && !verifiedAddresses.includes(b) ? -1 : 1),
    withDetails,
  });

  const tooltipIdentifiers = [...emailIdentifiers.slice(1), ...otherIdentifiers];

  const onlyOtherIdentifier = !emailIdentifiers.length && !!otherIdentifiers.length;

  return (
    <div>
      {phoneIdentifiers.length === 0 &&
      emailIdentifiers.length === 0 &&
      otherIdentifiers.length === 0 ? (
        <span style={{ color: "lightgray" }}>No identifiers</span>
      ) : (
        <div>
          <div className={classes.flexBox}>
            <IdentifierInfo identifier={phoneIdentifiers[0]} />
            {phoneIdentifiers.length > 1 && (
              <MoreIdentifiers identifiers={phoneIdentifiers.slice(1)} />
            )}
          </div>

          <div className={classes.flexBox}>
            <IdentifierInfo identifier={emailIdentifiers[0]} />

            {onlyOtherIdentifier && (
              <>
                <IdentifierInfo identifier={otherIdentifiers[0]} />

                {otherIdentifiers.length > 1 && (
                  <MoreIdentifiers identifiers={otherIdentifiers.slice(1)} />
                )}
              </>
            )}

            {!onlyOtherIdentifier && tooltipIdentifiers.length > 0 && (
              <MoreIdentifiers identifiers={tooltipIdentifiers} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default IdentityPoolUserIdentifierCell;
