import React, { useMemo } from "react";

import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import {
  ArrayFieldTemplateItemType,
  ArrayFieldTemplateProps,
  FieldErrorProps,
  FieldTemplateProps,
} from "@rjsf/utils";
import { Plus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import FormInputLabel from "../../../../../../../common/components/FormInputLabel";

const useStyles = makeStyles()(theme => ({
  arrayFieldItem: {
    display: "flex",
    alignContent: "center",
    width: "100%",
    "& > .form-group": {
      flex: 1,
      marginBottom: 16,
      minWidth: 0,
    },
    "& > .form-group.field > label": {
      display: "none",
    },
  },
  arrayFieldItemButtons: {
    padding: "12px 0 0 4px",
  },
  addButton: {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    margin: "0 0 16px",
  },
  textField: {
    width: "100%",
    "& .MuiInputLabel-root": {
      display: "none",
    },
    "& fieldset ": {
      top: 0,
      "& legend": {
        display: "none",
      },
    },
    "& .MuiTextField-root": {
      width: "100%",
      "& .MuiInputBase-root": {
        backgroundColor: theme.custom.greys.fieldBackground,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        boxShadow: theme.custom.shadows.formInner,
      },
    },
    "& .field-boolean h6": {
      display: "none",
    },
    "& .MuiFormHelperText-root[id$=__error]": {
      color: "#BD271E",
    },
  },
}));

export function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
  const { classes } = useStyles();

  return (
    <div>
      <Typography variant="h6" className={classes.title}>
        {props.title}
      </Typography>
      <div>
        {props.items.map(element => {
          return (
            <ArrayFieldItemTemplate {...element} key={element.key}>
              {element.children}
            </ArrayFieldItemTemplate>
          );
        })}
      </div>
      {props.canAdd && (
        <div className={classes.addButton}>
          <Button onClick={props.onAddClick} disabled={props.disabled}>
            <Plus size={15} style={{ marginRight: 4 }} /> Add items
          </Button>
        </div>
      )}
    </div>
  );
}

export function ArrayFieldItemTemplate(props: ArrayFieldTemplateItemType) {
  const { classes } = useStyles();

  const {
    children,
    hasToolbar,
    onDropIndexClick,
    onReorderClick,
    index,
    hasRemove,
    hasMoveUp,
    hasMoveDown,
    disabled,
    readonly,
    uiSchema,
    registry,
  } = props;
  const { MoveDownButton, MoveUpButton, RemoveButton } = registry.templates.ButtonTemplates;

  const onRemoveClick = useMemo(() => onDropIndexClick(index), [index, onDropIndexClick]);

  const onArrowUpClick = useMemo(() => onReorderClick(index, index - 1), [index, onReorderClick]);

  const onArrowDownClick = useMemo(() => onReorderClick(index, index + 1), [index, onReorderClick]);

  return (
    <div className={classes.arrayFieldItem}>
      {children}
      {hasToolbar && (
        <div className={classes.arrayFieldItemButtons}>
          {(hasMoveUp || hasMoveDown) && (
            <MoveUpButton
              disabled={disabled || readonly || !hasMoveUp}
              onClick={onArrowUpClick}
              uiSchema={uiSchema}
              registry={registry}
            />
          )}
          {(hasMoveUp || hasMoveDown) && (
            <MoveDownButton
              disabled={disabled || readonly || !hasMoveDown}
              onClick={onArrowDownClick}
              uiSchema={uiSchema}
              registry={registry}
            />
          )}
          {hasRemove && (
            <RemoveButton
              disabled={disabled || readonly}
              onClick={onRemoveClick}
              uiSchema={uiSchema}
              registry={registry}
            />
          )}
        </div>
      )}
    </div>
  );
}

export function FieldTemplate(props: FieldTemplateProps) {
  const { cx, classes } = useStyles();

  const { id, classNames, label, help, required, errors, children, displayLabel } = props;

  return (
    <div className={cx(classes.textField, classNames)}>
      <FormInputLabel
        id={`${id}-label`}
        forId={id}
        label={label}
        optional={displayLabel && !required}
      />
      {children}
      {errors}
      {help}
    </div>
  );
}

export function FieldErrorTemplate(props: FieldErrorProps) {
  const { errors } = props;

  return errors?.length ? (
    <>
      {errors.map((error, i) => (
        <FormHelperText error key={i}>
          {error}
        </FormHelperText>
      ))}
    </>
  ) : null;
}

export function transformErrors(errors) {
  return errors.map(error => {
    if (error.name === "required") {
      error.message = "Field is required";
    }
    return error;
  });
}
