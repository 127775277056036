import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import { UiSchema } from "@rjsf/utils";
import { AxiosResponse } from "axios";
import { Mail, Phone } from "react-feather";
import { makeStyles } from "tss-react/mui";

import {
  SelfUserVerifiableAddressTypeEnum,
  SelfUserWithDataV2,
} from "@cloudentity/acp-identityself";

import SchemaForm from "../../../admin/components/workspaceDirectory/identityPools/identityPool/users/user/SchemaForm";
import {
  getUIOrderBasedOnRequiredFields,
  mapFieldNameToTitle,
} from "../../../admin/components/workspaceDirectory/identityPools/schemas/schemas.utils";
import identitySelfApi from "../../services/identitySelfApi";
import FormSection from "../FormSection";
import LoadingButton from "../LoadingButton";
import ValueWithCopy from "../ValueWithCopy";
import { notifyErrorOrDefaultTo, notifySuccess } from "../notifications/notificationService";
import SelfServiceEmptySidePanel from "./SelfServiceEmptySidePanel";
import SelfServiceTabContent from "./SelfServiceTabContent";
import { useCommonStyles } from "./utils";

const useStyles = makeStyles()(theme => ({
  content: {
    "& #root__description": {
      display: "none",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 32,
  },
  icon: {
    marginRight: 16,
    color: theme.palette.secondary.light,
  },
}));

interface Props {
  userData: SelfUserWithDataV2 | null;
  fetchUser: (withSetter?: boolean) => Promise<AxiosResponse<SelfUserWithDataV2, any> | null>;
}

export default function SelfServiceProfile({ userData, fetchUser }: Props) {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const [data, setData] = useState(userData?.payload);
  const [updateProgress, setUpdateProgress] = useState(false);

  const schemaWithMappedTitles = mapFieldNameToTitle(userData?.payload_schema ?? {});
  const uiSchema: UiSchema = {
    "ui:order": getUIOrderBasedOnRequiredFields(schemaWithMappedTitles),
  };

  const addresses = userData?.verifiable_addresses ?? [];

  const phoneAddresses = addresses.filter(
    address => address.type === SelfUserVerifiableAddressTypeEnum.Mobile
  );
  const emailAddresses = addresses.filter(
    address => address.type === SelfUserVerifiableAddressTypeEnum.Email
  );

  const handleSave = () => {
    setUpdateProgress(true);

    identitySelfApi
      .updateUserProfile({ payload: data })
      .then(() => notifySuccess("User data updated successfully"))
      .then(() => fetchUser(true))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to update user"))
      .finally(() => setUpdateProgress(false));
  };

  return (
    <SelfServiceTabContent
      rightPanel={
        <>
          {!addresses.length ? (
            <SelfServiceEmptySidePanel
              header="No Contact Information"
              text="Reach out to the administrator to add your updated contact information"
            />
          ) : (
            <>
              <FormSection title="Contact information" subtitle="Your preferred contact details" />

              {!!phoneAddresses.length && (
                <>
                  {phoneAddresses.map(address => (
                    <ValueWithCopy
                      key={address.address}
                      value={address.address}
                      startAdornment={
                        <Phone size={16} className={commonClasses.infoPanelRowIcon} />
                      }
                    />
                  ))}
                </>
              )}

              {!!emailAddresses.length && (
                <>
                  {emailAddresses.map(address => (
                    <ValueWithCopy
                      key={address.address}
                      value={address.address}
                      startAdornment={<Mail size={16} className={commonClasses.infoPanelRowIcon} />}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </>
      }
    >
      <Paper className={commonClasses.paper}>
        <FormSection
          title="Personal information"
          subtitle="Provide your personal details below"
          style={{ marginBottom: 16 }}
        />

        <div className={classes.content}>
          <SchemaForm
            formData={data}
            setFormData={p => {
              setData(p);
            }}
            schema={schemaWithMappedTitles}
            UISchema={uiSchema}
            submitAttempt={false}
            extraErrors={{}}
            resetExtraErrors={() => {}}
            disabled={false}
          />

          <div className={classes.footer}>
            <div />
            {/* <Button variant="text" id="gateway-delete" color="error">
            Delete Account
          </Button> */}

            <LoadingButton
              id="profile-save-button"
              variant="contained"
              disabled={updateProgress}
              onClick={handleSave}
              loading={updateProgress}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </Paper>
    </SelfServiceTabContent>
  );
}
