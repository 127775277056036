import React from "react";

import Button from "@mui/material/Button";
import { Copy, Eye } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { copyToClipboard } from "../../../common/services/clipboard";

const useStyles = makeStyles()(theme => ({
  container: {
    border: "solid 1px #E0E1E3",
    backgroundColor: "#FBFCFD",
    borderRadius: 4,
    marginTop: 24,
    position: "relative",
  },
  line: {
    display: "flex",
    alignItems: "center",
  },
  number: {
    backgroundColor: "#E0E1E3",
    paddingRight: 8,
    paddingTop: 5,
    width: 34,
    fontWeight: 500,
    alignSelf: "normal",
    textAlign: "right",
  },
  code: {
    fontFamily: "monospace",
    fontSize: 13,
    padding: "4px 12px",
    flex: 1,
  },
  copyButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
    backgroundColor: "#F2F3F4",
    fontSize: 10,
    fontWeight: "bold",
    padding: "4px 8px",
    lineHeight: "10px",
    borderRadius: 4,
    minWidth: "unset",
    "& > svg": {
      marginLeft: 6,
    },
  },
  showHideButton: {
    top: 0,
    bottom: "unset",
  },
}));

interface Props {
  lines: string[];
  codeShowHide?: () => void;
}

const CodeBlock = ({ lines, codeShowHide }: Props) => {
  const { cx, classes } = useStyles();

  const getValue = () => lines.join("\n").replaceAll("\u00A0", "  ");

  return (
    <div className={classes.container}>
      {lines.map((line, index) => (
        <div key={line} className={classes.line}>
          <div className={classes.number}>{index + 1}</div>
          <div className={classes.code}>{line}</div>
        </div>
      ))}

      <Button
        aria-label="copy to clipboard"
        onClick={() => copyToClipboard(getValue(), "Command")}
        size="small"
        className={classes.copyButton}
      >
        Copy <Copy size={10} strokeWidth={3} />
      </Button>

      {codeShowHide && (
        <Button
          aria-label="show credentials"
          onClick={() => codeShowHide()}
          size="small"
          className={cx(classes.copyButton, classes.showHideButton)}
        >
          Show <Eye size={10} strokeWidth={3} />
        </Button>
      )}
    </div>
  );
};

export default CodeBlock;
