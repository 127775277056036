import React, { ReactNode, useEffect, useState } from "react";

import Password from "@mui/icons-material/Password";
import PinOutlined from "@mui/icons-material/PinOutlined";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { MoreVertical, Smartphone } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { ServerResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-admin";
import { PoolAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";

import FingerprintIcon from "../../../../../common/components/icons/FingerprintIcon";
import { useFormContext } from "../../../../../common/utils/forms/Form";
import { usePoolRootUrl } from "../utils";

const useStyles = makeStyles()(theme => ({
  container: {
    marginBottom: 32,
  },
  inputContainer: {
    marginBottom: 16,
    position: "relative",
  },
  badge: {
    position: "absolute",
    top: -16,
    right: 8,
    background: "#E9EBEC",
    borderRadius: "4px 4px 0px 0px",
    color: theme.palette.secondary.light,
    padding: "2px 4px",
  },
  addButtonContainer: {
    marginTop: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  select: {
    "& .MuiInputBase-input": {
      padding: "15px 14px",
    },
  },
  menuPaper: {
    minWidth: 165,
  },
  input: {
    backgroundColor: theme.custom.greys.fieldBackground,
  },
}));

export const poolAuthMechanismMapper: {
  [key in PoolAuthenticationMechanismsEnum]: { icon: any; label: string; id: string };
} = {
  [PoolAuthenticationMechanismsEnum.Otp]: {
    id: "otp",
    icon: <PinOutlined />,
    label: "Verification Code",
  },
  [PoolAuthenticationMechanismsEnum.Password]: {
    id: "password",
    icon: <Password style={{ fontSize: 23 }} />,
    label: "Password",
  },
  [PoolAuthenticationMechanismsEnum.Webauthn]: {
    id: "webauthn",
    icon: <FingerprintIcon />,
    label: "Passkey",
  },
  [PoolAuthenticationMechanismsEnum.Totp]: {
    id: "totp",
    icon: <Smartphone />,
    label: "Authenticator App",
  },
};

export interface IdentityPoolsAuthnSelectProps {
  id: string;
  label: ReactNode;
  authenticationMechanismsKeyName:
    | "authentication_mechanisms"
    | "second_factor_authentication_mechanisms";
  preferredAuthenticationMechanismsKeyName: string;
  allowedAuthenticationMechanisms: ServerResponseAuthenticationMechanismsEnum[];
  options: PoolAuthenticationMechanismsEnum[];
  progress: boolean;
  settings?: {
    [key in PoolAuthenticationMechanismsEnum]?: () => void;
  };
  disabled?: boolean;
  noManagePermission: boolean;
  onMoveToOtherFactor: (method: PoolAuthenticationMechanismsEnum) => void;
}

export default function IdentityPoolsAuthnSelect({
  id,
  label,
  authenticationMechanismsKeyName,
  preferredAuthenticationMechanismsKeyName,
  allowedAuthenticationMechanisms,
  options,
  progress,
  settings,
  disabled,
  noManagePermission,
  onMoveToOtherFactor,
}: IdentityPoolsAuthnSelectProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  const [moreMenu, setMoreMenu] = useState<{
    method: PoolAuthenticationMechanismsEnum;
    anchorEl: HTMLElement;
  }>();

  const [addMethodAnchorEl, setAddMethodAnchorEl] = useState<HTMLElement | null>(null);
  const { form } = useFormContext();

  const preferredAuthenticationMechanism =
    form.watch(preferredAuthenticationMechanismsKeyName) || "";
  const authenticationMechanisms: PoolAuthenticationMechanismsEnum[] =
    form.watch(authenticationMechanismsKeyName) || [];

  const { mode } = usePoolRootUrl();
  const isMethodAllowed = method =>
    allowedAuthenticationMechanisms.includes(method) || mode === "tenant";

  const allowedOptions = options.filter(o => isMethodAllowed(o));

  useEffect(() => {
    form.register(preferredAuthenticationMechanismsKeyName);
    form.register(authenticationMechanismsKeyName);
  }, [preferredAuthenticationMechanismsKeyName, authenticationMechanismsKeyName, form]);

  return (
    <div className={classes.container}>
      {label}

      {authenticationMechanisms
        .slice()
        .sort((a: any) => (a === preferredAuthenticationMechanism ? -1 : 1))
        .map(method => (
          <div className={classes.inputContainer} key={poolAuthMechanismMapper[method].id}>
            <FormControl fullWidth error={!isMethodAllowed(method)}>
              <OutlinedInput
                id={`authn-method-label-input-${method}`}
                key={poolAuthMechanismMapper[method].id}
                startAdornment={
                  <InputAdornment position="start">
                    {poolAuthMechanismMapper[method].icon}
                  </InputAdornment>
                }
                endAdornment={
                  !noManagePermission && (
                    <div style={{ width: 38 }}>
                      {(!!settings?.[method] || authenticationMechanisms.includes(method)) && (
                        <IconButton
                          aria-label={`toggle ${method} menu visibility`}
                          tabIndex={-1}
                          onClick={e => setMoreMenu({ method, anchorEl: e.currentTarget })}
                          id={`${method}-more-button`}
                          disabled={progress}
                        >
                          <MoreVertical size={16} />
                        </IconButton>
                      )}
                    </div>
                  )
                }
                value={poolAuthMechanismMapper[method].label}
                readOnly
                fullWidth
                className={classes.input}
                inputProps={{
                  style: { WebkitTextFillColor: theme.palette.secondary.light },
                  "data-ispreferred": preferredAuthenticationMechanism === (method as any),
                }}
              />
              {!isMethodAllowed(method) && (
                <FormHelperText>
                  Authentication method not supported in this workspace
                </FormHelperText>
              )}
            </FormControl>
            {preferredAuthenticationMechanism === (method as any) && (
              <Typography variant="textSM" component="div" className={classes.badge}>
                PREFERRED
              </Typography>
            )}
          </div>
        ))}

      {!noManagePermission && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            id={`add-${id}-button`}
            variant="text"
            color="primary"
            style={{ fontSize: 12, textTransform: "none" }}
            disabled={allowedOptions.length === 0 || disabled}
            onClick={e => setAddMethodAnchorEl(e.currentTarget)}
          >
            + Add method
          </Button>
          <Menu
            anchorEl={addMethodAnchorEl}
            keepMounted
            open={Boolean(addMethodAnchorEl)}
            onClose={() => setAddMethodAnchorEl(null)}
            classes={{ paper: classes.menuPaper }}
          >
            {allowedOptions.map(o => (
              <MenuItem
                key={o}
                onClick={() => {
                  form.setValue(authenticationMechanismsKeyName, [...authenticationMechanisms, o]);
                  setAddMethodAnchorEl(null);
                }}
              >
                {poolAuthMechanismMapper[o].label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}

      {moreMenu && !noManagePermission && (
        <Menu
          id={`${id}-${moreMenu.method}-menu`}
          anchorEl={moreMenu.anchorEl}
          keepMounted
          open={Boolean(moreMenu.anchorEl)}
          onClose={() => setMoreMenu(undefined)}
          classes={{ paper: classes.menuPaper }}
        >
          <MenuItem
            onClick={() => {
              onMoveToOtherFactor(moreMenu.method);
              setMoreMenu(undefined);
            }}
          >
            {"authentication_mechanisms" === authenticationMechanismsKeyName
              ? "Move to 2FA"
              : "Move to 1FA"}
          </MenuItem>
          {preferredAuthenticationMechanism !== (moreMenu.method as any) &&
            authenticationMechanisms.includes(moreMenu.method) && (
              <MenuItem
                onClick={() => {
                  form.setValue(preferredAuthenticationMechanismsKeyName, moreMenu.method as any);
                  setMoreMenu(undefined);
                }}
              >
                Make Preferred
              </MenuItem>
            )}
          {preferredAuthenticationMechanism === (moreMenu.method as any) && (
            <MenuItem
              onClick={() => {
                form.setValue(preferredAuthenticationMechanismsKeyName, "");
                setMoreMenu(undefined);
              }}
            >
              Clear Preferred
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              form.setValue(
                authenticationMechanismsKeyName,
                authenticationMechanisms.filter(am => am !== moreMenu.method)
              );
              setMoreMenu(undefined);
            }}
          >
            Remove
          </MenuItem>
          {settings?.[moreMenu.method] !== undefined && (
            <MenuItem
              onClick={() => {
                settings[moreMenu.method]?.();
                setMoreMenu(undefined);
              }}
            >
              Settings
            </MenuItem>
          )}
        </Menu>
      )}
    </div>
  );
}
