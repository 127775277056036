import React, { Fragment } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "tss-react/mui";

import { BaseUserWithData, UserIdentifierTypeEnum, UserWithData } from "@cloudentity/acp-identity";

import iconEnabled from "../../../../../../../../assets/images/icons/identities/user-provisioning.svg";
import { getTenantId } from "../../../../../../../../common/api/paths";
import RouterLink from "../../../../../../../../common/components/RouterLink";
import { useListIDPs } from "../../../../../../../services/adminIDPsQuery";
import { identitiesDetailsPath } from "../../../../../../identities/identities.utils";
import InfoField from "../InfoField";
import { getDateTime } from "../utils";

const useStyles = makeStyles()(() => ({
  userTypeField: {
    display: "flex",
    "& > img": {
      marginRight: 8,
    },
  },
}));

interface Props {
  user: UserWithData | BaseUserWithData;
}

export default function IdentityPoolUserSidePanelUserDetails({ user }: Props) {
  const { classes } = useStyles();
  const tenantId = getTenantId();

  const federatedIdentifiers =
    user.identifiers?.filter(identifier => identifier.type === UserIdentifierTypeEnum.Federated) ??
    [];

  const listIDPsQuery = useListIDPs(tenantId, federatedIdentifiers[0]?.metadata?.workspace_id, {
    enabled: !!federatedIdentifiers.length,
  });

  const federatedIdentifiersWithIdp = federatedIdentifiers.map(identifier => ({
    ...identifier,
    idp: (listIDPsQuery.data?.idps ?? []).find(
      idp => idp.id === identifier?.metadata?.federated_idp_id
    ),
  }));

  return (
    <>
      <Grid item xs={12}>
        <InfoField id="field-user-id" label="User id" value={user.id} noMarginTop />
      </Grid>

      <Grid item xs={6}>
        <InfoField
          id="field-user-created"
          label="User created"
          value={getDateTime(user.created_at)}
        />
      </Grid>

      <Grid item xs={6}>
        <InfoField
          id="field-last-updated"
          label="Last updated"
          value={getDateTime(user.updated_at)}
        />
      </Grid>

      {!!federatedIdentifiersWithIdp.length && (
        <>
          <Grid item xs={12}>
            <InfoField
              id="field-jit-user-type"
              label="User type"
              value={
                <div className={classes.userTypeField}>
                  <img src={iconEnabled} alt="user provisioned icon" />
                  <div>JIT provisioned</div>
                </div>
              }
            />
          </Grid>
          {federatedIdentifiersWithIdp.map(identifier => (
            <Fragment key={identifier.id}>
              <Grid item xs={6}>
                <InfoField
                  id="field-jit-user-idp"
                  label="Identity provider ID"
                  value={
                    identifier?.metadata?.workspace_id && identifier.idp ? (
                      <RouterLink
                        to={identitiesDetailsPath(
                          identifier?.metadata?.workspace_id,
                          identifier.idp.method,
                          identifier.idp.id,
                          "configuration"
                        )}
                      >
                        {identifier.idp.name}
                      </RouterLink>
                    ) : (
                      String(identifier.metadata?.federated_idp_id || "")
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <InfoField
                  id="field-jit-user-idp"
                  label="Federated sub"
                  value={String(identifier.metadata?.federated_sub || "")}
                />
              </Grid>
            </Fragment>
          ))}
        </>
      )}
    </>
  );
}
