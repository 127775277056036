import React from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import AppAvatar from "../../../common/components/AppAvatar";

interface Props {
  avatar?: string;
  name?: string;
  id?: string;
}

export default function AvatarNameId({ avatar, name, id }: Props) {
  const { classes } = useStyles();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 16,
        }}
      >
        {avatar && <AppAvatar>{avatar && (avatar[0] || "").toUpperCase()}</AppAvatar>}
      </div>
      <div>
        <Typography className={classes.firstLine}>{name}</Typography>
        <Typography variant="caption" noWrap className={classes.secondLine}>
          ID: {id}
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles()(theme => ({
  firstLine: {
    fontWeight: 500,
    color: theme.palette.secondary.dark,
    lineHeight: "16px",
    fontSize: 12,
  },
  secondLine: {
    color: theme.palette.secondary.light,
    lineHeight: "12px",
    fontSize: 10,
  },
}));
