import adminStatsApi from "./adminStatsApi";
import { useQueryWithWorkspacePermissionCheck, withQueryError } from "./queryUtils";

const GET_RECENT_ACTIVITIES_QUERY = "GET_RECENT_ACTIVITIES_QUERY";

export const getRecentActivitiesQueryKey = (tid: string, wid: string) => [
  GET_RECENT_ACTIVITIES_QUERY,
  tid,
  wid,
];

export const useGetRecentActivities = (tid: string, wid: string, options?: any) => {
  return useQueryWithWorkspacePermissionCheck(
    wid,
    "get_workspace",
    getRecentActivitiesQueryKey(tid, wid),
    withQueryError(async () => {
      const data = await adminStatsApi.getRecentActivities({ wid });
      return data?.data?.activities;
    }, "Error occurred while trying to fetch recent activities"),
    { ...options, cacheTime: 0 }
  );
};
