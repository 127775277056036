import React from "react";

import FormSection from "../../../../../common/components/FormSection";
import DurationField from "../../../../../common/utils/forms/DurationField";
import NumberField from "../../../../../common/utils/forms/NumberField";
import { validators } from "../../../../../common/utils/forms/validation";

export default function IdentityPoolOTPSettings() {
  return (
    <>
      <FormSection title="Activation OTP" id="otp-activation" small />

      <DurationField
        id="otp-settings-activation-ttl"
        name="otp_settings.activation.ttl"
        label="Time To Live"
        description="Defines the time-to-live period for One Time Passwords used for account activation. When such OTP expires, it can no longer be used to activate an account."
        rules={{
          validate: {
            inRangeDuration: validators.inRangeDuration({
              label: "Time To Live",
              min: "0h0m10s",
              max: "744h0m0s",
            }),
          },
        }}
        style={{ marginBottom: 16 }}
      />

      <NumberField
        id="activation-otp-length"
        name="otp_settings.activation.length"
        title="Length"
        description="Determines the length for One Time Passwords used for account activation."
        min={6}
        max={12}
      />

      <FormSection title="Reset Password OTP" id="otp-reset_password" small />

      <DurationField
        id="otp-settings-reset-password-ttl"
        name="otp_settings.reset_password.ttl"
        label="Time To Live"
        description="Defines the time-to-live period for One Time Passwords used for resetting user's password. When such OTP expires, the user can no longer use it for resetting their passwords."
        rules={{
          validate: {
            inRangeDuration: validators.inRangeDuration({
              label: "Time To Live",
              min: "0h0m10s",
              max: "744h0m0s",
            }),
          },
        }}
        style={{ marginBottom: 16 }}
      />

      <NumberField
        id="reset-password-otp-length"
        name="otp_settings.reset_password.length"
        title="Length"
        description="Determines the length for One Time Passwords used for resetting user's password."
        min={6}
        max={12}
      />

      <FormSection title="Challenge OTP" id="otp-challenge" small />

      <DurationField
        id="otp-settings-challenge-ttl"
        name="otp_settings.challenge.ttl"
        label="Time To Live"
        description="Defines the time-to-live period for One Time Passwords that are used for proof-of-possession challanges. When such OTP expires, it can be no longer used, for example, when providing help desk assistance, to authenticate the user, or to  verify user's address."
        rules={{
          validate: {
            inRangeDuration: validators.inRangeDuration({
              label: "Time To Live",
              min: "0h0m10s",
              max: "744h0m0s",
            }),
          },
        }}
        style={{ marginBottom: 16 }}
      />

      <NumberField
        id="challenge-otp-length"
        name="otp_settings.challenge.length"
        title="Length"
        description="Determines the length for One Time Passwords used for proof-of-possession challenges."
        min={6}
        max={12}
      />

      <FormSection title="Verify Address OTP" id="otp-verify_address" small />

      <DurationField
        id="otp-settings-verify-address-ttl"
        name="otp_settings.verify_address.ttl"
        label="Time To Live"
        description="Defines the time-to-live period for One Time Passwords used for verifying user's address. When such OTP expires, it can no longer be used to verify address."
        rules={{
          validate: {
            inRangeDuration: validators.inRangeDuration({
              label: "Time To Live",
              min: "0h0m10s",
              max: "744h0m0s",
            }),
          },
        }}
        style={{ marginBottom: 16 }}
      />

      <NumberField
        id="verify-address-otp-length"
        name="otp_settings.verify_address.length"
        title="Length"
        description="Determines the length for One Time Passwords used for verify address."
        min={6}
        max={12}
      />

      <FormSection title="Authentication OTP" id="otp-authentication" small />

      <DurationField
        id="otp-settings-authentication-ttl"
        name="otp_settings.authentication.ttl"
        label="Time To Live"
        description="Defines the time-to-live period for One Time Passwords that are used for authentication. When such OTP expires, it can no longer be used to authenticate the user."
        rules={{
          validate: {
            inRangeDuration: validators.inRangeDuration({
              label: "Time To Live",
              min: "0h0m10s",
              max: "744h0m0s",
            }),
          },
        }}
        style={{ marginBottom: 16 }}
      />

      <NumberField
        id="authentication-otp-length"
        name="otp_settings.authentication.length"
        title="Length"
        description="Determines the length for One Time Passwords used for authentication."
        min={6}
        max={12}
      />

      <FormSection title="Reset Authenticator OTP" id="reset-authenticator-otp" small />

      <DurationField
        id="otp-settings-reset-totp-ttl"
        name="otp_settings.reset_totp.ttl"
        label="Time To Live"
        description="Defines the time-to-live period for One Time Passwords used for resetting user's Authenticator secret. When such OTP expires, the user can no longer use it for resetting Authenticator."
        rules={{
          validate: {
            inRangeDuration: validators.inRangeDuration({
              label: "Time To Live",
              min: "0h0m10s",
              max: "744h0m0s",
            }),
          },
        }}
        style={{ marginBottom: 16 }}
      />

      <NumberField
        id="reset-totp-length"
        name="otp_settings.reset_totp.length"
        title="Length"
        description="Determines the length for One Time Passwords used for resetting user's Authenticator secret."
        min={6}
        max={12}
      />

      <FormSection title="Enroll Passkey OTP" id="enroll-passkey-otp" small />

      <DurationField
        id="otp-settings-enroll-webauthn-ttl"
        name="otp_settings.enroll_webauthn.ttl"
        label="Time To Live"
        description="Defines the time-to-live period for One Time Passwords used for enrolling additional user's passkey. When such OTP expires, the user can no longer use it for enrolling additional passkey."
        rules={{
          validate: {
            inRangeDuration: validators.inRangeDuration({
              label: "Time To Live",
              min: "0h0m10s",
              max: "744h0m0s",
            }),
          },
        }}
        style={{ marginBottom: 16 }}
      />

      <NumberField
        id="enroll-webauthn-length"
        name="otp_settings.enroll_webauthn.length"
        title="Length"
        description="Determines the length for One Time Passwords used for enrolling user's new passkey."
        min={6}
        max={12}
      />
    </>
  );
}
