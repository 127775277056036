import React, { CSSProperties, ReactNode } from "react";

import { makeStyles } from "tss-react/mui";

import Progress from "../../../common/components/Progress";

const useStyles = makeStyles()(theme => ({
  container: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
  },
}));

interface Props {
  progress?: boolean;
  children?: ReactNode;
  style?: CSSProperties;
}

export default function PageContainer({ progress, children, style = {} }: Props) {
  const { classes } = useStyles();
  return (
    <div className={classes.container} style={style}>
      {progress && <Progress isGlobalLoader />}

      {!progress && children}
    </div>
  );
}
