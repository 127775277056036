import { useState } from "react";

import { getFromLocalStorage, setInLocalStorage } from "../../common/utils/localStorage.utils";

export const ServicesLocalStorageKey = "services";

export enum ServiceCapability {
  ResourceServer = "resourceServer",
  ApiServer = "apiServer",
  OAuthClient = "oauthClient",
  TokenExchange = "tokenExchange",
  OAuthRAR = "oauthRAR",
}

type Capabilities = {
  [ServiceCapability.ResourceServer]: boolean;
  [ServiceCapability.OAuthRAR]: boolean;
  [ServiceCapability.ApiServer]: boolean;
  [ServiceCapability.OAuthClient]: boolean;
  [ServiceCapability.TokenExchange]: boolean;
};

export type ServiceCapabilitiesGlobalStoreContextType = {
  loadFromLocalStorage: (serviceId: string) => void;
  getCapabilities: (serviceId: string) => Capabilities;
  enable: (serviceId: string, capability: ServiceCapability) => void;
  disable: (serviceId: string, capability: ServiceCapability) => void;
};

const getAllStoredServices = () => JSON.parse(getFromLocalStorage(ServicesLocalStorageKey) || "{}");

const getServiceData = serviceID => getAllStoredServices()[serviceID] || {};
const getServiceCapabilities = serviceID => getAllStoredServices()[serviceID]?.capabilities || {};

export const useServiceCapabilitiesGlobalStore = (): ServiceCapabilitiesGlobalStoreContextType => {
  const [store, setStore] = useState<{
    capabilities: {
      [serviceId: string]: Capabilities;
    };
  }>({
    capabilities: {},
  });

  const loadFromLocalStorage = (serviceId: string) => {
    if (store.capabilities[serviceId]) {
      return;
    }

    const stored = getServiceCapabilities(serviceId);
    const capabilities: Capabilities = {
      [ServiceCapability.ResourceServer]: !!stored[ServiceCapability.ResourceServer],
      [ServiceCapability.OAuthRAR]: !!stored[ServiceCapability.OAuthRAR],
      [ServiceCapability.ApiServer]: !!stored[ServiceCapability.ApiServer],
      [ServiceCapability.OAuthClient]: !!stored[ServiceCapability.OAuthClient],
      [ServiceCapability.TokenExchange]: !!stored[ServiceCapability.TokenExchange],
    };

    setStore(store => ({ capabilities: { ...store.capabilities, [serviceId]: capabilities } }));
  };

  const getCapabilities = (serviceId: string): Capabilities => {
    return (
      store.capabilities[serviceId] ?? {
        [ServiceCapability.ResourceServer]: false,
        [ServiceCapability.OAuthRAR]: false,
        [ServiceCapability.ApiServer]: false,
        [ServiceCapability.OAuthClient]: false,
        [ServiceCapability.TokenExchange]: false,
      }
    );
  };

  const setCapabilities = (serviceId: string, capability: ServiceCapability, value: boolean) => {
    const allStoredCapabilities = getAllStoredServices();

    const serviceData = getServiceData(serviceId);
    const serviceCapabilities = getServiceCapabilities(serviceId);

    setInLocalStorage(
      ServicesLocalStorageKey,
      JSON.stringify({
        ...allStoredCapabilities,
        [serviceId]: {
          ...serviceData,
          capabilities: { ...serviceCapabilities, [capability]: value },
        },
      })
    );

    setStore(store => ({
      capabilities: {
        ...store.capabilities,
        [serviceId]: { ...store.capabilities[serviceId], [capability]: value },
      },
    }));
  };

  const enable = (serviceId: string, capability: ServiceCapability) => {
    setCapabilities(serviceId, capability, true);
  };

  const disable = (serviceId: string, capability: ServiceCapability) => {
    setCapabilities(serviceId, capability, false);
  };

  return {
    loadFromLocalStorage,
    getCapabilities,
    enable,
    disable,
  };
};
