import React, { useState } from "react";
import { useNavigate } from "react-router";

import { makeStyles } from "tss-react/mui";

import { PoolResponse, Schema } from "@cloudentity/acp-identity";

import usersIcon from "../../../../../assets/images/emptyStates/Users.svg";
import EmptyState from "../../../../../common/components/EmptyState";
import Progress from "../../../../../common/components/Progress";
import { getFromLocalStorage } from "../../../../../common/utils/localStorage.utils";
import { UseItemsReturnType } from "../../../common/EnhancedTableAsync/useItemsWithQuery";
import { usePoolRootUrl } from "../utils";
import IdentityPoolMenu from "./IdentityPoolMenu";
import IdentityPoolsCards from "./IdentityPoolsCards";
import IdentityPoolsListTable from "./IdentityPoolsListTable";
import IdentityPoolsToolbar from "./IdentityPoolsToolbar";
import { GRID_MODE_KEY, GridMode } from "./utils";

const useStyles = makeStyles()(() => ({
  content: {
    "#search-input-container > div": {
      width: 300,
    },
  },
}));

interface Props {
  data: UseItemsReturnType<PoolResponse>;
  schemas: Schema[];
  onCreate: (() => void) | undefined;
  onRemove: ((pool: PoolResponse) => void) | undefined;
}

function IdentityPoolsList({ data, schemas, onCreate, onRemove }: Props) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { rootUrl } = usePoolRootUrl();
  const [menu, setMenu] = useState<{ elementId: string; pool: PoolResponse }>();

  const defaultGridMode = (getFromLocalStorage(GRID_MODE_KEY) as GridMode) || "full-card";
  const [gridMode, setGridMode] = useState<GridMode>(defaultGridMode);

  const {
    data: pools,
    isFetching,
    page,
    onResetPage,
    onFiltersUpdate,
    filters,
    areFiltersApplied,
    searchText,
    firstPageLoading,
  } = data;

  const filteredPools = pools.sort((a, b) => ("" + a.name).localeCompare("" + b.name));

  const isEmpty =
    page === 0 && filteredPools.length === 0 && !areFiltersApplied && !searchText && !isFetching;

  if (firstPageLoading) {
    return <Progress />;
  }

  return (
    <>
      {isEmpty ? (
        <EmptyState
          title="User storage is not enabled"
          description="Add identity pool to support user storage, management and sign-in"
          buttonContent="+ Create pool"
          onSubmit={onCreate}
          img={usersIcon}
          imgSize={16}
          style={{ marginTop: 80 }}
        />
      ) : (
        <div className={classes.content}>
          <IdentityPoolsToolbar
            gridMode={gridMode}
            setGridMode={mode => {
              if (mode !== gridMode) {
                setGridMode(mode);
                onResetPage();
              }
            }}
            searchText={searchText}
            onCreate={onCreate}
            onChangeSearchText={searchText => onFiltersUpdate(searchText, filters)}
          />
          {gridMode === "full-card" && <IdentityPoolsCards data={data} onMenuOpen={setMenu} />}
          {gridMode === "table" && (
            <IdentityPoolsListTable data={data} schemas={schemas} setMenu={setMenu} />
          )}
        </div>
      )}

      {menu && (
        <IdentityPoolMenu
          pool={menu.pool}
          anchorEl={() => document.querySelector(`#${menu.elementId}`) ?? document.body}
          handleClose={() => setMenu(undefined)}
          handleGoToTab={(id: string, tab: string, section?: string) =>
            navigate(`${rootUrl}/${id}/${tab}`, { state: { section } })
          }
          onDelete={onRemove}
        />
      )}
    </>
  );
}
export default IdentityPoolsList;
