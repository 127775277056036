import React, { useEffect, useMemo } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Globe } from "react-feather";

import { ServerResponse } from "@cloudentity/acp-admin";
import { OIDCSettingsAuthenticationMethodEnum } from "@cloudentity/acp-root";

import FormAccordion from "../../../common/components/FormAccordion";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import CheckboxField from "../../../common/utils/forms/CheckboxField";
import DurationField from "../../../common/utils/forms/DurationField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import SelectField from "../../../common/utils/forms/SelectField";
import TextFieldReadOnly from "../../../common/utils/forms/TextFieldReadOnly";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import Instructions from "../common/Instructions";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "./amrOptions";
import { IdpUiModelOidcType, providers } from "./identities.utils";

const id = "identities-configuration-oidc";

const authenticationMethodOptions: {
  value: OIDCSettingsAuthenticationMethodEnum;
  name: string;
}[] = [
  {
    value: OIDCSettingsAuthenticationMethodEnum.ClientSecret,
    name: "Client Secret",
  },
  {
    value: OIDCSettingsAuthenticationMethodEnum.PrivateKeyJwt,
    name: "Private Key JWT",
  },
];

interface Props {
  provider: IdpUiModelOidcType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  customSubmit?: boolean;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelOidcType) => void;
  onDelete?: (idp: IdpUiModelOidcType) => void;
}

export default function IdentitiesConfigurationOIDC({
  provider,
  server,
  updateProgress,
  inEdit,
  onLogoEdit,
  customSubmit,
  onCancel,
  onSubmit,
  onInit,
  onDelete,
}: Props) {
  const data = useMemo(
    () => ({
      ...provider,
      settings: {
        ...provider.settings,
        scopes: provider.settings?.scopes ?? [],
        authentication_method:
          provider.settings?.authentication_method ||
          OIDCSettingsAuthenticationMethodEnum.ClientSecret,
      },
    }),
    [provider]
  );

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const form = useForm({
    id,
    initialValues: data,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const submitFn = (provider, data) => ({ ...provider, ...amrToResponse(data), ...data });

  const providerMapData = providers.find(p => p.method === "oidc");

  const authenticationMethod = form.watch("settings.authentication_method");

  useEffect(() => {
    onInit &&
      onInit(() => form.handleSubmit(data => onSubmit && onSubmit(submitFn(provider, data))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <Form form={form}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {!inEdit && providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32, width: "50%" }}
              />
            )}

            <CommonIdpConfigUpper
              provider={provider}
              inEdit={inEdit}
              onLogoEdit={onLogoEdit}
              onUpdate={onSubmit}
            />
            <TextFieldRequired
              name="settings.issuer_url"
              label="Issuer URL"
              defaultValue={provider.settings?.issuer_url ?? ""}
              rules={{ validate: { validURL: validators.validURL({ label: "Issuer URL" }) } }}
            />
            <TextFieldRequired
              name="settings.client_id"
              label="Client ID"
              defaultValue={provider.settings?.client_id ?? ""}
              withCopy
              inputProps={{ autoComplete: "off" }}
            />

            <SelectField
              name="settings.authentication_method"
              label="Authentication Method"
              defaultValue={provider.settings?.authentication_method ?? []}
              options={authenticationMethodOptions}
              optional={false}
            />

            {authenticationMethod === "client_secret" && (
              <TextFieldRequired
                name="credentials.client_secret"
                label="Client Secret"
                defaultValue={provider.credentials?.client_secret ?? ""}
                toggleVisibility
                defaultVisibility={false}
                withCopy
                inputProps={{ autoComplete: "off" }}
              />
            )}
            {authenticationMethod === "private_key_jwt" && (
              <>
                <TextFieldRequired
                  name="credentials.private_key_jwt.key"
                  label="Client Private Key"
                  rules={{
                    validate: { maxLength: () => true },
                  }}
                  minRows={5}
                  maxRows={5}
                  placeholder={"-----BEGIN RSA PRIVATE KEY-----\n-----END RSA PRIVATE KEY-----"}
                  helperText="PEM formatted private key used to sign client assertion"
                  multiline
                  withCopy={false}
                />

                <AutocompleteField
                  name="credentials.private_key_jwt.algorithm"
                  label="Algorithm"
                  defaultValue={provider.credentials?.private_key_jwt?.algorithm ?? []}
                  helperText="Used to sign the client assertion"
                  options={["RS256", "RS384", "RS512", "ES256", "ES384", "ES512"]}
                  optional={false}
                />

                <DurationField
                  name="credentials.private_key_jwt.exp"
                  label="Expires In"
                  description="Defines how long client assertion is valid for"
                />
              </>
            )}

            <TextFieldReadOnly name="redirectUrl" label="Redirect URL" withCopy />

            <CommonIdpConfig id={id} inEdit={inEdit} data={provider} />

            {inEdit && (
              <FormAccordion title="Advanced settings" id={id}>
                <AutocompleteField
                  name="settings.scopes"
                  label="Scopes"
                  options={[]}
                  freeSolo
                  multiple
                  optional={false}
                  helperText="To add multiple values, press the 'enter' key after adding each value"
                />
                <AutocompleteField
                  name="static_amr"
                  label="Authentication Method Reference"
                  helperText="If set overwrites AMR obtained from this authentication method"
                  defaultValue={provider.static_amr ?? []}
                  options={amrOptions.map(v => v.value)}
                  getOptionLabel={getAMRLabel}
                  multiple
                />
                <CheckboxField
                  name="settings.get_user_info"
                  label="Get user info"
                  style={{ marginBottom: 0 }}
                />
                <CheckboxField
                  name="settings.send_login_hint"
                  label={
                    <span>
                      Send <code>login_hint</code>
                    </span>
                  }
                  helperText={
                    <span>
                      Sends the entered identifier as a <code>login_hint</code> to the IDP
                    </span>
                  }
                />
                {serverSSOEnabled && <SSOIDPSettings />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={provider}
              onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {inEdit && (
          <Grid item xs={12} lg={5}>
            <Paper style={{ padding: 32 }}>
              {providerMapData && (
                <CardWithIconAndTitle
                  img={providerMapData.icon}
                  title={providerMapData.name}
                  id={providerMapData.name}
                  noBorder
                  style={{ marginBottom: 12 }}
                />
              )}
              <Instructions
                title="Instructions"
                text={
                  "To configure an OIDC connection, you need to enter your application's Issuer URL, Client ID and Client Secret, all of which should be available on your IDP's configuration page."
                }
                boxTitle="How to obtain the Issuer URL?"
                boxText={
                  "Enter the URL pointing to the app's OpenID discovery endpoint. For details, refer to the IDP app's configuration page."
                }
                boxLink="https://cloudentity.com/developers/howtos/identities/oidc/"
                boxIcon={<Globe />}
              />
            </Paper>
          </Grid>
        )}
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </Form>
  );
}
