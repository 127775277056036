import React from "react";

import SelectablePaper from "../../../../common/components/SelectablePaper";
import CardWithIconAndTitle from "../../common/CardWithIconAndTitle";
import { ProviderMapperType } from "../identities.utils";

interface Props {
  provider: ProviderMapperType;
  selected: ProviderMapperType | undefined;
  setSelected: (selected: ProviderMapperType | undefined) => void;
  subtitle?: string;
}

export default function IdentitiesCreateIdpCard({
  provider,
  selected,
  setSelected,
  subtitle,
}: Props) {
  return (
    <SelectablePaper
      selected={selected?.method === provider.method}
      onSelect={() => setSelected(provider)}
      selectable
      data-value={provider.method}
      style={{ width: 300 }}
    >
      <CardWithIconAndTitle
        img={provider.icon}
        title={provider.name}
        subtitle={subtitle || undefined}
        id={`idp-${provider.name.replace(/ /g, "-")}`}
        noBorder
        titleStyle={{ fontWeight: 600 }}
        subtitleStyle={subtitle ? { fontSize: 10, maxWidth: "calc(100% - 8px)" } : {}}
      />
    </SelectablePaper>
  );
}
