import * as React from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginLeft: 8,
  },
  spacing: {
    position: "absolute",
    width: 4,
    height: 4,
    background: theme.palette.primary.main,
    borderRadius: "50%",
    left: -12,
  },
  path: {
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  contentNotSelected: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 8,
  },
  pathNotSelected: {
    color: theme.palette.secondary.light,
    fontWeight: 400,
  },
}));

interface Props {
  selected: string;
  hasUnsavedChanges: boolean;
  paths: string[];
  onItemClick: (path: string) => void;
}

export default function FileBrowser({ selected, hasUnsavedChanges, paths, onItemClick }: Props) {
  const { classes } = useStyles();

  return (
    <List>
      {paths.map(path => (
        <ListItemButton dense={true} onClick={() => onItemClick(path)} key={path}>
          <ListItemText>
            {selected === path ? (
              <div className={classes.container}>
                <div className={classes.content}>
                  <div className={classes.spacing} />
                  <Typography className={classes.path}>
                    {path.replace("shared/", "")} {hasUnsavedChanges && "*"}{" "}
                  </Typography>
                </div>
              </div>
            ) : (
              <div className={classes.contentNotSelected}>
                <Typography className={classes.pathNotSelected}>
                  {path.replace("shared/", "")}
                </Typography>
              </div>
            )}
          </ListItemText>
        </ListItemButton>
      ))}
    </List>
  );
}
