import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { UserPlus } from "react-feather";

import {
  GrantTenantRoleRequestRoleEnum,
  GrantTenantRoleRequestTypeEnum,
} from "@cloudentity/acp-admin";
import {
  BaseNewUserPayloadStatusEnum,
  BaseUserWithData,
  NewUserIdentifierTypeEnum,
  NewUserVerifiableAddressStatusEnum,
  NewUserVerifiableAddressTypeEnum,
} from "@cloudentity/acp-identity";

import { BUILD_IN_ADMIN_POOL_ID } from "../admin/components/workspaceDirectory/administrator/AdministratorManagement";
import adminB2BUsersApi from "../admin/services/adminB2BUsersApi";
import identityUsersApi from "../admin/services/adminIdentityUsersApi";
import { getB2BUserQueryKey, listUsersQueryKey } from "../admin/services/adminIdentityUsersQuery";
import adminRolesApi from "../admin/services/adminRolesApi";
import { listTenantRoles, listUserRoles } from "../admin/services/adminRolesQuery";
import { getTenantId } from "../common/api/paths";
import Alert from "../common/components/Alert";
import Dialog from "../common/components/Dialog";
import Fab from "../common/components/Fab";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../common/components/notifications/notificationService";
import CheckboxField from "../common/utils/forms/CheckboxField";
import Form, { useForm } from "../common/utils/forms/Form";
import FormFooter from "../common/utils/forms/FormFooter";
import TextFieldRequired from "../common/utils/forms/TextFieldRequired";
import { validators } from "../common/utils/forms/validation";

const initialData = {
  email: "",
  given_name: "",
  family_name: "",
  addAnotherItem: false,
};

export default function AddB2BAdministratorDialog() {
  const [dialog, setDialog] = useState(false);
  const [identifierError, setIdentifierError] = useState("");
  const [progress, setProgress] = useState(false);

  const identityPoolId = BUILD_IN_ADMIN_POOL_ID;

  const queryClient = useQueryClient();

  const form = useForm({
    id: "administrator-create-user",
    initialValues: initialData,
    progress,
  });

  const onClose = () => {
    setDialog(false);
  };

  const handleCreate = (data: typeof initialData) => {
    setProgress(true);

    let createdUser: BaseUserWithData;

    return adminB2BUsersApi
      .createB2BUser({
        ipID: identityPoolId,
        newUser: {
          payload: {
            given_name: data.given_name.trim() as any,
            family_name: data.family_name.trim() as any,
          },
          status: BaseNewUserPayloadStatusEnum.New,
          credentials: [],
          identifiers: [
            {
              identifier: data.email,
              type: NewUserIdentifierTypeEnum.Email,
            },
          ],
          verifiable_addresses: [
            {
              address: data.email,
              status: NewUserVerifiableAddressStatusEnum.Active,
              type: NewUserVerifiableAddressTypeEnum.Email,
              verified: false,
            },
          ],
        },
      })
      .then(res => {
        createdUser = res.data;
        queryClient.setQueryData(getB2BUserQueryKey(getTenantId(), res.data.id), res.data);
      })
      .then(() =>
        identityUsersApi
          .sendActivationMessage({
            ipID: identityPoolId,
            userID: createdUser.id!,
          })
          .then(() =>
            notifySuccess(
              <span>
                Invite sent to: <strong>{data.email}</strong>
              </span>
            )
          )
          .catch(notifyErrorOrDefaultTo("Error occurred when trying to send activation message"))
      )
      .then(() =>
        adminRolesApi
          .grantTenantRole({
            request: {
              role: GrantTenantRoleRequestRoleEnum.BusinessAdmin,
              type: GrantTenantRoleRequestTypeEnum.IdentityPoolUser,
              identity_pool_id: createdUser.user_pool_id,
              identity_pool_user_id: createdUser.id,
              tenant_id: getTenantId(),
            },
          })
          .then(() => queryClient.invalidateQueries({ queryKey: listTenantRoles() }))
          .then(() =>
            queryClient.invalidateQueries({
              queryKey: listUserRoles(createdUser.user_pool_id, createdUser.id),
            })
          )
          .catch(notifyErrorOrDefaultTo("Error occurred when trying to grant tenant role"))
      )
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listUsersQueryKey(getTenantId(), identityPoolId),
        })
      )
      .then(() => {
        if (data.addAnotherItem) {
          form.reset(initialData);
        } else {
          onClose();
        }
      })
      .catch(err => {
        if (err?.response?.status === 409) {
          setIdentifierError("Identifier is already registered");
          return err;
        }

        return notifyErrorOrDefaultTo("Error occurred when trying to create user")(err);
      })
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <>
      <Fab onClick={() => setDialog(true)} id="manage-access-add-identity-pool-user-button">
        <UserPlus size={16} strokeWidth={3} style={{ marginRight: 8 }} />
        Invite Admin
      </Fab>
      <Dialog
        id="b2b-administrator-create-user"
        open={dialog}
        onClose={onClose}
        title="Invite business administrator"
      >
        <Form form={form}>
          <TextFieldRequired
            name="email"
            label="Email"
            rules={{
              validate: {
                validEmail: validators.validEmail({ label: "Email" }),
              },
            }}
            externalErrors={identifierError}
            onChange={() => setIdentifierError("")}
            inputProps={{ autoComplete: "off" }}
            autoFocus
          />
          <TextFieldRequired name="given_name" label="First name" />
          <TextFieldRequired name="family_name" label="Last name" />

          <Alert severity="info">
            Business administrators can onboard new users and manage all organizations
          </Alert>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div>
              <CheckboxField
                name="addAnotherItem"
                label="Invite another user"
                style={{ marginBottom: 0 }}
              />
            </div>

            <FormFooter submitText="Invite" onCancel={onClose} onSubmit={handleCreate} />
          </div>
        </Form>
      </Dialog>
    </>
  );
}
