export const inputWithButtonStyles = {
  "& > div": {
    borderRadius: "4px 0 0 4px",
    height: 48,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A0A3B5",
  },
  "& .MuiOutlinedInput-input": {
    padding: "15px 14px",
  },
  "& .MuiOutlinedInput-adornedEnd": {
    paddingRight: 2,
  },
  "& .MuiInputAdornment-root": {
    opacity: 0.9,
  },
};
