import React, { useEffect, useMemo, useState } from "react";

import uniq from "lodash/uniq";

import { getTenantId } from "../../../../common/api/paths";
import AutocompleteField from "../../../../common/utils/forms/AutocompleteField";
import { useFormContext } from "../../../../common/utils/forms/Form";
import { useListIDPs } from "../../../services/adminIDPsQuery";
import { useListPools } from "../../../services/adminIdentityPoolsQuery";
import { useListSchemas } from "../../../services/adminIdentitySchemasQuery";
import { useWorkspace } from "../../common/useWorkspace";
import { getAllPaths } from "../../workspaceDirectory/identityPools/schemas/schemas.utils";

export default function OAuthClaimsCreateIdentityPoolType() {
  const [workspace] = useWorkspace();
  const tenantId = getTenantId();
  const { form } = useFormContext();
  const sourcePath = form.watch("source_path");
  const listIDPsQuery = useListIDPs(tenantId, workspace);
  const [metadataProperties, setMetadataProperties] = useState<string[]>([]);
  const [payloadProperties, setPayloadProperties] = useState<string[]>([]);

  const allPoolsIds = useMemo(() => {
    const poolsIds =
      listIDPsQuery.data?.idps
        ?.map(idp => {
          if (idp.method === "identity_pool") {
            return idp.identity_pool_id;
          }
          if (idp.jit?.provisioning?.pool_id) {
            return idp.jit?.provisioning?.pool_id;
          }
          return undefined;
        })
        .filter((v): v is string => !!v) ?? [];

    return uniq(poolsIds);
  }, [listIDPsQuery.data]);

  const identityPoolsQuery = useListPools({ tid: tenantId, limit: 100 });
  const schemasQuery = useListSchemas(tenantId);

  const pools = useMemo(() => identityPoolsQuery.data?.pools ?? [], [identityPoolsQuery.data]);
  const schemas = useMemo(() => schemasQuery.data?.schemas ?? [], [schemasQuery.data]);

  useEffect(() => {
    const filteredPools = pools.filter(pool => allPoolsIds.includes(pool.id ?? ""));

    if (filteredPools.length && schemas.length) {
      let metadataSchemasIds: string[] = [];
      let payloadSchemasIds: string[] = [];

      filteredPools.forEach(({ metadata_schema_id, payload_schema_id }) => {
        if (metadata_schema_id) {
          metadataSchemasIds.push(metadata_schema_id);
        }
        if (payload_schema_id) {
          payloadSchemasIds.push(payload_schema_id);
        }
      });

      const uniqMetadataSchemasIds = uniq(metadataSchemasIds);
      const uniqPayloadSchemasIds = uniq(payloadSchemasIds);

      setMetadataProperties(
        uniq(
          uniqMetadataSchemasIds.flatMap(id => {
            const schema = schemas.find(schema => schema.id === id);
            return schema ? getAllPaths(schema.schema) : [];
          })
        )
      );

      setPayloadProperties(
        uniq(
          uniqPayloadSchemasIds.flatMap(id => {
            const schema = schemas.find(schema => schema.id === id);
            return schema ? getAllPaths(schema.schema) : [];
          })
        )
      );
    }
  }, [allPoolsIds, pools, schemas]);

  return (
    <AutocompleteField
      name="metadata_key"
      label={`${sourcePath?.description ?? "Source"} key`}
      options={sourcePath?.name === "metadata" ? metadataProperties : payloadProperties}
      defaultValue=""
      freeSolo
      autoHighlight={false}
      autoSelect
    />
  );
}
