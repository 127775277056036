import React from "react";

import { getTenantId } from "../../../common/api/paths";
import Dialog from "../../../common/components/Dialog";
import Progress from "../../../common/components/Progress";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { useAuthorizationServerMutation } from "../../services/adminServersMutations";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";

interface Props {
  serverID: string;
  onClose: () => void;
}

export default function EditWorkspaceDialog({ serverID, onClose }: Props) {
  const serverQuery = useGetAuthorizationServer(getTenantId(), serverID);
  const serverMutation = useAuthorizationServerMutation({ wid: serverID });

  const form = useForm({
    id: "edit-workspace",
    initialValues: serverQuery.data,
    progress: serverMutation.isPending,
  });

  const handleUpdate = data => {
    const d = { ...serverQuery.data, ...data };

    serverMutation
      .mutateAsync({ wid: serverID, server: d })
      .then(() => {
        notifySuccess("Workspace settings saved successfully");
        onClose();
      })
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to update workspace settings"));
  };

  return (
    <Dialog onClose={onClose} id="edit-workspace-dialog" title="General Settings">
      <Form form={form}>
        {serverQuery.isLoading ? (
          <div style={{ height: 169 }}>
            <Progress />
          </div>
        ) : (
          <>
            <TextFieldRequired name="name" label="Workspace name" />
            <FormFooter onCancel={onClose} onSubmit={handleUpdate} />
          </>
        )}
      </Form>
    </Dialog>
  );
}
