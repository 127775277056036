import { useQuery } from "@tanstack/react-query";

import { Images, ImagesApiSearchImagesRequest } from "@cloudentity/acp-admin";

import adminImagesApi from "./adminImagesApi";

const SEARCH_IMAGES_QUERY = "SEARCH_IMAGES_QUERY";

export const getSearchImagesQueryKey = q => [SEARCH_IMAGES_QUERY, q];

export const useSearchImages = (req: ImagesApiSearchImagesRequest, options?) =>
  useQuery<Images>({
    queryKey: getSearchImagesQueryKey(req.q),
    queryFn: async () => {
      const data = await adminImagesApi.searchImages(req);
      return data.data;
    },
    ...options,
  });
