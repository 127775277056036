import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router";

import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { CornerDownLeft } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { ServerResponseProfileEnum } from "@cloudentity/acp-admin";

import { portalMode } from "../../../common/api/paths";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { useGetWorkspace } from "../../services/adminServersQuery";
import { useOrganization } from "../common/useOrganization";
import { useWorkspace } from "../common/useWorkspace";
import WorkspaceAvatar from "./WorkspaceAvatar";

const useStyles = makeStyles<{ isButtonClickable: boolean }>()((_, { isButtonClickable }) => ({
  backSelect: {
    position: "relative",
    minHeight: 64,
    width: "100%",
    whiteSpace: "nowrap",
    paddingLeft: 12,
    "&:hover": {
      cursor: isButtonClickable ? "pointer" : "default",
      backgroundColor: isButtonClickable ? "rgba(0, 0, 0, 0.04)" : "white",
    },
  },
  backContent: {
    marginLeft: 16,
    width: "calc(100% - 40px)",
  },
  workspaceName: {
    color: "#A0A3B5",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

interface Props {
  isOpen: boolean;
  navigateToUrl?: string;
}

export default function BackToWorkspaceNavItem({ isOpen, navigateToUrl = "overview" }: Props) {
  const navigate = useNavigate();

  const location = useLocation();
  const pathname = location.pathname;
  const state = location.state as { backToWorkspace: string } | undefined;
  const [workspace] = useWorkspace();
  const [organizationId] = useOrganization();
  const isB2BPortal = portalMode === "b2b";

  const currentWorkspace = isB2BPortal ? organizationId : workspace;
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(currentWorkspace);

  const backToWorkspace = useRef(state?.backToWorkspace);

  const getWorkspaceQuery = useGetWorkspace(currentWorkspace, {
    enabled: !!checkWorkspacePermissionsQuery.data?.discover_workspace,
  });
  const workspaceExists = getWorkspaceQuery.isSuccess;
  const workspaceObj = getWorkspaceQuery.data;

  const isButtonLoading = getWorkspaceQuery.isLoading;
  const isButtonClickable = !isButtonLoading && workspaceExists;

  const { classes } = useStyles({ isButtonClickable });
  const link = isB2BPortal
    ? `${organizationId}/${navigateToUrl}`
    : `/${workspace}/${navigateToUrl}`;

  return (
    <ListItem
      onClick={() => {
        if (isButtonClickable)
          navigate(backToWorkspace.current || link, {
            state: {
              from: pathname,
            },
          });
      }}
      className={classes.backSelect}
      id="back-to-workspace-button"
    >
      {isButtonLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CircularProgress size={24} />
        </div>
      )}
      {isOpen && !isButtonLoading && workspaceExists && (
        <>
          <CornerDownLeft />
          <div className={classes.backContent}>
            <Typography>Back</Typography>
            <Typography className={classes.workspaceName}>
              {workspaceObj?.name || workspaceObj?.id || workspace}
            </Typography>
          </div>
        </>
      )}
      {!isOpen && !isButtonLoading && workspaceExists && (
        <WorkspaceAvatar server={workspaceObj} size="small" />
      )}
      {!isButtonLoading &&
        !workspaceExists &&
        ((isOpen && (
          <Typography
            component="div"
            style={{ color: "#A0A3B5", textAlign: "center", width: "100%" }}
          >
            No current {isB2BPortal ? "organization" : "workspace"}
          </Typography>
        )) || (
          <WorkspaceAvatar server={{ profile: ServerResponseProfileEnum.Default, name: "" }} />
        ))}
    </ListItem>
  );
}
