import { UsersApiFactory } from "@cloudentity/acp-identityself";
import { ClientsApiFactory, EnvironmentApiFactory } from "@cloudentity/acp-public";

import { getConfiguration } from "../../common/api/configuration";
import {
  acpBaseURI,
  apiPrefix,
  identityselfPathPrefix,
  userApiPrefix,
} from "../../common/api/paths";
import axiosInstance from "../userAxiosInstance";

const api = () => {
  const basePath = `${acpBaseURI}${apiPrefix}`;

  return ClientsApiFactory(getConfiguration(basePath), basePath, axiosInstance);
};

const identitySelfApi = () => {
  const basePath = `${acpBaseURI}${identityselfPathPrefix}`;

  return UsersApiFactory(getConfiguration(basePath), basePath, axiosInstance);
};

const environmentApi = () => {
  const basePath = `${acpBaseURI}${userApiPrefix}`;

  return EnvironmentApiFactory(getConfiguration(basePath), basePath, axiosInstance);
};

const userApi = {
  getClients: () => api().listClientsWithAccess(),
  revokeClient: ({ cid }) => api().revokeClientAccess(cid),
  changePassword: (oldPassword: string, newPassword: string) =>
    identitySelfApi().changePassword("", { old_password: oldPassword, new_password: newPassword }),
  getPublicEnvironment: () => environmentApi().getPublicEnvironment(),
};

export default userApi;
