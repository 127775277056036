import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";

import { ServerResponse } from "@cloudentity/acp-admin";
import { PoolAuthenticationMechanismsEnum, PoolResponse } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import Progress from "../../../../common/components/Progress";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import { useListIDPs } from "../../../services/adminIDPsQuery";
import {
  useListPoolsInfinite,
  useListWorkspacePoolsInfinite,
} from "../../../services/adminIdentityPoolsQuery";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import { amrToResponse } from "../amrOptions";
import { IdpUiModelType } from "../identities.utils";
import IdentitiesConfigurationIdentityPoolDetail from "./IdentitiesConfigurationIdentityPoolDetail";
import IdentitiesConfigurationIdentityPoolSelectExisting from "./IdentitiesConfigurationIdentityPoolSelectExisting";

const id = "identities-configuration-identity-pool";

interface Props {
  provider: IdpUiModelType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  customSubmit?: boolean;
  workspaceId: string | undefined;
  workspaceIdForNewPool?: string;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelType) => void;
  onDelete?: (idp: IdpUiModelType) => void;
}

export default function IdentitiesConfigurationIdentityPool({
  provider,
  server,
  updateProgress,
  inEdit, // in IDP detail view
  customSubmit, // in workspace wizard
  workspaceId,
  workspaceIdForNewPool,
  onCancel,
  onSubmit,
  onInit,
  onDelete,
}: Props) {
  const tenantId = getTenantId();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspaceId);

  const form = useForm({
    id,
    initialValues: provider as IdpUiModelType & { method: any },
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const submitFn = (provider, data) => ({
    ...provider,
    ...amrToResponse(data),
    ...data,
    ...(!inEdit && selected
      ? {
          name: selected.name,
          identity_pool_id: selected.id,
          authentication_mechanisms: [PoolAuthenticationMechanismsEnum.Password],
          identifier_case_insensitive: true,
        }
      : {}),
  });

  const [selected, setSelected] = useState<PoolResponse | null>(null);

  const listIDPsQuery = useListIDPs(tenantId, workspaceId);
  const idps = listIDPsQuery.data?.idps ?? [];

  const poolIDsWithConnectedIDPs = idps
    .filter(idp => idp.method === "identity_pool")
    .map(idp => idp.identity_pool_id)
    .filter(v => v);

  const listWorkspacePoolInfiniteQuery = useListWorkspacePoolsInfinite({
    wid: workspaceId ?? "",
    sort: "asc",
    order: "name",
  });

  const listTenantPoolsInfiniteQuery = useListPoolsInfinite(
    { tid: getTenantId() },
    {
      enabled:
        listWorkspacePoolInfiniteQuery.isFetched &&
        !listWorkspacePoolInfiniteQuery.hasNextPage &&
        !listWorkspacePoolInfiniteQuery.isFetchingNextPage,
    }
  );

  const sortNotConnectedFirst = (p1: PoolResponse, p2: PoolResponse) =>
    !poolIDsWithConnectedIDPs.includes(p1.id) && poolIDsWithConnectedIDPs.includes(p2.id) ? -1 : 1;
  const allPools = [
    ...(listWorkspacePoolInfiniteQuery.data?.pages.flatMap(p => p.pools || []) ?? []).sort(
      sortNotConnectedFirst
    ),
    ...(listTenantPoolsInfiniteQuery.data?.pages.flatMap(p => p.pools || []) ?? [])
      .filter(pool => (workspaceId === "admin" ? true : !pool.system))
      .sort(sortNotConnectedFirst),
  ];

  useEffect(() => {
    onInit &&
      onInit(() => form.handleSubmit(data => onSubmit && onSubmit(submitFn(provider, data))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (allPools.length > 0 && !selected) {
      const notConnected = (allPools || []).filter(p => !poolIDsWithConnectedIDPs.includes(p?.id));
      setSelected(notConnected.length > 1 ? notConnected[0]! : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPools, idps]);

  const progress = listIDPsQuery.isLoading || listWorkspacePoolInfiniteQuery.isLoading;

  return (
    <Form form={form} noFormTag>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {progress ? (
            <Progress />
          ) : (
            <div style={{ padding: 8 }}>
              {!inEdit && (
                <IdentitiesConfigurationIdentityPoolSelectExisting
                  pools={allPools}
                  poolIDsWithConnectedIDPs={poolIDsWithConnectedIDPs}
                  selected={selected}
                  setSelected={setSelected}
                  onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
                  onCancel={onCancel}
                  customSubmit={!!customSubmit}
                  workspaceIdForNewPool={workspaceIdForNewPool}
                />
              )}

              {inEdit && ( // IDP detail view
                <IdentitiesConfigurationIdentityPoolDetail
                  provider={provider}
                  server={server}
                  updateProgress={updateProgress}
                  pools={allPools}
                  onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
                  onDelete={onDelete}
                  onCancel={onCancel}
                  inEdit
                />
              )}
            </div>
          )}
        </Grid>
      </Grid>
      {inEdit && <RouteLeavingGuard />}
    </Form>
  );
}
