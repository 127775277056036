import React from "react";

import TabsMUI, { TabsProps } from "@mui/material/Tabs";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  tabs: {
    "& .MuiTab-root": {
      padding: 0,
      minWidth: "unset",
      marginRight: 32,
    },
  },
}));

const Tabs = ({ children, ...props }: TabsProps) => {
  const { classes } = useStyles();

  return (
    <TabsMUI className={classes.tabs} {...props}>
      {children}
    </TabsMUI>
  );
};

export default Tabs;
