import React, { useState } from "react";

import Button from "@mui/material/Button";
import { Plus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";

import Tooltip from "../../../common/Tooltip";
import AddIdentityPoolDialog from "../../administrator/AddIdentityPoolDialog";
import AddTeamDialog from "../../administrator/AddTeamDialog";

const useStyles = makeStyles()(() => ({
  button: {
    padding: 3,
    minWidth: "unset",
    marginLeft: 10,
    marginRight: 4,
    borderColor: "transparent",
    boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.31), 0px 1px 1px 0px rgba(0, 0, 0, 0.08)",
    background: "linear-gradient(270deg, #F2F5FF 0%, #FFF 100%))",
  },
}));

interface Props {
  hasPermissions: boolean;
  onCreated: (pool: PoolResponse) => void;
  workspaceId: string;
  mode: "admin" | "b2b";
}

export default function IdentityPoolCreateAnotherPoolButton({
  hasPermissions,
  onCreated,
  workspaceId,
  mode,
}: Props) {
  const { classes } = useStyles();
  const [createIdentityPoolDialog, setCreateIdentityPoolDialog] = useState(false);

  if (!hasPermissions) return null;

  return (
    <>
      <Tooltip
        title={
          <div style={{ padding: 8 }}>
            <b> Create another user population</b>
            <p>
              Arrange users into populations to logically separate identity sets (e.g. customers,
              merchants, users) and set different sign-in requirements.
            </p>
          </div>
        }
      >
        <Button
          variant="outlined"
          className={classes.button}
          onClick={() => setCreateIdentityPoolDialog(true)}
        >
          <Plus size={18} />
        </Button>
      </Tooltip>

      {createIdentityPoolDialog && mode === "admin" && (
        <AddIdentityPoolDialog
          onCreated={onCreated}
          onClose={() => setCreateIdentityPoolDialog(false)}
          workspaceId={workspaceId}
        />
      )}
      {createIdentityPoolDialog && mode === "b2b" && (
        <AddTeamDialog
          onCreated={onCreated}
          onClose={() => setCreateIdentityPoolDialog(false)}
          workspaceId={workspaceId}
        />
      )}
    </>
  );
}
