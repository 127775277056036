import React, { useEffect, useRef } from "react";

import { makeStyles } from "tss-react/mui";

import { AuthenticationContextAttribute, Claim } from "@cloudentity/acp-admin";

import AutocompleteField from "../../../../common/utils/forms/AutocompleteField";
import { useFormContext } from "../../../../common/utils/forms/Form";
import SelectField from "../../../../common/utils/forms/SelectField";
import TextField from "../../../../common/utils/forms/TextField";
import ClaimWorkspaceSourceType from "./ClaimWorkspaceSourceType";
import OAuthClaimsCreateIdentityPoolType from "./OAuthClaimsCreateIdentityPoolType";

const riskCtxOptions = [
  {
    name: "loa",
    description: "Level of assurance",
  },
  {
    name: "dbfp_fingerprint",
    description: "Fingerprint",
  },
  {
    name: "id",
    description: "Transaction ID",
  },
];

export const clientAttributesOptions = [
  {
    description: "Tenant ID",
    name: "tenant_id",
  },
  {
    description: "Server ID",
    name: "authorization_server_id",
  },
  {
    description: "ID",
    name: "client_id",
  },
  {
    description: "Name",
    name: "client_name",
  },
  {
    description: "Description",
    name: "description",
  },
  {
    description: "Application type",
    name: "application_type",
  },
  {
    description: "Redirect URIs",
    name: "redirect_uris",
  },
  {
    description: "Grant types",
    name: "grant_types",
  },
  {
    description: "Scopes",
    name: "scopes",
  },
  {
    description: "Audience",
    name: "audience",
  },
  {
    description: "Token endpoint authn method",
    name: "token_endpoint_auth_method",
  },
  {
    description: "Subject type",
    name: "subject_type",
  },
  {
    description: "Client URI",
    name: "client_uri",
  },
  {
    description: "System",
    name: "system",
  },
  {
    description: "Trusted",
    name: "trusted",
  },
  {
    name: "metadata",
    description: "Metadata",
  },
  {
    name: "developer_metadata",
    description: "Developer metadata",
  },
];

const workspaceAttributesOptions = [
  {
    name: "metadata",
    description: "Metadata",
  },
];

const identityPoolAttributesOptions = [
  {
    name: "metadata",
    description: "Metadata",
  },
  {
    name: "payload",
    description: "Payload",
  },
];

const organizationAttributesOptions = [
  {
    name: "id",
    description: "ID",
  },
  {
    name: "name",
    description: "Name",
  },
  {
    name: "description",
    description: "Description",
  },
  {
    name: "parent_id",
    description: "Parent ID",
  },
  {
    name: "metadata",
    description: "Metadata",
  },
];

export const sourceTypeOptions = [
  {
    name: "AuthN Context",
    value: "authnCtx",
  },
  {
    name: "Risk Context",
    value: "riskCtx",
  },
  {
    name: "Client",
    value: "client",
  },
  {
    name: "Workspace",
    value: "workspace",
  },
  {
    name: "Identity Pool",
    value: "identityPool",
  },
  {
    name: "Organization",
    value: "organization",
  },
];

export function PathOptions(source, authnCtxOptions) {
  if (source === "authnCtx") {
    return authnCtxOptions;
  } else if (source === "riskCtx") {
    return riskCtxOptions;
  } else if (source === "client") {
    return clientAttributesOptions;
  } else if (source === "identityPool") {
    return identityPoolAttributesOptions;
  } else if (source === "organization") {
    return organizationAttributesOptions;
  } else {
    return workspaceAttributesOptions;
  }
}

export function prepareClaimForUpdate(claim: Claim) {
  const firstDotIndex = claim.source_path?.indexOf(".");
  const [sourcePath, metadataKey] =
    firstDotIndex === -1
      ? [claim.source_path]
      : [
          claim.source_path?.slice(0, firstDotIndex),
          claim.source_path?.slice((firstDotIndex || 0) + 1),
        ];
  return {
    ...claim,
    source_path: sourcePath,
    metadata_key: metadataKey,
  };
}

export function getSourcePathWithMetadataKey(sourceType, sourcePath, metadataKey) {
  const sourcePathName = typeof sourcePath === "string" ? sourcePath : sourcePath?.name;
  const output = metadataKey ? `${sourcePathName}.${metadataKey}` : sourcePathName;
  return sourceType === "identityPool" ? `user.${output}` : output;
}

export function SourcePath({ options }: { options: AuthenticationContextAttribute[] }) {
  const { classes } = useCommonStyles();
  const { form } = useFormContext();

  const firstRender = useRef(true);
  const sourceType = form.watch("source_type");
  const sourcePath = form.watch("source_path");
  const metadataKey = form.watch("metadata_key");
  const metadataSourcePaths = ["metadata", "developer_metadata", "payload"];
  const isMetadataKeyFieldVisible =
    sourcePath &&
    sourcePath.name &&
    sourcePath.description &&
    metadataSourcePaths.includes(sourcePath.name);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    form.setValue("metadata_key", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcePath]);

  return (
    <>
      <AutocompleteField
        name="source_path"
        label="Source path"
        rules={{
          required: "Source path is required",
        }}
        options={options}
        getOptionLabel={option => (typeof option === "string" ? option : option.description ?? "")}
        freeSolo
        forcePopupIcon
      />

      {sourceType !== "identityPool" && sourceType !== "workspace" && (
        <TextField
          name="metadata_key"
          label={isMetadataKeyFieldVisible ? `${sourcePath.description} key` : ""}
          style={{ display: isMetadataKeyFieldVisible ? "block" : "none" }}
        />
      )}

      {sourceType === "identityPool" && <OAuthClaimsCreateIdentityPoolType />}

      {sourceType === "workspace" && <ClaimWorkspaceSourceType />}

      {sourcePath ? (
        <div className={classes.pathContainer}>
          Output source path:{" "}
          <b className={classes.pathInfo}>
            {getSourcePathWithMetadataKey(sourceType, sourcePath, metadataKey)}
          </b>
        </div>
      ) : null}
    </>
  );
}

export function SourceType({ options: sourceOptions, authnCtxOptions, setPaths }) {
  const { form } = useFormContext();

  return (
    <>
      <SelectField
        name="source_type"
        label="Source type"
        options={sourceOptions}
        optional={false}
        onChange={val => {
          const options = PathOptions(val, authnCtxOptions);
          setPaths(options);
          form.setValue("source_path", options[0]);
        }}
      />
    </>
  );
}

export type ClaimType = "id_token" | "id_token_verified_claim" | "access_token" | "saml_assertion";

export const useCommonStyles = makeStyles()(theme => ({
  chip: {
    borderRadius: 4,
    marginRight: 4,
    marginBottom: 4,
    color: theme.palette.secondary.light,
  },
  pathContainer: {
    color: "gray",
    marginBottom: 32,
    fontWeight: 500,
    fontSize: 13,
  },
  pathInfo: {
    color: theme.palette.secondary.dark,
  },
  warning: {
    backgroundColor: "#ff980012",
    border: `solid 1px ${theme.palette.warning.main}`,
    cursor: "default",
    "&:hover": {
      backgroundColor: "#ff980012 !important",
    },
  },
}));
