import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import { ExternalLink, Info } from "react-feather";
import { makeStyles } from "tss-react/mui";

import Menu from "../../../common/components/Menu";
import { openUrlInNewTab } from "../../../common/utils/browser.utils";

const useStyles = makeStyles()(theme => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    "& > span": {
      marginRight: 8,
    },
  },
  button: {
    color: theme.palette.secondary.dark,
  },
}));

interface Props {
  link: string;
  label?: string;
}

export default function InfoIconWithLink({ link, label }: Props) {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        id="secrets-header-more-button"
        onClick={e => {
          setAnchorEl(e.currentTarget);
        }}
        className={classes.button}
      >
        <Info size={18} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        items={[
          {
            onClick: () => {
              openUrlInNewTab(link);
              handleClose();
            },
            label: (
              <div className={classes.flexContainer}>
                <span>{label || "Developer documentation"}</span>
                <ExternalLink size={16} />
              </div>
            ),
            id: "docs-link",
          },
        ]}
        dark
      />
    </>
  );
}
