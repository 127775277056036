import React, { useRef } from "react";

import { OrganizationResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import {
  useInfiniteScrollForItemsWithQuery,
  UseItemsReturnType,
} from "../../admin/components/common/EnhancedTableAsync/useItemsWithQuery";
import AutocompleteField from "../../common/utils/forms/AutocompleteField";

interface Props {
  templates: { name?: string | undefined; id?: string | undefined }[];
  data: UseItemsReturnType<OrganizationResponse | WorkspaceResponse>;
  disableClearable?: boolean;
}

export default function TemplatesSelect({ templates, data, disableClearable = false }: Props) {
  const ref = useRef<HTMLUListElement>();
  const { onScroll } = useInfiniteScrollForItemsWithQuery({
    getElement: () => ref.current,
    onLastPage: data.onLastPage,
  });

  return templates.length > 0 ? (
    <AutocompleteField
      name="template"
      label="Template"
      options={templates}
      placeholder="None"
      disableClearable={disableClearable}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={o => (!!o && typeof o === "object" ? o?.name : o) || ""}
      optional={false}
      blurOnSelect
      ListboxProps={{ ref, onScroll } as any}
    />
  ) : null;
}
