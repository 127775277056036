import {
  ServersApi,
  ServersApiBindServerRequest,
  ServersApiBindServerThemeRequest,
  ServersApiCreateAuthorizationServerRequest,
  ServersApiDeleteAuthorizationServerRequest,
  ServersApiGetAuthorizationServerRequest,
  ServersApiGetCIBAAuthenticationServiceRequest,
  ServersApiGetServerConsentRequest,
  ServersApiListAuthorizationServersRequest,
  ServersApiListServersBindingsRequest,
  ServersApiSetCIBAAuthenticationServiceRequest,
  ServersApiSetServerConsentRequest,
  ServersApiUnbindServerRequest,
  ServersApiUnbindServerThemeRequest,
  ServersApiUpdateAuthorizationServerRequest,
  WorkspacesApi,
  WorkspacesApiGetWorkspaceRequest,
  WorkspacesApiListUserWorkspacesRequest,
  WorkspacesApiListWorkspacesRequest,
  WorkspacesApiUpdateWorkspaceMetadataRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => ServersApi = adminApiClazz(ServersApi);
const apiWorkspaces: () => WorkspacesApi = adminApiClazz(WorkspacesApi);

const adminServersApi = {
  listAuthorizationServers: (req: ServersApiListAuthorizationServersRequest) =>
    api().listAuthorizationServers(req),
  createServer: (req: ServersApiCreateAuthorizationServerRequest) =>
    api().createAuthorizationServer(req),
  removeServer: (req: ServersApiDeleteAuthorizationServerRequest) =>
    api().deleteAuthorizationServer(req),

  bindServer: (req: ServersApiBindServerRequest) => api().bindServer(req),
  unbindServer: (req: ServersApiUnbindServerRequest) => api().unbindServer(req),
  listServersBindings: (req: ServersApiListServersBindingsRequest) =>
    api().listServersBindings(req),

  getAuthorizationServer: (req: ServersApiGetAuthorizationServerRequest) =>
    api().getAuthorizationServer(req),
  updateAuthorizationServer: (req: ServersApiUpdateAuthorizationServerRequest) =>
    api().updateAuthorizationServer(req),
  getServerConsent: (req: ServersApiGetServerConsentRequest) => api().getServerConsent(req),
  updateServerConsent: (req: ServersApiSetServerConsentRequest) => api().setServerConsent(req),

  getCIBAAuthenticationService: (req: ServersApiGetCIBAAuthenticationServiceRequest) =>
    api().getCIBAAuthenticationService(req),
  updateCIBAAuthenticationService: (req: ServersApiSetCIBAAuthenticationServiceRequest) =>
    api().setCIBAAuthenticationService(req),

  bindServerTheme: (req: ServersApiBindServerThemeRequest) => api().bindServerTheme(req),
  unbindServerTheme: (req: ServersApiUnbindServerThemeRequest) => api().unbindServerTheme(req),

  getWorkspace: (req: WorkspacesApiGetWorkspaceRequest) => apiWorkspaces().getWorkspace(req),
  listWorkspaces: (req: WorkspacesApiListWorkspacesRequest) => apiWorkspaces().listWorkspaces(req),
  listUserWorkspaces: (req: WorkspacesApiListUserWorkspacesRequest) =>
    apiWorkspaces().listUserWorkspaces(req),
  updateWorkspaceMetadata: (req: WorkspacesApiUpdateWorkspaceMetadataRequest) =>
    apiWorkspaces().updateWorkspaceMetadata(req),
};

export default adminServersApi;
