import {
  SchemasApi,
  SchemasApiCreateSchemaRequest,
  SchemasApiDeleteSchemaRequest,
  SchemasApiGetSchemaRequest,
  SchemasApiGetWorkspaceSchemaRequest,
  SchemasApiListSchemasRequest,
  SchemasApiListWorkspaceSchemasRequest,
  SchemasApiUpdateSchemaRequest,
} from "@cloudentity/acp-identity";

import { identityApiClazz } from "./admin-services.common";

const api: () => SchemasApi = identityApiClazz(SchemasApi);

const identitySchemasApi = {
  listSchemas: (req: SchemasApiListSchemasRequest) => api().listSchemas(req),
  createSchema: (req: SchemasApiCreateSchemaRequest) => api().createSchema(req),
  getSchema: (req: SchemasApiGetSchemaRequest) => api().getSchema(req),
  updateSchema: (req: SchemasApiUpdateSchemaRequest) => api().updateSchema(req),
  deleteSchema: (req: SchemasApiDeleteSchemaRequest) => api().deleteSchema(req),
  getWorkspaceSchema: (req: SchemasApiGetWorkspaceSchemaRequest) => api().getWorkspaceSchema(req),
  listWorkspaceSchemas: (req: SchemasApiListWorkspaceSchemasRequest) =>
    api().listWorkspaceSchemas(req),
};

export default identitySchemasApi;
