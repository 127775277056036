import React from "react";

import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MoreVertical } from "react-feather";

import {
  GrantTenantRoleRequestRoleEnum,
  GrantWorkspaceRoleRequestRoleEnum,
  WorkspaceRoleSubject,
} from "@cloudentity/acp-admin";
import {
  BaseUserWithData,
  PoolResponse,
  UserWithData,
  UserWithDataStatusEnum,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../common/api/paths";
import Chip from "../../../common/components/Chip";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import EnhancedTable from "../common/EnhancedTable";
import Tooltip from "../common/Tooltip";
import { useWorkspace } from "../common/useWorkspace";
import StatusChip from "../workspaceDirectory/identityPools/identityPool/users/list/StatusChip";
import { serverTypeToRoleName } from "./workspaceRoleOptions";

const getHeadCells = (isB2BAdministrators?: boolean) => [
  { id: "user.payload.given_name", label: "First Name", sortable: true, align: "left" },
  { id: "user.payload.family_name", label: "Last Name", sortable: true, align: "left" },
  { id: "identifier", label: isB2BAdministrators ? "Email" : "Identifier", align: "left" },
  ...(isB2BAdministrators ? [] : [{ id: "identity-pool", label: "Identity Pool", align: "left" }]),
  { id: "user.status", label: "Status", sortable: true, align: "left" },
  ...(isB2BAdministrators ? [] : [{ id: "role", label: "Role", align: "left" }]),
  { id: "actions", label: "", align: "right", style: { width: 30 } },
];

export interface UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles {
  user: UserWithData | BaseUserWithData | undefined;
  identity_pool: PoolResponse | undefined;
  subject: WorkspaceRoleSubject | undefined;
  roles: GrantWorkspaceRoleRequestRoleEnum[] | GrantTenantRoleRequestRoleEnum[] | undefined;
}

interface Props {
  usersAndPoolsAndRoles: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles[];
  progress: boolean;
  hasManagePermission?: boolean;
  onMenuOpen: (
    anchorEl: HTMLElement,
    userAndPoolAndRoles: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles
  ) => void;
  onRowClick: (userAndPoolAndRoles: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles) => void;
  isB2BAdministrators?: boolean;
}

export default function WorkspaceAdministratorsTable({
  usersAndPoolsAndRoles,
  progress,
  hasManagePermission,
  onMenuOpen,
  onRowClick,
  isB2BAdministrators = false,
}: Props) {
  const isAdminAccessEnabled = useFeature("admin_workspace_access");
  const [workspace] = useWorkspace();

  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);

  const headCells = getHeadCells(isB2BAdministrators);
  const headCellsFiltered = headCells
    .filter(hc => (!hasManagePermission ? hc.id !== "actions" : true))
    .filter(hc => (!isAdminAccessEnabled ? hc.id !== "identity-pool" : true));

  return (
    <EnhancedTable
      id="manage-access-list"
      data={usersAndPoolsAndRoles.filter(up => !!up.user)}
      headCells={headCellsFiltered}
      loading={progress}
      createRow={(row: UserWithDataAndPoolResponseAndSubjectAndWorkspaceRoles, i, classes, cx) => {
        const disabled = row.user?.status === "deleted";
        return (
          <TableRow
            key={row.user?.id}
            style={{ height: 57, position: "relative" }}
            className={cx(hasManagePermission && !disabled && classes.selectableRow)}
            id={`manage-access-list-row-${row.user?.id || i}`}
            onClick={() => hasManagePermission && !disabled && onRowClick(row)}
            hover={hasManagePermission && !disabled}
          >
            <TableCell id={`manage-access-list-first-name-${i}`}>
              {(row.user?.payload?.given_name || "") as unknown as string}
            </TableCell>
            <TableCell id={`manage-access-list-last-name-${i}`}>
              {(row.user?.payload?.family_name || "") as unknown as string}
            </TableCell>
            <TableCell id={`manage-access-list-identifiers-${i}`}>
              <UserIdentifiers user={row.user} />
            </TableCell>
            {isAdminAccessEnabled && !isB2BAdministrators && (
              <TableCell id={`manage-access-list-identity-pool-${i}`}>
                {row.identity_pool?.name}
              </TableCell>
            )}
            <TableCell id={`manage-access-list-status-${i}`}>
              <UserStatus user={row.user} />
            </TableCell>
            {!isB2BAdministrators && (
              <TableCell id={`manage-access-list-roles-${i}`} width="20%">
                {row.roles
                  ?.map(r => serverTypeToRoleName(serverQuery.data?.type, r) || r)
                  .join(", ")}
              </TableCell>
            )}
            <TableCell align="right" padding="none">
              {hasManagePermission && !disabled && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    onMenuOpen(e.currentTarget, row);
                  }}
                  size="small"
                  id={`row-${i}-menu-button`}
                >
                  <MoreVertical />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}

const UserStatus = ({ user }: { user?: UserWithData | BaseUserWithData }) => {
  return (
    <StatusChip
      status={user?.status}
      label={user?.status === UserWithDataStatusEnum.New ? "Invite Sent" : undefined}
    />
  );
};

const UserIdentifiers = ({ user }: { user?: UserWithData | BaseUserWithData }) => {
  const identifiers = user?.identifiers || [];

  return (
    <>
      <span style={{ marginRight: 8 }}>
        {identifiers[0]?.identifier || <span>User ID: {user?.id}</span>}
      </span>
      {identifiers.length > 1 && (
        <Tooltip
          title={identifiers
            .slice(1)
            .map(identifier => identifier.identifier)
            .join(", ")}
        >
          <span>
            <Chip label={`+${identifiers.length - 1} more`} size="small" />
          </span>
        </Tooltip>
      )}
    </>
  );
};
