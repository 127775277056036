import React, { useEffect } from "react";

import Button from "@mui/material/Button";
import { makeStyles } from "tss-react/mui";

import { ServerResponseProfileEnum, ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import { useLocalStorage } from "../../../common/utils/hooks/useLocalStorage";
import { useGetWorkspace } from "../../services/adminServersQuery";
import { useWorkspace } from "../common/useWorkspace";
import { isOpenbanking } from "../workspaceDirectory/server-profiles";
import { drawerCollapsedWidth } from "./NavDrawer";

const useStyles = makeStyles()(() => ({
  root: {
    marginTop: 12,
    background: "#FCFCFF",
    containerType: "size",
  },
  button: {
    width: "100%",
    justifyContent: "flex-start",
    padding: 16,
    [`@container (max-width: ${drawerCollapsedWidth}px)`]: {
      padding: 0,
      minWidth: 0,
      fontSize: 12,
    },
  },
}));

export const NavModeChanger = () => {
  const { classes } = useStyles();

  const [workspace] = useWorkspace();
  const serverQuery = useGetWorkspace(workspace);

  const isModeSwitcherAvailable =
    (serverQuery.data?.type &&
      [ServerResponseTypeEnum.Organization, ServerResponseTypeEnum.Developer].includes(
        serverQuery.data?.type
      )) ||
    (serverQuery.data?.profile &&
      [ServerResponseProfileEnum.Consumer].includes(serverQuery.data?.profile)) ||
    isOpenbanking(serverQuery.data?.profile);

  const [mode, setNavMode] = useLocalStorage(`${workspace}_nav_mode`);

  useEffect(() => {
    if (isModeSwitcherAvailable && !mode) {
      setNavMode("simple");
    }
  }, [workspace, isModeSwitcherAvailable, mode, setNavMode]);

  if (!isModeSwitcherAvailable) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Button
        id={mode === "advanced" ? undefined : "show_all"}
        variant="text"
        onClick={() => {
          const newMode = mode === "simple" ? "advanced" : "simple";
          setNavMode(newMode);
        }}
        className={classes.button}
      >
        {mode === "advanced" ? "SHOW LESS" : "SHOW ALL"}
      </Button>
    </div>
  );
};
