import { getTenantId } from "../../../../common/api/paths";
import { useGetClients } from "../../../services/adminClientsQuery";
import { useGetAuthorizationServer } from "../../../services/adminServersQuery";

export const useTrySignInLink = ({
  serverId,
  idpHint,
  acrValues,
}: {
  serverId: string;
  idpHint?: string;
  acrValues?: string;
}) => {
  const getServerQuery = useGetAuthorizationServer(getTenantId(), serverId);
  const listClientsQuery = useGetClients(getTenantId(), serverId);
  const clients = listClientsQuery.data?.clients ?? [];
  const defaultApp = clients.find(
    client => client.client_id === getServerQuery.data?.settings?.default_client_id
  );
  const defaultAppLink = defaultApp?.app_url;

  const demoClient = clients.find(client => client.client_id === `${serverId}-demo`);
  const userPortal = clients.find(client => client.client_id === `${serverId}`);
  const demoLink =
    `${getServerQuery.data?.issuer_url ?? ""}/demo?auto_login=true` ||
    demoClient?.redirect_uris?.[0] ||
    "";
  const userPortalLink = `${getServerQuery.data?.issuer_url ?? ""}/app` || "";

  let link =
    (defaultApp && defaultAppLink) ||
    (demoClient && demoLink) ||
    (userPortal && userPortalLink) ||
    "";

  try {
    if (link) {
      const url = new URL(link);
      if (idpHint && !url.searchParams.has("idp_hint")) {
        url.searchParams.append("idp_hint", idpHint);
      }
      if (acrValues && !url.searchParams.has("acr_values")) {
        url.searchParams.append("acr_values", acrValues);
      }
      link = url.toString();
    }
  } catch {
    //
  }

  return link;
};
