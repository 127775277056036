import React from "react";

import ConfirmationDialog from "./ConfirmationDialog";
import { usePrompt } from "./RouteLeavingGuard";

interface Props {
  when: boolean;
}

function RouteLeavingGuardSimple({ when }: Props) {
  const { promptVisible, confirmNavigation, cancelNavigation } = usePrompt(when);

  return (
    <>
      {promptVisible && (
        <ConfirmationDialog
          title="Unsaved Changes"
          content="You have unsaved changes. Leave without saving?"
          cancelText="Dismiss"
          confirmText="Leave"
          onCancel={() => cancelNavigation && cancelNavigation()}
          onConfirm={() => confirmNavigation && confirmNavigation()}
        />
      )}
    </>
  );
}

export default RouteLeavingGuardSimple;
