import React from "react";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  box: {
    marginTop: 32,
    padding: 20,
    background: "#FBFCFD",
    borderRadius: 4,
    border: "1px solid #F4F4F4",
    position: "relative",
  },
  boxIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: 54,
    height: 54,
    right: 24,
    top: -24,
    background: "#fff",
    borderRadius: 4,
    color: theme.palette.primary.main,
    boxShadow:
      "0px 1.97075px 4.43419px -1.47806px rgba(24, 39, 75, 0.12), 0px 2.95612px 10.3464px -0.985375px rgba(24, 39, 75, 0.12)",
  },
  text: {
    marginTop: 12,
  },
  boxText: {
    marginTop: 8,
  },
  boxLink: {
    display: "block",
    marginTop: 12,
  },
}));

interface Props {
  title: string;
  text?: string;
  boxTitle?: string;
  boxText?: string;
  boxLink: string;
  boxLinkText?: string;
  boxIcon?: React.ReactNode;
}

export default function Instructions({
  title,
  text,
  boxTitle,
  boxText,
  boxLink,
  boxIcon,
  boxLinkText,
}: Props) {
  const { classes } = useStyles();

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      {text && (
        <Typography variant="caption" display="block" className={classes.text}>
          {text}
        </Typography>
      )}
      <div className={classes.box}>
        {boxIcon && <div className={classes.boxIconContainer}>{boxIcon}</div>}
        {boxTitle && (
          <Typography variant="h5" color="primary">
            {boxTitle}
          </Typography>
        )}
        {boxText && (
          <Typography variant="body2" display="block" className={classes.boxText}>
            {boxText}
          </Typography>
        )}
        <Link href={boxLink} className={classes.boxLink} target="_blank" rel="noopener noreferrer">
          {boxLinkText || "Learn more"}
        </Link>
      </div>
    </>
  );
}
