import React, { Dispatch, SetStateAction } from "react";

import IconButton from "@mui/material/IconButton";
import { makeStyles } from "tss-react/mui";

import IdpsListModeComplexIcon from "../../../../common/components/icons/IdpsListModeComplexIcon";
import IdpsListModeSimpleIcon from "../../../../common/components/icons/IdpsListModeSimpleIcon";
import { setInLocalStorage } from "../../../../common/utils/localStorage.utils";
import Tooltip from "../../common/Tooltip";
import { IDPS_LIST_VIEW_MODE_KEY, IdpsViewModeType } from "./identitiesListSimple/utils";

const useStyles = makeStyles<{ noMargin?: boolean }>()((theme, { noMargin }) => ({
  container: {
    marginRight: noMargin ? 0 : 16,
    height: "100%",
  },
  activeButton: {
    "&> svg": {
      color: theme.palette.primary.main,
    },
  },
}));

interface Props {
  mode: IdpsViewModeType;
  setMode: Dispatch<SetStateAction<IdpsViewModeType>>;
  noMargin?: boolean;
}

export default function IdentitiesListModeController({ mode, setMode, noMargin }: Props) {
  const { cx, classes } = useStyles({ noMargin });

  const handleChangeMode = (mode: IdpsViewModeType) => {
    setMode(mode);
    setInLocalStorage(IDPS_LIST_VIEW_MODE_KEY, mode);
  };

  return (
    <div className={classes.container}>
      <Tooltip title="Use simple view mode">
        <IconButton
          id="basic-view-button"
          className={cx(mode === "simple" && classes.activeButton)}
          onClick={() => handleChangeMode("simple")}
        >
          <IdpsListModeComplexIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Use advanced view mode">
        <IconButton
          id="advanced-view-button"
          className={cx(mode === "advanced" && classes.activeButton)}
          onClick={() => handleChangeMode("advanced")}
        >
          <IdpsListModeSimpleIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
