import React, { useCallback, useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import debounce from "lodash/debounce";
import { Search, X } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    margin: "0 10px 10px",
  },
  searchInputRoot: {
    width: 180,
    padding: "8px 10px",
    backgroundColor: "#F2F3F4",
  },
  searchInput: {
    padding: 0,
    fontSize: 12,
  },
  searchInputNotched: {
    border: "1px solid #ECECEC",
  },
}));

interface Props {
  onSearch: (value: string) => void;
}

function InMenuSearch({ onSearch }: Props) {
  const { classes } = useStyles();

  const [searchText, setSearchText] = useState("");

  const handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
  };

  const handleClearSearchText = () => {
    setSearchText("");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(debounce(onSearch, 250), [onSearch]);

  useEffect(() => {
    debouncedOnSearch(searchText);
  }, [debouncedOnSearch, searchText]);

  return (
    <div className={classes.container}>
      <OutlinedInput
        value={searchText}
        onChange={handleChangeSearchText}
        startAdornment={
          <InputAdornment position="start">
            <Search size="14" color="#9EA1B7" strokeWidth="2.5" />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {searchText !== "" ? (
              <IconButton
                aria-label="clear search input"
                style={{ padding: 6 }}
                onClick={handleClearSearchText}
                onMouseDown={handleClearSearchText}
                size="large"
              >
                <X size="16" color="#9EA1B7" strokeWidth="2.5" />
              </IconButton>
            ) : (
              <div style={{ width: 28 }} />
            )}
          </InputAdornment>
        }
        classes={{
          root: classes.searchInputRoot,
          input: classes.searchInput,
          notchedOutline: classes.searchInputNotched,
        }}
        placeholder="Search"
      />
    </div>
  );
}

export default InMenuSearch;
