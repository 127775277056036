import React from "react";

import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useQueryClient } from "@tanstack/react-query";

import { ServerResponse, Theme } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import adminServersApi from "../../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  listWorkspacesQueryKey,
} from "../../../services/adminServersQuery";
import { useListThemes } from "../../../services/adminThemesQuery";

interface Props {
  theme: Theme;
  server: ServerResponse;
  anchorEl: HTMLElement;
  onClose: () => void;
}

export default function BindToThemeMenu({ theme, server, anchorEl, onClose }: Props) {
  const themesQuery = useListThemes(getTenantId());
  const queryClient = useQueryClient();

  const themes = (themesQuery.data?.themes || [])
    .concat({ id: "default", name: "Default", tenant_id: getTenantId() })
    .filter(t => t.id !== server.theme_id)
    .filter(t => (theme.id === "default" ? t.id !== "default" : true));

  const handleBind = themeId => {
    if (themeId === "default") {
      adminServersApi
        .unbindServerTheme({ wid: server.id! })
        .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: getAuthorizationServerQueryKey(getTenantId(), server.id),
          })
        )
        .then(() => {
          notifySuccess("Server successfully bound to theme");
          onClose();
        })
        .catch(notifyErrorOrDefaultTo("Error occurred when trying to bind server to theme"));
    } else {
      adminServersApi
        .bindServerTheme({ wid: server.id!, themeID: themeId })
        .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: getAuthorizationServerQueryKey(getTenantId(), server.id),
          })
        )
        .then(() => {
          notifySuccess("Server successfully bound to theme");
          onClose();
        })
        .catch(notifyErrorOrDefaultTo("Error occurred when trying to bind server to theme"));
    }
  };

  return (
    <Menu
      id="bind-to-theme-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <ListSubheader component="div" id="themes-subheader">
        Themes
      </ListSubheader>
      {themes.map(theme => (
        <MenuItem
          id="bind-to-theme-menu-item"
          key={`bind-to-theme-menu-item-${theme.id}`}
          onClick={() => {
            handleBind(theme.id);
            onClose();
          }}
          style={{ minWidth: 200 }}
        >
          {theme.name}
        </MenuItem>
      ))}
      {themes.length === 0 && <MenuItem disabled>No themes</MenuItem>}
    </Menu>
  );
}
