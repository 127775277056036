import React, { useState } from "react";

import Progress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { OrganizationResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import { useGetOrganization } from "../../../services/adminOrganizationsQuery";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import { useWorkspaceCardMenuStyles } from "../workspaces/utils";
import ChangeParentOrganizationDialog from "./ChangeParentOrganizationDialog";
import DeleteOrganizationMenuItem from "./DeleteOrganizationMenuItem";
import EditOrganizationMenuItem from "./EditOrganizationMenuItem";
import ToggleAsTemplateMenuItems from "./ToggleAsTemplateMenuItems";
import { GridMode } from "./utils";

interface Props {
  workspace: OrganizationResponse;
  rootServer?: Pick<
    OrganizationResponse | WorkspaceResponse,
    "id" | "number_of_child_organizations"
  >;
  onSelectServer: (aid: string) => void;
  handleClose: () => void;
  mainMenuAnchorEl: HTMLElement | null;
  gridMode: GridMode;
  withChangeParentOrganization: boolean;
  withToggleAsTemplate?: boolean;
  onCreate: (parentId: string) => void;
  withEdit?: boolean;
  withCreateSuborganization?: boolean;
}
export default function OrganizationCardMenu({
  workspace,
  rootServer,
  onSelectServer,
  handleClose,
  mainMenuAnchorEl,
  gridMode,
  withChangeParentOrganization,
  withToggleAsTemplate,
  withEdit,
  withCreateSuborganization,
  onCreate,
}: Props) {
  const { classes } = useWorkspaceCardMenuStyles();

  const [changeParent, setChangeParent] = useState<OrganizationResponse | null>(null);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace.id);
  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const getOrganizationQuery = useGetOrganization(workspace.id);
  const organization = getOrganizationQuery.data;

  const { id = "" } = workspace;

  return (
    <>
      <Menu
        id={`${id}-card-main-menu`}
        anchorEl={mainMenuAnchorEl}
        keepMounted
        open={Boolean(mainMenuAnchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
      >
        {getOrganizationQuery.isLoading ||
        checkTenantPermissionsQuery.isLoading ||
        checkWorkspacePermissionsQuery.isLoading ? (
          <MenuItem disabled className={classes.menuProgress}>
            <Progress size={12} />
          </MenuItem>
        ) : (
          [
            <MenuItem
              id={`${id}-open-workspace`}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onSelectServer(id);
                handleClose();
              }}
              key="open"
            >
              Open
            </MenuItem>,
            withEdit ? (
              <EditOrganizationMenuItem
                orgId={workspace.id!}
                onClick={handleClose}
                key="edit-organization"
              />
            ) : null,
            <DeleteOrganizationMenuItem
              orgId={workspace.id!}
              onClick={handleClose}
              key="delete-organization"
            />,
            ...(withCreateSuborganization &&
            checkTenantPermissionsQuery.data?.create_organization &&
            gridMode === "hierarchy"
              ? [
                  <MenuItem
                    id={`${id}-create-sub-organization`}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (organization) {
                        onCreate(organization.id || "");
                      }
                      handleClose();
                    }}
                    key="create-sub-organization"
                  >
                    Create new sub-organization
                  </MenuItem>,
                ]
              : []),
            ...(withChangeParentOrganization &&
            checkWorkspacePermissionsQuery.data?.update_organization
              ? [
                  <MenuItem
                    id={`${id}-change-parent-org`}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setChangeParent(organization!);
                      handleClose();
                    }}
                    key="change-parent-org"
                  >
                    Change parent organization
                  </MenuItem>,
                ]
              : []),
            withToggleAsTemplate ? (
              <ToggleAsTemplateMenuItems
                organization={workspace}
                onClick={handleClose}
                key="toggle-as-template"
              />
            ) : null,
          ]
        )}
      </Menu>

      {changeParent && (
        <ChangeParentOrganizationDialog
          organization={changeParent}
          rootServer={rootServer}
          onCancel={() => {
            setChangeParent(null);
            handleClose();
          }}
        />
      )}
    </>
  );
}
