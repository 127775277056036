import React from "react";
import { Link, LinkProps } from "react-router-dom";

import { ExternalLink } from "react-feather";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  link: {
    color: theme.palette.primary.main,
  },
  icon: {
    marginLeft: 4,
    position: "relative",
    top: 1,
  },
}));

interface Props extends LinkProps {
  withIcon?: boolean;
}

export default function RouterLink({ children, className, withIcon, ...props }: Props) {
  const { cx, classes } = useStyles();
  return (
    <Link className={cx(classes.link, className)} {...props}>
      {children}
      {withIcon && <ExternalLink size={14} className={classes.icon} />}
    </Link>
  );
}
