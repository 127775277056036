import { useMemo } from "react";

import { Theme } from "@mui/material/styles";

import { Styling } from "@cloudentity/acp-admin";

import { useGetStyling } from "../../api/stylingQuery";

export const getDefaultStyling = (theme: Theme): Styling => ({
  colors: {
    primary: theme.palette.primary.main,
    background: theme.palette.background.default.toUpperCase(),
    header: theme.custom.headerBackground,
  },
  font_url: "",
  logo_url: "",
  logo_size: "M",
});

export function getStyling({ styling, defaults }: { styling?: Styling; defaults?: Styling } = {}) {
  return {
    colors: {
      primary: styling?.colors?.primary || defaults?.colors?.primary || "",
      background: styling?.colors?.background || defaults?.colors?.background || "",
      header: styling?.colors?.header || defaults?.colors?.header || "",
    },
    font_url: styling?.font_url || defaults?.font_url || "",
    logo_url: styling?.logo_url || defaults?.logo_url || "",
    logo_size: styling?.logo_size || defaults?.logo_size || "",
  };
}

export const getNewTheme = (theme: Theme, styling: Styling): Theme => {
  const fontStyles = styling.font_url
    ? {
        "@font-face": {
          fontFamily: "CustomTenantFont",
          src: `url(${encodeURI(styling.font_url)})`,
        },
      }
    : {};

  return {
    ...theme,
    palette:
      styling && styling.colors
        ? {
            ...(theme.palette || {}),
            primary: {
              ...(theme.palette.primary || {}),
              main: styling.colors.primary || theme.palette.primary.main,
            },
            background: {
              ...(theme.palette.background || {}),
              default: styling.colors.background || theme.palette.background.default,
            },
          }
        : theme.palette,
    custom: {
      ...(theme.custom || {}),
      headerBackground: styling.colors?.header || theme.custom.headerBackground,
    },
    components: {
      ...theme.components,
      MuiCssBaseline: {
        styleOverrides: {
          "@global": {
            ...fontStyles,
          },
        },
      },
    },
  };
};

export const logoSizeMapper = {
  S: 24,
  M: 36,
  L: 48,
};

export type CustomStylingType = {
  customTheme: Theme;
  isLoading: boolean;
  logo: {
    url: string;
    size: number;
  };
};

export const useStyling = (theme: Theme, wid?: string): CustomStylingType => {
  const defaultStyling = useMemo(() => getDefaultStyling(theme), [theme]);
  const getStylingQuery = useGetStyling(wid, { enabled: !!wid });

  const styling =
    wid && getStylingQuery.data
      ? getStyling({ styling: getStylingQuery.data, defaults: defaultStyling })
      : defaultStyling;

  const customTheme =
    wid && getStylingQuery.data
      ? getNewTheme(theme, getStylingQuery.data)
      : getNewTheme(theme, defaultStyling);

  return {
    customTheme,
    isLoading: getStylingQuery.isLoading,
    logo: {
      url: styling?.logo_url ?? "",
      size: (styling?.logo_size && logoSizeMapper[styling?.logo_size]) ?? "",
    },
  };
};
