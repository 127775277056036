import { useMutation } from "@tanstack/react-query";

import {
  OrganizationResponseAuthenticationMechanismsEnum,
  ServerResponseAuthenticationMechanismsEnum,
} from "@cloudentity/acp-admin";
import {
  Pool,
  PoolAuthenticationMechanismsEnum,
  PoolPreferredAuthenticationMechanismEnum,
  PoolsApiCreateWorkspacePoolRequest,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../common/api/paths";
import { listIDPsForIdentityPoolQueryKey, listIDPsQueryKey } from "./adminIDPsQuery";
import adminIdentityPoolsApi from "./adminIdentityPoolsApi";
import {
  listUserWorkspacePoolsQueryKey,
  listWorkspacePoolsQueryKey,
} from "./adminIdentityPoolsQuery";
import { invalidateQueries } from "./queryUtils";

export const useCreateWorkspaceIdentityPoolMutation = () => {
  return useMutation({
    mutationFn: (req: PoolsApiCreateWorkspacePoolRequest) =>
      adminIdentityPoolsApi.createWorkspacePool(req),
    onSuccess: (data, variables) =>
      invalidateQueries([
        listUserWorkspacePoolsQueryKey(data.data.workspace_id!),
        listWorkspacePoolsQueryKey(data.data.workspace_id!),
        ...(variables.withIdp
          ? [
              listIDPsQueryKey(getTenantId(), data.data.workspace_id!),
              listIDPsForIdentityPoolQueryKey(getTenantId()),
            ]
          : []),
      ]),
  });
};

export const setDefaultAuthenticationMechanismsIfAvailableForWorkspace = ({
  pool,
  supportedAuthenticationMechanisms,
  authenticationMechanism,
  setAuthenticationMechanismsAsPreferred,
}: {
  pool: Pool;
  supportedAuthenticationMechanisms: (
    | ServerResponseAuthenticationMechanismsEnum
    | OrganizationResponseAuthenticationMechanismsEnum
  )[];
  authenticationMechanism:
    | ServerResponseAuthenticationMechanismsEnum
    | OrganizationResponseAuthenticationMechanismsEnum;
  setAuthenticationMechanismsAsPreferred?: boolean;
}) => {
  const mechanism = supportedAuthenticationMechanisms.includes(authenticationMechanism)
    ? authenticationMechanism
    : supportedAuthenticationMechanisms[0];

  pool.authentication_mechanisms = [mechanism as unknown as PoolAuthenticationMechanismsEnum];

  if (setAuthenticationMechanismsAsPreferred) {
    pool.preferred_authentication_mechanism =
      mechanism as unknown as PoolPreferredAuthenticationMechanismEnum;
  }
};
