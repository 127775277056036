import React from "react";

import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

import PageContainer from "../../../admin/components/common/PageContainer";
import PageContent from "../../../admin/components/common/PageContent";
import emptyState from "../../../assets/images/emptyStates/Workspaces2.svg";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 100,
  },
  image: {
    width: 72,
    height: 72,
  },
  text: {
    color: "#969799",
    fontWeight: 400,
    marginTop: 16,
  },
  subtext: {
    color: "#A4A6A7",
    marginTop: 6,
  },
}));

export default function NoPermissionsEmptyState() {
  const { classes } = useStyles();
  return (
    <PageContainer>
      <PageContent>
        <div className={classes.container}>
          <img src={emptyState} alt="no workspaces found" className={classes.image} />

          <Typography className={classes.text} variant="h4">
            No resources found
          </Typography>
          <Typography className={classes.subtext} id="empty-state-description" variant="textMD">
            You are not entitled to access any workspaces or identity pools. Please contact
            administrator
          </Typography>
        </div>
      </PageContent>
    </PageContainer>
  );
}
