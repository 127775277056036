import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";

import identitySelfApi from "../../services/identitySelfApi";
import Form, { useForm } from "../../utils/forms/Form";
import FormFooter from "../../utils/forms/FormFooter";
import TextFieldRequired from "../../utils/forms/TextFieldRequired";
import Button from "../Button";
import Dialog from "../Dialog";
import { notifyErrorOrDefaultTo, notifySuccess } from "../notifications/notificationService";

const data = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export default function SelfServiceChangePassword() {
  const [dialog, setDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  const form = useForm({
    id: "change-password",
    initialValues: data,
    progress,
  });

  function handleClose() {
    setDialog(false);
  }

  const newPassword = form.watch("newPassword");

  useEffect(() => {
    form.trigger("confirmPassword");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword]);

  function handleChangePassword(newData: typeof data) {
    setProgress(true);
    identitySelfApi
      .changePassword({ old_password: newData.oldPassword, new_password: newData.newPassword })
      .then(handleClose)
      .then(() => notifySuccess("New password set successfully"))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to change password"))
      .finally(() => setProgress(false));
  }

  return (
    <>
      <Button
        id="change-password-button"
        onClick={() => setDialog(true)}
        variant="contained"
        size="small"
        style={{ fontSize: 12 }}
      >
        Change
      </Button>

      {dialog && (
        <Dialog onClose={handleClose} id="change-password-dialog" title="Change password">
          <Typography component="div" variant="textMD" style={{ marginBottom: 32 }}>
            Choose a strong password, and don't reuse it for other accounts.
          </Typography>

          <Form form={form}>
            <TextFieldRequired
              name="oldPassword"
              label="Old password"
              toggleVisibility
              defaultVisibility={false}
            />

            <TextFieldRequired
              name="newPassword"
              label="New password"
              toggleVisibility
              defaultVisibility={false}
              withPasswordStrengthMeter
            />

            <TextFieldRequired
              name="confirmPassword"
              label="Confirm password"
              toggleVisibility
              defaultVisibility={false}
              rules={{
                validate: {
                  matchNewPassword: v =>
                    v === newPassword || "New password does not match confirm password",
                },
              }}
            />

            <FormFooter
              onCancel={handleClose}
              onSubmit={handleChangePassword}
              submitText="Change"
            />
          </Form>
        </Dialog>
      )}
    </>
  );
}
