import React, {
  CSSProperties,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: 300,
    minWidth: "100%",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.dark,
    textAlign: "center",
    padding: "12px 50px 32px 50px",
    "& h3": {
      ...(theme.typography.h3 as CSSProperties),
    },
  },
  dots: {
    display: "flex",
    justifyContent: "center",
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.custom.greys.default,
    margin: "0 3px",
    cursor: "pointer",
  },
  dotActive: {
    backgroundColor: theme.palette.primary.main,
  },
  navArrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  navArrowLeft: {
    left: -56,
  },
  navArrowRight: {
    right: -56,
  },
  iconButton: {
    backgroundColor: "white",
    padding: 9,
    "&:hover": {
      backgroundColor: "white",
      opacity: 0.9,
    },
  },
}));

export type CarouselSlideType = {
  image: string;
  content: ReactElement | ((onNext: () => void, onPrev: () => void) => ReactElement);
  id: string;
};

interface Props {
  slides: CarouselSlideType[];
}

function Carousel({ slides: inputSlides }: Props) {
  const { cx, classes } = useStyles();
  const [isSliding, setIsSliding] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const slides = useMemo(() => inputSlides, []);

  const getPrevSlide = () => (slideIndex === 0 ? slides.length - 1 : slideIndex - 1);
  const getNextSlide = useCallback(
    () => (slideIndex === slides.length - 1 ? 0 : slideIndex + 1),
    [slideIndex, slides]
  );

  useEffect(() => {
    if (isSliding && slideIndex === slides.length - 1) {
      setIsSliding(false);
    }
  }, [isSliding, slideIndex, slides]);

  useEffect(() => {
    let interval;
    if (isSliding) {
      interval = setInterval(() => {
        setSlideIndex(getNextSlide());
      }, 3500);
    }
    return () => {
      clearInterval(interval);
    };
  }, [getNextSlide, isSliding, slideIndex, slides]);

  const handleClickPrev = () => {
    if (isSliding) setIsSliding(false);
    setSlideIndex(getPrevSlide());
  };

  const handleClickNext = () => {
    if (isSliding) setIsSliding(false);

    setSlideIndex(getNextSlide());
  };

  const slideContent = slides[slideIndex].content;

  return (
    <div className={classes.container}>
      <div
        style={{
          background: "#292E3E",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 260,
        }}
      >
        <img src={slides[slideIndex].image} alt="slide" style={{ maxWidth: "100%" }} />
      </div>
      <div className={classes.content}>
        {typeof slideContent === "function"
          ? slideContent(handleClickNext, handleClickPrev)
          : slideContent}
        {slides.length > 1 && (
          <div className={classes.dots}>
            {slides.map((slide, i) => (
              <div
                key={`dot-${slide.id}`}
                className={cx(classes.dot, slideIndex === i && classes.dotActive)}
                onClick={() => {
                  if (isSliding) setIsSliding(false);
                  setSlideIndex(i);
                }}
              />
            ))}
          </div>
        )}
      </div>
      {slides.length > 1 && (
        <>
          <div className={cx(classes.navArrow, classes.navArrowLeft)}>
            <IconButton onClick={handleClickPrev} className={classes.iconButton} size="large">
              <ChevronLeft style={{ fontSize: 22 }} />
            </IconButton>
          </div>
          <div className={cx(classes.navArrow, classes.navArrowRight)}>
            <IconButton onClick={handleClickNext} className={classes.iconButton} size="large">
              <ChevronRight style={{ fontSize: 22 }} />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
}

export default Carousel;
