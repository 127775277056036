import React from "react";

import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Trash } from "react-feather";

import { StaticUser } from "@cloudentity/acp-admin";

import Fab from "../../../common/components/Fab";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import EnhancedTable from "../common/EnhancedTable";
import { IdentityUserView } from "./identities.utils";

const headCells = [
  { id: "username", label: "Username", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "phone_number", label: "Phone", align: "left" },
  { id: "actions", label: "", align: "right" },
];

interface Props {
  users: StaticUser[];
  serverId: string;
  onRowClick: (data: StaticUser, index: number) => void;
  onDeleteClick: (index: number) => void;
  onViewChange: (view: IdentityUserView) => void;
}

export default function StaticIDPUsersList({
  users,
  serverId,
  onRowClick,
  onDeleteClick,
  onViewChange,
}: Props) {
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(serverId);

  return (
    <>
      {checkWorkspacePermissionsQuery.data?.manage_idps && (
        <div style={{ marginBottom: 24, textAlign: "right" }}>
          <Fab id="page-header-action-button" onClick={() => onViewChange("create")}>
            + Add user
          </Fab>
        </div>
      )}

      <EnhancedTable
        id="users"
        data={users}
        headCells={headCells}
        createRow={(row, index, classes, cx) => (
          <TableRow
            key={row.username}
            className={cx([classes.hoverRow, classes.selectableRow, classes.rowWithHoverIcons])}
            style={{ height: 57 }}
            onClick={() => onRowClick(row, index)}
          >
            <TableCell component="th" scope="row">
              {row.username}
            </TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.phone_number}</TableCell>
            <TableCell align="right" padding="none" style={{ padding: "0 24px" }}>
              {checkWorkspacePermissionsQuery.data?.manage_idps && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    onDeleteClick(index);
                  }}
                  aria-label="delete"
                  className={cx([classes.iconButton, classes.hoverIcon])}
                  size="large"
                >
                  <Trash size="14" />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        )}
      />
    </>
  );
}
