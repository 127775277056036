import React, { useCallback, useRef, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import debounce from "lodash/debounce";
import { Search } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { Theme, WorkspaceResponse } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import FormInputLabel from "../../../../common/components/FormInputLabel";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import adminServersApi from "../../../services/adminServersApi";
import {
  getAuthorizationServerQueryKey,
  listWorkspacesQueryKey,
} from "../../../services/adminServersQuery";
import { useInfiniteScrollForItemsWithQuery } from "../../common/EnhancedTableAsync/useItemsWithQuery";
import useWorkspacesSeqOrCursor from "../../common/EnhancedTableAsync/useWorkspacesSeqOrCursor";
import WorkspaceAvatar from "../../nav/WorkspaceAvatar";

const useStyles = makeStyles()(() => ({
  cell: {
    display: "flex",
    alignItems: "center",
    padding: 8,
    "& > div:first-of-type": {
      marginRight: 8,
    },
  },
  clearSearchIcon: {
    cursor: "pointer",
  },
  table: {
    borderRadius: 4,
    margin: "24px 0",
    height: 396,
    overflowY: "auto",
  },
  row: {
    cursor: "pointer",
  },
  disabledRow: {
    cursor: "not-allowed",
    backgroundColor: "#f0f0f0",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface Props {
  theme: Theme;
  onClose: () => void;
}

export default function BindToWorkspaceDialog({ theme, onClose }: Props) {
  const { classes } = useStyles();
  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceResponse | null>(null);
  const [progress, setProgress] = useState(false);
  const table = useRef<HTMLDivElement>(null);

  const { totalData, onFiltersUpdate, filters, isFetching, searchText, onLastPage } =
    useWorkspacesSeqOrCursor({ forceMode: "seq", ignoreUrlParams: true, forceLimit: 50 });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(onFiltersUpdate, 250), []);

  const { onScroll } = useInfiniteScrollForItemsWithQuery({
    onLastPage,
    getElement: () => table.current ?? undefined,
  });

  const queryClient = useQueryClient();

  const handleBind = serverId => {
    setProgress(true);
    if (theme.id === "default") {
      adminServersApi
        .unbindServerTheme({ wid: serverId })
        .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: getAuthorizationServerQueryKey(getTenantId(), serverId),
          })
        )
        .then(() => {
          notifySuccess("Server successfully bound to theme");
          onClose();
        })
        .catch(notifyErrorOrDefaultTo("Error occurred when trying to bind server to theme"))
        .finally(() => setProgress(false));
    } else {
      adminServersApi
        .bindServerTheme({ wid: serverId, themeID: theme.id! })
        .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
        .then(() =>
          queryClient.invalidateQueries({
            queryKey: getAuthorizationServerQueryKey(getTenantId(), serverId),
          })
        )
        .then(() => {
          notifySuccess("Server successfully bound to theme");
          onClose();
        })
        .catch(notifyErrorOrDefaultTo("Error occurred when trying to bind server to theme"))
        .finally(() => setProgress(false));
    }
  };

  return (
    <Dialog
      onClose={onClose}
      id="select-workspace-for-theme-dialog"
      title={`Select workspace for ${theme.name} theme`}
    >
      <FormInputLabel id="select-workspace-input-label" label="Workspace" />
      <TextField
        variant="outlined"
        placeholder="Search workspaces"
        onChange={e => {
          onSearch(e.target.value, filters);
          setSelectedWorkspace(null);
        }}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              className={searchText !== "" ? classes.clearSearchIcon : undefined}
            >
              {isFetching ? <CircularProgress size={20} /> : <Search size={18} />}
            </InputAdornment>
          ),
        }}
        helperText="Use the search if your workspace does not appear on the list"
      />
      <TableContainer ref={table} component={Paper} className={classes.table} onScroll={onScroll}>
        <Table id="workspace-selection-table" size="small">
          <TableBody>
            {totalData.length > 0 ? (
              totalData.map(workspace => {
                const isClickable =
                  theme.id === "default" ? !!workspace.theme_id : workspace.theme_id !== theme.id;

                return (
                  <TableRow
                    key={workspace.id}
                    onClick={isClickable ? () => setSelectedWorkspace(workspace) : undefined}
                    className={isClickable ? classes.row : classes.disabledRow}
                    hover={isClickable}
                    selected={selectedWorkspace?.id === workspace.id}
                  >
                    <TableCell className={classes.cell}>
                      <WorkspaceAvatar server={workspace} size="small" />
                      <Typography>{workspace.name}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center", borderBottom: "none", color: "gray" }}>
                  No workspaces
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.footer}>
        <Button
          id="search-workspace-cancel-button"
          onClick={onClose}
          style={{ width: 100, paddingTop: 12, paddingBottom: 12 }}
          disabled={progress}
        >
          Cancel
        </Button>
        <LoadingButton
          id="search-workspace-save-button"
          variant="contained"
          disabled={!selectedWorkspace}
          onClick={() => {
            if (selectedWorkspace) {
              handleBind(selectedWorkspace.id);
            }
          }}
          loading={progress}
        >
          Save
        </LoadingButton>
      </div>
    </Dialog>
  );
}
