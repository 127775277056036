import { Components } from "@mui/material/styles";

export const overrides: Components = {
  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottom: "1px solid #e8e8e8",
        marginBottom: 32,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
        fontSize: 14,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: "14px 24px",
        fontSize: 14,
      },
      head: {
        fontSize: 12,
        opacity: 0.6,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      hover: {
        "&:hover": {
          backgroundColor: "#F7FAFF !important",
          cursor: "pointer",
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        fontSize: 14,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        fontSize: 14,
      },
      containedSizeLarge: {
        padding: "12px 24px",
        fontSize: 16,
      },
      outlinedSizeLarge: {
        padding: "12px 24px",
        fontSize: 16,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 32,
        height: 18,
        padding: 0,
        display: "flex",
      },
      switchBase: {
        transform: "translateX(-7px) translateY(-7px)",
        "&.Mui-checked": {
          transform: "translateX(7px) translateY(-7px)",
          color: "white",
          "& + .MuiSwitch-track": {
            opacity: 1,
          },
          ".MuiSwitch-input": {
            left: -7,
          },
        },
        "&.Mui-disabled": {
          color: "white !important",
          "& + .MuiSwitch-track": {
            opacity: "0.5",
          },
        },
      },
      input: {
        left: 7,
        width: "100%",
      },
      thumb: {
        width: 14,
        height: 14,
        boxShadow: "none",
      },
      track: {
        border: `1px solid white`,
        borderRadius: 18 / 2,
        opacity: 1,
        backgroundColor: "#A9A9A9",
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      extended: {
        padding: "12px 32px",
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: "4px",
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: "22px 32px",
        fontSize: 16,
        lineHeight: "24px",
        letterSpacing: "-0.32px",
        fontWeight: 600,
        textTransform: "none",
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: 32,
        paddingTop: "32px !important",
        position: "relative",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: 14,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 40,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      option: {
        '&[data-focus="true"]': {},
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 12,
        wordBreak: "break-all",
        backgroundColor: "black",
        padding: "8px 24px",
      },
      arrow: {
        "&:before": {
          backgroundColor: "black",
        },
      },
    },
    defaultProps: {
      arrow: true,
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    defaultProps: {
      underline: "none",
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        "&:before": {
          opacity: 0,
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      content: {
        "&$expanded": {
          margin: "inherit",
        },
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variant: "body2",
    },
  },
  MuiPaper: {
    defaultProps: {
      variant: "outlined",
    },
  },
};
