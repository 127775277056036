import React from "react";

import Paper from "@mui/material/Paper";
import { editor } from "monaco-editor";

import FormInputLabel from "../../../common/components/FormInputLabel";
import SchemaEditor from "../services/rar/RARSchemaEditor";
import CustomAttributesSchemaMenus from "./CustomAttributesSchemaMenus";

import IMarker = editor.IMarker;

interface Props {
  id: string;
  label: string;
  value: string;
  path: string;
  errors?: IMarker[];
  onChange: (value: string, resetPayload?: boolean) => void;
  onValidate: (markers: IMarker[]) => void;
  disabled?: boolean;
}

export default function OAuthMetadataEditor({
  id,
  label,
  value,
  path,
  errors = [],
  onChange,
  onValidate,
  disabled = false,
}: Props) {
  return (
    <div style={{ marginBottom: 32, position: "relative" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 12,
        }}
      >
        <FormInputLabel
          id={id}
          label={label}
          style={{ textTransform: "uppercase", marginBottom: 0 }}
        />
        <div>
          <CustomAttributesSchemaMenus
            onSelectSchema={schema => onChange(schema, true)}
            disabled={disabled}
          />
        </div>
      </div>
      <Paper>
        <SchemaEditor
          id={id}
          value={value}
          path={path}
          errors={errors}
          disabled={disabled}
          onChange={onChange}
          onValidate={onValidate}
        />
      </Paper>
    </div>
  );
}
