import React, { useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useParams } from "react-router";

import { OrganizationResponse } from "@cloudentity/acp-admin";

import B2BOrgViewTour from "../admin/GlobalTours/Tours/B2BOrgViewTour";
import PageContainer from "../admin/components/common/PageContainer";
import { ORGANIZATION_KEY_LOCAL_STORAGE } from "../admin/components/common/useOrganization";
import NavAndContentContainer from "../admin/components/nav/NavAndContentContainer";
import NavDrawer from "../admin/components/nav/NavDrawer";
import { useGetWorkspaceEnvironment } from "../admin/services/adminEnvironmentQuery";
import { useListUserWorkspacePools } from "../admin/services/adminIdentityPoolsQuery";
import { useGetOrganization } from "../admin/services/adminOrganizationsQuery";
import {
  useCheckTenantPermissions,
  useCheckWorkspacePermissions,
} from "../admin/services/adminPermissionsQuery";
import { TOP_BAR_HEIGHT } from "../common/components/nav/utils";
import SelfServiceController from "../common/components/selfServiceSettings/SelfServiceController";
import { useFeature } from "../common/utils/hooks/useFeature";
import { setInLocalStorage } from "../common/utils/localStorage.utils";
import OrganizationsNavDrawerContent from "./OrganizationsNavDrawerContent";
import B2BSignIn from "./components/B2BSignIn";
import Settings from "./components/Settings/Settings";
import B2BSuborganizations from "./components/Suborganizations/B2BSuborganizations";
import B2BPoolController from "./components/Users/B2BPoolController";
import B2BPoolsList from "./components/Users/B2BPoolsList";
import NoAccessState from "./components/Users/NoAccessState";

export function OrgNavDrawer({ organization }: { organization?: OrganizationResponse }) {
  return (
    <NavDrawer content={OrganizationsNavDrawerContent} props={{ organization: organization }} />
  );
}

export default function B2BOrganizationView() {
  const { id } = useParams<{ id: string }>();
  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canCreateOrg = !!checkTenantPermissionsQuery.data?.create_organization;
  const isNewOnboardingEnabled = useFeature(
    "new_onboarding",
    !!checkTenantPermissionsQuery.data?.read_system_environment
  );

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(id);
  const getOrganizationQuery = useGetOrganization(id, {
    enabled: !!id && !!checkWorkspacePermissionsQuery.data?.get_organization,
  });

  const currentOrganization = useMemo(() => getOrganizationQuery.data, [getOrganizationQuery.data]);

  const listMyWorkspacePoolsQuery = useListUserWorkspacePools({ wid: id! });
  const getWorkspaceEnvironmentQuery = useGetWorkspaceEnvironment(id!, { enabled: !!id });

  const canAddPool = checkWorkspacePermissionsQuery.data?.create_identity_pool;

  const hasAccess =
    getOrganizationQuery.isSuccess &&
    listMyWorkspacePoolsQuery.isSuccess &&
    (canAddPool || !!listMyWorkspacePoolsQuery.data.pools?.length);

  useEffect(() => {
    if (currentOrganization) {
      setInLocalStorage(ORGANIZATION_KEY_LOCAL_STORAGE, currentOrganization.id ?? "");
    }
  }, [currentOrganization]);

  useEffect(() => {
    if (!getOrganizationQuery.data && !getOrganizationQuery.isLoading) {
      setInLocalStorage(ORGANIZATION_KEY_LOCAL_STORAGE, "");
    }
  }, [getOrganizationQuery.data, getOrganizationQuery.isLoading]);

  return (
    <PageContainer
      progress={
        listMyWorkspacePoolsQuery.isLoading ||
        getOrganizationQuery.isLoading ||
        getWorkspaceEnvironmentQuery.isLoading
      }
    >
      {!hasAccess && !getWorkspaceEnvironmentQuery.data?.self_service && <NoAccessState />}
      {!hasAccess && getWorkspaceEnvironmentQuery.data?.self_service && (
        <div style={{ marginTop: TOP_BAR_HEIGHT }}>
          <SelfServiceController
            config={{
              withDialog: false,
              showMetadata: true,
            }}
            wrapperStyle={{ minHeight: `calc(100vh - ${TOP_BAR_HEIGHT}px)` }}
          />
        </div>
      )}
      {hasAccess && (
        <NavAndContentContainer nav={() => OrgNavDrawer({ organization: currentOrganization })}>
          <Routes>
            <Route path="/" element={<Navigate to="users" replace />} />
            <Route path="/users" element={<B2BPoolsList workspaceId={id!} />} />
            <Route path="/users/:poolId" element={<B2BPoolController />} />
            <Route path="/suborganizations" element={<B2BSuborganizations />} />
            <Route path="/sign-in" element={<B2BSignIn />} />
            <Route
              path="/settings/:tab"
              element={<Settings organization={currentOrganization} />}
            />
            <Route path="*" element={<Navigate to="settings/general" replace />} />
          </Routes>
        </NavAndContentContainer>
      )}
      {isNewOnboardingEnabled && canCreateOrg && <B2BOrgViewTour />}
    </PageContainer>
  );
}
