import React, { useMemo, useState } from "react";

import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";

import { Server, ServerResponseProfileEnum } from "@cloudentity/acp-admin";

import fapiAdvanced from "../../../assets/images/icons/workspaceDirectory/fapi-advanced.svg";
import fapiRW from "../../../assets/images/icons/workspaceDirectory/fapi-rw.svg";
import FormSection from "../../../common/components/FormSection";
import Progress from "../../../common/components/Progress";
import { handleErrorWithNotify } from "../../../common/components/notifications/notificationService";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import CheckboxField from "../../../common/utils/forms/CheckboxField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormCardsField from "../../../common/utils/forms/FormCardsField";
import SwitchField from "../../../common/utils/forms/SwitchField";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../common/utils/forms/validation";
import { trimStringValues } from "../../../common/utils/object.utils";
import { toSlugString } from "../../../common/utils/string.utlis";
import adminServersApi from "../../services/adminServersApi";
import { listWorkspacesQueryKey } from "../../services/adminServersQuery";
import WorkspaceAvatar from "../nav/WorkspaceAvatar";
import WelcomeTypeCard from "../welcome/WelcomeTypeCard";
import { StepData } from "../welcome/useWorkspaceDirectoryData";
import WorkspacesColorInput from "./WorkspacesColorInput";
import WorkspacesPreviewInput from "./WorkspacesPreviewInput";
import {
  getServerAvatarSquare,
  getServerDefaultColorByProfile,
  isOPBrazil,
  isOPCDR,
  isOpenbanking,
} from "./server-profiles";
import { getWorkspaceAppTypeByValue } from "./workspace-directory.common";

interface Props {
  stepsData: StepData;
  actionsLeftMargin?: number;
  onChangeStepsData: (data: StepData) => void;
  onNext: () => void;
  onBack: () => void;
  onWorkspaceCreate?: (id: string) => void;
}

export default function WorkspaceDirectoryCreateAddWorkspaceDetailsStep({
  stepsData,
  actionsLeftMargin = 480,
  onChangeStepsData,
  onNext,
  onBack,
  onWorkspaceCreate,
}: Props) {
  const [createServerProgress, setCreateServerProgress] = useState(false);

  const typeCardData = getWorkspaceAppTypeByValue(stepsData["defineWorkspaceType"].selected);

  const data = useMemo(
    () => ({
      ...stepsData["addWorkspaceDetails"],
      profile: typeCardData?.profile,
      cdr: {
        industry: "banking",
        adr_validation_enabled: true,
      },
      obbr: {
        industry: "banking",
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const form = useForm({
    id: "workspace-directory-create-add-workspace-details-step",
    initialValues: data,
  });

  const queryClient = useQueryClient();

  const profile = form.watch("profile");
  const name = form.watch("name");

  const cdrAdrValidationEnabled = form.watch("cdr.adr_validation_enabled");

  const isCDRProfile = isOPCDR(profile);
  const isOPB = isOpenbanking(profile);
  const isOBBRProfile = isOPBrazil(profile);
  const isConsumerWorkspace = profile === ServerResponseProfileEnum.Consumer;

  const isFapi20Visible =
    !!profile &&
    [
      ServerResponseProfileEnum.FapiAdvanced,
      ServerResponseProfileEnum.Fapi20Security,
      ServerResponseProfileEnum.Fapi20MessageSigning,
    ].includes(profile);

  const setStepDataByField = (field, value) =>
    onChangeStepsData({
      ...stepsData,
      addWorkspaceDetails: {
        ...stepsData.addWorkspaceDetails,
        [field]: value,
      },
    });
  const setStepData = value =>
    onChangeStepsData({
      ...stepsData,
      addWorkspaceDetails: value,
    });
  const handleChange = field => e => setStepDataByField(field, e.target.value);
  const handleChangeCheckbox = field => e => setStepDataByField(field, e.target.checked);

  const handleChangeName = e => {
    const slugId = toSlugString(e.target.value);

    form.setValue("id", slugId);
    form.setValue("name", e.target.value);
    setStepData({ ...stepsData["addWorkspaceDetails"], id: slugId, name: e.target.value });
  };

  const handleSubmit = data => {
    setCreateServerProgress(true);
    adminServersApi
      .createServer({
        server: {
          ...trimStringValues(data),
          color: stepsData["addWorkspaceDetails"].color,
          profile,
          ...(isCDRProfile
            ? {
                cdr: {
                  industry: data.cdr.industry,
                  adr_validation_enabled: data.cdr.adr_validation_enabled,
                  register_url: data.cdr.register_url,
                },
              }
            : {}),
          ...(isOBBRProfile
            ? {
                obbr: {
                  industry: data.obbr.industry,
                },
              }
            : {}),
          ...(isConsumerWorkspace
            ? {
                enable_idp_discovery: true,
              }
            : {}),
        } as Server,
        withDemoClient:
          data.profile !== "partners" && stepsData["addWorkspaceDetails"].with_demo_client,
      })
      .then(res => {
        setStepDataByField("authorizationServer", res.data);
        onNext();
        if (onWorkspaceCreate) {
          onWorkspaceCreate(res.data.id ?? "");
        }
      })
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspacesQueryKey() }))
      .catch(err => {
        if (err.response.status === 409) {
          handleErrorWithNotify("Workspace ID is already used, please choose different one")(err);
        } else {
          handleErrorWithNotify("Error occur while trying to create workspace")(err);
        }
      })
      .finally(() => setCreateServerProgress(false));
  };

  return (
    <Form form={form}>
      {createServerProgress && <Progress isGlobalLoader />}
      <Paper style={{ padding: "24px 22px 12px", marginBottom: 32 }}>
        {typeCardData && (
          <WelcomeTypeCard
            id={`welcome-card-type-${typeCardData.value}`}
            title={typeCardData.title}
            subtitle={typeCardData.subtitle}
            avatar={getServerAvatarSquare({ profile })}
            selectable={false}
          />
        )}

        <div style={{ marginTop: 46 }}>
          <TextFieldRequired
            name="name"
            label="Display Name"
            onChange={handleChangeName}
            autoFocus
          />
          <TextFieldRequired
            name="id"
            label="Workspace ID"
            helperText="Once created this cannot be changed later"
            rules={{
              validate: {
                validID: validators.validID({ label: "Workspace ID" }),
              },
            }}
            onChange={handleChange("id")}
          />
          {isOBBRProfile && (
            <AutocompleteField
              name="obbr.industry"
              label="Industry"
              options={["banking", "insurance"]}
              rules={{
                required: "This field is required",
              }}
            />
          )}
          {isCDRProfile && (
            <AutocompleteField
              name="cdr.industry"
              label="Industry"
              options={["banking", "energy", "telco", "all"]}
              rules={{
                required: "This field is required",
              }}
            />
          )}
          {isCDRProfile && (
            <CheckboxField
              name="cdr.adr_validation_enabled"
              label="Enable ADR Validation"
              helperText="For testing purposes you can skip ADR validation"
            />
          )}
          {isCDRProfile && cdrAdrValidationEnabled && (
            <TextFieldRequired
              name="cdr.register_url"
              label="Register URL"
              helperText="URL to register maintained by the Australian Competition and Consumer Commission (ACCC)"
              defaultValue="https://api.cdr.gov.au"
              rules={{
                validate: {
                  validURL: validators.validURL({ label: "Register URL" }),
                },
              }}
            />
          )}
          <WorkspacesColorInput
            value={
              stepsData["addWorkspaceDetails"].color || getServerDefaultColorByProfile(profile)
            }
            onChange={color => setStepDataByField("color", color)}
          />
          <WorkspacesPreviewInput
            name={name}
            color={stepsData["addWorkspaceDetails"].color}
            icon={
              <WorkspaceAvatar
                server={{
                  profile,
                  color: stepsData["addWorkspaceDetails"].color,
                }}
              />
            }
            style={{ marginBottom: 32 }}
          />
          {profile !== "partners" && !isOPB && (
            <SwitchField
              name="with_demo_client"
              label="Enable demo application"
              onChange={handleChangeCheckbox("with_demo_client")}
            />
          )}
          {((!!profile &&
            [
              ServerResponseProfileEnum.OpenbankingUkFapiAdvanced,
              ServerResponseProfileEnum.OpenbankingUk,
              ServerResponseProfileEnum.CdrAustralia,
              ServerResponseProfileEnum.CdrAustraliaFapiRw,
            ].includes(profile)) ||
            isFapi20Visible) && (
            <FormSection
              id="workspace-directory-create-add-workspace-details-step-fapi-section"
              title="Financial-grade API specifications"
              subtitle={
                <span>
                  Ensure that this server enforces FAPI regulations.{" "}
                  <Link
                    href="https://cloudentity.com/developers/basics/fapi/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </Link>
                </span>
              }
            />
          )}
          {!!profile &&
            [
              ServerResponseProfileEnum.OpenbankingUkFapiAdvanced,
              ServerResponseProfileEnum.OpenbankingUk,
            ].includes(profile) && (
              <FormCardsField
                name="profile"
                cards={[
                  {
                    title: "FAPI Advanced",
                    subtitle: "",
                    value: ServerResponseProfileEnum.OpenbankingUkFapiAdvanced,
                    img: fapiAdvanced,
                  },
                  {
                    title: "FAPI Read/Write",
                    subtitle: "",
                    value: ServerResponseProfileEnum.OpenbankingUk,
                    img: fapiRW,
                  },
                ]}
              />
            )}
          {isCDRProfile && (
            <FormCardsField
              name="profile"
              cards={[
                {
                  title: "CDR Australia Advanced",
                  subtitle: "",
                  value: ServerResponseProfileEnum.CdrAustralia,
                  img: fapiAdvanced,
                },
                {
                  title: "CDR Australia FAPI Read/Write",
                  subtitle: "",
                  value: ServerResponseProfileEnum.CdrAustraliaFapiRw,
                  img: fapiRW,
                },
              ]}
            />
          )}
          {isFapi20Visible && (
            <FormCardsField
              name="profile"
              cards={[
                {
                  title: "FAPI 1.0 Advanced",
                  subtitle: "",
                  value: ServerResponseProfileEnum.FapiAdvanced,
                  img: fapiAdvanced,
                },
                {
                  title: "FAPI 2.0 Security Profile",
                  subtitle: "",
                  value: ServerResponseProfileEnum.Fapi20Security,
                  img: fapiRW,
                },
                {
                  title: "FAPI 2.0 Message Signing",
                  subtitle: "",
                  value: ServerResponseProfileEnum.Fapi20MessageSigning,
                  img: fapiRW,
                },
              ]}
            />
          )}
        </div>
      </Paper>

      <div
        style={{
          position: "fixed",
          padding: "0 32px",
          marginLeft: actionsLeftMargin,
          height: 80,
          background: "#fff",
          borderTop: "1px solid #ECECEC",
          display: "flex",
          bottom: 0,
          left: 0,
          right: 0,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          size="large"
          id="back-btn"
          variant="outlined"
          color="primary"
          disabled={createServerProgress}
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          size="large"
          id="next-btn"
          variant="contained"
          color="primary"
          disabled={createServerProgress}
          onClick={form.handleSubmit(handleSubmit)}
        >
          Next
        </Button>
      </div>
    </Form>
  );
}
