import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";

import Tab from "@mui/material/Tab";
import { useQueryClient } from "@tanstack/react-query";

import { System } from "@cloudentity/acp-permissions";

import { getTenantId } from "../../../../common/api/paths";
import Breadcrumbs from "../../../../common/components/Breadcrumbs";
import Progress from "../../../../common/components/Progress";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import permissionsSystemsApi from "../../../services/adminPermissionsSystemsApi";
import {
  getPermissionSystemQueryKey,
  listPermissionSystemsQueryKey,
  useGetPermissionSystem,
  useListPermissionSystems,
} from "../../../services/adminPermissionsSystemsQuery";
import { useCheckTenantPermissions } from "../../../services/adminTenantsQuery";
import PageContent from "../../common/PageContent";
import PageHeader from "../../common/PageHeader";
import PermissionConfiguration from "./PermissionConfiguration";
import PermissionQuickstart from "./PermissionQuickstart";
import PermissionSchema from "./PermissionSchema";
import PermissionTokens from "./PermissionTokens";

function PermissionController() {
  const { id = "", tab } = useParams<{ id: string; tab: string }>();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const tenantId = getTenantId();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();

  const permissionSystemQuery = useGetPermissionSystem(id, !isRemoved);
  const system = permissionSystemQuery.data ?? { id: "", name: "", prefix: "", tenant_id: "" };
  const queryClient = useQueryClient();

  const systemsQuery = useListPermissionSystems(tenantId);
  const systemNames = (systemsQuery.data?.systems ?? []).map(system => system.name);

  const handleUpdate = (data: System) => {
    setProgress(true);
    permissionsSystemsApi
      .updateSystem({ psID: id, system: { ...permissionSystemQuery.data, ...data } })
      .then(res => queryClient.setQueryData(getPermissionSystemQueryKey(tenantId, id), res.data))
      .then(() => notifySuccess("Permission system updated successfully"))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listPermissionSystemsQueryKey(tenantId) })
      )
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to update permission system"))
      .finally(() => setProgress(false));
  };

  const handleUpdateSchema = (schema: string) => {
    return permissionsSystemsApi
      .updateSystem({ psID: id, system: { ...permissionSystemQuery.data, schema } as any })
      .then(res => queryClient.setQueryData(getPermissionSystemQueryKey(tenantId, id), res.data))
      .then(() => notifySuccess("Schema updated successfully"))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listPermissionSystemsQueryKey(tenantId) })
      );
  };

  const handleRemove = () => {
    setIsRemoved(true);
    setProgress(true);
    permissionsSystemsApi
      .deleteSystem({ psID: id })
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listPermissionSystemsQueryKey(getTenantId()) })
      )
      .then(() => navigate("/permissions"))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to delete permission system"))
      .finally(() => {
        setIsRemoved(false);
        setProgress(false);
      });
  };

  const handleTabChange = (tab: string) => {
    navigate(`/permissions/${id}/${tab}`);
  };

  const fullWidth = ["tokens"].includes(tab ?? "");

  return (
    <div style={{ position: "relative" }}>
      {permissionSystemQuery.isLoading || progress ? (
        <Progress />
      ) : (
        <>
          <PageHeader
            title={permissionSystemQuery.data?.name ?? ""}
            breadcrumb={<Breadcrumb label={permissionSystemQuery.data?.name ?? "details"} />}
            tabs={[
              <Tab
                label="Quickstart"
                value="quickstart"
                key="permission-quickstart"
                id="permission-quickstart"
              />,
              <Tab
                label="Configuration"
                value="configuration"
                key="permission-configuration"
                id="permission-configuration"
              />,
              <Tab label="Schema" value="schema" key="permission-schema" id="permission-schema" />,
              <Tab label="Tokens" value="tokens" key="permission-tokens" id="permission-tokens" />,
            ]}
            onChangeTab={handleTabChange}
            currentTab={tab}
          />
          <PageContent fullWidth={fullWidth}>
            {tab === "quickstart" && (
              <PermissionQuickstart id={id} prefix={system.prefix ?? "PREFIX"} />
            )}
            {tab === "configuration" && (
              <PermissionConfiguration
                system={system}
                systemNames={systemNames}
                onUpdate={handleUpdate}
                onRemove={handleRemove}
              />
            )}
            {tab === "schema" && <PermissionSchema system={system} onUpdate={handleUpdateSchema} />}
            {tab === "tokens" && (
              <PermissionTokens
                readonly={!checkTenantPermissionsQuery.data?.manage_permission_systems}
              />
            )}
          </PageContent>
        </>
      )}
    </div>
  );
}

const Breadcrumb = ({ label }: { label: string }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumbs
      items={[
        {
          key: "list",
          label: "Permission Systems List",
          onClick: () => navigate("/permissions"),
        },
        { key: "detail", label },
      ]}
    />
  );
};

export default PermissionController;
