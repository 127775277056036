import React, { ReactNode } from "react";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { X } from "react-feather";

import { GlobalToursContext } from "../GlobalTours";

interface Props {
  header: string;
  description: ReactNode;
  buttonText: string;
  handleButtonClick: () => void;
  tour: string;
  hideCloseButton?: boolean;
}

const Slide = ({
  header,
  description,
  buttonText,
  handleButtonClick,
  tour,
  hideCloseButton,
}: Props) => {
  const globalToursContext = React.useContext(GlobalToursContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
      }}
    >
      <h3>{header}</h3>
      <div style={{ flex: 1 }}>{description}</div>
      <Button
        id="get-started"
        variant="contained"
        color="primary"
        style={{ margin: "32px 0" }}
        onClick={handleButtonClick}
        size="large"
      >
        {buttonText}
      </Button>
      {!hideCloseButton && (
        <IconButton
          style={{ position: "absolute", top: 0, right: 0, color: "white" }}
          onClick={() => {
            globalToursContext.completeAndStopAll(tour);
          }}
          id="skip-tour-button"
          size="large"
        >
          <X />
        </IconButton>
      )}
    </div>
  );
};

export default Slide;
