import { useQuery } from "@tanstack/react-query";

import { ScopesWithServices } from "@cloudentity/acp-admin";

import adminScopesApi from "./adminScopesApi";
import { withQueryError } from "./queryUtils";

const GET_SCOPES_QUERY = "GET_SCOPES_QUERY";

export const getScopesQueryKey = (tid: string, wid: string) => [GET_SCOPES_QUERY, tid, wid];

export const useGetScopes = (tid: string, wid: string, options?: any) =>
  useQuery<ScopesWithServices>({
    queryKey: getScopesQueryKey(tid, wid),
    queryFn: withQueryError(async () => {
      const data = await adminScopesApi.getScopes({ wid });
      return data.data;
    }, "Error occurred while trying to fetch scopes"),
    ...options,
  });
