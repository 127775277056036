import React, { useState } from "react";
import { useNavigate } from "react-router";

import { OrganizationResponse, WorkspaceResponse } from "@cloudentity/acp-admin";

import PageContainer from "../admin/components/common/PageContainer";
import PageContent from "../admin/components/common/PageContent";
import PageHeader from "../admin/components/common/PageHeader";
import OrganizationCardMenu from "../admin/components/workspaceDirectory/organizations/OrganizationCardMenu";
import {
  B2B_ORGANIZATIONS_GRID_MODE_KEY,
  GridMode,
} from "../admin/components/workspaceDirectory/organizations/utils";
import { useCheckTenantPermissions } from "../admin/services/adminTenantsQuery";
import { getFromLocalStorage } from "../common/utils/localStorage.utils";
import B2BOrganizationsCards from "./B2BOrganizationsCards";
import B2BOrganizationsHierarchy from "./B2BOrganizationsHierarchy";
import B2BAddOrganization from "./directory/B2BAddOrganization";

export default function B2BOrganizations() {
  const navigate = useNavigate();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canListOrganizations = checkTenantPermissionsQuery?.data?.list_organizations;
  const defaultGridMode =
    (canListOrganizations && (getFromLocalStorage(B2B_ORGANIZATIONS_GRID_MODE_KEY) as GridMode)) ||
    "cards";
  const [gridMode, setGridMode] = useState<GridMode>(defaultGridMode);

  const [createDialog, setCreateDialog] = useState(false);

  const handleSelectServer = (id: string) => {
    navigate(`/organizations/${id}`);
  };

  const [cardMenu, setCardMenu] = useState<
    | {
        anchorEl: HTMLElement | null;
        server: OrganizationResponse;
        rootServer?: OrganizationResponse | WorkspaceResponse | undefined;
      }
    | undefined
  >();

  return (
    <PageContainer>
      <PageHeader title="Organizations" />
      <PageContent progress={checkTenantPermissionsQuery.isLoading}>
        <div
          style={{ display: "none" }}
          id="b2b-landing-version"
          data-testid="organizations-directory-with-navigation"
        ></div>

        {gridMode === "cards" && (
          <B2BOrganizationsCards
            onMenuOpen={setCardMenu}
            onCreate={() => setCreateDialog(true)}
            onSetGridMode={setGridMode}
          />
        )}

        {gridMode === "hierarchy" && (
          <B2BOrganizationsHierarchy
            onMenuOpen={setCardMenu}
            onCreate={() => setCreateDialog(true)}
            onSetGridMode={setGridMode}
            onSelectServer={handleSelectServer}
          />
        )}

        {createDialog && (
          <B2BAddOrganization
            onCancel={() => setCreateDialog(false)}
            onSkip={() => setCreateDialog(false)}
            onInviteSent={() => setCreateDialog(false)}
          />
        )}

        {cardMenu && (
          <OrganizationCardMenu
            mainMenuAnchorEl={cardMenu.anchorEl}
            handleClose={() => setCardMenu(p => (p ? { ...p, anchorEl: null } : undefined))}
            workspace={cardMenu.server}
            onSelectServer={handleSelectServer}
            withChangeParentOrganization={!!cardMenu.server.parent_id && gridMode === "hierarchy"}
            rootServer={cardMenu.rootServer}
            onCreate={() => setCreateDialog(true)}
            gridMode={gridMode}
          />
        )}
      </PageContent>
    </PageContainer>
  );
}
