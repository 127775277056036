import { useState } from "react";

export type AuthorizationStoreContextType = {
  authorized: boolean;
  setAuthorized: (authorized: boolean) => void;
};

export const useAuthorizationStore = (): AuthorizationStoreContextType => {
  const [store, setStore] = useState({ authorized: false });

  const setAuthorized = (authorized: boolean) => {
    setStore(store => ({
      ...store,
      authorized,
    }));
  };

  return {
    authorized: store.authorized,
    setAuthorized,
  };
};
