import React, { lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { ServerResponseProfileEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import Button from "../../../../common/components/Button";
import LoadingButton from "../../../../common/components/LoadingButton";
import { useFeature } from "../../../../common/utils/hooks/useFeature";
import { useGetTenant } from "../../../services/adminTenantsQuery";
import { useMarkGetStartedVisited } from "../../getStarted/GetStarted";
import GetStartedCard from "../../getStarted/GetStartedCard";
import {
  createDemoWorkspace,
  getDemoWorkspaceId,
} from "../../workspaceDirectory/WorkspaceDirectoryCreateDefineWorkspaceTypeStep";
import { getServerAvatarIcon } from "../../workspaceDirectory/server-profiles";
import { SmallInfoText, Steps, useCommonStyles } from "../utils";

const PasskeyLottie = lazy(() => import("../animations/PasskeyLottie"));

const useStyles = makeStyles()(() => ({
  container: {
    padding: 32,
    height: "100%",
    width: 650,
    borderRight: "1px solid #D0D5DD",
  },
  content: {
    position: "relative",
    marginBottom: 32,
    display: "flex",
    flexDirection: "column",
    gap: 24,
    width: "100%",
  },
  right: {
    flex: 1,
    backgroundColor: "#e0f9c7",
  },
}));

interface Props {
  onChangeStep: (step: Steps) => void;
}

export default function SelectOpenbanking({ onChangeStep }: Props) {
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const queryClient = useQueryClient();
  const getTenantQuery = useGetTenant(getTenantId());
  const navigate = useNavigate();
  const isOBBREnabled = useFeature("obbr");
  const isConnectIDEnabled = useFeature("connect_id");

  const [progress, setProgress] = useState(false);
  const [selected, setSelected] = useState<ServerResponseProfileEnum | null>(null);

  const {
    markGetStartedVisited,
    progress: markProgress,
    shouldVisitGetStarted,
  } = useMarkGetStartedVisited();

  const handleTryDemoOpenbanking = () => {
    if (!selected) return;

    setProgress(true);

    const id = getDemoWorkspaceId(selected);

    createDemoWorkspace(selected, id, queryClient, getTenantQuery.data)
      .then(() => {
        if (shouldVisitGetStarted) {
          markGetStartedVisited();
        }
      })
      .finally(() => {
        setProgress(false);
        navigate(`/${id}/overview`);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <>
      <div className={commonClasses.leftWithPreview}>
        <div className={classes.container}>
          <div className={commonClasses.intro}>
            <Typography variant="h2" className={commonClasses.header}>
              Select your ecosystem
            </Typography>
            <SmallInfoText fontSize={14}>
              We provide compliance profiles for global open banking
            </SmallInfoText>

            <Divider className={commonClasses.divider} />
          </div>

          <div className={classes.content}>
            <GetStartedCard
              label="Financial Data Exchange"
              icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.Fdx })}
              selectable={!(progress || markProgress)}
              onSelect={() => setSelected(ServerResponseProfileEnum.Fdx)}
              selected={selected === ServerResponseProfileEnum.Fdx}
              id="fdx-card"
              noChevron
            />
            <GetStartedCard
              label="CDR Australia"
              icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.CdrAustralia })}
              selectable={!(progress || markProgress)}
              onSelect={() => setSelected(ServerResponseProfileEnum.CdrAustralia)}
              selected={selected === ServerResponseProfileEnum.CdrAustralia}
              id="cdr-card"
              noChevron
            />
            {isOBBREnabled && (
              <GetStartedCard
                label="Open Finance Brazil"
                icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.OpenbankingBr })}
                selectable={!(progress || markProgress)}
                onSelect={() => setSelected(ServerResponseProfileEnum.OpenbankingBr)}
                selected={selected === ServerResponseProfileEnum.OpenbankingBr}
                id="obbr-card"
                noChevron
              />
            )}
            <GetStartedCard
              label="Open Banking UK"
              icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.OpenbankingUk })}
              selectable={!(progress || markProgress)}
              onSelect={() => setSelected(ServerResponseProfileEnum.OpenbankingUkFapiAdvanced)}
              selected={selected === ServerResponseProfileEnum.OpenbankingUkFapiAdvanced}
              id="obuk-card"
              noChevron
            />
            <GetStartedCard
              label="Other/Private ecosystem/FAPI"
              icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.Fapi20Security })}
              selectable={!(progress || markProgress)}
              onSelect={() => setSelected(ServerResponseProfileEnum.Fapi20Security)}
              selected={selected === ServerResponseProfileEnum.Fapi20Security}
              id="fapi-card"
              noChevron
            />
            {isConnectIDEnabled && (
              <GetStartedCard
                label="Connect ID"
                icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.ConnectId })}
                selectable={!(progress || markProgress)}
                onSelect={() => setSelected(ServerResponseProfileEnum.ConnectId)}
                selected={selected === ServerResponseProfileEnum.ConnectId}
                id="connect-id-card"
                noChevron
              />
            )}
            <GetStartedCard
              label="Open Banking Kingdom Saudi Arabia"
              icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.OpenbankingKsa })}
              selectable={!(progress || markProgress)}
              onSelect={() => setSelected(ServerResponseProfileEnum.OpenbankingKsa)}
              selected={selected === ServerResponseProfileEnum.OpenbankingKsa}
              id="obksa-card"
              noChevron
            />
          </div>
          <div className={commonClasses.flexContainer}>
            <Button
              variant="text"
              onClick={() => onChangeStep(Steps.WELCOME)}
              color="inherit"
              className={commonClasses.backButton}
            >
              Back
            </Button>
            <LoadingButton
              variant="contained"
              loading={progress}
              disabled={!selected}
              onClick={() => {
                handleTryDemoOpenbanking();
              }}
            >
              Continue
            </LoadingButton>
          </div>
        </div>
      </div>
      <div className={classes.right}>
        <PasskeyLottie />
      </div>
    </>
  );
}
