import {
  RolesApi,
  RolesApiGrantIdentityPoolRoleRequest,
  RolesApiGrantTenantRoleRequest,
  RolesApiGrantWorkspaceRoleRequest,
  RolesApiListIdentityPoolRolesRequest,
  RolesApiListTenantRolesRequest,
  RolesApiListUserRolesRequest,
  RolesApiListWorkspaceRolesRequest,
  RolesApiRevokeIdentityPoolRoleRequest,
  RolesApiRevokeTenantRoleRequest,
  RolesApiRevokeWorkspaceRoleRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const rolesApi: () => RolesApi = adminApiClazz(RolesApi);

const adminRolesApi = {
  listUserRoles: (req: RolesApiListUserRolesRequest) => rolesApi().listUserRoles(req),

  listTenantRoles: (req: RolesApiListTenantRolesRequest) => rolesApi().listTenantRoles(req),
  grantTenantRole: (req: RolesApiGrantTenantRoleRequest) => rolesApi().grantTenantRole(req),
  revokeTenantRole: (req: RolesApiRevokeTenantRoleRequest) => rolesApi().revokeTenantRole(req),

  listWorkspaceRoles: (req: RolesApiListWorkspaceRolesRequest) =>
    rolesApi().listWorkspaceRoles(req),
  grantWorkspaceRole: (req: RolesApiGrantWorkspaceRoleRequest) =>
    rolesApi().grantWorkspaceRole(req),
  revokeWorkspaceRole: (req: RolesApiRevokeWorkspaceRoleRequest) =>
    rolesApi().revokeWorkspaceRole(req),

  listIdentityPoolRoles: (req: RolesApiListIdentityPoolRolesRequest) =>
    rolesApi().listIdentityPoolRoles(req),
  grantIdentityPoolRole: (req: RolesApiGrantIdentityPoolRoleRequest) =>
    rolesApi().grantIdentityPoolRole(req),
  revokeIdentityPoolRole: (req: RolesApiRevokeIdentityPoolRoleRequest) =>
    rolesApi().revokeIdentityPoolRole(req),
};

export default adminRolesApi;
