import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import some from "lodash/some";
import { Eye, Feather, Grid as GridIcon, Settings, Shield, Star, Tool, Users } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { ServerResponseProfileEnum, ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../common/api/paths";
import Progress from "../../../common/components/Progress";
import AnalyticsIcon from "../../../common/components/icons/AnalyticsIcon";
import AuthZServerIcon from "../../../common/components/icons/AuthZServerIcon";
import AuthenticationIcon from "../../../common/components/icons/AuthenticationIcon";
import ExtensionsIcon from "../../../common/components/icons/ExtensionsIcon";
import LogsIcon from "../../../common/components/icons/LogsIcon";
import OBQuickstartIcon from "../../../common/components/icons/OBQuickstartIcon";
import OrganizationsIcon from "../../../common/components/icons/OrganizationsIcon";
import {
  useFeature,
  useFeatureIsOrganizationsEnabled,
} from "../../../common/utils/hooks/useFeature";
import { useLocalStorage } from "../../../common/utils/hooks/useLocalStorage";
import { getFromLocalStorage } from "../../../common/utils/localStorage.utils";
import { useListWorkspacePools } from "../../services/adminIdentityPoolsQuery";
import { useGetWorkspace } from "../../services/adminServersQuery";
import { isSystemTenantAdminWorkspace } from "../AdminPanel";
import { useWorkspace } from "../common/useWorkspace";
import {
  checkIfDemoWorkspace,
  getHasOpenBankingQuickstartView,
  isOpenbanking,
} from "../workspaceDirectory/server-profiles";
import WorkspaceSelector from "../workspaces/WorkspaceSelector";
import { NavDrawerContentProps } from "./NavDrawer";
import NavItem from "./NavItem";
import { NavModeChanger } from "./NavModeChanger";
import NavSection from "./NavSection";
import NavSectionItem from "./NavSectionItem";
import { useSections } from "./useSections";

const useStyles = makeStyles()(() => ({
  listContainer: {
    overflowY: "auto",
    height: "100%",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },
  listPadding: {
    padding: 0,
  },
  link: {
    display: "flex",
    alignItems: "center",
    color: "inherit",
    cursor: "pointer",
  },
}));

export default function AdminNavDrawerContent({
  isOpen,
  onOpenDrawer,
  onSectionClick,
  onItemClick,
}: NavDrawerContentProps) {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const tenantId = getTenantId();
  const [workspace] = useWorkspace();
  const navigate = useNavigate();
  const isOrganizationsEnabled = useFeatureIsOrganizationsEnabled();
  const isAnalyticsEnabled = useFeature("with_analytics");
  const isNewOnboardingEnabled = useFeature("new_onboarding");

  const [navMode] = useLocalStorage(`${workspace}_nav_mode`);

  const serverQuery = useGetWorkspace(workspace);
  const server = serverQuery.data;
  const workspacePoolsQuery = useListWorkspacePools(
    { wid: workspace },
    { enabled: workspace === "admin" }
  );
  const hasOpenBankingQuickstartView = getHasOpenBankingQuickstartView(
    server?.profile,
    server?.id ?? ""
  );

  const isConsumerWorkspace =
    serverQuery.data?.profile === ServerResponseProfileEnum.Consumer || false;
  const isOrganizationWorkspace =
    serverQuery.data?.type === ServerResponseTypeEnum.Organization || false;

  const isOpenbankingWorkspace = isOpenbanking(serverQuery.data?.profile);
  const isOrganizationOrConsumerWorkspace = isConsumerWorkspace || isOrganizationWorkspace;
  const isDemoWorkspace = checkIfDemoWorkspace(workspace, serverQuery.data?.profile);

  const initSections = useMemo(
    () => ({
      quickstart: {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/quickstart`),
      },
      "openbanking-quickstart": {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/openbanking`),
      },
      overview: {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/overview`),
      },
      dashboards: {
        isActive: (pathname, workspace) =>
          pathname.startsWith(`/${workspace}/overview`) ||
          pathname.startsWith(`/${workspace}/audit-events`),
      },
      authentication: {
        isActive: (pathname, workspace) =>
          pathname.startsWith(`/${workspace}/authentication/identities`) ||
          pathname.startsWith(`/${workspace}/identities`) ||
          ((navMode === "advanced" || !isOrganizationOrConsumerWorkspace) &&
            pathname.startsWith(`/${workspace}/authentication/settings`)),
      },
      applications: {
        isActive: (pathname, workspace) =>
          pathname.startsWith(`/${workspace}/applications`) ||
          ((navMode === "advanced" || !isOrganizationOrConsumerWorkspace) &&
            (pathname.startsWith(`/${workspace}/application-builder`) ||
              pathname.startsWith(`/${workspace}/services`))),
      },
      governance: {
        isActive: (pathname, workspace) =>
          pathname.startsWith(`/${workspace}/visualizer`) ||
          pathname.startsWith(`/${workspace}/policies`) ||
          pathname.startsWith(`/${workspace}/apis/`) ||
          pathname.startsWith(`/${workspace}/gateways`) ||
          pathname.startsWith(`/${workspace}/workspaces/authorization`),
      },
      extensions: {
        isActive: (pathname, workspace) =>
          pathname.startsWith(`/${workspace}/extensions`) ||
          pathname.startsWith(`/${workspace}/secrets`) ||
          pathname.startsWith(`/${workspace}/custom-applications`) ||
          pathname.startsWith(`/${workspace}/webhooks`),
      },
      "oauth-server": {
        isActive: (pathname, workspace) =>
          pathname.startsWith(`/${workspace}/workspaces/`) &&
          !pathname.startsWith(`/${workspace}/workspaces/authorization`),
      },
      analytics: {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/analytics`),
      },
      appearance: {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/appearance`),
      },
      "audit-logs": {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/audit-events`),
      },
      settings: {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/settings/`),
      },
      "users-link": {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/pools`),
      },
      organizations: {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/organizations`),
      },
      "tenant-management-link": {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/tenant-management`),
      },
      "features-link": {
        isActive: (pathname, workspace) => pathname.startsWith(`/${workspace}/features`),
      },
    }),
    [navMode, isOrganizationOrConsumerWorkspace]
  );

  const { sections: openSectionsMap, sectionClick } = useSections(
    initSections,
    isOpen,
    onSectionClick
  );

  // Check after switch to different workspace if in new workspace current navigation item exists, if not redirect to overview
  useEffect(() => {
    const navMode = getFromLocalStorage(`${workspace}_nav_mode`);
    if (!navMode) {
      return;
    }

    const dataToElements = Array.from(document.querySelectorAll("[data-to]"));
    const dataNavSectionIdElements = Array.from(document.querySelectorAll("[data-nav-section-id]"));
    const dataToAttributes = dataToElements.map(item => item.getAttribute("data-to"));
    const dataNavSectionIdAttributes = dataNavSectionIdElements.map(item =>
      item.getAttribute("data-nav-section-id")
    );

    const anySectionActive = some(
      Object.keys(initSections)
        .filter(s => dataNavSectionIdAttributes.includes(s))
        .map(section => initSections[section].isActive(pathname, workspace)),
      v => v
    );
    if (dataToAttributes.length > 2) {
      // allow rendering get started view in workspace view with no such nav item
      if (isNewOnboardingEnabled && isDemoWorkspace && pathname.includes("/get-started-v2")) {
        return;
      }
      if (!anySectionActive) {
        onOpenDrawer();
        navigate(`/${workspace}/overview`, { replace: true });
      }
    }
  }, [
    workspace,
    pathname,
    navigate,
    initSections,
    onOpenDrawer,
    serverQuery,
    isNewOnboardingEnabled,
    isConsumerWorkspace,
    isDemoWorkspace,
  ]);

  if (serverQuery.isLoading || workspacePoolsQuery.isLoading) {
    return <Progress />;
  }

  return (
    <>
      <WorkspaceSelector onClick={onOpenDrawer} />
      <Divider />
      <div className={classes.listContainer} id="side-nav">
        <List classes={{ padding: classes.listPadding }} className={classes.list}>
          {isDemoWorkspace && (
            <NavItem
              id="quickstart"
              text="Quickstart"
              to={`/${workspace}/quickstart`}
              icon={OBQuickstartIcon}
              onClick={onItemClick}
            />
          )}
          {hasOpenBankingQuickstartView && (
            <NavItem
              id="openbanking-quickstart"
              text="Quickstart"
              to={`/${workspace}/openbanking`}
              icon={OBQuickstartIcon}
              onClick={onItemClick}
            />
          )}
          <NavItem
            id="overview"
            text="Overview"
            to={`/${workspace}/overview`}
            icon={Eye}
            onClick={onItemClick}
            isActive={pathname.startsWith(`/${workspace}/overview`)}
          />

          {(!navMode || navMode === "simple") &&
            serverQuery.data?.profile === ServerResponseProfileEnum.Consumer && (
              <NavItem
                id="applications"
                text="Applications"
                to={`/${workspace}/applications`}
                icon={GridIcon}
                onClick={onItemClick}
              />
            )}
          {(navMode === "advanced" || !isOrganizationOrConsumerWorkspace) && (
            <NavSection
              id="applications"
              label="Applications"
              isActive={initSections.applications.isActive(pathname, workspace)}
              isOpen={openSectionsMap.applications.isOpen}
              icon={GridIcon}
              hasOneOfWorkspacePermissions={["read_clients", "read_services"]}
              onClick={sectionClick}
              isDrawerOpen={isOpen}
            >
              {isNewOnboardingEnabled && isDemoWorkspace ? null : (
                <NavSectionItem
                  id="application-builder-link"
                  text="Topology"
                  to={`/${workspace}/application-builder`}
                  hasWorkspacePermission="read_clients"
                  onClick={onItemClick}
                />
              )}
              <NavSectionItem
                id="clients-link"
                text="Clients"
                to={`/${workspace}/applications`}
                hasWorkspacePermission="read_clients"
                onClick={onItemClick}
              />
              <NavSectionItem
                id="services-link"
                text="Services"
                to={`/${workspace}/services`}
                hasWorkspacePermission="read_services"
                onClick={onItemClick}
              />
            </NavSection>
          )}

          {(!navMode || navMode === "simple") && isOrganizationOrConsumerWorkspace && (
            <NavItem
              id="authentication"
              text="Authentication"
              to={`/${workspace}/authentication/identities`}
              icon={AuthenticationIcon}
              onClick={onItemClick}
            />
          )}
          {(navMode === "advanced" || !isOrganizationOrConsumerWorkspace) && (
            <NavSection
              id="authentication"
              label="Authentication"
              isActive={initSections.authentication.isActive(pathname, workspace)}
              isOpen={openSectionsMap.authentication.isOpen}
              icon={AuthenticationIcon}
              hasOneOfWorkspacePermissions={["read_idps"]}
              onClick={sectionClick}
              isDrawerOpen={isOpen}
            >
              <NavSectionItem
                id="providers-link"
                text="Providers"
                to={`/${workspace}/authentication/identities`}
                isActive={
                  pathname.startsWith(`/${workspace}/authentication/identities`) ||
                  pathname.startsWith(`/${workspace}/identities`)
                }
                ignoreMatchAndActiveToAllowNavigate
                hasWorkspacePermission="read_idps"
                onClick={onItemClick}
              />
              <NavSectionItem
                id="settings-link"
                text="Settings"
                to={`/${workspace}/authentication/settings/session`}
                isActive={pathname.startsWith(`/${workspace}/authentication/settings/`)}
                hasWorkspacePermission="read_idps"
                onClick={onItemClick}
              />
            </NavSection>
          )}

          {(navMode === "advanced" || !isOpenbankingWorkspace) &&
            ((workspacePoolsQuery.data?.pools || []).length > 1 || workspace !== "admin") && (
              <NavItem
                id="users-link"
                text={workspace === "admin" ? "Identity Pools" : "Users"}
                to={`/${workspace}/pools`}
                icon={Users}
                hasWorkspacePermission="list_identity_pools"
                onClick={onItemClick}
              />
            )}

          {(navMode === "advanced" || !isOpenbankingWorkspace) && isOrganizationsEnabled && (
            <NavItem
              id="organizations"
              text={isOrganizationWorkspace ? "Suborganizations" : "Organizations"}
              to={`/${workspace}/organizations/organizations`}
              isActive={pathname.startsWith(`/${workspace}/organizations/`)}
              icon={OrganizationsIcon}
              hasWorkspacePermission="get_workspace"
              onClick={onItemClick}
            />
          )}

          <NavItem
            id="appearance"
            text="Appearance"
            to={`/${workspace}/appearance`}
            icon={Feather}
            hasWorkspacePermission="get_workspace"
            onClick={onItemClick}
          />

          {(navMode === "advanced" || !isOrganizationOrConsumerWorkspace) && (
            <NavSection
              id="oauth-server"
              label="OAuth"
              isActive={initSections["oauth-server"].isActive(pathname, workspace)}
              isOpen={openSectionsMap["oauth-server"].isOpen}
              icon={AuthZServerIcon}
              hasOneOfWorkspacePermissions={["get_workspace"]}
              onClick={sectionClick}
              isDrawerOpen={isOpen}
            >
              <NavSectionItem
                id="oauth-server-oauth"
                text="Authorization Server"
                to={`/${workspace}/workspaces/oauth/general`}
                isActive={
                  pathname.startsWith(`/${workspace}/workspaces/oauth`) ||
                  pathname.startsWith(`/${workspace}/workspaces/consent`) ||
                  pathname.startsWith(`/${workspace}/workspaces/advanced`)
                }
                onClick={onItemClick}
              />
              <NavSectionItem
                id="oauth-server-tokens"
                text="Tokens"
                to={`/${workspace}/workspaces/tokens/type`}
                isActive={
                  pathname.startsWith(`/${workspace}/workspaces/tokens`) &&
                  !pathname.startsWith(`/${workspace}/workspaces/tokens/claims`)
                }
                onClick={onItemClick}
              />
              <NavSectionItem
                id="oauth-server-claims"
                text="Claims"
                to={`/${workspace}/workspaces/tokens/claims`}
                isActive={pathname.startsWith(`/${workspace}/workspaces/tokens/claims`)}
                onClick={onItemClick}
              />
            </NavSection>
          )}

          {(navMode === "advanced" || !isOrganizationOrConsumerWorkspace) && (
            <NavSection
              id="governance"
              label="Authorization"
              isActive={initSections.governance.isActive(pathname, workspace)}
              isOpen={openSectionsMap.governance.isOpen}
              icon={Shield}
              hasOneOfWorkspacePermissions={[
                "read_policies",
                "read_idps",
                "read_gateways",
                "read_apis",
                "read_acrs",
              ]}
              onClick={sectionClick}
              isDrawerOpen={isOpen}
            >
              <NavSectionItem
                id="authorizers-link"
                text="API Authorization"
                to={`/${workspace}/apis/authorization`}
                isActive={pathname.startsWith(`/${workspace}/apis/`)}
                hasWorkspacePermission="read_apis"
                onClick={onItemClick}
              />
              <NavSectionItem
                id="server-policies-link"
                text="OAuth Server Authorization"
                to={`/${workspace}/workspaces/authorization`}
                hasWorkspacePermission={["read_acrs", "read_policies"]}
                isActive={pathname.startsWith(`/${workspace}/workspaces/authorization/`)}
                onClick={onItemClick}
              />
              <NavSectionItem
                id="policies-link"
                text="Policies"
                to={`/${workspace}/policies`}
                hasWorkspacePermission="read_policies"
                onClick={onItemClick}
              />
              <NavSectionItem
                id="gateways-link"
                text="Gateways"
                to={`/${workspace}/gateways`}
                hasWorkspacePermission="read_gateways"
                onClick={onItemClick}
              />
              <NavSectionItem
                id="visualizer-link"
                text="Data Governance"
                to={`/${workspace}/visualizer`}
                onClick={() => onItemClick(true)}
              />
            </NavSection>
          )}

          {(navMode === "advanced" || !isOrganizationOrConsumerWorkspace) && (
            <NavSection
              id="extensions"
              label="Extensions"
              isActive={initSections.extensions.isActive(pathname, workspace)}
              isOpen={openSectionsMap.extensions.isOpen}
              icon={ExtensionsIcon}
              hasOneOfWorkspacePermissions={[
                "read_scripts",
                "read_custom_apps",
                "read_secrets",
                "read_webhooks",
              ]}
              onClick={sectionClick}
              isDrawerOpen={isOpen}
            >
              <NavSectionItem
                id="scripts-link"
                text="Scripts"
                to={`/${workspace}/extensions`}
                hasWorkspacePermission="read_scripts"
                onClick={() => onItemClick(true)}
              />
              <NavSectionItem
                id="webhooks-link"
                text="Webhooks"
                to={`/${workspace}/webhooks`}
                hasWorkspacePermission="read_webhooks"
                onClick={onItemClick}
              />
              <NavSectionItem
                id="custom-applications-link"
                text="Extension Apps"
                to={`/${workspace}/custom-applications`}
                hasWorkspacePermission="read_custom_apps"
                onClick={onItemClick}
              />
              <NavSectionItem
                id="secrets-link"
                text="Secrets"
                to={`/${workspace}/secrets`}
                hasWorkspacePermission="read_secrets"
                onClick={onItemClick}
              />
            </NavSection>
          )}

          {(navMode === "advanced" || !isOrganizationWorkspace) && isAnalyticsEnabled && (
            <NavItem
              id="analytics"
              text="Analytics"
              to={`/${workspace}/analytics/oauth2`}
              icon={AnalyticsIcon}
              hasWorkspacePermission="read_analytics"
              onClick={onItemClick}
              isActive={pathname.startsWith(`/${workspace}/analytics`)}
            />
          )}

          <NavItem
            id="audit-logs"
            text="Audit Logs"
            to={`/${workspace}/audit-events`}
            icon={LogsIcon}
            hasWorkspacePermission="read_audit_events"
            onClick={onItemClick}
            isActive={pathname.startsWith(`/${workspace}/audit-events`)}
          />

          <NavItem
            id="settings"
            text="Settings"
            to={`/${workspace}/settings/general`}
            icon={Settings}
            hasWorkspacePermission="get_workspace"
            onClick={onItemClick}
            isActive={pathname.startsWith(`/${workspace}/settings/`)}
          />

          {isSystemTenantAdminWorkspace(tenantId, workspace) && (
            <>
              <NavItem
                id="tenant-management-link"
                text="Tenant management"
                to={`/${workspace}/tenant-management`}
                icon={Tool}
                onClick={onItemClick}
              />
              <NavItem
                id="features-link"
                text="System features"
                to={`/${workspace}/features`}
                icon={Star}
                onClick={onItemClick}
              />
            </>
          )}
          <NavModeChanger />
        </List>
      </div>
    </>
  );
}
