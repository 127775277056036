import React, { useState } from "react";
import { useNavigate } from "react-router";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { makeStyles } from "tss-react/mui";

import { ServerResponseProfileEnum } from "@cloudentity/acp-admin";

import { getTenantId } from "../../../../common/api/paths";
import { TOP_BAR_HEIGHT } from "../../../../common/components/nav/utils";
import { useFeature } from "../../../../common/utils/hooks/useFeature";
import { useGetTenant } from "../../../services/adminTenantsQuery";
import PageContainer from "../../common/PageContainer";
import PageContent from "../../common/PageContent";
import { useMarkGetStartedVisited } from "../../getStarted/GetStarted";
import GetStartedCard from "../../getStarted/GetStartedCard";
import TopBar from "../../nav/TopBar";
import {
  createDemoWorkspace,
  getDemoWorkspaceId,
} from "../../workspaceDirectory/WorkspaceDirectoryCreateDefineWorkspaceTypeStep";
import { getServerAvatarIcon } from "../../workspaceDirectory/server-profiles";

const useStyles = makeStyles<{ disabled: boolean }>()((_, { disabled }) => ({
  container: {
    marginTop: TOP_BAR_HEIGHT,
    position: "relative",
  },
  title: {
    margin: "10px 0",
  },
  intro: {
    margin: "0 28px",
  },
  loading: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
  },
}));

export default function SelectEcosystem() {
  const [progress, setProgress] = useState(false);
  const { markGetStartedVisited, progress: markProgress } = useMarkGetStartedVisited();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { classes } = useStyles({ disabled: progress || markProgress });
  const isConnectIDEnabled = useFeature("connect_id");
  const isOBBREnabled = useFeature("obbr");

  const getTenantQuery = useGetTenant(getTenantId());

  const handleTryDemoOpenbanking = (profile: ServerResponseProfileEnum) => () => {
    setProgress(true);

    const id = getDemoWorkspaceId(profile);

    createDemoWorkspace(profile, id, queryClient, getTenantQuery.data)
      .then(() => markGetStartedVisited())
      .finally(() => {
        setProgress(false);
        navigate(`/${id}/overview`);
      });
  };

  return (
    <PageContainer>
      <TopBar hideTenantSettings />
      <div className={classes.container}>
        <PageContent fullWidth={false} style={{ marginLeft: "auto" }}>
          {progress || (markProgress && <LinearProgress className={classes.loading} />)}

          <div className={classes.intro}>
            <div>We provide compliance profiles for global open banking</div>
            <Typography variant="h2" className={classes.title}>
              Select your ecosystem
            </Typography>

            <div
              style={{
                marginTop: 24,
                marginBottom: 24,
                display: "flex",
                flexDirection: "column",
                gap: 24,
                width: "100%",
              }}
            >
              <GetStartedCard
                label="Financial Data Exchange"
                icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.Fdx })}
                selectable={!(progress || markProgress)}
                onSelect={handleTryDemoOpenbanking(ServerResponseProfileEnum.Fdx)}
                id="fdx-card"
              />
              <GetStartedCard
                label="CDR Australia"
                icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.CdrAustralia })}
                selectable={!(progress || markProgress)}
                onSelect={handleTryDemoOpenbanking(ServerResponseProfileEnum.CdrAustralia)}
                id="cdr-card"
              />
              {isOBBREnabled && (
                <GetStartedCard
                  label="Open Finance Brazil"
                  icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.OpenbankingBr })}
                  selectable={!(progress || markProgress)}
                  onSelect={handleTryDemoOpenbanking(ServerResponseProfileEnum.OpenbankingBr)}
                  id="obbr-card"
                />
              )}
              <GetStartedCard
                label="Open Banking UK"
                icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.OpenbankingUk })}
                selectable={!(progress || markProgress)}
                onSelect={handleTryDemoOpenbanking(
                  ServerResponseProfileEnum.OpenbankingUkFapiAdvanced
                )}
                id="obuk-card"
              />
              <GetStartedCard
                label="Other/Private ecosystem/FAPI"
                icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.Fapi20Security })}
                selectable={!(progress || markProgress)}
                onSelect={handleTryDemoOpenbanking(ServerResponseProfileEnum.Fapi20Security)}
                id="fapi-card"
              />
              {isConnectIDEnabled && (
                <GetStartedCard
                  label="Connect ID"
                  icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.ConnectId })}
                  selectable={!(progress || markProgress)}
                  onSelect={handleTryDemoOpenbanking(ServerResponseProfileEnum.ConnectId)}
                  id="connect-id-card"
                />
              )}
              <GetStartedCard
                label="Open Banking Kingdom Saudi Arabia"
                icon={getServerAvatarIcon({ profile: ServerResponseProfileEnum.OpenbankingKsa })}
                selectable={!(progress || markProgress)}
                onSelect={handleTryDemoOpenbanking(ServerResponseProfileEnum.OpenbankingKsa)}
                id="obksa-card"
              />
            </div>
            <Divider style={{ marginTop: 32, marginBottom: 32 }} />
            <div style={{ textAlign: "right" }}>
              <Button
                variant="text"
                onClick={() => navigate("/get-started")}
                disabled={progress || markProgress}
                id="backButton"
              >
                Back
              </Button>
            </div>
          </div>
        </PageContent>
      </div>
    </PageContainer>
  );
}
