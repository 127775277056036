import React from "react";

import { AlertTriangle, CheckCircle } from "react-feather";
import { makeStyles } from "tss-react/mui";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

import { Address, IdentifierDetails, IdentifierDetailsTypeEnum } from "@cloudentity/acp-identity";

import Chip from "../../../../../../../common/components/Chip";
import Tooltip from "../../../../../common/Tooltip";

const useStyles = makeStyles()(() => ({
  flexBox: {
    display: "flex",
    alignItems: "center",
  },
  checkIcon: {
    color: "#10B981",
    marginRight: 5,
  },
  alertIcon: {
    color: "#9EA1B7",
    marginRight: 5,
  },
}));

export const IdentifierInfo = ({
  isVerified,
  identifier,
}: {
  isVerified?: boolean;
  identifier: string;
}) => {
  const { classes } = useStyles();

  return identifier ? (
    <div className={classes.flexBox}>
      {isVerified === undefined ? null : isVerified ? (
        <Tooltip title="Verified address">
          <CheckCircle size={12} className={classes.checkIcon} />
        </Tooltip>
      ) : (
        <Tooltip title="Unverified address">
          <AlertTriangle size={12} className={classes.alertIcon} />
        </Tooltip>
      )}{" "}
      {identifier}
    </div>
  ) : null;
};

export const MoreIdentifiers = ({ identifiers }: { identifiers: string[] }) => {
  return (
    <Tooltip title={identifiers.join(", ")} style={{ marginLeft: 8 }}>
      <span>
        <Chip
          label={`+ ${identifiers.length}`}
          size="small"
          style={{ backgroundColor: "#F2F3F4", height: 20, fontSize: 10 }}
        />
      </span>
    </Tooltip>
  );
};

const divideIdentifiersByTypeWithValidators = (identifiers: string[]) => {
  const phoneIdentifiers: string[] = [];
  const emailIdentifiers: string[] = [];
  const otherIdentifiers: string[] = [];

  identifiers.forEach(identifier => {
    if (isMobilePhone(identifier)) {
      phoneIdentifiers.push(identifier);
    } else if (isEmail(identifier)) {
      emailIdentifiers.push(identifier);
    } else {
      otherIdentifiers.push(identifier);
    }
  });

  return { phoneIdentifiers, emailIdentifiers, otherIdentifiers };
};

export const divideIdentifiersByType = ({
  identifiers,
  sortFn,
  withDetails,
}: {
  sortFn: ((a: IdentifierDetails, b: IdentifierDetails) => number) | undefined;
  identifiers: IdentifierDetails[] | string[];
  withDetails: boolean;
}) => {
  let sortedIdentifiers = identifiers.slice();
  if (sortFn) {
    sortedIdentifiers.sort(sortFn);
  }

  if (withDetails) {
    const phoneIdentifiers: string[] = [];
    const emailIdentifiers: string[] = [];
    const otherIdentifiers: string[] = [];

    sortedIdentifiers.forEach(identifier => {
      switch (identifier.type) {
        case IdentifierDetailsTypeEnum.Mobile:
          phoneIdentifiers.push(identifier.value ?? "");
          break;
        case IdentifierDetailsTypeEnum.Email:
          emailIdentifiers.push(identifier.value ?? "");
          break;
        case IdentifierDetailsTypeEnum.Federated:
          break;
        default:
          otherIdentifiers.push(identifier.value ?? "");
          break;
      }
    });

    return { phoneIdentifiers, emailIdentifiers, otherIdentifiers };
  } else {
    return divideIdentifiersByTypeWithValidators(sortedIdentifiers as string[]);
  }
};

export const divideAddressesByType = ({ addresses }: { addresses: Address[] }) => {
  const phoneAddresses: Address[] = [];
  const emailAddresses: Address[] = [];

  addresses
    .sort((a1, a2) => {
      if (!a1.verified && a2.verified) return 1;
      if (a1.verified && !a2.verified) return -1;
      return 0;
    })
    .forEach(address => {
      if (isMobilePhone(address.address ?? "")) {
        phoneAddresses.push(address);
      } else if (isEmail(address.address ?? "")) {
        emailAddresses.push(address);
      }
    });

  return { phoneAddresses, emailAddresses };
};
