import React, { useCallback, useEffect, useRef, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import debounce from "lodash/debounce";
import { X } from "react-feather";
import { makeStyles } from "tss-react/mui";

import Checkbox from "../../../../common/components/Checkbox";
import SearchInput from "../SearchInput";

const useStyles = makeStyles()(() => ({
  toolBar: {
    marginBottom: 6,
    display: "flex",
    alignItems: "center",
  },
  chips: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: 12,
  },
  chip: {
    marginLeft: 8,
  },
  chipIcon: {
    width: 12,
    height: 12,
    marginRight: 10,
  },
}));

export type Filters = { [key: string]: string | string[] };
export type FiltersSetup = (
  | { type: "checkbox"; title: string; key: string; options: { label: string; value: string }[] }
  | { type: "radio"; title: string; key: string; options: { label: string; value: string }[] }
)[];

interface FiltersToolbarProps {
  filters: Filters;
  filtersSetup: FiltersSetup;
  searchText: string;
  searchPlaceholder: string;
  onUpdate: (searchText: string, filters: Filters) => void;
}

export default function FiltersToolbar({
  filters: upperFilters,
  filtersSetup,
  searchText: upperSearchText,
  searchPlaceholder,
  onUpdate,
}: FiltersToolbarProps) {
  const { classes } = useStyles();
  const theme = useTheme();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(upperFilters);
  const [searchText, setSearchText] = useState(upperSearchText);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = useCallback(
    debounce((searchText: string, filters: Filters) => {
      onUpdate(searchText, filters);
    }, 250),
    [onUpdate]
  );

  const handleChangeSearchText = (newSearchText: string) => {
    setSearchText(newSearchText);
    handleUpdate(newSearchText, filters);
  };

  const handleClearSearchText = () => {
    setSearchText("");
    handleUpdate("", filters);
  };

  const handleChangeFilters = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    let newFilters = { ...filters };
    switch (type) {
      case "checkbox":
        if (!newFilters[name]) {
          newFilters[name] = [];
        }
        newFilters[name] = (newFilters[name] as string[]).filter(v => v !== value);
        if (checked) {
          (newFilters[name] as string[]).push(value);
        }
        break;
      case "radio":
        newFilters[event.target.name] = event.target.value;
        break;
      default:
        break;
    }
    setFilters(newFilters);
    handleUpdate(searchText, newFilters);
  };

  const handleSelectAllFilters = (params: FiltersSetup[0]) => {
    if (params.type === "checkbox") {
      const newFilters = { ...filters, [params.key]: params.options.map(option => option.value) };
      setFilters(newFilters);
      handleUpdate(searchText, newFilters);
    }
  };

  const handleRemoveFilter = ({
    params,
    option,
  }: {
    params: FiltersSetup[0];
    option: FiltersSetup[0]["options"][0];
  }) => {
    let newFilters = { ...filters };
    switch (params.type) {
      case "checkbox":
        if (!newFilters[params.key]) {
          newFilters[params.key] = [];
        }
        newFilters[params.key] = (newFilters[params.key] as string[]).filter(
          v => v !== option.value
        );
        break;
      case "radio":
        newFilters[params.key] = "";
        break;
      default:
        break;
    }
    setFilters(newFilters);
    handleUpdate(searchText, newFilters);
  };

  const handleClearFilters = () => {
    const defaultFilters = filtersSetup.reduce(
      (acc, curr) => ({ ...acc, [curr.key]: (curr.type === "checkbox" && []) || "" }),
      {}
    );
    setFilters(defaultFilters);
    handleUpdate(searchText, defaultFilters);
  };

  useEffect(() => {
    setFilters(upperFilters);
  }, [upperFilters]);

  useEffect(() => {
    setSearchText(upperSearchText);
  }, [upperSearchText]);

  const Chips = filtersSetup
    .filter(
      params =>
        (params.type === "checkbox" && filters[params.key]?.length) ||
        (params.type === "radio" && filters[params.key])
    )
    .flatMap(params => [
      ...(params.type === "checkbox"
        ? params.options
            .filter(option => filters[params.key]?.includes(option.value))
            .map(option => ({ params, option }))
        : []),
      ...(params.type === "radio"
        ? params.options
            .filter(option => filters[params.key] === option.value)
            .map(option => ({ params, option }))
        : []),
    ])
    .map(({ params, option }) => (
      <Chip
        key={params.key + option.value}
        label={filtersSetup.length === 1 ? option.label : `${params.title}: ${option.label}`}
        size="small"
        className={classes.chip}
        onDelete={() => handleRemoveFilter({ params, option })}
        deleteIcon={<X strokeWidth="3" size="16" color={theme.palette.secondary.light} />}
        classes={{
          deleteIcon: classes.chipIcon,
        }}
        data-testid={`filters-chip-${params.key}-${option.value}`}
      />
    ));

  return (
    <div>
      <div className={classes.toolBar}>
        <SearchInput
          value={searchText}
          placeholder={searchPlaceholder}
          onSearch={handleChangeSearchText}
          onClear={handleClearSearchText}
        />

        {filtersSetup.length > 0 && (
          <>
            <Button
              ref={anchorRef}
              aria-controls="menu-list-grow"
              aria-haspopup="true"
              onClick={handleToggle}
              variant="outlined"
              color="primary"
              endIcon={<KeyboardArrowDownIcon />}
              id="filters-button"
              style={{ padding: "11px 15px", marginLeft: 12 }}
            >
              Filter
            </Button>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              style={{ zIndex: 10000 }}
              placement="bottom-end"
            >
              <Paper
                style={{
                  padding: 24,
                  minWidth: 284,
                  boxShadow:
                    "0px 1px 16px -4px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.31)",
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div>
                    {filtersSetup.map(params => (
                      <FormControl key={params.key} component="fieldset" fullWidth>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            minHeight: 32,
                            marginBottom: 8,
                          }}
                        >
                          <FormHelperText
                            style={{ fontWeight: 700, fontSize: 12, textTransform: "uppercase" }}
                          >
                            {params.title}
                          </FormHelperText>
                          {params.type === "checkbox" && (
                            <Button
                              variant="text"
                              color="primary"
                              size="small"
                              onClick={() => handleSelectAllFilters(params)}
                              id="select-all-filters-button"
                            >
                              Select all
                            </Button>
                          )}
                        </div>
                        <FormGroup>
                          {(params.type === "checkbox" &&
                            params.options.map(option => (
                              <FormControlLabel
                                key={option.value}
                                label={option.label}
                                control={
                                  <Checkbox
                                    name={params.key}
                                    value={option.value}
                                    checked={(filters[params.key] as string[])?.includes(
                                      option.value
                                    )}
                                    onChange={handleChangeFilters(params.type)}
                                    id={`checkbox-${params.key}-${option.value}`}
                                  />
                                }
                              />
                            ))) ||
                            (params.type === "radio" && (
                              <RadioGroup
                                aria-label={params.key}
                                name={params.key}
                                value={filters[params.key]}
                                onChange={handleChangeFilters(params.type)}
                              >
                                {params.options.map(option => (
                                  <FormControlLabel
                                    key={option.label}
                                    value={option.value}
                                    control={
                                      <Radio
                                        color="primary"
                                        id={`radio-${params.key}-${option.value}`}
                                      />
                                    }
                                    label={option.label}
                                  />
                                ))}
                              </RadioGroup>
                            ))}
                        </FormGroup>
                      </FormControl>
                    ))}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </>
        )}
      </div>
      <div className={classes.chips}>
        {Chips.length ? (
          <Typography style={{ marginRight: 16 }} fontWeight="600">
            Filter ({Chips.length})
          </Typography>
        ) : null}
        {Chips}
        {Chips.length ? (
          <Button
            variant="text"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={handleClearFilters}
            id="clear-filters-button"
          >
            Clear all
          </Button>
        ) : null}
      </div>
    </div>
  );
}
