import {
  ScopesApi,
  ScopesApiCreateScopeRequest,
  ScopesApiDeleteScopeRequest,
  ScopesApiListScopesRequest,
  ScopesApiUpdateScopeRequest,
} from "@cloudentity/acp-admin";

import { adminApiClazz } from "./admin-services.common";

const api: () => ScopesApi = adminApiClazz(ScopesApi);

const adminScopesApi = {
  getScopes: (req: ScopesApiListScopesRequest) => api().listScopes(req),
  createScope: (req: ScopesApiCreateScopeRequest) => api().createScope(req),
  updateScope: (req: ScopesApiUpdateScopeRequest) => api().updateScope(req),
  removeScope: (req: ScopesApiDeleteScopeRequest) => api().deleteScope(req),
};

export default adminScopesApi;
