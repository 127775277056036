import {
  B2BUsersApi,
  B2BUsersApiCreateB2BUserRequest,
  B2BUsersApiDeleteB2BUserRequest,
  B2BUsersApiGetB2BUserRequest,
  B2BUsersApiListUsersB2BRequest,
  B2BUsersApiUpdateB2BUserRequest,
} from "@cloudentity/acp-identity";

import { identityApiClazz } from "./admin-services.common";

const api: () => B2BUsersApi = identityApiClazz(B2BUsersApi);

const b2bUsersApi = {
  listUsersB2B: (req: B2BUsersApiListUsersB2BRequest) => api().listUsersB2B(req),
  getB2BUser: (req: B2BUsersApiGetB2BUserRequest) => api().getB2BUser(req),
  createB2BUser: (req: B2BUsersApiCreateB2BUserRequest) => api().createB2BUser(req),
  updateB2BUser: (req: B2BUsersApiUpdateB2BUserRequest) => api().updateB2BUser(req),
  deleteB2BUser: (req: B2BUsersApiDeleteB2BUserRequest) => api().deleteB2BUser(req),
};

export default b2bUsersApi;
