import React, { ChangeEvent, CSSProperties, ReactNode, useState } from "react";
import { Controller } from "react-hook-form";

import { CheckboxProps } from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "tss-react/mui";

import Tooltip from "../../../admin/components/common/Tooltip";
import Checkbox from "../../components/Checkbox";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useFormContext } from "./Form";

const useStyles = makeStyles<{ withPaper?: boolean }>()((theme, { withPaper }) => ({
  container: {
    marginBottom: 32,
    width: "100%",
    ...(withPaper
      ? {
          borderRadius: 4,
          background: "white",
          boxShadow: theme.custom.shadows.surfaceResting,
          padding: "8px 16px 16px",
          marginTop: 24,
        }
      : {}),
  },
  tooltip: {
    wordBreak: "break-word",
  },
}));

export interface CheckboxFieldProps extends Omit<CheckboxProps, "form"> {
  id?: string;
  name: string;
  label?: string | ReactNode;
  style?: CSSProperties;
  helperText?: string | ReactNode;
  withConfirmation?: boolean;
  confirmation?: { title: string; content: string | ReactNode; confirmText: string };
  withTooltip?: string | ReactNode;
  sectionId?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  withPaper?: boolean;
  disabled?: boolean;
}

export default function CheckboxField({
  id,
  name,
  label,
  style,
  helperText,
  withConfirmation,
  confirmation = { title: "", content: "", confirmText: "" },
  withTooltip,
  sectionId,
  onChange,
  withPaper,
  disabled,
  ...props
}: CheckboxFieldProps) {
  const [dialog, setDialog] = useState(false);
  const { classes } = useStyles({ withPaper });

  const { form, disabled: formDisabled, id: formId } = useFormContext();
  const htmlId = id || formId;

  return (
    <>
      <FormControl className={classes.container} style={style} id={sectionId}>
        <Controller
          render={({ field }) => {
            const CheckboxController = (
              <Checkbox
                checked={!!field.value}
                onChange={e => {
                  const value = e.target.checked;
                  if (value && withConfirmation) {
                    setDialog(true);
                  } else {
                    onChange && onChange(e);
                    field.onChange(value);
                  }
                }}
                disabled={disabled || formDisabled}
                {...props}
              />
            );

            const CheckboxWithLabel = (
              <FormControlLabel
                id={`${htmlId}-${name}-checkbox`}
                name={name}
                control={
                  !!withTooltip ? (
                    <Tooltip title={withTooltip} classes={{ tooltip: classes.tooltip }}>
                      <span>{CheckboxController}</span>
                    </Tooltip>
                  ) : (
                    CheckboxController
                  )
                }
                label={label}
              />
            );

            return label ? CheckboxWithLabel : CheckboxController;
          }}
          name={name}
          control={form.control}
          defaultValue={props.defaultValue || false}
        />

        {helperText && (
          <FormHelperText id={`${htmlId}-helper-text`} style={{ marginLeft: 28 }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>

      {dialog && (
        <ConfirmationDialog
          title={confirmation.title}
          content={confirmation.content}
          confirmText={confirmation.confirmText}
          onCancel={() => setDialog(false)}
          onConfirm={() => {
            form.setValue(name, true);
            setDialog(false);
          }}
        />
      )}
    </>
  );
}
