import React, { useCallback, useState } from "react";
import { useParams } from "react-router";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import formatDistance from "date-fns/formatDistance";
import { Edit2, Trash2 } from "react-feather";

import { Token } from "@cloudentity/acp-permissions";

import ConfirmationDialog from "../../../../common/components/ConfirmationDialog";
import IconButton from "../../../../common/components/IconButton";
import permissionsTokensApi from "../../../services/adminPermissionsTokensApi";
import EnhancedTableAsync, {
  HeadCellsType,
} from "../../common/EnhancedTableAsync/EnhancedTableAsync";
import useItems from "../../common/EnhancedTableAsync/useItems";
import PermissionTokenCopyKeyDialog from "./PermissionTokenCopyKeyDialog";
import PermissionTokenCreateUpdateDialog from "./PermissionTokenCreateUpdateDialog";

function getFormattedDate(date: string) {
  try {
    return formatDistance(new Date(date), new Date(), { addSuffix: true });
  } catch {
    return date;
  }
}

const headCells: HeadCellsType = [
  { id: "name", label: "Name", align: "left" },
  { id: "description", label: "Description", align: "left" },
  { id: "created_at", label: "Created at", align: "left" },
  { id: "actions", label: "", align: "right", width: 200 },
];

interface Props {
  readonly?: boolean;
}

function PermissionTokens({ readonly }: Props) {
  const [progress, setProgress] = useState(false);
  const { id = "" } = useParams<{ id: string }>();

  const [addToken, setAddToken] = useState(false);
  const [editToken, setEditToken] = useState<Token>();
  const [removeToken, setRemoveToken] = useState<Token>();
  const [copyKeyDialog, setCopyKeyDialog] = useState<string>();

  const promiseFetch = useCallback(
    () => permissionsTokensApi.listTokens({ psID: id }).then(res => res.data.tokens ?? []),
    [id]
  );

  const {
    items,
    itemsProgress,
    sort,
    order,
    page,
    limit,
    nextPageAvailable,
    searchText,
    onResetPage,
    onPaginationUpdate,
    onFiltersUpdate,
  } = useItems<Token>({
    id: "permission-system-tokens",
    filtersSetup: [],
    idGetter: user => user.id,
    promiseFetch,
    initialSort: "ID",
  });

  const handleCreate = (data: any) => {
    return permissionsTokensApi
      .createToken({
        psID: id,
        token: {
          name: data.name.trim(),
          description: data.description.trim(),
          system_id: id,
        },
      })
      .then(({ data }: { data: Token }) => data?.key && setCopyKeyDialog(data.key))
      .then(onResetPage)
      .then(() => setAddToken(false));
  };

  const handleUpdate = (data: Token) => {
    return permissionsTokensApi
      .updateToken({
        psID: id,
        tokenID: editToken?.id ?? "",
        token: {
          ...editToken,
          ...data,
        },
      })
      .then(onResetPage)
      .then(() => setEditToken(undefined));
  };

  const handleRemove = (tokenId: string) => {
    setProgress(true);
    permissionsTokensApi
      .deleteToken({ psID: id, tokenID: tokenId })
      .then(onResetPage)
      .then(() => setRemoveToken(undefined))
      .finally(() => setProgress(false));
  };

  return (
    <div>
      <EnhancedTableAsync
        id="permission-system-tokens-table"
        data={items}
        loading={itemsProgress || progress}
        headCells={headCells}
        sort={sort}
        order={order}
        page={page}
        limit={limit}
        nextPageAvailable={nextPageAvailable}
        onPaginationUpdate={onPaginationUpdate}
        onFiltersUpdate={onFiltersUpdate}
        filters={{}}
        filtersSetup={[]}
        searchText={searchText}
        searchPlaceholder="Search"
        actionButtonLabel="+ Add token"
        noToolbar
        onToolbarAction={!readonly ? () => setAddToken(true) : undefined}
        toolbarStyle={{ display: "flex", justifyContent: "flex-end" }}
        createRow={(row: Token, _, classes) => {
          return (
            <TableRow
              key={row.id}
              className={!readonly && classes.row}
              hover={!readonly}
              onClick={
                !readonly
                  ? e => {
                      e.preventDefault();
                      setEditToken(row);
                    }
                  : undefined
              }
            >
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{getFormattedDate(row.created_at ?? "")}</TableCell>
              <TableCell align="right">
                {!readonly && (
                  <>
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        setEditToken(row);
                      }}
                      icon={Edit2}
                      size="small"
                      style={{ marginRight: 16 }}
                    />
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        setRemoveToken(row);
                      }}
                      icon={Trash2}
                      size="small"
                    />
                  </>
                )}
              </TableCell>
            </TableRow>
          );
        }}
      />

      {addToken && (
        <PermissionTokenCreateUpdateDialog
          onSubmit={handleCreate}
          onClose={() => setAddToken(false)}
        />
      )}

      {editToken && (
        <PermissionTokenCreateUpdateDialog
          onSubmit={handleUpdate}
          onClose={() => setEditToken(undefined)}
          data={editToken}
        />
      )}

      {removeToken && (
        <ConfirmationDialog
          title="Delete Token"
          content={
            <>
              You're about to delete the <b>{removeToken.name}</b> token. This cannot be undone.
              Delete anyway?
            </>
          }
          confirmText="Delete"
          onCancel={() => setRemoveToken(undefined)}
          onConfirm={() => {
            handleRemove(removeToken.id ?? "");
          }}
          progress={progress}
        />
      )}

      {copyKeyDialog && (
        <PermissionTokenCopyKeyDialog
          onClose={() => setCopyKeyDialog(undefined)}
          dataKey={copyKeyDialog}
        />
      )}
    </div>
  );
}

export default PermissionTokens;
