import React from "react";

import { ServerResponseTypeEnum } from "@cloudentity/acp-admin";

import RouterLink from "../../../common/components/RouterLink";
import { useGetOrganization } from "../../services/adminOrganizationsQuery";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { useGetWorkspace } from "../../services/adminServersQuery";

interface Props {
  name: string;
  parentId: string;
  parentUrl: (parentId: string) => string;
  allowLinkToNoOrganizationRoot?: boolean;
  isNested?: boolean;
}

export default function OrganizationBreadcrumb({
  name,
  parentId,
  parentUrl,
  allowLinkToNoOrganizationRoot,
  isNested,
}: Props) {
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(parentId);
  const parentWorkspaceQuery = useGetWorkspace(parentId, {
    enabled: !!parentId && !!checkWorkspacePermissionsQuery.data?.discover_workspace,
  });
  const parentOrganizationQuery = useGetOrganization(parentId, {
    enabled:
      parentWorkspaceQuery.data?.type === ServerResponseTypeEnum.Organization &&
      !!parentId &&
      !!checkWorkspacePermissionsQuery.data?.get_organization,
  });

  if (!parentId && !isNested) {
    return <span>{name}</span>;
  }

  const parent =
    parentWorkspaceQuery.data?.type === ServerResponseTypeEnum.Organization ||
    allowLinkToNoOrganizationRoot ? (
      <RouterLink to={parentUrl(parentId)}>
        {parentWorkspaceQuery.data?.name || parentWorkspaceQuery.data?.id}
      </RouterLink>
    ) : (
      parentWorkspaceQuery.data?.name || parentWorkspaceQuery.data?.id
    );

  if (parentId) {
    return (
      <>
        <OrganizationBreadcrumb
          key={name}
          name={parentOrganizationQuery?.data?.name!}
          parentId={parentOrganizationQuery.data?.parent_id!}
          parentUrl={parentUrl}
          allowLinkToNoOrganizationRoot={allowLinkToNoOrganizationRoot}
          isNested
        />
        {parent} {checkWorkspacePermissionsQuery.data?.discover_workspace && <span>&gt;</span>}{" "}
        {!isNested && <span>{name}</span>}
      </>
    );
  }

  return null;
}
