import React, { CSSProperties, ReactNode } from "react";

import Container, { ContainerProps } from "@mui/material/Container";

import Progress from "../../../common/components/Progress";
import { TOP_BAR_HEIGHT } from "../../../common/components/nav/utils";
import { PAGE_HEADER_HEIGHT } from "./PageHeader";

interface Props extends ContainerProps {
  progress?: boolean;
  noPadding?: boolean;
  isGlobalLoader?: boolean;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  children: ReactNode;
  noScrollHeight?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
}

export default function PageContent({
  progress,
  noPadding,
  isGlobalLoader = true,
  style = {},
  innerStyle = {},
  children,
  noScrollHeight = false,
  fullWidth = true,
  fullHeight = false,
  ...props
}: Props) {
  return (
    <Container
      style={{
        marginLeft: 0,
        minWidth: 920,
        ...(fullWidth
          ? {
              marginRight: 0,
              marginTop: 0,
              marginBottom: 0,
              paddingLeft: 32,
              paddingRight: 32,
              maxWidth: "100%",
            }
          : {}),
        ...(noScrollHeight
          ? { height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px - ${TOP_BAR_HEIGHT}px)` }
          : {}),
        ...(fullHeight
          ? { height: `calc(100vh - ${TOP_BAR_HEIGHT}px - ${PAGE_HEADER_HEIGHT}px)` }
          : {}),
        ...style,
      }}
      {...props}
    >
      <div
        style={{
          position: "relative",
          paddingTop: noPadding ? 0 : 32,
          paddingBottom: noPadding ? 0 : 32,
          height: "100%",
          ...innerStyle,
        }}
      >
        {progress && <Progress isGlobalLoader={isGlobalLoader} />}
        {!progress && children}
      </div>
    </Container>
  );
}
