import React, { useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { RevokeWorkspaceRoleRequestTypeEnum } from "@cloudentity/acp-admin";
import { BaseUserWithData, UserWithData } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../common/api/paths";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";
import adminRolesApi from "../../services/adminRolesApi";
import {
  listUserRoles,
  listWorkspaceRoles,
  useListWorkspaceRoles,
} from "../../services/adminRolesQuery";
import { useGetAuthorizationServer } from "../../services/adminServersQuery";
import { subjectToRevokeRoles } from "./manageAccess.utils";
import { serverTypeToRoleName } from "./workspaceRoleOptions";

interface Props {
  user?: UserWithData | BaseUserWithData;
  workspace: string;
  onRevoked: () => void;
  onCancel: () => void;
}

export default function ManageAccessRevokeRoleDialog({
  user,
  workspace,
  onRevoked,
  onCancel,
}: Props) {
  const [progress, setProgress] = useState(false);
  const queryClient = useQueryClient();

  const identifiers = user?.identifiers || [];
  const listWorkspaceRolesQuery = useListWorkspaceRoles(workspace);
  const serverQuery = useGetAuthorizationServer(getTenantId(), workspace);

  const subject = (listWorkspaceRolesQuery.data?.subjects || []).find(
    s => s.identity_pool_user_id === user?.id
  );
  const roles = subjectToRevokeRoles(subject);

  const handleRevokeWorkspaceRole = () => {
    setProgress(true);

    Promise.all(
      roles.map(role =>
        adminRolesApi.revokeWorkspaceRole({
          wid: workspace,
          request: {
            ...subject,
            type: RevokeWorkspaceRoleRequestTypeEnum.IdentityPoolUser,
            role,
          },
        })
      )
    )
      .then(() => queryClient.invalidateQueries({ queryKey: listWorkspaceRoles(workspace) }))
      .then(() =>
        queryClient.invalidateQueries({ queryKey: listUserRoles(user?.user_pool_id, user?.id!) })
      )
      .then(() => onRevoked())
      .then(() => notifySuccess("Workspace role revoked successfully"))
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to revoke workspace role"))
      .finally(() => {
        setProgress(false);
      });
  };

  return (
    <ConfirmationDialog
      title="Revoke Workspace Role"
      content={
        <div>
          You're about to revoke {roles.length > 1 ? "roles" : "role"}{" "}
          <strong>
            {roles
              .map(role => serverTypeToRoleName(serverQuery.data?.type, role) || role)
              .join(", ")}
          </strong>{" "}
          from user with {identifiers[0] ? "identifier" : "id"}{" "}
          <strong>
            {identifiers[0] ? identifiers[0].identifier : subject?.identity_pool_user_id}
          </strong>
          . Do you want to continue?
        </div>
      }
      confirmText="Revoke"
      progress={progress}
      onCancel={onCancel}
      onConfirm={handleRevokeWorkspaceRole}
    />
  );
}
