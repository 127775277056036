import { useState } from "react";

export type DeletedUsersStoreContextType = {
  addDeletedUserId: (userId: string | undefined) => void;
  filterDeletedUsersFromData: <T extends { id?: string }>(data?: T[]) => T[] | undefined;
};

export const useDeletedUsersStore = (): DeletedUsersStoreContextType => {
  const [store, setStore] = useState<{ deletedUserIds: string[] }>({ deletedUserIds: [] });

  const addDeletedUserId = (deletedUserId: string | undefined) => {
    setStore(store => ({
      ...store,
      deletedUserIds: deletedUserId
        ? [...store.deletedUserIds, deletedUserId]
        : store.deletedUserIds,
    }));
  };

  const filterDeletedUsersFromData = <T extends { id?: string }>(data?: T[]) =>
    data?.filter(user => user.id && !store.deletedUserIds.includes(user.id));

  return {
    addDeletedUserId,
    filterDeletedUsersFromData,
  };
};
