export const graphUserAttributesOptions = [
  { name: "Business phones", value: "businessPhones" },
  { name: "Display name", value: "displayName" },
  { name: "Given name", value: "givenName" },
  { name: "Id", value: "id" },
  { name: "Job title", value: "jobTitle" },
  { name: "Mail", value: "mail" },
  { name: "Mobile phone", value: "mobilePhone" },
  { name: "Office location", value: "officeLocation" },
  { name: "Preferred language", value: "preferredLanguage" },
  { name: "Surname", value: "surname" },
  { name: "User principal name", value: "userPrincipalName" },
];
