import { getConfiguration } from "../../common/api/configuration";
import {
  acpBaseURI,
  analyticsPathPrefix,
  apiPrefix,
  identityPathPrefix,
  permissionsPathPrefix,
} from "../../common/api/paths";
import axiosInstance from "../adminAxiosInstance";

export const adminApiClazz = clazz => () => {
  const basePath = `${acpBaseURI}${apiPrefix}`;

  return new clazz(getConfiguration(basePath), basePath, axiosInstance);
};

export const identityApiClazz = clazz => () => {
  const basePath = `${acpBaseURI}${identityPathPrefix}`;

  return new clazz(getConfiguration(basePath), basePath, axiosInstance);
};

export const permissionsApiClazz = clazz => () => {
  const basePath = `${acpBaseURI}${permissionsPathPrefix}`;

  return new clazz(getConfiguration(basePath), basePath, axiosInstance);
};

export const analyticsApiClazz = clazz => () => {
  const basePath = `${acpBaseURI}${analyticsPathPrefix}`;

  return new clazz(getConfiguration(basePath), basePath, axiosInstance);
};
