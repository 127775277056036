import React, { ReactNode } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { makeStyles } from "tss-react/mui";

import { useCommonStyles } from "./utils";

const useStyles = makeStyles()(theme => ({
  accordion: {
    marginTop: 2,
  },
  accordionSummary: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  accordionSummaryContent: {
    marginRight: 16,
  },
  flexContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  chips: {
    justifyContent: "flex-end",
  },
  summaryInfo: {
    fontSize: 12,
    color: theme.palette.secondary.light,
    textWrap: "nowrap",
  },
}));

interface Props {
  id: string;
  icon?: ReactNode;
  name: string;
  summaryInfo?: string;
  chips: ReactNode;
  children: ReactNode;
  noDetailsPadding?: boolean;
}

export default function SelfServiceCommonAccordion({
  id,
  icon,
  name,
  summaryInfo,
  chips,
  children,
  noDetailsPadding,
}: Props) {
  const { cx, classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={id}
        className={classes.accordionSummary}
      >
        <div className={cx(classes.accordionSummaryContent, classes.flexContainer)}>
          <div className={classes.flexContainer}>
            <div className={commonClasses.icon}>{icon}</div>
            {name}
          </div>
          {summaryInfo && <div className={classes.summaryInfo}>{summaryInfo}</div>}
          <div className={cx(classes.chips, classes.flexContainer)}>{chips}</div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={noDetailsPadding ? { padding: 0 } : {}}>{children}</AccordionDetails>
    </Accordion>
  );
}
