import React from "react";
import { useNavigate } from "react-router";

import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { MoreVertical } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { PoolResponse, Schema } from "@cloudentity/acp-identity";

import EnhancedTableAsync from "../../../common/EnhancedTableAsync/EnhancedTableAsync";
import { UseItemsReturnType } from "../../../common/EnhancedTableAsync/useItemsWithQuery";
import { businessMetadataSchemaLabel, metadataSchemaLabel, payloadSchemaLabel } from "../consts";
import { usePoolRootUrl } from "../utils";
import IdentityPoolCardIcons from "./IdentityPoolCardIcons";
import { getNumberOfUsers } from "./utils";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  nameContainer: {
    maxWidth: "calc(100% - 60px)",
    display: "flex",
    flexDirection: "column",
  },
  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.secondary.dark,
  },
  description: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.secondary.light,
    marginTop: 2,
  },
}));

const headCells = [
  {
    id: "name",
    label: "Name",
    width: "40%",
    sortable: true,
  },
  {
    id: "population",
    label: "Population",
    align: "center" as "center",
  },
  {
    id: "sign_in_sign_up",
    label: "Sign-in / Sign-up",
  },
  {
    id: "payload_schema_id",
    label: payloadSchemaLabel,
  },
  {
    id: "metadata_schema_id",
    label: metadataSchemaLabel,
  },
  {
    id: "business_metadata_schema_id",
    label: businessMetadataSchemaLabel,
  },
  { id: "actions", label: "", align: "right" as "right", width: 50 },
];

interface Props {
  data: UseItemsReturnType<PoolResponse>;
  schemas: Schema[];
  setMenu: (menu: { elementId: string; pool: PoolResponse }) => void;
}

function IdentityPoolsListTable({ data, schemas, setMenu }: Props) {
  const { cx, classes: componentClasses } = useStyles();
  const navigate = useNavigate();
  const { rootUrl } = usePoolRootUrl();

  const {
    data: pools,
    isFetching,
    sort,
    order,
    page,
    limit,
    nextPageAvailable,
    onPaginationUpdate,
    onFiltersUpdate,
    filters,
    searchText,
  } = data;

  return (
    <EnhancedTableAsync
      id="pools"
      data={pools}
      headCells={headCells}
      loading={isFetching}
      sort={sort}
      order={order}
      page={page}
      limit={limit}
      nextPageAvailable={nextPageAvailable}
      onPaginationUpdate={onPaginationUpdate}
      onFiltersUpdate={onFiltersUpdate}
      filters={filters}
      filtersSetup={[]}
      searchText={searchText}
      searchPlaceholder="Search by identity pool name or ID"
      noToolbar
      createRow={(row: PoolResponse, _, classes) => {
        const {
          id,
          name,
          description,
          metadata_schema_id,
          business_metadata_schema_id,
          payload_schema_id,
          badge_color,
        } = row;
        const metadataSchema = schemas.find(schema => schema.id === metadata_schema_id);
        const businessMetadataSchema = schemas.find(
          schema => schema.id === business_metadata_schema_id
        );
        const payloadSchema = schemas.find(schema => schema.id === payload_schema_id);

        return (
          <TableRow
            key={id}
            hover
            style={{ height: 57, position: "relative" }}
            id={`pools-table-row-${id}`}
            className={cx([classes.rowWithHoverIcons, classes.row])}
            onClick={() => id && navigate(`${rootUrl}/${id}/users`)}
          >
            <TableCell id={`pools-table-name-${id}`} style={{ maxWidth: 0, minWidth: 300 }}>
              <div className={componentClasses.container}>
                <Avatar style={{ marginRight: 16, backgroundColor: badge_color || "#BDBDBD" }}>
                  {(name && name[0] && name[0].toUpperCase()) || ""}
                </Avatar>
                <div className={componentClasses.nameContainer}>
                  <Typography variant="textMD" className={componentClasses.name}>
                    {name}
                  </Typography>
                  <Typography variant="textSM" className={componentClasses.description}>
                    {description}
                  </Typography>
                </div>
              </div>
            </TableCell>
            <TableCell id={`pools-table-population-${id}`} align="center">
              {getNumberOfUsers(row.number_of_users)}
            </TableCell>
            <TableCell id={`pools-table-sign-in-sign-up-${id}`}>
              <div style={{ display: "flex" }}>
                <IdentityPoolCardIcons pool={row} />
              </div>
            </TableCell>
            <TableCell id={`pools-table-user-schema-${id}`}>
              {payloadSchema ? (
                <Link
                  onClick={e => {
                    e.stopPropagation();
                    navigate(`/identity-pools/schemas/${payloadSchema.id}/schema`);
                  }}
                >
                  {payloadSchema.name}
                </Link>
              ) : (
                ""
              )}
            </TableCell>
            <TableCell id={`pools-table-metadata-schema-${id}`}>
              {metadataSchema ? (
                <Link
                  onClick={e => {
                    e.stopPropagation();
                    navigate(`/identity-pools/schemas/${metadataSchema.id}/schema`);
                  }}
                >
                  {metadataSchema.name}
                </Link>
              ) : (
                ""
              )}
            </TableCell>
            <TableCell id={`pools-table-business-metadata-schema-${id}`}>
              {businessMetadataSchema ? (
                <Link
                  onClick={e => {
                    e.stopPropagation();
                    navigate(`/identity-pools/schemas/${businessMetadataSchema.id}/schema`);
                  }}
                >
                  {businessMetadataSchema.name}
                </Link>
              ) : (
                ""
              )}
            </TableCell>
            <TableCell align="right">
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  setMenu({ elementId: e.currentTarget.id, pool: row });
                }}
                id={`row-${id}-menu-button`}
              >
                <MoreVertical />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}
export default IdentityPoolsListTable;
