import { useQuery } from "@tanstack/react-query";

import { FeaturesResponse } from "@cloudentity/acp-admin";

import adminFeaturesApi from "./adminFeaturesApi";
import { withQueryError } from "./queryUtils";

const GET_BETA_FEATURES = "GET_BETA_FEATURES";

export const getBetaFeaturesKey = [GET_BETA_FEATURES];

export const useGetBetaFeatures = () =>
  useQuery<FeaturesResponse>({
    queryKey: getBetaFeaturesKey,
    queryFn: withQueryError<FeaturesResponse>(async () => {
      const data = await adminFeaturesApi.getBetaFeatures();
      return data.data;
    }, "Error occurred while trying to get early access features"),
  });
