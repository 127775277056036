import React from "react";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import BreadcrumbsMUI from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  container: {
    marginBottom: 13,
  },
  label: {
    color: theme.custom.greys.textDisabled,
  },
  link: {
    border: "none",
    background: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
    padding: 0,
    margin: 0,
  },
}));

interface Props {
  items: { label: string; key: string; onClick?: () => void }[];
}

function Breadcrumbs({ items }: Props) {
  const { cx, classes } = useStyles();

  return (
    <div className={classes.container}>
      <BreadcrumbsMUI
        separator={<NavigateNextIcon fontSize="small" style={{ color: "#A0A3B5" }} />}
        aria-label="breadcrumb"
      >
        {items.map(item =>
          item.onClick ? (
            <button
              type="button"
              onClick={item.onClick}
              className={cx(classes.label, classes.link)}
              key={item.key}
            >
              <Typography variant="caption">{item.label}</Typography>
            </button>
          ) : (
            <Typography variant="caption" className={classes.label} key={item.key}>
              {item.label}
            </Typography>
          )
        )}
      </BreadcrumbsMUI>
    </div>
  );
}

export default Breadcrumbs;
