import React from "react";
import { useNavigate } from "react-router";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import { Schema } from "@cloudentity/acp-identity";

import Chip from "../../../../../common/components/Chip";
import OverflowTooltip from "../../../../../common/components/OverflowTooltip";
import SelectablePaper from "../../../../../common/components/SelectablePaper";

interface Props {
  schema: Schema;
}

export default function SchemasGridItem({ schema }: Props) {
  const navigate = useNavigate();

  return (
    <SelectablePaper
      id={`${schema.id}`}
      selectable
      onSelect={() => navigate(`/identity-pools/schemas/${schema.id}/schema`)}
      style={{ minHeight: 155 }}
    >
      <div style={{ display: "flex", padding: "16px 12px", maxWidth: "100%", width: "100%" }}>
        <Avatar
          style={{
            marginRight: 8,
            background: schema.system ? "#393954" : "#EAB344",
          }}
        >
          {schema.name[0]?.toUpperCase()}
        </Avatar>
        <div style={{ maxWidth: "calc(100% - 112px)" }}>
          <OverflowTooltip id="workspace-card-name-tooltip" value={schema.name} />
          <Typography variant="caption" style={{ color: "#9EA1B7" }}>
            {schema.description || "No description"}
          </Typography>
        </div>
      </div>
      <div style={{ padding: "16px 12px" }}>
        {schema.system && <Chip label="Readonly" size="small" style={{ marginRight: 6 }} />}
      </div>
    </SelectablePaper>
  );
}
