import { notifyError } from "../../components/notifications/notificationService";

export const parseJwtPayload = token => {
  if (!token) return {};

  try {
    const parsed = JSON.parse(decodeBase64url(token.split(".")[1]));
    return parsed;
  } catch {
    notifyError("Error occurred while trying to parse software statement");
    return {};
  }
};

export const getFromToken = (field, token) => parseJwtPayload(token)[field];

export const decodeBase64url = function (input) {
  // https://stackoverflow.com/a/51838635/2119651
  // Replace non-url compatible chars with base64 standard chars
  input = input.replace(/-/g, "+").replace(/_/g, "/");

  // Pad out with standard base64 required padding characters
  const pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error(
        "InvalidLengthError: Input base64url string is the wrong length to determine padding"
      );
    }
    input += new Array(5 - pad).join("=");
  }

  return window.atob(input);
};
