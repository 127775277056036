import React, { useCallback, useContext, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import { useQueryClient } from "@tanstack/react-query";

import { BaseUserWithData } from "@cloudentity/acp-identity";

import { GlobalStoreContext } from "../../../admin/GlobalStore/GlobalStore";
import adminB2BUsersApi from "../../../admin/services/adminB2BUsersApi";
import { useCheckPoolPermissions } from "../../../admin/services/adminIdentityPoolsQuery";
import { listUsersQueryKey } from "../../../admin/services/adminIdentityUsersQuery";
import { getTenantId } from "../../../common/api/paths";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../common/components/notifications/notificationService";

interface Props {
  user?: BaseUserWithData;
  email: string;
  onDeleted: () => void;
  onCloseConfirmation: () => void;
}

export default function B2BManageUserActionsDeleteUser({
  user,
  email,
  onDeleted,
  onCloseConfirmation,
}: Props) {
  const globalStoreContext = useContext(GlobalStoreContext);

  const [progress, setProgress] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const queryClient = useQueryClient();

  const handleConfirm = useCallback(() => {
    setProgress(true);
    adminB2BUsersApi
      .deleteB2BUser({ ipID: user?.user_pool_id!, userID: user?.id! })
      .then(() => globalStoreContext.deletedUsers.addDeletedUserId(user?.id))
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listUsersQueryKey(getTenantId(), user?.user_pool_id),
        })
      )
      .then(() => notifySuccess("User successfully deleted"))
      .then(() => onDeleted())
      .catch(notifyErrorOrDefaultTo("Error occurred when trying to delete user"))
      .finally(() => {
        setProgress(false);
        setConfirmation(false);
      });
  }, [queryClient, globalStoreContext, user, onDeleted]);

  const checkPoolPermissionsQuery = useCheckPoolPermissions(user?.user_pool_id!, {
    enabled: !!user?.user_pool_id,
  });
  if (!checkPoolPermissionsQuery.data?.b2b_manage_users) {
    return null;
  }

  return (
    <>
      <MenuItem
        id="delete-user-button"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setConfirmation(true);
        }}
      >
        Delete User
      </MenuItem>
      {confirmation && (
        <ConfirmationDialog
          title="Delete User"
          content={
            <div>
              You're about to delete user with <b>{user?.identifiers?.at(0)?.identifier ?? ""}</b>{" "}
              identifier. This cannot be undone. Delete anyway?
            </div>
          }
          confirmText="Delete"
          progress={progress}
          onCancel={() => {
            setConfirmation(false);
            onCloseConfirmation();
          }}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
}
