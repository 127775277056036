import { useState } from "react";

export type ApplicationBuilderGlobalStoreContextType = {
  newlyCreatedGateways: string[];
  addNewlyCreatedGateway: (gatewayId: string) => void;
  removeNewlyCreatedGateway: (gatewayId: string) => void;
};

export const useApplicationBuilderGlobalStore = (): ApplicationBuilderGlobalStoreContextType => {
  const [store, setStore] = useState<{ newlyCreatedGateways: string[] }>({
    newlyCreatedGateways: [],
  });

  const addNewlyCreatedGateway = (gatewayId: string) => {
    setStore(store => ({
      ...store,
      newlyCreatedGateways: [...store.newlyCreatedGateways, gatewayId],
    }));
  };

  const removeNewlyCreatedGateway = (gatewayId: string) => {
    setStore(store => ({
      ...store,
      newlyCreatedGateways: store.newlyCreatedGateways.filter(v => v !== gatewayId),
    }));
  };

  return {
    newlyCreatedGateways: store.newlyCreatedGateways,
    addNewlyCreatedGateway,
    removeNewlyCreatedGateway,
  };
};
