import { useState } from "react";

export type AuditEventsGlobalStoreContextType = {
  liveMode: boolean;
  dateRange: boolean;
  dateRangeFrom: string;
  dateRangeTo: string;
  lastId: string | null;
  setLiveMode: (liveMode: boolean) => void;
  setDateRange: (dateRange: boolean) => void;
  setDateRangeFrom: (from: string) => void;
  setDateRangeTo: (to: string) => void;
  setLastId: (id: string) => void;
};

export const useAuditEventsGlobalStore = (): AuditEventsGlobalStoreContextType => {
  const [store, setStore] = useState<{
    liveMode: boolean;
    dateRange: boolean;
    dateRangeFrom: string;
    dateRangeTo: string;
    lastId: string | null;
  }>({
    liveMode: true,
    dateRange: false,
    dateRangeFrom: "",
    dateRangeTo: "",
    lastId: null,
  });

  const setLiveMode = (liveMode: boolean) => setStore(store => ({ ...store, liveMode }));
  const setDateRange = (dateRange: boolean) => setStore(store => ({ ...store, dateRange }));
  const setDateRangeFrom = (dateRangeFrom: string) =>
    setStore(store => ({ ...store, dateRangeFrom }));
  const setDateRangeTo = (dateRangeTo: string) => setStore(store => ({ ...store, dateRangeTo }));
  const setLastId = (lastId: string) => setStore(store => ({ ...store, lastId }));

  return {
    ...store,
    setLiveMode,
    setDateRange,
    setDateRangeFrom,
    setDateRangeTo,
    setLastId,
  };
};
