import React from "react";

import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import IdpLogoManagement from "./IdpLogoManagement";
import { IdpUiModelType } from "./identities.utils";

interface IdentityConfigurationUpperProps {
  onUpdate: (data: any) => void;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  provider: IdpUiModelType;
}

function CommonIdpConfigUpper({
  onUpdate,
  onLogoEdit,
  inEdit,
  provider,
}: IdentityConfigurationUpperProps) {
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(
    provider.authorization_server_id
  );

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", flex: 1 }}>
          <IdpLogoManagement
            logoURI={provider.logo_uri}
            disabled={!checkWorkspacePermissionsQuery.data?.manage_idps}
            onEdit={logoURI =>
              inEdit
                ? onUpdate({ ...provider, logo_uri: logoURI })
                : onLogoEdit && onLogoEdit({ logo_uri: logoURI })
            }
          />
          <TextFieldRequired
            name="name"
            label="Name"
            defaultValue={provider.name}
            autoFocus={!inEdit}
          />
        </div>

        {inEdit && (
          <div style={{ width: 84, marginLeft: 16 }}>
            <TextFieldRequired
              name="display_order"
              label="Display order"
              rules={{
                validate: {
                  inRange: validators.inRange({ label: "Display order", min: 0, max: 255 }),
                },
              }}
              type="number"
              inputProps={{ min: 0, max: 255 }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default CommonIdpConfigUpper;
