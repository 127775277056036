import { RevokeWorkspaceRoleRequestRoleEnum, WorkspaceRoleSubject } from "@cloudentity/acp-admin";

export const subjectToRevokeRoles = (subject?: WorkspaceRoleSubject) => {
  let roles: RevokeWorkspaceRoleRequestRoleEnum[] = [];

  if (subject?.roles?.admin) {
    roles.push(RevokeWorkspaceRoleRequestRoleEnum.Admin);
  }
  if (subject?.roles?.auditor) {
    roles.push(RevokeWorkspaceRoleRequestRoleEnum.Auditor);
  }
  if (subject?.roles?.manager) {
    roles.push(RevokeWorkspaceRoleRequestRoleEnum.Manager);
  }
  if (subject?.roles?.user_manager) {
    roles.push(RevokeWorkspaceRoleRequestRoleEnum.UserManager);
  }
  if (subject?.roles?.member) {
    roles.push(RevokeWorkspaceRoleRequestRoleEnum.Member);
  }

  return roles;
};
