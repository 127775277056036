import React from "react";
import { useMatch } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import some from "lodash/some";
import { makeStyles } from "tss-react/mui";

import { WorkspacePermissionsResponse } from "@cloudentity/acp-admin";

import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import { useWorkspace } from "../common/useWorkspace";

interface Props {
  id: string;
  text: string;
  to: string;
  isActive?: boolean;
  ignoreMatchAndActiveToAllowNavigate?: boolean;
  hasWorkspacePermission?:
    | keyof WorkspacePermissionsResponse
    | Array<keyof WorkspacePermissionsResponse>;
  onClick?: () => void;
}

export default function NavSectionItem({
  id,
  text,
  to,
  isActive,
  ignoreMatchAndActiveToAllowNavigate,
  hasWorkspacePermission,
  onClick,
}: Props) {
  const { classes } = useStyles();
  const match = useMatch({ path: to, end: false });
  const matchExact = useMatch(to);
  const [workspace] = useWorkspace();

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  if (
    hasWorkspacePermission &&
    typeof hasWorkspacePermission === "string" &&
    !checkWorkspacePermissionsQuery.data?.[hasWorkspacePermission]
  ) {
    return null;
  }

  if (
    hasWorkspacePermission &&
    Array.isArray(hasWorkspacePermission) &&
    !some(hasWorkspacePermission, permission => checkWorkspacePermissionsQuery.data?.[permission])
  ) {
    return null;
  }

  const forbiddenClick = !ignoreMatchAndActiveToAllowNavigate && (matchExact || isActive);

  return (
    <ListItemButton
      id={id}
      data-to={to}
      key={id}
      className={match || isActive ? classes.selected : undefined}
      classes={{ root: classes.listItemRoot }}
      onClick={() => !forbiddenClick && onClick?.()}
      {...(forbiddenClick ? {} : { component: RouterLink, to })}
    >
      <ListItemText
        style={{ whiteSpace: "nowrap" }}
        disableTypography
        primary={<Typography variant="caption">{text}</Typography>}
      />
    </ListItemButton>
  );
}

const useStyles = makeStyles()(theme => ({
  selected: {
    color: theme.palette.primary.main,
  },
  disabled: {
    opacity: "1 !important",
  },
  listItemRoot: {
    paddingLeft: 58,
    paddingTop: 8,
    paddingBottom: 8,
    overflow: "hidden",
    fontSize: 12,
  },
}));
