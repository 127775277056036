import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import PageContainer from "../admin/components/common/PageContainer";
import NavAndContentContainer from "../admin/components/nav/NavAndContentContainer";
import NavDrawer from "../admin/components/nav/NavDrawer";
import { useCheckTenantPermissions } from "../admin/services/adminPermissionsQuery";
import B2BAdministrators from "./B2BAdministrators";
import B2BOrganizations from "./B2BOrganizations";
import OrganizationsDirectoryNavDrawerContent from "./OrganizationsDirectoryNavDrawerContent";

function OrganizationsNavDrawer() {
  return <NavDrawer content={OrganizationsDirectoryNavDrawerContent} />;
}

export default function B2BOrganizationsDirectory() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const canManageAdministrators = !!checkTenantPermissionsQuery.data?.manage_admin_role;

  useEffect(() => {
    if (pathname === "/administrators" && !canManageAdministrators) {
      navigate("/organizations");
    }
  }, [canManageAdministrators, navigate, pathname]);

  return (
    <PageContainer>
      <NavAndContentContainer nav={OrganizationsNavDrawer}>
        {pathname === "/administrators" && canManageAdministrators ? (
          <B2BAdministrators />
        ) : (
          <B2BOrganizations />
        )}
      </NavAndContentContainer>
    </PageContainer>
  );
}
