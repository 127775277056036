import {
  ChangePassword,
  SelfUserUpdate,
  SetPassword,
  UsersApiFactory,
  MfaApiFactory,
  ChangeTotpSecret,
  SetTotpSecret,
  SetWebAuthn,
  CredentialCreationResponse,
  NameWebAuthnKey,
} from "@cloudentity/acp-identityself";
import { ClientsApiFactory, SessionsApiFactory } from "@cloudentity/acp-public";

import { getConfiguration } from "../api/configuration";
import { acpBaseURI, userApiPrefix, identityselfPathPrefix } from "../api/paths";
import axiosInstance from "./commonAxiosInstance";

const userApi = () => {
  const basePath = `${acpBaseURI}${userApiPrefix}`;

  return ClientsApiFactory(getConfiguration(basePath), basePath, axiosInstance);
};

const sessionsApi = () => {
  const basePath = `${acpBaseURI}${userApiPrefix}`;

  return SessionsApiFactory(getConfiguration(basePath), basePath, axiosInstance);
};

const api = () => {
  const basePath = `${acpBaseURI}${identityselfPathPrefix}`;

  return UsersApiFactory(getConfiguration(basePath), basePath, axiosInstance);
};

const mfaApi = () => {
  const basePath = `${acpBaseURI}${identityselfPathPrefix}`;

  return MfaApiFactory(getConfiguration(basePath), basePath, axiosInstance);
};

const identitySelfApi = {
  getUserProfile: () => api().getUserProfileV2(),
  updateUserProfile: (req: SelfUserUpdate) => api().updateUserProfileV2(undefined, req),
  changePassword: (req: ChangePassword) => api().changePasswordV2(undefined, req),
  setPassword: (req: SetPassword) => api().setPassword(undefined, req),
  changeTotpSecret: (req: ChangeTotpSecret) => api().changeTotpSecret(req),
  setTotpSecret: (req: SetTotpSecret) => api().setTotpSecret(undefined, req),
  setWebAuthn: (setWebAuthnSecret: SetWebAuthn) => api().setWebAuthn(setWebAuthnSecret),
  beginWebAuthnCredentialsGeneration: () => api().beginWebAuthnCredentialsGeneration(),
  completeWebAuthnCredentialsGeneration: (selfCompleteWebAuthnCreate: CredentialCreationResponse) =>
    api().completeWebAuthnCredentialsGeneration(selfCompleteWebAuthnCreate),
  nameWebAuthnKey: (webAuthnCredentialId: string, nameWebAuthnKey: NameWebAuthnKey) =>
    api().nameWebAuthnKey(webAuthnCredentialId, nameWebAuthnKey),
  deleteWebAuthnKey: (webAuthnCredentialId: string) =>
    api().deleteWebAuthnKey(webAuthnCredentialId),
  getClients: () => userApi().listClientsWithAccess(),
  revokeClient: ({ cid }) => userApi().revokeClientAccess(cid),
  getSessions: () => sessionsApi().listUserSessions(),
  deleteSession: (sessionId: string) => sessionsApi().deleteSession(sessionId),
  listUserMFASessions: () => mfaApi().listUserMFASessions(),
  deleteMFASession: (sessionId: string) => mfaApi().deleteMFASession(sessionId),
};

export default identitySelfApi;
