export const getHoverRowStyles = classes => ({
  row: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  hoverRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  hoverIcon: {
    opacity: 0.1,
  },
  rowWithHoverIcons: {
    [`&:hover .${classes.hoverIcon}`]: {
      opacity: 1,
    },
  },
  selectableRow: {
    cursor: "pointer",
    "&:focus": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  },
});
