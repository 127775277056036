import React from "react";

import Fingerprint from "@mui/icons-material/Fingerprint";
import Password from "@mui/icons-material/Password";
import { Smartphone, UserPlus } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { PoolResponse, PoolResponseAuthenticationMechanismsEnum } from "@cloudentity/acp-identity";

import Tooltip from "../../../common/Tooltip";

const useStyles = makeStyles()(() => ({
  cardBottomItemIcon: {
    backgroundColor: "#F2F3F4",
    width: 18,
    height: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8,
  },
  cardBottomItemInactive: {
    opacity: 0.2,
  },
}));

interface Props {
  pool: PoolResponse;
}

function IdentityPoolCardIcons({ pool }: Props) {
  const { cx, classes } = useStyles();

  return (
    <>
      <div
        className={cx(
          classes.cardBottomItemIcon,
          !pool.public_registration_allowed && classes.cardBottomItemInactive
        )}
        id={`pool-${pool.id}-public-registration`}
      >
        <Tooltip title="Public Registration">
          <UserPlus size="14" />
        </Tooltip>
      </div>
      <div
        className={cx(
          classes.cardBottomItemIcon,
          !pool.authentication_mechanisms?.includes(
            PoolResponseAuthenticationMechanismsEnum.Password
          ) && classes.cardBottomItemInactive
        )}
        id={`pool-${pool.id}-password`}
      >
        <Tooltip title="Password">
          <Password style={{ fontSize: 14 }} />
        </Tooltip>
      </div>
      <div
        className={cx(
          classes.cardBottomItemIcon,
          !pool.authentication_mechanisms?.includes(PoolResponseAuthenticationMechanismsEnum.Otp) &&
            classes.cardBottomItemInactive
        )}
        id={`pool-${pool.id}-otp`}
      >
        <Tooltip title="One Time Password">
          <Smartphone size="14" />
        </Tooltip>
      </div>
      <div
        className={cx(
          classes.cardBottomItemIcon,
          !pool.authentication_mechanisms?.includes(
            PoolResponseAuthenticationMechanismsEnum.Webauthn
          ) && classes.cardBottomItemInactive
        )}
        id={`pool-${pool.id}-passkey`}
      >
        <Tooltip title="Passkey">
          <Fingerprint style={{ fontSize: 14 }} />
        </Tooltip>
      </div>
    </>
  );
}
export default IdentityPoolCardIcons;
